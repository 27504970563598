<template>
  <div
    id="sidebar-public"
    class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-full tw-h-full tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-bg-white"
  >
    <a href="#" class="tw-w-full tw-h-1/8 tw-flex tw-justify-between">
      <img
        class="logo-top-sidebare"
        :src="theme.logo"
        alt="logo"
        @click="navigate('LoginPage')"
      />
      <img
        src="@/assets/images/icons/close.svg"
        alt="menu"
        class="exit-top-sidebare"
        @click="$parent.showSidebar = false"
      />
    </a>
    <ul class="tw-flex tw-flex-col tw-items-center tw-h-5/6">
      <li class="public-header-sidebar-list-li">
        <a
          class="public-header-list-li-a"
          @click="navigate(CheckIfHome + '#second')"
          >Vision</a
        >
      </li>
      <li class="public-header-sidebar-list-li">
        <a
          class="public-header-list-li-a"
          @click="navigate(CheckIfHome + '#ResultSection')"
          >Vos obligations</a
        >
      </li>
      <li class="public-header-sidebar-list-li">
        <a
          class="public-header-list-li-a"
          @click="navigate(CheckIfHome + '#ContactSection')"
          >Contact</a
        >
      </li>
      <li class="public-header-sidebar-list-li">
        <a
          class="public-header-list-li-a"
          @click="navigate({ name: 'LoginPage' })"
          >Se connecter</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StepsComponent",
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    theme() {
      return this.$store.state.referrer;
    },
    CheckIfHome: function () {
      return this.$router.resolve({ name: "landing-public" }).resolved.fullPath;
    },
  },
  methods: {
    navigate(nav) {
      this.$parent.showSidebar = false;
      this.$router.push(nav);
    },
  },
};
</script>

<style scoped></style>
