<template>
  <v-dialog 
    v-model="open" 
    transition="dialog-bottom-transition" 
    persistent 
    width="auto" 
    content-class="rounded-xl"
  >
    <div class="tw-py-28 tw-px-24 tw-flex tw-flex-col tw-items-center">
      <div class="tw-font-semibold tw-text-2xl">L’invitation a bien été envoyée !</div>

      <button 
        class="tw-mt-10 tw-font-bold tw-text-xs tw-text-white tw-rounded-3xl tw-py-2 tw-px-16"
        style="background-color: var(--cta-bg-color);"
        @click="$emit('close')"
      >Retour à la liste</button>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'InviteCollaboratorsSuccessPopup',
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
    addedMembersCount: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      open: false,
    }
  },
  watch: {
    canOpen: { handler(val) { (this.open = val) }, immediate: true },
  }
}
</script>