<template>
  <v-row class="text-center mt-4 p-4 align-center" wrap>
    <v-col cols="12" md="6" lg="8" class="tw-flex">
      <pagination-component
        :length="length"
        :page="page"
        @changePage="changePage"
      />
    </v-col>
    <v-col cols="6" md="3" lg="1">
      <div class="tw-relative">
        <select class="tw-block tw-w-full select-option" v-model="perPage" @change="selectPerPage">
          <option v-for="(item, index) in perPageChoices" :key="item" :selected="index==0">{{ item }}</option>
        </select>
        <svg
          class="flesh h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            :fill="referer.theme.ctaBgColor"
          />
        </svg>
      </div>
    </v-col>
    <v-col cols="6" md="3" lg="2">
      <div class="select-page tw-flex tw-justify-center tw-items-center">
        <span>Page</span>
        <input
          type="number"
          class="tw-border input-text"
          v-model="choosedPage"
          min="1"
          :max="length"
        />
        <span> / {{ length }}</span>
      </div>
    </v-col>
    <v-col cols="12" lg="1">
      <button class="btn-pagination" @click="changeLimitAndPage">OK</button>
    </v-col>
  </v-row>
</template>

<script>
import paginationComponent from './PaginationComponent.vue';
export default {
  components: { paginationComponent },
  name: "FooterTablePaginationComponent",
  props: {
    page: Number,
    length: Number,
    choosingPage : Number,
    limit : Number,
  },
  data() {
    return {
      perPage: 20,
      choosedPage: 2,
      perPageChoices: [10, 20, 50, 100],
    };
  },
  mounted() {
    this.choosedPage = this.choosingPage;
    this.perPage = this.limit;
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    selectPerPage() {
      this.$emit("changeLimitAndPage", {
        page: 1,
        limit: this.perPage,
      });
    },
    changeLimitAndPage() {
      this.$emit("changeLimitAndPage", {
        page: this.choosedPage,
        limit: this.perPage,
      });
    },
  },
  watch: {
    choosingPage: function (val) {
      this.choosedPage = val;
    },
    limit: function (val) {
      this.perPage = val;
    },
  },
};
</script>

<style scoped>
.select-option{
  position: relative;
  border: 1px solid var(--cta-bg-color);
  border-radius: 5px;
  padding: 5px;
  color: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  height: 100%;
}
.select-page{
  border: 1px solid var(--cta-bg-color);
  border-radius: 5px;
  padding: 5px;
  color: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  height: 100%;
}
.input-text{
  padding: 0 6px;
  color: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 30%;
  height: 100%;
}
.flesh{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.btn-pagination{
  border: 1px solid var(--cta-bg-color);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  color: #fff;
  background: var(--cta-bg-color);
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  height: 100%;
}

</style>
