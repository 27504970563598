<template>
  <div class="dynamic-text">
    <div class="tw-space-y-2">
      <div v-for="(activity, index) in activities" :key="index" class="tw-flex tw-full">
        <div
          class="
            tw-w-2/12
            tw-py-2
            tw-px-4
            tw-rounded-xl
            tw-text-white
            tw-font-semibold
            tw-text-sm
            tw-text-center
            dynamic-bg
          "
          style="z-index: 99;"
        >
          Activité {{ index + 1 }} :
        </div>
        <input
          class="
            tw-font-semibold
            tw-w-4/12
            tw-ml-[-8px]
            tw-border-2
            tw-border-l-0
            tw-border-solid
            tw-text-sm
            tw-py-2
            tw-pl-4
            tw-pr-2
          "
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important`,backgroundColor: `#C1C1C1 !important`, color: `#FFF !important` }"
          type="text"
          placeholder="ex. Gestion de la paie"
          :value="activity.registerActivityName"
          disabled
        />
        <input
          class="
            tw-font-semibold
            tw-w-2/12
            tw-border-2
            tw-border-l-0
            tw-border-solid
            tw-text-sm
            tw-py-2
            tw-pl-4
            tw-pr-2
          "
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important`,backgroundColor: `#C1C1C1 !important`, color: `#FFF !important`  }"
          type="text"
          placeholder="Fonction"
          v-model="activity.function"
          disabled
        />
        <input
          class="*
            tw-font-semibold
            tw-w-4/12
            tw-border-2
            tw-border-l-0
            tw-border-solid
            tw-text-sm
            tw-py-2
            tw-pl-4
            tw-pr-2
            tw-rounded-r-xl
          "
          :style="{ borderColor: `${referer.theme.ctaBgColor} !important`,backgroundColor: `#C1C1C1 !important`, color: `#FFF !important`  }"
          type="text"
          placeholder="e-mail"
          v-model="activity.collaborateur.email"
          disabled
        />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    if (this.activities.length === 0) {
      console.log("🚀 ~ file: ActivitiesListFull.vue:87 ~ activities:", this.activities)
    }
  },
  computed:{
    referer() {
      return this.$store.state.referrer;
    }
  },
  methods: {
    addActivitySlot() {
      this.activities.push({
        name: "",
        function: "",
        // lastName: "",
        email: "",
      });
    },
    handleActivitiesChanged() {
      this.$emit('handleActivitiesChanged', this.activities);
    }
  },
};
</script>
