<template>
  <v-container class="tw-pt-0 tw-mt-0" id="register-step-one-expert-page">
    <div class="auth-container tw-mt-10">
      <div v-if="!formSent">
        <TitleComponent class="tw-mt-8" text="Création de votre compte multi-entités/clients" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Voici les informations que vous avez renseignées et celles concernant votre entité. Veuillez vérifier et compléter ces informations.
        </SignupSecondaryTextComponent>
        <v-form
            ref="form"
            @submit="onSubmit"
            v-model="formValid"
            class="tw-mt-12"
            autocomplete="off"
        >
          <div class="tw-flex tw-gap-x-5">
            <TextFieldComponent
              label="Prénom"
              disabled
              class="field-readonly"
              :value="firstName"
              style="width: 12rem;"
            />
            <TextFieldComponent
              label="Nom"
              disabled
              class="field-readonly"
              :value="lastName"
              style="width: 12rem;"
            />
            <TextFieldComponent
              label="Email"
              disabled
              class="field-readonly"
              :value="email"
              style="width: 28rem;"
            />
            <TextFieldComponent
              label="Raison Sociale"
              disabled
              class="field-readonly"
              :value="socialReason"
              style="width: 16rem;"
            />
            <TextFieldComponent
              label="Siren"
              disabled
              class="field-readonly"
              :value="sirenOrRna"
              style="width: 10rem;"
            />
          </div>

          <div class="tw-flex tw-gap-x-5 tw-mt-4">
            <TextFieldComponent
              :rules="rules.legalStatus"
              :error-messages="errors.legalStatus"
              v-model="form.legalStatus"
              label="Structure Juridique"
              style="width: 25rem;"
            />
            <TextFieldComponent
              :rules="rules.tradeName"
              :error-messages="errors.tradeName"
              v-model="form.tradeName"
              label="Dénomination Commerciale"
              style="width: 28rem;"
            />
            <TextFieldComponent
              :rules="rules.tva"
              :error-messages="errors.tva"
              v-model="form.tva"
              label="TVA Intracommunautaire"
              style="width: 14rem;"
            />
            <TextFieldComponent
              :rules="rules.postalAPE"
              :error-messages="errors.postalAPE"
              v-model="form.postalAPE"
              label="Code APE"
              style="width: 10rem; margin-left: 2.5rem;"
            />
          </div>

          <div class="tw-flex tw-gap-x-5 tw-mt-4">
            <TextFieldComponent
              :rules="rules.activity"
              :error-messages="errors.activity"
              v-model="form.activity"
              label="Domaine D’activité / Métier"
              style="width: 25rem;"
            />
            <TextFieldComponent
              :rules="rules.address"
              :error-messages="errors.address"
              v-model="form.address"
              label="Adresse Postale, Rue, Voie"
              style="width: 28rem;"
            />
            <TextFieldComponent
              :rules="rules.postalCode"
              :error-messages="errors.postalCode"
              v-model="form.postalCode"
              label="Code Postal"
              style="width: 10rem;"
            />
            <TextFieldComponent
              :rules="rules.city"
              :error-messages="errors.city"
              v-model="form.city"
              label="Ville"
              style="width: 17rem;"
            />
          </div>

          <div class="tw-flex tw-gap-x-5 tw-mt-12">
            <PasswordFieldComponent
              @keyup="handelPassword"
              @useSuggestPassword="useSuggestPassword"
              v-model="form.password"
              :rules="rules.password"
              :error-messages="errors.password"
              label="Saisissez votre mot de passe"
              validate
              passwordRules="<div class='tw-flex tw-items-center'><svg class='tw-mr-2' xmlns='http://www.w3.org/2000/svg' width='38' height='38' viewBox='0 0 24 24'><path fill='currentColor' d='M2.73 20L12 4l9.27 16H2.73Zm1.72-1h15.1L12 6L4.45 19ZM12 17.615q.262 0 .438-.177q.177-.176.177-.438t-.177-.438q-.176-.177-.438-.177t-.438.177q-.177.176-.177.438t.177.438q.176.177.438.177Zm-.5-2.23h1v-5h-1v5ZM12 12.5Z'/></svg>Votre mot de passe doit contenir au moins 8 caractères, <br>une majuscule, 2 chiffres et 2 signes tels que !#@&;+)<div>"
            />
            <div>
              <PasswordFieldComponent
                :rules="rules.confirmPassword"
                v-model="form.confirmPassword"
                :error-messages="errors.confirmPassword"
                label="Confirmez votre mot de passe"
                style="width: 28rem;"
              />

              <div @click="useSuggestPassword()" class="tw-mt-2 tw-p-1 tw-flex tw-items-baseline tw-space-x-2 tw-border tw-border-black tw-rounded-lg tw-cursor-pointer" style="width: fit-content;">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.53871 5.7496C8.53871 7.90614 7.00432 9.37029 5.44355 9.37029C3.88278 9.37029 2.34839 7.90614 2.34839 5.7496C2.34839 3.59305 3.88278 2.12891 5.44355 2.12891C7.00432 2.12891 8.53871 3.59305 8.53871 5.7496Z" stroke="#292A3B" stroke-width="3"/>
                  <rect x="8.0325" y="4.85352" width="10.6968" height="1.7931" fill="#292A3B" stroke="#292A3B"/>
                  <rect x="13.0454" y="7.64648" width="4.0129" height="1.7931" fill="#292A3B" stroke="#292A3B"/>
                </svg>

                <span class="tw-text-xs">Suggérer un mot de passe sécurisé</span>
              </div>
            </div>
          </div>

          <div class="tw-flex tw-justify-end tw-mt-8">
            <PrimaryButtonComponent :loading="isSendingForm" type="submit">
              Suivant
              <svg class="tw-ml-3" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6H15" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 1L15 6L10 11" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </PrimaryButtonComponent>
          </div>
        </v-form>

        <p class="registration-iml tw-mt-14" style="position: unset;">
          Les informations à caractère personnel recueillies dans le cadre de
          votre demande de souscription à la plateforme Viqtor® sont enregistrées
          dans un fichier informatisé par la société DBWO pour permettre la
          finalisation de l’inscription de votre organisation à nos services.
          Elles seront conservées jusqu’à la validation de la souscription,
          période durant laquelle elles pourront être régulièrement mises à jour à
          votre demande. Hors demande de votre part, elles seront détruites en cas
          de non-finalisation de votre demande sous un délai de 2 mois après cet
          envoi. Ces données sont destinées au personnel administratif de DBWO et
          conservées sur les serveurs de l’entreprise OVH qui héberge le site
          Viqtor® sur le territoire français. Conformément à la loi « informatique
          et libertés », vous pouvez exercer votre droit d'accès aux données vous
          concernant et les faire rectifier en contactant : dpo@dbwo.legal.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PasswordFieldComponent from "@/components/common/PasswordFieldComponent";
import { validationRules } from "@/utils/validation-rules";
import { getUserDataByToken, postSecondStepRegistrationExpert } from "@/features/auth/services/appApi";

export default {
  name: "ExpertSecondStepRegistrationPage",
  components: {
    PasswordFieldComponent,
    TextFieldComponent,
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      sirenOrRna: '',
      socialReason: '',
      formSent: false,
      formValid: false,
      isSendingForm: false,
      disableComfirmPassword: true,
      errors: {
        legalStatus: "",
        tradeName: "",
        tva: "",
        postalAPE: "",
        activity: "",
        address: "",
        postalCode: "",
        city: "",
        password: "",
        confirmPassword: ""
      },
      form: {
        legalStatus: "",
        tradeName: "",
        tva: "",
        postalAPE: "",
        activity: "",
        address: "",
        postalCode: "",
        city: "",
        password: "",
        confirmPassword: ""
      },
      rules: {
        legalStatus: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "legalStatus"),
        ],
        tradeName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "tradeName"),
        ],
        tva: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "tva"),
        ],
        postalAPE: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "postalCode"),
        ],
        activity: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "activity"),
        ],
        address: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "address"),
        ],
        postalCode: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "postalCode"),
        ],
        city: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "city"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.form.password),
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    useSuggestPassword() {
      let securedRandomPassword = Math.random().toString(36).slice(-9);
      securedRandomPassword += Math.random().toString(36).slice(-9).toUpperCase();
      securedRandomPassword += Math.floor(Math.random() * 10);
      securedRandomPassword += securedRandomPassword[2].replace(
        securedRandomPassword[2],
        ["!", "@", "#", "$", "%", "^", "&", "*", "?", "_", "~", "-", "+"][Math.floor(Math.random() * 12)]
      );
      
      this.form.password = securedRandomPassword;
      this.form.confirmPassword = securedRandomPassword;
    },
    fetchData() {
      getUserDataByToken(this.$route.params.token)
          .then(({ data: { data } }) => {
            this.isLoadingData = false;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.sirenOrRna = data.workInCompany.sirenOrRna;
            this.socialReason = data.workInCompany.socialReason;
          })
          .catch(({ response }) => {
            if (!response) {
              this.$snackbar.showAlertMessage({
                message: "Erreur lors de la récupération des données",
                type: "error",
              });
              return;
            }

            this.isLoadingData = false;
            if (response.status === 404) {
              this.$router.replace({ name: "TokenExpired" });
            } else {
              this.$snackbar.showAlertMessage({
                message: "Erreur: quelque chose s'est mal passé",
                type: "error",
              });
            }
          });
    },
    onSubmit($event) {
      $event.preventDefault();

      if (!this.firstName || !this.lastName || !this.email || !this.sirenOrRna || !this.socialReason) {
        this.$snackbar.showAlertMessage({
          message: "Vous ne pouvez pas continuer sans avoir validé les informations de votre compte, veuillez réessayer plus tard",
          type: "error",
        });
        return;
      }

      if (!this.formValid) {
        this.$snackbar.showAlertMessage({
          message: "Veuillez remplir correctement tous les champs",
          type: "error",
        });
        return;
      }

      this.isSendingForm = true;
      postSecondStepRegistrationExpert(
        {
          legalStatus: this.form.legalStatus,
          tradeName: this.form.tradeName,
          tva: this.form.tva,
          postalAPE: this.form.postalAPE,
          activity: this.form.activity,
          address: this.form.address,
          postalCode: this.form.postalCode,
          city: this.form.city,
          password: this.form.password,
        },
        this.$route.params.token
      )
          .then(() => {
            this.isSendingForm = false;
            this.formSent = true;
            this.$router.push({ 
              name: 'Auth.Registration.Expert.LastStep',
              params: { token: this.$route.params.token }
            });
            window.scrollTo(0, 0);
          })
          .catch(({ response }) => {
            window.scrollTo(0, 0);
            this.isSendingForm = false;
            if (response.status === 400) {
              response.data.message.forEach((item) => {
                this.$notify({
                  group: "foo",
                  type: "error",
                  title: "Attention",
                  position:"bottom right",
                  text: item,
                });
              });
            } else {
              this.$snackbar.showAlertMessage({
                message: "Erreur: la requete a échoué",
                type: "error",
              });
            }
            this.$refs.form.validate();
          });
    },
    handelPassword(event) {
      if (
          event.target.value.length >= 8 &&
          event.target.value.match(/[a-z]/) &&
          event.target.value.match(/[A-Z]/) &&
          event.target.value.match(/\d+/) &&
          (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) || event.target.value.includes('-') )
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.form.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    },
  }
}
</script>

<style>
#register-step-one-expert-page .field-readonly .v-input--is-dirty.theme--light.v-input input {
  border: none !important;
  box-shadow: none !important;
  background-color: #F4F4F4 !important;
}
#register-step-one-expert-page .v-input {
  font-size: 14px !important;
}
</style>
