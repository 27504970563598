<template>
  <div id="visa-paiement-container">
    <div class="tw-flex tw-flex-row">
      <div class="sm:tw-w-3/12">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-ml-3 dynamic-text">
          <b> Récapitulatif </b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="tw-w-7/12 tw-ml-6">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-ml-3 dynamic-text">
          <b> Ajouter un compte courant </b>
        </SignupSecondaryTextComponent>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-row visa-paiement-sub-container">
      <div class="md:tw-w-3/12 lg:tw-w-3/12 paiement-border-div">
        <SignupSecondaryTextComponent class="public-text-register">
          Date : {{ currentDate }} UTM<br />
          Solution {{ user.plan.salaryRange === '0 - 0' ? `Module sous-traitance` : `${user.plan.salaryRange} salariés`
          }}<br />
          Abonnement {{ user.type === 'monthly' ? 'mensuel' : 'annuel' }}<br />
          <b>Montant : {{ user.price }} € HT / {{ ((user.price) * 1.2).toFixed(2) }} € TTC</b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="md:tw-w-9/12 lg:tw-w-9/12 tw-flex tw-flex-col tw-space-y-4 tw-px-3">

        <div class="tw-grid tw-gap-4 tw-grid-cols-12">
          <div class="tw-col-span-12">
            <TextFieldPaiementComponent label="Nom" placeholder="Nom" sub-label="" v-model="visaForm.cardName" />
          </div>
          <div class="tw-col-span-12">
            <TextFieldPaiementComponent label="Email" placeholder="Email" sub-label="" v-model="visaForm.cardEMAIL" />
          </div>
          <div class="tw-col-span-12 tw-flex tw-justify-center tw-items-center">
            <span class="paiement-input-label tw-w-5/12" for="iban-element">
              Iban
            </span>
            <div class="tw-w-7/12 tw-ml-3 paiement-input" id="iban-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-row tw-justify-end">
      <button class="validate-button" @click="submit" id="submit-button" :disabled="isLoading" :data-secret="{clientSecret}">
        {{ buttonText }}
      </button>
    </div>
    <div id="mandate-acceptance" class="tw-text-xs tw-text-justify">
      En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) Rocket Rides et Stripe, 
      notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à donner à votre banque l’instruction 
      de débiter votre compte, et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, 
      vous êtes en droit d’obtenir un remboursement de la part de votre banque, conformément aux conditions de votre contrat avec cette 
      dernière. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte. 
      Vos droits sont précisés dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des 
      notifications concernant les débits futurs jusqu’à 2 jours avant qu’ils n’aient lieu.
    </div>
    <!-- Used to display form errors. -->
    <div id="error-message" role="alert"></div>
  </div>
</template>

<script>
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import TextFieldPaiementComponent from "@/components/common/TextFieldPaiementComponent";
import { subscriptionUserSepa } from "@/features/auth/services/appApi";
import moment from "moment";
import { mapState } from 'vuex';
import {loadStripe} from '@stripe/stripe-js';
export default {
  components: { TextFieldPaiementComponent, SignupSecondaryTextComponent },
  data() {
    return {
      stripe: null,
      iban: null,
      isLoading: false,
      buttonText: 'validez',
      visaForm: {
        planPriceId: null,
        cardName: '',
        cardEMAIL: '',
        cardIBAN: '',
      }
    }
  },
  computed: mapState({
    userState: state => state.user,
    currentDate() {
      return moment().format('DD/MM/YY - HH:MM')
    },
    referer() {
      return this.$store.state.referrer;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.userState.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  }),
  props: {
    user: {},
    selectedType: {
      type: String,
      default: ''
    },
    clientSecret: {
      type: String,
      default: ''
    },
    publicKey: {
      type: String,
      default: ''
    },
  },
  created() {
    this.visaForm.cardName = `${this.userState.firstName} ${this.userState.lastName}`;
    this.visaForm.cardEMAIL = `${this.userState.email}`;
  },
  mounted() {
    this.initIbanInputStripe()
  },
  methods: {
    async initIbanInputStripe(){
      this.stripe =  await loadStripe(this.publicKey);
      const elements = this.stripe.elements();
      // Custom styling can be passed to options when creating an Element.
      const style = {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        },
      };

      const options = {
        style,
        hideIcon : true,
        supportedCountries: ['SEPA'],
        placeholderCountry: 'FR',
      };

      this.iban = elements.create('iban', options);
      this.iban.mount('#iban-element');
    },
    async submit() {
      this.isLoading = true;
      this.buttonText = 'Chargement...';
      const response = await this.stripe.confirmSepaDebitSetup(
      this.clientSecret,
          {
            payment_method: {
              sepa_debit: this.iban,
              billing_details: {
                name: this.visaForm.cardName,
                email: this.visaForm.cardEMAIL,
              },
            },
          }
      )

      try {
        const paymentData = {paymentMethod : response.setupIntent.payment_method, planPriceId : this.user.id};
        const resp = await subscriptionUserSepa(paymentData)
        this.$store.dispatch('setAuth', resp.data.data);
        this.$snackbar.showAlertMessage({ message: resp.data.message, type: "success" });
        this.$router.push({ name: this.dashboardName });
      } catch (error) {
        this.$snackbar.showAlertMessage({ message: error.response.data.message, type: "error" });
      }
      finally {
        this.isLoading = false;
        this.buttonText = 'validez';
      }
    }
  }
}
</script>

<style scoped>
.paiement-input-label{
  font-family: Poppins, 'sans-serif';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #25282B;
    text-align: right;
}
.paiement-input {
    min-height: 20px;
    border: 0.5px solid  var(--cta-bg-color);
    box-sizing: border-box;
    padding: 3px;
  }
</style>
