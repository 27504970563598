<template>
  <section :style="{ 'background-color': referer.theme.ctaBgColor }" id="SignSection">
    <v-container class="tw-mx-auto tw-px-5">
      <span>{{ referer.text.signSectionText }}</span>
      <v-btn
        elevation="1"
        class="tw-cursor-pointer"
        :style="{
            'background-color': `${referer.theme.ctaTextColor} !important`,
            color: `#000000 !important`,
          }"
        text
        rounded
        large
        @click="goToRegister"
      >
        {{ referer.text.signSectionButtonText }}
        <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.95115 8.00002H28.0414" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.9098 1.34998L28.0414 7.99998L18.9098 14.65" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </v-btn>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "ThirdSection",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    goToRegister() {
      if (this.referer.title === 'Viqtor Expert') {
        this.$router.push({ name:  'Auth.Registration.Expert.PreRegister' });
      } else {
        this.$router.push({ name:  'signup' });
      }
    }
  }
};
</script>
