<template>
  <div id="mes-partages">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Mes partages"
        description="Faites découvrir à vos partenaires votre score et votre questionnaire en détail."
      />
      <v-btn
        elevation="1"
        text
        rounded
        large
        @click="historique()"
        class="tw-text-white tw-flex tw-items-center"
      >
        Historique
        <svg class="tw-ml-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C11.6355 15 15 11.6355 15 7.5C15 3.3645 11.6355 0 7.5 0ZM7.5 13.5C4.19175 13.5 1.5 10.8082 1.5 7.5C1.5 4.19175 4.19175 1.5 7.5 1.5C10.8082 1.5 13.5 4.19175 13.5 7.5C13.5 10.8082 10.8082 13.5 7.5 13.5Z" fill="white"/>
          <path d="M8.33333 3H7V8H11V6.33333H8.33333V3Z" fill="white"/>
        </svg>

      </v-btn>
    </div>
    <EntrepriseTableComponent
      title="Avec qui souhaitez-vous partager votre questionnaire ?"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EntrepriseTableComponent from "../../../components/partage/EntrepriseTableComponent";
export default {
  name: "MonEntreprisePartagePage",
  computed: {},
  components: {
    EntrepriseHeaderBlockTextComponent,
    EntrepriseTableComponent,
  },
  data() {
    return {
      openMenu: false,
    };
  },
  methods: {
    historique() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Partage.Historique",
      });
    },
  },
};
</script>
