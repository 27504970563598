<template>
  <v-switch class="primary-switch" v-bind="$attrs" v-on="$listeners"></v-switch>
</template>

<script>
export default {
  name: "SwitchButtonComponent",
};
</script>

<style scoped></style>
