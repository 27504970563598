<template>
  <div>
    <signup-secondary-text-component class="subheader tw-mb-6">
        {{ documentSelected.title }}
      </signup-secondary-text-component>
      <div class="tw-px-14 font-calibri show-refrence-box" id="reference">
        <component ref="selectComponent" :is="documentSelected.component" 
        :oldReference="documentSelected.answers"
        :data="data"
        ></component>
      </div>
  </div>
</template>

<script>
import SignupSecondaryTextComponent from '../../../../../../auth/components/SignupSecondaryTextComponent.vue'
import {getReferenceCountByDocument} from '../../../../../services/appApi'
import data from '../data/data.js'
import steps from './documents'
import _ from "lodash";
import moment from 'moment'

export default {
  name: 'ShowRefrence',
  components: { ...steps, SignupSecondaryTextComponent },
  props:{
    documentSelected: {
      type: Object,
      default: () => {}
    },
    changeReference:{
      type: Boolean,
      default: () => false
    },
    subcontractors:{
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      data:{},
      menuSubString:'',
      selected:null,
      version:0,
      menuRed:['CLIENTS', 'ADMNISTRÉS', 'PATIENTS', 'ABONNÉS', 'SOCIÉTAIRES', 'ADHÉRENTS', 'MEMBRES', 'COLLABORATEURS'],
      menuGray:['COMMERCIALISE', 'FOURNIT', 'LIVRE', 'DISPENSE', 'DISTRIBUE'],
      menuPurple:['PRODUITS', 'SERVICES', 'PRESTATIONS', 'OFFRES'],
      menuActions:['conçoit', 'intègre', 'installe', 'maintient']
    }
  },
  computed : {
    user(){
      return this.$store.getters.getAuthUser
    },
    selectedData (){
        return data.filter(el => el.id == this.documentSelected.id) 
    },
  },
  async mounted(){

    const dpo = this.$store.getters.getDpo
    const user = this.$store.getters.getAuthUser


    this.data={
        dateRouge:moment().format("DD/MM/YYYY"),
        anneeRouge:moment().format("YYYY"),
        adressSiegeSocial:user.workInCompany.address??'',
        email:dpo.email??user.workInCompany.email,
        ville:user.workInCompany.city??'',
        socialClient:user.workInCompany.tradeName??'',
        nomYellow:dpo.lastName??'',
        prenomYellow:dpo.firstName??'',
        fonctionYellow:dpo.function??'',
        nomClient:user.lastName??'',
        prenomClient:user.firstName??'',
        fonctionClient:user.function??'',
        structureJuridiqueYellow: user.workInCompany.legalStatus??'',
        corporateOfficer:user.workInCompany.corporateOfficer??'',
        version:await this.getReferenceCountByDocument(),
        menuRed:['CLIENTS', 'ADMNISTRÉS', 'PATIENTS', 'ABONNÉS', 'SOCIÉTAIRES', 'ADHÉRENTS', 'MEMBRES', 'COLLABORATEURS'],
        menuGray:['COMMERCIALISE', 'FOURNIT', 'LIVRE', 'DISPENSE', 'DISTRIBUE'],
        menuPurple:['PRODUITS', 'SERVICES', 'PRESTATIONS', 'OFFRES'],
        menuBlue:['Délégué à la Protection des Données (DPO)', 'Correspondant à la Protection des Données CPD'],
        menuActions:['conçoit', 'intègre', 'installe', 'maintient'],
        subcontractors:this.subcontractors.filter(el=>el.subcontractor.tradeName),
        show:true
      }   
  },
  watch:{
    async changeReference() {
      this.data.show=false;
      await this.$nextTick();
      const emails = document.querySelectorAll(".email-editable");
      const emailsArray = [];
      if(emails)
        emails.forEach(el=>emailsArray.push(el.textContent))
      const template = this.$refs.selectComponent.$el.outerHTML;
      this.changeReference && this.$emit('sendReference',{template, emails:JSON.stringify(emailsArray)})
    },
  },
  methods:{
    getReferenceCountByDocument(){
      return getReferenceCountByDocument(this.documentSelected.id).then((res)=>{
        return`N°${_.padStart(res.data+1, 4, '0')}`
      }).catch((err) => {
        console.log("🚀 ~ err:", err)
        
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#app.v-application * {
        font-family: "Calibri" !important;
} 
.show-refrence-box{
    // height: 400px;
    width: 691px;
    padding: 20px;
    overflow: auto;
    margin: 0 auto;
}
select{
  position: relative;
  &::after{
  content:'^';
  position: absolute;
  right: 0;
  top: 10px;
  }
}

</style>