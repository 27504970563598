<template>
  <div class="tw-flex tw-flex-col tw-align-start" id="cgu-page">
    <h2 id="title" class="tw-mx-auto">CONDITIONS GENERALES D’UTILISATION ET D’ASSISTANCE DBWO GOUVERANCE RGPD</h2>
    <CGUComponent class="tw-mt-6" />
  </div>
</template>

<script>
import CGUComponent from "@/features/company/components/Dashboard/CGUComponent";
export default {
name: "CGUPage",
  components: {CGUComponent}
}
</script>

<style scoped>
#title {
  padding-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #25282B;
}
</style>