<template>
  <div id="collaborateurs-table">
    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>
    <!-- table  -->
    <v-simple-table v-else height="600px">
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              <div @click="addToOrder('firstName')" class="tw-cursor-pointer tw-flex">
                <span>Collaborateur</span>
                <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby == 'firstName' && orderType == 'desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby == 'firstName' && orderType == 'asc'"
                  alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
              </div>
            </th>
            <th>
              <div @click="addToOrder('function')" class="tw-cursor-pointer tw-flex">
                <span>Fonction</span>
                <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby == 'function' && orderType == 'desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg"
                  v-else-if="orderby == 'function' && orderType == 'asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
              </div>
            </th>
            <th>
              <div @click="addToOrder('email')" class="tw-cursor-pointer tw-flex">
                <span>Email</span>
                <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby == 'email' && orderType == 'desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg"
                  v-else-if="orderby == 'email' && orderType == 'asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
              </div>
            </th>
            <th style="text-align: center">
              <div @click="addToOrder('status')" class="">
                <span>Statut</span>
                <!-- <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby == 'status' && orderType == 'desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg"
                  v-else-if="orderby == 'status' && orderType == 'asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt=""> -->
              </div>
            </th>
            <th style="text-align: center">Action</th>
          </tr>
        </thead>
        <tbody v-if="data.length > 0">
          <tr v-for="item in data" :key="item.name" class="tw-cursor-pointer">
            <td :title="item.collaborateur.firstName + ' ' +
                item.collaborateur.lastName" class="limit-length">{{
                item.collaborateur.firstName + " " +
                item.collaborateur.lastName
            }}</td>
            <td :title="item.collaborateur.function" class="limit-length">{{ item.collaborateur.function }}</td>
            <td :title="item.collaborateur.email" class="limit-length">{{ item.collaborateur.email }}</td>
            <td v-html="handleStatusSheet(item)"></td>
            <td class="!tw-text-center">
              <div class="tw-cursor-pointer tw-flex tw-justify-center tw-items-center tooltip">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                </svg>
                <div class="tooltiptext">
                  <ul>
                    <li v-if="item.ecosystemQuiz && !item.collaborateur.inactive" @click="goToDetail(item)">Voir</li>
                    <li v-if="item.ecosystemQuiz" @click="deleteEcosystem(item)">Supprimer</li>
                    <li v-if="item.status === 0" @click="resendInvitation(item)">Renvoyer l'invitation</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
        <tfoot class="text-center" v-if="length > 1">
          <tr>
            <td colspan="5">
              <footer-table-pagination-component :limit="perPage" :choosingPage="choosedPage" :length="length" :page="page" @changeLimitAndPage="changeLimitAndPage" @changePage="changePage" />
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <!-- end table  -->
    <EcosystemConfirmeDeletePopup v-if="selectedItem && openPopup" :openPopup="openPopup" :item="selectedItem"
      v-on:fetchData="pagination" @close="openPopup = false" />
  </div>
</template>
<script>
import { getNewCollaboratorsPaginate, resendCollaborator } from "../../../../services/appApi";
import EcosystemConfirmeDeletePopup from "@/features/ProcessingRegister/components/EcosystemConfirmeDeletePopup.vue";
import FooterTablePaginationComponent from '@/components/common/FooterTablePaginationComponent.vue';
export default {
  components: {
    EcosystemConfirmeDeletePopup,
    FooterTablePaginationComponent
  },
  name: "EcosystemCollaborateursTableComponent",
  props: ['word','filter'],
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    referer() {
      return this.$store.state.referrer;
    }
  },
  mounted() {
    this.pagination();
  },
  data() {
    return {
      ids: [],
      data: [],
      page: 1,
      order: null,
      orderby: "",
      orderType: "",
      length: 1,
      choosedPage: 1,
      perPage:10,
      openPopup: false,
      selectedItem: {},
      loading_table: false,
      // sheet,
    }
  },
  methods: {
    resendInvitation(module) {
      resendCollaborator(module.type, module.id)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
    changeLimitAndPage(obj) {
      this.perPage = obj.limit;
      this.page == obj.page?this.pagination():this.changePage(obj.page);
    },
    changePage(page){
      this.page=page;
      setTimeout(()=>{
        document.querySelector('#collaborateurs-table').scrollIntoView({
          behavior: 'smooth'
        });
      },200)
    },
    addToOrder(item) {
      if (this.orderby == item && this.orderType == "desc") {
        this.orderby = null
        this.orderType = null
      } else {
        if (this.orderby == item) {
          this.orderType = "desc"
        }
        else {
          this.orderby = item
          this.orderType = "asc"
        }
      }
    },
    pagination(value = this.page, keyWord = this.word) {
      this.loading_table = true
      getNewCollaboratorsPaginate('ecosystem', value, keyWord, this.perPage, this.filter, this.orderby, this.orderType)
        .then((res) => {
          this.data = res.data.items;
          this.page = res.data.meta.currentPage;
          this.length = res.data.meta.totalPages;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_table = false;
        });
    },
    deleteEcosystem(item) {
      if (!item.ecosystemQuiz) {
        this.selectedItem = item;
        this.openPopup = true;
      }
    },
    goToDetail(item) {
      if (item.ecosystemQuiz)
        this.$router.push({ name: 'EcoSystem.One.Item', params: { id: item.id } })
    },
    handleStatusSheet(item) {
      if (item.status === 0) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E;">
                    En cours
                  </div>
                </div>`;
      }
      else {
        if(item.collaborateur.inactive)
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #d51609">
                      Inactif
                    </div>
                  </div>`;
        else
          return `<div class="tw-flex tw-justify-center tw-items-center">
                    <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #27AE60">
                      Actif
                    </div>
                  </div>`;
      }
    },
  },
  watch: {
    word() {
      this.pagination()
    },
    filter:{
      handler() {
        this.pagination()
      },
      deep: true
    },
    orderby() {
        this.pagination()
    },
    orderType() {
        this.pagination()
    },
    page() {
      this.choosedPage = this.page
        this.pagination()
    }
  }
};
</script>
<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: 22px;
  right: -38px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>