import PendingSubContractors from "../features/subcontractor/pages/pending/PendingSubContractors.vue";
import Verifier from "../features/subcontractor/pages/pending/Verifier.vue";
import Preview from "../features/subcontractor/pages/pending/Preview.vue";
import Invitation from "../features/subcontractor/pages/pending/Invitation.vue";
import LastVerification from "../features/subcontractor/pages/pending/LastVerification.vue";

const routes = [
  {
    name: "SubContractor.PendingSubContractors",
    path: "",
    component: PendingSubContractors,
    meta: {
      requiresAuth: true,
      breadcrumb: {
        name: 'En attente'
      }
    },
  },
  {
    name: "SubContractor.PendingSubContractors.verifier",
    path: "invite",
    component: Verifier,
    meta: {
      requiresAuth: true,
      breadcrumb: {
        name: 'Vérifier'
      }
    },
  },
  {
    name: "SubContractor.PendingSubContractors.verifier.preview",
    path: "invite/Prévisualisation",
    component: Preview,
    meta: {
      requiresAuth: true,
      breadcrumb: {
        name: 'Prévisualisation'
      }
    },
  },
  {
    name: "SubContractor.PendingSubContractors.verifier.invitation",
    path: "invite/Prévisualisation/Invitation",
    component: LastVerification,
    meta: {
      requiresAuth: true,
      breadcrumb: {
        name: 'Invitation'
      }
    },
  },
  {
    name: "SubContractor.PendingSubContractors.verifier.invitation.msg",
    path: "invite/Prévisualisation/Invitation/msg",
    component: Invitation,
    meta: {
      requiresAuth: true,
      breadcrumb: {
        name: 'Invitation'
      }
    },
  },
];
export default routes;
