<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-overflow-y-auto tw-w-full tw-max-w-80 tw-py-6">
    <template v-if="loading_qst">
        <content-placeholders class="">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
    </template>
    <template v-else>
      <img width="6%" :src="referer.logo" alt="LOGO" />

      <h3 class="tw-font-semibold tw-text-2xl tw-w-fit tw-mt-8" style="letter-spacing: -0.5px;">Votre score est prêt !</h3>
        
      <span class="mon-entreprise-para tw-mt-3 !tw-text-center !tw-leading-[1.4]">
        Laissez-nous vos coordonnées afin d'obtenir les résultats de votre questionnaire RGPD par mail.
      </span>

      <v-form
        ref="form"
        @submit="onSubmit"
        v-model="formValid"
        class="tw-mt-8"
        style="width: 100%; max-width: 40rem;"
        autocomplete="off"
      >
        <RadioButtonComponent
          :rules="rules.gender"
          :items="genders"
          @change="onGenderChange"
        />
        <TextFieldComponent
          :rules="rules.firstName"
          :error-messages="errors.firstName"
          v-model="form.firstName"
          label="Prénom"
          class="tw-mt-6"
        />
        <TextFieldComponent
          :rules="rules.lastName"
          v-model="form.lastName"
          :error-messages="errors.lastName"
          label="Nom"
        />
        <TextFieldComponent
          :rules="rules.email"
          v-model="form.email"
          label="Adresse e-mail"
        />
        <TextFieldComponent
          :rules="rules.socialReason"
          v-model="form.socialReason"
          :error-messages="errors.socialReason"
          label="Raison social de l'entreprise"
        />
        <TextFieldComponent
          :rules="rules.phone"
          v-model="form.phone"
          :error-messages="errors.phone"
          label="Numéro de téléphone"
          hide-details
        />
        <div class="tw-flex tw-justify-center tw-pb-12 md:tw-pb-20 tw-mt-6">
          <PrimaryButtonComponent
            :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important`,
            }"
            :loading="isSendingForm"
            :disabled="!formValid || isSendingForm"
            type="submit"
          >Envoyer</PrimaryButtonComponent>
        </div>
      </v-form>
      <p class="tw-font-light tw-text-xs !tw-text-center" style="width: 100%; max-width: 1400px; padding: 0 1rem;">
        Les informations à caractère personnel recueillies dans le cadre de
        votre demande de souscription à la plateforme Viqtor® sont
        enregistrées dans un fichier informatisé par la société DBWO pour
        permettre la finalisation de l’inscription de votre organisation à nos
        services. Elles seront conservées jusqu’à la validation de la
        souscription, période durant laquelle elles pourront être
        régulièrement mises à jour à votre demande. Hors demande de votre
        part, elles seront détruites en cas de non-finalisation de votre
        demande sous un délai de 2 mois après cet envoi. Ces données sont
        destinées au personnel administratif de DBWO et conservées sur les
        serveurs de l’entreprise OVH qui héberge le site Viqtor® sur le
        territoire français. Conformément à la loi « informatique et libertés
        », vous pouvez exercer votre droit d'accès aux données vous concernant
        et les faire rectifier en contactant : dpo@dbwo.legal.
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/utils/validation-rules";
import RadioButtonComponent from "@/components/common/RadioButtonComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import { storePublicQuiz } from "@/features/company/services/appApi";

export default {
  name: "GDPRPublicQuizSignup",
  components: {
    TextFieldComponent,
    RadioButtonComponent,
    PrimaryButtonComponent,
  },
  data() {
    return {
      loading_qst: false,
      formValid: false,
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
      ],
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        phone: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        socialReason: "",
        phone: "",
      },
      isSendingForm: false,
    }
  },
  computed: {
    ...mapGetters(["getPublicQuizChapters"]),
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      const data = {
        quizForm: { chapters: this.getPublicQuizChapters },
        signupForm: this.form,
      };
      storePublicQuiz(data)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          window.scrollTo(0, 0);
          this.$store.commit("SET_PUBLIC_QUIZ_CHAPTERS", []);
          this.$router.push({ name: "GdprPublicQuiz.success" });
        })
        .catch(({ response }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          if (response.status === 400) {
            response.data.message.forEach((err) => {
              if (err === "User avec le même 'email' existe déjà") {
                this.$router.push({
                  name: "DoubleEmailOrSiren",
                  params: {
                    errorType: "email",
                  },
                });
              }
            });
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                position: "bottom right",
                text: item,
              });
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: la requete a échoué",
              type: "error",
            });
          }
          this.$refs.form.validate();
        });
    },
    onGenderChange(value) {
      this.form.gender = value;
    },
  },
}
</script>