<template>
  <div id="Questionnaire-detail">
    <div class="tw-flex tw-items-center tw-justify-between">
      <EntrepriseHeaderBlockTextComponent
        title="Détails du questionnaire"
        description="Retrouvez ici les réponses du questionnaire."
      />
      <div class="tw-flex tw-items-center tw-justify-between">
        <v-btn
          :loading="downoald_loading"
          elevation="1"
          text
          rounded
          large
          @click="telecharger()"
          class="tw-text-white"
        >
          Télécharger
        </v-btn>
<!--        <v-btn-->
<!--          elevation="1"-->
<!--          text-->
<!--          rounded-->
<!--          large-->
<!--          @click="imprimer()"-->
<!--          class="tw-text-white"-->
<!--        >-->
<!--          Imprimer-->
<!--        </v-btn>-->
      </div>
    </div>
    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-w-full marginTop" id="table">
        <template v-for="(item, key) in blueprint.quizChapters">
          <ChapterComponent
              :key="item.id"
              :index="key"
              :chaptre="item"
              :readonly="true"
              :disabled="false"
          ></ChapterComponent>
        </template>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-pb-12">
        <v-btn class="tw-ml-0" elevation="1" text rounded large @click="Retour()"> Retour </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import ChapterComponent from "@/features/company/components/Questionnaire/ChapterReponseComponent";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import jsPDF from "jspdf"
import "jspdf-autotable";
import {getScoreQuizSubContractors} from "@/features/subcontractor/services/appApi";
export default {
  name: 'ReponsesQuestionnaireSubContractorPage',
  computed: {},
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChapterComponent,
  },
  props: ['subContractor'],
  data() {
    return {
      downoald_loading: false,
      loading: false,
      blueprint: [],
      selectChapitre: null,
    };
  },
  mounted() {
    if (!this.subContractor){
      this.$router.push({ name: 'SubContractor.EvaluatedSubContractors' });
    } else {
      this.fetchData();
    }

  },
  methods: {
    fetchData() {
      this.loading = true;
      getScoreQuizSubContractors(this.subContractor).then((response) => {
        this.blueprint = response.data.data;
      })
      .catch((err) => {
        this.$snackbar.showAlertMessage({message: err.response.data.message, type: "error"});
      })
      .finally(() => {
        this.loading = false;
      });
    },
    handleChapitre(id) {
      if (this.selectChapitre === id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    Retour() {
      this.$router.push({ name: 'SubContractorProfile',
        params: { subContractor: this.subContractor } });
    },
    imprimer() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire",
      });
    },
    telecharger() {
      this.downoald_loading = true;
      let doc = new jsPDF();
      // const width        = doc.internal.pageSize.getWidth();
      // const height       = doc.internal.pageSize.getHeight();
      const h3_font_size = 10;

      doc.addImage("/images/logo.png", "JPEG", 75, 7, 60, 20);

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      doc.text("Résultat du questionnaire",75, 40);

      this.blueprint.quizChapters.forEach((row, index) => {
        let quiz_data = [];

        row.quizQuestions.forEach((item, indexJ) => {
          quiz_data.push([
            {
              content: `${index + 1}.${indexJ + 1} )  ${item.question.label}`,
              styles: { halign: "left" }
            },
            {
              content: `${item.responses[0] ? item.responses[0].label : ''}`,
              styles: { halign: "center", valign: "middle", cellWidth: 45, fontSize: 12 }
            },
          ]);

        });
        let color = [];
        if (row.status === 'A') {
          color = [85, 140, 102];
        } else if (row.status === 'B') {
          color = [212, 151, 37];
        } else {
          color = [187, 69, 76];
        }

        if(quiz_data.length > 0){
          doc.setFontSize(h3_font_size);
          doc.autoTable({
            theme: 'plain',
            styles: { lineColor: [0, 0, 0], lineWidth: 0.3 },
            margin: { top: 60, left: 22,right: 22 },
            head: [[`${index + 1}. ${row.chapter.label}`, `${row.scorePercent} %`]],
            headStyles: {
              fillColor: color,
              textColor: [255, 255, 255],
              halign: "center",
              valign: "middle"
            },
            body: quiz_data,
          });
        }
      });

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0);
      doc.text(`Total : `,22,240);
      if (this.blueprint.status=== 'OK_GO') {
        doc.setTextColor(85,140,102);
      }
      else if (this.blueprint.status=== 'GO_SR_A' || this.blueprint.status=== 'GO_SR_B') {
        doc.setTextColor(212,151,37);
      }
      else {
        doc.setTextColor(187,69,76);
      }
      doc.text(`${this.blueprint.scorePercent ? this.blueprint.scorePercent.toFixed() : '0'} / 100`,42,240);


      doc.save("quiz.pdf");

      this.downoald_loading = false;
    },
  },
};
</script>
<style scoped></style>
