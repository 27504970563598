<template>
  <div
    class="tw-flex tw-justify-start tw-items-center tw-space-x-1"
    v-if="length > 1"
  >
    <div class="tw-flex tw-justify-between tw-space-x-2">
      <div
        class="tw-cursor-pointer tw-flex tw-items-center paginate"
        :class="{ 'color-gray': page <= 1 }"
        @click="page > 1 && changePage(1)"
      >
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.79485 9.53048C4.87576 9.53048 4.95855 9.49851 5.02064 9.43643C5.14481 9.31225 5.14481 9.10905 5.02064 8.98487L0.770452 4.73279L4.95855 0.544693C5.08273 0.42051 5.08273 0.217304 4.95855 0.0931305C4.83438 -0.0310435 4.63118 -0.0310435 4.50701 0.0931305L0.0931306 4.50701C-0.0310435 4.63118 -0.0310435 4.83438 0.0931306 4.95855L4.56909 9.4364C4.63118 9.49848 4.71396 9.53047 4.79486 9.53047L4.79485 9.53048Z" :fill="page <= 1?'#b3b3b3':referer.theme.ctaBgColor"/>
        <path d="M8.79485 9.53048C8.87576 9.53048 8.95855 9.49851 9.02064 9.43643C9.14481 9.31225 9.14481 9.10905 9.02064 8.98487L4.77045 4.73279L8.95855 0.544693C9.08273 0.42051 9.08273 0.217304 8.95855 0.0931305C8.83438 -0.0310435 8.63118 -0.0310435 8.50701 0.0931305L4.09313 4.50701C3.96896 4.63118 3.96896 4.83438 4.09313 4.95855L8.56909 9.4364C8.63118 9.49848 8.71396 9.53047 8.79486 9.53047L8.79485 9.53048Z" :fill="page <= 1?'#b3b3b3':referer.theme.ctaBgColor"/>
      </svg>

      </div>
      <div
        class="tw-cursor-pointer tw-flex tw-items-center paginate"
        :class="{ 'color-gray': page <= 1 }"
        @click="page > 1 && changePage(page - 1)"
      >
      <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.79485 9.53048C4.87576 9.53048 4.95855 9.49851 5.02064 9.43643C5.14481 9.31225 5.14481 9.10905 5.02064 8.98487L0.770452 4.73279L4.95855 0.544693C5.08273 0.42051 5.08273 0.217304 4.95855 0.0931305C4.83438 -0.0310435 4.63118 -0.0310435 4.50701 0.0931305L0.0931306 4.50701C-0.0310435 4.63118 -0.0310435 4.83438 0.0931306 4.95855L4.56909 9.4364C4.63118 9.49848 4.71396 9.53047 4.79486 9.53047L4.79485 9.53048Z"
            :fill="page <= 1?'#b3b3b3':referer.theme.ctaBgColor"
          />
        </svg>
      </div>
      <span
        class="tw-cursor-pointer paginate"
        :class="{ 'color-gray': page == 1 }"
        @click="changePage(1)"
        >{{ "01" }}</span
      >
      <span v-if="page >= 4">...</span>
      <span
        v-if="page > 2"
        @click="changePage(page - 1)"
        class="tw-cursor-pointer paginate"
      >
        <span v-if="page - 1 < 10">{{ `0${page - 1}` }}</span>
        <span v-else>{{ page - 1 }}</span>
      </span>
      <span
        class="tw-cursor-pointer paginate"
        v-if="page >= 2 && page < length"
        @click="changePage(page)"
        :class="{ 'color-gray': page != length }"
      >
        <span v-if="page < 10">{{ `0${page}` }}</span>
        <span v-else>{{ page }}</span>
      </span>
      <span
        v-if="page >= 1 && page + 1 < length"
        @click="changePage(page + 1)"
        class="tw-cursor-pointer paginate"
      >
        <span v-if="page + 1 < 10">{{ `0${page + 1}` }}</span>
        <span v-else>{{ page + 1 }}</span>
      </span>

      <span v-if="page <= length - 2">...</span>
      <span
        class="tw-cursor-pointer paginate"
        @click="changePage(length)"
        :class="{ 'color-gray': page == length }"
        >{{ length > 9 ? length : `0${length}` }}</span
      >
      <div
        class="tw-cursor-pointer paginate tw-flex tw-items-center"
        :class="{ 'color-gray': page >= length }"
        @click="page < length && changePage(page + 1)"
      >
      <svg
          width="5"
          height="10"
          viewBox="0 0 5 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.304726 9.53048C0.227422 9.53048 0.148311 9.49851 0.0889866 9.43643C-0.0296622 9.31225 -0.0296622 9.10905 0.0889866 8.98487L4.15006 4.73279L0.148311 0.544693C0.0296623 0.42051 0.0296623 0.217304 0.148311 0.0931305C0.26696 -0.0310435 0.461115 -0.0310435 0.579764 0.0931305L4.79724 4.50701C4.91589 4.63118 4.91589 4.83438 4.79724 4.95855L0.520439 9.4364C0.461115 9.49848 0.382022 9.53047 0.304718 9.53047L0.304726 9.53048Z"
            :fill="page >= length?'#b3b3b3':referer.theme.ctaBgColor"
          />
        </svg>
      </div>
      <div
        class="tw-cursor-pointer paginate tw-flex tw-items-center"
        :class="{ 'color-gray': page >= length }"
        @click="page < length && changePage(length)"
      >
      <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.2111 9.53048C4.13222 9.53048 4.05149 9.49851 3.99095 9.43643C3.86988 9.31225 3.86988 9.10905 3.99095 8.98487L8.13503 4.73279L4.05149 0.544693C3.93042 0.42051 3.93042 0.217304 4.05149 0.0931305C4.17256 -0.0310435 4.37069 -0.0310435 4.49176 0.0931305L8.79544 4.50701C8.91651 4.63118 8.91651 4.83438 8.79544 4.95855L4.43122 9.4364C4.37069 9.49848 4.28998 9.53047 4.21109 9.53047L4.2111 9.53048Z" :fill="page >= length?'#b3b3b3':referer.theme.ctaBgColor"/>
        <path d="M0.310955 9.53048C0.23207 9.53048 0.151342 9.49851 0.0908053 9.43643C-0.0302684 9.31225 -0.0302684 9.10905 0.0908053 8.98487L4.23488 4.73279L0.151342 0.544693C0.0302685 0.42051 0.0302685 0.217304 0.151342 0.0931305C0.272416 -0.0310435 0.470539 -0.0310435 0.591613 0.0931305L4.89529 4.50701C5.01637 4.63118 5.01637 4.83438 4.89529 4.95855L0.531076 9.4364C0.470539 9.49848 0.38983 9.53047 0.310945 9.53047L0.310955 9.53048Z" :fill="page >= length?'#b3b3b3':referer.theme.ctaBgColor"/>
      </svg>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    length: Number,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style scoped>
.label {
  top: -15px;
}
.paginate {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid var(--cta-bg-color);
  color: var(--cta-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.color-gray {
  color: #b3b3b3 !important;
}
</style>
