import ApiService from "@/services/api.service";

// signUp SubContractors
export const getDataFromToken = (token) => {
  return ApiService.get(`auth/signup/second-step/${token}`);
};

export const secondStepSignUpApi = (token, data) => {
  return ApiService.post(`subcontractor/signup/second-step/${token}`, {
    tradeName: data.tradeName,
    corporateOfficer: data.corporateOfficer,
    address: data.address,
    socialReason: data.socialReason,
    sirenOrRna: data.sirenOrRna,
    legalStatus: data.legalStatus,
    nbrEmployees: data.nbrEmployees,
    service: data.service,
    role: data.role,
    password: data.password,
    phone: data.phone,
  });
};

export const sendTestInvitation = (data) => {
  return ApiService.post(`subcontractor/test-invitation`, data);
};

//Dashboard
export const sendManyInvitation = (data) => {
  return ApiService.post(`subcontractor/invite-many`, data);
};
export const sendManyWatingInvitation = (data) => {
  return ApiService.post(`subcontractor/invite-waiting`, data);
};

export const resendInvitation = (data) => {
  return ApiService.post(`subcontractor/resend-invitation`, data);
};

// Subconrtacttors
export const getAllSubContractors = (url) => {
  return ApiService.get(`${url}`);
};

export const getEvaluatedSubContractors = (url) => {
  return ApiService.get(`${url}`);
};

export const getNotEvaluatedSubContractors = (url) => {
  return ApiService.get(`${url}`);
};

export const getProgressedSubContractors = (url) => {
  return ApiService.get(`${url}`);
};

export const getAttendedSubContractors = (query) => {
  return ApiService.get(`subcontractor/waiting?${query}`);
};

export const getQuizSubContractors = (id) => {
  return ApiService.get(`quiz/${id}`);
};

export const getScoreQuizSubContractors = (id) => {
  return ApiService.get(`quiz/${id}/detail`);
};


