<template>
  <v-snackbar timeout="2000" right bottom v-model="show" :color="color">
    <span v-html="message"></span>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarComponent",
  data() {
    return {
      
      show: false,
      message: "",
      type: "error", // error, info, success
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showAlertMessage") {
        this.message = state.snackbar.message;
        this.type = state.snackbar.type;
        this.show = true;
      }
    });
  },
  computed: {
    color: function () {
      switch (this.type) {
        case "error":
          return "red";
        case "success":
          return "green";
        case "info":
          return "info";
        default:
          return this.$store.state.referrer.theme.ctaBgColor;
      }
    },
  },
};
</script>

<style scoped></style>
