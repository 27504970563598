<template>
  <div class="tw-w-full tw-px-5">
    <div class="company-select-box">
      <div class="tw-relative">
      </div>
      <div data-app>
        <v-select 
          v-bind="$attrs" 
          v-on="$listeners" 
          :items="answers" 
          label="Sélectionner" 
          item-text="label"
          item-value="id" 
          persistent-hint 
          single-line 
          append-icon="mdi-chevron-down" 
          @change="changeHandler"
          v-model="answerId" 
          full-width
          block
        >
          <template v-slot:item="{ item }">
            <div class="tw-text-black-100 tw-font-bold tw-pl-2">
              {{ item.label }}
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectQuestionnaireComponent",
  props: {
    answers: {
      type: Array,
      required: true,
    },
    selectedAnswerId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      answerId: 0,
    }
  },
  mounted() {
    this.answerId = this.selectedAnswerId;
  },
  watch: {
    selectedAnswerId: {
      immediate: true,
      handler(value) {
        this.answerId = value;
      }
    }
  },
  methods: {
    changeHandler() {
      this.$emit("answered", this.answerId);
    }
  },
}
</script>