<template>
  <v-container class="overflow-auto px-12">
    <Breadcrumb divider=">"></Breadcrumb>

    <v-row no-gutters class="mb-12 mt-4">
      <v-col cols="12">
        <h1
          class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"
        >
          Vérifiez votre importation !
        </h1>
        <h5 class="tw-tracking-tight tw-text-base caption">
          Certaines informations peuvent nécessiter une vérification.
        </h5>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="my-4" cols="12">
        <h1
          class="my-4 tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"
        >
          Sous-traitants qui méritent votre attention :
        </h1>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Raison sociale</th>
                <th>Contact</th>
                <th>Remarques</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in subcontractorsInvalid" :key="item.name">
                <td>{{ item[0].target.socialReason }}</td>
                <td>
                  {{ item[0].target.firstName + " " + item[0].target.lastName }}
                </td>
                <td>
                  <span
                    class="green--text text--lighten-1"
                    v-html="getErrorMessage(item)"
                  ></span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col class="my-4" cols="12">
        <h1
          class="my-4 tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"
        >
          Sous-traitants importés avec succès :
        </h1>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Raison sociale</th>
                <th>Contact</th>
                <th>SIREN</th>
                <th>Suppression</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in subcontractorsValid" :key="item.$key">
                <td>{{ item.socialReason }}</td>
                <td>{{ item.firstName + " " + item.lastName }}</td>
                <td>{{ item.sirenOrRna }}</td>
                <td
                  @click="supprimerSubcontractorValid(item)"
                  class="tw-cursor-pointer"
                >
                  <v-icon>mdi-close</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col class="my-4 d-flex justify-end" cols="12">
        <PrimaryButtonComponent
          class="export-btn"
          @click="continuer"
          :loading="loading"
          :disabled="subcontractorsValid.length < 1"
          >Continuer</PrimaryButtonComponent
        >
      </v-col>
    </v-row>

    <SubcontractorsLoaderModal
      :count-all="countTotalSubcontractors"
      :count-finished="countFinishedSubcontractors"
      :action="action"
      v-model="importSubcontractorsModal"
      :error="importationError"
      :is-loading="subscribing"
      :log="log"
      :on-close="onCloseImportModal"
    />
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import SubcontractorsLoaderModal from "../components/SubcontractorsLoaderModal";
import {
  bindEventPusher,
  onChannelSubscriptionError,
  onChannelSubscriptionSucceeded,
  subscribeToChannel,
  unsubscribeFromChannelPusher,
} from "../services/pusherService";
import ApiService from "../../../services/api.service";

export default {
  name: "SubContractorImport",

  computed: {
    subcontractorsValid() {
      return this.$store.getters.getSubcontractorsValid;
    },
    subcontractorsInvalid() {
      return this.$store.getters.getSubcontractorsInvalid;
    },
  },

  data() {
    return {
      loading: false,
      importSubcontractorsModal: false,
      countTotalSubcontractors: 0,
      countFinishedSubcontractors: 0,
      invalidData: 0,
      importationError: "",
      subscribing: true,
      log: "",
      // validation or importation
      action: "validation",
      validationMap: [
        {
          field: "email",
          messages: ["match-existent-subcontractor"],
          color: "#558C66", //green
        },
        {
          field: "email",
          messages: ["isEmail"],
          color: "#D49725", //yellow
        },

        {
          field: "sirenOrRna",
          messages: ["siren"],
          color: "#D49725", //yellow
        },
        {
          field: "sirenOrRna",
          messages: ["match-existent-subcontractor"],
          color: "#558C66", //green
        },
        {
          field: "sirenOrRna",
          messages: ["match-existent-subcontractor"],
          color: "#D49725",
        },
        {
          field: "email",
          messages: ["match-existent-subcontractor"],
          color: "#D49725",
        },
      ],
    };
  },
  components: {
    SubcontractorsLoaderModal,
    Breadcrumb,
    PrimaryButtonComponent,
  },
  mounted() {
    const channelName = `importation-${this.$store.state.user.id}`;
    const channel = subscribeToChannel(channelName);

    if(this.$route.params.formData){     

    onChannelSubscriptionSucceeded(channel, () => {
      console.log("subscription succeeded", channel);
      this.importSubcontractorsModal = true;
      this.uploadFile(this.$route.params.formData);
    });

    onChannelSubscriptionError(channel, (error) => {
      this.subscribing = false;
      this.importSubcontractorsModal = false;
      this.$snackbar.showAlertMessage({
        message: "Connexion a echoué",
        type: "error",
      });
      console.log("subscription failed", error);
    });

    bindEventPusher(channel, "error", (error) => {
      unsubscribeFromChannelPusher(channelName);
      this.$snackbar.showAlertMessage({
        message: error.message,
        type: "error",
      });
      this.importationError = error.message;
      this.subscribing = false;
      console.log("error when importing subcontractors");
    });

    /*

    validation events

    */
    bindEventPusher(channel, "start_validation", (data) => {
      this.importSubcontractorsModal = true;
      this.countTotalSubcontractors = data.total;
      this.countFinishedSubcontractors = 0;
      this.action = "validation";
      this.subscribing = false;
      console.log("start_validation");
    });

    bindEventPusher(channel, "valid_subcontractor", ({ data }) => {
      console.log("valid_subcontractor", data);
      this.$store.dispatch("loadSubcontractorValid", [
        ...this.subcontractorsValid,
        data,
      ]);
      this.countFinishedSubcontractors++;
      this.log = `${data.sirenOrRna}, ${data.firstName} ${data.lastName}: Réussi`;
    });

    bindEventPusher(channel, "invalid_subcontractor", ({ data }) => {
      console.log("invalid_subcontractor", data);
      this.$store.dispatch("loadSubcontractorInvalid", [
        ...this.subcontractorsInvalid,
        data,
      ]);
      this.countFinishedSubcontractors++;
      this.invalidData++;
      this.log = `${data[0].target.sirenOrRna}, ${data[0].target.firstName} ${data[0].target.lastName}: Echoué`;
    });

    bindEventPusher(channel, "finish_validation", (data) => {
      unsubscribeFromChannelPusher(channelName);
      console.log("finish_validation", data);
      if (this.invalidData>0) {
        this.$snackbar.showAlertMessage({
          message: "Certains sous-traitant n'ont pas été ajoutés",
          type: "error",
        });
      }
    });
  }
  },
  methods: {
    onCloseImportModal() {
      this.importSubcontractorsModal = false;
    },
    continuer() {
      this.$router.push({
        name: "SubContractorInvitation",
        params: { type: "many" },
      });
    },
    supprimerSubcontractorValid(item) {
      this.$store.dispatch("supprimerSubcontractorValid", item);
    },
    getErrorMessage(item) {
      let errorField = this.validationMap.filter(
        (validation) => validation.field == item[0].property
      );
      if (errorField) {
        let message = "";
        errorField.forEach((field) => {
          if (item[0].constraints[field.messages[0]] !== undefined) {
            message =
              "<span style='color:" +
              field.color +
              "'>" +
              item[0].constraints[field.messages[0]] +
              "</span>";
          }
        });
        return message;
      }
      return null;
    },
    uploadFile(file) {
      this.loading = true;

      ApiService.post("subcontractor/upload", file)
        .catch(() => {
          this.subscribing = false;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss">
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.download-model-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: auto !important;
  margin: 0 12px;
  background: #fff !important;
  color: #6c5dd3 !important;
  border: 2px solid #6c5dd3 !important;
}

.upload-container {
  border-radius: 10px;
  border: 2px dashed #000;
  padding: 16px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  .v-icon {
    color: black !important;
    font-size: 45px !important;
  }

  > p {
    font-size: 16px;
  }

  .import-btn {
    height: 45px !important;
    font-weight: bold !important;
    font-size: 12px !important;
    background: #fff !important;
    color: #000 !important;
    border: 2px solid #000 !important;
    width: 100px !important;
    margin: 0 auto;
  }
}
</style>
