<template>
  <div>
    <Breadcrumb divider=">"></Breadcrumb>
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";

export default {
  name: "ProfileContainer",
  components: {
    Breadcrumb,
  },
};
</script>
