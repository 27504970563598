<template>
  <router-view class="subcontractor-content"></router-view>
</template>

<script>
export default {
  name: "Company",
  
  methods: {
    showMenu() {
      this.$el.querySelector("#sidebar").classList.toggle("tw-hidden");
    },
  },
};
</script>
