<template>
    <div>
        <div class="tw-m-28">
            <div class="tw-w-3/4 tw-mx-6">
                Avant de répondre aux quelques questions que comporte le questionnaire, <br />
                merci de vérifier et de compléter les données professionnelles qui vous concernent.
            </div>
            <div class="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-my-10 tw-gap-8 questionnaire">
                <div class="">
                    <TextFieldComponent v-model="user.lastName" class="search-input"
                        :class="{ 'error-field': errors.lastName }" label="Nom" />
                    <span class="tw-text-xs tw-pl-4 tw-text-red-500">{{ errors.lastName ? errors.lastName[0].replace( "lastName","nom") : null }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.firstName" class="search-input"
                        :class="{ 'error-field': errors.firstName }" label="Prénom" />
                    <span
                        class="tw-text-xs tw-pl-4 tw-text-red-500">{{ errors.firstName ? errors.firstName[0].replace( "firstName","prénom") : null }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.fonction" class="search-input"
                        :class="{ 'error-field': errors.fonction }" label="Fonction" />
                    <span class="tw-text-xs tw-pl-4 tw-text-red-500">{{
                        errors.fonction ? errors.fonction[0] : null
                    }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.phoneNumber" class="search-input"
                        :class="{ 'error-field': errors.phoneNumber }" label="Téléphone fixe entreprise" />
                    <span class="tw-text-xs tw-pl-4 tw-text-red-500">{{
                        errors.phoneNumber ? errors.phoneNumber[0] :
                            null
                    }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.mobileNumberProfessional" class="search-input"
                        :class="{ 'error-field': errors.mobileNumberProfessional }"
                        label="Téléphone mobile professionnel" />
                    <span class="tw-text-xs tw-pl-4 tw-text-red-500">{{
                        errors.mobileNumberProfessional ?
                            errors.mobileNumberProfessional[0] : null
                    }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.emailProfessional" class="search-input"
                        :class="{ 'error-field': errors.emailProfessional }" label="Adresse e-mail professionnelle" />
                    <span
                        class="tw-text-xs tw-pl-4 tw-text-red-500">{{ errors.emailProfessional ? errors.emailProfessional[0].replace('emailProfessional','email professionnelle') : null }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.service" class="search-input"
                        :class="{ 'error-field': errors.service }" label="Service" />
                    <span class="tw-text-xs tw-pl-4 tw-text-red-500">{{
                        errors.service ?
                            errors.service[0] : null
                    }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.superiorLastName" class="search-input"
                        :class="{ 'error-field': errors.superiorLastName }"
                        label="Nom de votre supérieur(e) hiérarchique" />
                    <span
                        class="tw-text-xs tw-pl-4 tw-text-red-500">{{ errors.superiorLastName ? errors.superiorLastName[0].replace('superiorLastName','nom de votre supérieur(e) hiérarchique') : null }}</span>
                </div>
                <div>
                    <TextFieldComponent v-model="user.superiorFirstName" class="search-input"
                        :class="{ 'error-field': errors.superiorFirstName }"
                        label="Prénom de votre supérieur(e) hiérarchique" />
                    <span
                        class="tw-text-xs tw-pl-4 tw-text-red-500">{{ errors.superiorFirstName ? errors.superiorFirstName[0].replace('superiorFirstName','prénom de votre supérieur(e) hiérarchique') : null }}</span>
                </div>
            </div>
        </div>
        <div class="tw-mb-6 tw-flex tw-justify-end tw-px-16">
            <primary-button-component class="tw-text-white tw-outline tw-outline-2 tw-outline-white tw-bg-transparent"
                :style="{
                    backgroundColor: `${referrer.theme.ctaBgColor} !important`,
                    color: `${referrer.theme.ctaTextColor}  !important`
                }" @click="nextStep()">Suivant &#8594;
            </primary-button-component>
        </div>
    </div>
</template>

<script>
import TextFieldComponent from '@/components/common/TextFieldComponent'
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { storeQuestionnaireCollaborator } from "@/features/company/services/appApi";
export default {
    props: ['user'],
    components: {
        TextFieldComponent,
        PrimaryButtonComponent
    },
    data() {
        return {
            errors: {}
        }
    },
    computed: {
        referrer() {
            return this.$store.state.referrer;
        },
    },
    methods: {
        nextStep() {
            const object = this.user


            for (var key in object) {
                if (key in object) {
                    var value = object[key];
                    if (value === null || value === undefined || value === '') {
                        delete object[key];
                    }
                }
            }
            storeQuestionnaireCollaborator(this.$route.params.token, object)
                .then(() => {
                    this.$parent.index++
                    this.errors = {}
                })
                .catch(({ response }) => {
                    if (response.status == 400) {
                        this.errors = response.data.errors
                    }
                }

                )
        },
    }
}
</script>

<style lang="scss">
.error-field {
    .v-input {
        input {
            border: 1px solid red !important;
            border-radius: 20px !important;
        }

        label {
            color: red !important;
        }
    }
}
</style>
