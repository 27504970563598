<template>
  <div>
    <template v-if="isLoading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div>
        <EntrepriseHeaderBlockTextComponent
          title="Mes moyens de paiement"
          description=""
        />
      </div>

      <a style="width: fit-content" class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="$router.push({ name: 'Company.Profile.Invoices' })">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
        </svg>
        <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
      </a>

      <div class="tw-mt-12 tw-py-11 tw-pl-11" style="background-color: #FAFAFA;">
        <span class="tw-font-medium tw-text-sm" style="color: #616161;">Carte bleue</span>

        <div class="tw-mt-6 tw-mr-4 tw-p-4 tw-rounded-xl" id="card-element" style="border: 0.5px solid rgb(50, 50, 93, .5);"></div>
        <div class="tw-mt-4 tw-text-sm tw-font-medium" style="color: #fa755a;" id="card-result"></div>
        <div class="tw-mt-4 tw-flex tw-justify-center">
          <v-btn 
            id="card-button"
            elevation="0" 
            class="tw-text-white"
            :style="{ background: referer.theme.ctaBgColor, padding: '.5rem 3rem' }"
            @click="addCardHandler"
            :loading="isAddingCard"
          >
            Ajouter
          </v-btn>
        </div>
      </div>

      <div class="tw-mt-12 tw-py-11 tw-pl-11" style="background-color: #FAFAFA;">
        <span class="tw-font-medium tw-text-sm" style="color: #616161;">IBAN</span>

        <div class="tw-mt-6 tw-mr-4 tw-p-4 tw-rounded-xl" id="sepa-element" style="border: 0.5px solid rgb(50, 50, 93, .5);"></div>
        <div class="tw-mt-4 tw-text-sm tw-font-medium" style="color: #fa755a;" id="sepa-result"></div>
        <div class="tw-mt-4 tw-flex tw-justify-center">
          <v-btn 
            id="sepa-button"
            elevation="0"
            class="tw-text-white"
            :style="{ background: referer.theme.ctaBgColor, padding: '.5rem 3rem' }"
            @click="addSepaHandler"
            :loading="isAddingSepa"
          >
            Ajouter
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { loadStripe } from '@stripe/stripe-js';
import { attachPaymentMethod, getStripePublicKey } from "../../../services/appApi";

export default {
  name: 'ProfileAddPaymentMethodPage',
  components: { EntrepriseHeaderBlockTextComponent },
  data() {
    return {
      isLoading: true,
      stripe: null,
      cardElement: null,
      sepaElement: null,
      isAddingCard: false,
      isAddingSepa: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    }
  },
  async mounted() {
    await this.setStripePublicKey();
    const elements = this.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    };

    this.cardElement = elements.create('card', { style });
    this.sepaElement = elements.create('iban', {
      supportedCountries: ['SEPA'],
      placeholderCountry: 'FR',
      style
    });
    this.isLoading = false;

    setTimeout(() => { 
      this.cardElement.mount('#card-element');
      this.sepaElement.mount('#sepa-element');
    }, 1000);
  },
  methods: {
    async setStripePublicKey() {
      try {
        const { data } = await getStripePublicKey();
        this.stripe = await loadStripe(data);
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la récupération de la clé publique Stripe",
          type: "error",
        });
      }
    },
    async addCardHandler() {
      this.isAddingCard = true;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      })

      if (!!paymentMethod && !!paymentMethod.id) {
        try {
          await attachPaymentMethod({ paymentMethodId: paymentMethod.id });
          this.$router.push({ name: 'Company.Profile.Invoices' });
        }
        catch(error) { 
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de l'ajout de la methode de paiement",
            type: "error",
          });
        }
      }
      
      if (error) document.getElementById('card-result').textContent = error.message;
      else document.getElementById('card-result').textContent = "Carte ajoutée avec succès";

      this.isAddingCard = false;
    },
    async addSepaHandler() {
      this.isAddingSepa = true;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: this.sepaElement,
        billing_details: {
          name: this.user.firstName + ' ' + this.user.lastName,
          email: this.user.email,
        },
      })

      if (!!paymentMethod && !!paymentMethod.id) {
        try {
          await attachPaymentMethod({ paymentMethodId: paymentMethod.id });
          this.$router.push({ name: 'Company.Profile.Invoices' });
        }
        catch(error) { 
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de l'ajout de la methode de paiement",
            type: "error",
          });
        }
      }
      
      if (error) document.getElementById('sepa-result').textContent = error.message;
      else document.getElementById('sepa-result').textContent = "IBAN ajouté avec succès";

      this.isAddingSepa = false;
    }
  }
}
</script>