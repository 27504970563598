import { render, staticRenderFns } from "./TeamItem.vue?vue&type=template&id=01ee24ee&scoped=true&"
import script from "./TeamItem.vue?vue&type=script&lang=js&"
export * from "./TeamItem.vue?vue&type=script&lang=js&"
import style0 from "./TeamItem.vue?vue&type=style&index=0&id=01ee24ee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01ee24ee",
  null
  
)

export default component.exports