<template>
  <div class="auth-select-box">
    <div class="tw-relative" v-if="!noSingleLine">
      <div style="font-size: 12px" class="tw-text-black-100 tw-absolute label">{{ label }}</div>
    </div>
    <div data-app>
      <v-select
        v-bind="$attrs"
        v-on="$listeners"
        :items="items"
        :label="noSingleLine ? label : 'Sélectionner'"
        item-text="label"
        item-value="value"
        persistent-hint
        :single-line="!noSingleLine"
        append-icon="mdi-chevron-down"
      >
        <template v-slot:item="{ item }">
          <div class="tw-text-black-100 tw-font-bold tw-pl-2" :class="itemLabelsClasses">
            {{ item.label }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectBoxComponent",
  props: {
    label: String,
    items: Array,
    noSingleLine: { type: Boolean, default: false },
    itemLabelsClasses: { type: String, default: "" },
  },
};
</script>

<style scoped>
.label {
  top: -15px;
}
</style>
