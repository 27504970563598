<template>
  <div id="landing">
    <v-container class="tw-pt-0 tw-mt-0">
      <FirstSection />
    </v-container>
    <SecondSection />
    <ThirdSection />
    <ResultSection />
    <SignSection />
    <ContactSection ref="ContactSection"/>
    <SubMenuSection />
    
  </div>
</template>

<script>
import FirstSection from "../components/FirstSection";
import SecondSection from "../components/SecondSection";
import ThirdSection from "../components/ThirdSection";
import ContactSection from "../components/ContactSection";
import SubMenuSection from "../components/SubMenuSection";
import ResultSection from "../components/ResultSection";
import SignSection from "../components/SignSection";
export default {
  name: "HomePage",
  data() {
    return {};
  },
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    ContactSection,
    SubMenuSection,
    ResultSection,
    SignSection,
  },
};
</script>
