<template>
  <div id="sous-traitants" class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-py-6 tw-px-12">
      <div class="tw-flex tw-items-center tw-justify-between">
        <img :src="logo" style="width: 170px;" alt="LOGO" />
        <span @click="logout" class="tw-cursor-pointer" :style="{ color: theme.ctaBgColor }">Se déconnecter</span>
      </div>

      <EntrepriseHeaderBlockTextComponent class="tw-mt-10" title="Back-office"
        description="Suivi des questionnaires RGPD." />

      <div class="tw-flex tw-items-center tw-justify-between">
        <search-text-field-component class="tw-mt-8" v-model="keyWord" label="Rechercher" append-icon="mdi-magnify"
          @change="search"/>

        <PrimaryButtonComponent
          :style="{
            backgroundColor: `${isExporting ? '#F4F4F4' : theme.ctaBgColor} !important`,
            color: `${isExporting ? 'black' : theme.ctaTextColor} !important`,
            borderColor: `${isExporting ? '#F4F4F4' : theme.ctaBgColor} !important`,
          }"
          :loading="isExporting"
          :disabled="isExporting"
        >
          <download-excel :data="exportData" :fields="jsonFields">Exporter</download-excel>
        </PrimaryButtonComponent>
      </div>

      <template v-if="loading">
        <content-placeholders class="">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
      </template>
      <template v-else>
        <v-simple-table class="tw-mt-12">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="table-sous-th tw-w-2/12">Raison sociale</th>
                <th class="table-sous-th tw-w-4/12">Contact</th>
                <th class="table-sous-th tw-w-3/12">Email</th>
                <th class="table-sous-th tw-w-1/12" style="text-align: center !important;">Score</th>
                <th class="table-sous-th tw-w-1/12" style="text-align: center !important;">Statut</th>
              </tr>
            </thead>
            <tbody v-if="clients.length > 0">
              <tr v-for="client in clients" :key="client.id">
                <td class="table-sous-td">{{ client.socialReason || '' }}
                </td>
                <td class="table-sous-td">{{ [client.firstname, client.lastname].join(' ') || '' }}</td>
                <td class="table-sous-td">{{ client.email || '' }}</td>
                <td class="table-sous-td tw-text-center" v-html="getScore(client)"></td>
                <td class="table-sous-td tw-text-right">{{ client.isCompleted ? 'Evalué' : 'En cours' }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-center">
                  Pas de données disponibles
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="currentPage !== lastPage && lastPage !== 0"
          class="tw-flex tw-flex-col tw-items-center tw-mt-4 tw-mb-4">
          <v-btn @click="loadMoreData(currentPage + 1)" class="mx-2" fab dark x-small
            :color="theme.ctaBgColor">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <span style="font-style: normal; font-weight: 600; font-size: 12px; line-height: 18px; text-align: center;"
            :style="{ color: `${theme.ctaBgColor}` }">Voir plus</span>
        </div>
      </template>
    </div>

    <Footer class="tw-w-full tw-mt-auto" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SearchTextFieldComponent from '@/components/common/SearchTextFieldComponent.vue';
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import Footer from "@/components/layout/Footer";
import { getClients } from "@/features/gpmse/services/api";

export default {
  name: "GdprQuizGpmseFollowup",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SearchTextFieldComponent,
    PrimaryButtonComponent,
    Footer,
  },
  data() {
    return {
      keyWord: '',
      currentPage: 1,
      lastPage: 1,
      loading: false,
      isExporting: false,
      clients: [],
      jsonFields: {
        "Raison sociale": 'socialReason',
        "Contact": 'contact',
        "Score": 'score',
        "Statut": 'statut',
      },
    }
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
    theme() {
      return this.$store.state.referrer.theme;
    },
    exportData() {
      return this.clients.map(client => {
        return {
          socialReason: client.socialReason,
          contact: [client.firstname, client.lastname].join(' '),
          score: client.scorePercent || '--',
          statut: client.isCompleted ? 'Evalué' : 'En cours',
        }
      });
    },
  },
  mounted() {
    this.loading = true;
    this.populateTable();
  },
  methods: {
    populateTable() {
      getClients({ page: this.currentPage, search: this.keyWord })
        .then(({ data: { items, meta } }) => {
          items.forEach(client => {
            this.clients.push({
              id: client.id,
              firstname: client.firstName,
              lastname: client.lastName,
              socialReason: client.company.socialReason,
              email: client.email,
              score: client.company.quiz ? client.company.quiz.status : null,
              scorePercent: client.company.quiz ? client.company.quiz.scorePercent : 0,
              isCompleted: !!client.company.quiz,
            })
          })
          this.currentPage = meta.currentPage;
          this.lastPage = meta.totalPages;
          this.loading = false;
        })
        .catch(_ => console.log(_))
    },
    getScore(client) {
      if (!client.isCompleted)
        return `<span class="v-chip v-size--default" style="background:#A0A0A0; color: #fff;"><span class="v-chip-text"><i aria-hidden="true" class="v-icon mdi mdi-clock-time-three-outline" style="font-size: 16px;"></i></span></span>`
      else if (client.score == 'OK_GO')
        return `<span class="v-chip v-size--default" style="background:#558C66; color: #fff;"><span class="v-chip-text">${client.scorePercent} / 100</span></span>`
      else if (client.score == 'GO_SR_A')
        return `<span class="v-chip v-size--default" style="background:#D49725; color: #fff;"><span class="v-chip-text">${client.scorePercent} / 100</span></span>`
      else if (client.score == 'GO_SR_B')
        return `<span class="v-chip v-size--default" style="background:#D49725; color: #fff;"><span class="v-chip-text">${client.scorePercent} / 100</span></span>`
      else
        return `<span class="v-chip v-size--default" style="background:#BB454C; color: #fff;"><span class="v-chip-text">${client.scorePercent} / 100</span></span>`
		},
    search() {
      this.clients = [];
      this.populateTable();
    },
    loadMoreData(page) {
      this.currentPage = page;
      this.populateTable();
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
  },
}
</script>