<template>
  <v-app class="tw-p-0 tw-m-0" id="questionnaire">
    <vue-page-transition name="fade-in-right" class="tw-h-full tw-flex tw-flex-col tw-justify-center">
        <router-view></router-view>
    </vue-page-transition>
  </v-app>
</template>

<script>
export default {
  name: "Questionnaire",

};
</script>
