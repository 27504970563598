<template>
  <v-container class="tw-pt-0 !tw-mt-0" id="pre-register-expert-page">
    <div class="auth-container tw-mt-10">
      <h1 class="tw-text-3xl tw-font-semibold">Vous avez complété la 1re étape d’inscription !</h1>

      <p class="tw-mt-12" style="line-height: 3rem;">
        Nous venons de vous envoyer un <b>email</b> vous invitant à <b>finaliser votre inscription.</b> <br /> 
        Important, regardez dans vos “courriels indésirables”, on ne sait jamais. <br />
        Dans le cas où l’email ne vous ait pas parvenu, cliquez sur “renvoyer”.
      </p>

      <div class="tw-mt-12 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-2">
        <v-btn
          text large rounded
          class="tw-text-white"
          :style="{
            'background-color': !isResent ? `${referer.theme.ctaBgColor} !important` : '#BDBDBD !important',
            color: `${referer.theme.ctaTextColor} !important`,
          }"
          :loading="isLoading"
          @click="resendHandler"
        >
          <span class="tw-px-4">Renvoyer</span>
        </v-btn>
        <span v-if="isResent" class="tw-text-sm">Email renvoyé !</span>
      </div>
    </div>
  </v-container>
</template>

<script>
import { repostPreRegistrationExpert, getUserDataByToken } from "@/features/auth/services/appApi";

export default {
  name: "PreRegisterConfirmExpertPage",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isResent: false,
      resendDelay: 10, // in minutes
      resendDelayCounter: 0, // in minutes
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getUserDataByToken(this.$route.params.token)
        .then(({ data }) => {
          const diff = new Date() - new Date(data.data.updatedAt);
          const createdDiff = new Date() - new Date(data.data.createdAt);

          if (
            createdDiff > 10000 &&
            diff < this.resendDelay * 1000 * 60
          ) {
            this.isResent = true;
            this.activateDelayCounter(this.resendDelay - Math.floor(diff / 1000 / 60));
          }
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            this.$router.replace({ name: "TokenExpired" });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: quelque chose s'est mal passé",
              type: "error",
            });
          }
        });
    },
    resendHandler() {
      if (this.isResent && this.resendDelayCounter) {
        this.$snackbar.showAlertMessage({
          message: `Veuillez patienter ${this.resendDelayCounter} minutes avant de renvoyer.`,
          type: "info",
        });
        return;
      }

      this.isLoading = true;

      repostPreRegistrationExpert(this.token)
        .then(({ data: { message, type } }) => {
          this.$snackbar.showAlertMessage({ message, type });

          if (type === "success") {
            this.isResent = true;
            this.activateDelayCounter();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue, veuillez réessayer plus tard.",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    activateDelayCounter(preset = 0) {
      setTimeout(
        () => { this.isResent = false; },
        (preset || this.resendDelay) * 1000 * 60
      );

      this.resendDelayCounter = preset || this.resendDelay;
      const interval = setInterval(() => {
        this.resendDelayCounter--;
        if (this.resendDelayCounter === 0) {
          clearInterval(interval);
        }
      }, 1000 * 60);
    },
  }
};
</script>