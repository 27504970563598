<template>
    <div class="tw-mt-16 tw-mb-5 tw-text-black-100">
        <div>
            <h4 class="tw-font-semibold tw-text-2xl tw-leading-10"> Mon premier registre de traitement</h4>
            <p class="tw-mt-4 tw-font-medium tw-leading-6">
                Le registre de traitement est destiné à recenser les traitements de données personnelles mis en œuvre dans votre 
                entreprise en tant que responsable de traitement. Centralisé et régulièrement mis à jour, il vous permet de répondre à 
                l’obligation de tenir un registre prévu par le RGPD.
            </p>
            <p class="tw-mt-4 tw-font-medium tw-leading-6">
                Comme vous avez déjà procédé à la cartographie de <b class="tw-underline">l’écosystème de la donnée</b> personnelle de personnes physiques 
                que traite votre entreprise,  il vous suffira de passer en revue le registre des activités et les collaborateurs respectifs qui 
                sont concernés par ces dernières.
            </p>
            <p class="tw-mt-6 tw-font-bold tw-leading-6">
                Ok, à vous de jouer, il y a 3 étapes, laissez vous simplement guider !
            </p>
        </div>

        <add-guide-component
            with-action
            cta-text="C’est le point le plus fastidieux, mais finalement ça reste simple, non ?"
            button-route="Register.SecondStep.Page"
            step-one-text="Vous allez passer en revue le registre des activités, dont les informations préremplies proviennent de la cartographie de votre écosystème de la donnée que vous avez effectuée. Il vous faut vérifier, modifier et/ou compléter les informations."
            step-two-text="Une fois le recensement des activités effectué, vous serez en mesure de faire procéder à l’analyse des traitements de des données personnelles. Cela leur prendra à peine 10 minutes. Au final, vous aurez un registre des activités exhaustif et précis."
            step-three-text="Un message prérédigé vous est proposé, vous pouvez le modifier comme vous le souhaitez, et l’envoyer en un clic à tous les collaborateurs concernés. Vous n’avez rien de plus à faire, vous en avez pour 5 minutes tout au plus ! Après, Victor gèrera pour vous."
        />
    </div>
</template>

<script>
import AddGuideComponent from '@/components/common/AddGuideComponent'

export default {
    components: { AddGuideComponent },
}
</script>