<template>
  <div id="invitation">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Vos invitations ont bien été envoyées !"
        description="Félicitations ! Vos collaborateurs vont recevoir une invitation très rapidement ! Ils s’ajouteront à la liste disponible dans la section Collaborateurs de “Mon Entreprise”"
      />
    </div>
    <v-btn
      elevation="1"
      text
      rounded
      large
      @click="$router.push({ name: dashboardName })"
      class="tw-text-white"
      :style="{
            'background-color': `${referer.theme.ctaTextColor} !important`,
            color: `${referer.theme.ctaBgColor} !important`,
          }"
    >
      Revenir au Dashboard
    </v-btn>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
export default {
  name: "invitationCollaborateursPage",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  },
  components: {
    EntrepriseHeaderBlockTextComponent,
  },
  data() {
    return {
      openMenu: false,
    };
  },
};
</script>
