<template>
  <div id="reference-add-container">
    <div class="tw-flex tw-align-start tw-items-center tw-justify-between tw-mt-2" id="mes-partages-header">
      <div class="tw-flex tw-flex-col tw-align-start">

        <EntrepriseHeaderBlockTextComponent
          title="Documentation" 
          description="L’article 5 du RGPD prévoit l’obligation pour les entreprises de mettre en œuvre des mécanismes et des procédures internes permettant de démontrer le respect des règles relatives à la protection des données.
Il est temps pour vous de démontrer que la protection de la donnée est au cœur de votre organisation<br>
&emsp;&#9679;&emsp;Vos équipes sont constituées (cf nominations DPO, comité de pilotage, comité de direction)<br>
&emsp;&#9679;&emsp;Vos objectifs sont communiqués (cf lettre d’engagement, politiques générales de protection des données)<br>
&emsp;&#9679;&emsp;Vos règles sont fixées (charte informatique, avenant de confidentialité)"/>
      </div>
    </div>
    <div class="tw-mt-10 document-box">
      <reference-list :references="references" v-if="!documentSelected" @selectDocument="selectDocument"/>
      <show-refrence :subcontractors="subcontractors" :documentSelected="documentSelected" :changeReference="changeReference" v-if="documentSelected && !documentSelected.done && !documentSelected.show" @sendReference="sendReference"/>
      <view-refrence :subcontractors="subcontractors" :documentSelected="documentSelected" :changeReference="changeReference" v-if="documentSelected && documentSelected.done && documentSelected.show"/>
      <reference-versions-list :documentSelected.sync="documentSelected" v-if="documentSelected && documentSelected.done && !documentSelected.show"/>
    </div>
    <div  class="tw-flex tw-justify-center tw-items-center tw-mt-4 tw-gap-4">
          <secondary-button-component v-if="documentSelected" @click="goBack" :style="{'color':referer.theme.ctaBgColor}">
            Précédent
          </secondary-button-component>
          <primary-button-component v-if="documentSelected && !documentSelected.done && !documentSelected.show"  @click="handelPopup=true">Suivant
          </primary-button-component>
    </div>
    <!-- <div v-if="documentSelected && documentSelected.done && !documentSelected.show" class="tw-flex tw-justify-center tw-items-center tw-mt-4 tw-gap-4">
          <primary-button-component  @click="documentSelected=null">Terminer
          </primary-button-component>
    </div> -->

  <!-- <div id="html-content" v-html="htmlSelected"></div> -->
      
    <div class="text-center">
      <v-dialog
      v-if="handelPopup"
      v-model="handelPopup"
      @click:outside="closePopup"
      width="536"
      transition="dialog-bottom-transition"
    >
    <svg class="svg-close tw-cursor-pointer" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" @click="closePopup">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8004 1.16393C15.0665 0.897671 15.0665 0.465972 14.8004 0.199708C14.534 -0.0665693 14.1023 -0.0665693 13.836 0.199708L7.5 6.53576L1.16393 0.199708C0.897671 -0.0665693 0.465972 -0.0665693 0.199708 0.199708C-0.0665693 0.465972 -0.0665693 0.897671 0.199708 1.16393L6.53576 7.5L0.199708 13.836C-0.0665693 14.1023 -0.0665693 14.534 0.199708 14.8004C0.465972 15.0665 0.897671 15.0665 1.16393 14.8004L7.5 8.46424L13.836 14.8004C14.1023 15.0665 14.534 15.0665 14.8004 14.8004C15.0665 14.534 15.0665 14.1023 14.8004 13.836L8.46424 7.5L14.8004 1.16393Z" fill="#414042"/>
    </svg>
    <!-- <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/> -->
    <div id="deleteDialog" class="tw-p-8">
      <h2 class="tw-font-bold tw-text-center width-fix" :style="{color:referer.theme.ctaBgColor,'margin-bottom':'85px','margin-top':'45px'}">{{textPopup}}</h2>
      <div class="tw-flex tw-justify-around tw-w-full">
          <v-btn
            text
            large
            @click="closePopup"
            class="tw-text-gray-100 tw-cursor-pointer normal-btn tw-border"
            :style="{'border':`1px solid ${referer.theme.ctaBgColor}`,'color':referer.theme.ctaBgColor,'width':'160px'}"
            rounded
          >
          Retour
          </v-btn>
          <v-btn
            text
            large
            :loading="isSendingForm"
            @click="handleClick"
            class="tw-text-white tw-cursor-pointer"
            :style="{'width':'160px'}"
            rounded
          >
          {{textButton}}
          </v-btn>
      </div>
      </div>
    </v-dialog>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import ReferenceList from './components/referenceList.vue';
import ShowRefrence from './components/showRefrence.vue';
import ReferenceVersionsList from './components/referenceVersionsList.vue';
import SecondaryButtonComponent from '@/components/common/SecondaryButtonComponent.vue';
import PrimaryButtonComponent from '@/components/common/PrimaryButtonComponent.vue';
import { getSendingReferences, getDpo, getSubcontractorsList, generatePDF} from '../../../../services/appApi'
import ViewRefrence from './components/viewRefrence.vue';
// import data from './data/data'
// import html2pdf from 'html2pdf.js';
// import jsPDF from 'jspdf';

export default {
  name: "GouvernanceReferencePage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    ReferenceList,
    ShowRefrence,
    ReferenceVersionsList,
    SecondaryButtonComponent,
    PrimaryButtonComponent,
    ViewRefrence,
  },

  computed: {
    textButton(){
      return this.referencesSendingEmail.includes(this.documentSelected.title) ? 'Envoyer' : 'Confirmer'
    },
    textPopup(){
      return this.referencesSendingEmail.includes(this.documentSelected.title) 
      ? 'Ce document sera envoyé à l’ensemble des collaborateurs de votre entreprise ayant accusé réception de l’email de Gouvernance' 
      : 'Merci de confirmer que les informations renseignées dans ce document sont correctes, vous pourrez toutefois les modifier par la suite.'
    },
    referer() {
      return this.$store.state.referrer;
    },
    emails(){
      const emails =  this.$store.getters.getChildren??[];

      return emails
    },
    answers(){
      return this.$store.getters.getReferenceData;
    },
  },
  mounted() {
    this.checkSendingReferences()
    this.getCompanyDpo()
    this.subcontractorsList()
  },
  data() {
    return {
      changeReference:false,
      isSendingForm:false,
      handelPopup:false,
      documentSelected:null,
      subcontractors:[],
      htmlSelected:'',
      referencesSendingEmail:[
      'Avis constitution comité pilotage RGPD',
      'Membre comité pilotage RGPD',
      'Engagement de la direction',
      'Charte informatique',
      'Engagement de confidentialité',
      'Membre du comité data breach'
      ],
      references:[
        {
          id:1,
          title:"Engagement de la direction",
          component:"engagementDeLaDirection",
          done:false,
          date:"",
          show:false
        },
        // {
        //   id:2,
        //   title:"Mention contractuelles sous-traitants",
        //   component:"mentionContractuelleSoustraitants",
        //   done:false,
        //   date:"",
        //   show:false
        // },
        {
          id:3,
          title:"Mention contractuelles Salaries",
          component:"mentionContractuelleSalaries",
          done:false,
          date:"",
          show:false
        },
        {
          id:4,
          title:"Politique générale de gestion des données",
          component:"politiqueGeneraleDeGestionDesDonnees",
          done:false,
          date:"",
          show:false
        },
        {
          id:5,
          title:"Charte informatique",
          component:"charteInformatique",
          done:false,
          date:"",
          show:false
        },
        {
          id:6,
          title:"Avis constitution comité pilotage RGPD",
          component:"avisConstitutionComitePilotageRgpd",
          done:false,
          date:"",
          show:false
        },
        {
          id:7,
          title:"Membre du comité data breach",
          component:"membreDuComiteBataBreach",
          done:false,
          date:"",
          show:false
        },
        {
          id:8,
          title:"Avenant RGPD responsable de traitement",
          component:"avenantRgpdResponsableDeTraitement",
          done:false,
          date:"",
          show:false
        },
        {
          id:9,
          title:"Mention contractuelles Clients directes",
          component:"mentionContractuellesClientsDirectes",
          done:false,
          date:"",
          show:false
        },
        {
          id:10,
          title:"Engagement de confidentialité",
          component:"engagementDeConfidentialite",
          done:false,
          date:"",
          show:false
        },
        {
          id:11,
          title:"Membre comité pilotage RGPD",
          component:"membreComitePilotageRgpd",
          done:false,
          date:"",
          show:false
        }
      ]
    };
  },
  methods: {

    subcontractorsList(){
      getSubcontractorsList().then(res=>this.subcontractors=res.data).catch(err=>console.log(err))
    },
    getCompanyDpo(){
      getDpo().then(res=>{
        this.$store.commit('SET_DPO',res.data)
      }).catch(err=> console.log(err))
    },
    goBack(){
      if(this.documentSelected.show){
        this.documentSelected.show = false
        }
      else
          {
          if(this.documentSelected.update){
              this.documentSelected.show=false;
              this.documentSelected.update=false;
            }
          else{
              this.documentSelected=null;
          }
          }


      this.changeReference=false;

      this.checkSendingReferences()
    },
    handleClick(){
      this.changeReference = true
    },
    closePopup(){
      this.handelPopup = false
    },
    selectDocument(arg){
      this.documentSelected = arg
    },
    sendReference(arg){
      this.htmlSelected = arg;
      this.isSendingForm = true;

      const formData = new FormData();
      // formData.append('pdf', blob, 'filename.pdf');
      formData.append('document', this.documentSelected.id);
      formData.append('title', this.documentSelected.title);
      formData.append('template', arg.template.toString());
      formData.append('SubcontractorsEmails', JSON.stringify(this.emails));
      formData.append('answers', JSON.stringify(this.answers));

      
        generatePDF( formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{
          console.log(res.data)
          this.$notify({
                      group: "foo",
                      type: "success",
                      // title: "avec succès",
                      position: "bottom right",
                      text: 'Le document a été généré avec succès.',
                    });
        }).catch(({ response })=>{
          response.data.message.forEach((item) => {
                    this.$notify({
                      group: "foo",
                      type: "error",
                      title: "Attention",
                      position: "bottom right",
                      text: item,
                    });
                  });
        }).finally(()=>{
          // this.documentSelected= null
          this.handelPopup = false
          this.changeReference = false
          this.documentSelected.show=false;
          this.documentSelected.done =true;
          this.htmlSelected = '';
          this.isSendingForm = false
          this.checkSendingReferences()
        });
},
    checkSendingReferences(){
      getSendingReferences().then(res => {
        this.references.forEach((item,index)=>{
          const checkIfItemExistInComingData = res.data.some(el=>el.document == item.id)
          if(checkIfItemExistInComingData){
            this.references[index].done = true
            this.references[index].date = res.data.filter(el=>el.document == item.id)[0].createdAt
          }
        })
      }).catch(err=>console.log(err)
      )
    }
  },
  watch: {
    // isSendingForm(){
    //   this.checkSendingReferences()
    // }
  }
};
</script>

<style lang="scss" scoped>
.done-color{
  color: #FFF !important;
  background: var(--cta-text-color) !important;
}
.tw-mt-2{
  margin-top: 0.6rem !important;
}
.document-box{
  padding: 40px;
  min-height: 500px;
  box-shadow: 3px 3px .4em rgb(178, 178, 178), -3px 3px .4em rgb(178, 178, 178);
  border-radius: 25px;
}
.document-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.item-box{
  height: 145px;
  width: 145px
}
.h-1{
  height: 66px;
}
.h-2{
  height: 50px;
}
.h-3{
  height: 23px;
}
.gap-40{
  gap: 40px;
}
.width-fix{
  width:392px;
}

.svg-close {
    height: 15px;
    width: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    float: right;
    cursor: pointer;
    z-index: 99;
  }
  .editable {
        border: none;
}
</style>