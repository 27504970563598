<template>
  <div id="collaborateurs" class="tw-mt-10">
    <div
      class="tw-flex tw-pb-2 tw-align-start tw-items-center tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Ma base de données clients"
        description=""
      />
    </div>

    <div class="tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-space-y-10 tw-mt-20">
      <div class="tw-flex tw-items-center tw-justify-between">
        <SignupSecondaryTextComponent class="tw-col-span-1">
          <b>La liste des clients de l’entreprise.</b>
        </SignupSecondaryTextComponent>
        <div class="tw-flex tw-items-center tw-space-x-2">
          <div v-if="!user.collaborateur" class="tw-w-64">
            <button
              id="team-select-button"
              @click="handleOpenTeamSelect"
              v-click-outside="handleOpenTeamSelect"
              type="button"
              style="background: rgb(240, 240, 240) !important; font-size: 10px;"
              class="tw-w-64 tw-relative tw-cursor-default tw-rounded-full tw-py-2.5 tw-pl-5 tw-pr-10 tw-text-left tw-shadow-sm tw-ring-1 tw-text-gray-400 tw-ring-inset tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 sm:tw-text-sm sm:tw-leading-6"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
            >
              <span 
                v-if="teamFilterString"
                class="tw-block tw-truncate"
                style="color: #323232; font-size: 12px; font-weight: 700;"
              >{{ teamFilterString }}</span>
              <span v-else class="tw-block tw-truncate tw-text-gray-400">Filtrer par équipe</span>
              <span class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                <svg
                  :style="isOpenTeamSelect ? 'transform: rotate(180deg);' : ''"
                  width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0.538854 0.656759C0.538737 0.579455 0.574871 0.500192 0.645186 0.440572C0.785816 0.321332 1.01625 0.320364 1.15724 0.438421L5.98513 4.47923L10.7283 0.457546C10.8689 0.338306 11.0993 0.337338 11.2403 0.455395C11.3813 0.573452 11.3816 0.767606 11.241 0.886846L6.24214 5.12534C6.10151 5.24458 5.87109 5.24554 5.7301 5.12749L0.645871 0.872023C0.575377 0.812995 0.538982 0.734054 0.538865 0.65675L0.538854 0.656759Z" fill="#434343"/>
                </svg>
              </span>
            </button>
            <transition
              leave-active-class="tw-transition tw-ease-in tw-duration-100"
              leave-from-class="tw-opacity-100"
              leave-to-class="tw-opacity-0"
            >
              <ul
                v-if="isOpenTeamSelect"
                style="background: rgb(240, 240, 240) !important; margin-top: 8px;"
                class="tw-absolute tw-z-10 tw-max-h-60 tw-w-64 tw-overflow-auto tw-rounded-md tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-text-xs"
                tabindex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
              >
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-select-none tw-py-2 tw-px-3 tw-text-gray-400 hover:tw-text-gray-600"
                  id="listbox-option-0"
                  role="option"
                  @click="filterByTeam('all')"
                >
                  <span class="tw-block tw-truncate tw-font-normal">Tous</span>
                  <svg 
                    v-if="filters.teamFilter === 'all'"
                    class="tw-h-4 tw-w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                  </svg>
                </li>
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-select-none tw-py-2 tw-px-3 tw-text-gray-400 hover:tw-text-gray-600"
                  id="listbox-option-0"
                  role="option"
                  v-for="team in allExpertTeams"
                  :key="team.id"
                  @click="filterByTeam(team.id.toString())"
                >
                  <span class="tw-block tw-truncate tw-font-normal">{{ team.name }}</span>
                  <svg 
                    v-if="filters.teamFilter === team.id.toString()"
                    class="tw-h-4 tw-w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                  </svg>
                </li>
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-select-none tw-py-2 tw-px-3 tw-text-gray-400 hover:tw-text-gray-600"
                  id="listbox-option-0"
                  role="option"
                  @click="filterByTeam('unassigned')"
                >
                <span class="tw-block tw-truncate tw-font-normal">Aucune</span>
                  <svg 
                    v-if="filters.teamFilter === 'unassigned'"
                    class="tw-h-4 tw-w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                  </svg>
                </li>
              </ul>
            </transition>
          </div>
          <div v-if="!user.collaborateur" class="tw-w-64">
            <button
              id="collaborator-select-button"
              @click="handleOpenCollaboratorSelect"
              v-click-outside="handleOpenCollaboratorSelect"
              type="button"
              style="background: rgb(240, 240, 240) !important; font-size: 10px;"
              class="tw-w-64 tw-relative tw-cursor-default tw-rounded-full tw-py-2.5 tw-pl-5 tw-pr-10 tw-text-left tw-shadow-sm tw-ring-1 tw-text-gray-400 tw-ring-inset tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 sm:tw-text-sm sm:tw-leading-6"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
            >
              <span 
                v-if="collaboratorFilterString"
                class="tw-block tw-truncate"
                style="color: #323232; font-size: 12px; font-weight: 700;"
              >{{ collaboratorFilterString }}</span>
              <span v-else class="tw-block tw-truncate tw-text-gray-400">Filtrer par collaborateur</span>
              <span class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                <svg
                  :style="isOpenCollaboratorSelect ? 'transform: rotate(180deg);' : ''"
                  width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0.538854 0.656759C0.538737 0.579455 0.574871 0.500192 0.645186 0.440572C0.785816 0.321332 1.01625 0.320364 1.15724 0.438421L5.98513 4.47923L10.7283 0.457546C10.8689 0.338306 11.0993 0.337338 11.2403 0.455395C11.3813 0.573452 11.3816 0.767606 11.241 0.886846L6.24214 5.12534C6.10151 5.24458 5.87109 5.24554 5.7301 5.12749L0.645871 0.872023C0.575377 0.812995 0.538982 0.734054 0.538865 0.65675L0.538854 0.656759Z" fill="#434343"/>
                </svg>
              </span>
            </button>
            <transition
              leave-active-class="tw-transition tw-ease-in tw-duration-100"
              leave-from-class="tw-opacity-100"
              leave-to-class="tw-opacity-0"
            >
              <ul
                v-if="isOpenCollaboratorSelect"
                style="background: rgb(240, 240, 240) !important; margin-top: 8px;"
                class="tw-absolute tw-z-10 tw-max-h-60 tw-w-64 tw-overflow-auto tw-rounded-md tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-text-xs"
                tabindex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
              >
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-select-none tw-py-2 tw-px-3 tw-text-gray-400 hover:tw-text-gray-600"
                  id="listbox-option-0"
                  role="option"
                  @click="filterByCollaborator('all')"
                >
                  <span class="tw-block tw-truncate tw-font-normal">Tous</span>
                  <svg 
                    v-if="filters.collaboratorFilter === 'all'"
                    class="tw-h-4 tw-w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                  </svg>
                </li>
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-select-none tw-py-2 tw-px-3 tw-text-gray-400 hover:tw-text-gray-600"
                  id="listbox-option-0"
                  role="option"
                  v-for="collaborator in allExpertCollaborators"
                  :key="collaborator.id"
                  @click="filterByCollaborator(collaborator.id.toString())"
                >
                  <span class="tw-block tw-truncate tw-font-normal">{{ collaborator.firstName }}</span>
                  <svg 
                    v-if="filters.collaboratorFilter === collaborator.id.toString()"
                    class="tw-h-4 tw-w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                  </svg>
                </li>
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-select-none tw-py-2 tw-px-3 tw-text-gray-400 hover:tw-text-gray-600"
                  id="listbox-option-0"
                  role="option"
                  @click="filterByCollaborator('unassigned')"
                >
                <span class="tw-block tw-truncate tw-font-normal">Aucun</span>
                  <svg 
                    v-if="filters.collaboratorFilter === 'unassigned'"
                    class="tw-h-4 tw-w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                  </svg>
                </li>
              </ul>
            </transition>
          </div>
          <TextFieldComponent
            v-model="filters.keyWord"
            class="search-input with-border"
            label="Rechercher"
            append-icon="mdi-magnify"
            @change="search"
          />
        </div>
      </div>

      <ExpertClientDataBaseTableComponent class="tw-col-span-3" :filters="filters" />
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import ExpertClientDataBaseTableComponent from "@/features/company/pages/Expert/Client/ExpertClientDataBaseTableComponent";
import { getTeamMembersWithoutPagination, getTeamsWithoutPagination } from "@/features/company/services/appApi";

export default {
  name: "ClientDataBase",
  components: {
    ExpertClientDataBaseTableComponent,
    TextFieldComponent,
    SignupSecondaryTextComponent,
    EntrepriseHeaderBlockTextComponent,
  },
  data() {
    return {
      keyWord: "",
      filters: {
        keyWord: "",
        collaboratorFilter: "",
        teamFilter: "",
      },
      collaboratorFilterString: "",
      teamFilterString: "",
      word: "",
      isOpenCollaboratorSelect: false,
      isOpenTeamSelect: false,
      isSelectExpertCollaborator: null,
      allExpertCollaborators: [],
      allExpertTeams: [],
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.getTeamMembers();
    this.getTeams();
  },
  methods: {
    getTeamMembers() {
      getTeamMembersWithoutPagination()
        .then(({ data }) => {
          this.allExpertCollaborators = data.map((collaborateur) => ({
            id: collaborateur.id,
            firstName: collaborateur.firstName,
            lastName: collaborateur.lastName,
          }));
        })
        .catch(error => {
          console.log(error)
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la récupération des collaborateurs",
            type: "error",
          });
        })
    },
    getTeams() {
      getTeamsWithoutPagination()
        .then(({ data }) => {
          this.allExpertTeams = data.map((team) => ({
            id: team.id,
            name: team.name,
          }));
        })
        .catch(error => {
          console.log(error)
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la récupération des équipes",
            type: "error",
          });
        })
    },
    filterByCollaborator(filter) {
      this.filters.collaboratorFilter = filter;
      this.collaboratorFilterString = (
        (this.filters.collaboratorFilter === "all" && "Tous") ||
        (this.filters.collaboratorFilter === "unassigned" && "Aucun") ||
        this.allExpertCollaborators.find(
          (collaborator) =>
            collaborator.id.toString() === this.filters.collaboratorFilter
        )?.firstName
      )
      this.isOpenCollaboratorSelect = false;
    },
    filterByTeam(filter) {
      this.filters.teamFilter = filter;
      this.teamFilterString = (
        (this.filters.teamFilter === "all" && "Tous") ||
        (this.filters.teamFilter === "unassigned" && "Aucune") ||
        this.allExpertTeams.find(
          (team) =>
            team.id.toString() === this.filters.teamFilter
        )?.name
      )
      this.isOpenTeamSelect = false;
    },
    handleOpenCollaboratorSelect($event) {
      const targetElement = $event.target;
      const buttonElement = document.getElementById("collaborator-select-button");

      this.isOpenCollaboratorSelect = buttonElement.contains(targetElement)
        ? !this.isOpenCollaboratorSelect
        : this.isOpenCollaboratorSelect && false;
    },
    handleOpenTeamSelect($event) {
      const targetElement = $event.target;
      const buttonElement = document.getElementById("team-select-button");

      this.isOpenTeamSelect = buttonElement.contains(targetElement)
        ? !this.isOpenTeamSelect
        : this.isOpenTeamSelect && false;
    },
    addClient() {
      this.$router.push({
        name: "Company.ExpertClientAddNew",
      });
    },
    search() {
      this.word = this.keyWord;
    },
  },
};
</script>

<style>
#collaborateurs .search-input.with-border input {
  border: 1px solid rgb(209, 213, 219) !important;
}
#collaborateurs .search-input.with-border label {
  color: rgb(156, 163, 175) !important;
}
</style>
