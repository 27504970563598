<template>
  <v-container id="LoginPage" class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <router-link :to="{ name: 'MultiAccount.OTPLogin' }">
        <RetourButtonComponent />
      </router-link>

      <TitleComponent class="public-title-auth tw-mt-4" text="Sélectionnez l’espace" />
      <TitleComponent class="public-title-auth" text="dans lequel vous voulez entrer." />

      <LoginSecondaryTextComponent class="public-text-login tw-mt-16">Sélectionnez le compte sur lequel vous souhaitez entrer.</LoginSecondaryTextComponent>

      <v-list class="tw-mt-8" style="width: 390px;">
        <v-list-item-group v-model="selectedCompany" class="tw-flex tw-flex-col tw-gap-y-4">
          <v-list-item
            v-for="company in companies"
            :key="company.id"
            class="tw-cursor-pointer tw-p-4 tw-rounded-lg hover:tw-bg-theme-primary hover:tw-text-white"
            :style="{
              border: `1px solid ${theme.ctaBgColor}`,
            }"
            @click="selectedCompanyId = company.id"
            active-class="tw-bg-theme-primary tw-text-white"
          >
            <v-list-item-content>
              <v-list-item-title>{{ company.socialReason }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="tw-mt-10" v-if="!!selectedCompanyId">
        <PrimaryButtonComponent
          elevation="0"
          :style="{
            backgroundColor: `#f4f4f4 !important`,
            color: `${theme.ctaBgColor}  !important`
          }" 
          class="!tw-px-12 !tw-rounded-lg"
          :loading="isSendingForm"
          :disabled="isSendingForm" type="submit"
          @click="onSubmit()"
        >Accéder au compte sélectionné ></PrimaryButtonComponent>
      </div>
    </div>
  </v-container>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import TitleComponent from "@/components/common/TitleComponent";
import LoginSecondaryTextComponent from "@/features/auth/components/LoginSecondaryTextComponent";
import { loginWithCompany } from "../../services/multiAuth.api";

export default {
  name: "MultiAccountCompanySelection",
  components: {
    RetourButtonComponent,
    TitleComponent,
    LoginSecondaryTextComponent,
    PrimaryButtonComponent,
  },
  data() {
    return {
      companies: [],
      selectedCompany: null,
      selectedCompanyId: null,
      isSendingForm: false,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme
    },
    platformTitle() {
      return this.$store.state.referrer.title
    }
  },
  mounted() {
    const accounts = this.$store.state.multiAuthAccounts;
    const email = this.$store.state.multiAuthEmail;

    if (!accounts.length || !email) 
      this.$router.push({ name: 'LoginPage' })

    this.companies = accounts.map(account => ({ id: account.id, socialReason: account.socialReason }))
  },
  methods: {
    onSubmit() {
      this.isSendingForm = true;
      
      loginWithCompany(this.$store.state.multiAuthEmail, this.selectedCompanyId)
        .then(({ data }) => {
          this.isSendingForm = false;
          this.$store.dispatch("setToken", data.data.accessToken);

          if (
            !data.data.planPriceId &&
            !data.data.subscribed &&
            !data.data.isExpertAccountActive
          )
            this.$router.push({ name: this.platformTitle === 'Viqtor Expert' ? 'SetupAccountExpert' : 'SetupAccountCompany' });
          if (data.data.planPriceId && data.data.subscribed === false)
            this.$router.push({ name: "SetupAccountPricingCompany", params: { planId: data.data.planPriceId } });
          if (data.data.subscribed === true || data.data.isExpertAccountActive === true)
            this.$router.push({ name: ['Viqtor Expert'].includes(this.platformTitle) && data.data.expertType !== "company"
              ? 'Company.Expert.DashboardPage' : 'Company.DashboardPage' 
            });
        })
        .catch((error) => {
          console.log(error);
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message ||
              "Une erreur est survenue lors de la vérification de votre compte.",
            type: "error",
          });
        })
    },
  }
}
</script>