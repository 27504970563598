import { render, staticRenderFns } from "./LoginSecondStep.vue?vue&type=template&id=94b52c10&scoped=true&"
import script from "./LoginSecondStep.vue?vue&type=script&lang=js&"
export * from "./LoginSecondStep.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94b52c10",
  null
  
)

export default component.exports