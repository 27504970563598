<template>
    <div>
        <div class="tw-mt-6 tw-py-1 tw-pl-3 tw-flex tw-items-center dynamic-bg">
            <p class="!tw-mb-0 tw-font-bold tw-text-[#F2F2F2] tw-text-sm tw-leading-5">{{ label }}</p>
        </div>

        <div class="tw-my-2 tw-grid tw-grid-cols-3 tw-gap-x-9">
            <text-field-component
                v-for="(input, index) in getInputs(entity)"
                :disabled="showMultipleChoices"
                :key="index"
                :value="input.value"
                @input="input.change"
                :type="input.type"
                :placeholder="input.label"
                :error="errors[type] && errors[type][input.index]"
                :error-messages="errors[type] && errors[type][input.index] ? errors[type][input.index] : null"
                class="input-text"
                :hide-details="!errors[type]"
            />
        </div>

        <div v-if="showChoices" class="tw-mt-6 tw-flex tw-items-center tw-space-x-24">
            <p class="tw-w-3/5 !tw-mb-0 tw-text-sm tw-text-brand">{{ choicesDescription }}</p>
            <div class="tw-flex tw-space-x-4">
                <div
                    class="tw-py-3 tw-px-12 tw-rounded-3xl tw-cursor-pointer tw-border-2"
                    :class="isResponsableDesignated ? 'dynamic-bg tw-text-white' : 'tw-bg-white dynamic-text'"
                    :style="{ borderColor: `${referer.theme.ctaBgColor} !important`}"
                    @click="$emit('setChoice', true)"
                >OUI</div>
                <div
                    class="tw-py-3 tw-px-12 tw-rounded-3xl tw-cursor-pointer tw-border-2"
                    :class="!isResponsableDesignated ? 'dynamic-bg tw-text-white' : 'tw-bg-white dynamic-text'"
                    :style="{ borderColor: `${referer.theme.ctaBgColor} !important`}"
                    @click="$emit('setChoice', false)"
                >NON</div>
            </div>
        </div>
        <div v-if="showMultipleChoices" class="tw-mt-6 tw-flex tw-flex-col">
            <div class="tw-w-full tw-flex tw-items-center tw-space-x-24 tw-space-y-3" v-for="(choise,index) in choicesObject" :key="`choise-${index}`">
                <p class="tw-w-3/5 !tw-mb-0 tw-text-sm tw-text-brand">{{ choise.description }}</p>
                <div class="tw-flex tw-space-x-4">
                    <div
                        class="tw-py-3 tw-px-12 tw-rounded-3xl tw-cursor-pointer tw-border-2"
                        :class="choise.choise ? 'dynamic-bg tw-text-white' : 'tw-bg-white dynamic-text'"
                        :style="{ borderColor: `${referer.theme.ctaBgColor} !important`}"
                        @click="$emit('setChoice', {type:choise.type,choise:true})"
                    >OUI</div>
                    <div
                        class="tw-py-3 tw-px-12 tw-rounded-3xl tw-cursor-pointer tw-border-2"
                        :class="!choise.choise ? 'dynamic-bg tw-text-white' : 'tw-bg-white dynamic-text'"
                        :style="{ borderColor: `${referer.theme.ctaBgColor} !important`}"
                        @click="$emit('setChoice', {type:choise.type,choise:false})"
                    >NON</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextFieldComponent from '@/components/common/TextFieldComponent'

export default {
  components: { TextFieldComponent },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
    props: {
        /** @type {
         * firstName: String,
         * lastName: String,
         * fonction: String,
         * professionalAddress: String,
         * codePostal: String,
         * ville: String,
         * businessLandlinePhone: String,
         * mobilePhone: String,
         * email: String,
         * isResponsableDesignated: Boolean
        * } **/
        entity: Object,
        errors: {
          type: Object,
          default: () => {
            return {
              responsible: null,
              manager: null,
              delegate: null,
            }
          }
        },
        getInputs: {
            type: Function,
            default: () => []
        },
        showChoices: {
            type: Boolean,
            default: false
        },
        showMultipleChoices: {
            type: Boolean,
            default: false
        },
        isResponsableDesignated: {
            type: Boolean,
            default: false
        },
        isManagerResponsible: {
            type: Boolean,
            default: false
        },
        isManagerDelegate: {
            type: Boolean,
            default: false
        },
        choicesDescription: {
            type: String,
            default: "Untitled"
        },
        choicesObject: {
            type: Array,
            default: ()=>[]
        },
        label: {
            type: String,
            default: "Untitled"
        },
        type: {
          type: String,
          default: "Untitled"
        }
    },
}
</script>

<style lang="scss">
.input-text input {
    height: 40px !important;
}
</style>
