import Company from "@/Company";
import SubContractor from "@/SubContractor";
// MonEntreprise
import MonEntrepriseContainner from "../features/company/pages/MonEntreprise/ContainnerPage.vue";
import MonEntreprisePage from "../features/company/pages/MonEntreprise/MonEntreprisePage.vue";

import MonEntrepriseQuestionnairePage from "../features/company/pages/MonEntreprise/Questionnaire/QuestionnairePage.vue";
import ReponsesQuestionnairePage from "../features/company/pages/MonEntreprise/Questionnaire/ReponsesQuestionnairePage.vue";
import QuestionnaireRGBDPage from "../features/company/pages/MonEntreprise/Questionnaire/QuestionnaireRGBDPage.vue";
import ValidQuestionnairePage from "../features/company/pages/MonEntreprise/Questionnaire/ValidQuestionnairePage.vue";
import InvitationPage from "../features/company/pages/MonEntreprise/Questionnaire/InvitationPage.vue";

import MonEntreprisePartageContainnerPage from "../features/company/pages/MonEntreprise/Partage/MonEntreprisePartageContainnerPage.vue";
import MonEntreprisePartagePage from "../features/company/pages/MonEntreprise/Partage/MonEntreprisePartagePage.vue";
import MonEntreprisePartageMonHistoriquePage from "../features/company/pages/MonEntreprise/Partage/MonEntreprisePartageMonHistoriquePage.vue";

import CollaborateursPage from "../features/Collaborateurs/pages/CollaborateursPage.vue";
import BecomeAdminPage from "../features/Collaborateurs/pages/BecomeAdminPage.vue";
import invitationCollaborateursPage from "../features/Collaborateurs/pages/invitationCollaborateursPage.vue";

import EcosystemCollaborateursListe from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/EcosystemCollaborateursListe";
import EcosystemPersonelData from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/EcosystemPersonelData";

//ELearning
import ElearningCollaboratorsList from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/ElearningCollaboratorsList";

import ParametresContainnerPage from "../features/company/pages/MonEntreprise/Parametres/ParametresContainnerPage.vue";
import Notification from "../features/company/pages/MonEntreprise/Parametres/Notification.vue";
import DashboardContainnerPage from "@/features/company/pages/Dashboard/DashboardContainnerPage";
import DashboardIndexPage from "@/features/company/pages/Dashboard/DashboardIndexPage";
import NotificationParametres from "@/features/company/pages/MonEntreprise/Parametres/NotificationParametres";
import ProfilContactPage from "@/features/company/pages/Dashboard/ProfilContactPage";

// SubContractor
import SubContractors from "../features/subcontractor/pages/SubContractors.vue";
import EvaluatedSubContractors from "../features/subcontractor/pages/EvaluatedSubContractors.vue";
import NonEvaluatedSubContractors from "../features/subcontractor/pages/NonEvaluatedSubContractors.vue";
import PendingSubContractors from "../features/subcontractor/pages/PendingSubContractors.vue";
import AddSubContractor from "../features/subcontractor/pages/AddSubContractor.vue";
import SubContractorImport from "../features/subcontractor/pages/SubContractorImport.vue";
import SubContractorImportVerification from "../features/subcontractor/pages/SubContractorImportVerification.vue";
import SubContractorInvitation from "../features/subcontractor/pages/SubContractorInvitation.vue";
import SubContractorInvitationVerification from "../features/subcontractor/pages/SubContractorInvitationVerification.vue";
import SubContractorInvitationConfirmation from "../features/subcontractor/pages/SubContractorInvitationConfirmation.vue";
import SubContractorProfile from "../features/subcontractor/pages/SubContractorProfile.vue";
import SubContractorQuiz from "../features/subcontractor/pages/SubContractorQuiz.vue";
import CGUPage from "@/features/company/pages/Dashboard/CGUPage";

import subContractorsPending from "./subContractorsPending.js";
import ReponsesQuestionnaireSubContractorPage from "@/features/subcontractor/pages/ReponsesQuestionnaireSubContractorPage";
import NewCollaboratorGuidePage from "@/features/company/pages/MonEntreprise/Collaborateurs/global/NewCollaboratorGuidePage";
import NewEcoSystemGuidePage from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/NewEcoSystemGuidePage";
import NewEcoSystemStepOnePage from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/NewEcoSystemStepOnePage";
import NewEcoSystemStepTwoPage from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/NewEcoSystemStepTwoPage";
import NewEcoSystemStepTwoEmailPage from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/NewEcoSystemStepTwoEmailPage";
import NewEcoSystemStepThreePage from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/NewEcoSystemStepThreePage";
import NewGouvernanceGuidePage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/NewGouvernanceGuidePage";
import NewGouvernanceStepOnePage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/NewGouvernanceStepOnePage";
import NewGouvernanceStepTwoPage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/NewGouvernanceStepTwoPage";
import NewGouvernanceStepTwoEmailPage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/NewGouvernanceStepTwoEmailPage";
import NewGouvernanceStepThreePage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/NewGouvernanceStepThreePage";
import NewElearningGuidePage from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/NewElearningGuidePage";
import NewElearningStepOnePage from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/NewElearningStepOnePage";
import NewElearningStepTwoPage from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/NewElearningStepTwoPage";
import NewElearningStepTwoEmailPage from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/NewElearningStepTwoEmailPage";
import NewElearningStepThreePage from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/NewElearningStepThreePage";
import GouvernanceListPage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/GouvernanceListPage";
import GouvernanceReferenceVerification from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/GouvernanceReferenceVerification";
import GouvernanceReferencePage from "@/features/company/pages/MonEntreprise/Collaborateurs/Gouvernance/GouvernanceReferencePage";
import MonEntrepriseCollaborateursContainnerPage from "@/features/company/pages/MonEntreprise/Collaborateurs/MonEntrepriseCollaborateursContainnerPage";
import MonEntrepriseCollaborateursPage from "../features/company/pages/MonEntreprise/Collaborateurs/CollaborateursPage.vue";
import AddCollaborateursPage from "@/features/company/pages/MonEntreprise/Collaborateurs/AddCollaborateursPage";

import PreRegister from "@/features/ProcessingRegister/pages/PreRegister";
import FirstStepWithoutEcosystem from '@/features/ProcessingRegister/pages/FirstStepWithoutEcosystem';
import SecondStepWithoutEcosystem from '@/features/ProcessingRegister/pages/SecondStepWithoutEcosystem';
import FirstStepWithEcosystem from '@/features/ProcessingRegister/pages/FirstStepWithEcosystem';
import SecondStep from '@/features/ProcessingRegister/pages/SecondStep';
import LastStep from '@/features/ProcessingRegister/pages/LastStep';
import PreRegisterDetail from "@/features/ProcessingRegister/pages/PreRegisterDetail";
import RgpdProcessesContainerPage from "@/features/company/pages/MonEntreprise/rgpd-processes/RgpdProcessesContainerPage";
import RgpdProcessesList from "@/features/company/pages/MonEntreprise/rgpd-processes/RgpdProcessesList";
import RgpdProcessesDetail from "@/features/company/pages/MonEntreprise/rgpd-processes/RgpdProcessesDetail";

import ExpertClientDashboardPage from "@/features/company/pages/Expert/Client/Dashboard";
import ExpertClientTeamListPage from "@/features/company/pages/Expert/Client/Team";
import ExpertClientManageTeamListPage from "@/features/company/pages/Expert/Manage/Team/List";
import ClientDataBase from "@/features/company/pages/Expert/Client/ClientDataBase";
import NewExpertClientForm from "@/features/company/pages/Expert/Client/NewExpertClientForm";
import NewExpertClientSubsciptionSelection from "@/features/company/pages/Expert/Client/NewExpertClientSubsciptionSelection";
import NewExpertClientConfirmation from "@/features/company/pages/Expert/Client/NewExpertClientConfirmation";
import NewExpertClientStepThree from "@/features/company/pages/Expert/Client/NewExpertClientStepThree";
import IncidentPage from "@/features/incident/IncidentPage.vue";
import pendingPage from "@/features/404/pages/pendingPage.vue";

// Mon Compte
import ProfileContainer from "@/features/company/pages/MonEntreprise/Profile/Container.vue";
import ProfileDashboardPage from "@/features/company/pages/MonEntreprise/Profile/Dashboard.vue";
import ProfileInformationsPage from "@/features/company/pages/MonEntreprise/Profile/Informations.vue";
import ProfileInvoicesPage from "@/features/company/pages/MonEntreprise/Profile/Invoices.vue";
import ProfileAdministratorsPage from "@/features/company/pages/MonEntreprise/Profile/Administrators.vue";
import ProfileUpdateSubscriptionPage from "@/features/company/pages/MonEntreprise/Profile/UpdateSubscription.vue";
import ProfileConnexionHistoryPage from "@/features/company/pages/MonEntreprise/Profile/ConnexionHistory.vue";
import ProfileAddPaymentMethodPage from "@/features/company/pages/MonEntreprise/Profile/AddPaymentMethod.vue";

const routes = [
  {
    name: "Gouvernance.References.Verification",
    path: "/governance/reference/:token",
    component: GouvernanceReferenceVerification,
  },
  {
    name: "Collaborateurs.BecomeAdmin",
    path: "/collaborators/become-admin/:token",
    props: true,
    component: BecomeAdminPage,
  },
  {
    name: "company",
    path: "/company",
    component: Company,
    redirect: { name: "Company.DashboardPage" },
    meta: {
      requiresAuth: true,
      breadcrumb: { name: "" },
    },
    children: [
      
      {
        name: "pending",
        path: "pending",
        component: pendingPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      {
        name: "Collaborateurs.myReferents",
        path: "collaborators/my-referents",
        component: CollaborateursPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      {
        name: "Collaborateurs",
        path: "collaborators/list",
        component: CollaborateursPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      {
        name: "Incident.Homepage",
        path: "incident",
        component: IncidentPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: ''}
        },
      },
      {
        name: "Collaborateurs.Add.Guide",
        path: "collaborators/add-guide",
        component: NewCollaboratorGuidePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Guide pour ajouter un collaborateur" },
        },
      },
      {
        name: "Collaborateurs.invitation",
        path: "collaborators/invitation",
        component: invitationCollaborateursPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Invitation" },
        },
      },
      {
        name: "EcoSystem.List",
        path: "ecosystem/list",
        component: EcosystemCollaborateursListe,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      {
        name: "EcoSystem.One.Item",
        path: "ecosystem/list/:id",
        component: EcosystemPersonelData,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      {
        name: "EcoSystem.Add.Guide",
        path: "ecosystem/add-guide",
        component: NewEcoSystemGuidePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Guide pour ajouter un collaborateur" },
        },
      },
      {
        name: "EcoSystem.Add.Step1",
        path: "ecosystem/add-step-1",
        component: NewEcoSystemStepOnePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 1" },
        },
      },
      {
        name: "EcoSystem.Add.Step2",
        path: "ecosystem/add-step-2",
        component: NewEcoSystemStepTwoPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 2" },
        },
      },
      {
        name: "EcoSystem.Add.Step2.EMAIL",
        path: "ecosystem/add-step-2-preview",
        component: NewEcoSystemStepTwoEmailPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 2 email" },
        },
      },
      {
        name: "EcoSystem.Add.Step3",
        path: "ecosystem/add-step-3",
        component: NewEcoSystemStepThreePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 3" },
        },
      },
      //  Gouvernance
      {
        name: "Gouvernance.List.Page",
        path: "governance/list",
        component: GouvernanceListPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Gouvernance liste" },
        },
      },
      {
        name: "Gouvernance.References.page",
        path: "governance/reference",
        component: GouvernanceReferencePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Gouvernance reference" },
        },
      },
      {
        name: "Gouvernance.References.list",
        path: "governance/reference/list",
        component: GouvernanceReferencePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Gouvernance reference" },
        },
      },

      {
        name: "Gouvernance.Add.Guide",
        path: "governance/add-guide",
        component: NewGouvernanceGuidePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Guide pour ajouter un collaborateur" },
        },
      },
      {
        name: "Gouvernance.Add.Step1",
        path: "governance/add-step-1",
        component: NewGouvernanceStepOnePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 1" },
        },
      },
      {
        name: "Gouvernance.Add.Step2",
        path: "governance/add-step-2",
        component: NewGouvernanceStepTwoPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 2" },
        },
      },
      {
        name: "Gouvernance.Add.Step2.EMAIL",
        path: "governance/add-step-2-preview",
        props: true,
        component: NewGouvernanceStepTwoEmailPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 2 email" },
        },
      },
      {
        name: "Gouvernance.Add.Step3",
        path: "governance/add-step-3/:ids",
        props: true,
        component: NewGouvernanceStepThreePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 3" },
        },
      },
      {
        name: "Gouvernance.Add.AcceptReceipt",
        path: "governance/add-accept-receipt/:token",
        component: NewGouvernanceStepThreePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 3" },
        },
      },
      // Register
      {
        name: "Register.PreRegister.Page",
        path: "register/pre-register/my-treatment-register",
        component: PreRegister,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.PreRegister.Page.AddClientAccount",
        path: "register/pre-register/add-account",
        component: PreRegister,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.PreRegister.Detail",
        path: "register/pre-register/detail/:id",
        props: true,
        component: PreRegisterDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.without-ecosystem.FirstStep.Page",
        path: "register/without-ecom/first-step",
        component: FirstStepWithoutEcosystem,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.without-ecosystem.SecondStep.Page",
        path: "register/without-ecom/second-step",
        component: SecondStepWithoutEcosystem,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.FirstStep.Page",
        path: "register/first-step",
        component: FirstStepWithEcosystem,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.SecondStep.Page",
        path: "register/second-step",
        component: SecondStep,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Register.LastStep.Page",
        path: "register/last-step",
        component: LastStep,
        meta: {
          requiresAuth: true,
        },
      },
      //  Elearning
      {
        name: "Elearning",
        path: "elearning",
        component: ElearningCollaboratorsList,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      {
        name: "Elearning.Add.Guide",
        path: "elearning/add-guide",
        component: NewElearningGuidePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Guide pour ajouter un collaborateur" },
        },
      },
      {
        name: "Elearning.Add.Step1",
        path: "elearning/add-step-1",
        component: NewElearningStepOnePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 1" },
        },
      },
      {
        name: "Elearning.Add.Step2",
        path: "elearning/add-step-2",
        component: NewElearningStepTwoPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 2" },
        },
      },
      {
        name: "Elearning.Add.Step2.EMAIL",
        path: "elearning/add-step-2-preview",
        component: NewElearningStepTwoEmailPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 2 email" },
        },
      },
      {
        name: "Elearning.Add.Step3",
        path: "elearning/add-step-3",
        component: NewElearningStepThreePage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un collaborateur étape 3" },
        },
      },
      // RGPD processes
      {
        name: "Company.RgpdProcesses",
        path: "rgpd-processes",
        component: RgpdProcessesContainerPage,
        redirect: { name: "Company.RgpdProcesses.List" },
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "rgpd" },
        },
        children: [
          {
            name: "Company.RgpdProcesses.List",
            path: "list",
            component: RgpdProcessesList,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "list" },
            },
          },
          {
            name: "Company.RgpdProcesses.Detail",
            path: "detail/:id",
            component: RgpdProcessesDetail,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "list" },
            },
          },
        ],
      },
      {
        name: "Company.Profil.Contact",
        path: "profil-contact",
        component: ProfilContactPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Company.Profil.CGU",
        path: "cgu",
        component: CGUPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "SubContractorImport",
        path: "sous-traitants/import",
        component: SubContractorImport,
        meta: {
          breadcrumb: {
            name: "",
          },
        },
      },
      {
        name: "SubContractorImportVerification",
        path: "sous-traitants/import/verifier",
        component: SubContractorImportVerification,
        meta: {
          requiresAuth: true,
          breadcrumb: {
            name: "",
          },
        },
      },
      {
        name: "SubContractorInvitation",
        path: "sous-traitants/invitation/:type",
        component: SubContractorInvitation,
        meta: {
          requiresAuth: true,
          breadcrumb: {
            name:
              "",
          },
        },
      },
      {
        name: "SubContractorInvitationVerification",
        path: "sous-traitants/invitation/verification",
        component: SubContractorInvitationVerification,
        meta: {
          requiresAuth: true,
          breadcrumb: {
            name:
              "",
          },
        },
      },
      {
        name: "SubContractorInvitationConfirmation",
        path: "sous-traitants/invitation/confirmation",
        component: SubContractorInvitationConfirmation,
        meta: {
          requiresAuth: true,
          breadcrumb: {
            name:
              "",
          },
        },
      },
      //Dashboard
      {
        name: "Company.Expert.DashboardPage",
        path: "expert/dashboard",
        component: DashboardContainnerPage,
        meta: {
          requiresAuth: true,
        },
        redirect: { name: "Company.Expert.DashboardPage.Index" },
        children: [
          {
            name: "Company.Expert.DashboardPage.Index",
            path: "",
            component: ExpertClientDashboardPage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "" },
            },
          },
        ],
      },
      {
        name: "Company.DashboardPage",
        path: "dashboard",
        component: DashboardContainnerPage,
        meta: {
          requiresAuth: true,
        },
        redirect: { name: "Company.DashboardPage.Index" },
        children: [
          {
            name: "Company.DashboardPage.Index",
            path: "",
            component: DashboardIndexPage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "" },
            },
          },
        ],
      },
      {
        name: "SubContractorProfile",
        path: "sous-traitants/evalues/profile/:subContractor",
        component: SubContractorProfile,
        props: true,
        meta: {
          requiresAuth: true,
          breadcrumb: {
            name: "",
          },
        },
      },
      {
        name: "SubContractorProfileQuiz",
        path: "sous-traitants/evalues/quiz/:subContractor",
        component: ReponsesQuestionnaireSubContractorPage,
        props: true,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Sous-traitants" },
        },
      },
      {
        name: "SubContractorQuiz",
        path: "sous-traitants/questionnaire",
        component: SubContractorQuiz,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "" },
        },
      },
      // Mon entreprise
      {
        name: "Company.MonEntreprisePage",
        path: "mon-entreprise",
        component: MonEntrepriseContainner,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Mon Entreprise" },
        },
        redirect: { name: "Company.MonEntreprisePage.Index" },
        children: [
          {
            name: "Company.MonEntreprisePage.Index",
            path: "",
            component: MonEntreprisePage,
            props: true,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "" },
            },
          },
          {
            name: "Company.MonEntreprisePage.Update",
            path: "Modifier",
            component: MonEntreprisePage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Modifier mes informations" },
            },
          },
          // Questionnaire
          {
            name: "Company.MonEntreprisePage.Questionnaire",
            path: "Questionnaire",
            component: MonEntrepriseQuestionnairePage,
            props: true,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Mon questionnaire" },
            },
          },
          {
            name: "Company.MonEntreprisePage.Questionnaire.reponses",
            path: "Questionnaire/reponses",
            component: ReponsesQuestionnairePage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Mes réponses" },
            },
          },
          {
            name: "Company.MonEntreprisePage.Questionnaire.RGBD",
            path: "Questionnaire/RGBD",
            component: QuestionnaireRGBDPage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Mon questionnaire" },
            },
          },
          {
            name: "Company.MonEntreprisePage.Questionnaire.RGBD.valid",
            path: "Questionnaire/RGBD/valid",
            component: ValidQuestionnairePage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "C’est validé !" },
            },
          },
          {
            name: "Company.MonEntreprisePage.Questionnaire.RGBD.invitation",
            path: "Questionnaire/RGBD/invitation",
            component: InvitationPage,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "C’est validé !" },
            },
          },
          // Partage
          {
            name: "Company.MonEntreprisePage.Partage",
            path: "Partage",
            component: MonEntreprisePartageContainnerPage,
            redirect: { name: "Company.MonEntreprisePage.Partage" },
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Partages" },
            },
            children: [
              {
                name: "Company.MonEntreprisePage.Partage",
                path: "",
                component: MonEntreprisePartagePage,
                meta: {
                  requiresAuth: true,
                  breadcrumb: { name: "" },
                },
              },
              {
                name: "Company.MonEntreprisePage.Partage.Historique",
                path: "Mon-historique",
                component: MonEntreprisePartageMonHistoriquePage,
                meta: {
                  requiresAuth: true,
                  breadcrumb: { name: "Historique" },
                },
              },
            ],
          },
          // Collaborateurs
          {
            name: "Company.MonEntreprisePage.Collaborateurs",
            path: "Collaborateurs",
            component: MonEntrepriseCollaborateursContainnerPage,
            redirect: { name: "Company.MonEntreprisePage.Collaborateurs" },
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Collaborateurs" },
            },
            children: [
              // Collaborateurs add
              {
                name: "Company.MonEntreprisePage.Collaborateurs",
                path: "",
                component: MonEntrepriseCollaborateursPage,
                meta: {
                  requiresAuth: true,
                  breadcrumb: { name: "" },
                },
              },
              {
                name: "Company.MonEntreprisePage.Collaborateurs.add",
                path: "add",
                component: AddCollaborateursPage,
                meta: {
                  requiresAuth: true,
                  breadcrumb: { name: "Ajouter un collaborateur" },
                },
              },
              {
                name: "Company.MonEntreprisePage.Collaborateurs.invitation",
                path: "invitation",
                component: invitationCollaborateursPage,
                meta: {
                  requiresAuth: true,
                  breadcrumb: { name: "Invitation" },
                },
              },
            ],
          },
        ],
      },
      //
      {
        name: "Company.Profile",
        path: "mon-compte",
        component: ProfileContainer,
        redirect: { name: "Company.Profile.Dashboard" },
        meta: { requiresAuth: true },
        children: [
          {
            name: "Company.Profile.Dashboard",
            path: "",
            component: ProfileDashboardPage,
            meta: { requiresAuth: true },
          },
          {
            name: "Company.Profile.Informations",
            path: "/informations",
            component: ProfileInformationsPage,
            meta: { requiresAuth: true },
          },
          {
            name: "Company.Profile.Invoices",
            path: "/factures",
            component: ProfileInvoicesPage,
            meta: { requiresAuth: true },
          },
          {
            name: "Company.Profile.Administrators",
            path: "/administrateurs",
            component: ProfileAdministratorsPage,
            meta: { requiresAuth: true },
          },
          {
            name: "Company.Profile.UpdateSubscription",
            path: "/changer-pack",
            component: ProfileUpdateSubscriptionPage,
            meta: { requiresAuth: true },
          },
          {
            name: "Company.Profile.ConnexionHistory",
            path: "/historique-connexion",
            component: ProfileConnexionHistoryPage,
            meta: { requiresAuth: true },
          },
          {
            name: "Company.Profile.AddPaymentMethod",
            path: "/ajouter-moyen-paiement",
            component: ProfileAddPaymentMethodPage,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        name: "Company.Parametres",
        path: "parametres",
        component: ParametresContainnerPage,
        redirect: { name: "Company.Parametres.NotificationPage" },
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Parametres" },
        },
        children: [
          {
            name: "Company.Parametres.NotificationPage",
            path: "notifications",
            component: NotificationParametres,
            meta: {
              requiresAuth: true,
              breadcrumb: { name: "Notifications" },
            },
          },
        ],
      },
      // notification
      {
        name: "Company.NotificationPage",
        path: "notification",
        component: Notification,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Notifications" },
        },
      },
      {
        name: "SubContractor",
        path: "/sous-traitant",
        component: SubContractor,
        redirect: { name: "SubContractor.List" },
        meta: {
          requiresAuth: true,
          breadcrumb: {
            name: "Sous-traitants",
          },
        },
        children: [
          {
            name: "SubContractor.List",
            path: "all",
            component: SubContractors,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "",
              },
            },
          },
          {
            name: "SubContractor.SubContractorProfile",
            path: "profile",
            component: SubContractorProfile,
            meta: {
              breadcrumb: {
                name: "Dassault Systèmes",
              },
            },
          },
          {
            name: "SubContractor.SubContractorQuiz",
            path: "questionnaire",
            component: SubContractorQuiz,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Questionnaire",
              },
            },
          },
          {
            name: "SubContractor.EvaluatedSubContractors",
            path: "evalues",
            component: EvaluatedSubContractors,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Évalués",
              },
            },
          },
          {
            name: "SubContractor.NonEvaluatedSubContractors",
            path: "non-evalues",
            component: NonEvaluatedSubContractors,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Non-évalués",
              },
            },
          },
          // en attente
          {
            name: "SubContractor.PendingSubContractors.containner",
            path: "attente",
            component: PendingSubContractors,
            redirect: "/",
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "",
              },
            },
            children: subContractorsPending,
          },
          {
            name: "SubContractor.AddSubContractor",
            path: "ajout",
            component: AddSubContractor,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Ajouter sous-traitant",
              },
            },
          },
          {
            name: "SubContractor.SubContractorImport",
            path: "import",
            component: SubContractorImport,
            redirect: { name: "SubContractor.SubContractorImport.SubContractorImportVerification" },
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "import",
              },
            },
            children: [
              {
                name:
                  "SubContractor.SubContractorImport.SubContractorImportVerification",
                path: "verifier",
                component: SubContractorImportVerification,
                meta: {
                  requiresAuth: true,
                  breadcrumb: {
                    name: "Vérifier",
                  },
                },
              },
            ],
          },
          {
            name: "SubContractor.SubContractorInvitation",
            path: "invitation",
            component: SubContractorInvitation,
            props: true,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Invitation",
              },
            },
          },
          {
            name:
              "SubContractor.SubContractorInvitation.SubContractorInvitationVerification",
            path: "invitation/verification",
            component: SubContractorInvitationVerification,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Vérification",
              },
            },
          },
          {
            name:
              "SubContractor.SubContractorInvitation.SubContractorInvitationConfirmation",
            path: "invitation/confirmation",
            component: SubContractorInvitationConfirmation,
            meta: {
              requiresAuth: true,
              breadcrumb: {
                name: "Confirmation",
              },
            },
          },
        ],
      },
      // EXPERT CLIENT
      {
        name: "company.expert.manage.team",
        path: "expert-client-manage-team",
        component: ExpertClientManageTeamListPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Manage Equipe" },
        },
      },
      {
        name: "Company.ExpertClientTeam",
        path: "expert-client-team",
        component: ExpertClientTeamListPage,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Equipe" },
        },
      },
      {
        name: "Company.ExpertClientDataBase",
        path: "expert-client-database/list",
        component: ClientDataBase,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Base de données" },
        },
      },
      {
        name: "Company.ExpertClientAddNew",
        path: "expert-client-database/add",
        component: NewExpertClientForm,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "New" },
        },
      },
      {
        name: "Company.ExpertClientAddNew.Subscription",
        path: "expert-client-database/add/subsciption",
        component: NewExpertClientSubsciptionSelection,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "New" },
        },
        props: true,
      },
      {
        name: "Company.ExpertClientAddNew.Confirmation",
        path: "expert-client-database/add/confirmation",
        component: NewExpertClientConfirmation,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "New" },
        },
        props: true,
      },
      {
        name: "Company.Add.Step3",
        path: "expert-client-database/step-3",
        component: NewExpertClientStepThree,
        meta: {
          requiresAuth: true,
          breadcrumb: { name: "Ajouter un client étape 3" },
        },
      },
    ],
  },
];

export default routes;
