import ApiService from "@/services/api.service";
import axios from "axios";
import {serverConfig} from "@/config/serverConfig";
// import { serverConfig } from "../../../config/serverConfig";

export const firstStepSignUpApi = (data) => {
  return ApiService.post(`auth/signup/first-step`, {
    firstName: data.firstName,
    gender: data.gender,
    lastName: data.lastName,
    email: data.email,
    socialReason: data.socialReason,
    sirenOrRna: data.sirenOrRna,
    phone: data.phone,
    password: data.password,
  });
};

export const collaboratorbecomeAdmin = (data) => {
  return axios.post(`${serverConfig.appUrl}/collaborator/become-admin`, data);
};
export const collaboratorcanUpdateAdmin = (token) => {
  return axios.get(`${serverConfig.appUrl}/collaborator/can-update-admin/${token}`);
};
export const collaboratorupdateAdmin = (data) => {
  return axios.post(`${serverConfig.appUrl}/collaborator/update-admin`, data);
};
export const collaboratordeleteAdmin = ({collaboratorId}) => {
  return axios.delete(`${serverConfig.appUrl}/collaborator/admin/${collaboratorId}`);
};
export const lastStepSignUpApi = (token) => {
  return axios.post(`${serverConfig.appUrl}/auth/signup/last-step/${token}`);
};

//Dashboard
export const sendContactForm = (data) => {
  return ApiService.post(`contact`, data);
};

export const getDashboardData = () => {
  return ApiService.get(`dashboard`);
};

export const getUserDataByToken = () => {
  return ApiService.get(`profile`);
};
// mon entreprise update info
export const updateCompany = (data) => {
  return ApiService.put(`profile/company`,data);
};

// mon entreprise parametteres
export const updateUserApi = (data) => {
  return ApiService.put(`profile/user`,data);
};

export const updateUserEmail = (data) => {
  return ApiService.post(`auth/security/change-email`,data);
};

export const updateUserPassword = (data) => {
  return ApiService.post(`auth/security/change-password`,data);
};

export const deleteAccountUser = (data) => {
  return ApiService.delete(`auth/security/delete-account`,data);
};

export const getNotificationList = () => {
  return ApiService.get(`notification/params`);
};

export const changeNotificationStatus = (data) => {
  return ApiService.put(`notification/params`,data);
};

// mon entreprise partage
export const ShareValiderContactApi = (data) => {
  return ApiService.post(`quiz/respond-invitations`,data);
};
export const HistoriqueValiderContactApi = (data) => {
  return ApiService.post(`quiz/respond-invitations`,data);
};
export const HistoriqueDeleteContactApi = (id) => {
  return ApiService.delete(`quiz/cancel/${id}`);
};

export const ShareGetContactApi = () => {
  return ApiService.get(`quiz/new-invitations`);
};

export const HistoriqueShareGetContactApi = () => {
  return ApiService.get(`quiz/invitations`);
};

// mon entreprise collaborateur
export const getCollaborator = (page=1) => {
  return ApiService.get(`collaborator?page=${page}`);
};
export const deleteCollaborator = (id) => {
  return ApiService.delete(`collaborator/${id}`);
};
export const updateCollaborator = (id,data) => {
  return ApiService.put(`collaborator/update/${id}`,data);
};

export const deleteNewCollaborator = (id) => {
  return ApiService.delete(`collaborator/new/${id}`);
};
export const inviteCollaborator = (id) => {
  return ApiService.get(`collaborator/${id}/invite`);
};
export const storeCollaborator = (data) => {
  return ApiService.post(`collaborator`,data);
};
export const importCollaborators = (data) => {
  return ApiService.post(`collaborator/upload`,data);
};
export const uploadModelCollaborators = (data) => {
  // url: `${this.$app_url}/api/v1/agency/commercial_campaign/file/panorama`,
  // method: "GET",
  // responseType: "blob",
  return ApiService.post(`collaborator`,data);
};

// questionnaire
export const getQuiBlueprint = () => {
  return ApiService.get(`quiz/blueprint`);
};
export const getQuiz = () => {
  return ApiService.get(`quiz`);
};
export const getQuizDetail = () => {
  return ApiService.get(`quiz/detail`);
};
export const storeQuiz = (data) => {
  return ApiService.post(`quiz`, data);
};

export const storeDraftQuiz = (data) => {
  return ApiService.post(`quiz/draft`, data);
};

// Company notifications
export const getNotificationUser = () => {
  return ApiService.get(`notification`);
};

export const deleteNotificationUser = (id) => {
  return ApiService.delete(`notification/${id}`);
};

// Company notifications
export const readNotificationUser = (id) => {
  return ApiService.get(`notification/${id}`);
};
