<template>
  <v-text-field
    class="login-text-field"
    v-bind="$attrs"
    v-on="$listeners"
    @click:append="toggleRightIcon"
  ></v-text-field>
</template>

<script>
export default {
  name: "LoginTextFieldComponent",
  props: {
    containerStyle: {
      type: String,
      default: "",
    },
    toggleRightIcon: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
