<template>
  <p class="tw-absolute tw-bottom-0 registration-iml">
    Les informations à caractère personnel recueillies dans le cadre contractuel
    de votre souscription à la plateforme Viqtor sont enregistrées dans un
    fichier informatisé par la société DBWO pour vous permettre l’accès aux
    services qu’elle propose, assurer l’interaction de votre organisation avec
    ses partenaires ainsi que vous tenir informés de l’évolutions de ses
    prestations. Elles seront conservées tout au long de la durée de
    souscription au service, période durant laquelle elles pourront être
    régulièrement mises à jour à votre demande. Hors demande de votre part,
    elles seront détruites après résiliation de la souscription par votre
    organisation sous un délai de 3 mois après le terme du contrat. Ces données
    sont destinées au personnel administratif de DBWO et conservées sur les
    serveurs de l’entreprise OVH. Conformément à la loi « informatique et
    libertés », vous pouvez exercer votre droit d'accès aux données vous
    concernant et les faire rectifier en contactant : dpo@dbwo.legal
  </p>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
