<template>
  <v-app class="tw-pt-0 tw-mt-0">
    <div id="governance-accept-receipt" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <img :src="referer.logoWhite" class="accept-receipt-logo" width="20%" height="auto" alt="LOGO" />
      <h1 class="accept-receipt-title tw-mt-20">Merci d’avoir complété<br />le registre de traitement</h1>
      <p class="accept-receipt-name tw-mt-12">{{ module.user ? module.user.firstName : '' }} {{ module.user ? module.user.lastName : '' }}</p>
    </div>
  </v-app>
</template>

<script>

import {getNewCollaboratorByToken} from "@/features/company/services/appApi";
import {persistStatusRegister} from "@/features/ProcessingRegister/services/appApi";

export default {
  name: "RegisterFinishQuiz",
  components: {},
  computed: {
    referer() {
     return this.$store.state.referrer;
    },
  },
  data() {
    return {
      module: {},
    }
  },
  mounted() {
    this.getNewCollaboratorByToken();
  },
  methods: {
    getNewCollaboratorByToken() {
      getNewCollaboratorByToken('register', this.$route.params.token)
          .then(response => {
            if(!response.data.data) {
              this.$router.replace({ name: 'landing-public' });
            } else  {
              this.module = response.data.data;
              this.changeStatusRegisterQuestion();
            }
          })
          .catch(() => this.$router.replace({ name: 'landing-public' }));
    },
    changeStatusRegisterQuestion() {
      persistStatusRegister(this.$route.params.token,'1')
          .then(() => {
            this.$router.replace({ name: 'Register.Activity.Token.Finish', params: { token: this.$route.params.token } });
          })
          .catch(err => console.log(err))
          .finally(() => this.isSubmitLoading = false);
    }
  }
}
</script>

<style scoped>

</style>
