<template>
  <div class="tw-relative tw-h-full tw-flex tw-flex-col tw-justify-between" v-if="data[index]">
      <div class="tw-mt-6">
          <div class="tw-mx-6">
              <img :src="referer.logo" width="140" alt="">
          </div>
          <div class="tw-flex tw-flex-col tw-my-6">
            <div class="tw-self-center font-color-primary tw-text-4xl">Environnement</div>
            <div class="tw-self-center tw-font-bold tw-text-4xl">{{data[index].title}}</div>
          </div>
      </div>
      <div class="tw-h-full tw-grid tw-grid-cols-8 tw-mx-10">
        <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-select-none" @click="prev()"><img  v-show="index>0" src="@/assets/images/icons/prev.svg" alt=""></div>
        <div class="tw-col-span-6 tw-h-full tw-overflow-y-auto tw-py-8 elearning-quiz-scroll tw-flex tw-items-center">
            <div v-html="data[index].content"></div>
        </div>
        <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-select-none" @click="next()"><img src="@/assets/images/icons/next.svg" alt=""></div>
      </div>
      <pagination :id="index+1" :data="data"/>
  </div>
</template>

<script>
import {Chapitres} from "./mocks/data"
import pagination from "./components/pagination.vue"

export default {
    name: "ElearningQuizStepTree",
    data(){
        return{
            index:0,
            data:[],
            sousChapitre:[]
        }
    },
    components:{
      pagination
    },
    computed: {
      referer() {
        return this.$store.state.referrer;
      },
    },
    mounted(){
        Chapitres.forEach(element=>{
            if(element.id==this.$route.params.chapitre_id)
                {
                    this.sousChapitre=element.sous_chapitre
                }
            this.sousChapitre.forEach(el=>{
                if(el.id==this.$route.params.sous_chapitre_id)
                {
                    this.data=el.cours
                }
            })
        })
    },
    methods:{
        next(){

                if(this.index!=this.data.length-1){
                    if(this.index<this.data.length-1)
                    this.index++
                }
                else{
                    if(this.sousChapitre.length  <= this.$route.params.sous_chapitre_id)
                    {
                        if(Chapitres.length > this.$route.params.chapitre_id){

                        this.$router.push({name:'Elearning-quiz-chapitre',params:{id:parseInt(this.$route.params.chapitre_id)+1}})

                        }
                        else{
                            this.$router.push({name:'Elearning-quiz-question',params:{id:1}})
                        }
                    }else
                    {if(Chapitres.length +1 > this.$route.params.chapitre_id){
                    this.$router.push({name:'Elearning-quiz-sous-chapitre',params:{sous_chapitre_id:parseInt(this.$route.params.sous_chapitre_id)+1}})
                    }else{
                        this.$router.push({name:'Elearning-quiz-question',params:{id:1}})
                    }}
                }


        },
        prev(){
        if(this.index>0)
        this.index--
        }
    }
}
</script>

<style>

</style>
