<template>
  <v-dialog
    v-if="handelPopup"
    v-model="handelPopup"
    @click:outside="closePopup"
    width="600"
    transition="dialog-bottom-transition"
  >
    <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopupBlack.svg" alt="closePopup" @click="closePopup"/>
    <div id="AddMultipleAnswersModal" class="tw-p-8">
      <!-- <img src="@/assets/images/icons/info.svg" alt="logo" /> -->
      <h2 class="tw-font-bold tw-text-center tw-text-3xl label">
        {{ item.content }}
      </h2>
      <div class="tw-my-6 tw-w-full tw-mx-auto">
        <div
        class="tw-flex tw-items-center tw-justify-between tw-mb-2"
            v-for="(el, index) in additionnalAnswers"
            :key="index"
        >
          <div class="tw-flex tw-items-center tw-justify-center tw-w-1/4">
            <span class="">Descriptif N°{{ index+1 }} :</span> 
          </div>
          <div  class="tw-flex tw-items-center tw-justify-center tw-w-3/4 tw-relative">
            <input
              class="answerInput"
              label=""
              v-model="el.answer"
            />
            <img v-if="index!==0" class="delete-input tw-cursor-pointer" src="@/assets/images/icons/closePopupBlack.svg" alt="closePopup" @click="deleteItem(el)"/>
          </div>
          <svg class="tw-cursor-pointer tw-ml-1" v-if="index+1===additionnalAnswers.length" @click="addNewAnswer()" width="27" height="27" viewBox="0 0 17 17" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.9911 7.89314H9.10718V5.00921C9.10718 4.92573 9.03887 4.85742 8.95539 4.85742H8.04468C7.9612 4.85742 7.89289 4.92573 7.89289 5.00921V7.89314H5.00896C4.92548 7.89314 4.85718 7.96144 4.85718 8.04492V8.95564C4.85718 9.03912 4.92548 9.10742 5.00896 9.10742H7.89289V11.9914C7.89289 12.0748 7.9612 12.1431 8.04468 12.1431H8.95539C9.03887 12.1431 9.10718 12.0748 9.10718 11.9914V9.10742H11.9911C12.0746 9.10742 12.1429 9.03912 12.1429 8.95564V8.04492C12.1429 7.96144 12.0746 7.89314 11.9911 7.89314Z"
                fill="#2d2e2d" />
              <path
                d="M8.5 0C3.80603 0 0 3.80603 0 8.5C0 13.194 3.80603 17 8.5 17C13.194 17 17 13.194 17 8.5C17 3.80603 13.194 0 8.5 0ZM8.5 15.558C4.6029 15.558 1.44196 12.3971 1.44196 8.5C1.44196 4.6029 4.6029 1.44196 8.5 1.44196C12.3971 1.44196 15.558 4.6029 15.558 8.5C15.558 12.3971 12.3971 15.558 8.5 15.558Z"
                fill="#2d2e2d" />
          </svg>
          <div v-else style="width:30px;height: 27px;"></div>
        </div>
        <div class="tw-flex tw-justify-end">
          <primary-button-component @click="ValidationAnswers()" class="
                    dynamic-text
                    tw-text-sm tw-font-bold
                  " :style="{
                    color: `${referrer.theme.ctaBgColor} !important`,
                    border: ` 1px solid ${referrer.theme.ctaBgColor}  !important`,
                    height: `auto`,
                    padding: `0.5rem`, backgroundColor: `${referrer.theme.ctaTextColor} !important`
                  }">Valider

          </primary-button-component>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import PrimaryButtonComponent from '@/components/common/PrimaryButtonComponent.vue';
export default {
  components: { PrimaryButtonComponent },
  name: "showadditionalAnswerModal",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      additionnalAnswers:[]
    };
  },
  computed: {
    referrer() {
      return this.$store.state.referrer;
    },
    handelPopup() {
      return this.$parent.openPopup;
    },
  },
  mounted() {
    this.additionnalAnswers = this.item.additionnalAnswers.length ? this.item.additionnalAnswers : [{answer: ""}];
  },
  methods: {
    ValidationAnswers(){
      this.clearEmptyAnswers();
      if(this.additionnalAnswers.length > 0){
        this.$emit("addAdditionalAnswersToOldAnswer", this.additionnalAnswers);
        this.$snackbar.showAlertMessage({
          message: "Réponse ajoutée avec succès",
          type: "success",
        });
        this.$emit("popupClose");
      }else{
        this.additionnalAnswers = [{answer: ""}];
        this.$snackbar.showAlertMessage({
          message: "Veuillez ajouter au moins une réponse",
          type: "error",
        });
      }
    },
    addNewAnswer() {
      this.additionnalAnswers.push({
        answer: "",
      });
    },
    deleteItem(el) {
      if(this.additionnalAnswers.length > 1) {
        this.additionnalAnswers = this.additionnalAnswers.filter((item) => item !== el);
      }
      this.$emit("addAdditionalAnswersToOldAnswer", this.additionnalAnswers);
    },
    closePopup() {
      this.clearEmptyAnswers();
      if(this.additionnalAnswers.length===0){
        delete this.additionnalAnswers;
      }
      
      this.$emit("popupClose");
    },
    clearEmptyAnswers() {
        this.additionnalAnswers = this.additionnalAnswers.filter((el) => el.answer !== "");
    },
  },
  watch: {
    item: {
      handler: function (val) {
        this.additionnalAnswers = val.additionnalAnswers;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#AddMultipleAnswersModal{
  .answerInput{
    width: 100%;
    height: 50px;
    border: 2px solid var(--cta-text-color);
    padding: 0 1rem;
  }
  .label{
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--cta-text-color);
  }
  .delete-input{
    height: 9px;
    position: absolute;
    right: 10px;
  }
}
</style>
