<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <!--class="lg:tw-ml-52"-->
    <div id="collaborateurs-add">
      <div
        class="tw-flex tw-align-start tw-justify-between"
        id="mes-partages-header"
      >
        <EntrepriseHeaderBlockTextComponent
          title="Ajouter un ou plusieurs sous-traitants"
          description="Pour ajouter manuellement un sous-traitant, veuillez remplir les champs ci-dessous :"
        />
      </div>
      <SoustraitantFormAddComponent />
      <img src="@/assets/images/icons/hr.svg" class="hr_img" />
      <div class="collaborators-row">
        <div class="collaborators-col-8">
          <CollaborateurBlockTextComponent
            title="1. Téléchargez d’abord le modèle"
            description="Pour vous permettre d’importer avec plus de facilité la liste de vos sous-traitants, téléchargez ce modèle."
          />
          <v-btn
            elevation="1"
            text
            rounded
            large
            @click="downloadCSVData"
            download
            class="tw-text-white tw-cursor-pointer"
            outlined
          >
            Télécharger le modèle 
            <img src="@/assets/images/icons/download.svg" />
          </v-btn>
          <p>
            Ce fichier est un Excel .csv comprenant 5 colonnes, dans l’ordre : le numéro de SIREN, la civilité, le prénom et le nom du contact ainsi que son adresse email.<br><br>
            Ne modifiez pas l’ordre des colonnes et renseignez autant de sous-traitants que souhaité.<br><br>
            <b>Vous pouvez importer jusqu’à 50 sous-traitants par fichier.</b>
          </p>
        </div>
        <div class="collaborators-col-4">
          <CollaborateurBlockTextComponent
            title="2. Importez votre liste de sous-traitants"
            description="Pour vous permettre de d’importer avec plus de faciliter la liste de vos sous-traitants, téléchargez ce modèle."
          />

          <vue-dropzone 
								ref="myVueDropzone" 
								id="dropzone" 
								useCustomSlot=true 
								:options="dropzoneOptions" 
								class="upload-file" 
								:includeStyling="false"
								@vdropzone-removed-file="fileRemoved" 
                @vdropzone-file-added="fileAdded"
								>
								<div class="dropzone-custom-content tw-flex tw-items-center tw-flex-col">
									<img src="@/assets/images/icons/upload.svg" />
									Glissez et déposez ici votre liste de sous-traitants 
									<v-btn
									elevation="1"
									text
									rounded
									large
									:loading="isUploadModel"
									@click="uploadModel"
									class="tw-text-white tw-cursor-pointer"
									>
									Parcourir
									</v-btn>
								</div>
							</vue-dropzone>
          <v-btn
            elevation="1"
            text
            rounded
            large
            :loading="isImportFile"
            @click="importFile"
            class="tw-text-white tw-cursor-pointer"
          >
            Continuer
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import CollaborateurBlockTextComponent from "@/components/common/CollaborateurBlockTextComponent";
import SoustraitantFormAddComponent from "@/features/auth/components/Collaborateurs/SoustraitantFormAddComponent";
import { importCollaborators } from "../../services/appApi";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "AddCollaborateursPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SoustraitantFormAddComponent,
    CollaborateurBlockTextComponent,
    vueDropzone: vue2Dropzone,
  },
  computed: {
    fileInputText() {
      return this.file ? "" : "AUCUN FICHIER CHOISI";
    },
    fileLInk() {
      return require("@/assets/csv/collaborators.csv");
    },
  },
  data() {
    return {
      isUploadModel: false,
      isImportFile: false,
      file: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 50,
        maxFilesize: 0.5,
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFile:1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        uploadMultiple:1
      },
    };
  },
  methods: {

    fileRemoved() {
      this.file = null;
    },
    fileAdded(file){
			if(this.file){
				this.$refs.myVueDropzone.removeFile(this.file)
			}
			this.file = file;
		},
    importFile() {
      this.isImportFile = true;
      const data = new FormData();
      data.append("file", this.file);
      importCollaborators(this.$route.params.token, data)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.$router.push({
            name: "MsgSousTraitant",
          });
        })
        .catch(({ response }) => {
          if(response.status === 500){
            this.$snackbar.showAlertMessage({
              message: "Ficher importé",
              type: "success",
            });
            this.$router.push({
              name: "Company.MonEntreprisePage.Collaborateurs.invitation",
            });
          }else {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                text: "fichier incorrect.",
              });
            }
          })
          .finally(() => {
            this.isImportFile = false;
          });
    },
    downloadCSVData() {
			var str = "SIREN;GENRE;PRENOM;NOM DE FAMILLE;EMAIL;RAISON SOCIALE";
      var blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "viqtor-sous-traitant-model.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
