<template>
    <div>
        <div class="tw-mt-6">
            <div class="tw-mx-6">
                <img :src="referrer.logo" width="140" height="auto" alt="" />
            </div>
            <div class="tw-flex tw-flex-col tw-my-8 tw-text-2xl tw-font-semibold font-color-primary">
                <div class="tw-self-center">{{ socialReason }} / <span class="tw-mr-1 tw-capitalize">{{
                    user.firstName
                }}</span><span class="tw-uppercase">{{ user.lastName }}</span></div>
                <div class="tw-self-center">CARTOGRAPHIE DE L’ÉCOSYSTÈME DE LA DONNÉE PERSONNELLE</div>

            </div>
        </div>
        <step-zero :user="user" v-if="index == 0" />
        <step-one v-else-if="index == 1" />
        <step-two v-else-if="index == 2" />
        <step-three :token="$route.params.token" v-else-if="index == 3" />
    </div>
</template>

<script>

import { getNewCollaboratorByToken } from "@/features/company/services/appApi";
import stepZero from './components/step-zero'
import stepOne from './components/step-one'
import stepTwo from './components/step-two'
import stepThree from './components/step-three'

export default {
    name: "QuestionnaireStepTwo",
    data() {
        return {
            user: {
                firstName: null,
                lastName: null,
                service: null,
                emailProfessional: null,
                phoneNumber: null,
                mobileNumberProfessional: null,
                fonction: null,
                superiorLastName: null,
                superiorFirstName: null,
            },
            socialReason: null,
            index: 0,
            allAnswers: [],
        }
    },
    components: {
        stepOne, stepTwo, stepThree, stepZero
    },
    mounted() {
        this.getCollaborator()
    },
    computed: {
        referrer() {
            return this.$store.state.referrer ? this.$store.state.referrer : {};
        },
    },
    methods: {

        getCollaborator() {
            getNewCollaboratorByToken("ecosystem", this.$route.params.token).then(res => {
                this.user.firstName = res.data.data.collaborateur.firstName
                this.user.lastName = res.data.data.collaborateur.lastName
                this.user.service = res.data.data.collaborateur.service
                this.user.emailProfessional = res.data.data.collaborateur.email
                this.user.phoneNumber = res.data.data.collaborateur.phone
                this.user.mobileNumberProfessional = res.data.data.collaborateur.mobileNumberProfessional
                this.user.fonction = res.data.data.collaborateur.function
                this.user.superiorLastName = res.data.data.collaborateur.superiorFirstName
                this.user.superiorFirstName = res.data.data.collaborateur.superiorLastName
                this.socialReason = res.data.data.collaborateur.company.socialReason

            }).catch(() => {
                // console.log(response);
            })
        }
    }
}
</script>

