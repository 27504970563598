import avenantRgpdResponsableDeTraitement from './avenantRgpdResponsableDeTraitement.vue'
import avisConstitutionComitePilotageRgpd from './avisConstitutionComitePilotageRGPD.vue'
import charteInformatique from './charteInformatique.vue'
import engagementDeConfidentialite from './engagementDeConfidentialite.vue'
import engagementDeLaDirection from './engagementDeLaDirection.vue'
import membreComitePilotageRgpd from './membreComitePilotageRgpd.vue'
import membreDuComiteBataBreach from './membreDuComiteBataBreach.vue'
import mentionContractuelleSalaries from './mentionContractuelleSalaries.vue'
import mentionContractuellesClientsDirectes from './mentionContractuellesClientsDirectes.vue'
import mentionContractuelleSoustraitants from './mentionContractuelleSoustraitants.vue'
import politiqueGeneraleDeGestionDesDonnees from './politiqueGeneraleDeGestionDesDonnees.vue'


export default {
    engagementDeLaDirection,
    mentionContractuelleSoustraitants,
    mentionContractuelleSalaries,
    politiqueGeneraleDeGestionDesDonnees,
    charteInformatique,
    avisConstitutionComitePilotageRgpd,
    membreDuComiteBataBreach,
    avenantRgpdResponsableDeTraitement,
    mentionContractuellesClientsDirectes,
    engagementDeConfidentialite,
    membreComitePilotageRgpd,
}