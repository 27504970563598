<template>

  <div id="entreprise-table">
    <h1 class="mon-entreprise-table-title">{{ title }}</h1>
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <!-- table  -->
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th>Raison sociale</th>
            <th>Contact</th>
            <th>
              <div>
                Accepter
                <img src="@/assets/images/icons/accepter.svg" alt="accepter" />
              </div>
            </th>
            <th>
              <div>
                Refuser
                <img src="@/assets/images/icons/refuser.svg" alt="refuser" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="table_data.length>0">
          <tr v-for="item in table_data" :key="item.name">
            <td>{{ item.socialReason }}</td>
            <td>{{ item.contact }}</td>
            <td>
              <label :for="'Accepter[' + item.id + ']'">
                <img
                    src="@/assets/images/icons/radio-input.svg"
                    v-if="item.accepted == false || item.accepted == null"
                />
                <input
                    type="radio"
                    :name="'result[' + item.id + ']'"
                    :id="'Accepter[' + item.id + ']'"
                    value="1"
                    class="tw-hidden"
                    v-model="item.accepted"
                />
                <img
                    src="@/assets/images/icons/radio-check.svg"
                    alt="accepter"
                    v-if="item.accepted == true"
                />
              </label>
            </td>
            <td>
              <label :for="'Refuser['+item.id+']'">
                <img
                    src="@/assets/images/icons/radio-input.svg"
                    v-if="item.accepted == true || item.accepted == null"
                />
                <input
                    type="radio"
                    :name="'result[' + item.id + ']'"
                    :id="'Refuser[' + item.id + ']'"
                    :checked="item.accepted"
                    value="0"
                    class="tw-hidden"
                    v-model="item.accepted"
                />
                <img
                    src="@/assets/images/icons/radio-disabled.svg"
                    alt="refuser"
                    v-if="item.accepted == false"
                />
              </label>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- end table  -->
    <v-btn
      elevation="1"
      text
      rounded
      large
      class="tw-text-white"
      :loading="isSendingForm"
      @click="onSubmit"
      v-if="table_data.length>0"
    >
      {{ formSubmitButton }}
    </v-btn>
    </template>
  </div>
</template>
<script>
import {
  ShareValiderContactApi,
  ShareGetContactApi,
} from "../../services/appApi";
export default {
  name: "EntrepriseTableComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
  },
  data() {
    return {
      loading_qst:false,
      table_data: [],
      isSendingForm: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onSubmit() {
      this.isSendingForm = true;
      let reqData = this.table_data.map((item)=> ({clientId:item.clientId, accepted : item.accepted === "1" ? true : false}));
      ShareValiderContactApi({"responses": reqData})
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.fetchData();
        }).catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },

    fetchData() {
      this.loading_qst=true;
      ShareGetContactApi()
        .then((res) => {
          this.table_data = res.data.data.map((data)=>{
            return {
              clientId: data.client.id, accepted: null, socialReason: data.client.socialReason , contact: data.client.user.firstName +" "+ data.client.user.lastName
            }
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.mesage,
            type: "error",
          });
        })
        .finally(() => {
          this.isSendingForm = false;
          this.loading_qst = false;
        });
    },
  },
};
</script>
