<template>
  <div class="-tw-mx-4 tw-mt-10 tw-mb-5 tw-text-black-100">
    <EntrepriseHeaderBlockTextComponent :title="`${tradeName} , mon registre de traitement`" />
    <h3 class="tw-mt-4 tw-text-justify tw-text-xs md:tw-w-9/12">
      Veuillez vérifier, modifier si besoin ou compléter les informations pour lister les activités de l’entreprise
      impliquant le
      traitement de données personnelles. Il n’est pas nécessaire que vous ayez autant d’activités que ce qui vous est
      proposé. Mais qui peut le plus, peut le moins.
      <br />
      <br />
      Quand vous aurez tout vérifié et tout renseigné, les collaborateurs de votre entreprise concernés recevront un
      e-mail les
      invitant à compléter la ou les fiches du registre de l’activité qui les concernent. Dans tous les cas, il faut une
      fiche de
      registre d’activité pour chaque activité listée.
    </h3>
    <h3 class="tw-mt-2 tw-text-justify tw-text-xs tw-font-bold md:tw-w-9/12">
      Voilà c’est aussi simple que ça !
    </h3>
    
    <activities-list class="tw-mt-10" @handleActivitiesChanged="handleActivitiesChanged" :oldActivities="data" />
    

    <div class="tw-mt-4 tw-flex tw-justify-center">
      <PrimaryButtonComponent :loading.camel="isSendingForm" :style="{
        backgroundColor: `${referer.theme.ctaBgColor} !important`,
        color: `${referer.theme.ctaTextColor} !important`
      }" @click="submit"
        class="tw-cursor-pointer tw-select-none tw-px-8 tw-py-2 tw-text-white tw-rounded-2xl tw-font-bold tw-text-xs dynamic-bg">
        Passer à la dernière étape</PrimaryButtonComponent>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivitiesList from '../components/ActivitiesList';
import { validateSecondStepRegister } from "@/features/ProcessingRegister/services/appApi";
import { getNewCollaboratorswithoutPagination } from "@/features/company/services/appApi.js";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
export default {
  components: { EntrepriseHeaderBlockTextComponent, PrimaryButtonComponent, ActivitiesList },
  computed: {
    ...mapGetters({
      tradeName: 'getAuthUserTradeName',
      register: 'getProcessingRegister'
    }),
    referer() {
      return this.$store.state.referrer;
    }
  },
  data() {
    return {
      isSendingForm: false,
      data: [],
      form: {
        activities: [],
      },
    }
  },
  mounted() {
    this.getRegisters();
  },
  methods: {
    getRegisters() {
      getNewCollaboratorswithoutPagination('register')
        .then((res) => {
          console.log("🚀 ~ file: SecondStep.vue:66 ~ .then ~ res:", res)
          this.data = res.data;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
    },
    handleActivitiesChanged(activities) {
      this.form.activities = activities;
    },
    submit() {
      this.isSendingForm = true;

      this.form.activities = this.form.activities.filter(item => Object.values(item).some(value => value !== null && value !== ''));
      validateSecondStepRegister(this.form)
        .then(response => {
          if (response.data.data.invalidCollaboratorsErrors.length > 0) {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Attention",
              position: "bottom right",
              text: "L'un des activités saisie est incorrect",
            });
          } else {
            console.log(response.data);
            this.$store.dispatch('setProcessingRegister', { ...this.register, activities: response.data.data.validCollaborators });
            this.$router.push({ name: 'Register.LastStep.Page' })
          }
        })
        .catch(({ response }) => {
          console.log(response);
          if (response.status === 400) {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Attention",
              position: "bottom right",
              text: "Veuillez remplir au moins une activité",
            });
          }
        })
        .finally(() => this.isSendingForm = false);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
