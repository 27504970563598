<template>
  <div id="ecosystem-add-container">
    <div
      class="tw-flex tw-flex-col tw-align-start"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Cartographie de l’écosystème de la donnée<br> personnelle de votre entreprise"
      />
    </div>
    <div>
      <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="1" />
    </div>
    <div class="collaborator-form-container">
      <SignupSecondaryTextComponent class="public-text-register">
        <b>Création de la liste des collaborateurs de l’entreprise.</b>
      </SignupSecondaryTextComponent>
      <p class="tw-text-xs tw-mt-4 md:tw-w-8/12">
        C’est une étape cruciale qui ne vous prendra pas plus de temps que ça.<br />
        Il est important d’identifier les collaborateurs de l’entreprise qui font partie de cet écosystème,
        qui collectent, utilisent, manipulent et archivent les données personnelles de personnes physiques
        que votre entreprise est amenée à traiter dans le cadre de ses activités.
      </p>
      <SignupSecondaryTextComponent class="tw-mt-6 dynamic-text">
        <b> Ajouter une liste complète de collaboratrices et de collaborateurs </b>
      </SignupSecondaryTextComponent>
      <div class="collaborators-row">
        <div class="collaborators-col-8">
          <CollaborateurImportBlockTextComponent
              title="1. Téléchargez d’abord le modèle"
              description="Pour vous permettre d’importer avec plus de facilité la liste de vos collaborateurs, téléchargez ce modèle."
          />
          <div class="tw-flex tw-flex-col tw-items-center">
            <v-btn elevation="0" text rounded large @click="downloadCSVData" download outlined>
              Télécharger le modèle
              <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.4375 3.66667H6.1875V0H2.8125V3.66667H0.5625L4.5 8.55556L8.4375 3.66667ZM0 9.77778H9V11H0V9.77778Z" :fill="referer.theme.ctaBgColor"/>
              </svg>
            </v-btn>
            <p class="tw-text-center">
              Ce fichier est un Excel .csv comprenant 5 colonnes, dans l’ordre : la civilité, le prénom, le nom,
              l’email et le service de vos collaborateurs. Ne modifiez pas l’ordre des colonnes.
            </p>
          </div>
        </div>
        <div class="collaborators-col-4">
          <CollaborateurImportBlockTextComponent
              title="2. Importez votre liste de collaborateurs"
              description="Glissez / déposez votre fichier Excel .csv ici, ou importez le grâce au bouton ci-dessous !"
          />

          <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :useCustomSlot="true"
              :options="dropzoneOptions"
              class="upload-file"
              :includeStyling="false"
              @vdropzone-removed-file="fileRemoved"
              @vdropzone-file-added="fileAdded"
          >
            <div class="dropzone-custom-content tw-flex tw-flex-col tw-items-center tw-justify-around">
              <img src="@/assets/images/icons/upload.svg" />
              <span class="title-upload-drpzone">Glissez votre liste de collaborateurs</span>
              <v-btn
                  elevation="1"
                  text
                  rounded
                  large
                  :loading="isUploadModel"
                  class="tw-text-white tw-mb-4"
              >
                Ou importer
              </v-btn>
            </div>
          </vue-dropzone>
        </div>
      </div>
      <div class="collaborators-row tw-items-center tw-justify-center">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.373 20.086C20.373 17.16 18.161 15.088 15.375 15.088C12.617 15.088 10.363 17.16 10.363 20.086C10.363 23.026 12.617 25.098 15.375 25.098C18.147 25.098 20.373 23.026 20.373 20.086ZM12.379 20.086C12.379 18.098 13.597 16.852 15.375 16.852C17.139 16.852 18.357 18.098 18.357 20.086C18.357 22.074 17.139 23.348 15.375 23.348C13.597 23.348 12.379 22.074 12.379 20.086ZM21.3032 21.262C21.3032 23.838 23.0672 25.098 25.2092 25.098C27.3512 25.098 29.1992 23.838 29.1992 21.262V15.228H27.2252V21.276C27.2252 22.62 26.4972 23.32 25.2372 23.32C23.9912 23.32 23.2632 22.62 23.2632 21.276V15.228H21.3032V21.262Z" :fill="referer.theme.ctaBgColor" />
          <circle cx="20" cy="20" r="19.5" :stroke="referer.theme.ctaBgColor" />
        </svg>
      </div>
      <div class="collaborators-row">
          <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-3 tw-w-full">
            <SelectBoxComponent
              v-model="form.gender"
              :items="genderItems"
              label="Civilité"
              :error="errors.gender != ''"
              :error-messages="errors.gender"
            />
            <TextFieldComponent
              v-model="form.firstName"
              label="Prénom"
              :error="errors.firstName != ''"
              :error-messages="errors.firstName"
            />
            <TextFieldComponent
              v-model="form.lastName"
              label="Nom"
              :error="errors.lastName != ''"
              :error-messages="errors.lastName"
            />
            <TextFieldComponent
              v-model="form.email"
              label="Adresse e-mail"
              :error="errors.email != ''"
              :error-messages="errors.email"
            />
            <TextFieldComponent
              v-model="form.function"
              label="Fonction"
              :error="errors.function != ''"
              :error-messages="errors.function"
            />
          </div>
      </div>
      <div class="collaborators-row tw-items-center tw-justify-center tw-space-x-12">
          <PrimaryButtonComponent class="collaborator-add-return-btn tw-mt-6" @click="back"
          >Précédent</PrimaryButtonComponent
          >
          <PrimaryButtonComponent class="collaborator-add-validate-btn tw-mt-6" @click="submit"
          >Suivant</PrimaryButtonComponent
          >
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import StepsComponent from "@/features/auth/components/StepsComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import CollaborateurImportBlockTextComponent from "@/components/common/CollaborateurImportBlockTextComponent";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import SelectBoxComponent from "@/components/common/SelectBoxComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "NewEcoSystemStepOnePage",
  components: {
    PrimaryButtonComponent,
    TextFieldComponent,
    SelectBoxComponent,
    CollaborateurImportBlockTextComponent,
    SignupSecondaryTextComponent,
    StepsComponent,
    EntrepriseHeaderBlockTextComponent,
    vueDropzone: vue2Dropzone,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
		fileInputText() {
			return this.file ? "" : "AUCUN FICHIER CHOISI"
		},
		fileLInk() {
      return require("@/assets/csv/collaborators.csv");
		},
	},
  data() {
    return {
      isUploadModel: false,
      isImportFile: false,
      file: null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 50,
        maxFiles:1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        addRemoveLinks: true,
        autoProcessQueue: false,
        uploadMultiple:1
      },

      isSendingForm: false,
      formValid: false,
      disableInput: true,
      genderItems: [
        { label: "Madame", value: "female" },
        { label: "Monsieur", value: "male" },
      ],
      errors: {
        gender: "",
        email: "",
        firstName: "",
        lastName: "",
        function: ""
      },
      form: {
        gender: "",
        email: "",
        firstName: "",
        lastName: "",
        function: ""
      },
    };
  },
  methods: {
    back() {
      this.$router.push({
        name: "EcoSystem.Add.Guide"
      });
    },
    validateForm() {
      this.errors = {
        gender: (
          (this.form.gender === "" && "Veuillez sélectionner une civilité") ||
          ""
        ),
        email: (
          (this.form.email === "" && "Veuillez saisir une adresse e-mail") ||
          (
            !this.form.email.match(
              /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
            ) && "Veuillez saisir une adresse e-mail valide"
          ) ||
          ""
        ),
        firstName: "",
        lastName: "",
        function: "",
      };

      const isThereNoError = Object.values(this.errors).every(
        (error) => error === ""
      );
      return isThereNoError;
    },
    submit() {
      if(!this.file) {
        if(!this.validateForm()) return;

        const data = { one: this.form, multi: null };
        this.isSendingForm = true;
        this.$store.dispatch("addCollaborator", data);
        this.$router.push({
          name: "EcoSystem.Add.Step2.EMAIL"
        });
        this.isSendingForm = false;
      }
      else {
        let formData = new FormData();
        formData.append("file", this.file);

        this.$store.dispatch("loadCollaboratorValid", []);
        this.$store.dispatch("loadCollaboratorInvalid", []);

        this.$router.push({
          name: "EcoSystem.Add.Step2",
          params: {
            formData,
          },
        });
      }
    },
    onFileChanged(event) {
			this.file = event.target.files[0]
		},
    fileRemoved() {
      this.file = null;
    },
    fileAdded(file) {
      if (this.file) {
        this.$refs.myVueDropzone.removeFile(this.file);
      }
      this.file = file;
    },
    afterComplete(file) {
      this.file = file;
    },
    downloadCSVData () {
        var str = "gender;firstName;lastName;email;function";
        var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement('a');
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'viqtor-collaborateurs-model.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
  },
};
</script>
