<template>
  <v-text-field

    class="company-text-field"
    :class="customClass"
    v-bind="$attrs"
    v-on="$listeners"
    @click:append="toggleRightIcon"
  ></v-text-field>
</template>

<script>
export default {
  name: "TextFieldCompanyComponent",
  props: {
    containerStyle: {
      type: String,
      default: "",
    },
    toggleRightIcon: {
      type: Function,
      default: () => {},
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-label {
  top: 0px !important;
}
</style>
