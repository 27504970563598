<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="60%" content-class="rounded-xl">
    <div class="tw-py-11 tw-px-9">
      <EntrepriseHeaderBlockTextComponent
        title="Ajouter un collaborateur à votre équipe d’Expert."
        description="Complétez les informations ci-dessous pour ajouter un collaborateur."
      />
      
      <div class="tw-mt-12 tw-flex tw-space-x-10">
        <TextFieldComponent
          v-model="form.firstName"
          label="Prénom"
          :hide-details="!errors.firstName.length"
          :error="errors.firstName.length > 0"
          :error-messages="errors.firstName"
          style="font-size: 14px !important;"
          persistent-placeholder
          custom-class="custom-text-field"
        />
        <TextFieldComponent
          v-model="form.lastName"
          label="Nom"
          :hide-details="!errors.lastName.length"
          :error="errors.lastName.length > 0"
          :error-messages="errors.lastName"
          style="font-size: 14px !important;"
          persistent-placeholder
          custom-class="custom-text-field"
        />
      </div>

      <div class="tw-mt-5 tw-flex tw-space-x-10">
        <TextFieldComponent
          v-model="form.email"
          label="Adresse email"
          :hide-details="!errors.email.length"
          :error="errors.email.length > 0"
          :error-messages="errors.email"
          style="font-size: 14px !important;"
          persistent-placeholder
          custom-class="custom-text-field"
        />
        <TextFieldComponent
          v-model="form.service"
          label="Service"
          :hide-details="!errors.service.length"
          :error="errors.service.length > 0"
          :error-messages="errors.service"
          style="font-size: 14px !important;"
          persistent-placeholder
          custom-class="custom-text-field"
        />
      </div>

      <div class="tw-mt-12 tw-flex tw-justify-center tw-items-center tw-space-x-8">
        <span 
          class="tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-16 tw-cursor-pointer" 
          :style="{ 
            border: `1px solid ${referrer.theme.ctaBgColor}`,
            color: `${referrer.theme.ctaBgColor}` 
          }"
          @click="$emit('close')"
        >Fermer</span>
        <v-btn
          elevation="0" text rounded large
          class="tw-text-white tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer" 
          :style="{ 
            backgroundColor: `${referrer.theme.ctaBgColor}`
          }"
          :loading="isLoading"
          @click="addCollaboratorHandler"
        >
          <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">Créer le collaborateur</span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import TextFieldComponent from "@/components/common/TextFieldCompanyComponent";
import { validateExpertTeamMember } from "@/features/company/services/appApi";
import { mapState } from "vuex";

export default {
  name: 'AddCollaboratorPopup',
  components: {
    EntrepriseHeaderBlockTextComponent,
    TextFieldComponent,
  },
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
    previousMember: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      open: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        service: ""
      },
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        service: []
      },
      isLoading: false,
    }
  },
  computed: {
    ...mapState(["referrer"]),
  },
  watch: {
    canOpen: { handler(val) { (this.open = val) }, immediate: true },
    form: {
      handler() {
        this.errors = {
          firstName: [],
          lastName: [],
          email: [],
          service: []
        }
      },
      deep: true
    },
    previousMember: {
      handler(val) {
        if (val) {
          this.form = {
            firstName: val.firstName,
            lastName: val.lastName,
            email: val.email,
            service: val.service
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    addCollaboratorHandler() {
      this.isLoading = true;
      validateExpertTeamMember(this.form)
        .then(() => { this.$emit('added', this.form); })
        .catch((error) => { 
          if (error.response.data.errors)
            this.errors = { ...this.errors, ...error.response.data.errors }; 
          else
            this.$snackbar.showAlertMessage({ message: error.response.data.message, type: "error" });
        })
        .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style>
.company-text-field.custom-text-field.v-text-field input {
  padding: 0 1rem !important;
  height: 40px !important;
}
</style>