<template>
  <v-dialog v-if="handelPopup" v-model="handelPopup" @click:outside="closePopup" width="600"
    transition="dialog-bottom-transition">
    <!-- <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/> -->
    <div id="deleteDialog" class="tw-p-8">
      <h2 class="tw-font-bold tw-mb-6">Modification du register de traitement</h2>
      <hr class="tw-w-full tw-border-black" />
      <p>
        <text-field-component class="register-activity-input tw-w-96" label="" placeholder="Activité"
          v-model="module.activity" :error-messages="errors.activity" />
      </p>
      <div class="tw-flex tw-justify-around tw-w-full">
        <v-btn elevation="3" text large @click="closePopup" class="tw-text-gray-100 tw-cursor-pointer normal-btn">
          ANNULER
        </v-btn>
        <v-btn elevation="3" text large :loading="isSendingForm" @click="updateItem"
          class="tw-text-white tw-cursor-pointer">
          CONFIRMER
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import TextFieldComponent from '../../../components/common/TextFieldComponent.vue';
import { updateRegister } from '../services/appApi';
export default {
  components: { TextFieldComponent },
  name: "ModificationPopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    handelPopup() {
      return this.openPopup;
    }
  },
  data() {
    return {
      isSendingForm: false,
      module:{
        activity: ''
      },
      errors:{
        activity: ''
      },
    };
  },
  mounted(){
    this.module.activity = this.item.registerActivityName;
  },
  methods: {
    updateItem() {
      this.isSendingForm = true;
      updateRegister(this.item.id,this.module)
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: 'modifié avec succès',
            type: "success",
          });
          this.$emit('fetchData');
          this.$parent.openPopupModification = false;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message[0],
            type: "error",
          });
        }).finally(() => {
          this.isSendingForm = false;
          this.closePopup();
        });
    },
    closePopup() {
      this.$emit('close');
      this.$parent.openPopup = false;
    }
  },
};
</script>
<style lang="scss" scoped>
#deleteDialog {
  position: relative;

  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }

  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
