<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    width="65%"
    transition="dialog-bottom-transition"
    persistent
  >
    <div id="client-form" class="tw-px-12 tw-py-8">
      <div class="tw-flex tw-justify-end">
        <svg @click="$emit('close')" class="tw-cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.3242 0.5L0.824219 15" stroke="black"/>
          <path d="M0.824218 0.499999L15.3242 15" stroke="black"/>
        </svg>
      </div>

      <h3 class="tw-text-center tw-text-black tw-text-2xl tw-font-medium">Informations</h3>

      <div class="tw-flex tw-space-x-10 tw-mt-20">
        <TextFieldComponent
          v-model="form.firstName"
          label="Prénom"
          :disabled="disableInput"
          :hide-details="!errors.firstName.length"
          :error="errors.firstName.length > 0"
          :error-messages="errors.firstName"
          style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.lastName"
          label="Nom"
          :disabled="disableInput"
          :hide-details="!errors.lastName.length"
          :error="errors.lastName.length > 0"
          :error-messages="errors.lastName"
          style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.email"
          label="Email"
          :disabled="disableInput"
          :hide-details="!errors.email.length"
          :error="errors.email.length > 0"
          :error-messages="errors.email"
          style="font-size: 14px !important; width: 340px !important;"
          persistent-placeholder
        />
      </div>
      <div class="tw-flex tw-space-x-10" :class="{'tw-mt-5': disableInput, 'tw-mt-8': !disableInput}">
        <TextFieldComponent
          v-model="form.service"
          label="Service"
          :disabled="disableInput"
          :hide-details="!!errors.service && !errors.service.length"
          :error="!!errors.service && errors.service.length > 0"
          :error-messages="errors.service"
          style="width: 30%; font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.phone"
          label="N° de téléphone"
          :disabled="disableInput"
          :hide-details="!!errors.phone && !errors.phone.length"
          :error="!!errors.phone && errors.phone.length > 0"
          :error-messages="errors.phone"
          style="width: 30%; font-size: 14px !important;"
          persistent-placeholder
        />
        <div style="width: 30%;"></div>
      </div>
      <div class="tw-mt-12 tw-flex tw-justify-center tw-items-center tw-space-x-8">
        <span 
          class="tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer" 
          :style="{ 
            border: `1px solid ${referrer.theme.ctaBgColor}`,
            color: `${referrer.theme.ctaBgColor}` 
          }"
          @click="$emit('close')"
        >Fermer</span>
        <v-btn
          elevation="0" text rounded large
          class="tw-text-white tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer" 
          :style="{ 
            backgroundColor: `${referrer.theme.ctaBgColor}`
          }"
          :loading="isLoading"
          @click="updateClientHandler"
        >
          <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4">{{ disableInput ? 'Modifier' : 'Valider' }}</span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import TextFieldComponent from "@/components/common/TextFieldCompanyComponent";
import { updateExpertTeamMember } from "@/features/company/services/appApi";
import { mapState } from "vuex";

export default {
  components: {
    TextFieldComponent,
  },
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        service: null,
        phone: null,
      },
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        service: [],
        phone: [],
      },
      disableInput: true,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(["referrer"]),
    handelPopup() {
      return this.openPopup;
    },
  },
  mounted() {
    this.dialog = this.openPopup;
    this.initForm();
  },
  watch: {
    form: {
      handler() {
        this.errors = {
          socialReason: [],
          firstName: [],
          lastName: [],
          email: [],
          address: [],
          zipCode: [],
          city: [],
        };
      },
      deep: true,
    },
  },
  methods: {
    initForm() {
      this.form = {
        id: this.item.id ?? "",
        firstName: this.item.firstName ?? "",
        lastName: this.item.lastName ?? "",
        email: this.item.email ?? "",
        service: this.item.service ?? "",
        phone: this.item.phone ?? "",
      };
    },
    updateClientHandler() {
      if (this.disableInput) {
        this.disableInput = false;
        return;
      }

      this.isLoading = true;
      updateExpertTeamMember(this.form)
        .then(({ data: { message }}) => {
          this.$snackbar.showAlertMessage({ message: message, type: "success" });
          this.disableInput = true;
          this.$emit("updated");
        })
        .catch(({ response }) => {
          if (response.status === 400)
            this.errors = { ...this.errors, ...response.data.errors };
          else
            this.$snackbar.showAlertMessage({ message: "Une erreur est survenue", type: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
#client-form input:disabled, 
#client-form .v-select__slot:disabled {
  background-color: white !important;
  border: none;
  box-shadow: none;
  padding-left: 0 !important;
  padding-top: 0 !important;
  color: var(--cta-bg-color) !important;
}

#client-form label {
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: 500 !important;
}
</style>
