<template>
    <v-container class="px-12 overflow-auto">
      <Breadcrumb divider=">"></Breadcrumb>

      <v-row no-gutters class="mb-12 mt-4">
        <v-col cols="12" class="mb-12 mt-4">
          <h1 class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl">Vérifiez votre liste !</h1>
          <h5 class="tw-tracking-tight tw-text-base caption">Retrouvez ici la liste de vos sous-traitants.</h5>
        </v-col>

        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Raison sociale
                  </th>
                  <th>
                    Contact
                  </th>
                  <th>
                    Soumis par
                  </th>
                  <th>
                    Sélection
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in table_data"
                  :key="item.name"
                >
                  <td>{{ item.raison_sociale }}</td>
                  <td>{{ item.contact }}</td>
                  <td>{{ item.soumis_par }}</td>
                  <td>
                    <v-checkbox color="indigo"></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" class="mb-12 mt-4 d-flex justify-end">
          <PrimaryButtonComponent class="confirme-btn">Confirmer</PrimaryButtonComponent>
        </v-col>

      </v-row>
    </v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "SubContractorImportVerification",

  computed: {

  },

  data() {
    return {
      table_data: [
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        },
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        },
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        },
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        },
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        },
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        },
        {
          raison_sociale: 'Chrisitian Dior',
          contact: 'Marine Dupond',
          soumis_par: 'Jean Dujardin',
        }
      ],
    };
  },

   components: {
    Breadcrumb,
    PrimaryButtonComponent,
  },

  methods: {

  },
};
</script>

<style lang="scss">
.v-application ol, .v-application ul {
  padding-left: 0 !important;
}

.confirme-btn {
  height: 45px !important;
  padding: 0 !important;
  font-size: 12px !important;
  min-width: 100px !important;
  width: 120px !important;
  margin: 0 12px;
  font-weight: normal !important;
  color: #fff !important;
}
</style>
