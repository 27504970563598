<template>
    <transition name="slide-fade">
        <div class="tw-mt-4 tw-mb-5 tw-text-black-100">
            <v-btn tile depressed color="white" @click="back"  elevation="0" class="pa-0 tw-tracking-tighter tw-lowercase tw-font-bold">
                <v-icon size="30" :color="referer.theme.ctaBgColor" class="tw-mr-2">mdi-arrow-left</v-icon> retour
            </v-btn>
            <PreRegisterWithoutEcosystemGuideComponent/>
            <br/>
            <div class="tw-mt-20 tw-flex tw-justify-center tw-space-x-12">
                <div
                    class="selection"
                    :class="{'selection-active': canLaunchCompanyDataEcosystemMapping}"
                    @click="(canLaunchCompanyDataEcosystemMapping = true)"
                >OUI je lance la cartographie de mon écosystème de la donnée</div>
                <div
                    class="selection"
                    :class="{'selection-active': !canLaunchCompanyDataEcosystemMapping}"
                    @click="(canLaunchCompanyDataEcosystemMapping = false)"
                >NON je préfère moi-même renseigner le registre des activités</div>
            </div>
            <br/>
            <div>
                <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="3" />
            </div>
            <div class="tw-mt-12  tw-flex tw-flex-row tw-items-center tw-justify-between">
                <p class="tw-text-xs tw-mr-24">
                    {{stepOneText}}
                </p>
                <p class="tw-text-xs tw-mr-24">
                    {{stepTwoText}}
                </p>
                <p class="tw-text-xs">
                    {{stepThreeText}}
                </p>
            </div>
            <br/>
            <br/>
          
            <div class="tw-mt-24 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-6">
                <PrimaryButtonComponent
                    :style="{
                        backgroundColor: `${referer.theme.ctaBgColor} !important`,
                        color: `${referer.theme.ctaTextColor} !important`
                    }"
                    type="submit"
                    @click="submit">
                    C'est parti !
                </PrimaryButtonComponent>
            </div>
        </div>
    </transition>
</template>

<script>
import StepsComponent from "@/features/auth/components/StepsComponent";

import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import PreRegisterWithoutEcosystemGuideComponent from '@/components/common/PreRegisterWithoutEcosystemGuideComponent.vue'
import { mapGetters } from "vuex";

export default {
    components: {
        PreRegisterWithoutEcosystemGuideComponent,
        PrimaryButtonComponent,
        StepsComponent
    },
    data() {
        return {
            canLaunchCompanyDataEcosystemMapping: false,
                        stepOneText:"Vous allez passer en revue le registre des activités, dont les informations préremplies provenant de la cartographie de votre écosystème de la donnée que vous avez effectuée.Il vous faut vérifier, modifier et/ou compléter les informations.",
            stepTwoText:"Une fois le recensement des activités effectué, vous serez en mesure de faire procéder à l’analyse des traitements de des données personnelles. Cela leur prendra à peine 10 minutes. Au final, vous aurez un registre des activités exhaustives et précises.",
            stepThreeText:"Un message prérédigé vous est proposé, vous pouvez le modifier comme vous le souhaitez, et l’envoyer en un clic à tous les collaborateurs concernés.Vous n’avez rien de plus à faire, vous en avez pour 5 minutes tout au plus !Après, Victor gèrera pour vous.",

        }
    },
    computed: {
        referer() {
      return this.$store.state.referrer;
    },
      ...mapGetters({
        processingRegister: 'getProcessingRegister'
      })
    },
  methods: {
    submit() {
        if (this.canLaunchCompanyDataEcosystemMapping) {
             this.$router.push({name: 'EcoSystem.List' });
        } else {
            this.$router.push({ name: 'Register.SecondStep.Page' })
        }
    },
    back() {
        this.$router.push({ name: 'Register.PreRegister.Page' });
    }
  }
}
</script>

<style lang="scss" scoped>
.selection {
    @apply tw-w-[320px] tw-py-5 tw-px-3 tw-border tw-border-gray-100 tw-rounded-3xl tw-text-center tw-text-gray-100 tw-font-medium tw-leading-6 tw-cursor-pointer;
}

.selection-active {
    @apply tw-border tw-border-brand tw-text-brand;
}
.cta {
  max-width: 200px;
    @apply tw-px-14 tw-py-2 tw-text-white tw-rounded-2xl tw-font-bold tw-text-xs tw-bg-brand tw-cursor-pointer;
}

.slide-fade-enter-active {
    animation: slide-fade .8s;
}
.slide-fade-leave-active {
    display: none;
}
@keyframes slide-fade {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
</style>
