<template>
  <v-app class="tw-pt-0 tw-mt-0">
    <AssistHeader class="public-header" />
    <router-view></router-view>
    <AssistFooter v-if="checkLanding" class="tw-w-full" />
    <FooterLanding v-else class="tw-w-full" />
  </v-app>
</template>

<script>
import AssistHeader from "./components/layout/assist/AssistHeader";
import AssistFooter from "./components/layout/assist/AssistFooter";
import FooterLanding from "./components/layout/landing/Footer";

export default {
  name: "App",
  computed: {
    checkLanding() {
      return this.$route.name !== "landing-public";
    },
  },
  data() {
    return {
      activeRoute: this.$route.name,
    };
  },
  watch: {
    $route() {
      this.activeRoute = this.$route.name;
    },
  },
  components: {
    AssistFooter,
    AssistHeader,
    FooterLanding,
  },
};
</script>

<style type="scss">
@import "assets/styles/auth/public.scss";
</style>
