<template>
  <div id="collaborateurs-table">
    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>
    <!-- table  -->
    <v-simple-table v-else height="600px">
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              <div
                @click="addToOrder('companies.socialReason')"
                class="tw-flex tw-justify-start tw-cursor-pointer"
                style="width: max-content"
              >
                <span style="width: max-content">Nom de la société</span>
                <img
                  v-if="orderby === 'companies.socialReason' && orderType === 'desc'"
                  src="@/assets/images/icons/sort-desc.svg"
                  alt=""
                />
                <img
                  v-else-if="orderby === 'companies.socialReason' && orderType === 'asc'"
                  src="@/assets/images/icons/sort-asc.svg"
                  alt=""
                />
                <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
              </div>
            </th>
            <th>
              <div
                @click="addToOrder('users.firstName')"
                class="tw-cursor-pointer tw-flex"
              >
                <span>Contact</span>
                <img
                  v-if="orderby == 'users.firstName' && orderType == 'desc'"
                  src="@/assets/images/icons/sort-desc.svg"
                  alt=""
                />
                <img
                  v-else-if="orderby == 'users.firstName' && orderType == 'asc'"
                  src="@/assets/images/icons/sort-asc.svg"
                  alt=""
                />
                <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
              </div>
            </th>
            <th>
              <div
                @click="addToOrder('users.email')"
                class="tw-cursor-pointer tw-flex"
              >
                <span>Email</span>
                <img
                  v-if="orderby == 'users.email' && orderType == 'desc'"
                  src="@/assets/images/icons/sort-desc.svg"
                  alt=""
                />
                <img
                  v-else-if="orderby == 'users.email' && orderType == 'asc'"
                  src="@/assets/images/icons/sort-asc.svg"
                  alt=""
                />
                <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
              </div>
            </th>
            <th>Téléphone</th>
            <th class="!tw-text-center">Statut</th>
            <th class="!tw-text-center" v-if="!user.collaborateur">Collaborateurs</th>
            <th class="!tw-text-center" v-if="!user.collaborateur">Equipes</th>
            <th class="!tw-text-center">Action</th>
          </tr>
        </thead>
        <tbody v-if="data.length > 0">
          <tr
            v-for="item in data"
            :key="item.id"
            @click="showDetails(item)"
            class="tw-cursor-pointer"
          >
            <td>{{ item.socialReason }}</td>
            <td :title="item.users[0].firstName + ' ' + item.users[0].lastName" class="limit-length">
              {{ 
                (item.users[0].firstName + ' ' + item.users[0].lastName).length > 30 
                  ? (item.users[0].firstName + ' ' + item.users[0].lastName).substring(0,30) + '...' 
                  : (item.users[0].firstName + ' ' + item.users[0].lastName)
              }}
            </td>
            <td>{{ item.users[0].email }}</td>
            <td>{{ item.users[0].phone }}</td>
            <td v-html="handleStatusSheet(item.users[0])"></td>
            <td v-if="!user.collaborateur">
              <div 
                v-if="item.expertCollaborators.length === 0"
                class="tw-flex tw-justify-center tw-items-center"
              ><span> - </span></div>
              <div 
                v-if="item.expertCollaborators.length > 0"
                class="tw-flex tw-items-center tw-justify-center tw-space-x-0.5"
              >
                <div v-for="(collaborator, index) in (item.expertCollaborators.length > 3 ? item.expertCollaborators.slice(0, 2) : item.expertCollaborators)" :key="index">
                  <div
                    :style="{ 'background-color': generateSpecialColor(`${collaborator.firstName} ${collaborator.lastName}`) }"
                    class="tw-rounded-full tw-text-black tw-bg-gray-500 tw-flex tw-items-center tw-justify-center tw-ring-white"
                  >
                    <v-tooltip top content-class="tw-bg-white tw-border tw-border-black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <span class="tw-text-white">{{ getShortAbreviation(`${collaborator.firstName} ${collaborator.lastName}`) }}</span>
                        </v-btn>
                      </template>
                      <span class="tw-text-black">{{ `${collaborator.firstName} ${collaborator.lastName}` }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div
                  v-if="item.expertCollaborators.length > 3"
                  :style="{ 'background-color': '#2A353A' }"
                  class=" tw-h-9 tw-p-1 tw-w-9 tw-uppercase tw-rounded-full tw-text-white tw-bg-gray-500 tw-flex tw-items-center tw-justify-center tw-ring-white tw-font-medium"
                >
                  +{{ item.expertCollaborators.length - 2 }}
                </div>
              </div>
            </td>
            <td v-if="!user.collaborateur">
              <div class="tw-relative tw-flex tw-justify-center tw-col-span-2">
                <div class="tw-pl-4" v-if="item.expertTeams.length == 0">-</div>
                <div v-if="item.expertTeams.length == 1">
                  <BadgeComponent :label="item.expertTeams[0].name" />
                </div>
                <div v-if="item.expertTeams.length > 1">
                  <v-tooltip right content-class="tw-bg-white tw-border tw-border-black">
                    <template v-slot:activator="{ on, attrs }">
                      <span 
                        v-bind="attrs" v-on="on"
                        class="tw-text-white tw-bg-[#2A353A] tw-py-1 tw-px-6 tw-text-xs tw-font-medium tw-rounded-full"
                        style="width: 7rem; display: block;"
                      >{{ item.expertTeams.length }} équipes</span>
                    </template>
                    <div class="tw-flex tw-flex-col tw-text-black tw-text-xs">
                      <span v-for="team in item.expertTeams" :key="team.id">{{ team.name }}</span>
                    </div>
                  </v-tooltip>
                </div>
              </div>
            </td>
            <td>
              <div class="tooltip" :class="{'is-collab': user.collaborateur}" style="display: flex; align-items: center; justify-content: center;" v-if="item.users[0].isAccountActive || !user.collaborateur">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                </svg>
                <div class="tooltiptext">
                  <ul>
                    <li v-if="item.users[0].isAccountActive" class="tw-cursor-pointer" @click.stop="showClient(item.users[0])">Voir le compte</li>
                    <li v-if="!user.collaborateur" class="tw-cursor-pointer" @click.stop="addOrRemoveCollaborators(item)">Attribuer</li>
                    <li v-if="!user.collaborateur" class="tw-cursor-pointer" @click.stop="supprimerClient(item.users[0])">Supprimer</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">Pas de données disponibles</td>
          </tr>
        </tbody>
        <tfoot class="text-center" v-if="length > 1">
          <tr>
            <td colspan="100%">
              <v-pagination
                :color="referer.theme.ctaBgColor"
                v-model="page"
                :length="length"
                @input="pagination"
              ></v-pagination>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <!-- end table  -->
    <ClientConfirmeDeletePopup
      v-if="selectedClientId && openPopup"
      :openPopup="openPopup"
      :selected-client-id="selectedClientId"
      v-on:fetchData="pagination"
      @close="openPopup = false"
    />
    <ClientPopup
      v-if="selectedClient && openClientPopup"
      :openPopup="openClientPopup"
      :item="selectedClient"
      @close="openClientPopup = false"
      @updated="pagination"
    />
    <AddOrRemoveCollaboratorsPopup
      :openPopup="openAddOrRemoveCollaboratorsPopup"
      :selected-company="selectedCompany"
      @close="() => (openAddOrRemoveCollaboratorsPopup = false)"
      @updated="pagination"
    />
  </div>
</template>
<script>
import {getDataBaseClientExpert, loginToClientExpert } from "@/features/company/services/appApi";
import ClientConfirmeDeletePopup from "../../../components/partage/ClientConfirmeDeletePopup.vue";
import ClientPopup from "../../../components/partage/ClientPopup.vue";
import AddOrRemoveCollaboratorsPopup from "./AddOrRemoveCollaboratorsPopup.vue";

export default {
  name: "ExpertClientDataBaseTableComponent",
  props: ["filters"],
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return ["Viqtor Expert"].includes(this.referer.title) &&
        this.user.expertType !== "company"
        ? "Company.DashboardPage"
        : "Company.Expert.DashboardPage";
    },
  },
  mounted() {
    this.collaborators = this.user.workInCompany.users;
    this.pagination();
  },
  data() {
    return {
      data: [],
      collaborators: [],
      page: 1,
      order: null,
      orderby: "",
      orderType: "",
      length: null,
      openPopup: false,
      selectedClientId: null,
      openClientPopup: false,
      openAddOrRemoveCollaboratorsPopup: false,
      selectedClient: {},
      loading_table: true,
      selectedCompany: null,
    };
  },
  methods: {
    addOrRemoveCollaborators(company) {
      this.selectedCompany = company;
      this.openAddOrRemoveCollaboratorsPopup = true;
    },
    
    addToOrder(item) {
      if (this.orderby === item) {
        this.orderType = this.orderType === "asc" ? "desc" : "asc";
      } else {
        this.orderby = item;
        this.orderType = "asc";
      }
      this.pagination();
    },
    pagination(
      value = this.page, 
      filters = this.filters
    ) {
      getDataBaseClientExpert(
        value, 
        { ...filters, orderBy: this.orderby, orderType: this.orderType }
      )
        .then(({ data }) => {
          this.data = data.items;
          this.page = data.meta.currentPage;
          this.length = data.meta.totalPages;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_table = false;
        });
    },
    showDetails(item) {
      this.selectedClient = item;
      this.openClientPopup = true;
    },
    showClient(item) {
      loginToClientExpert(item.id)
        .then((response) => {
          this.$store.dispatch("setExpertToken").then(() => {
            this.$store.dispatch("setToken", response.data.data.accessToken);
            if (
              !response.data.data.planPriceId &&
              response.data.data.subscribed === false
            )
              this.$router.push({ name: "SetupAccountCompany" });
            if (
              response.data.data.planPriceId &&
              response.data.data.subscribed === false
            )
              this.$router.push({
                name: "SetupAccountPricingCompany",
                params: { planId: response.data.data.planPriceId },
              });
            if (response.data.data.subscribed === true)
              this.$router.push({ name: this.dashboardName });

            this.$snackbar.showAlertMessage({
              message: "Vous êtes connecté en tant que client",
              type: "success",
            });
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
    supprimerClient(client) {
      this.selectedClientId = client.id;
      this.openPopup = true;
    },
    handleStatusSheet(item) {
      if (item.isAccountActive) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #27AE60; padding: 14px 0;">
                    Actif
                  </div>
                </div>`;
      } else {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E; padding: 14px 0;">
                    Inactif
                  </div>
                </div>`;
      }
    },
  },
  watch: {
    filters: {
      handler() {
        this.pagination();
      },
      deep: true,
    }
  },
  components: {
    ClientConfirmeDeletePopup,
    ClientPopup,
    AddOrRemoveCollaboratorsPopup,
  },
};
</script>
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  width: max-content;
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: 1.35rem;
  right: 0;
}

// .tooltip.is-collab .tooltiptext {
//   top: -6px;
//   right: 60px;
// }

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>
