<template>
  <div class="tw-flex tw-flex-col tw-justify-center tw-py-7 bg-color-primary tw-h-full tw-text-white">
    <div class="tw-flex tw-justify-center">
      <img :src="referrer.logoWhite" width="140" height="auto" alt="" />
    </div>
    <div class="tw-mt-14 tw-flex tw-flex-col tw-space-y-10" v-if="data">
      <span class="tw-self-center tw-text-lg">Cartographie de l’écosystème de la donnée personnelle</span>
      <span class="tw-self-center tw-uppercase tw-font-thin tw-text-3xl tw-tracking-wider"
        v-if="data.collaborateur.company">{{
          data.collaborateur.company.tradeName
        }}</span>
    </div>
    <div v-if="data" class="tw-flex tw-justify-center tw-my-24 tw-font-thin tw-text-2xl tw-uppercase">
      {{ data.collaborateur.firstName }} {{ data.collaborateur.lastName }}</div>
    <div class="tw-flex tw-justify-center tw-text-center tw-text-sm tw-w-1/2 tw-mx-auto">
      Dans le cadre de la mise en conformité avec le Règlement Général de la Protection des Données [RGPD]
      Merci de prendre les quelques minutes complémentaires pour renseigner le questionnaire qui suit.
    </div>
    <div class="tw-flex tw-justify-center tw-my-16">
      <primary-button-component
        class="tw-text-white tw-outline tw-outline-2 tw-outline-white tw-bg-transparent btn-step-hover hover:tw-bg-white"
        :style="{
          backgroundColor: `${referrer.theme.ctaBgColor} !important`,
          color: `${referrer.theme.ctaTextColor}  !important`,
          border: `2px solid ${referrer.theme.ctaTextColor}  !important`
        }" @click="nextStep()">
        C’est parti !
      </primary-button-component>
    </div>
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { getNewCollaboratorByToken } from "@/features/company/services/appApi";

export default {
  name: "QuestionnaireStepOne",
  components: { PrimaryButtonComponent },
  data() {
    return {
      data: null
    }
  },
  mounted() {
    this.getCollaborator()
  },
  computed: {
    referrer() {
      return this.$store.state.referrer ? this.$store.state.referrer : {};
    },
  },
  methods: {
    nextStep() {
      this.$router.push({ name: 'Questionnaire-step-two', params: { token: this.$route.params.token } })
    },
    getCollaborator() {
      getNewCollaboratorByToken("ecosystem", this.$route.params.token).then(res => {
        this.data = res.data.data
        if (res.data.data) {
          this.$store.commit('SET_QUESTIONNAIRE_TOKEN', this.$route.params.token)
          this.$store.commit('SET_USER', res.data.data.collaborateur)
        }
        else
          this.$router.push({ name: "TokenExpired" })

      }).catch(({response}) => {
          this.isLoadingData = false;
          if (response.status === 404) {
            this.$router.replace({ name: "TokenExpired" });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: quelque chose s'est mal passé",
              type: "error",
            });
          }
      })
    }
  }
}
</script>

<style>

</style>
