import { render, staticRenderFns } from "./AnswersTable.vue?vue&type=template&id=b4f4ce06&scoped=true&"
import script from "./AnswersTable.vue?vue&type=script&lang=js&"
export * from "./AnswersTable.vue?vue&type=script&lang=js&"
import style0 from "./AnswersTable.vue?vue&type=style&index=0&id=b4f4ce06&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4f4ce06",
  null
  
)

export default component.exports