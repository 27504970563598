<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Engagement RGPD
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.anneeRouge }}</span>
    </p>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Chères Collaboratrices, chers collaborateurs,<br /><br /><br /><br />
      Nous poursuivons notre démarche de mise en conformité des traitements de
      données personnelles (collecte, enrichissement, développement,
      hébergement, maintenance, etc.) qu’effectue notre Entreprise, aux
      exigences du Règlement Général sur la Protection des Données.<br /><br />
      Le principal objectif
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.anneeRouge }}</span>
      sera d’avoir mis à jour notre gouvernance RGPD au
      <text-field placeholder="JJ/MM/AAAA" type="date" v-model="referenceData.updateGouvernanceDate" v-if="data.show"/>
      <span style="margin-left: 3px;" v-if="!data.show">{{ referenceData.updateGouvernanceDate  | formatDate}}</span>
      prochain.<br /><br />
      Dans les prochains jours, notre comité de pilotage de la conformité va
      être appelé à siéger.<br /><br />
      J’ai chargé
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.prenomYellow }}</span>
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.nomYellow }}</span
      >, de mener un audit général de notre entreprise afin de d’identifier les
      points d’amélioration de notre conformité au RGPD . <br /><br />
      Il présentera au Comité de pilotage les axes de progression de notre mise
      en conformité et nous déciderons la feuille de route
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.anneeRouge }}</span
      >. <br /><br /><br /><br />
      Les principales étapes que je nous fixe sur les prochains mois sont les
      suivantes :<br />
      <br />
      Au
      <text-field placeholder="JJ/MM/AAAA" type="date" v-model="referenceData.finalisationAudit" v-if="data.show"/>
      <span style="margin-left: 3px;" v-if="!data.show">{{ referenceData.finalisationAudit  | formatDate }}</span>
      : finalisation de l’audit.<br />
      <br />
      Au
      <text-field placeholder="JJ/MM/AAAA" type="date" v-model="referenceData.revuAnnuelle" v-if="data.show"/>
      <span style="margin-left: 3px;" v-if="!data.show">{{ referenceData.revuAnnuelle | formatDate }}</span>
      : revue annuelle de Direction RGPD et validation de la feuille de route
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.anneeRouge }}</span
      ><br />
      <br />
      Au
      <text-field placeholder="JJ/MM/AAAA" type="date" v-model="referenceData.bilanActions" v-if="data.show"/>
      <span style="margin-left: 3px;" v-if="!data.show">{{ referenceData.bilanActions | formatDate }}</span>
      : bilan des actions entreprises dans le cadre de la feuille de route<br /><br />

      Je vous demande de partager pleinement ce projet clé car au-delà de la
      nécessaire conformité à la loi, je suis persuadé des bénéfices que nous
      pourrons retirer de la démarche, tant en termes d’efficacité interne que
      d’avantage concurrentiel.
      <br />
    </p>
    <br />
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
        margin-left: 20px;
      "
    >
      Sachant que je peux compter sur vous, je vous remercie de votre
      engagement.
    </p>
    <br />
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
      "
    >
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.ville }}</span
      >, le
      <span
        style="
        margin-left: 3px;
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
      >{{ data.dateRouge }}</span
      >.<br />
      <span
        style="margin-left: 3px;font-family: 'Calibri', sans-serif"
      >{{ data.corporateOfficer }}</span>
    </p>
  </div>
</template>

<script>
import textField from './components/textField.vue';
export default {
  components: { textField },
    name: "EngagementDeLaDirection",
    props:{
      data:{
        type:Object,
        default:()=>{}
      },
      oldReference:{
        type:Object,
        default:()=>{}
      },
    },
    data(){
      return {
        referenceData:{
          finalisationAudit:"",
          updateGouvernanceDate:"",
          revuAnnuelle:"",
          bilanActions:"",
          newPrenomDpo:"",
          newNomDpo:"",
          newFonctionDpo:""
        }
      }
    },
    mounted(){
    if(Object.keys(this.oldReference)){
      this.referenceData = this.oldReference
    }
    else{
    this.referenceData.newPrenomDpo=this.data.prenomYellow;
    this.referenceData.newNomDpo=this.data.nomYellow;
    this.referenceData.newFonctionDpo=this.data.fonctionYellow;
  }
    },
    watch:{
      referenceData:{
        handler(value){
          this.$store.dispatch('handleReferenceData',value)
        },
        deep:true
      },
    }
};
</script>

<style></style>
