<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
    <img width="12%" :src="referer.logo" alt="LOGO" />

    <h3 class="tw-mt-12 tw-font-semibold tw-text-2xl tw-w-fit" style="letter-spacing: -0.5px;">Mon questionnaire</h3>
    
    <span class="mon-entreprise-para tw-mt-3">Vos scores et vos éventuels axes d’amélioration.</span>

    <div id="questionnaire-empty" class="tw-w-fit tw-flex tw-flex-col tw-items-center">
      <v-btn
        elevation="1" text rounded large
        @click="startQuiz"
      >Répondre</v-btn>
      <img class="tw-w-60 tw-mt-8" src="@/assets/images/icons/bell_black.svg" alt="invitation" />
    </div>
  </div>
</template>

<script>
export default {
  name: "GDPRPublicQuizStart",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    startQuiz() {
      this.$router.push({ name: 'GdprPublicQuiz.quiz', params: { token: 'test' } })
    },
  }
};
</script>