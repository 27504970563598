<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
      "
    >
      Information des clients directs
    </p>
    <br /><br /><br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Des informations à caractère personnel vous concernant sont enregistrées
      dans un fichier informatisé par
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      pour assurer l’exécution du contrat de prestation que vous avez
      souscrit.<br /><br />
      Elles seront conservées tout au long de la durée du contrat, période
      durant laquelle elles pourront être régulièrement mises à jour à votre
      demande. Hors obligations légales spécifiques, elles seront détruites sous
      un délai de 3 mois après le terme du contrat.<br /><br />
      Ces données sont destinées aux techniciens et personnels administratifs de
      notre Entreprise<br /><br />
      Conformément à la loi « informatique et libertés », vous pouvez exercer
      votre droit d'accès aux données vous concernant et les faire rectifier en
      contactant :
      <a
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
        :href="`mailto:${data.email}`"
      >{{ data.email }}</a
      ><br /><br />
      Nous vous informons de l’existence de la liste d'opposition au démarchage
      téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici :
      <a
        style="
          font-family: 'Calibri', sans-serif;
          font-size: 15px;
          line-height: 20px;
        "
        target="_blank"
        href="https://www.bloctel.gouv.fr/"
        >https://www.bloctel.gouv.fr/</a
      ><br /><br />
    </p>
  </div>
</template>

<script>
export default {
  name: "mentionContractuellesClientsDirectes",
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
      type:Object,
      default:()=>{}
    }
  },
  mounted() {
    if(Object.keys(this.oldReference)){
      this.referenceData = this.oldReference
    }
    this.$store.dispatch('handleReferenceData',{})
  },
};
</script>

<style></style>
