import Pusher from "pusher-js";
import JwtService from "../../../services/jwt.service";
import { serverConfig } from "@/config/serverConfig";

let pusher;

function initPusher() {
  return new Pusher(serverConfig.pusher_key, {
    cluster: "eu",
    encrypted: true,
    authEndpoint: `${serverConfig.appUrl}/pusher/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${JwtService.getToken().token}`,
      },
    },
  });
}

export function subscribeToChannel(channelName) {
  pusher = initPusher();
  return pusher.subscribe("private-" + channelName);
}

export function bindEventPusher(channel, eventName, callback) {
  channel.bind(eventName, callback);
}

export function unsubscribeFromChannelPusher(channelName) {
  pusher.unsubscribe("private-" + channelName);
}

export function unbindEventPusher(channel, eventName, callback) {
  pusher.bind(eventName, callback);
}

export function onChannelSubscriptionSucceeded(channel, callback) {
  return channel.bind("pusher:subscription_succeeded", callback);
}

export function onChannelSubscriptionError(channel, callback) {
  return channel.bind("pusher:subscription_error", callback);
}
