<template>
  <div id="Questionnaire-container">
    <EntrepriseHeaderBlockTextComponent
      class="tw-w-full"
      title="Mon questionnaire"
      description="Vos scores et vos éventuels axes d’amélioration."
    />
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-grid tw-grid-cols-5 tw-gap-4 tw-w-full" v-if="showQuestion">
        <div class="tw-col-span-5 lg:tw-col-span-2 box-questionnaire">
          <h2>Mon questionnaire</h2>
          <span class="score" :class="getColorBadge()">
          {{ total !== null ? total.toFixed() : 0 }} / 100
        </span>
          <hr />
          <v-btn
              elevation="1"
              text
              rounded
              large
              @click="MiseAjour()"
              class="tw-text-white victor-submit-button tw-m-auto"
          >
            Mettre à jour
          </v-btn>
          <p>
            Du changement concernant votre conformité ?
            Une fois par an, vous avez la possibilité de modifier vos réponses
            au questionnaire et faire évoluer votre score.
          </p>
        </div>
        <div class="tw-col-span-5 lg:tw-col-span-3 box-score">
          <h2>Mon score en détails</h2>
          <v-simple-table id="contact-table">
            <template v-slot:default>
              <thead>
              <tr>
                <th>Chapitre</th>
                <th>Score</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in table_data" :key="item.id">
                <td>{{ key + 1 }}. {{ item.chapter.label }}</td>
                <td>
                  <span :class="getBadge(item.status)"
                  >{{ item.scorePercent !== null ? item.scorePercent.toFixed() : 0 }} / 100</span
                  >
                </td>
              </tr>

              <tr>
                <td>Total:</td>
                <td>
                  <span class="tw-whitespace-nowrap" :class="getLgBadge()"
                  >{{ total !== null ? total.toFixed() : 0 }}  / 100</span>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
            <v-btn
                elevation="1"
                text
                rounded
                large
                @click="voirDetail()"
                class="tw-text-white victor-submit-button-detail"
            >
              Voir le détail
            </v-btn>
          </div>

        </div>
      </div>
      <EmptyComponent v-else />
    </template>

  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EmptyComponent from "@/features/company/components/Questionnaire/EmptyComponent";
import { getQuiz } from "@/features/company/services/appApi";

export default {
  name: "MonEntrepriseQuestionnairePage",

  components: {
    EntrepriseHeaderBlockTextComponent,
    EmptyComponent,
  },
  data() {
    return {
      loading_qst: false,
      table_data: [],
      score: null,
      scoreTotal: null,
      showQuestion: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading_qst = true;
      getQuiz()
        .then((res) => {
          this.table_data   = res.data.data.quiz.quizChapters.sort((a, b) => a.chapterId - b.chapterId);
          this.scoreTotal   = res.data.data.quiz.status;
          this.showQuestion = !res.data.data.quiz.isDraft;
          this.total = res.data.data.quiz.scorePercent;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_qst = false;
        });
    },
    getBadge(score) {
      if (score =="A") return "victor-badge-green";
      else if (score =="B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getLgBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    getColorBadge() {
      if (this.scoreTotal =="OK_GO") return "victor-green";
      else if (this.scoreTotal =="GO_SR_A") return "victor-yellow";
      else if (this.scoreTotal =="GO_SR_B") return "victor-yellow";
      else return "victor-red";
    },
    voirDetail() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.reponses",
      });
    },
    MiseAjour() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.RGBD",
      });
    },
  },
};
</script>
<style scoped></style>
