<template>
  <div id="collaborateurs-add">
    <CollaboratorAddGuideComponent
        title1="Ma gouvernance, responsabilité"
        title2="et engagement de la Direction de l’entreprise"
        link-route="Gouvernance.Add.Step1"
        button-route="Gouvernance.Add.Step1"
        step-one-text="Vous allez télécharger la liste des collaborateurs de l’entreprise, de tous les collaborateurs. Sauf si vous l’avez déjà fait, notamment pour l’écosystème des données ou dans l’espace mes collaborateurs."
        step-two-text="Un message prérédigé vous est proposé, vous pouvez le modifier comme vous le voulez et l’envoyer en un clic à tous les collaborateurs. Vous n’avez rien de plus à faire, vous en avez pour 5 minutes tout au plus !"
        step-three-text="Tous les collaborateurs vont être invités à cliquer sur le message pour accuser réception de l’engagement de la direction. Vous saurez donc, qui a lu le message de la Direction."
    >
      <div
          class="tw-flex tw-flex-col tw-align-start tw-justify-between"
      >
        <h3 class="tw-mt-4 tw-text-justify tw-text-xs md:tw-w-9/12">
          L’engagement de la Direction est un point important. Il se manifeste au travers du document
          « Engagement RGPD » qui doit être diffusé à l’interne par email et dont l’objet est « Mise en conformité
          au RGPD de notre société ». Chaque année, Viqtor® synthétise les lignes de force des actions meenées,
          recense l’ensemble des données de chaque acteur interne et les circularise personnellement et
          automatiquement par email. Les nouveaux collaborateurs que vous renseignerez seront immédiatement informés.
          C’est aussi simple !
        </h3>
        <h3 class="tw-mt-2 tw-text-justify tw-text-xs tw-font-bold md:tw-w-9/12">
          Il y a 3 étapes dont seulement deux vous concernent, laissez-vous tout simplement guider !
        </h3>
      </div>
    </CollaboratorAddGuideComponent>
  </div>
</template>

<script>
import CollaboratorAddGuideComponent from "@/features/company/components/Collaborateurs/CollaboratorAddGuideComponent";
export default {
  name: "NewGouvernanceGuidePage",
  components: {CollaboratorAddGuideComponent}
}
</script>

<style scoped>

</style>
