<template>
  <v-dialog @click:outside="$parent.showModal=false" v-if="show" v-model="show" :persistent="true" width="40%" v-bind="$attrs" v-on="$listeners" content-class="modalDashboard">
    <div class="tw-text-center tw-px-4 tw-py-2 tw-text-black tw-min">
      <h2 class="tw-font-bold tw-text-xl font-color-primary">Écosystème des données </h2>
      <h3 class="tw-font-bold tw-text-sm tw-mb-4">Cartographie des ressources internes </h3>
        <hr class="tw-w-1/4 tw-mx-auto tw-border-black tw-my-3"/>
        <div class="tw-font-bold tw-text-sm">Total questionnaires des collaborateurs</div>
      <div class="tw-flex tw-justify-between tw-my-6">
          <div class="tw-flex tw-flex-col tw-w-2/4 tw-justify-center tw-items-center tw-space-y-8">
              <div class="tw-text-xs tw-w-2/4">Total questionnaires renseignés</div>
              <div>
                    <v-progress-circular
                        :key="index"
                        :indeterminate=false
                        :rotate="-90"
                        :size="90"
                        :width="10"
                        :value="pourcentage"
                        color="green"
                    >
                        <div class="font-color-primary tw-text-lg tw-font-bold">{{ number }}<span class="tw-text-black">/ {{ total }}</span></div>
                    </v-progress-circular>
              </div>
          </div>
          <div class="tw-flex tw-flex-col tw-w-2/4 tw-justify-center tw-items-center tw-space-y-2">
            <div class="tw-text-xs tw-w-3/4">Date d’envoi dernière invitation questionnaire</div>
            <div class="tw-text-sm tw-font-bold">{{ date }} UTM</div>
            <div class="tw-text-xs tw-w-3/4">Envoyer une nouvelle invitation aux non-répondants</div>
            <br />
            <v-btn
                rounded
                @click="go"
                class="tw-text-white bg-color-primary victor-submit-button tw-m-auto"
            >
                Envoyer
            </v-btn>
          </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {getCollaboratorDashboardDetail} from "@/features/company/services/appApi";

export default {
  name: "modalDashboard",
  props:['show', 'type'],
  data(){
    return{
      number: 0,
      total: 0,
      date: '14 Avril 2022',
      index:0
    }
  },
  mounted() {
    getCollaboratorDashboardDetail(this.type)
        .then(response => {
          this.number = response.data.data.number;
          this.total = response.data.data.total;
          this.date = response.data.data.date;
        })
        .catch(err => console.log(err));
  },
  computed: {
    pourcentage() {
      return (this.number / this.total) * 100
    }
  },
  methods:{
    go(){
      this.close();
      this.$router.push({name:"EcoSystem.List"})
    },
    close() {
      this.$snackbar.showAlertMessage({
        message: 'Invitation envoyée avec succès.',
        type: "success",
      });
      this.$emit('close');
    }
  }
};
</script>

<style scoped>

</style>
