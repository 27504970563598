<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" persistent width="60%" content-class="rounded-xl">
    <div class="tw-py-5 tw-pl-9">
      <EntrepriseHeaderBlockTextComponent title="Ajouter plusieurs collaborateur à votre équipe d’Expert." />

      <div class="tw-mt-6">
        <CollaborateurImportBlockTextComponent
          title="1. Téléchargez d’abord le modèle"
          description="Pour importer facilement la liste de vos collaborateurs, téléchargez ce modèle."
        />

        <div class="tw-flex tw-justify-center tw-mt-6">
          <v-btn
            elevation="0" text rounded large download outlined
            @click="downloadCSVData"
            :style="{ color: referer.theme.ctaBgColor, borderColor: referer.theme.ctaBgColor }"
          >
            <span>Télécharger le modèle</span>
            <svg class="tw-ml-4" width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.4375 3.66667H6.1875V0H2.8125V3.66667H0.5625L4.5 8.55556L8.4375 3.66667ZM0 9.77778H9V11H0V9.77778Z" :fill="referer.theme.ctaBgColor"/>
            </svg>
          </v-btn>
        </div>

        <div class="tw-flex tw-justify-center tw-mt-4">
          <p class="tw-w-[80%] tw-font-medium tw-text-xs tw-text-center">
            Ce fichier est un Excel .csv comprenant 4 colonnes, dans l’ordre : le prénom, le nom,  l’email et le service de vos collaborateurs. Ne modifiez pas l’ordre des collones.
          </p>
        </div>
      </div>

      <div class="tw-mt-2">
        <CollaborateurImportBlockTextComponent
          title="2. Importer votre liste decollaborateur"
          description="Glisser / déposer votre fichier Excel .csv ici, ou importez le grâce au bouton ci-dessous !"
        />

        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          useCustomSlot
          :options="dropzoneOptions"
          class="upload-file tw-mt-6 tw-mb-6 tw-py-6"
          :includeStyling="false"
          @vdropzone-removed-file="(file = null)" 
          @vdropzone-file-added="fileAdded"
        >
          <div class="dropzone-custom-content tw-flex tw-items-center tw-flex-col">
            <img src="@/assets/images/icons/upload.svg" />
            <span class="title-upload-drpzone">Glissez et déposez ici votre liste de collaborateurs</span>
            <v-btn
              elevation="1" text rounded large
              :loading="isUploadModel"
              class="tw-text-white"
            >
              Parcourir
            </v-btn>
          </div>
        </vue-dropzone>        
      </div>

      <div class="tw-mt-2 tw-flex tw-justify-center tw-items-center tw-space-x-8">
        <span 
          class="tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-16 tw-cursor-pointer" 
          :style="{ 
            border: `1px solid ${referer.theme.ctaBgColor}`,
            color: `${referer.theme.ctaBgColor}` 
          }"
          @click="$emit('close')"
        >Fermer</span>
        <v-btn
          elevation="0" text rounded large
          class="tw-text-white tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer" 
          :style="{ 
            backgroundColor: `${referer.theme.ctaBgColor}`
          }"
          :loading="isLoading"
          @click="importCollaboratorsHandler"
        >
          <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-12">Suivant</span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import CollaborateurImportBlockTextComponent from "@/components/common/CollaborateurImportBlockTextComponent";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { validateExpertMultiTeamMember } from "@/features/company/services/appApi";

export default {
  name: 'ImportCollaboratorsPopup',
  components: {
    EntrepriseHeaderBlockTextComponent,
    CollaborateurImportBlockTextComponent,
    vueDropzone: vue2Dropzone,
  },
  props: {
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      isUploadModel: false,
      file: null,
      isLoading: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 50,
        maxFiles:1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        addRemoveLinks: true,
        autoProcessQueue: false,
        uploadMultiple:1
      },
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  watch: {
    canOpen: { handler(val) { (this.open = val) }, immediate: true },
  },
  methods: {
    downloadCSVData () {
      var str = "firstName;lastName;email;service";
      var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement('a');
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'viqtor-collaborateurs-model.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    fileAdded(file){
      if(this.file){
        this.$refs.myVueDropzone.removeFile(this.file)
      }
      this.file = file;
    },
    importCollaboratorsHandler() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("file", this.file);
      
      validateExpertMultiTeamMember(formData)
        .then(({ data: { validTeamMembers, invalidTeamMembersErrors } }) => {
          if (invalidTeamMembersErrors.length > 0) {
            invalidTeamMembersErrors.forEach(error => {
              this.$snackbar.showAlertMessage({
                message: error,
                type: "error",
              });
            });
            return;
          }
          
          this.$emit('imported', validTeamMembers);
        })
        .catch(error => {
          console.log(error)
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de l'importation de vos collaborateurs.",
            type: "error",
          });
        })
        .finally(() => { this.isLoading = false; })
    }
  }
}
</script>