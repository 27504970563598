var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center tw-py-7 bg-color-primary tw-h-full tw-text-white"},[_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('img',{attrs:{"src":_vm.referrer.logoWhite,"width":"140","height":"auto","alt":""}})]),_vm._m(0),_c('div',{staticClass:"tw-flex tw-justify-center tw-my-24 tw-font-thin tw-text-lg tw-uppercase"},[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_vm._m(1),_c('div',{staticClass:"tw-flex tw-justify-center tw-pt-1 tw-my-16"},[_c('primary-button-component',{staticClass:"dynamic-text tw-bg-white",style:({
              backgroundColor: `${_vm.referrer.theme.ctaBgColor} !important`,
              color: `${_vm.referrer.theme.ctaTextColor}  !important`,
              border: `2px solid ${_vm.referrer.theme.ctaTextColor}  !important`
            }),on:{"click":function($event){return _vm.endSteps()}}},[_vm._v(" Fermer ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-col tw-space-y-2 tw-mt-20"},[_c('span',{staticClass:"tw-self-center tw-font-extrabold tw-text-4xl tw-tracking-wider"},[_vm._v("Merci pour vos réponses !")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-justify-center tw-text-center tw-w-2/4 tw-mx-auto"},[_c('p',[_vm._v("Merci d’avoir répondu au questionnaire. Vos réponses ont été enregistrées et serviront à la mise en conformité avec le "),_c('span',{staticClass:"tw-font-bold"},[_vm._v("Règlement Général de la Protection des Données. [RGPD]")])])])
}]

export { render, staticRenderFns }