import { render, staticRenderFns } from "./modalDashboardEcosystem.vue?vue&type=template&id=11085b1a&scoped=true&"
import script from "./modalDashboardEcosystem.vue?vue&type=script&lang=js&"
export * from "./modalDashboardEcosystem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11085b1a",
  null
  
)

export default component.exports