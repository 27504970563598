<template>
  <div id="entreprise-form">
    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <h1 class="mb-8 mon-entreprise-title-form">{{ title }}</h1>
      <v-form @submit="onSubmit" v-model="formValid" class="tw-pb-16">
        <div class="tw-grid tw-gap-0 tw-grid-cols-1 md:tw-grid-cols-2" v-if="company">
          <TextFieldComponent
              v-model="company.sirenOrRna"
              label="Numéro SIREN / RNA"
              class="mb-23"
              disabled
          />

          <TextFieldComponent
              v-model="form.socialReason"
              label="Raison Sociale"
              class="mb-23"
              :disabled="inputDisabled"
          />
          <TextFieldComponent
              v-model="form.tradeName"
              label="Dénomination commerciale"
              class="mb-23"
              :disabled="inputDisabled"
          />

          <select-box-component
            v-model="form.legalStatus" 
            :items="legalStatuses"
            name="legalStatus"
            class="mb-23"
            label="Structure juridique"
            :disabled="inputDisabled"
          />
          <!-- <TextFieldComponent
              v-model="form.nbrEmployees"
              label="Nombre de salariés"
              class="mb-23"
              :disabled="inputDisabled"
          /> -->

          <SelectBoxComponent
              v-model="form.nbrEmployees"
              :items="nbrEmployersItems"
              class="mb-23"
              label="Nombre de salariés"
              :disabled="inputDisabled"
            />

          <TextFieldComponent
              v-model="form.corporateOfficer"
              label="Mandataire social"
              class="mb-23"
              :disabled="inputDisabled"
          />
          <h1 class="mb-8 mon-entreprise-title-form tw-w-full">Coordonnées</h1>
          <br />
          <TextFieldComponent
              v-model="form.phone"
              label="Téléphone mobile"
              class="mb-23"
              :disabled="inputDisabled"
          />
          <TextFieldComponent
              v-model="form.website"
              label="Site Internet"
              class="mb-23"
              :disabled="inputDisabled"
          />
          <TextFieldComponent
          v-model="form.email"
          label="Email"
          class="mb-23"
          :disabled="inputDisabled"
          />

          <div class="tw-flex tw-flex-col margin-16 tw-relative">
            <label class="tw-absolute top-label" for="Logo">Logo</label>
            <input
                type="file"
                accept="image/png, image/jpeg"
                label="Logo"
                class="tw-mt-4 tw-pl-3"
                style="padding-top: 8px;"
                :disabled="inputDisabled"
                ref="imageUpload"
                @change="onInputChange"
            />
          </div>
          <div></div>
          <div class="tw-w-full tw-flex tw-justify-center tw-relative">
            
            <img :src="image" alt="logo" width="200" v-if="image">
            <svg @click="removeImage" v-if="!inputDisabled && image" class="tw-absolute close-icon" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8004 1.97851C15.0665 1.71225 15.0665 1.28055 14.8004 1.01428C14.534 0.748006 14.1023 0.748006 13.836 1.01428L7.5 7.35034L1.16393 1.01428C0.897671 0.748006 0.465972 0.748006 0.199708 1.01428C-0.0665693 1.28055 -0.0665693 1.71225 0.199708 1.97851L6.53576 8.31458L0.199708 14.6506C-0.0665693 14.9169 -0.0665693 15.3486 0.199708 15.6149C0.465972 15.8811 0.897671 15.8811 1.16393 15.6149L7.5 9.27882L13.836 15.6149C14.1023 15.8811 14.534 15.8811 14.8004 15.6149C15.0665 15.3486 15.0665 14.9169 14.8004 14.6506L8.46424 8.31458L14.8004 1.97851Z" fill="#414042"/>
            </svg>
          </div>

          <h1 class="mb-8 mon-entreprise-title-form tw-w-full">Adresse</h1>
          <br />
          <TextFieldComponent
              v-model="form.address"
              label="Rue"
              class="mb-23"
              :disabled="inputDisabled"
          />
          <TextFieldComponent
              v-model="form.zipCode"
              label="Code postal"
              class="mb-23"
              :disabled="inputDisabled"
              hide-details
          />

          <TextFieldComponent
              v-model="form.city"
              label="Ville"
              class="mb-23"
              :disabled="inputDisabled"
              hide-details
          />
        </div>
        <SubmitButtonComponent
            class="tw-text-white"
            :loading="isSendingForm"
            type="submit"
        >{{ formSubmitButton }}
        </SubmitButtonComponent>
      </v-form>
    </template>

  </div>
</template>
<script>
import SelectBoxComponent from "@/components/common/SelectBoxCompanyComponent";
import TextFieldComponent from "@/components/common/TextFieldCompanyComponent";
import SubmitButtonComponent from "@/components/common/SubmitButtonComponent";
import { getUserDataByToken, updateCompany, removeImage } from '../../company/services/appApi';
import { serverConfig } from "@/config/serverConfig";
export default {
  name: "EntrepriseFormComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    formSubmitButton() {
      return this.$route.name !== "Company.MonEntreprisePage.Update" ?  "Modifier" : "Valider";
    },
    inputDisabled() {
      return this.$route.name !== "Company.MonEntreprisePage.Update";
    },
  },
  components: {
    TextFieldComponent,
    SelectBoxComponent,
    SubmitButtonComponent
  },
  data() {
    return {
      legalStatuses: [
        { header: "PRIVÉE" },
        { name: "Entreprise individuelle (EI)" ,label: "Entreprise individuelle (EI)" },
        { name: "Entreprise unipersonnelle à responsabilité limitée (EURL)" ,label: "Entreprise unipersonnelle à responsabilité limitée (EURL)" },
        { name: "Société à responsabilité limitée (SARL)" ,label: "Société à responsabilité limitée (SARL)" },
        { name: "Société anonyme (SA)" ,label: "Société anonyme (SA)" },
        { name: "Société par actions simplifiée unipersonnelle (SASU)" ,label: "Société par actions simplifiée unipersonnelle (SASU)" },
        { name: "Société par actions simplifiée (SAS)" ,label: "Société par actions simplifiée (SAS)" },
        { name: "Société en nom collectif (SNC)" ,label: "Société en nom collectif (SNC)" },
        { name: "Société en commandite simple (SCS)" ,label: "Société en commandite simple (SCS)" },
        { name: "Société en commandite par actions (SCA)" ,label: "Société en commandite par actions (SCA)" },
        { name: "Société coopérative et participative (SCOP)" ,label: "Société coopérative et participative (SCOP)" },
        { name: "Société Civile Immobilière (SCI)" ,label: "Société Civile Immobilière (SCI)" },
        { name: "Société d’exercice libéral à responsabilité limitée (SELARL)" ,label: "Société d’exercice libéral à responsabilité limitée (SELARL)" },

        { header: "PUBLIQUE" },
        { name: "Établissement public à caractère administratif (EPA)" ,label: "Établissement public à caractère administratif (EPA)" },
        { name: "Établissement public à caractère industriel et commercial (EPIC)" ,label: "Établissement public à caractère industriel et commercial (EPIC)" },
        { name: "Société d'économie mixte (SEM)" ,label: "Société d'économie mixte (SEM)" },
        { name: "Société publique locale (SPL)" ,label: "Société publique locale (SPL)" },
        { name: "Mairie / Établissement public communal (EPC)" ,label: "Mairie / Établissement public communal (EPC)" },

        { header: "ASSOCIATIVE" },
        { name: "Fédération (FED)" ,label: "Fédération (FED)" },
        { name: "Association loi 1901 (AMU)" ,label: "Association loi 1901 (AMU)" },
        { name: "Organisation non gouvernementale (ONG)" ,label: "Organisation non gouvernementale (ONG)" },
        { name: "Association reconnue d'utilité publique (ARUP)" ,label: "Association reconnue d'utilité publique (ARUP)" },
        { name: "Association d'intérêt général (AIG)" ,label: "Association d'intérêt général (AIG)" },
        { name: "Association culturelle (ACU)" ,label: "Association culturelle (ACU)" },
        { name: "Association sportive (ASP)" ,label: "Association sportive (ASP)" },
        { name: "Association caritative (ACA)" ,label: "Association caritative (ACA)" },

        { name: "AUTRE",label: "AUTRE" },
    ],
      loading: false,
      isSendingForm: false,
      formValid: false,
      company: null,
      nbrEmployersItems: [
        { label: "1 - 2", value: "1-2" },
        { label: "3 - 9", value: "3-9" },
        { label: "10 - 49", value: "10-49" },
        { label: "50 - 99", value: "50-99" },
        { label: "100 - 249", value: "100-249" },
        { label: "250 - 499", value: "250-499" },
        { label: "500 - 2499", value: "500-2499" },
        { label: "2500 - 4999", value: "2500-4999" },
        { label: "5000+", value: "5000+" },
      ],
      form: {
        phone: "",
        email: "",
        address: "",
        website : "",
        zipCode: "",
        city: "",
        socialReason: "",
        tradeName: "",
        legalStatus: "",
        nbrEmployees: "",
        corporateOfficer: "",
      },
      file: null,
      image:null
    };
  },
  mounted() {

   this.fetchData();
  },
  methods: {
    removeImage(){
      const uri=this.company.logo
      removeImage({uri}).then(() => {
        this.image = "";
        this.$snackbar.showAlertMessage({
            message: 'image supprimer avec succees',
            type: "success",
          });
      }).catch(err=>{
        console.log(err)
      })
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.$notify({
              group: 'foo',
              type: 'error',
              title: 'Attention',
              text: `${file.name} ce n'est pas une image`
            })
        return;
      }

      this.file=file;

      const reader = new FileReader();

      reader.onload = (e) => this.image=e.target.result;

      reader.readAsDataURL(file);
    },
    onInputChange(e) {
      const file =  e.target.files[0];

      this.addImage(file);
    },
    onSubmit(e) {
      e.preventDefault();
      if(!this.inputDisabled) {
        this.update();
      }else {
        this.$router.push({
          name: "Company.MonEntreprisePage.Update", params : {update:false}
        });
      }
    },
    update() {
      this.isSendingForm = true;
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("form", JSON.stringify(this.form));
      updateCompany(formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'

                                }
                  })
        .then((res) => {
          this.fetchData()
          this.$router.push({ name: 'Company.MonEntreprisePage.Index' });
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          response.data.message.forEach(item => {
            this.$notify({
              group: 'foo',
              type: 'error',
              title: 'Attention',
              text: item
            })
          })
          // this.$snackbar.showAlertMessage({
          //   message: response.data.message,
          //   type: "error",
          // });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
    fetchData() {
       this.loading = true;
      getUserDataByToken().then((res) => {
         this.company = res.data.data.workInCompany;
         this.image = res.data.data.workInCompany.logo? `${ serverConfig.appUrl }${res.data.data.workInCompany.logo}` : null;
        this.form.phone= res.data.data.phone;
        this.form.email= res.data.data.email;
        this.form.website = res.data.data.workInCompany.website;
        this.form.address = res.data.data.workInCompany.address ;
        this.form.zipCode= res.data.data.workInCompany.zipCode;
        this.form.city= res.data.data.workInCompany.city;

        this.form.socialReason= res.data.data.workInCompany.socialReason;
        this.form.tradeName= res.data.data.workInCompany.tradeName;
        this.form.legalStatus= res.data.data.workInCompany.legalStatus;
        this.form.nbrEmployees= res.data.data.workInCompany.nbrEmployees;
        this.form.corporateOfficer= res.data.data.workInCompany.corporateOfficer;



      }).catch(({ response }) => {
        this.$snackbar.showAlertMessage({
          message: response.data.message,
          type: "error",
        });
      }).finally(()=> this.loading = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-label{
  top:-12px !important;
  left: 0;
}
.close-icon{
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.margin-16{
  margin-left: 64px;
  @media screen and (max-width: 720px) {
    margin-left: 0;   
  }
}
</style>