<template>
  <div class="">
    <v-row no-gutters class="mb-12 mt-4">
      <v-col cols="12">
        <h1
          class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"
        >
          Dernière vérification !
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="my-4" cols="12">
        <h5
          class="my-4 tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"
        >
          Liste des sous-traitants qui vont recevoir l’invitation :
        </h5>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Raison sociale</th>
                <th>Contact</th>
                <th>Suggéré par </th>
                <th>Supprimer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in subcontractorsValid" :key="item.name">
                <td>{{ item.subcontractor.socialReason }}</td>
                <td>
                  {{ item.subcontractor.user.firstName }}
                  {{ item.subcontractor.user.lastName }}
                </td>
                <td>{{ item.invitedBy.firstName +" "+item.invitedBy.lastName }}</td>
                <td @click="supprimerSubcontractorValid(item)" class="tw-cursor-pointer">
                  <v-icon dark small color="black">mdi-close</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col class="my-4 d-flex justify-space-between" cols="12">
        <PrimaryButtonComponent class="return-btn" @click="retour"
          >Retour</PrimaryButtonComponent
        >
        <PrimaryButtonComponent class="validate-btn" @click="valider"
          >Envoyer</PrimaryButtonComponent
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { sendManyWatingInvitation } from '../../services/appApi';

export default {
  name: "SubContractorInvitationVerification",
  computed: {
    subcontractorsValid() {
      return this.$store.getters.getSubcontractorsValid;
    },
  },
  data() {
    return {};
  },
  components: {
    PrimaryButtonComponent,
  },
  methods: {
    supprimerSubcontractorValid(item) {
      this.$store.dispatch("supprimerSubcontractorValid", item);
    },
    retour() {
      this.$router.push({
        name: "SubContractor.PendingSubContractors.verifier.preview",
      });
    },
    valider() {
      this.isSendingForm = true;
      let data = this.subcontractorsValid.map((invitation) => {
        return {
          clientSubcontractorId: invitation.id
        };
      });
      let req = {clientSubcontractorIds: data,emailTemplate: this.$store.getters.getEmailTemplate};
      sendManyWatingInvitation(req).then((res) => {
        console.log(res);
        // this.$snackbar.showAlertMessage({
        //   message: res.data.message,
        //   type: "success",
        // });
        this.$router.push({
          name: "SubContractor.PendingSubContractors.verifier.invitation.msg",
        });
      }).catch(({ response }) => {
        response.data.message.forEach((err)=>{
          this.$snackbar.showAlertMessage({
            message: err,
            type: "error",
          });
        })
      }).finally(()=>{
        this.isSendingForm = false;
      });
    },
  },
};
</script>

<style lang="scss">
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.return-btn,
.validate-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: 250px !important;
  margin: 0 12px;
  background: var(--cta-bg-color) !important;
  color: #fff !important;
}
</style>
