<template>
  <nav class="tw-w-full" id="breadcrumb">
    <ul class="tw-list-reset tw-flex tw-text-grey-dark tw-pl-0">
      <template v-for="(breadcrumb, index) in breadcrumbs">
        <template v-if="breadcrumb.name">
          <li
            v-bind:key="index"
            @click="() => goToRoute(index, breadcrumb.routeName)"
            class="tw-font-normal tw-text-px-10 tw-flex tw-items-center"
            :class="{ 
              'breadcrumb-active': index == lastBreadcrumbIndex && lastBreadcrumbIndex > 0,
              'breadcrumb-link': index !== lastBreadcrumbIndex && lastBreadcrumbIndex > 0
            }"
          >
            {{ breadcrumb.name }}
          </li>
          <li
            class="tw-font-normal tw-text-px-10 tw-flex tw-items-center"
            v-if="index !== lastBreadcrumbIndex"
            :key="index + (Math.floor(Math.random() * 9999) + 1000)"
          >
            <span class="mx-2">{{ divider }}</span>
          </li>
        </template>
      </template>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    divider: String,
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched
        .filter((m) => m.meta.breadcrumb)
        .map((route) => (
          route.meta.breadcrumb.name !== ""
            ? { name: route.meta.breadcrumb.name, routeName: route.name }
            : {}
        ))
        .filter(b => !!b.name);
    },
    lastBreadcrumbIndex() {
      return Object.keys(this.$route.matched.filter((m) => 
        m.meta.breadcrumb && m.meta.breadcrumb.name !== ""
      )).length - 1;
    }
  },
  // mounted() {
  //   console.log(this.$route.matched.map(r => ({ 
  //     name: r.name, 
  //     path: r.path,
  //     breadcrumb: r.meta.breadcrumb.name,
  //   })));
  // },
  methods: {
    goToRoute(routeIndex, routeName) {
      if (routeIndex === this.lastBreadcrumbIndex) return;
      this.$router.push({ name: routeName })
    },
  }
};
</script>
