<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen">
      <StepsComponent :step="1" />
      <div class="tw-relative tw-min-h-screen">
        <TitleComponent class="tw-mt-12" text="Inscription, pour le compte de votre entreprise" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Il vous reste deux informations à renseigner pour vous inscrire à la plateforme Viqtor® EXPERT.
          Laissez vous guider, vous y êtes presque.
        </SignupSecondaryTextComponent>
        <v-form
            ref="form"
            @submit="onSubmit"
            v-model="formValid"
            class="tw-mt-8"
            autocomplete="off"
        >
          <div class="tw-flex tw-flex-col tw-w-full md:tw-w-10/12 lg:tw-w-9/12 tw-mx-auto">
            <div class="tw-flex tw-flex-row tw-items-center">
              <span class="register-subtitile">Vous</span>
            </div>
            <div class="tw-grid tw-gap-4 tw-grid-cols-1 sm:tw-grid-cols-1 md:tw-grid-cols-3 tw-mt-2">
              <TextFieldComponent
                  v-model="form.gender"
                  label="Civilité"
                  readonly
              />
              <TextFieldComponent
                  v-model="form.firstName"
                  label="Prénom"
                  readonly
              />
              <TextFieldComponent
                  v-model="form.lastName"
                  label="Nom"
                  readonly
              />
              <TextFieldComponent
                  class="tw-col-span-2"
                  v-model="form.email"
                  label="Adresse e-mail"
                  readonly
              />
              <TextFieldComponent
                  v-model="form.phone"
                  label="Téléphone"
                  readonly
              />
            </div>
            <div class="tw-flex tw-flex-row tw-items-center tw-mt-6">
              <span class="register-subtitile">Votre entreprise</span>
            </div>
            <div class="tw-grid tw-gap-4 tw-grid-cols-1 sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-mt-2">
              <TextFieldComponent
                  :rules="rules.socialReason"
                  :error-messages="errors.socialReason"
                  v-model="form.socialReason"
                  label="raison sociale"
              />
              <TextFieldComponent
                  :rules="rules.sirenOrRna"
                  :error-messages="errors.sirenOrRna"
                  v-model="form.sirenOrRna"
                  label="n° Siren / Rna"
              />
            </div>
            <p class="p-grid-step2 tw-mt-8">
              Viqtor® est connecté aux plateformes gouvernementales. En cliquant sur “Suivant” les informations officielles<br />
              de votre entreprise seront automatiquement renseignées.  Cela peut prendre un peu de temps.
            </p>
          </div>

          <div class="tw-flex tw-justify-end tw-pb-20 tw-mt-6">
            <PrimaryButtonComponent
                :loading="isSendingForm"
                :disabled="!formValid || isSendingForm"
                type="submit"
            >
              Suivant
              <svg class="tw-ml-3" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6H15" :stroke="!formValid ? '#25282B' : '#FFFFFF'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 1L15 6L10 11" :stroke="!formValid ? '#25282B' : '#FFFFFF'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </PrimaryButtonComponent
            >
          </div>
        </v-form>
        <p class="tw-absolute tw-bottom-0 registration-iml">
          Les informations à caractère personnel recueillies dans le cadre de
          votre demande de souscription à la plateforme Viqtor® sont enregistrées
          dans un fichier informatisé par la société DBWO pour permettre la
          finalisation de l’inscription de votre organisation à nos services.
          Elles seront conservées jusqu’à la validation de la souscription,
          période durant laquelle elles pourront être régulièrement mises à jour à
          votre demande. Hors demande de votre part, elles seront détruites en cas
          de non-finalisation de votre demande sous un délai de 2 mois après cet
          envoi. Ces données sont destinées au personnel administratif de DBWO et
          conservées sur les serveurs de l’entreprise OVH qui héberge le site
          Viqtor® sur le territoire français. Conformément à la loi « informatique
          et libertés », vous pouvez exercer votre droit d'accès aux données vous
          concernant et les faire rectifier en contactant : dpo@dbwo.legal.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import StepsComponent from "@/features/auth/components/StepsComponent";
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import {validationRules} from "@/utils/validation-rules";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import {
  getUserDataByToken,
  postFirstStepRegistrationExpert,
} from "@/features/auth/services/appApi";
export default {
  name: "ExpertFirstStepRegistrationPage",
  components: {TextFieldComponent, PrimaryButtonComponent, SignupSecondaryTextComponent, TitleComponent, StepsComponent},
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        phone: "",
        socialReason: "",
        sirenOrRna: ""
      },
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        gender: null,
        phone: null,
        socialReason: null,
        sirenOrRna: null
      },
      rules: {
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        sirenOrRna: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "sirenOrRna"),
        ],
      },
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getUserDataByToken(this.$route.params.token)
          .then(({ data: { data } }) => {
            this.isLoadingData = false;
            this.form = {
              ...this.form,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              gender: data.gender === 'male' ? 'Homme' : data.gender === 'female' ? 'Femme' : 'Non précisé',
              phone: data.phone,
            };
          })
          .catch(({ response }) => {
            this.isLoadingData = false;
            if (response.status === 404) {
              this.$router.replace({ name: "TokenExpired" });
            } else {
              this.$snackbar.showAlertMessage({
                message: "Erreur: quelque chose s'est mal passé",
                type: "error",
              });
            }
          });
    },
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      postFirstStepRegistrationExpert(this.form, this.$route.params.token)
          .then(() => {
            this.isSendingForm = false;
            this.formSent = true;
            this.$router.push({ name: 'Auth.Registration.Expert.SecondStep', params: { token: this.$route.params.token } });
            window.scrollTo(0, 0);
          })
          .catch(({ response }) => {
            window.scrollTo(0, 0);
            this.isSendingForm = false;
            if (response.status === 400) {
              response.data.message.forEach((item) => {
                this.$notify({
                  group: "foo",
                  type: "error",
                  title: "Attention",
                  position:"bottom right",
                  text: item,
                });
              });
            } else {
              this.$snackbar.showAlertMessage({
                message: "Erreur: la requete a échoué",
                type: "error",
              });
            }
            this.$refs.form.validate();
          });
    },
  }
}
</script>

<style scoped>

</style>
