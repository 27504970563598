<template>
  <div>
    <EntrepriseHeaderBlockTextComponent 
      class="tw-mt-24"
      title="Créer un compte client"
      description="Renseignez les informations de votre client afin de créer son compte Viqtor®."
    />

    <div 
      class="tw-mt-9 tw-mb-16 tw-pt-16 tw-px-10 tw-pb-12 tw-rounded-3xl"
      style="width: 75%; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);"
    >
      <p class="tw-text-center">
        La création de votre compte client <b>{{ tradeName }}</b> a bien été effectuée.
        <br /><br />
        Vous pourrez accéder à ce compte client en utilisant vos informations de connexion habituelles
        (login - mot de passe)
        <br /><br />
        Il ne sera pas nécessaire d’en créer de nouvelles.
      </p>

      <div class="tw-flex tw-justify-center tw-items-center tw-space-x-4">
        <v-btn
          elevation="1" text rounded large
          class="tw-mt-14 tw-text-white"
          :style="{ backgroundColor: `${referer.theme.ctaBgColor}` }"
          @click="submitForm"
          :loading="isLoading"
        ><span class="tw-px-6">Terminer</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";

export default {
  name: 'NewExpertClientConfirmation',
  components: {
    EntrepriseHeaderBlockTextComponent
  },
  props: {
    tradeName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    if (!this.tradeName) this.$router.push({ name: 'Company.ExpertClientAddNew' });
  },
  methods: {
    submitForm() {
      this.$router.push({ name: 'Company.ExpertClientDataBase' });
    }
  }
}
</script>