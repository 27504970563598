<template>
  <div>
    <svg v-if="switched" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1.23633" width="17" height="17" :stroke="referer.theme.ctaBgColor"/>
        <rect x="2" y="2.73633" width="14" height="14" :fill="referer.theme.ctaBgColor"/>
    </svg>

    <svg v-else width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="1.23633" width="17" height="17" stroke="#333333"/>
    </svg>


  </div>
</template>

<script>
export default {

    props:['switched'],
    computed: {
    referer() {
      return this.$store.state.referrer;
    }
  },


}
</script>

<style>

</style>