<template>
  <v-app class="tw-pt-0 tw-mt-0">
    <div id="governance-accept-receipt" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <img class="accept-receipt-logo !tw-mt-0" :src="referer.logoWhite" width="12%" height="auto" alt="LOGO" />
      <h1 class="accept-receipt-title tw-mt-40">Merci d’avoir accusé réception</h1>
      <p class="accept-receipt-name tw-mt-12">{{ fullName }}</p>
    </div>
  </v-app>
</template>

<script>

import { changeNewCollaboratorStatus, getNewCollaboratorByToken } from "@/features/company/services/appApi";

export default {
  name: "NewGouvernanceAcceptReceipt",
  components: {},
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      fullName: '',
    }
  },
  mounted() {
    getNewCollaboratorByToken('governance', this.$route.params.token)
      .then(response => {
        if (response.data.data) {
          this.fullName = `${response.data.data.collaborateur.firstName} ${response.data.data.collaborateur.lastName}`;
          changeNewCollaboratorStatus('governance', response.data.data.id, 2);

        } else {
          this.$router.replace({ name: 'landing-public' });
        }

      })
      .catch(() => '');
  }
}
</script>

<style scoped>

</style>
