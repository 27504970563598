<template>
  <div>
    <v-checkbox 
      :color="referer.theme.ctaBgColor" 
      block 
      v-for="(answer, index) in answers" 
      :key="answer.id"
      :label="answer.label"
      v-model="selectedAnswersList[index].responseId"
      :value="answer.responseId"
      :ref="'checkbox' + answer.id" 
      @change="changeHandler($event, answer.id)"
    />
  </div>
</template>

<script>
export default {
  name: "CheckButtonQuestionnaireComponent",
  props: {
    answers: {
      type: Array,
      default: () => [],
    },
    selectedAnswers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedAnswersList: [],
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    }
  },
  mounted() {
    this.selectedAnswersList = this.answers.map((answer) => {
      return {
        id: answer.id,
        responseId: this.selectedAnswers.find(a => a.id == answer.id) 
          ? this.selectedAnswers.find(a => a.id == answer.id).responseId
          : null
      };
    });
  },
  watch: {
    selectedAnswers: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedAnswersList = this.answers.map((answer) => {
          return {
            id: answer.id,
            responseId: this.selectedAnswers.find(a => a.id == answer.id) 
              ? this.selectedAnswers.find(a => a.id == answer.id).responseId
              : answer.responseId,
          };
        });
      }
    }
  },
  methods: {
    changeHandler(event, answerId) {
      this.selectedAnswersList = this.selectedAnswersList.map((answer) => {
        if (answer.id == answerId) {
          return {
            ...answer,
            responseId: event ? answer.id : null,
          };
        }
        return answer;
      });
      this.$emit("answered", [...this.selectedAnswersList.map(a => a.responseId)]);
    }
  },
}
</script>