<template>
    <div
      class="tw-col-span-1 item-box tw-border tw-rounded-md tw-mx-auto tw-my-3 tw-cursor-pointer"
      :class="{ 'done-color': reference.done }"
      :style="{'border-color':referer.theme.ctaBgColor}"

    >
          <div
            class="h-1 tw-flex tw-justify-center tw-items-center tw-px-1 tw-py-2"
          >
            <span class="document-title">{{ reference.title }}</span>
          </div>
          <div class="h-2 tw-flex tw-justify-center tw-items-center">
            <svg
              v-if="reference.done"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="30" height="30" rx="6" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.905 10.5507H17.8238C20.13 10.5507 21.255 11.782 21.2488 14.307V17.3507C21.2488 19.7632 19.7613 21.2507 17.3425 21.2507H12.6488C10.2425 21.2507 8.74878 19.7632 8.74878 17.3445V12.6507C8.74878 10.0632 9.89878 8.75072 12.1675 8.75072H13.155C13.7369 8.74447 14.28 9.01322 14.6363 9.46947L15.1863 10.2007C15.3613 10.4195 15.6238 10.5507 15.905 10.5507ZM12.1052 17.057H17.8927C18.1489 17.057 18.3552 16.8445 18.3552 16.5882C18.3552 16.3257 18.1489 16.1195 17.8927 16.1195H12.1052C11.8427 16.1195 11.6364 16.3257 11.6364 16.5882C11.6364 16.8445 11.8427 17.057 12.1052 17.057Z"
                :fill="referer.theme.ctaBgColor"
              />
            </svg>
            <svg
              v-else
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="30"
                height="30"
                rx="6"
                :fill="referer.theme.ctaBgColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.905 10.5507H17.8238C20.13 10.5507 21.255 11.782 21.2488 14.307V17.3507C21.2488 19.7632 19.7613 21.2507 17.3425 21.2507H12.6488C10.2425 21.2507 8.74878 19.7632 8.74878 17.3445V12.6507C8.74878 10.0632 9.89878 8.75072 12.1675 8.75072H13.155C13.7369 8.74447 14.28 9.01322 14.6363 9.46947L15.1863 10.2007C15.3613 10.4195 15.6238 10.5507 15.905 10.5507ZM12.1052 17.057H17.8927C18.1489 17.057 18.3552 16.8445 18.3552 16.5882C18.3552 16.3257 18.1489 16.1195 17.8927 16.1195H12.1052C11.8427 16.1195 11.6364 16.3257 11.6364 16.5882C11.6364 16.8445 11.8427 17.057 12.1052 17.057Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="h-3 tw-flex tw-justify-center tw-items-center tw-text-xs">
            <span v-if="reference.done">(Fait le {{ reference.date | formatDate}})</span>
            <span class="tw-underline" style=" text-underline-offset: 2px;" v-else>A faire</span>
          </div>
      </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'ReferenceItem',
    props: {
        reference: {
        type: Object,
        },
        referer: {
        type: Object,
        },
    },
    filters: {
    formatDate(date) {
      return date?moment(date).format("DD/MM/YYYY"):'';
    },
  },
}
</script>

<style lang="scss" scoped>
.done-color{
  color: #FFF !important;
  background: var(--cta-text-color) !important;
}
.tw-mt-2{
  margin-top: 0.6rem !important;
}
.document-box{
  padding: 40px;
  min-height: 500px;
  box-shadow: 3px 3px .4em rgb(178, 178, 178), -3px 3px .4em rgb(178, 178, 178);
  border-radius: 25px;
}
.document-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.item-box{
  height: 145px;
  width: 145px
}
.h-1{
  height: 66px;
}
.h-2{
  height: 50px;
}
.h-3{
  height: 23px;
}
.gap-40{
  gap: 40px;
}
</style>