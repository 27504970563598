<template>
  <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
    <div class="tw-px-24 tw-pb-7 tw-pt-16 tw-shadow-md tw-rounded-2xl tw-flex tw-flex-col tw-items-center tw-justify-center" style="width: fit-content;">
      <img :src="referer.logo" width="150" height="auto" alt="LOGO" />
      <h1 class="accept-receipt-title tw-mt-16 tw-text-2xl tw-font-medium tw-text-center tw-leading-6">LA PLATEFORME EST ACTUELLEMENT <br /> EN MAINTENANCE</h1>
      <h1 class="accept-receipt-name dynamic-text tw-mt-10 tw-text-center tw-leading-5">Votre espace sera accessible <br/> prochainement</h1>
      <p class="tw-mt-10 tw-text-center tw-leading-5">
        Une question ? <br />
        <a href="mailto:contact@viqtor.eu" class="tw-underline">contact@viqtor.eu</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
};
</script>