<template>
  <div class="tw-mt-6 tw-flex align-center" id="dashboard-block"
    :style="{ backgroundColor: `${referer.theme.ctaBgColor} !important` }">
    <div class="tw-w-3/4 tw-flex tw-flex-col tw-align-start tw-justify-between">
      <h1 class="title-block-dashboard">Avec Viqtor, le chef d’orchestre c’est vous !</h1>
      <span class="text-block-dashboard">
        Bon, ça y est, vous y êtes ! Il faut bien commencer un jour par démêler le fil de la conformité
        du RGPD de votre entreprise. En fait c’est très simple, vous pouvez commencer là où ça vous
        chante. {{ isFreePlan ? '' : 'On vous a précisé le temps approximatif que vous prendra chaque action.' }}
      </span>
      <!-- <v-btn class="tw-mt-4 btn-block-dashboard tw-cursor-pointer" @click="$router.push({ name: 'SubContractor.AddSubContractor' })">
        <span class="btn-text-block-dashboard">
          Inviter vos sous-traitants
        </span>

      </v-btn> -->
      <span class="tw-text-sm tw-font-semibold tw-text-white tw-py-3">Et soyez rassuré, Viqtor® est votre ami, il vous
        relancera, doucement mais surement !</span>
    </div>
    <div class="tw-w-1/4 tw-flex tw-align-center tw-justify-end">
      <img src="@/assets/images/dashboard/dashboard-icon.png" width="150" alt="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardInvitationBlock",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    isFreePlan() {
      if (this.$store.getters.getAuthUser) {
        return this.$store.getters.getAuthUser.workInCompany.planPrice ? this.$store.getters.getAuthUser.workInCompany.planPrice.price === 0 : true;
      } else {
        return true;
      }
    },
  },
}
</script>

<style scoped>

</style>
