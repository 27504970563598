<template>
  <div>
    <EntrepriseHeaderBlockTextComponent 
      class="tw-mt-24"
      title="Créer un compte client"
      description="Renseignez les informations de votre client afin de créer son compte Viqtor®."
    />

    <div 
      class="tw-w-full tw-mt-9 tw-mb-16 tw-pt-5 tw-px-10 tw-pb-12 tw-rounded-3xl"
      style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);"
    >
      <span>Compte client</span>

      <div class="tw-flex tw-items-center tw-mt-8 tw-space-x-8">
        <TextFieldComponent
          v-model="form.firstName"
          label="Prénom du contact"
          :hide-details="false"
          :error="errors.firstName.length > 0"
          :error-messages="errors.firstName"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.lastName"
          label="Nom du contact"
          :hide-details="false"
          :error="errors.lastName.length > 0"
          :error-messages="errors.lastName"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.email"
          label="Email du contact"
          :hide-details="false"
          :error="errors.email.length > 0"
          :error-messages="errors.email"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
      </div>

      <div class="tw-flex tw-items-center tw-space-x-4 tw-mt-6">
        <TextFieldComponent
          v-model="form.legalStatus"
          label="Structure juridique"
          :hide-details="false"
          :error="errors.legalStatus.length > 0"
          :error-messages="errors.legalStatus"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.tradeName"
          label="Dénomination commerciale"
          :hide-details="false"
          :error="errors.tradeName.length > 0"
          :error-messages="errors.tradeName"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.phone"
          label="Téléphone"
          :hide-details="false"
          :error="errors.phone.length > 0"
          :error-messages="errors.phone"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.sirenOrRna"
          label="SIREN / RNA"
          :hide-details="false"
          :error="errors.sirenOrRna.length > 0"
          :error-messages="errors.sirenOrRna"
          class="tw-w-full"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
      </div>
      
      <div class="tw-flex tw-items-center tw-space-x-8 tw-mt-6">
        <TextFieldComponent
          v-model="form.address"
          label="Adresse postale, rue, voie"
          :hide-details="false"
          :error="errors.address.length > 0"
          :error-messages="errors.address"
          style="width: 45%;"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.zipCode"
          label="Code postal"
          :hide-details="false"
          :error="errors.zipCode.length > 0"
          :error-messages="errors.zipCode"
          style="width: 28%;"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
        <TextFieldComponent
          v-model="form.city"
          label="Ville"
          :hide-details="false"
          :error="errors.city.length > 0"
          :error-messages="errors.city"
          style="width: 28%;"
          input-style="font-size: 14px !important;"
          persistent-placeholder
        />
      </div>

      <div class="tw-flex tw-justify-center">
        <v-btn
          elevation="1" text rounded large
          class="tw-mt-14 tw-text-white"
          :style="{ backgroundColor: referer.theme.ctaBgColor }"
          @click="submitForm"
          :loading="isLoading"
        ><span class="tw-px-8">Suivant</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { validateExpertClient } from "@/features/company/services/appApi";

export default {
  name: 'NewExpertClientForm',
  components: {
    EntrepriseHeaderBlockTextComponent
  },
  data() {
    return {
      isLoading: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        legalStatus: '',
        tradeName: '',
        phone: '',
        sirenOrRna: '',
        address: '',
        zipCode: '',
        city: '',
      },
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        legalStatus: [],
        tradeName: [],
        phone: [],
        sirenOrRna: [],
        address: [],
        zipCode: [],
        city: [],
      }
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  watch: {
    form: {
      handler() {
        this.errors = {
          firstName: [],
          lastName: [],
          email: [],
          legalStatus: [],
          tradeName: [],
          phone: [],
          sirenOrRna: [],
          address: [],
          zipCode: [],
          city: [],
        }
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.errors = {
        firstName: [],
        lastName: [],
        email: [],
        legalStatus: [],
        tradeName: [],
        phone: [],
        sirenOrRna: [],
        address: [],
        zipCode: [],
        city: [],
      }

      validateExpertClient(this.form)
        .then(() => {
          this.$router.push({ name: 'Company.ExpertClientAddNew.Subscription', params: { form: this.form } });
        })
        .catch(error => {
          this.errors = { ...this.errors, ...error.response.data.errors };
        })
        .finally(() => {
          this.isLoading = false;
        })
    }
  }
}
</script>