<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20" style="min-height: 462px">
      <router-link :to="{ name: 'landing-public' }">
      <RetourButtonComponent />
      </router-link>
      <TitleComponent class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-24" text="Erreur 404" />
      <ErrorSecondaryTextComponent class="public-text-login tw-mt-12">
        Oups, le fil s’est emmêlé !
      </ErrorSecondaryTextComponent>
      <ErrorSecondaryTextComponent class="public-text-login tw-mt-3">
        La page demandée n’existe pas, vous pouvez
      </ErrorSecondaryTextComponent>
      <ErrorSecondaryTextComponent class="public-text-login tw-mt-3">
        retourner à la
        <router-link :to="{ name: 'landing-public' }" class="text-decoration-underline" :style="{color: `${referer.theme.ctaBgColor}` }">
          page d’accueil
        </router-link>
        ou sur la page
      </ErrorSecondaryTextComponent>
      <ErrorSecondaryTextComponent class="public-text-login tw-mt-3">
        précédente pour démêler tout ça.
      </ErrorSecondaryTextComponent>

    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import ErrorSecondaryTextComponent from "@/features/404/components/ErrorSecondaryTextComponent";

export default {
  name: "Error404",
  data() {
    return {

    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    ErrorSecondaryTextComponent, RetourButtonComponent, TitleComponent
  },

  methods: {
    onSubmit() {

    },
    onPasswordForget() {
      alert('forget');
    }
  }
}
</script>

<style scoped>
</style>