<template>
<div class=" tw-flex tw-justify-center tw-items-center">
    <button
    v-bind="$attrs"
    class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center"
    style="height: 30px !important;"
     :class="[disableAction?colorChange:'between']"
    :disabled="!disableAction"
    >
    <slot></slot>
    </button>
</div>
</template>

<script>
import moment from 'moment'
export default {
    props:['item'],
    inheritAttrs: false,
    computed:{
        disableAction(){
            if(this.item.elearningSendAt && this.item.elearningScore<75){
                return moment(new Date()).diff(moment(this.item.elearningSendAt),'hours')>=72
            }
            return true
        },
        colorChange(){
            if(this.item.status)
            {
                return this.item.elearningScore<75?"tw-cursor-pointer fail":'tw-cursor-pointer success'
            }else{
                 return "desactivated"
            }
        },
    },
    methods:{
    }

}
</script>

<style scoped>

.desactivated{
background: var(--cta-bg-color);
color: #fff;
}
.fail{
 background-color: #E8973E;
 color: #FFF;
}
.between{
 background-color: #0202;
 color: #FFF;
}
.success{
    width: 35px;
    border-radius: 50%;
}
</style>
