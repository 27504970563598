<template>
    <div>
        <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29 2.73633L11.9054 25.7359" :stroke="referer.theme.ctaBgColor" stroke-width="6"/>
            <path d="M2 14.7363L16 25.2363" :stroke="referer.theme.ctaBgColor" stroke-width="6"/>
        </svg>
    </div>
</template>

<script>
export default {
  computed: {
    referer() {
      return this.$store.state.referrer;
    }
  },
}
</script>

<style>

</style>