import ApiService from "@/services/api.service";
import axios from "axios";
import { serverConfig } from "@/config/serverConfig";

export const checkIfHasMultiAccountsByEmail = (email) => {
  return ApiService.post("auth/has-multi-accounts", { email });
}

export const singleAccountLogin = (email, password) => {
  return axios.post(`${serverConfig.appUrl}/auth/single-login`, { email, password });
}

export const verifyCodeMultiAuth = (email, code) => {
  return ApiService.post("auth/multi-auth/verify-code", { email, code });
}

export const loginWithCompany = (email, companyId) => {
  return ApiService.post("auth/multi-auth/login-with-company", { email, companyId });
}