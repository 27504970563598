<template>
  <div id="notification_parametres" class="">
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <!-- table  -->
      <v-simple-table>
        <template v-slot:default>
          <tbody>
          <tr v-for="item in table_data" :key="item.id">
            <td>
              <label :for="'Accepter[' + item.id + ']'">
                <svg v-if="item.seen === false || item.seen == null" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.5" cy="9.5" r="9.5" :fill="referer.theme.ctaBgColor" />
                </svg>
                <svg v-if="item.seen === true" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.5" cy="9.5" r="8.5" :stroke="referer.theme.ctaBgColor" stroke-width="2" />
                </svg>
              </label>
            </td>
            <td v-if="item.seen === false">
              {{ item.createdAt | get_date_evaluation }} <br />
              <span class="tw-font-bold">{{ item.subject }}</span>
            </td>
            <td v-if="item.seen === true">
              {{ item.createdAt | get_date_evaluation }} <br />
              {{ item.subject }}
            </td>
            <td>
              <v-btn
                  elevation="1"
                  text
                  rounded
                  large
                  @click="Consulter(item)"
                  class="tw-text-white tw-hidden"
              >
                Consulter
              </v-btn>
            </td>
            <td>
              <img
                  style="cursor: pointer"
                  src="@/assets/images/icons/close.svg"
                  class="tw-w-3 tw-h-3"
                  alt="close"
                  @click="deleteNotification(item)"
              />
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- end table  -->
    </template>

  </div>
</template>
<script>
import {deleteNotificationUser, getNotificationUser, readNotificationUser} from "../../services/appApi";
import moment from "moment";
export default {
  name: "EntrepriseTableComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  filters: {
    get_date_evaluation(date) {
      let newDate = moment(date).format('DD/MM HH:mm');
      return  newDate;
    }
  },

  computed: {
    getNotificationsMessage() {
      return this.$store.getters.getNotifications;
    },
    getNotificationsNotRead() {
      return this.table_data.filter( function( element ) {
        return element.seen === true;
      }).length;
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      loading_qst: false,
      table_data: [],
      isSendingForm: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    Consulter(item) {
      readNotificationUser(item.id)
        .then(() => {
          this.fetchData();
        })
      .catch(({response}) => {
        this.$snackbar.showAlertMessage({
          message: response.data.message,
          type: "error",
        });
      })
      this.$router.push({
        name: item.redirectPath,
      });
    },

    deleteNotification(item) {
      deleteNotificationUser(item.id)
          .then(response => {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "success",
            });
            this.fetchData();
          })
          .catch(({response}) => {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
          })
    },

    fetchData() {
      this.loading_qst = true;
      getNotificationUser()
        .then(response => {
          this.table_data = response.data.data;
          let notifCount = response.data.data.filter( function( element ) {
            return element.seen === false;
          }).length;
          this.$store.dispatch('increment', {
            count: notifCount
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_qst = false;
        });
    },

  },
};
</script>
