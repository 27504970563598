<template>
  <footer :style="{ backgroundColor: `${referer.theme.ctaBgColor} !important` }">
    <div class="tw-container tw-mx-auto">
      <div class="tw-py-3 tw-text-center tw-text-white">© {{ new Date().getFullYear() }}, {{ referer.title }}.</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
};
</script>

<style scoped>

</style>
