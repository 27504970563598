<template>
  <div class="tw-grid tw-grid-cols-8 tw-gap-2">
    <div class="tw-relative tw-col-span-4">
      <div class="tw-flex tw-items-center tw-justify-start tw-space-x-5">
        <div class="tw-flex tw-justify-center tw-items-center">
          <AvatarComponent :label="collaborator.fullName"/>
        </div>
        <div class="tw-relative">
          <div class="tw-font-medium tw-text-gray-900 tw-text-xs tw-w-max">
            {{ collaborator.fullName.length > 30 ? collaborator.fullName.substring(0,30) + '...' : collaborator.fullName }}
          </div>
        </div>
      </div>
    </div>
    <div class="tw-relative tw-flex tw-justify-center tw-col-span-2">
      <div class="tw-pl-4" v-if="collaborator.relatedTeams.length == 0">-</div>
      <div v-if="collaborator.relatedTeams.length == 1">
        <BadgeComponent :label="collaborator.relatedTeams[0].name" />
      </div>
      <div v-if="collaborator.relatedTeams.length > 1">
        <v-tooltip right content-class="tw-bg-white tw-border tw-border-black">
          <template v-slot:activator="{ on, attrs }">
            <span 
              v-bind="attrs" v-on="on"
              class="tw-text-white tw-bg-[#2A353A] tw-py-1 tw-px-6 tw-text-xs tw-font-medium tw-rounded-full"
              style="width: 7rem; display: block;"
            >{{ collaborator.relatedTeams.length }} équipes</span>
          </template>
          <div class="tw-flex tw-flex-col tw-text-black tw-text-xs">
            <span v-for="team in collaborator.relatedTeams" :key="team.id">{{ team.name }}</span>
          </div>
        </v-tooltip>
      </div>
    </div>
    <div class="tw-relative tw-col-span-2">
      <label class="tw-flex tw-items-center tw-justify-end">
        <div
          class="tw-flex tw-h-6 tw-w-6 tw-items-start tw-justify-start tw-border tw-border-gray-900 tw-focus-within:border-gray-500"
        >
          <input
            type="checkbox"
            :checked="collaborator.isAttachedToCompany"
            @change="$emit('selected', collaborator.id)"
            class="tw-absolute tw-opacity-0 tw-focus:outline-none"
          />
          <svg class="tw-pointer-events-none tw-hidden tw-h-[18px] tw-w-[18px] tw-fill-current tw-text-gray-800" viewBox="0 0 20 20">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"/>
          </svg>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollaboratorItemComponent",
  props: {
    collaborator: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
input:checked + svg {
  display: block;
}
</style>
