<template>
  <v-container id="LoginPage" class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <router-link :to="{ name: 'landing-public' }">
        <RetourButtonComponent />
      </router-link>

      <TitleComponent class="public-title-auth tw-mt-4" text="Entrez sur" />
      <TitleComponent class="public-title-auth" text="votre espace." />

      <v-form ref="form" @submit.prevent="onSubmit" v-model="isFormValid" class="tw-mt-10">
        <TextFieldComponent append-icon="mdi-account-outline" class="sm:tw-w-full md:tw-w-1/3" :rules="rules.email"
          v-model="form.email" label="Adresse e-mail" :error-messages="errors.email" />
        <div class="tw-mt-10">
          <PrimaryButtonComponent :style="{
            backgroundColor: `${theme.ctaBgColor} !important`,
            color: `${theme.ctaTextColor}  !important`
          }" class="tw-rounded-lg" :loading="isSendingForm"
            :disabled="!isFormValid || isSendingForm" type="submit">Entrer</PrimaryButtonComponent>
        </div>
        <LoginSecondaryTextComponent class="public-text-login tw-mt-16">
          Pas encore de compte ?
          <router-link :to="{ name: platformTitle == 'Viqtor Expert' ? 'Auth.Registration.Expert.PreRegister' : 'signup' }" class="text-decoration-underline" :style="{
            color: `${theme.ctaBgColor}`,
          }">
            Inscrivez-vous
          </router-link>
        </LoginSecondaryTextComponent>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import TitleComponent from "@/components/common/TitleComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import LoginSecondaryTextComponent from "@/features/auth/components/LoginSecondaryTextComponent";
import { validationRules } from "@/utils/validation-rules";
import { checkIfHasMultiAccountsByEmail } from "../../services/multiAuth.api";

export default {
  name: "MultiAccountEmailLogin",
  components: {
    RetourButtonComponent,
    TitleComponent,
    TextFieldComponent,
    PrimaryButtonComponent,
    LoginSecondaryTextComponent,
  },
  data() {
    return {
      isFormValid: false,
      isSendingForm: false,
      rules: {
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
      },
      form: { email: "" },
      errors: { email: "" },
      hasMultiAccount: true,
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme
    },
    platformTitle() {
      return this.$store.state.referrer.title
    }
  },
  methods: {
    onSubmit() {
      this.isSendingForm = true;

      checkIfHasMultiAccountsByEmail(this.form.email)
        .then((response) => {
          this.hasMultiAccount = response.data.data.hasMultiAccount;
          this.isSendingForm = false;
          this.$store.commit("SET_MULTI_AUTH_EMAIL", this.form.email);
          this.hasMultiAccount 
            ? this.$router.push({ name: 'MultiAccount.OTPLogin' }) 
            : this.$router.push({ name: 'MultiAccount.PasswordLogin' });
        })
        .catch((error) => {
          console.log(error);
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message ||
              "Une erreur est survenue lors de la vérification de votre compte.",
            type: "error",
          })
        });
    }
  }
}
</script>