<template>
  <v-container class="tw-pt-0 tw-mt-0">

  <div id="invitation">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Nous avons bien reçu la liste !"
        description="Félicitation ! Vos sous-traitants vont recevoir votre invitation très rapidement !"
      />
    </div>
  </div>
  </v-container>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
export default {
  name: "invitationCollaborateursPage",
  computed: {},
  components: {
    EntrepriseHeaderBlockTextComponent,
  },
  data() {
    return {
      openMenu: false,
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "landing-public",
      });
    },
  },
};
</script>
<style scoped>
  #invitation {
    position: relative;
    min-height: 100vh !important;
  }
  footer {
    position: absolute !important;
    bottom: 0  !important;
  }
</style>
