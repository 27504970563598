<script>
import { Bar } from 'vue-chartjs';

export default {
  name: "BarChartSubContractor",
  props: {
    data: { type: Array, default: function () { return [] }, },
    colors: { type: Array, default: function () { return [] }, },
  },
  extends: Bar,
  data() {
    return {
      chartData: {
        labels: [
          this.data[0] && this.data[0].month ? this.data[0].month : '',
          this.data[1] && this.data[1].month ? this.data[1].month : '',
          this.data[2] && this.data[2].month ? this.data[2].month : '',
          this.data[3] && this.data[3].month ? this.data[3].month : '',
          this.data[4] && this.data[4].month ? this.data[4].month : '',
        ],
        datasets: [
          {
            label: "Invitation",
            backgroundColor: this.colors[0],
            data: [
              this.data[0] && this.data[0].invitation ? this.data[0].invitation : 0,
              this.data[1] && this.data[1].invitation ? this.data[1].invitation : 0,
              this.data[2] && this.data[2].invitation ? this.data[2].invitation : 0,
              this.data[3] && this.data[3].invitation ? this.data[3].invitation : 0,
              this.data[4] && this.data[4].invitation ? this.data[4].invitation : 0,
            ]
          },
          {
            label: "Réponse",
            backgroundColor: this.colors[1],
            data: [
              this.data[1] && this.data[0].reponse ? this.data[0].reponse : 0,
              this.data[2] && this.data[1].reponse ? this.data[1].reponse : 0,
              this.data[3] && this.data[2].reponse ? this.data[2].reponse : 0,
              this.data[4] && this.data[3].reponse ? this.data[3].reponse : 0,
              this.data[5] && this.data[4].reponse ? this.data[4].reponse : 0,
            ]
          }
        ]
      },
      options: {
        barValueSpacing: 10,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
