<template>
  <div class="tw-w-full tw-flex tw-justify-center tw-items-center" style="height: 100%;">
    <div class="tw-text-center tw-font-bold tw-text-4xl w-75 ">
        Vous allez recevoir un email de confirmation vous permettant 
        d’accéder au questionnaire d’évaluation de la conformité
        au RGPD de votre entreprise.
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.h-90 {
  height: 80vh;
  margin-bottom: 3px;
}
.w-75 {
  width: 75%;
}
</style>