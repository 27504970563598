<template>
  <div id="notification_parametres">
   <EntrepriseHeaderBlockTextComponent
        title="Notifications"
        description="Personnalisez les paramètres de vos notifications."
      />
      <div>
          <EntrepriseHeaderBlockTextComponent
        title="Notifications par e-mail : "
        description=""
        class="tw-mt-9"
      />
        <template v-if="loading">
          <content-placeholders class="">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </template>
        <template v-else>
          <div class="tw-mt-6">
            <tbody>
            <tr v-for="item in mail_data" :key="item.id">
              <td>
                <label :for="'Accepter[' + item.id + ']'" class="tw-mb-2">
                  <img class="Toggle tw-mb-4"
                       src="@/assets/images/icons/Toggle_grey.svg"
                       v-if="!item.enabled"
                  />
                  <input
                      @change="handle($event,item)"
                      type="checkbox"
                      class="tw-hidden"
                      :name="'result[' + item.id + ']'"
                      :id="'Accepter[' + item.id + ']'"
                      :value="item.enabled ? 1 : 0"
                      v-model="item.enabled"
                  />

                  <svg v-if="item.enabled" class="tw-mb-4" width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.3125 4.37533C13.9388 6.20403 13.1251 8.4746 13.1251 10.9377C13.1216 13.3047 13.8895 15.6084 15.3125 17.5L6.56272 17.5C4.82228 17.5 3.15312 16.8086 1.92245 15.5779C0.69177 14.3473 0.000383799 12.6781 0.000383951 10.9377C0.000384103 9.19722 0.69177 7.52807 1.92245 6.29739C3.15312 5.06671 4.82228 4.37533 6.56272 4.37533L15.3125 4.37533Z" :fill="referer.theme.ctaBgColor" />
                    <path d="M24.0628 2.18799C26.3834 2.18799 28.6089 3.10984 30.2498 4.75074C31.8907 6.39165 32.8126 8.61719 32.8126 10.9378C32.8126 13.2584 31.8907 15.4839 30.2498 17.1248C28.6089 18.7657 26.3834 19.6876 24.0628 19.6876C21.7422 19.6876 19.5166 18.7657 17.8757 17.1248C16.2348 15.4839 15.313 13.2584 15.313 10.9378C15.313 8.61718 16.2348 6.39164 17.8757 4.75074C19.5166 3.10984 21.7422 2.18799 24.0628 2.18799ZM24.0628 0.000544546C21.162 0.000544292 18.3801 1.15286 16.329 3.20398C14.2779 5.25511 13.1255 8.03704 13.1255 10.9378C13.1255 13.8385 14.2779 16.6204 16.329 18.6716C18.3801 20.7227 21.162 21.875 24.0628 21.875C26.9635 21.875 29.7454 20.7227 31.7966 18.6716C33.8477 16.6204 35 13.8385 35 10.9378C35 8.03704 33.8477 5.25511 31.7966 3.20398C29.7454 1.15286 26.9635 0.000544799 24.0628 0.000544546Z" :fill="referer.theme.ctaBgColor" />
                  </svg>

                </label>
              </td>
              <td>
                <p class="tw-mb-2">{{ item.notificationParam.label }}</p>
              </td>
            </tr>
            </tbody>
          </div>
        </template>

      </div>
      <div class="tw-mt-9">
          <EntrepriseHeaderBlockTextComponent
        title="Notifications in-app : "
        description=""
        />
        <template v-if="loading">
          <content-placeholders class="">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </template>
        <template v-else>
          <div class="tw-mt-6">
            <tbody>
            <tr v-for="item in app_data" :key="item.id">
              <td @click="onSubmit2(item)">
                <label :for="'Accepter[' + item.id + ']'" class="tw-mb-2">
                  <img class="Toggle tw-mb-4"
                       src="@/assets/images/icons/Toggle_grey.svg"
                       v-if="item.enabled === false || item.enabled === null"
                  />
                  <input
                      @change="handle($event,item)"
                      type="checkbox"
                      class="tw-hidden"
                      :name="'result[' + item.id + ']'"
                      :id="'Accepter[' + item.id + ']'"
                      :value="item.enabled ? 1 : 0"
                      v-model="item.enabled"
                  />

                  <svg v-if="item.enabled" class="tw-mb-4" width="35" height="22" viewBox="0 0 35 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.3125 4.37533C13.9388 6.20403 13.1251 8.4746 13.1251 10.9377C13.1216 13.3047 13.8895 15.6084 15.3125 17.5L6.56272 17.5C4.82228 17.5 3.15312 16.8086 1.92245 15.5779C0.69177 14.3473 0.000383799 12.6781 0.000383951 10.9377C0.000384103 9.19722 0.69177 7.52807 1.92245 6.29739C3.15312 5.06671 4.82228 4.37533 6.56272 4.37533L15.3125 4.37533Z" :fill="referer.theme.ctaBgColor" />
                    <path d="M24.0628 2.18799C26.3834 2.18799 28.6089 3.10984 30.2498 4.75074C31.8907 6.39165 32.8126 8.61719 32.8126 10.9378C32.8126 13.2584 31.8907 15.4839 30.2498 17.1248C28.6089 18.7657 26.3834 19.6876 24.0628 19.6876C21.7422 19.6876 19.5166 18.7657 17.8757 17.1248C16.2348 15.4839 15.313 13.2584 15.313 10.9378C15.313 8.61718 16.2348 6.39164 17.8757 4.75074C19.5166 3.10984 21.7422 2.18799 24.0628 2.18799ZM24.0628 0.000544546C21.162 0.000544292 18.3801 1.15286 16.329 3.20398C14.2779 5.25511 13.1255 8.03704 13.1255 10.9378C13.1255 13.8385 14.2779 16.6204 16.329 18.6716C18.3801 20.7227 21.162 21.875 24.0628 21.875C26.9635 21.875 29.7454 20.7227 31.7966 18.6716C33.8477 16.6204 35 13.8385 35 10.9378C35 8.03704 33.8477 5.25511 31.7966 3.20398C29.7454 1.15286 26.9635 0.000544799 24.0628 0.000544546Z" :fill="referer.theme.ctaBgColor" />
                  </svg>

                </label>
              </td>
              <td>
                <p class="tw-mb-2">{{ item.notificationParam.label }}</p>
              </td>
            </tr>
            </tbody>
          </div>
        </template>

      </div>
      <img src="@/assets/images/bg_notification.svg" alt="menu" class="notification-bg"/>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import {changeNotificationStatus, getNotificationList} from "@/features/company/services/appApi";
import _ from "lodash";
export default {
  name: "MonEntreprisePartagePage",
  components: {
    EntrepriseHeaderBlockTextComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      loading: false,
      mail_data: [],
      app_data: [],
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      getNotificationList()
      .then(response => {
        this.app_data = response.data.data.filter((item)=>{
          return item.type === 'in_app';
        });
        this.mail_data = response.data.data.filter((item)=>{
          return item.type === 'per_email';
        });
      })
      .catch(({ response }) => {
        console.log("err ", response)
      })
      .finally(() => {
        this.loading = false;
      });
    },
    handle(event,item){
      this.loading = true;
      this.onSubmit(item.id,item.enabled);
    },
    onSubmit(id,value){
      changeNotificationStatus({
        userNotificationParamId: id,
        enabled: value
      }).then(() => {
        this.$snackbar.showAlertMessage({
          message: "Changement effectuée avec succès",
          type: "success",
        });
      }).catch(({ response }) => {
        this.fetchData();
        if (_.isArray(response.data.message)) {
          response.data.message.forEach(item => {
            this.$notify({
              group: 'foo',
              type: 'error',
              title: 'Attention',
              text: item
            })
          });
        }
        else {
          this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Attention',
            text: response.data.message
          });
        }
      }).finally(()=> this.fetchData());
    },
  }
};
</script>
