<template>
  <div>
    <v-radio-group class="auth-v-label" row v-bind="$attrs" v-on="$listeners" v-model="column">
      <v-radio
        :color="referer.theme.ctaBgColor"
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioButtonComponent",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  props: {
    items: Array,
    select: [Number, String],
  },
  data () {
    return {
      column: this.select,
    }
  },
  watch: {
    select: function (val) {
      this.column = val;
    },
  }
};
</script>

<style scoped></style>
