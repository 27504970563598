<template>
<div class="tw-flex tw-justify-center tw-items-center">
    <div
    class="tw-w-2 tw-h-2 tw-rounded-full tw-flex tw-justify-center tw-items-center" :class="colorChange"
    >
    </div>
</div>
</template>

<script>
export default {
    props:['items'],
    computed:{
        colorChange(){
        if(this.items.elearningScore){
            if(this.items.elearningScore>=75)
            {
                return "success";
            }
            else
            {
                return this.items.elearningScore<30?'fail':'between'
            }
        }
        else{
                 return "desactivated"
            }
        },
    }

}
</script>

<style scoped>
.success{
background-color: #27AE60;
}
.fail{
background-color: #EB5757;
}
.between{
 background-color: #F2C94C;
}
.desactivated{
 background-color: #0202;
}
</style>
