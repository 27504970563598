<template>
  <div id="entreprise-header">
    <SignupSecondaryTextComponent class="tw-text-xs tw-font-semibold tw-mt-1">
      <b> {{ title }} </b>
    </SignupSecondaryTextComponent>
    <SignupSecondaryTextComponent class="tw-text-xs tw-mt-2">
      {{ description }}
    </SignupSecondaryTextComponent>
  </div>
</template>

<script>
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
export default {
  name: "CollaborateurImportBlockTextComponent",
  components: {SignupSecondaryTextComponent},
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
