<template>
  <div v-if="!isChangingPack">
    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div>
        <EntrepriseHeaderBlockTextComponent title="Changer de pack" />
      </div>
      <a style="width: fit-content" class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="$router.push({ name: 'Company.Profile' })">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
        </svg>
        <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
      </a>
      <div class="tw-mt-8">
        <RadioButtonComponent 
          :select="engagement"
          :items="engagementItems" 
          @change="engagementChangeHandler"
        />
        <RadioButtonComponent 
          :select="regulation" 
          :items="regulationItems"
          @change="regulationChangeHandler"
        />
      </div>
      <div class="tw-mt-4 tw-grid tw-grid-cols-4 tw-gap-14 tw-place-items-center">
        <OfferItemComponent
          v-for="offer in offers"
          :key="offer.id"
          :offer="offer"
          :selectedOfferId="currentOfferId"
          :style="{ cursor: `${offer.isActive ? 'pointer' : 'default'} !important` }"
          @click="offer.isActive && selectOfferHandler(offer.id)"
          :selected-regulation="regulation.toString()" 
          :selected-engagement="engagement.toString()"
          :is-upcoming="upcommingSubscriptionPlanToken === offer.stripeToken"
        />
      </div>
    </template>
  </div>
  <div v-else>
    <div>
      <EntrepriseHeaderBlockTextComponent
        title="Changer de pack"
        description=""
      />
    </div>
    <a style="width: fit-content" class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="(isChangingPack = false)">
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
      </svg>
      <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
    </a>
    <div class="tw-mt-8 tw-flex tw-items-center tw-justify-around">
      <OfferItemComponent 
        :offer="selectedOffer" 
        :selected-regulation="regulation.toString()" 
        :selected-engagement="engagement.toString()"
      />

      <div class="tw-w-3/5 tw-mt-8">
        <div class="tw-py-5 tw-flex tw-justify-center tw-rounded-t-2xl" :style="{background: `${referrer.theme.ctaBgColor}`}">
          <span class="tw-font-semibold tw-text-sm tw-text-white">Détails du pack sélectionné</span>
        </div>
        <div class="tw-px-5 tw-py-10 tw-bg-[#FAFAFA] tw-rounded-b-2xl tw-space-y-4 tw-flex tw-flex-col tw-items-center">
          <div class="tw-flex tw-items-center tw-space-x-5 tw-w-4/5">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5.5" cy="5.5" r="5.5" :fill="`${referrer.theme.ctaBgColor}`"/>
            </svg>
            <span class="tw-text-xs">Nombre de salariés : {{ selectedOffer.plan.salaryRange }}</span>
          </div>
          
          <div class="tw-flex tw-items-center tw-space-x-5 tw-w-4/5">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5.5" cy="5.5" r="5.5" :fill="`${referrer.theme.ctaBgColor}`"/>
            </svg>
            <span class="tw-text-xs">Nombre de sous-traitants : {{ selectedOffer.plan.subContractorNumber }}</span>
          </div>
          
          <div class="tw-flex tw-items-center tw-space-x-5 tw-w-4/5">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5.5" cy="5.5" r="5.5" :fill="`${referrer.theme.ctaBgColor}`"/>
            </svg>
            <span class="tw-text-xs">
              Tarif :
              {{ selectedOffer.price.toLocaleString('fr-FR') }}€/mois
              ({{ (selectedOffer.price * 12).toLocaleString('fr-FR') }}€/an)
            </span>
          </div>

          <v-btn
            elevation="1" text rounded large
            class="tw-text-white !tw-mt-14"
            :style="{ backgroundColor: `${referrer.theme.ctaBgColor}` }"
            :loading="isStartedChangingPack"
            @click="applyOfferHandler"
          >
            <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-12">Valider</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import OfferItemComponent from "@/features/company/components/Profile/OfferItemComponent";
import RadioButtonComponent from "@/components/common/RadioButtonComponent";
import { getPlansByParams } from "@/features/auth/services/appApi";
import { upgradeUserSubscription, getUserUpcomingSubscription } from "../../../services/appApi";
import { mapState } from "vuex";

export default {
  name: "ProfileUpdateSubscriptionPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    OfferItemComponent,
    RadioButtonComponent,
  },
  data() {
    return {
      loading: true,
      isChangingPack: false,
      isStartedChangingPack: false,
      currentOfferId: 2,
      selectedOffer: null,
      upcommingSubscriptionPlanToken: null,
      regulation: "monthly",
      engagement: 24,
      engagementItems: [
        {
          value: 12,
          label: "Engagement 1 an",
        },
        {
          value: 24,
          label: "Engagement 2 ans",
        },
      ],
      regulationItems: [
        {
          value: "yearly",
          label: "Règlement annuel",
        },
        {
          value: "monthly",
          label: "Règlement mensuel",
        },
      ],
      offers: [],
    };
  },
  computed: {
    ...mapState(['referrer', 'user']),
  },
  async mounted() {
    this.engagement = this.user.workInCompany.planPrice.engagement;
    this.regulation = this.user.workInCompany.planPrice.type;
    this.currentOfferId = this.user.workInCompany.planPrice.id;
    await this.getUpcomingSubscription();
    this.getPlans();
  },
  methods: {
    isOfferAvailable(offer) {
      return (
        this.currentOfferId !== offer.id &&
        this.upcommingSubscriptionPlanToken !== offer.stripeToken
      );
    },
    getPlans() {
      this.loading = false;
      this.offers = [];

      getPlansByParams({ regulation: this.regulation, engagement: this.engagement })
        .then(({ data }) => {
          data.data.forEach(plan => {
            this.offers.push({
              id: plan.id,
              stripeToken: plan.stripeToken,
              price: plan.price,
              plan: {
                salaryRange: plan.plan.salaryRange,
                subContractorNumber: plan.plan.subContractorNumber,
                img: plan.plan.img,
              },
              isActive: this.isOfferAvailable(plan)
            })
          });
        })
        .catch(error => console.log(error))
        .finally(() => { this.loading = false; });
    },
    async getUpcomingSubscription() {
      try {
        const { data: { planToken } } = await getUserUpcomingSubscription()
        this.upcommingSubscriptionPlanToken = planToken;
      }
      catch(error) {
        console.log(error)
      }
    },
    engagementChangeHandler(value) {
      this.engagement = value;
      this.getPlans();
    },
    regulationChangeHandler(value) {
      this.regulation = value;
      this.getPlans();
    },
    selectOfferHandler(id) {
      this.selectedOffer = this.offers.find(offer => offer.id === id);
      this.isChangingPack = true;
    },
    applyOfferHandler() {
      this.isStartedChangingPack = true;

      upgradeUserSubscription({ planPriceId: this.selectedOffer.stripeToken })
        .then(async () => {
          this.$snackbar.showAlertMessage({
            message: "Votre abonnement a été mis à jour avec succès",
            type: "success",
          });
          this.upcommingSubscriptionPlanToken = this.selectedOffer.stripeToken;
          this.offers = this.offers.map(offer => {
            if (offer.stripeToken === this.selectedOffer.stripeToken) offer.isActive = false;
            return offer;
          });
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la mise à jour de votre abonnement",
            type: "error",
          });
        })
        .finally(() => { 
          this.isChangingPack = false;
          this.isStartedChangingPack = false;
        });
    }
  }
}
</script>