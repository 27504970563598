<template>
<div id="rgpd-detail-container" class="tw-pt-8 tw-px-1">
  <v-btn class="tw-mb-8 rgpd-detail-retour-btn" color="#25282B" plain :to="{ name: 'Company.RgpdProcesses.List' }">
    Retour
  </v-btn>
  <div class="tw-flex tw-align-start tw-justify-between">
    <EntrepriseHeaderBlockTextComponent title="Mes processus RGPD" />
  </div>
  <v-row>
    <v-col cols="12" md="11">
      <div class="tw-w-full tw-flex tw-items-center tw-justify-between tw-my-6">
        <SignupSecondaryTextComponent>
          <b>{{ selectedRgpd.name }}</b>
        </SignupSecondaryTextComponent>
        <a :href="url" class="rgpd-detail-button" target="_blank" download>Télécharger</a>
      </div>
        <div v-if="selectedRgpd.firstRow" class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-space-x-4 tw-mb-6">
          <div class="rgpd-detail-item first" v-html="selectedRgpd.firstRow.first" />
          <div class="rgpd-detail-item second one" v-html="selectedRgpd.firstRow.second" />
          <div class="rgpd-detail-item second two" v-html="selectedRgpd.firstRow.third" />
          <div class="rgpd-detail-item second three" v-html="selectedRgpd.firstRow.forth" />
          <div class="rgpd-detail-item second four" v-html="selectedRgpd.firstRow.fifth" />
        </div>

        <div  v-if="selectedRgpd.secondRow"  class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-space-x-4 tw-mb-6">
          <div class="rgpd-detail-item first" v-html="selectedRgpd.secondRow.first" />
          <div class="rgpd-detail-item second one" v-html="selectedRgpd.secondRow.second" />
          <div class="rgpd-detail-item second two" v-html="selectedRgpd.secondRow.third" />
          <div class="rgpd-detail-item second three" v-html="selectedRgpd.secondRow.forth" />
          <div class="rgpd-detail-item second four" v-html="selectedRgpd.secondRow.fifth" />
        </div>

        <div  v-if="selectedRgpd.thirdRow"  class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-space-x-4 tw-mb-6">
          <div class="rgpd-detail-item" v-html="selectedRgpd.thirdRow.first" />
          <div class="rgpd-detail-item one" v-html="selectedRgpd.thirdRow.second" />
          <div class="rgpd-detail-item two" v-html="selectedRgpd.thirdRow.third" />
          <div class="rgpd-detail-item three" v-html="selectedRgpd.thirdRow.forth" />
          <div class="rgpd-detail-item four" v-html="selectedRgpd.thirdRow.fifth" />
        </div>

        <div  v-if="selectedRgpd.forthRow"  class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-space-x-4 tw-mb-6">
          <div class="rgpd-detail-item third" v-html="selectedRgpd.forthRow.first" />
          <div class="rgpd-detail-item third one" v-html="selectedRgpd.forthRow.second" />
          <div class="rgpd-detail-item third two" v-html="selectedRgpd.forthRow.third" />
          <div class="rgpd-detail-item third three" v-html="selectedRgpd.forthRow.forth" />
          <div class="rgpd-detail-item third four" v-html="selectedRgpd.forthRow.fifth" />
        </div>
    </v-col>
    <v-col cols="12" md="1">
      <div class="tw-flex tw-flex-row tw-justify-start tw-items-end tw-h-full" style="padding-bottom: 50px">
        <p class="rgpd-detail-items-text">©2022 Viqtor - tous droits reservés</p>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
export default {
  name: "RgpdProcessesDetail",
  components: {SignupSecondaryTextComponent, EntrepriseHeaderBlockTextComponent},
  data() {
    return {
      selectedRgpd: {},
      processesRgpdList: [
        {
          id: 2222,
          name: 'Respect de la vie privée dès la conception',
          file: 'respect-de-la-vie-privée-dès-la-conception.pdf',
          firstRow: {
            first: "Demande <br /> de nouveau service",
            second: "Analyse <br /> du traitement",
            third: "Etude <br /> d’impact",
            forth: "Intégration  <br /> de la réglementation",
            fifth: "Validation <br /> du cahier des charges <br /> final"
          },
          secondRow: {
            first: "Service commercial <br /> Client",
            second: "Demandeur Superviseur",
            third: "Demandeur Superviseur",
            forth: "Demandeur Superviseur",
            fifth: "DG Demandeur DPO"
          },
          thirdRow: {
            first: "Cahier des charges",
            second: "Cartographie <br />  des données",
            third: "Analyse <br /> des risques",
            forth: "Recommandations",
            fifth: "Validation <br /> du cahier des charges <br /> final"
          },
          forthRow: {
            first: "Innovation Efficience Performance Service Qualité Croissance",
            second: "Identification des traitements de données personnelle. Catégories de données personnelles traitées. Objectifs poursuivis par les opérations de traitements. Acteurs qui traitent ces données. Origine et la destination des données.",
            third: "Description du traitement et de ses finalités. Évaluation de nécessité et de la proportionnalité du traitement. Appréciation des risques sur les droits et libertés des personnes concernées",
            forth: "Mesures de sécurité techniques, contractuelles et organisationnelles Mesures permettant l’exercice des droits",
            fifth: "Innovation Efficience Performance Service Qualité Croissance Respect de la vie privée"
          }
        },
        {
          id: 4444,
          name: 'Analyse d’impact relative à la protection des données',
          file: 'analyse-d’impact-relative-à-la-protection-des-données.pdf',
          firstRow: {
            first: "Identification",
            second: "Validation du traitement",
            third: "Analyse de  de risques",
            forth: "Synthèse",
            fifth: "Validation"
          },
          secondRow: {
            first: "Représentant <br /> du responsable de <br /> traitement",
            second: "Représentant <br /> du responsable de <br /> traitement",
            third: "Représentant de <br /> du responsable de de <br /> traitement",
            forth: "Représentant <br /> du responsable de traitement <br /> DPO",
            fifth: "Comité de pilotage RGPD <br /> Personnes concernées"
          },
          thirdRow: {
            first: "Registre de traitement <br /> ad hoc",
            second: "Evaluation <br />  de la conformité",
            third: "Analyse <br /> des risques",
            forth: "Documentation",
            fifth: "Décision"
          },
          forthRow: {
            first: "Description du traitement Finalités du traitement Enjeux du traitement Responsable du traitement Sous-traitant(s) Données Destination Conservation",
            second: "Évaluation des mesures garantissant la proportionnalité et la nécessité du traitement. Evaluation des mesures protectrices des droits des personnes des personnes concernées",
            third: "Analyse et estimation des risques Mesures contribuant à traiter des risques liés à la sécurité des données Mesures générales de sécurité Mesures organisationnelles",
            forth: "Cartographie des risques liés à la sécurité des données Synthèse relative à la conformité aux bonnes pratiques des mesures contribuant à traiter les risques liés à la sécurité des données. Élaboration du plan d’action",
            fifth: "Conseil du DPO. Avis des personnes concernées. Avis de l’autorité de contrôle Formalisation de la validation du responsable de traitement Registre des AIP"
          }
        },
        {
          id: 7777,
          name: 'Violation de données à caractère personnel',
          file: 'violation-de-données-à-caractère-personnel.pdf',
          firstRow: {
            first: "Violation de sécurité",
            second: "Réunion du comité <br /> violation de données",
            third: "Communication",
            forth: "Suites judiciaires",
            fifth: "Retour d’expérience"
          },
          secondRow: {
            first: "Info tiers (clients, ANSSI…) <br /> Info interne (Service <br /> informatique…)",
            second: "Comité violation de <br /> données",
            third: "DPO",
            forth: "Direction",
            fifth: "DPO"
          },
          thirdRow: {
            first: "Identification <br /> de la violation de donnée",
            second: "Plan d’action",
            third: "Notifications <br /> & communication média",
            forth: "Plainte",
            fifth: "Documentation"
          },
          forthRow: {
            first: "Origine de l’alerte Données impactées Volumétrie Nombre de clients impactés Mesure techniques prises ou à prendre pour remédier à la violation",
            second: "Présentation de la situation. Analyse des risques au regard du RGPD par le DPO. Validation d’un plan d’action portant sur la remédiation et la communication",
            third: "Notification : - A la CNIL sous 72 heures des éléments prévus à l’article 33.3 du RGPD. - Aux clients sous réserve de l’évaluation des conséquences de l’information et des exceptions prévues à l’article 34.3 du RGPD",
            forth: "Dépôt de plainte auprès du Procureur de la République au vu des infractions commises",
            fifth: "Documentation du registre interne des violations de données. Mise à jour du registre des violations de données"
          }
        },
        {
          id: 8888,
          name: 'Audit',
          file: 'audit.pdf',
          firstRow: {
            first: "Détermination <br /> du périmètre audité",
            second: "Audit <br /> de la gouvernance",
            third: "Audit <br /> du registre de traitement",
            forth: "Audit <br /> des mesures de sécurité",
            fifth: "Rapport d’audit"
          },
          secondRow: {
            first: "Comité de pilotage RGPD",
            second: "DPO",
            third: "DPO",
            forth: "DPO",
            fifth: "Comité de pilotage RGPD"
          },
          thirdRow: {
            first: "Demande d’audit",
            second: "Observation des écarts",
            third: "Contrôle <br /> des cohérences <br /> et des mises à jour",
            forth: "Evaluation <br /> de la pertinence et de <br /> l’efficacité des mesures",
            fifth: "Plan d’action"
          },
          forthRow: {
            first: "Traitements internes Sous-traitants PIA Personnes à auditer",
            second: "Politique générale de protection des données. Responsabilité de la direction. Management de la ressource. Mise en œuvre de la protection",
            third: "Cartographie des données Responsabilité Origine des données Destinataires des données Transferts de données Durée de conservation Identification des Mesures de sécurité",
            forth: "Mesures de sécurité techniques, contractuelles et organisationnelles Mesures permettant l’exercice des droits",
            fifth: "Mesure des écarts avec le référentiel RGPD. (dé)Référencement Programme de mise en conformité. Registre des audits"
          }
        },
        {
          id: 5555,
          name: 'Exercice des droits',
          file: 'exercice-des-droits.pdf',
          firstRow: {
            first: "Courrier de demande",
            second: "Réception du courrier",
            third: "Recevabilité <br /> de la demande",
            forth: "Exécution de la demande",
            fifth: "Information de la <br /> personne concernée"
          },
          secondRow: {
            first: "Personne concernée",
            second: "DPO <br /> Assistante adm. <br /> DG.",
            third: "DPO <br /> Responsable de <br /> traitement",
            forth: "Responsable de <br /> traitement",
            fifth: "DPO <br /> Assistante adm."
          },
          thirdRow: {
            first: "Mail avec PJ <br />  ou <br />  courrier",
            second: "Ouverture <br /> d’une demande",
            third: "Validation <br /> de la demande",
            forth: "Mise à jour",
            fifth: "Clôture du dossier"
          },
          forthRow: {
            first: "Accès Rectification Effacement Portabilité Limitation du traitement Opposition au traitement Refus de décision automatisée",
            second: "Vérification de l’identité de la personne. Inscription au registre des demandes. ouverture d’un ticket. Confirmation de réception sous 48 h",
            third: "Examen des cas de figure envisagés par le règlement Examen de exceptions prévues au règlement. Examen de la faisabilité techniques. Examen des conséquences pour le traitement",
            forth: "Prise en compte de la demande dans la base de donnée. Information des destinataires internes et externes",
            fifth: "Sous un mois (ou prolongation annoncée et justifiée de deux mois) : Mail avec PJ ou courrier au client Clôture du ticket Mise à jour du registre des demandes"
          }
        }
      ]
    }
  },
  mounted() {
    this.processesRgpdList.map(item => {
      if(item.id === Number(this.$route.params.id)) { this.selectedRgpd = item }
    });
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    url() {
      return `${this.$asset_url}/files/${this.referer.title === 'Viqtor' ? 'viqtor' : 'iron'}/${this.selectedRgpd.file}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.rgpd-detail-retour-btn {
  text-transform: none !important;
  text-underline-offset: 2px;
  padding: 0 !important;
}

.rgpd-detail-button {
  background: var(--cta-bg-color);
  border-radius: 20px;
  padding: 11px 41px;

  font-family: 'Poppins', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF !important;
}
.rgpd-detail-items-text {
  font-style: normal;
  font-weight: 600;
  font-size: 6px;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #333333;
  transform: rotate(-90deg);
}

.rgpd-detail-item {
  padding: 0 10px;
  border-radius: 5px;
  background-color: var(--rgpd-processes-color-one);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 183px;
  min-height: 80px;

  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #FFFFFF;

  &.first {
    background-color: var(--rgpd-processes-color-one);
    border-radius: 5px 50.5px 50.5px 5px;
  }

  &.second {
    border-radius: 0 50.5px 50.5px 0;
     &:before {
       content: "";
       position: absolute;
       top: 0;
       left: -15px;
       width: 32px;
       height: 100%;
       shape-outside: ellipse(20% 50%);
       -webkit-clip-path: ellipse(47% 50%);
       clip-path: ellipse(47% 50%);
       background: #FFFFFF;
     }
  }

  &.third {
    min-height: 220px;
  }

  &.one {
    background-color: var(--rgpd-processes-color-two);
  }

  &.two {
    background-color: var(--rgpd-processes-color-three);
  }

  &.three {
    background-color: var(--rgpd-processes-color-four);
  }

  &.four {
    background-color: var(--rgpd-processes-color-five);
  }
}

</style>
