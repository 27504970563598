var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[(_vm.$parent.videoModal)?_c('v-dialog',_vm._g(_vm._b({attrs:{"content-class":"video-modal","max-width":"1000"},on:{"click:outside":_vm.clickOutsideDialog},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto mt-0 mb-16 tw-relative tw-z-10 tw-hidden lg:tw-block",style:({
          'background-color': `${_vm.theme.ctaBgColor} !important`,
          color: `${_vm.theme.ctaTextColor} !important`,
          outline: 'none !important',
          border: 'none !important'
        })},'v-btn',attrs,false),on),[_vm._v("Voir la vidéo "),_c('img',{attrs:{"width":"10%","src":require("@/assets/images/icons/play.svg")}})]),_c('v-btn',_vm._g(_vm._b({staticClass:"lg:tw-hidden tw-block",style:({
           marginTop: '260px',
           marginLeft: '30px',
          'background-color': `${_vm.theme.ctaBgColor} !important`,
          color: `${_vm.theme.ctaTextColor} !important`,
          outline: 'none !important',
          border: 'none !important'
        })},'v-btn',attrs,false),on),[_vm._v("Voir la vidéo "),_c('img',{attrs:{"width":"10%","src":require("@/assets/images/icons/play.svg")}})])]}}],null,false,1936613516)},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('video',{attrs:{"id":"video-landing","controls":"","autoplay":"","src":require("@/assets/videos/VIQTOR_V01.mp4")}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }