<template>
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <h1 class="tw-font-extrabold tw-text-4xl tw-flex tw-justify-center tw-items-center">En attendant la validation 
            <div class="dots tw-flex tw-justify-center tw-items-center">
                <span class="changeColor1">.</span>
                <span class="changeColor2">.</span>
                <span class="changeColor3">.</span>
            </div>
        </h1>
        <p>
            <span v-if="referrer.title !== 'Viqtor Expert'">
                Si vous n'avez pas encore choisi ou renouvelé votre plan, veuillez cliquer
            </span>
            <span v-if="referrer.title === 'Viqtor Expert'">
                Si vous n'avez pas encore passé au paiement, veuillez cliquer
            </span>
            <span class="tw-font-semibold tw-cursor-pointer" @click="goToPlan">ici</span>
        </p>
    </div>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.state.user;
        },
        referrer() {
            return this.$store.state.referrer;
        }
    },
    methods:{
        goToPlan(){
            if (this.referrer.title === 'Viqtor Expert') {
                this.$router.push({ name: 'SetupAccountExpert'})
                return;
            }

            this.$router.push({ name: !this.user.workInCompany.planPriceId
                ? 'SetupAccountCompany'
                : 'SetupAccountPricingCompany',
                params: { planId: this.user.workInCompany.planPriceId }
            })
        }
    }
}
</script>

<style>
.dots{
    width: 50px;
}
@keyframes changeColor {
    0%   {font-size: 2.25rem;}
  25%  {font-size: 3rem;}
  50%  {font-size: 3.75rem;}
  75%  {font-size: 3rem;}
  100% {font-size: 2.25rem;}
}

/* The element to apply the animation to */
.changeColor1 {
  color: rgb(66, 66, 66);
  animation-name: changeColor;
  animation-duration:0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.changeColor2 {
  color: rgb(66, 66, 66);
  animation-name: changeColor;
  animation-duration:0.6s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.changeColor3 {
  color: rgb(66, 66, 66);
  animation-name: changeColor;
  animation-duration:0.6s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
</style>