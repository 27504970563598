<template>
  <div id="entreprise-header">
    <h1 class="mon-entreprise-title">{{ title }}</h1>
    <span class="mon-entreprise-para">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: "CollaborateurBlockTextComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
