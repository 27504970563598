<template>
  <div>
    <h3 class="tw-text-center tw-font-semibold">CONDITIONS GENERALES D’UTILISATION ET D’ASSISTANCE DBWO GOUVERNANCE RGPD</h3>
    <br/><br/>
    <p class="tw-text-justify">Les présentes Conditions Générales (ci-après les « Conditions Générales ») s’appliquent entre la société DBWO, Société par Actions Simplifiée au capital de 2.000,00 €, immatriculée au Registre du Commerce et des Sociétés de Paris sous le n° 848 437 893, dont le Siège social est situé au 70 rue de Ponthieu, 75008 Paris (ci-après dénommée « DBWO ») et le client (ci-après le « Client »), ayant signé le Devis qui intègre les Conditions Générales par référence, ce que le Client reconnait expressément.</p>
    <br/>
    <div class="tw-border-2 tw-border-black tw-p-4">
      <h3 class="tw-text-center tw-font-semibold">AVERTISSEMENT</h3><br/>
      <p class="tw-text-justify">DBWO s’engage à mettre à la disposition du Client l’ensemble des informations nécessaires et utiles à la conclusion des Conditions Générales.</p><br/>
      <p class="tw-text-justify">Client, DBWO s’engage à apporter au Client toute information complémentaire utile à la bonne compréhension des produits et services proposés par DBWO.</p><br/>
      <p class="tw-text-justify">Le degré d’information précontractuelle apporté par DBWO s’entend dans la limite de sa connaissance du projet du Client, ainsi que de son infrastructure informatique et de l’exactitude des informations communiquées par le Client à DBWO dans le cadre de l’expression de son besoin.</p><br/>
      <p class="tw-text-justify">A défaut de mention contraire, les informations fournies par DBWO au Client ont une portée générale et sont limitées à ce que les clients de DBWO souhaitent de manière générale pour une situation analogue à celle du Client. </p><br/>
      <p class="tw-text-justify">Par conséquent, le Client reconnait avoir choisi l’application VIQTOR au regard des informations précontractuelles portées à sa connaissance par DBWO et qu'il reconnaît avoir reçues. </p>
    </div>
    <br/><br/>
    <p class="tw-border-b tw-border-black">
      Préambule
    </p><br/>
    <p class="tw-text-justify">Le client reconnaît avoir préalablement pris connaissance des conditions générales applicables à la date de la validation du Devis, de la conclusion du présent contrat ou de son renouvellement.</p><br/>
    <p class="tw-text-justify">La validation du Devis, la conclusion du contrat ou son renouvellement, ainsi que l'installation et l'utilisation de l’application VIQTOR, impliquent l'acceptation sans réserve de ces Conditions Générales par le client. DBWO se réserve le droit de mettre à jour les Conditions Générales et s'engage à en informer le Client par tout moyen. </p><br/>
    <p class="tw-text-justify">La poursuite de l'utilisation de l’Application VIQTOR et/ou le recours aux services de l'assistance DBWO postérieurement à la notification par DBWO de la modification des présentes présume(nt) l'acceptation sans réserve du Client des nouvelles Conditions Générales ainsi notifiées. La version la plus récente des Conditions Générales peut être consultée librement par le Client à tout moment sur www.DBWO.fr, rubrique « Conditions Générales ». </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">1.</span>
      <span>Définition</span>
    </p><br/>
    <p class="tw-text-justify">« Affilié » désigne toute entité contrôlée par le Client (le terme « contrôle » s’entendant au sens qui lui est donné par l’article L.233- 3 du Code de Commerce).</p><br/>
    <p class="tw-text-justify">« Adaptations » désigne un groupe de prestations réalisé par DBWO ou tout tiers choisi par le Client composé de la réalisation de développements spécifiques et/ou d’interfaces et/ou de la personnalisation d’éditions, effectuées au titre d’un contrat de prestations distinct. </p><br/>
    <p class="tw-text-justify">« Base de données » désigne l’ensemble des données organisées et transmises par le Client comportant des informations sur ses salariés ou ses fournisseurs et utilisé dans le cadre du Service. </p><br/>
    <p class="tw-text-justify">« Devis » désigne tout devis édité par DBWO et dûment signé par le Client. </p><br/>
    <p class="tw-text-justify">« Documentation » désigne la description des fonctionnalités et du mode d'emploi de l’Application VIQTOR. Elle est disponible dans l’application VIQTOR ou sur simple demande écrite du Client à DBWO, au format papier ou électronique à la convenance de DBWO et en langue française ou anglaise. </p><br/>
    <p class="tw-text-justify">« Service » désigne le service objet du Contrat. </p><br/>
    <p class="tw-text-justify">« Site » désigne le lieu d’installation de l’Application VIQTOR. Ce Site peut être différent du ou des sites dans lesquels se trouvent les utilisateurs. « Site Internet » désigne l’espace mis à disposition par DBWO pour que le Client puisse accéder au Service. Ce site est disponible à l’adresse <a href="www.viqtor.com">www.viqtor.com</a> </p><br/>
    <p class="tw-text-justify">« Territoire » désigne la France métropolitaine, Monaco et Corse, à l'exclusion des Dom/Tom. </p><br/>
    <p class="tw-text-justify">« Utilisateur » désigne toute personne physique ou morale exploitant les fonctionnalités de l’Application Viqtor dans un cadre professionnel pour ses besoins de gestion des données privées. </p><br/>
    <p class="tw-text-justify">« Utilisation » ou « Utiliser » signifient exploiter le service afin de réaliser le traitement des opérations du Client. </p><br/>
    <p class="tw-text-justify">« VIQTOR » désigne l’application de mise en conformité et de suivi RGPD VIQTOR mise à disposition en mode SaaS et sa Documentation associée.</p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">2.</span>
      <span>Contenu</span>
    </p><br/>
    <p class="tw-text-justify">Les Conditions Générales définissent les conditions dans lesquelles le client est autorisé à utiliser le service mis à sa disposition par DBWO.</p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">3.</span>
      <span>Document contractuel </span>
    </p><br/>
    <p class="tw-text-justify">Les Conditions Générales sont formées, entre DBWO et le Client, par les documents contractuels suivants présentés par ordre hiérarchique de valeur juridique décroissante :</p><br/>
    <ul class="tw-list-disc tw-ml-12">
      <li>Tout avenant éventuellement signé entre les Parties et ayant pour objet d’amender un ou plusieurs documents contractuels,</li>
      <li>Le corps des Conditions Générales,</li>
      <li>Le Devis,</li>
    </ul><br/>
    <p class="tw-text-justify">En cas de contradiction entre une ou plusieurs dispositions figurant dans les documents mentionnés ci-dessus, le document de rang supérieur prévaut.</p><br/>
    <p class="tw-text-justify">Aucune annotation manuscrite du Client sur le Devis ou sur tout autre document contractuel n’aura de valeur entre les Parties sauf accord écrit de DBWO sur ladite modification ou mention.</p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">4.</span>
      <span>Condition d’utilisation et d’assistance</span>
    </p><br/>
    <span class="tw-ml-6 tw-mr-4">4.1.</span>
    <span>Condition d’Utilisation </span>
    <br/><br/>
    <span class="tw-ml-12 tw-mr-4">4.1.1.</span>
    <span>Inscription</span>
    <br/><br/>
    <p class="tw-text-justify">Afin de disposer d’un accès au Service, le Client doit remplir le formulaire d’inscription et le valider en ligne directement sur le Site Internet.</p><br/>
    <p class="tw-text-justify">Le formulaire doit être rempli avec précision et chaque champ obligatoire doit être complété sous peine de ne pouvoir prendre en considération les demandes du Client. </p><br/>
    <p class="tw-text-justify">En outre le Client s’engage à ne transmettre à DBWO aucune information fausse ou erronée figurant dans le formulaire d’inscription. </p><br/>
    <p class="tw-text-justify">Le Client déclare être juridiquement capable de remplir et d’adresser le formulaire d’inscription à DBWO. </p><br/>
    <p class="tw-text-justify">Il appartient au Client de sauvegarder et/ou d’éditer le formulaire d’inscription sur tout support de son choix.</p><br/>
    <span class="tw-ml-12 tw-mr-4">4.1.2.</span>
    <span>Mot de passe et authentification</span>
    <br/><br/>
    <p class="tw-text-justify">A réception du formulaire d’inscription, DBWO permettra au Client d’enregistrer un identifiant et un mot de passe lui permettant de se connecter au Service. </p><br/>
    <p class="tw-text-justify">Le Client demeure seul responsable du mot de passe qu’il définit.</p><br/>
    <p class="tw-text-justify">En cas de perte ou de vol de son mot de passe, le Client pourra s’en créer un nouveau sur le Site Internet à partir de l’option disponible en ligne. </p><br/>
    <p class="tw-text-justify">En cas de perte de son identifiant, le Client sera tenu d’en informer DBWO dans les plus brefs délais.</p><br/>
    <span class="tw-ml-12 tw-mr-4">4.1.3.</span>
    <span>Attribution des droits d’Utilisation du Service </span>
    <br/><br/>
    <p class="tw-text-justify">En retour du formulaire, et après réception du paiement correspondant au Service tel que visé au sein du Contrat, il sera concédé au Client le droit limité, personnel, non cessible et non exclusif d'accès et d’Utilisation de l’Application VIQTOR dans le cadre du Service. </p><br/>
    <span class="tw-ml-12 tw-mr-4">4.1.4.</span>
    <span>Description du Service</span>
    <br/><br/>
    <p class="tw-text-justify">Le Service permet au Client d’accéder pour ses besoins personnels, via le réseau internet, à la méthodologie développée par DBWO ainsi qu’à l’ensemble des tableaux de bords, bases de données, procédures, documentations et formulaires juridiques, questionnaires, outils de pilotage, de traçage, d’évaluation, de communication, de formation et de contractualisation permettant la mise en place et le suivi d’une gouvernance des données privées détenues par l’entreprise  conforme aux exigences du RGPD. </p><br/>
    <span class="tw-ml-6 tw-mr-4">4.2.</span>
    <span>Condition d’assistance</span>
    <br/><br/>
    <p class="tw-text-justify">Pendant la durée du Contrat souscrit par le Client, DBWO mettra tous les moyens en œuvre afin d’assurer un service d’assistance comme suit :</p><br/>
    <ul class="tw-list-disc tw-ml-12">
      <li>Le service d'assistance téléphonique est disponible de 9h00 à 18h00 du lundi au jeudi et de 9h00 à 17h00 le vendredi ; </li>
      <li>Le Client dispose d’un nombre d'appels téléphoniques illimité auprès de l’équipe de techniciens spécialisés afin de poser toutes questions afférentes à l’Utilisation du Service et toutes questions en matière de traitement de la paye ; </li>
      <li>Le Client dispose également de la possibilité de poser des questions par écrit à l’équipe de techniciens spécialisés, 24h/24 et 7j/7, en utilisant la fonction « poser votre question » disponible à partir de son dossier. Les techniciens traitent les réponses dans un délai raisonnable, les jours ouvrés, dans les plages d’ouverture du service d’assistance. </li>
    </ul><br/>
    <p class="tw-text-justify">DBWO n’assurera pas les services d’assistance à l’égard : </p><br/>
    <ul class="tw-list-disc tw-ml-12">
      <li>De toute demande d’assistance n’entrant pas dans l’objet du Contrat, </li>
      <li>De toute demande d’intervention par un Utilisateur autre qu’un interlocuteur référencé dans le formulaire d’inscription,</li>
      <li>De toute demande qui paraîtrait contraire aux dispositions légales en vigueur,</li>
      <li>Une Utilisation du Service non conforme au Contrat, aux consignes d’Utilisation ou à sa destination, ou encore une Utilisation anormale, quelle que soit la raison (négligence, erreur de manipulation, accident)</li>
      <li>La fourniture d’un réseau de télécommunications ne permettant pas l’accès au Service, </li>
      <li>Une défaillance de l’un des éléments constituant l’environnement logiciel du Client (système d’exploitation, autres logiciels que l’application VIQTOR, systèmes réseaux...) ; </li>
      <li>D’une façon générale, le non-respect par le Client de ses obligations au titre du Contrat. </li>
    </ul><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">5.</span>
      <span>Prérequis </span>
    </p><br/>
    <p class="tw-text-justify">Il appartient au Client : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>De s’assurer de l’adéquation du Service offert à ses besoins et notamment sur la base des indications fournies et de la Documentation accessible sur le Site Internet ;</li>
      <li>De disposer d’une connexion au réseau internet de type ADSL ; </li>
      <li>De disposer du personnel qualifié pour transmettre et saisir l’ensemble des informations et données nécessaires pour la réalisation du Service, objet du Contrat. </li>
    </ul><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">6.</span>
      <span>Rôle et obligations de DBWO</span>
    </p><br/>
    <p class="tw-text-justify">DBWO fournit au Client un accès au Service en ligne lui permettant la mise en place et le suivi de sa conformité RGPD. DBWO héberge et assure la sécurité de la Base de données et de l’ensemble des données la constituant. </p><br/>
    <p class="tw-text-justify">DBWO mettra en œuvre des moyens pour assurer une veille légale et règlementaire en matière de conformité au règlement du la protection des données privées et procédera aux évolutions du Service rendues nécessaires par l'évolution de la législation. </p><br/>
    <p class="tw-text-justify">Pour les éléments particuliers à l’entreprise, DBWO apportera réponse aux demandes exprimées par le Client au service d’assistance. </p><br/>
    <p class="tw-text-justify">DBWO met à disposition une assistance permettant d’assurer le bon fonctionnement du Service au Client. </p><br/>
    <span class="tw-ml-6 tw-mr-4">6.1.</span>
    <span>Mise à disposition du Site </span>
    <br/><br/>
    <p class="tw-text-justify">DBWO s’engage à rendre accessible 7 jours sur 7 et 24 heures sur 24 le Service permettant au Client de collecter, incrémenter, visualiser, imprimer et transmettre, via le réseau internet, les informations nécessaires au suivi de la conformité RGPD. </p><br/>
    <p class="tw-text-justify">DBWO se réserve toutefois le droit de restreindre totalement ou partiellement l’accès aux Services afin d’en assurer la maintenance, dans le cadre de prestations programmées, de sa configuration informatique et des infrastructures mise en œuvre pour la fourniture des Services. </p><br/>
    <p class="tw-text-justify">A ce titre, DBWO s’efforcera, dans la mesure du possible, de ne pas rendre indisponible le Service via le réseau internet pendant un temps excessif. </p><br/>
    <span class="tw-ml-6 tw-mr-4">6.2.</span>
    <span>Mise en garde </span>
    <br/><br/>
    <p class="tw-text-justify">Pour la réalisation du Service, DBWO a mis en place un cryptage pour sécuriser la transmission de données sur internet (Cryptage SSL 128 bits). </p><br/>
    <p class="tw-text-justify">Toutefois, le Client déclare être informé du défaut de fiabilité du réseau internet, tout spécialement en termes de sécurité relative à la transmission des données, à la continuité dans l’accès au Service, aux performances en termes de volume et de rapidité de transmission des données. </p><br/>
    <span class="tw-ml-6 tw-mr-4">6.3.</span>
    <span>Base de données - hébergement – consultation en ligne des documents </span>
    <br/><br/>
    <p class="tw-text-justify">Il est expressément convenu entre les parties que la Base de données sera hébergée en France chez OVH par DBWO. </p><br/>
    <p class="tw-text-justify">Pendant la durée du Contrat, DBWO assure la consultation en ligne des documents issus du service sur les trois (3) dernières années. </p><br/>
    <span class="tw-ml-6 tw-mr-4">6.4.</span>
    <span>Evolution et mise à disposition du Service </span>
    <br/><br/>
    <p class="tw-text-justify">DBWO se réserve le droit de faire évoluer le Service. Toute modification sera opposable au client dès communication, sans préavis. </p><br/>
    <p class="tw-text-justify">En outre, DBWO se réserve le droit d’arrêter définitivement le Service. L’arrêt définitif de ce Service fera l’objet d’un préavis minimum de six (6) mois porté à la connaissance du Client par lettre recommandée avec avis de réception.</p><br/>
    <span class="tw-ml-6 tw-mr-4">6.5.</span>
    <span>Mise à jour du Service Mise en garde </span>
    <br/><br/>
    <p class="tw-text-justify">La nécessité de réaliser une mise à jour est décidée unilatéralement par DBWO au regard des évolutions légales et technologiques. Les mises à jour peuvent intégrer, selon les cas : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>La correction des Anomalies, </li>
      <li>Les modifications rendues nécessaires par l'évolution des textes législatifs ou réglementaires applicables aux fonctions traitées par le Service, sauf si ces modifications nécessitent une modification substantielle du Service qui fera alors l'objet de notification par DBWO au Client, </li>
      <li>L'apport d'améliorations des fonctions existantes. </li>
    </ul><br/>
    <p class="tw-text-justify">En revanche, est exclue du présent contrat, la fourniture d'un Service nouveau qui viendrait se substituer à l’Application VIQTOR existante. </p><br/>
    <span class="tw-ml-6 tw-mr-4">6.6.</span>
    <span>Restitution des éléments remis </span>
    <br/><br/>
    <p class="tw-text-justify">En cas de cessation des relations contractuelles pour quelque cause que ce soit, DBWO pourra restituer à la demande du Client l’ensemble des données et informations remises par le Client à DBWO dans le cadre du Contrat. </p><br/>
    <p class="tw-text-justify">Le cas échéant, tous les frais relatifs à la restitution des données et, qui sont disponibles sur simple demande auprès des services de DBWO, seront à la charge du Client. </p><br/>
    <p class="tw-text-justify">La demande du Client doit être faite dans un délai n’excédant pas trois (3) mois à compter de la cessation du Contrat pour quelques raisons que ce soit. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">7.</span>
      <span>Rôle et obligations du Client </span>
    </p><br/>
    <p class="tw-text-justify">Le Client s’engage : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>A ne pas utiliser le Service à des fins illicites, interdites et/ou illégales, ou dans des conditions qui ne sont pas prévues au Contrat ; </li>
      <li>A transmettre l’ensemble des informations préalablement vérifiées et contrôlées qui sont nécessaires afin de permettre à DBWO de restituer une situation fiable et sincère de l’état de conformité de l’entreprise au RGPD.</li>
      <li>A régler l’ensemble des sommes dues à DBWO au titre de l’accès au Service ainsi que les sommes dues au titre des éventuels services optionnels que le Client aura souhaité souscrire. </li>
    </ul><br/>
    <p class="tw-text-justify">En cas de non-respect des obligations visées aux présentes Conditions Générales par le Client, DBWO se réserve le droit de suspendre de plein droit et sans préavis l’accès au Service.</p><br/>
    <p class="tw-text-justify">Cette suspension d’accès au Service ne donne droit à aucune indemnité. </p><br/>
    <span class="tw-ml-6 tw-mr-4">7.1.</span>
    <span>Mise en Place.</span>
    <br/><br/>
    <p class="tw-text-justify">Dans la prestation de base, la saisie nécessaire à la qualification de la société de ses salariés et sous-traitants est à la charge du client</p><br/>
    <span class="tw-ml-6 tw-mr-4">7.2.</span>
    <span>Utilisation du Service</span>
    <br/><br/>
    <p class="tw-text-justify">A chaque fois qu’il l’estime nécessaire, le Client peut se connecter sur le Site Internet pour : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>Créer ou supprimer des salariés ;</li>
      <li>Créer ou supprimer des sous-traitants ; </li>
      <li>Mettre à jour le registre de traitement, les responsabilités</li>
      <li>Actionner et suivre les procédures ; </li>
      <li>Personnaliser et éditer/transmettre des outils contractuels</li>
      <li>Consulter la base documentaire</li>
      <li>Ouvrir des cessions e-learning</li>
      <li>Accéder à ses outils de pilotage et tableaux de synthèse</li>
    </ul><br/>
    <p class="tw-text-justify">À tout moment, le Client bénéficie des services de l’Assistance tels que décrit aux présentes. </p><br/>
    <span class="tw-ml-6 tw-mr-4">7.3.</span>
    <span>Devoir de contrôle et d’archivage </span>
    <br/><br/>
    <p class="tw-text-justify">Il est expressément convenu entre les Parties que les différents formulaires et fiches accessibles au sein du Site Internet sont complétés, informés et adressés par le Client à DBWO sous l’entière responsabilité du Client. </p><br/>
    <p class="tw-text-justify">A ce titre, Il appartient au Client de valider par une personne habilitée ou de faire valider, le cas échéant par un Délégué à la Protection des Données, l’ensemble des traitements réalisés. </p><br/>
    <p class="tw-text-justify">Le Client s’assure que seul son personnel autorisé ait accès aux serveurs de DBWO. Tout autre accès, à un tiers ne devra intervenir qu’après l’accord exprès de DBWO. </p><br/>
    <p class="tw-text-justify">Il est de la responsabilité exclusive du Client de se prémunir contre les risques de destruction ou de détérioration de ses fichiers et de l’ensemble des données transmises ou reçues dans le cadre du Service en procédant régulièrement aux archivages nécessaires (sauvegardes et/ou éditions). </p><br/>
    <p class="tw-text-justify">La conservation par DBWO durant trois (3) années des documents issus du Service répond à un besoin de consultation en ligne mais ne vaut pas archivage légal. </p><br/>
    <span class="tw-ml-6 tw-mr-4">7.4.</span>
    <span>Preuve et traçabilité </span>
    <br/><br/>
    <p class="tw-text-justify">Les registres informatisés seront considérés comme les preuves des communications et d’envoi des formulaires d’inscription par le Client dans le cadre du Service. </p><br/>
    <p class="tw-text-justify">L’archivage des différents formulaires d’inscription et de renseignements est effectué sur un support fiable de manière à correspondre à une copie fidèle et durable. </p><br/>
    <p class="tw-text-justify">En cas de conflit entre les registres informatisés de DBWO et tout document sur support écrit ou fichier électronique du Client, il est expressément convenu entre les Parties que les registres informatisés de DBWO primeront sur les documents du Client et seront seuls admis à titre de preuve. </p><br/>
    <span class="tw-ml-6 tw-mr-4">7.5.</span>
    <span>Accès et télécommunication </span>
    <br/><br/>
    <p class="tw-text-justify">Dans le but de permettre l’envoi à DBWO des informations relatives à la conformité RGPD en vue de leur traitement, le Client devra faire son affaire personnelle de l’accès à ces éléments et Services par la voie des télécommunications. Les coûts d’accès au serveur, à l’assistance de DBWO et notamment les frais de communication téléphonique, et les éventuels frais d’accès à internet, ne sont pas compris dans le prix de l’abonnement au Service.</p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">8.</span>
      <span>Indépendance des contrats</span>
    </p><br/>
    <p class="tw-text-justify">Le Client reconnaît que les Conditions Générales constituent un document contractuel autonome et indépendant et ne dépendent en aucun cas d’autres contrats qui auraient pu être conclus autour de l’Application VIQTOR. </p><br/>
    <p class="tw-text-justify">En conséquence, toute somme due au titre des Conditions Générales restera due par le Client étant entendu que DBWO ne procèdera à aucun remboursement d’une somme déjà versée en exécution des présentes. </p><br/>
    <p class="tw-text-justify">Le Client reconnait que les dispositions de l’article 1186 du Code Civil relatives à la caducité ne pourront être invoquées par lui pour se délier des obligations contractuelles souscrites auprès de DBWO quelles qu’elles soient. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">9.</span>
      <span>Durée - Résiliation </span>
    </p><br/>
    <span class="tw-ml-6 tw-mr-4">9.1.</span>
    <span>Durée </span>
    <br/><br/>
    <p class="tw-text-justify">Les Conditions Générales entrent en vigueur à compter de leur acceptation par le Client par la validation en ligne du lien d’activation du Service qui aura été envoyé par DBWO à la suite de l’inscription du Client au Service et à la réception de son paiement. </p><br/>
    <p class="tw-text-justify">Sauf stipulation contraire convenue entre les Parties et mentionnée dans le Devis, le Contrat est conclu pour une durée de douze (12) mois à compter de la date de la facture adressée au Client et selon le nombre de salariés déclarés. </p><br/>
    <p class="tw-text-justify">Les Conditions Générales seront automatiquement reconduites par tacite reconduction pour des périodes successives de douze (12) mois. </p><br/>
    <p class="tw-text-justify">Toute dénonciation du Contrat à l'initiative du Client, devra être notifiée par lettre recommandée avec accusé de réception et reçue par DBWO au plus tard deux (2) mois avant le terme de la période contractuelle en cours.</p><br/>
    <p class="tw-text-justify">DBWO se réserve le droit de dénoncer le Contrat par tout moyen jusqu'au terme de l'échéance en cours. </p><br/>
    <span class="tw-ml-6 tw-mr-4">9.2.</span>
    <span>Résiliation </span>
    <br/><br/>
    <p class="tw-text-justify">En cas de manquement par l’une des Parties aux obligations des présentes, non réparé dans un délai de trente (30) jours à compter de la lettre recommandée avec accusé de réception (ci-après la « Notification ») notifiant le manquement en cause, l’autre Partie pourra faire valoir la résiliation des Conditions Générales sous réserve de tous les dommages et intérêts auxquels elle pourra prétendre. </p><br/>
    <p class="tw-text-justify">Les Parties reconnaissent que le Service trouve son utilité au fur et à mesure de l'exécution des présentes. </p><br/>
    <p class="tw-text-justify">En conséquence, en cas de résiliation des Conditions Générales, quelle qu’en soit la raison, aucun remboursement ne pourra intervenir au profit du Client en exécution des dispositions de l’Article 1229 du Code Civil.</p><br/>
    <span class="tw-ml-6 tw-mr-4">9.3.</span>
    <span>Conséquences de la résolution ou de l’échéance des Conditions Générales </span>
    <br/><br/>
    <p class="tw-text-justify">En cas de résiliation des Conditions Générales ou à l’échéance de celui-ci, quelle qu’en soit la raison, les données du Client restent sa propriété et il est de sa responsabilité de les récupérer préalablement à la date de résiliation effective des Conditions Générales ou leur échéance. </p><br/>
    <p class="tw-text-justify">Tout manquement à cette obligation ne saurait affecter la date de résiliation des Conditions Générales ou leur échéance, ni entraîner la mise en cause de la responsabilité de DBWO. </p><br/>
    <p class="tw-text-justify">Toutes les stipulations des Conditions Générales qui ont vocation du fait de leur nature à lui survivre continueront à produire leurs effets, notamment les stipulations afférentes aux garanties et limitations de responsabilité. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">10.</span>
      <span>Droit de rétractation </span>
    </p><br/>
    <p class="tw-text-justify">Conformément à l’article L221-3 du Code de la consommation, le Client est informé qu’il dispose d’un droit de rétractation dans les conditions prévues par la loi. </p><br/>
    <p class="tw-text-justify">Ainsi dans l’hypothèse où les conditions cumulatives prévues par l’article susvisé seraient remplies, le Client pourra notifier à DBWO par lettre recommandée avec accusé de réception, sa volonté de se prévaloir dudit droit de rétractation dans un délai de quatorze (14) jours à compter de l’Activation du Service. </p><br/>
    <p class="tw-text-justify">En dehors de l’hypothèse susvisée, exclusivement réservée au Client professionnel entrant dans le champ de l’article L221-3 du Code de la consommation, aucun droit de rétractation ne pourra être exercé par le Client. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">11.</span>
      <span>Responsabilité </span>
    </p><br/>
    <p class="tw-text-justify">DBWO exécute les obligations contractuelles mises à sa charge avec tout le soin possible en usage dans sa profession. En aucun cas, DBWO ne pourra être déclarée responsable : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>De la destruction ou de la détérioration de fichiers ou de programmes appartenant au Client, </li>
      <li>Des préjudices indirects reconnus par la jurisprudence des tribunaux français et notamment préjudice commercial, perte de clientèle, perte de commande, atteinte à l'image de marque. </li>
    </ul><br/>
    <p class="tw-text-justify">Fournisseur d’un support cohérent de documentation de la conformité du Client, DBWO n’assume aucun devoir de conseil juridique ou organisationnel vis-à-vis de celui-ci et en cas de questions à ce sujet, communiquera au Client une liste de professionnels dûment qualifiés en ce domaine. Elle ne saurait être recherché en responsabilité en cas de défaillance du Client sur ce point.</p><br/>
    <p class="tw-text-justify">La responsabilité de DBWO ne saurait également être engagée en cas d’information fausse, incomplète ou absente du fait volontaire du Client ou de sa mauvaise compréhension des textes législatifs et règlementaires relatifs à la protection des données privées. </p><br/>
    <p class="tw-text-justify">DBWO ne peut être tenue pour responsable des difficultés ou impossibilités d’accès à son serveur par le Client, le matériel et l’équipement d’utilisation et d’accès au Service étant à la charge exclusive du Client. </p><br/>
    <p class="tw-text-justify">De même DBWO ne saurait être tenue responsable des défaillances dues aux réseaux intranet Client et internet ou des modifications apportées au réseau par le Client. </p><br/>
    <p class="tw-text-justify">Le Client est seul responsable du matériel installé par l’opérateur téléphonique sur ses Sites et doit en laisser le libre accès à ce dernier. </p><br/>
    <p class="tw-text-justify">En tout état de cause, si la responsabilité de DBWO venait à être reconnue au titre des présentes, l’indemnisation qui pourrait lui être réclamée serait expressément limitée au montant du prix hors taxes perçu par DBWO au titre du Devis concerné par le fait générateur de responsabilité, pour la période annuelle en cours lors de la survenance du dommage. </p><br/>
    <p class="tw-text-justify">Nonobstant ce qui précède, la limitation prévue ci-dessus ne sera pas applicable en cas de faute lourde ou faute intentionnelle de DBWO ou de décès ou dommages corporels causés par un employé de DBWO au Client. </p><br/>
    <p class="tw-text-justify">Les présentes dispositions établissent une répartition des risques entre DBWO et le Client. Le prix reflète cette répartition ainsi que la limitation de responsabilité décrite. </p><br/>
    <p class="tw-text-justify">Par ailleurs, par dérogation expresse à l’article 1222 du Code Civil, les Parties conviennent expressément d’écarter l’exécution forcée par un tiers ou le Client lui-même aux frais de DBWO. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">12.</span>
      <span>Conditions financières</span>
    </p><br/>
    <span class="tw-ml-6 tw-mr-4">12.1.</span>
    <span>Prix et paiement </span>
    <br/><br/>
    <p class="tw-text-justify">Pour la prestation de base, le Client paiera un prix forfaitaire en fonction du nombre de salariés déclarés selon un barème par tranche accessible sur le site internet.</p><br/>
    <p class="tw-text-justify">En cas d’entrée d’un nouveau salarié, le Client verra apparaître via le Service un message l’informant le cas échéant de son nouveau positionnement tarifaire. </p><br/>
    <p class="tw-text-justify">Les prix de l’abonnement annuel sont disponibles sur le Site Internet ou sur simple demande auprès des services de DBWO.  A l’engagement, les prix qui s’appliquent sont ceux publiés sur le Site Internet ou communiqués par les services de DBWO dans le cadre d’un devis ou par l’établissement d’une facture. </p><br/>
    <p class="tw-text-justify">Le Service est payable par mensualités. </p><br/>
    <span class="tw-ml-6 tw-mr-4">12.2.</span>
    <span>Dispositions communes</span>
    <br/><br/>
    <p class="tw-text-justify">Sauf mention contraire du Devis, les factures sont payables au comptant par prélèvement bancaire. </p><br/>
    <p class="tw-text-justify">Le montant de la redevance hors taxes sera majoré des taxes en vigueur à la date de facturation. </p><br/>
    <p class="tw-text-justify">Le défaut de paiement d’une redevance à échéance entraînera l’application d’une pénalité de retard qui sera facturée sur la base de trois fois le taux d’intérêt légal par jour de retard. Ces pénalités de retard seront dues dès le lendemain de la date d'échéance. </p><br/>
    <p class="tw-text-justify">Une indemnité forfaitaire de quarante euros (40 €) sera également due à DBWO pour frais de recouvrement, à l'occasion de tout retard de paiement. </p><br/>
    <p class="tw-text-justify">L'indemnité définie ci-dessus est due pour chaque facture payée en retard et non sur l'ensemble des factures concernées. </p><br/>
    <p class="tw-text-justify">Toutefois, cette indemnité ne s'appliquera pas si le Client est en cours de procédure de sauvegarde, de redressement ou de liquidation judiciaire. Si les frais de recouvrement réellement engagés sont supérieurs à ce montant forfaitaire, notamment en cas de recours à un cabinet de recouvrement externe, une indemnisation complémentaire pourra être demandée par DBWO au Client. </p><br/>
    <p class="tw-text-justify">L'indemnité sera due en totalité même en cas de paiement partiel de la facture à l'échéance, quelle que soit la durée du retard. Ce montant forfaitaire s'ajoute aux pénalités de retard mais n'est pas inclus dans la base de calcul des pénalités. </p><br/>
    <p class="tw-text-justify">Ces pénalités ne seront pas soumises à la TVA. </p><br/>
    <p class="tw-text-justify">Les pénalités de retard seront exigibles sans qu'un rappel ne soit nécessaire, l'envoi d'une lettre recommandée n'étant pas requis pour déclencher le droit pour DBWO de les percevoir. </p><br/>
    <p class="tw-text-justify">De plus, en cas de non-paiement d'une facture à son échéance, DBWO pourra également, après une mise en demeure restée sans effet pendant un délai de soixante (60) jours, suspendre les droits d’utilisation et d’assistance jusqu’au complet paiement de ladite facture. </p><br/>
    <p class="tw-text-justify">Le Client est dûment informé qu’il ne pourra être fait aucune révision des montants dus au titre des présentes Conditions Générales en cas de non-utilisation de l’ensemble des droits qui y sont concédés. </p><br/>
    <span class="tw-ml-6 tw-mr-4">12.3.</span>
    <span>Facture électronique</span>
    <br/><br/>
    <p class="tw-text-justify">Le Client reconnaît et accepte expressément que DBWO se réserve le droit de lui adresser sa facture ou son avoir au seul format électronique (PDF). </p><br/>
    <p class="tw-text-justify">Dans ce cas, les Parties conviennent que DBWO adressera selon la périodicité de facturation, à l’adresse électronique ou au numéro de téléphone communiqué par le Client, une notification de mise à disposition de sa facture ou avoir au format électronique (PDF) dans son espace client. Le Client pourra, depuis son espace client, demander la modification de l’adresse électronique ou du numéro de téléphone communiqué à DBWO. Le Client pourra également, sur simple demande écrite au service client de DBWO ou depuis son espace client, demander à bénéficier de l’envoi de sa facture au format papier. </p><br/>
    <p class="tw-text-justify">Le Client pourra accéder à sa facture ou son avoir en ligne depuis son espace client, après authentification, pendant un délai de treize (13) mois. Il sera libre de consulter sa facture ou son avoir, de la ou le télécharger au format PDF ou de l’imprimer depuis son espace client. Il appartient au Client de procéder à l’archivage de ses factures pendant la durée légale requise. Le Client reconnaît et accepte que les factures ou avoirs ne seront plus accessibles depuis son espace client au-delà de la période précitée. En cas de résiliation des Conditions Générales qu’elle qu’en soit la raison ou à l’échéance de celles-ci, le Client n’aura plus accès à son espace client. En conséquence, le Client reconnaît être informé qu’il lui appartient de télécharger préalablement à l’échéance des Conditions Générales l’ensemble de ses factures afin de procéder à leur archivage. </p><br/>
    <p class="tw-text-justify">Dans l’hypothèse où le Client n’a pas été en capacité de télécharger ses factures dans le délai qui lui était imparti, ce dernier pourra demander à DBWO, par écrit, l’envoi d’une copie au format papier des factures qui étaient stockées sur son espace client. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">13.</span>
      <span>Révision tarifaire </span>
    </p><br/>
    <p class="tw-text-justify">Le montant de la redevance pour les abonnements seront révisés annuellement à la date anniversaire du Devis par application de la formule suivante : </p><br/>
    <p class="tw-text-center">P1 = P0 x (1,02 + Y x (S1 / S0 -1)) </p><br/>
    <p class="tw-text-justify">Dans laquelle : </p><br/>
    <p class="tw-text-justify tw-ml-16">P1 = Montant de la redevance applicable pour l’année n </p>
    <p class="tw-text-justify tw-ml-16">P0 = Montant de la dernière redevance (prix public N-1 en vigueur hors toute remise exceptionnelle) </p>
    <p class="tw-text-justify tw-ml-16">S0 = Indice de référence pour l’année n – 2 </p>
    <p class="tw-text-justify tw-ml-16">S1 = Indice de référence pour l’année n – 1 </p>
    <p class="tw-text-justify tw-ml-16">Y = Valeur comprise entre 0 et 3. </p><br/>
    <p class="tw-text-justify">L’indice de référence est égal à la moyenne des Indices Syntec des mois de janvier, février et mars de l’année prise en compte. </p><br/>
    <p class="tw-text-justify">Le montant de la redevance de maintenance pourra être révisé par DBWO annuellement à date anniversaire par l’application du nouveau tarif en vigueur. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">14.</span>
      <span>Propriété et garantie de propriété intellectuelle </span>
    </p><br/>
    <span class="tw-ml-6 tw-mr-4">14.1.</span>
    <span>Propriété Intellectuelle </span>
    <br/><br/>
    <p class="tw-text-justify">DBWO garantit au Client qu'elle est titulaire des droits patrimoniaux sur l’application VIQTOR et sa Documentation et qu'elle peut en conséquence librement accorder au Client le droit d'Utilisation prévu aux présentes. </p><br/>
    <p class="tw-text-justify">La concession du droit d’utilisation de l’Application VIQTOR n’entraîne pas transfert des droits de propriété au profit du Client. L’application VIQTOR reste la propriété de DBWO, quel que soit la forme, le langage, le support du programme ou la langue utilisée. </p><br/>
    <p class="tw-text-justify">Le Client s’engage à ne pas porter atteinte directement ou indirectement par l’intermédiaire de tiers, aux droits de propriété intellectuelle de DBWO sur l’application VIQTOR. A ce titre, il maintiendra en bon état toutes les mentions de propriété et/ou de copyright qui seraient portées sur les éléments constitutifs de l’Application VIQTOR et de la Documentation. De même, il fera figurer ces mentions sur toute reproduction totale ou partielle qui serait autorisée par DBWO et notamment sur la copie de sauvegarde. </p><br/>
    <span class="tw-ml-6 tw-mr-4">14.2.</span>
    <span>Garantie en contrefaçon </span>
    <br/><br/>
    <p class="tw-text-justify">En cas de réclamation portant sur la contrefaçon par l’application VIQTOR d’un droit de propriété intellectuelle, DBWO pourra, à son choix et à ses frais, soit remplacer ou modifier la totalité ou une partie quelconque de l’Application VIQTOR, soit obtenir pour le Client un droit d’utilisation, pour autant que le Client ait respecté les conditions suivantes :</p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>Le Client doit avoir accepté et exécuté l'intégralité de ses obligations aux termes des présentes, </li>
      <li>Le Client doit avoir notifié à DBWO dans les meilleurs délais et par lettre recommandée avec avis de réception, l’action en contrefaçon ou la déclaration ayant précédé cette allégation, </li>
      <li>Le Client doit collaborer loyalement avec DBWO en lui fournissant tous les éléments, informations et assistance nécessaires afin de permettre à DBWO d’être en mesure d’assurer la défense de ses propres intérêts et de ceux du Client. </li>
    </ul><br/>
    <p class="tw-text-justify">Le Client s’interdit de transiger seul le litige avec le tiers alléguant d’une contrefaçon. Dans l’hypothèse de la conclusion d’une transaction dont le montant serait convenu entre DBWO et le tiers alléguant d’une contrefaçon, DBWO prendra à sa charge l’intégralité des montants à verser au tiers qui serait susceptible d’être mis à la charge du Client au titre de la transaction. </p><br/>
    <p class="tw-text-justify">A défaut pour DBWO d’avoir pu conclure la transaction susvisée, cette dernière assumera, sous son contrôle et sa direction, avec l’assistance du Client, la défense judiciaire à opposer à la demande du tiers alléguant d’une contrefaçon. Le Client s’interdit de conduire seul la défense judiciaire du litige diligenté contre lui par le tiers alléguant d’une contrefaçon et s’engage à ce titre à appeler sans délai DBWO en garantie. </p><br/>
    <p class="tw-text-justify">Pour le cas où la contestation du tiers alléguant d’une contrefaçon se conclurait par une décision de justice, ayant autorité de chose jugée au principal et passée en force de chose jugée, entrant en voie de condamnation pécuniaire à l’égard du Client, DBWO indemnisera ce dernier du montant de la condamnation à dommages-intérêts prononcée en principal et intérêts, sous réserve de la justification de l’encaissement par le tiers du montant de la condamnation versé par le Client. </p><br/>
    <p class="tw-text-justify">Dans le cas où DBWO ne peut raisonnablement modifier, remplacer ou obtenir pour le Client une licence d’utilisation sur l’application VIQTOR, les Parties pourront décider d’un commun accord de mettre fin aux Conditions Générales. DBWO remboursera alors au Client selon le Mode de commercialisation cent pour cent (100 %) du montant hors taxes des redevances acquittées par le Client au titre des douze (12) derniers mois précédents l’échéance des Conditions Générales résiliées d’un commun accord. </p><br/>
    <p class="tw-text-justify">Les dispositions du présent article définissent l'intégralité des obligations de DBWO en matière de contrefaçon de brevet et de droit d'auteur du fait de l’utilisation de l’Application VIQTOR. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">15.</span>
      <span>Lutte anti-corruption </span>
    </p><br/>
    <p class="tw-text-justify">Chacune des Parties s’engage à, et fera en sorte que les Parties liées à elle en fassent de même :</p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>Respecter toutes les lois, dispositions légales, règlements et codes applicables concernant la lutte contre la fraude et la corruption (les « Dispositions anti-fraude ») ; </li>
      <li>Ne commettre aucun fait susceptible d’enfreindre l’une des Dispositions anti-fraude ; </li>
      <li>S’abstenir de tout acte ou omission susceptible d’amener l’autre Partie à enfreindre des Dispositions anti-fraude ; </li>
      <li>Notifier dans les meilleurs délais l’autre Partie toute demande ayant pour objet un avantage financier ou tout autre avantage injustifié, reçue par elle à l’occasion des présentes ; </li>
      <li>Mettre en place et conserver pendant la durée des présentes leurs propres politiques et procédures pour garantir le respect des exigences applicables et les fassent appliquer le cas échéant. </li>
    </ul><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">16.</span>
      <span>Données personnelles </span>
    </p><br/>
    <p class="tw-text-justify">Les Parties s’engagent à respecter les dispositions de la règlementation en vigueur afférente à la protection des données à caractère personnel. </p><br/>
    <span class="tw-ml-6 tw-mr-4">16.1.</span>
    <span>Données personnelles traitées par le Client au moyen du Service </span>
    <br/><br/>
    <p class="tw-text-justify">Dans le cadre de l’utilisation de l’Application VIQTOR par le Client, ce dernier agit en qualité de responsable de traitement au sens de la loi du 6 janvier 1978 modifiée dite loi Informatique et Liberté. </p><br/>
    <p class="tw-text-justify">Au titre des présentes, DBWO agira en tant que sous-traitant du Client au sens des lois précitées et dans ce cadre s’engage à : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>Traiter les données personnelles auxquelles il pourrait avoir accès dans le cadre strict et nécessaire de l’exécution des Conditions Générales et d’une manière générale, en ce qui concerne lesdites données personnelles à n’agir que sur la seule instruction du Client ; </li>
      <li>Assurer la protection des données personnelles et traitement y afférant auxquels il aurait accès ; </li>
      <li>Prendre toutes précautions utiles afin de préserver la confidentialité et la sécurité des données personnelles auxquelles il a accès ou collectées à l’occasion des présentes et notamment d’empêcher qu’elles ne soient déformées, endommagées ou communiquées à des tiers non-autorisés. </li>
    </ul><br/>
    <p class="tw-text-justify">DBWO reconnaît qu’il mettra en œuvre les mesures de sécurité technique et d’organisation adaptées pour protéger les données et traitements auxquels il pourrait avoir accès. </p><br/>
    <p class="tw-text-justify">Par ailleurs, DBWO s’engage notamment à ne pas utiliser les données à caractère personnel à d’autres fins que celles prévues aux Conditions Générales. </p><br/>
    <p class="tw-text-justify">L’ensemble des engagements règlementaires et contractuels pris par DBWO font l’objet des documents « politique générale de protection des données » et « notre engagement de sous-traitant de vos données » accessible depuis l’onglet « RGPD » du site DBWO.</p><br/>
    <span class="tw-ml-6 tw-mr-4">16.2.</span>
    <span>Données personnelles du Client traitées par DBWO </span>
    <br/><br/>
    <p class="tw-text-justify">Dans le cadre de l’exécution des présentes, DBWO est amenée à collecter des données personnelles du Client en qualité de responsable de traitement au sens de la loi du 6 janvier 1978 modifiée dite loi Informatique et Liberté. </p><br/>
    <p class="tw-text-justify">Le Client est informé que DBWO met en œuvre un traitement de ses données à caractère personnel pour les finalités suivantes : effectuer les opérations relatives à la gestion des clients, des opérations relatives à la prospection, la réalisation d'opérations de sollicitations, l'élaboration de statistiques commerciales, la cession, la location ou l'échange de ses fichiers de clients et de ses fichiers de prospects, l’actualisation de ses fichiers de prospection, l'organisation de jeux concours, de loteries ou de toute opération promotionnelle, la gestion des demandes de droit d'accès, de rectification et d'opposition, la gestion des impayés et du contentieux. </p><br/>
    <p class="tw-text-justify">Les informations collectées et traitées par DBWO au titre des Conditions Générales, sont susceptibles d’être transférées aux sociétés du Groupe DBWO ainsi qu’à ses partenaires (fournisseurs et revendeurs). </p><br/>
    <p class="tw-text-justify">Le Client bénéficie d’un droit d’accès et de rectification aux informations le concernant qui peut être exercé par courrier électronique adressé à DPO@DBWO.com ou par courrier postal à l'attention de : Délégué à la Protection des Données, DBWO, 70 rue de Ponthieu, 75008 Paris, accompagné d’une copie d’un titre d’identité. </p><br/>
    <p class="tw-text-justify">DBWO s’engage à conserver les données ainsi collectées pour la durée strictement nécessaire à la réalisation de la finalité des traitements conformément aux dispositions légales en vigueur.</p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">17.</span>
      <span>Cession du contrat </span>
    </p><br/>
    <p class="tw-text-justify">Les Conditions Générales sont conclues intuitu personae. En conséquence, les droits du Client découlant des présentes ne peuvent être cédés, sous licenciés, vendus ou transférés de quelque manière par le Client, sauf accord préalable écrit de DBWO. Toute cession acceptée sera facturée au montant en vigueur chez DBWO au jour de la réception de la demande par DBWO. </p><br/>
    <p class="tw-text-justify">Nonobstant ce qui précède, DBWO sera libre de céder les Conditions Générales sans l’accord préalable du Client au profit de toute société du Groupe DBWO ou tout tiers de son choix. </p><br/>
    <p class="tw-text-justify">Dans l’hypothèse de la cession des Conditions Générales par l’une ou l’autre des Parties, celle-ci s’entend sans aucune solidarité, ce que reconnaissent expressément les Parties. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">18.</span>
      <span>Respect de la règlementation relative au droit social </span>
    </p><br/>
    <span class="tw-ml-6 tw-mr-4">18.1.</span>
    <span>Statut des intervenants </span>
    <br/><br/>
    <p class="tw-text-justify">Le personnel de DBWO reste en toutes circonstances sous son autorité hiérarchique et disciplinaire. </p><br/>
    <p class="tw-text-justify">DBWO garantit, en sa qualité d’employeur, la gestion administrative, comptable et sociale de ses salariés intervenant dans l’exécution des prestations prévues aux présentes. </p><br/>
    <p class="tw-text-justify">Si le personnel de DBWO est amené à intervenir dans les locaux du Client pour les besoins exclusifs de l’exécution des obligations de DBWO au titre des Conditions Générales, cette intervention doit être réalisée dans la plage horaire d’ouverture du site du Client. </p><br/>
    <p class="tw-text-justify">Les horaires de travail seront définis par DBWO, au sein notamment des contrats de travail qui le lient à son personnel. </p><br/>
    <p class="tw-text-justify">Par ailleurs, le personnel de DBWO respectera le règlement intérieur du Client qui définit les conditions d'accès, d'hygiène et de sécurité et qui devra lui être remis dès l’arrivée dans les locaux concernés. </p><br/>
    <p class="tw-text-justify">Sauf pour des raisons de sécurité et en cas d'urgence, le Client s'interdit de donner quelque instruction que ce soit à ce personnel, toute demande ou instruction devant être adressée à l’interlocuteur du Client désigné par DBWO. </p><br/>
    <span class="tw-ml-6 tw-mr-4">18.2.</span>
    <span>Travail dissimulé </span>
    <br/><br/>
    <p class="tw-text-justify">En application des articles L 8221-1, L 8221-3, L 8221-5, et conformément aux articles L 8222-1 et D 8222-5 du Code du travail, DBWO remettra au Client sur demande de sa part : </p><br/>
    <ul class="tw-list-disc tw-list-inside">
      <li>Une attestation de fourniture de déclarations sociales émanant de l’organisme de protection sociale chargé du recouvrement des cotisations sociales lui incombant ; </li>
      <li>Un extrait KBIS ; </li>
    </ul><br/>
    <p class="tw-text-justify">Tels qu’exigés par la législation relative à l’interdiction du travail dissimulé, DBWO certifie que ses salariés sont employés régulièrement au regard des articles L 3243-2 et L 1221-10, L 1221-13, L 1221-15 du Code du travail. </p><br/>
    <p class="tw-text-justify">Sur demande écrite du Client, DBWO pourra fournir la liste nominative des salariés de nationalité étrangère et soumis à une autorisation de travail qui interviendraient dans le cadre de l’exécution des Conditions Générales, conformément aux dispositions de l’article D 8254-2 du Code du travail. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">19.</span>
      <span>Non-sollicitation de personnel </span>
    </p><br/>
    <p class="tw-text-justify">Chacune des Parties s’engage à renoncer à engager ou faire travailler, directement ou par personne interposée, tout collaborateur de l’autre Partie en lien avec l’exécution des Conditions Générales, quelle que soit sa spécialisation et même si la sollicitation initiale est formulée par ledit collaborateur. </p><br/>
    <p class="tw-text-justify">Cette renonciation est valable pendant toute la durée des Conditions Générales et pendant une durée de douze (12) mois commençant à l'expiration de ces dernières quelle qu’en soit la raison. </p><br/>
    <p class="tw-text-justify">Dans le cas où l’une des Parties ne respecterait pas cette obligation, elle s'engage à dédommager l’autre Partie en lui versant une somme forfaitaire égale aux appointements bruts que ce collaborateur aura perçus pendant les douze (12) mois précédant son départ. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">20.</span>
      <span>Dispositions diverses </span>
    </p><br/>
    <span class="tw-ml-6 tw-mr-4">20.1.</span>
    <span>Confidentialité</span>
    <br/><br/>
    <p class="tw-text-justify">Les Parties pourront, en application des Conditions Générales, avoir accès à des informations confidentielles de l'autre Partie. </p><br/>
    <p class="tw-text-justify">Sont des informations confidentielles toutes informations ou données de nature technique, commerciale, financière ou autre, transmises entre les Parties incluant, sans limitation tous documents écrits ou imprimés, plans, tous échantillons, modèles, ou, plus généralement, tous moyens ou supports de divulgation. </p><br/>
    <p class="tw-text-justify">Ne sont pas des informations confidentielles celles qui, en l'absence de faute, se trouvent dans le domaine public ; celles dont la Partie réceptrice était en possession avant leur communication, sans les avoir reçues de l’autre Partie ; celles qui sont communiquées aux Parties par des tiers, sans condition de confidentialité ; celles que chaque Partie développe indépendamment des présentes. </p><br/>
    <p class="tw-text-justify">La Partie à qui une information confidentielle sera communiquée en préservera le caractère confidentiel avec un soin non inférieur à celui qu'elle apporte à la préservation de sa propre information confidentielle et ne pourra les communiquer ou les divulguer à des tiers, si ce n'est avec l'accord écrit et préalable de l’autre Partie ou dans la mesure éventuellement requise par la Loi. Les Parties conviennent d’adopter toutes mesures raisonnables pour s’assurer que les informations confidentielles ne soient pas communiquées à leurs employés ou contractants en violation des présentes. Les termes de cette obligation sont valables pendant toute la durée de validité des Conditions Générales et pendant une période de deux (2) ans à compter de l’expiration de ces dernières. </p><br/>
    <span class="tw-ml-6 tw-mr-4">20.2.</span>
    <span>Force Majeure </span>
    <br/><br/>
    <p class="tw-text-justify">Conformément aux dispositions de l’article 1218 du Code Civil, la réalisation des présentes Conditions Générales pourra être suspendue si l’une des Parties se trouve affectée par un cas de force majeure. En pareille hypothèse, la Partie affectée par le cas de force majeure devra notifier l’autre Partie de l’existence dudit cas de force majeure. Cette notification entrainera la suspension des présentes pendant le délai mentionné au courrier de notification, ce délai ne pouvant excéder un mois. </p><br/>
    <p class="tw-text-justify">Si à l’issue de ce délai, le cas de force majeure persiste, la Partie la plus diligente pourra notifier à l’autre Partie la résiliation des présentes. La résiliation prendra effet au jour de la notification. En toute hypothèse, aucune somme versée par le Client ne sera remboursée par DBWO. Au titre du présent article, les Parties écartent toute possibilité de résolution des Conditions Générales. </p><br/>
    <p class="tw-text-justify">Pour l’application du présent article, les Parties conviennent que sont considérés comme cas de force majeure ou de cas fortuits, outre ceux habituellement retenus par la jurisprudence des Tribunaux français, les cas suivants : grève totale ou partielle, blocage, perturbation ou encombrement des réseaux de télécommunication, mauvaise qualité du courant électrique, blocage des moyens de transport ou d’approvisionnement pour quelque raison que ce soit, intempéries, épidémies, tremblements de terre, incendies, tempêtes, inondations, dégâts des eaux, restrictions gouvernementales ou légales, guerre. </p><br/>
    <span class="tw-ml-6 tw-mr-4">20.3.</span>
    <span>Renonciation </span>
    <br/><br/>
    <p class="tw-text-justify">Le fait pour l'une des Parties de ne pas se prévaloir d’un droit qu’elle détient au titre des Conditions Générales ne saurait être interprété pour l'avenir comme une renonciation à ce droit. </p><br/>
    <p class="tw-text-justify">Le Client renonce irrévocablement à toute demande, réclamation, droit ou action à l’encontre de DBWO ayant trait à l’exécution des présentes et qui serait formulée plus de douze (12) mois après le fait générateur, et de ce fait, renonce irrévocablement à introduire toute action devant toute juridiction sur cette base à l’encontre de DBWO ou de l’une quelconque des sociétés du groupe auquel elle appartient. </p><br/>
    <span class="tw-ml-6 tw-mr-4">20.4.</span>
    <span>Références </span>
    <br/><br/>
    <p class="tw-text-justify">DBWO pourra faire état du nom du Client pour la promotion de son Application Viqtor. Ainsi, le Client accepte que DBWO puisse faire référence à son nom, sa dénomination sociale, un logo ou une marque déposée du Client sur tout support média y compris tous les sites internet DBWO et ce, dans le monde entier. Le Client peut cependant informer DBWO par tout moyen écrit et à tout moment de son refus et/ou demander le cas échéant le retrait de la référence susvisée. </p><br/>
    <p class="tw-border-b tw-border-black">
      <span class="tw-mr-6">21.</span>
      <span>Loi et attribution de compétence </span>
    </p><br/>
    <p class="tw-text-justify">Les présentes Conditions Générales sont soumises au droit français, à l’exclusion de toute autre législation. </p><br/>
    <p class="tw-text-justify">Toute difficulté relative à l’interprétation ou à l’exécution des présentes Conditions Générales relèvera de la compétence exclusive des tribunaux du ressort de la Cour d’Appel de Paris, quel que soit le lieu d’exécution des Conditions Générales, le domicile du défendeur ou le mode de règlement, même dans le cas d’un appel en garantie, d’une pluralité de défendeurs ou d’une procédure en référé.</p><br/>
  </div>
</template>

<script>
export default {
  name: "CGUComponent",
}
</script>

<style scoped>
</style>