<template>
  <div id="ecosystem-add-container">
    <div class="tw-flex tw-flex-col tw-align-start">
      <EntrepriseHeaderBlockTextComponent title="" />
    </div>
    <div>
      <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="3" />
    </div>
    <v-container class="px-12 overflow-auto">
      <v-row no-gutters>
        <v-col cols="12" class="mb-12 mt-4">
          <h1
            class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"
          >
            Vos invitations ont bien été envoyées !
          </h1>
          <h5 class="tw-tracking-tight tw-text-base caption">
            Félicitations ! Vos clients vont recevoir votre invitation très
            rapidement !
          </h5>
          <h5 class="tw-tracking-tight tw-text-base caption">
            Vous retrouverez leur score et leur statut dans votre liste de
            clients.
          </h5>
        </v-col>

        <v-col cols="12" class="mb-12 mt-4">
          <PrimaryButtonComponent
            class="return-btn"
            :style="{
              'background-color': `${referer.theme.ctaTextColor} !important`,
              color: `${referer.theme.ctaBgColor} !important`,
            }"
            :to="{ name: 'Company.ExpertClientDataBase' }"
            >Revenir au dashboard</PrimaryButtonComponent
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";

export default {
  components: {
    EntrepriseHeaderBlockTextComponent,
    PrimaryButtonComponent,
    StepsComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
};
</script>
