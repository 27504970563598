<template>
  <svg
    :class="className"
    viewBox="0 0 10 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.11111 6.75L0 0.5625L3.05556 3.375L5 0L6.94444 3.375L10 0.5625L8.88889 6.75H1.11111ZM8.88889 8.4375C8.88889 8.775 8.66667 9 8.33333 9H1.66667C1.33333 9 1.11111 8.775 1.11111 8.4375V7.875H8.88889V8.4375Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  name: "CrownIconComponent",
  props: {
    className: {
      type: String,
      default: "tw-h-5 tw-w-4",
    },
  },
};
</script>
