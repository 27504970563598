<template>
  <v-dialog 
    v-if="isVisible" 
    v-model="isVisible"
    @click:outside="$emit('close')" 
    width="600"
    transition="dialog-bottom-transition"
  >
    <div id="modifyDialog" class="tw-p-8">
      <h2 class="tw-font-bold tw-text-center tw-text-3xl">
        Modifier l'administrateur
      </h2>

      <hr class="tw-w-full tw-border-black" />

      <div class="tw-my-10">
        <text-field-component 
          class="register-activity-input" 
          label="" 
          placeholder="Email"
          v-model="form.email" 
          :error="errors.email.length > 0"
          :error-messages="errors.email"
        />
        <text-field-component 
          class="register-activity-input" 
          label="" 
          placeholder="Nom" 
          v-model="form.lastName"
          :error="errors.lastName.length > 0"
          :error-messages="errors.lastName"
        />
        <text-field-component 
          class="register-activity-input"
          label=""
          placeholder="Prénom"
          v-model="form.firstName"
          :error="errors.firstName.length > 0"
          :error-messages="errors.firstName"
        />
      </div>

      <div class="tw-flex tw-justify-around tw-w-full">
        <v-btn 
          elevation="3" 
          text large 
          @click="$emit('close')" 
          class="tw-text-gray-100 tw-cursor-pointer normal-btn"
        >ANNULER</v-btn>
        <v-btn 
          elevation="3"
          text large
          :loading="isLoading"
          @click="updateAdminHandler"
          class="tw-text-white tw-cursor-pointer"
        >MODIFIER</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { updateAdmin } from '@/features/company/services/appApi';

export default {
  name: "ProfileAdminModifyPopup",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      form: {
        email: "",
        firstName: "",
        lastName: "",
      },
      errors: {
        email: [],
        firstName: [],
        lastName: [],
      },
      isLoading: false,
    }
  },
  mounted() {
    this.form = { ...this.admin };
  },
  watch: {
    form: {
      handler() {
        this.errors = {
          email: [],
          firstName: [],
          lastName: [],
        }
      },
      deep: true,
    }
  },
  methods: {
    async updateAdminHandler() {
      this.isLoading = true;

      try {
        await updateAdmin({ ...this.form, id: this.admin.id })
        this.$snackbar.showAlertMessage({
          message: 'Modifié avec succès',
          type: "success",
        });
        this.$emit('close');
      }
      catch({ response }) { this.errors = response.data.errors; }
      finally { this.isLoading = false; }
    }
  }
}
</script>

<style lang="scss" scoped>
#modifyDialog {
  button {
    background-color: var(--cta-bg-color);
  }

  .normal-btn {
    background-color: transparent !important;
  }

  position: relative;

  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }

  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>