<template>
  <v-container id="LoginPage" class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <router-link :to="{ name: 'LoginPage' }">
        <RetourButtonComponent />
      </router-link>

      <TitleComponent class="public-title-auth tw-mt-4" text="Entrez sur" />
      <TitleComponent class="public-title-auth" text="votre espace." />

      <v-form ref="form" @submit.prevent="onSubmit" v-model="isFormValid" class="tw-mt-10">
        <PasswordFieldComponent
          class="tw-mt-6 sm:tw-w-full md:tw-w-1/3"
          v-model="form.password"
          :rules="rules.password"
          :error-messages="errors.password"
          label="Mot de passe"
        />
        <v-btn
          tile
          depressed
          @click="onPasswordForget"
          color="white"
          elevation="0"
          class="tw-tracking-normal tw-mt-4 pa-0 tw-normal-case text-transform-none"
        >
          <span class="public-text-login"
                :style="{
            color: `${theme.ctaBgColor}`,
          }"
            >Mot de passe oublié ?</span
          >
        </v-btn>
        <div class="tw-mt-10">
          <PrimaryButtonComponent :style="{
            backgroundColor: `${theme.ctaBgColor} !important`,
            color: `${theme.ctaTextColor}  !important`
          }" class="tw-rounded-lg" :loading="isSendingForm"
            :disabled="!isFormValid || isSendingForm" type="submit">Entrer</PrimaryButtonComponent>
        </div>
        <LoginSecondaryTextComponent class="public-text-login tw-mt-16">
          Pas encore de compte ?
          <router-link :to="{ name: platformTitle == 'Viqtor Expert' ? 'Auth.Registration.Expert.PreRegister' : 'signup' }" class="text-decoration-underline" :style="{
            color: `${theme.ctaBgColor}`,
          }">
            Inscrivez-vous
          </router-link>
        </LoginSecondaryTextComponent>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import TitleComponent from "@/components/common/TitleComponent";
import PasswordFieldComponent from "@/components/common/PasswordFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import LoginSecondaryTextComponent from "@/features/auth/components/LoginSecondaryTextComponent";
import { validationRules } from "@/utils/validation-rules";
import { singleAccountLogin } from "../../services/multiAuth.api";

export default {
  name: "MultiAccountPasswordLogin",
  components: {
    RetourButtonComponent,
    TitleComponent,
    PasswordFieldComponent,
    PrimaryButtonComponent,
    LoginSecondaryTextComponent,
  },
  data() {
    return {
      isFormValid: false,
      isSendingForm: false,
      rules: {
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
      },
      form: { password: "" },
      errors: { password: "" },
    }
  },
  computed: {
    theme() {
      return this.$store.state.referrer.theme
    },
    platformTitle() {
      return this.$store.state.referrer.title
    },
  },
  methods: {
    onPasswordForget() { this.$router.push({ name: "reset" }); },
    onSubmit() {
      this.isSendingForm = true;

      singleAccountLogin(this.$store.state.multiAuthEmail, this.form.password)
        .then(({ data }) => {
          this.isSendingForm = false;
          this.$router.push({
            name: "TwoFactorValidation",
            params: {
              token: data.data.secret,
              phone: data.data.phone,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: error?.response?.data?.message ||
              "Une erreur est survenue lors de la vérification de votre compte.",
            type: "error",
          });
        });
    },
  }
}
</script>