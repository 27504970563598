<template>
  <div class="!tw-pt-12">
    <template v-if="isLoading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-flex tw-items-center tw-justify-between">
        <EntrepriseHeaderBlockTextComponent title="Mon équipe" />
        <div class="tw-flex tw-space-x-8">
          <img @click="openAddCollaboratorPopupHandler" class="tw-cursor-pointer" src="@/assets/images/cta/add_collaborator_cta.svg" alt="logo" />
          <img @click="openImportCollaboratorsPopupHandler" class="tw-cursor-pointer" src="@/assets/images/cta/import_many_collaborators_cta.svg" alt="logo" />
        </div>
      </div>

      <div class="tw-w-full tw-overflow-auto filter tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-8">
        <SignupSecondaryTextComponent class="subheader tw-mb-6">
          La liste des collaborateurs de votre équipe d’Expert.
        </SignupSecondaryTextComponent>

        <v-simple-table height="600px" id="collaborateurs-table">
          <thead>
            <tr>
              <th>
                  <div @click="addToOrder('user.firstName')" class="tw-cursor-pointer tw-flex">
                    <span>Collaborateur</span>
                    <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='user.firstName' && orderType=='desc'" alt="">
                    <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='user.firstName' && orderType=='asc'" alt="">
                    <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                  </div>
              </th>
              <th>
                  <div @click="addToOrder('user.service')" class="tw-cursor-pointer tw-flex">
                    <span>Service</span>
                    <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='user.service' && orderType=='desc'" alt="">
                    <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='user.service' && orderType=='asc'" alt="">
                    <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                  </div>
              </th>
              <th>
                  <div @click="addToOrder('user.email')" class="tw-cursor-pointer tw-flex">
                    <span>Email</span>
                    <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='user.email' && orderType=='desc'" alt="">
                    <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='user.email' && orderType=='asc'" alt="">
                    <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                  </div>
              </th>
              <th>
                <div @click="addToOrder('expertCompaniesCount')" class="tw-cursor-pointer tw-flex tw-justify-center">
                  <span>Clients</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='expertCompaniesCount' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='expertCompaniesCount' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
              </th>
              <th>
                <div @click="addToOrder('expertTeamsCount')" class="tw-cursor-pointer tw-flex tw-justify-center">
                  <span>Equipe</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='expertTeamsCount' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='expertTeamsCount' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
              </th>
              <th>
                <div @click="addToOrder('user.isAccountActive')" class="tw-cursor-pointer tw-flex" style="padding-left: 1rem;">
                  <span>Statut</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='user.isAccountActive' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='user.isAccountActive' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
              </th>
              <th><span>Options</span></th>
            </tr>
          </thead>
          <tbody v-if="teamMembers.length > 0">
            <tr v-for="member in teamMembers" :key="member.id">
              <td :title="member.firstName + ' ' + member.lastName" class="limit-length">
                {{ 
                  (member.firstName + ' ' + member.lastName).length > 30 
                    ? (member.firstName + ' ' + member.lastName).substring(0,30) + '...' 
                    : (member.firstName + ' ' + member.lastName)
                }}
              </td>
              <td :title="member.service" class="limit-length">{{ member.service }}</td>
              <td :title="member.email" class="limit-length">{{ member.email }}</td>
              <td>
                <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
                  <v-tooltip right v-if="member.clientsCount > 0" content-class="tw-bg-white tw-border tw-border-black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tw-inline-flex tw-items-center tw-justify-center tw-space-x-2 tw-border tw-border-gray-900 tw-rounded-full tw-text-xs tw-font-medium tw-text-gray-900 tw-h-8 tw-w-8">
                        <span class="tw-block">{{ member.clientsCount }}</span>
                      </span>
                    </template>
                    <div class="tw-flex tw-flex-col tw-text-black tw-text-xs">
                      <span v-for="(tradeName, index) in member.clientsTradeNames" :key="index">{{ tradeName }}</span>
                    </div>
                  </v-tooltip>
                  <span v-if="!member.clientsCount" class="tw-block"> - </span>
                </div>
              </td>
              <td>
                <div class="tw-relative tw-flex tw-justify-center tw-col-span-2">
                <div class="tw-pl-4" v-if="member.teams.length == 0">-</div>
                <div v-if="member.teams.length == 1">
                  <BadgeComponent :label="member.teams[0].name">
                    <template #icon v-if="member.isLeader"><crown-icon/></template>
                  </BadgeComponent>
                </div>
                <div v-if="member.teams.length > 1">
                  <v-tooltip right content-class="tw-bg-white tw-border tw-border-black">
                    <template v-slot:activator="{ on, attrs }">
                      <span 
                        v-bind="attrs" v-on="on"
                        class="tw-text-white tw-bg-[#2A353A] tw-py-1 tw-px-6 tw-text-xs tw-font-medium tw-rounded-full"
                        style="width: 7rem; display: block;"
                      >{{ member.teams.length }} équipes</span>
                    </template>
                    <div class="tw-flex tw-flex-col tw-text-black tw-text-xs">
                      <span v-for="team in member.teams" :key="team.id">{{ team.name }}</span>
                    </div>
                  </v-tooltip>
                </div>
              </div>
              </td>
              <td v-html="handleStatusSheet(member)"></td>
              <td style="padding-left: 2rem;">
                <div class="tw-w-100 tw-flex tw-justify-center tw-items-center tooltip">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                  </svg>
                  <div class="tooltiptext">
                    <ul>
                      <li v-if="member.isAccountActive" class="tw-cursor-pointer" @click="viewMember(member)">Voir</li>
                      <li v-if="!member.isAccountActive" class="tw-cursor-pointer" @click="resendInvitation(member)">Relancer</li>
                      <li class="tw-cursor-pointer" @click="deleteClient(member)">Supprimer</li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center">Pas de données disponibles</td>
            </tr>
          </tbody>
          <tfoot class="text-center" v-if="length > 1">
            <tr>
              <td colspan="7">
                <v-pagination
                  :color="referer.theme.ctaBgColor"
                  v-model="page"
                  :length="length"
                  @input="pagination"
                ></v-pagination>
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </div>

      <TeamMemberPopup
        v-if="canOpenTeamMemberUpdatePopup"
        :openPopup="canOpenTeamMemberUpdatePopup"
        :item="selectedTeamMember"
        @close="canOpenTeamMemberUpdatePopup = false"
        @updated="pagination"
      />
      <TeamMemberConfirmDeletePopup
        v-if="canOpenTeamMemberConfirmDeletePopup"
        :openPopup="canOpenTeamMemberConfirmDeletePopup"
        :item="selectedTeamMember"
        @close="canOpenTeamMemberConfirmDeletePopup = false"
        @deleted="pagination"
      />
      <AddCollaboratorPopup
        v-if="canOpenAddCollaboratorPopup"
        :can-open="canOpenAddCollaboratorPopup"
        :previous-member="newTeamMembers[0]"
        @close="closeAddCollaboratorPopupHandler"
        @added="addedHandler"
      />
      <ImportCollaboratorsPopup
        v-if="canOpenImportCollaboratorsPopup"
        :can-open="canOpenImportCollaboratorsPopup"
        @close="closeImportCollaboratorsPopupHandler"
        @imported="importedHandler"
      />
      <InviteCollaboratorsMailConfirmationPopup 
        v-if="canOpenInviteCollaboratorsMailConfirmationPopup"
        :can-open="canOpenInviteCollaboratorsMailConfirmationPopup"
        :members="newTeamMembers"
        @close="closeInviteCollaboratorsMailConfirmationPopupHandler"
        @inviatationSent="invitationSentHandler"
      />
      <InviteCollaboratorsSuccessPopup 
        v-if="canOpenInviteCollaboratorsSuccessPopup"
        :can-open="canOpenInviteCollaboratorsSuccessPopup"
        :added-members-count="addedMembersCount"
        @close="closeInviteCollaboratorsSuccessPopupHandler"
      />
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import AddCollaboratorPopup from "@/features/company/components/Expert/AddCollaboratorPopup.vue";
import ImportCollaboratorsPopup from "@/features/company/components/Expert/ImportCollaboratorsPopup.vue";
import InviteCollaboratorsMailConfirmationPopup from "@/features/company/components/Expert/InviteCollaboratorsMailConfirmationPopup.vue";
import InviteCollaboratorsSuccessPopup from "@/features/company/components/Expert/InviteCollaboratorsSuccessPopup.vue";
import TeamMemberPopup from "@/features/company/components/Expert/TeamMemberPopup.vue";
import TeamMemberConfirmDeletePopup from "@/features/company/components/Expert/TeamMemberConfirmDeletePopup.vue";
import CrownIcon from '@/components/icons/CrownIcon.vue';
import { getExpertTeamMembers, resendInvitationForTeamMember } from "@/features/company/services/appApi";

export default {
  name: 'ExpertClientTeam',
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    AddCollaboratorPopup,
    ImportCollaboratorsPopup,
    InviteCollaboratorsMailConfirmationPopup,
    InviteCollaboratorsSuccessPopup,
    TeamMemberPopup,
    TeamMemberConfirmDeletePopup,
    CrownIcon
  },
  data() {
    return {
      isLoading: true,
      isResendingInvitation: false,
      page: 1,
      length: null,
      orderby: "",
      orderType: "",
      canOpenAddCollaboratorPopup: false,
      canOpenImportCollaboratorsPopup: false,
      canOpenInviteCollaboratorsMailConfirmationPopup: false,
      canOpenInviteCollaboratorsSuccessPopup: false,
      canOpenTeamMemberUpdatePopup: false,
      canOpenTeamMemberConfirmDeletePopup: false,
      lastPopupOpened: "",
      teamMembers: [],
      selectedTeamMember: {},
      newTeamMembers: [],
      addedMembersCount: 0,
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    this.pagination()
  },
  methods: {
    addToOrder(item) {
      if (this.orderby === item) {
        this.orderType = this.orderType === "asc" ? "desc" : "asc";
      } else {
        this.orderby = item;
        this.orderType = "asc";
      }
      this.pagination();
    },
    addedHandler(form) {
      this.newTeamMembers = [ form ];
      this.canOpenAddCollaboratorPopup = false;
      this.canOpenInviteCollaboratorsMailConfirmationPopup = true;
    },
    importedHandler(members) {
      this.newTeamMembers = [ ...members ];
      this.canOpenImportCollaboratorsPopup = false;
      this.canOpenInviteCollaboratorsMailConfirmationPopup = true;
    },
    closeAddCollaboratorPopupHandler() {
      this.canOpenAddCollaboratorPopup = false;
      this.newTeamMembers = [];
    },
    closeImportCollaboratorsPopupHandler() {
      this.canOpenImportCollaboratorsPopup = false;
      this.newTeamMembers = [];
    },
    openAddCollaboratorPopupHandler() {
      this.lastPopupOpened = "add-collaborator-popup"
      this.canOpenAddCollaboratorPopup = true;
    },
    openImportCollaboratorsPopupHandler() {
      this.lastPopupOpened = "import-collaborators-popup"
      this.canOpenImportCollaboratorsPopup = true;
    },
    closeInviteCollaboratorsMailConfirmationPopupHandler() {
      this.canOpenInviteCollaboratorsMailConfirmationPopup = false;
      if (this.lastPopupOpened === "add-collaborator-popup")
        this.canOpenAddCollaboratorPopup = true;
      if (this.lastPopupOpened === "import-collaborators-popup")
        this.canOpenImportCollaboratorsPopup = true;
    },
    invitationSentHandler(newMembersCount) {
      this.addedMembersCount = newMembersCount;
      this.newTeamMembers = [];
      this.canOpenInviteCollaboratorsMailConfirmationPopup = false;
      this.canOpenInviteCollaboratorsSuccessPopup = true;
    },
    closeInviteCollaboratorsSuccessPopupHandler() {
      this.canOpenInviteCollaboratorsSuccessPopup = false;
      this.pagination();
    },
    pagination() {
      getExpertTeamMembers(this.page, this.orderby, this.orderType)
        .then(({ data }) => {
          this.teamMembers = data.items.map(member => {
            return {
              id: member.id,
              firstName: member.firstName,
              lastName: member.lastName,
              service: member.user.service,
              email: member.email,
              clientsCount: member.expertCompanies.length,
              clientsTradeNames: member.expertCompanies.map(company => company.tradeName),
              isAccountActive: member.user.isAccountActive,
              phone: member.user.phone,
              isLeader: member.expertTeams.some(expertTeam => expertTeam.isLead),
              teams: member.expertTeams.map(({ team }) => ({ id: team.id, name: team.name })),
            }
          });
          this.page = data.meta.currentPage;
          this.length = data.meta.totalPages;
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la récupération des données.",
            type: "error",
          });
        })
        .finally(() => { this.isLoading = false; });
    },
    handleStatusSheet(item) {
      if (item.isAccountActive) {
        return `
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #27AE60; padding: 14px 0;">
                    Actif
                  </div>
                `;
      } else {
        return `
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E; padding: 14px 0;">
                    En cours
                  </div>
                `;
      }
    },
    viewMember(member) {
      this.selectedTeamMember = member;
      this.canOpenTeamMemberUpdatePopup = true;
    },
    deleteClient(member) {
      this.selectedTeamMember = member;
      this.canOpenTeamMemberConfirmDeletePopup = true;
    },
    resendInvitation(member) {
      if (member.isAccountActive) return;

      if (this.isResendingInvitation) {
        this.$snackbar.showAlertMessage({
          message: "Une invitation est déjà en cours d'envoi.",
          type: "error",
        });
        return;
      }

      this.isResendingInvitation = true;
      resendInvitationForTeamMember(member.id)
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "L'invitation a été renvoyée.",
            type: "success",
          });
          this.pagination();
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de l'envoi de l'invitation.",
            type: "error",
          });
        })
        .finally(() => { this.isResendingInvitation = false; });
    },
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: 22px;
  right: -36px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>