<template>
<div style="display: inline-block;">
    <input style="width: 100%;padding-left: 4px;height: 30px;" class="action_menu" v-bind="$attrs" :type="type"
    :value="value" 
    @input="updateValue($event.target.value)" 
    >
</div>
</template>

<script>
export default {
props:{
    type:{
        type:String,
        default:'text'
    },
    value:{
        type:String,
        default:''
    },
},
methods:{
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
}
}
</script>

<style>

</style>