<template>
  <h1 class="tw-text-3xl tw-font-bold tw-text-black-200">{{ text }}</h1>
</template>

<script>
export default {
  name: "TitleComponent",
  props: ["text"],
};
</script>

<style scoped></style>
