<template>
  <h2 v-bind="$attrs"><slot /></h2>
</template>

<script>
export default {
  name: "SignupSecondaryTextComponent",
  props: {
    text: String,
  },
};
</script>

<style scoped>
</style>
