<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
    <div><slot name="icon" /></div>
    <div
      :style="{
        'background-color': generateSpecialColor(label),
      }"
      class="tw-rounded-full tw-text-black tw-bg-gray-500 tw-flex tw-items-center tw-justify-center tw-ring-white"
      :class="className"
    >
      <v-tooltip
        v-if="!noTooltip"
        v-model="show"
        top
        content-class="tw-bg-white tw-border tw-border-black"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <span class="tw-text-white">{{ getShortAbreviation(label) }}</span>
          </v-btn>
        </template>
        <span class="tw-text-black">{{ label }}</span>
      </v-tooltip>

      <template v-else>
        <span class="tw-text-white">{{ getShortAbreviation(label) }}</span>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "AvatarComponent",
  props: {
    label: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "tw-h-8 tw-w-8",
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
