<template>
  <div id="collaborateurs">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Mes collaborateurs"
        class="tw-mb-2"
        description=""
      />
      <!-- <v-btn
        elevation="1"
        text
        rounded
        large
        @click="addCollaborateur()"
        class="tw-text-white"
      >
        Inviter
        <img src="@/assets/images/icons/add.svg" alt="logo" />
      </v-btn> -->
    </div>
    <CollaborateursTableComponent />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import CollaborateursTableComponent from "@/features/company/components/Collaborateurs/CollaborateursTableComponent";
export default {
  name: "MonEntrepriseCollaborateursPage",
  computed: {},
  components: {
    EntrepriseHeaderBlockTextComponent,
    CollaborateursTableComponent,
  },
  data() {
    return {

    };
  },
  methods: {
    addCollaborateur() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Collaborateurs.add",
      });
    },

  },
};
</script>
