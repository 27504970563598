<template>
  <div 
    id="offer-item-container" 
    class="tw-rounded-xl" 
    :style="{
      backgroundColor: selectedOfferId === offer.id 
        ? '#56CCF2' : `${referer.theme.ctaBgColor}`,
      opacity: isUpcoming ? '0.5' : '1',
    }"
    @click="$emit('click', offer.id)"
  >
    <div class="tw-p-2 offer-item-container-one">
      <p 
        v-if="offer.price === 0" 
        class="offer-item-number-salary-text"
        :style="{color: selectedOfferId !== offer.id ? '#FFFFFF' : `${referer.theme.ctaBgColor}`}"
      >
        Module <br /> sous-traitance
      </p>
      
      <p 
        v-if="offer.price !== 0"
        class="offer-item-number-salary-text"
        :style="{color: selectedOfferId !== offer.id ? '#FFFFFF' : `${referer.theme.ctaBgColor}`}"
      >
        {{ offer.plan.salaryRange }} <br /> salariés
      </p>
      
      <p 
        v-if="offer.price !== 0" 
        class="offer-item-number-subcontractor-text"
        :style="{color: selectedOfferId !== offer.id ? '#FFFFFF' : `${referer.theme.ctaBgColor}`}"
      >
        Jusqu’à {{ offer.plan.subContractorNumber }} sous-traitants
      </p>
      
      <img class="tw-mx-auto" :src="getImage(offer.plan.img)" :alt="offer.plan.img" />
      
      <div v-if="offer.price === 0">
        <p 
          class="tw-mt-4 offer-item-price-mounth-text"
          :style="{color: selectedOfferId !== offer.id ? '#FFFFFF' : `${referer.theme.ctaBgColor}`}"
        >
          Gratuit !
        </p>
        <p 
          class="offer-item-price-year-text"
          :style="{color: selectedOfferId !== offer.id ? '#FFFFFF' : `${referer.theme.ctaBgColor}`}"
        >
          {{ referer.title === 'GDPR assist' ? '  ' : 'Pour bénéficier du module gratuit vous devrez reseigner un moyen de paiement' }}
        </p>
      </div>

      <div v-if="offer.price !== 0">
        <p 
          class="tw-mt-4 offer-item-number-subcontractor-text"
          :style="{color: selectedOfferId !== offer.id ? '#FFFFFF' : `${referer.theme.ctaBgColor}`}"
        >
          Engagement {{ selectedEngagement === '24' ? '2 ans' : '1 an' }}
        </p>
      </div>
    </div>

    <div class="offer-item-with-container !tw-py-3 tw-mb-8 tw-rounded-lg">
      <p class="offer-item-annual-text">
        {{ selectedRegulation === 'monthly' ? 'Règlement mensuel' : 'Règlement en 1 fois' }} <br> prélevement annuel
      </p>
      <p class="offer-item-annual-text !tw-mb-0 !tw-text-2xl !tw-font-normal">
        {{ offer.price.toLocaleString('fr-FR') }} €
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileOfferItemComponent',
  props: {
    offer: {
      type: Object,
      required: true,
    },
    selectedOfferId: {
      type: Number,
      default: 0,
    },
    selectedRegulation: {
      type: String,
      default: '',
    },
    selectedEngagement: {
      type: String,
      default: '',
    },
    isUpcoming: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    getImage(img) {
      return require('@/assets/images/salaries/' + img);
    }
  }
}
</script>