<template>
    <v-container class="tw-pt-0 tw-mt-0">
      <div class="auth-container tw-mt-10 tw-min-h-screen">
        <StepsComponent :step="1" />
        <TitleComponent class="tw-mt-8 public-title-auth" text="Inscription impossible !" />
        <div v-if="errorType === 'siren'">
          <ErrorSecondaryTextComponent class="public-text-register tw-mt-1">
            Le numéro de SIREN que vous avez renseigné existe déjà dans notre base de données.
          </ErrorSecondaryTextComponent>
        </div>
        <div v-if="errorType === 'email'">
          <ErrorSecondaryTextComponent class="public-text-register">
            L’email que vous avez renseigné existe déjà dans notre base de données et est associé au SIREN d’une autre entreprise.
          </ErrorSecondaryTextComponent>
          <ErrorSecondaryTextComponent class="public-text-register">
            Vous êtes gérant de plusieurs entreprises ? Contactez nous !
          </ErrorSecondaryTextComponent>
        </div>

        <div class="tw-w-full tw-flex flex-row justify-center">
          <img src="../../../assets/images/vector.svg" width="22%" alt="email or siren invalid" />
        </div>
      </div>
    </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import ErrorSecondaryTextComponent from "@/features/404/components/ErrorSecondaryTextComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";

export default {
  name: "DoubleEmailOrSiren",
  props: {
    errorType : String,
  },
  data() {
    return {
      email: 'bdu***@veniseactivation.com',
    }
  },
  components: {
    StepsComponent,
    ErrorSecondaryTextComponent, TitleComponent
  },

  methods: {
  }
}
</script>

<style scoped>
.text-error-siren-email {
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
}
</style>