<template>
  <div :style="{ 'background-color': referer.theme.bgColor1 }" id="loading11">
    <footer>
      <v-container>
        <hr class="inner-containner tw-mx-auto" />
        <div class="inner-containner tw-mx-auto tw-flex tw-items-baseline tw-justify-between">
          <span class="tw-text-white">© 2021, {{referer.title}}.</span>
          <span class="tw-text-white">Conception et réalisation <a style="margin: 0;" href="http://veniseactivation.com" target="_blank" rel="noopener noreferrer">Venise Activation.</a></span>
          <span class="tw-text-white"><a href="#landing">Revenir en haut</a></span>
        </div>
      </v-container>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
};
</script>

<style scoped></style>
