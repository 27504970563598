<template>
  <v-container class="overflow-auto">
    <Breadcrumb divider=">"></Breadcrumb>

    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <v-row no-gutters class="mb-4 mt-4">
        <v-col cols="12">
          <h1
            class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"
          >
            {{ subcontractor ? subcontractor.socialReason : "" }}
          </h1>
        </v-col>

        <v-col md="4" cols="12" class="">
          <div
            class="d-flex flex-column elevation-1 justify-center align-center mx-auto my-4 score-container"
            :class="getScoreBorderColor(scoreTotal)"
          >
            <p class="font-weight-bold description">Score de conformité :</p>
            <p :class="getScoreColor(scoreTotal)">{{ score }} / 100</p>
          </div>

          <form action="" class="d-flex flex-column">
            <div class="form-field my-4">
              <TextFieldComponent
                class="profile-form-input"
                v-model="form.statut"
                label="Statut"
                readonly
              />
            </div>

            <div class="form-field my-4">
              <TextFieldComponent
                class="profile-form-input"
                v-model="form.numero_siren"
                label="Numéro SIREN"
                readonly
              />
            </div>

            <div class="form-field my-4">
              <TextFieldComponent
                class="profile-form-input"
                v-model="form.contact"
                label="Contact"
                readonly
              />
            </div>

            <div class="form-field my-4">
              <TextFieldComponent
                class="profile-form-input"
                v-model="form.e_mail"
                label="E-mail"
                readonly
              />
            </div>

            <div class="form-field my-4">
              <TextFieldComponent
                class="profile-form-input"
                v-model="form.date_evaluation"
                label="Date d’évaluation"
                readonly
              />
            </div>
          </form>
        </v-col>

        <v-col md="1" cols="12" class="d-flex align-center justify-center">
          <v-divider vertical></v-divider>
        </v-col>

        <v-col md="7" cols="12" class="">
          <div class="elevation-1 px-4 score-details-container">
            <v-col cols="12">
              <h1
                class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 text-center"
              >
                Score en détails
              </h1>
            </v-col>

            <v-simple-table>
              <template v-slot:default>
                <thead class="header-table">
                  <tr>
                    <th>Chapitre</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in table_data" :key="item.name">
                    <td class="tw-pb-5 tw-pt-5">{{ item.chapitre }}</td>
                    <td>
                      <span
                        class="tw-whitespace-nowrap"
                        :class="getBadge(item.status)"
                        >{{ item.score }} / 100</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="tw-pb-5 tw-pt-5">Total</td>
                    <td>
                      <span class="tw-whitespace-nowrap" :class="getLgBadge()">
                        {{ score }} / 100
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div class="d-flex justify-center tw-py-2">
              <PrimaryButtonComponent
                  class="profil-btn2"
                  :style="{
                  backgroundColor: `${referer.theme.ctaBgColor} !important`,
                  color: `${referer.theme.ctaTextColor} !important` }"
                  @click="voirDetail"
                >Voir le détail</PrimaryButtonComponent
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" cols="12">
          <div>
            <PrimaryButtonComponent @click="go_back" class="profil-btn"
              >Retour</PrimaryButtonComponent
            >
          </div>
        </v-col>
        <v-col md="6" cols="12">
          <div class="d-none lastbtn">
            <PrimaryButtonComponent class="profil-btn3"
              >Supprimer</PrimaryButtonComponent
            >
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import { getQuizSubContractors } from "@/features/subcontractor/services/appApi";
import moment from "moment";

export default {
  name: "SubContractorProfile",
  mounted() {
    if (!this.subContractor) {
      this.$router.push({ name: "SubContractor.EvaluatedSubContractors" });
    } else {
      this.fetchData();
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },

  data() {
    return {
      form: {
        statut: "Évalué",
        numero_siren: "",
        contact: "",
        e_mail: "",
        date_evaluation: "",
      },
      scoreTotal: "78.00",
      score: null,
      table_data: [],
      loading: false,
      user: {},
      subcontractor: null,
    };
  },

  components: {
    Breadcrumb,
    PrimaryButtonComponent,
    TextFieldComponent,
  },

  props: ["subContractor"],

  methods: {
    getBadge(score) {
      if (score == "A") return "victor-badge-green";
      else if (score == "B") return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getLgBadge() {
      if (this.scoreTotal == "OK_GO") return "victor-lg-badge-green";
      else if (this.scoreTotal == "GO_SR_A") return "victor-lg-badge-yellow";
      else if (this.scoreTotal == "GO_SR_B") return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    getScoreColor() {
      if (this.scoreTotal == "OK_GO") return "score-green";
      else if (this.scoreTotal == "GO_SR_A") return "score-yellow";
      else if (this.scoreTotal == "GO_SR_B") return "score-yellow";
      else return "score-red";
    },
    getScoreBorderColor() {
      if (this.scoreTotal == "OK_GO") return "score-container-green";
      else if (this.scoreTotal == "GO_SR_A") return "score-container-yellow";
      else if (this.scoreTotal == "GO_SR_B") return "score-container-yellow";
      else return "score-container-red";
    },
    fetchData() {
      this.loading = true;
      getQuizSubContractors(this.subContractor)
        .then((res) => {
          let siren = res.data.data.subcontractor.sirenOrRna.match(/.{1,3}/g);
          siren.forEach((str) => {
            this.form.numero_siren = this.form.numero_siren + str + " ";
          });
          this.form.contact =
            res.data.data.subcontractor.user.firstName +
            " " +
            res.data.data.subcontractor.user.lastName;
          this.form.e_mail = res.data.data.subcontractor.user.email;
          this.form.date_evaluation = moment(
            res.data.data.subcontractor.quiz.createdAt
          ).format("DD/MM/YYYY");
          const sortedQuizChapters = res.data.data.subcontractor.quiz.quizChapters.sort(
            (a, b) => a.chapter.id - b.chapter.id
          );
          sortedQuizChapters.forEach((item, key) => {
            this.table_data.push({
              chapitre: key + 1 + ". " + item.chapter.label,
              score: item.scorePercent,
              status: item.status,
            });
          });
          this.scoreTotal = res.data.data.subcontractor.quiz.status;
          this.score =
            res.data.data.subcontractor.quiz.scorePercent !== null
              ? res.data.data.subcontractor.quiz.scorePercent.toFixed()
              : 0;
          this.subcontractor = res.data.data.subcontractor;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voirDetail() {
      this.$router.push({
        name: "SubContractorProfileQuiz",
        params: { subContractor: this.subContractor },
      });
    },
    go_back() {
      this.$router.push({ name: "SubContractor.EvaluatedSubContractors" });
    },
  },
};
</script>

<style lang="scss">
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.header-table {
  background: var(--cta-bg-color);
  margin: 0 auto;
  width: 80%;
  border-radius: 10px !important;

  tr {
    border-radius: 10px !important;
    th {
      color: white !important;
      padding: 8px 12px !important;
      height: auto !important;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      border: none;
    }

    th:first-child {
      color: white !important;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      text-align: left;
      padding-left: 60px !important;
    }
    th:last-child {
      color: white !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
      }
    }
  }
}

.profil-btn {
  background: var(--cta-bg-color) !important;
  height: 45px !important;
  padding: 0 !important;
  font-size: 12px !important;
  min-width: 100px !important;
  width: 130px !important;
  margin: 0 12px;
  font-weight: normal !important;
  color: #fff !important;
}
.profil-btn2 {
  background: var(--cta-bg-color) !important;
  height: 45px !important;
  padding: 0 !important;
  font-size: 12px !important;
  min-width: 100px !important;
  width: 130px !important;
  font-weight: normal !important;
  color: #fff !important;
  border-radius: 12px !important;
}

.profil-btn3 {
  height: 45px !important;
  padding: 0 !important;
  font-size: 12px !important;
  min-width: 100px !important;
  width: 130px !important;
  font-weight: normal !important;
  color: var(--cta-bg-color) !important;
  background-color: #fff !important;
  border: 1px solid var(--cta-bg-color) !important;
}

.score-container-green {
  border: 1px solid #558c66;
  width: 90%;
  border-radius: 15px;
  padding: 20px;
}

.score-container-yellow {
  border: 1px solid #d49725;
  width: 90%;
  border-radius: 15px;
  padding: 20px;
}

.score-container-red {
  border: 1px solid #bb454c;
  width: 90%;
  border-radius: 15px;
  padding: 20px;
}

.score-container {
  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    margin: 14px auto;
  }

  .score-green {
    color: #558c66;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
  }
  .score-yellow {
    color: #d49725;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
  }
  .score-red {
    color: #bb454c;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
  }
}

.profile-form-input {
  label {
    font-size: 10px !important;
    top: 12px !important;

    &.v-label--active {
      transform: translateY(-35px) scale(1) !important;
    }
  }

  input {
    font-size: 12px;
    line-height: 26px !important;
    background: #fff !important;
    border-radius: 15px !important;
    border: 2px solid var(--cta-bg-color);
    height: 45px !important;
  }
}

.score-details-container {
  border-radius: 15px;
}

.lastbtn {
  justify-content: flex-end;
}
</style>
