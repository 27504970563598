<template>
  <v-dialog
    v-if="isVisible"
    v-model="isVisible"
    @click:outside="$emit('close')"
    width="600"
    transition="dialog-bottom-transition"
  >
    <div id="deleteDialog" class="tw-p-8">
      <h2 class="tw-font-bold tw-mb-6">
        êtes vous sur de vouloir supprimer
        <span :style="{ color: referer.theme.ctaBgColor }">{{ admin.firstName }} {{ admin.lastName }}</span> 
        ?
      </h2>
      <hr class="tw-w-full tw-border-black"/>
      <p>
        Vous vous appretez à supprimer les données associées à ce compte.
        Veuillez annuler si cette action n’est pas volontaire, sinon cliquez sur Supprimer
      </p>
      <div class="tw-flex tw-justify-around tw-w-full">
          <v-btn
            elevation="3"
            text
            large
            @click="$emit('close')"
            class="tw-text-gray-100 tw-cursor-pointer normal-btn"
          >
            ANNULER
          </v-btn>
          <v-btn
            elevation="3"
            text
            large
            :loading="isLoading"
            @click="deleteAdminHandler"
            class="tw-text-white tw-cursor-pointer"
          >
            Supprimer
          </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { deleteAdmin } from '@/features/company/services/appApi';

export default {
  name: "ProfileAdminConfirmDeletePopup",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    async deleteAdminHandler() {
      this.isLoading = true;

      try {
        await deleteAdmin({ id: this.admin.id });
        this.$snackbar.showAlertMessage({
          message: 'Administrateur supprimé avec succès',
          type: "success",
        });
        this.$emit('close');
      }
      catch (error) {
        this.$snackbar.showAlertMessage({
          message: "Une erreur s'est produite lors de la suppression de l'administrateur",
          type: "error",
        });
      }
      finally { this.isLoading = false; }
    }
  }
}
</script>

<style lang="scss" scoped>
  #deleteDialog{
    position: relative;
    img {
      height: 40px;
      width: 40px;
      margin: 20px auto 20px;
    }
    .img-close {
      height: 10px;
      width: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
</style>