<template>
<div class="tw-flex tw-justify-center tw-items-center" style="width: 100%;">
    <div
    class="tw-w-2 tw-h-2 tw-rounded-full tw-flex tw-justify-center tw-items-center" :class="colorChange"
    >
    </div>
</div>
</template>

<script>
export default {
    props:['color','items'],
    computed:{
        colorChange(){
        if(this.items.elearning && this.items.elearning.elearningScore){
                if(this.items.elearning.elearningScore<30)
                return "not-viewed"
                else if(this.items.elearning.elearningScore<75)
                return "viewed" 
                else
                return "confirmed"
                
            }
        else{
                 return "desactivated"
            }
        },
    },
    mounted(){
       
    }

}
</script>

<style scoped>
.confirmed{
background-color: #27AE60;
}
.not-viewed{
background-color: #EB5757;
}
.viewed{
 background-color: #F2C94C;
}
.desactivated{
 background-color: #0202;
}
</style>
