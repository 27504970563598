<template>
  <div class="overflow-auto">
    <v-container class="px-12">
      <Breadcrumb divider=">"></Breadcrumb>

      <v-row no-gutters class="mb-12 mt-4">
        <v-col cols="12">
          <h1 class="tw-tracking-tight tw-text-xl tw-font-semibold tw-mt-4">
            Ajouter un ou plusieurs sous-traitants
          </h1>
          <h2 class="tw-mt-4 tw-font-semibold">
            Renseigner les informations d'un sous traitant manuellement :
          </h2>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="my-4" md="4" cols="12">
          <SelectBoxComponent
            class="add-subcontractor-select"
            v-model="form.gender"
            :items="genderItems"
            label="Civilité"
          />
        </v-col>

        <v-col class="my-4" md="4" cols="12">
          <TextFieldComponent
            class="add-subcontractor-input"
            v-model="form.firstName"
            label="Prénom"
          />
        </v-col>

        <v-col class="my-4" md="4" cols="12">
          <TextFieldComponent
            class="add-subcontractor-input"
            v-model="form.lastName"
            label="Nom"
          />
        </v-col>

        <v-col class="my-4" md="4" cols="12">
          <TextFieldComponent
            class="add-subcontractor-input"
            v-model="form.email"
            label="Adresse e-mail"
          />
        </v-col>

        <v-col class="my-4" md="4" cols="12">
          <TextFieldComponent
            class="add-subcontractor-input"
            v-model="form.sirenOrRna"
            label="SIREN / RNA"
          />
        </v-col>

        <v-col class="my-4" md="4" cols="12">
          <TextFieldComponent
            class="add-subcontractor-input"
            v-model="form.socialReason"
            label="Raison Sociale"
          />
        </v-col>

        <v-col class="my-4 d-flex justify-end" cols="12">
          <PrimaryButtonComponent
              :style="{
                backgroundColor: `${referer.theme.ctaBgColor} !important`,
                color: `${referer.theme.ctaTextColor} !important` }"
              class="export-btn" @click="submit"
            >Continuer
          </PrimaryButtonComponent>
        </v-col>

        <hr />
        <v-col cols="12">
          <h1 class="tw-mt-4 tw-font-bold">
            Importer une liste de plusieurs sous-traitants en 2 étapes :
          </h1>
        </v-col>
        <v-col md="6" cols="12">
          <v-row no-gutters>
            <v-col cols="12" class="mb-12 mt-4">
              <h1
                class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"
              >
                1. Téléchargez d’abord le modèle
              </h1>
              <h5 style="font-size: 12px">
                Pour importer facilement la liste de vos sous-traitants,
                téléchargez ce modèle.
              </h5>
            </v-col>

            <v-col cols="12" class="px-0 mb-12 mt-4">
              <PrimaryButtonComponent
                :style="{
                  borderColor: `${referer.theme.ctaBgColor} !important`,
                  backgroundColor: `${referer.theme.ctaBgColor} !important`,
                  color: `${referer.theme.ctaTextColor} !important`
                }"
                outlined
                class="download-model-btn"
                @click="downloadCSVData"
              >Télécharger le modèle
                <v-icon right dark color="white" class="tw-mt-1"> mdi-download </v-icon>
              </PrimaryButtonComponent>
            </v-col>

            <v-col cols="12" class="px-0 mb-12 mt-4">
              <p style="font-size: 11px">
                Remplissez le fichier sans modifier l’ordre des colonnes.<br /><br />
                Ce fichier est un Excel .csv comprenant 5 colonnes, dans l’ordre
                : le numéro de SIREN, la civilité, le prénom et le nom du
                contact ainsi que son adresse email.<br /><br />
                <strong>
                  Vous pouvez importer jusqu’à 50 sous-traitants par
                  fichier.</strong
                >
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col md="6" cols="12">
          <v-row no-gutters>
            <v-col cols="12" class="mb-12 mt-4">
              <h1
                class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"
              >
                2. Importez votre liste de sous-traitants
              </h1>
              <h5 style="font-size: 12px">
                Une fois le fichier rempli, importez-le ici.
              </h5>
            </v-col>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :useCustomSlot="true"
              :options="dropzoneOptions"
              class="upload-file"
              :includeStyling="false"
              @vdropzone-removed-file="fileRemoved"
              @vdropzone-file-added="fileAdded"
            >
              <div
                class="dropzone-custom-content tw-flex tw-items-center tw-flex-col"
              >
                <img src="@/assets/images/icons/upload.svg" />
                Glissez et déposez ici votre liste de sous-traitants
                <v-btn
                  elevation="1"
                  text
                  rounded
                  large
                  class="tw-text-white"
                >
                  Parcourir
                </v-btn>
              </div>
            </vue-dropzone>
            <v-col class="px-14 mb-12 mt-4 d-flex justify-end" cols="12">
              <PrimaryButtonComponent
                  :style="{
                  backgroundColor: `${referer.theme.ctaBgColor} !important`,
                  color: `${referer.theme.ctaTextColor} !important` }"
                class="export-btn"
                @click="import_file"
                :loading="loading"
                :disabled="!file"
                >Continuer
              </PrimaryButtonComponent>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import SelectBoxComponent from "@/components/common/SelectBoxComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "AddSubContractor",
  data() {
    return {
      genderItems: [
        { label: "Madame", value: "male" },
        { label: "Monsieur", value: "female" },
      ],

      form: {
        gender: "",
        firstName: "",
        lastName: "",
        email: "",
        sirenOrRna: "",
        socialReason: "",
      },
      file: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
        maxFiles: 1,
        autoQueue: false,
        uploadMultiple: 1,
        dictRemoveFile: `<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
      },
      loading: false,
    };
  },
  components: {
    Breadcrumb,
    PrimaryButtonComponent,
    SelectBoxComponent,
    TextFieldComponent,
    vueDropzone: vue2Dropzone,
  },
  watch: {
    'form.sirenOrRna': function (newVal) {
      this.form.sirenOrRna = newVal.replaceAll(' ', '');
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    validForm() {
      return !(this.form.gender === "" ||
          this.form.email === "" ||
          this.form.firstName === "" ||
          this.form.lastName === "" ||
          this.form.sirenOrRna === "" ||
          this.form.socialReason === "");
    },
  },

  methods: {
    afterComplete(file) {
      this.file = file;
    },

    fileRemoved() {
      this.file = null;
    },

    fileAdded(file) {
      if (this.file) {
        this.$refs.myVueDropzone.removeFile(this.file);
      }
      this.file = file;
    },

    submit() {
      if (!this.validForm) {
        this.$snackbar.showAlertMessage({
          message: "veuillez remplir tous les champs",
          type: "error",
        });
      } else {
        this.$store.dispatch("importOneSubcontractor", this.form);
        this.$router.push({
          name: "SubContractor.SubContractorInvitation",
        });
      }
    },
    downloadCSVData() {
      var str = "SIREN;GENRE;PRENOM;NOM DE FAMILLE;EMAIL;RAISON SOCIALE";
      var blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "viqtor-sous-traitant-model.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    import_file() {
      let formData = new FormData();
      formData.append("file", this.file);

      this.$store.dispatch("loadSubcontractorValid", []);
      this.$store.dispatch("loadSubcontractorInvalid", []);

      this.$router.push({
        name: "SubContractor.SubContractorImport",
        params: {
          formData,
        },
      });
    },
  },
};
</script>

<style lang="scss">
$primary-color: var(--cta-bg-color) !important;
$primary-dark-color: #2c2560 !important;
$black-color: #25282b !important;
$white-color: #ffffff !important;
$input-disabled-bg: #f4f4f4;

/*
    * Responsive
    */
$breakpoint-laptop: 1023px;
$breakpoint-tablet: 768px;

$small: 300px;
$tablet: 768px;
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.add-subcontractor-select {
  height: 45px;
  margin-right: 12px !important;
  padding: 0 !important;

  .label {
    font-size: 10px !important;
    height: 20px;
    line-height: 20px;
  }

  .v-input__control,
  .v-input__slot {
    height: 45px;

    .v-select__slot {
      padding-top: 0;
      padding-bottom: 0;
      height: 45px;

      font-size: 12px;
      line-height: 26px !important;
      background: #fff !important;
      border-radius: 15px !important;
      border: 2px solid $primary-color;
      height: 45px !important;

      .v-label {
        top: 12px;
      }

      .v-input__append-inner {
        margin-top: 8px !important;
      }
    }
  }
}

.add-subcontractor-input {
  height: 45px;
  margin-right: 12px !important;

  .v-input__control {
    height: 45px;
  }

  label {
    font-size: 10px !important;
    top: 12px !important;

    &.v-label--active {
      transform: translateY(-35px) scale(1) !important;
    }
  }

  // input {
  // 	font-size: 12px;
  // 	line-height: 26px !important;
  // 	background: #fff !important;
  // 	border-radius: 15px !important;
  // 	border: 2px solid #6c5dd3;
  // 	height: 45px !important;
  // }
  label {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }

  .theme--light.v-label {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    padding-bottom: 15px;
  }

  input,
  select {
    border-radius: 11px;
    height: 40px !important;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    //border: 2px solid #6c5dd3;
    box-shadow: none;
    border-radius: 11px;

    &:disabled {
      background-color: $input-disabled-bg;
      border: none;
    }

    @media (max-width: $breakpoint-laptop) {
      width: 100%;
      max-width: 450px;
    }
  }

  .v-select {
    font-family: "Poppins";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    padding-top: 15px;
  }
}

.download-model-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: auto !important;
  margin: 0 12px;
  background: #fff !important;
  color: $primary-color !important;
  border: 2px solid $primary-color !important;
}

.upload-container {
  border-radius: 10px;
  border: 2px dashed #000;
  padding: 0 !important;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  .vue-dropzone {
    border: none !important;
  }

  .v-icon {
    color: $primary-color !important;
    font-size: 45px !important;
  }

  > p {
    font-size: 16px;
  }

  .import-btn {
    height: 45px !important;
    font-weight: bold !important;
    font-size: 12px !important;
    background: #fff !important;
    color: #000 !important;
    border: 2px solid #000 !important;
    width: 100px !important;
    margin: 0 auto;
  }
}

label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.upload-file {
  height: auto;
  width: 88%;
  padding: 8%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  border: 2px dashed #25282b;
  margin: 15px auto 40px;
  font-size: 13px;

  button {
    border: 2px solid $black-color;
    background-color: white !important;
    border-radius: 20px;
    width: 109px;
    height: 40px;
    float: right;
    margin-top: 10px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    text-transform: capitalize;
    color: $black-color;
  }

  .dz-image {
    display: none !important;
  }
}
</style>
