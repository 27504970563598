<template>
  <div id="ecosystem-add-container">
    <div
        class="tw-flex tw-flex-col tw-align-start"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Cartographie de l’écosystème de la donnée<br> personnelle de votre entreprise"
      />
    </div>
    <div>
      <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="2" />
    </div>
    <div class="collaborator-form-container">
      <SignupSecondaryTextComponent class="public-text-register">
        <b>La liste des collaborateurs de l’entreprise.</b>
      </SignupSecondaryTextComponent>
      <div class="collaborators-row">
        <v-row>
          <v-col class="my-4" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="table-collaborator-th md:tw-w-3/12">Collaborateurs</th>
                  <th class="table-collaborator-th md:tw-w-2/12">Fonction</th>
                  <th class="table-collaborator-th md:tw-w-5/12">Email</th>
                  <th class="table-collaborator-th md:tw-w-2/12" style="text-align: center">Supprimer</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in collaboratorsValid" :key="item.$key">
                  <td class="table-collaborator-td">{{ item.firstName }} {{ item.lastName }}</td>
                  <td class="table-collaborator-td">{{ item.function }}</td>
                  <td class="table-collaborator-td">
                    {{ item.email }}
                  </td>
                  <td
                      class="tw-cursor-pointer table-collaborator-td tw-text-center"
                  >
                    <v-icon dark small color="black" @click="supprimerCollaboratorValid(item)">mdi-close</v-icon>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
      <div class="collaborators-row tw-items-center tw-justify-center tw-space-x-12">
        <PrimaryButtonComponent class="collaborator-add-return-btn tw-mt-6" @click="back"
        >Précédent</PrimaryButtonComponent
        >
        <PrimaryButtonComponent class="collaborator-add-validate-btn tw-mt-6" @click="submit"
        >Suivant</PrimaryButtonComponent
        >
      </div>
    </div>
    <CollaboratorsLoaderModal
        :count-all="countTotalCollaborators"
        :count-finished="countFinishedCollaborators"
        :action="action"
        v-model="importCollaboratorsModal"
        :error="importationError"
        :is-loading="subscribing"
        :log="log"
        :on-close="onCloseImportModal"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import CollaboratorsLoaderModal from "@/features/company/components/Collaborateurs/CollaboratorsLoaderModal";
import {
  bindEventPusher,
  onChannelSubscriptionError,
  onChannelSubscriptionSucceeded,
  subscribeToChannel, unsubscribeFromChannelPusher
} from "@/features/subcontractor/services/pusherService";
import ApiService from "@/services/api.service";

export default {
  name: "NewEcoSystemStepTwoPage",
  props: ['id'],
  components: {
    CollaboratorsLoaderModal,
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    StepsComponent,
    EntrepriseHeaderBlockTextComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    collaboratorsValid() {
      return this.$store.getters.getCollaboratorsValid;
    },
    collaboratorsInvalid() {
      return this.$store.getters.getCollaboratorsInvalid;
    },
	},
  data() {
    return {
      loading: false,
      importCollaboratorsModal: false,
      countTotalCollaborators: 0,
      countFinishedCollaborators: 0,
      invalidData:0,
      importationError: "",
      subscribing: true,
      log: "",
      // validation or importation
      action: "validation",
      validationMap: [
        {
          field: "email",
          messages: ["match-existent-collaborator"],
          color: "#558C66", //green
        },
        {
          field: "email",
          messages: ["isEmail"],
          color: "#D49725", //yellow
        },
        {
          field: "email",
          messages: ["match-existent-subcontractor"],
          color: "#D49725",
        },
      ],
    };
  },
  async mounted() {
    const channelName = `importation-${this.$store.state.user.id}`;
    const channel = await subscribeToChannel(channelName);

    if(this.$route.params.formData){     

    onChannelSubscriptionSucceeded(channel, () => {
      console.log("subscription succeeded", channel);
      this.importCollaboratorsModal = true;
      this.uploadFile(this.$route.params.formData);
    });

    onChannelSubscriptionError(channel, (error) => {
      this.importCollaboratorsModal = false;
      this.subscribing = false;
      this.$snackbar.showAlertMessage({
        message: "Connexion a echoué",
        type: "error",
      });
      console.log("subscription failed", error);
    });

    bindEventPusher(channel, "error", (error) => {
      unsubscribeFromChannelPusher(channelName);
      this.$snackbar.showAlertMessage({
        message: error.message,
        type: "error",
      });
      this.importationError = error.message;
      this.importCollaboratorsModal = false;
      this.subscribing = false;
      console.log("error when importing collaborators");
    });

    /*

    validation events

    */
    bindEventPusher(channel, "start_validation", (data) => {
      this.importCollaboratorsModal = true;
      this.countTotalCollaborators = data.total;
      this.countFinishedCollaborators = 0;
      this.action = "validation";
      this.subscribing = false;
      console.log("start_validation");
    });

    bindEventPusher(channel, "valid_collaborator", ({ data }) => {
      console.log("valid_collaborator", data);
      this.$store.dispatch("loadCollaboratorValid", [
        ...this.collaboratorsValid,
        data,
      ]);
      this.countFinishedCollaborators++;
      this.log = `${data.firstName} ${data.lastName}: Réussi`;
    });

    bindEventPusher(channel, "invalid_collaborator", ({ data }) => {
      console.log("invalid_collaborator", data);
      this.$store.dispatch("loadCollaboratorInvalidInvalid", [
        ...this.collaboratorsInvalid,
        data,
      ]);
      this.countFinishedCollaborators++;
      this.invalidData++;
      this.log = `${data[0].target.firstName} ${data[0].target.lastName}: Echoué`;
    });

    bindEventPusher(channel, "finish_validation", (data) => {
      unsubscribeFromChannelPusher(channelName);
      console.log("finish_validation", data);
      if(this.invalidData > 0){
        this.$snackbar.showAlertMessage({
          message: "Certains collaborateurs n'ont pas été ajoutés",
          type: "error",
        });
      }
    });
  }
  },
  methods: {
    onCloseImportModal() {
      this.importCollaboratorsModal = false;
    },
    uploadFile(file) {
      this.loading = true;
      ApiService.post("collaborator/new-upload", file)
          .catch(() => {
            this.subscribing = false;
          })
          .finally(() => (this.loading = false));
    },
    supprimerCollaboratorValid(item) {
      this.$store.dispatch("supprimerCollaboratorValid", item);
    },
    submit() {
      const data = { one: null, multi: this.collaboratorsValid };
      this.isSendingForm = true;
      this.$store.dispatch("addCollaborator", data);
      this.$router.push({ name: "EcoSystem.Add.Step2.EMAIL" });
		},
    back() {
      this.$router.push({ name: 'EcoSystem.Add.Step1' });
    },
  },
};
</script>
