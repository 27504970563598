<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-relative tw-min-h-screen">
      <v-overlay :value="isLoadingData">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <StepsComponent :step="3" />
      <div v-if="!formSent">
        <TitleComponent class="public-title-auth tw-mt-12"
          text="SAISISSEZ LES COORDONNÉES DE VOS COLLABORATEURS QUE VOUS SOUHAITEZ INSCRIRE DANS LA PLATEFORME" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-1">
          Veuillez saisir les informations de vos collaborateurs dont vous souhaitez donner les accès à la
          plateforme.<br />
          Les champs ne sont pas obligatoires.
        </SignupSecondaryTextComponent>

      </div>

      <div class="tw-text-center">
        <v-form ref="form" @submit.prevent="onSubmit" v-model="formValid" class="tw-mt-8" autocomplete="off">
          <div class="tw-flex tw-flex-col">

            <div class="tw-grid tw-gap-4 tw-grid-cols-12 tw-mt-2">
              <div class="tw-col-span-12 md:tw-col-span-1 tw-text-black-100 tw-font-normal tw-text-sm"></div>
              <div class="tw-col-span-12 md:tw-col-span-2 tw-text-black-100 tw-font-normal tw-text-sm tw-text-left"
                :style="{
                  color: `${referer.theme.ctaBgColor} !important`
                }">
                Prénom
              </div>
              <div class="tw-col-span-12 md:tw-col-span-2 tw-text-black-100 tw-font-normal tw-text-sm tw-text-left"
                :style="{
                  color: `${referer.theme.ctaBgColor} !important`
                }">Nom</div>
              <div class="tw-col-span-12 md:tw-col-span-4 tw-text-black-100 tw-font-normal tw-text-sm tw-text-left"
                :style="{
                  color: `${referer.theme.ctaBgColor} !important`
                }">Email</div>
              <div class="tw-col-span-12 md:tw-col-span-3 tw-text-black-100 tw-font-normal tw-text-sm tw-text-left"
                :style="{
                  color: `${referer.theme.ctaBgColor} !important`
                }">Mobile</div>
            </div>

            <div class="tw-grid tw-gap-4 tw-grid-cols-12 " v-for="(contact, index) in form" :key="index">
              <div
                class="tw-col-span-12 md:tw-col-span-1 tw-text-black-100 tw-font-normal tw-text-sm tw-text-left tw-flex tw-items-center"
                :style="{
                  color: `${referer.theme.ctaBgColor} !important`
                }">
                Contact {{ index + 1}}
              </div>
              <TextFieldComponent class="tw-col-span-12 md:tw-col-span-2"  v-model="contact.firstName"
                :rules="rules[index].firstName" :error-messages="errors[index].firstName" />
              <TextFieldComponent class="tw-col-span-12 md:tw-col-span-2" v-model="contact.lastName"
                :rules="rules[index].lastName" :error-messages="errors[index].lastName" />
              <TextFieldComponent class="tw-col-span-12 md:tw-col-span-4" type="email" v-model="contact.email"
                :rules="rules[index].email" :error-messages="errors[index].email" />
              <TextFieldComponent class="tw-col-span-12 md:tw-col-span-3" type="tel" v-model="contact.phone"
                :rules="rules[index].phone" :error-messages="errors[index].phone" />
              <!-- <div class="tw-col-span-12 md:tw-col-span-1 tw-flex tw-items-center">
                <img src="@/assets/images/icons/close.svg" alt="menu" width="36px"
                  @click="removeCollaborateur(index)" />
              </div> -->
            </div>
            <!-- <div class="tw-grid tw-gap-4 tw-grid-cols-12 tw-mt-2">
              <div class="tw-col-span-12 md:tw-col-span-12 tw-text-black-100 tw-font-normal tw-text-sm tw-text-right"
                :style="{
                  color: `${referer.theme.ctaBgColor} !important`
                }">
                <PrimaryButtonComponent class="back-btn" @click="addCollaborateur">
                  Ajouter collaborator
                </PrimaryButtonComponent>

              </div>
            </div> -->
          </div>

          <div class="tw-flex tw-justify-between tw-pb-20 tw-mt-8">
            <PrimaryButtonComponent class="back-btn" @click="$router.back()">
              <svg class="tw-mr-3" width="16" height="12" viewBox="0 0 16 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6L1 6" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 11L1 6L6 1" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              Précédent
            </PrimaryButtonComponent>

            <PrimaryButtonComponent :loading="isSendingForm" :disabled="isSendingForm" type="submit">
              Suivant
              <svg class="tw-ml-3" width="16" height="12" viewBox="0 0 16 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6H15" :stroke="'#FFFFFF'" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M10 1L15 6L10 11" :stroke="'#FFFFFF'" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </PrimaryButtonComponent>
          </div>
        </v-form>
        <footer-paragraph-text v-if="!formSent" />
      </div>
    </div>
  </v-container>
</template>

<script>

import StepsComponent from "@/features/auth/components/StepsComponent";
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { validationRules } from "@/utils/validation-rules";
import { postLastStepRegistrationManyCollabo } from "@/features/auth/services/appApi";
export default {
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
    StepsComponent
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isLoadingData: false,
      isSendingForm: false,
      form: [
        { firstName: "", lastName: "", email: "", phone: "" },
        { firstName: "", lastName: "", email: "", phone: "" },
      ],
      errors: [
        { firstName: "", lastName: "", email: "", phone: "" },
        { firstName: "", lastName: "", email: "", phone: "" },
      ],
      rules: [{
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[0], "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[0], "lastName"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors[0], "email"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[0], "phone"),
        ],
      },
      {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[1], "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[1], "lastName"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors[1], "email"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[1], "phone"),
        ],
      },
      ],

    }
      ;
  },
  methods: {
    addCollaborateur() {
      let newCollaborateur = { firstName: "", lastName: "", email: "", phone: "" };
      this.form.push(newCollaborateur);
      this.errors.push({ firstName: "", lastName: "", email: "", phone: "" });
      this.rules.push({
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[this.form.length - 1], "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[this.form.length - 1], "lastName"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors[this.form.length - 1], "email"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors[this.form.length - 1], "phone"),
        ],
      });
    },
    removeCollaborateur(index) {
      this.form.splice(index, 1);
      this.errors.splice(index, 1);
      this.rules.splice(index, 1);
    },
    onBack() {
      this.$router.push({
        name: "signupSecond",
        params: { token: this.$route.params.token },
      });
    },
    onSubmit() {
      this.isSendingForm = true;
      let formSubmit = this.form.filter((form) => {
        return form.firstName || form.lastName || form.email || form.phone;
      });
      postLastStepRegistrationManyCollabo(this.$route.params.token, formSubmit).then(() => {
        this.isSendingForm = false;
        this.formSent = true;
        window.scrollTo(0, 0);
        this.$router.push({
          name: "signupLast",
          params: { token: this.$route.params.token },
        });
      }).catch(() => {
        window.scrollTo(0, 0);
        this.isSendingForm = false;

        this.$notify({
          group: "foo",
          type: "error",
          title: "Attention",
          text: `Veuillez vérifier tous les champs`,
        });

      });
    },
    go_to_login() {
      this.$router.push({ name: "LoginPage" });
    },
  },
};
</script>

