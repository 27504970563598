<template>
  <v-dialog
      v-if="handelPopup"
      v-model="handelPopup"
      @click:outside="closePopup"
      width="600"
      transition="dialog-bottom-transition"
    >
    <!-- <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/> -->
    <div id="deleteDialog" class="tw-p-8">
      <!-- <img src="@/assets/images/icons/info.svg" alt="logo" /> -->
      <h2 class="tw-font-bold tw-mb-6">êtes vous sur de vouloir supprimer <span :style="{color:referer.theme.ctaBgColor}">{{item.firstName}} {{item.lastName}}</span> ?</h2>
      <hr class="tw-w-full tw-border-black"/>
      <p>
        Vous vous appretez à supprimer les données associées à ce compte.
        Veuillez annuler si cette action n’est pas volontaire, sinon cliquez sur Supprimer
      </p>
      <div class="tw-flex tw-justify-around tw-w-full">
          <v-btn
            elevation="3"
            text
            large
            @click="closePopup"
            class="tw-text-gray-100 tw-cursor-pointer normal-btn"
          >
            ANNULER
          </v-btn>
          <v-btn
            elevation="3"
            text
            large
            :loading="isSendingForm"
            @click="supprimerContact"
            class="tw-text-white tw-cursor-pointer"
          >
            Supprimer
          </v-btn>
      </div>
      </div>
    </v-dialog>
</template>
<script>
import {deleteCollaborator, deleteNewCollaborator} from '../services/appApi';
export default {
  name: "ConfirmeDeletePopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    handelPopup(){
      return this.$parent.openPopup;
    }
  },
  data() {
    return {
      isSendingForm: false
    };
  },
  methods: {
    supprimerContact() {
      this.isSendingForm = true;
      if (this.item.gouvernance === null && this.item.ecosystem === null && this.item.elearning === null) {
        deleteCollaborator(this.item.id)
            .then(() => {
              this.$snackbar.showAlertMessage({
                message: 'Supprimé avec succès',
                type: "success",
              });
              this.$emit('fetchData');
              this.$parent.openPopup = false;
            })
            .catch(({ response }) => {
              this.$snackbar.showAlertMessage({
                message: response.status.mesage,
                type: "error",
              });
            }).finally(()=>{
          this.isSendingForm = false;
          this.closePopup();
        });
      }
      else {
        deleteNewCollaborator(this.item.id)
            .then(() => {
              this.$snackbar.showAlertMessage({
                message: 'Supprimé avec succès',
                type: "success",
              });
              this.$emit('fetchData');
              this.$parent.openPopup = false;
            })
            .catch(({ response }) => {
              this.$snackbar.showAlertMessage({
                message: response.status.mesage,
                type: "error",
              });
            }).finally(()=>{
          this.isSendingForm = false;
          this.closePopup();
        });
      }
    },
    closePopup(){
      this.$emit('close');
      this.$parent.openPopup = false;
    }
  },
};
</script>
<style lang="scss" scoped>
  #deleteDialog{
    position: relative;
    img {
      height: 40px;
      width: 40px;
      margin: 20px auto 20px;
    }
    .img-close {
      height: 10px;
      width: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
</style>
