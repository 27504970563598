<template>
  <div id="collaborateurs-table">
    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>
    <!-- table  -->
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Collaborateur</th>
            <th>Fonction</th>
            <th>Email</th>
            <th>Renvoyer</th>
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody v-if="collaborateurs.length>0">
          <tr v-for="item in collaborateurs" :key="item.name">
            <td>{{ item.firstName + " " + item.lastName }}</td>
            <td>{{ item.function }}</td>
            <td>{{ item.email }}</td>
            <td class="tw-flex tw-justify-center tw-items-center">
              <svg v-if="item.isAccountActive" class="tw-cursor-pointer" width="50" height="18" viewBox="0 0 50 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="50" height="18" rx="9" :fill="referer.theme.ctaBgColor" />
                <path d="M23.5 10L21.5 8L20 9.5L23.5 13L30 6.5L28.5 5L23.5 10Z" fill="white" />
              </svg>
              <svg class="tw-cursor-pointer" @click="envoyer(item)" v-else width="50" height="18" viewBox="0 0 50 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="50" height="18" rx="9" :fill="referer.theme.ctaBgColor" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M33.6807 9.00029C33.6807 9.29535 33.5124 9.56456 33.2472 9.69379L22.6105 14.8758C22.3274 15.0137 21.9888 14.9657 21.7552 14.7544C21.5216 14.5432 21.4399 14.2111 21.5488 13.9156L23.4579 8.73361C23.5697 8.43035 23.8586 8.22888 24.1818 8.22888H27.4546C27.8806 8.22888 28.226 8.57425 28.226 9.00029C28.226 9.42633 27.8806 9.77171 27.4546 9.77171H24.7197L23.6599 12.6484L31.148 9.00029L23.6601 5.35237L24.3132 7.12439C24.4605 7.52414 24.2559 7.96764 23.8562 8.11497C23.4564 8.2623 23.0129 8.05767 22.8656 7.65792L21.5488 4.08508C21.4399 3.78955 21.5216 3.45748 21.7552 3.2462C21.9888 3.03493 22.3273 2.98688 22.6105 3.12483L33.2472 8.3068C33.5124 8.43603 33.6807 8.70524 33.6807 9.00029Z"
                      fill="white" />
              </svg>
            </td>
            <td>
              <div class="tw-w-full tw-flex tw-justify-center tw-items-center">
                <svg  @click="supprimerContact(item)" class="tw-cursor-pointer" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.33352 0.211923L5.00009 3.87813L8.66666 0.223033C8.73885 0.149586 8.8255 0.0919159 8.92112 0.05367C9.01674 0.0154241 9.11927 -0.00256573 9.2222 0.000838218C9.42418 0.0139081 9.6145 0.100038 9.75762 0.243146C9.90075 0.386254 9.98689 0.576556 9.99996 0.778519C10.001 0.87782 9.98178 0.97629 9.94358 1.06796C9.90539 1.15962 9.84896 1.24258 9.77774 1.31179L6.10006 5.00022L9.77774 8.68864C9.92218 8.82863 10.0022 9.02194 9.99996 9.22191C9.98689 9.42387 9.90075 9.61418 9.75762 9.75729C9.6145 9.90039 9.42418 9.98652 9.2222 9.99959C9.11927 10.003 9.01674 9.98501 8.92112 9.94676C8.8255 9.90851 8.73885 9.85085 8.66666 9.7774L5.00009 6.1223L1.34464 9.7774C1.27244 9.85085 1.1858 9.90851 1.09017 9.94676C0.99455 9.98501 0.892029 10.003 0.789095 9.99959C0.583345 9.98888 0.388856 9.90234 0.243172 9.75667C0.0974876 9.611 0.0109371 9.41653 0.000227414 9.2108C-0.000777895 9.1115 0.0184003 9.01303 0.0565986 8.92136C0.094797 8.8297 0.151219 8.74674 0.222444 8.67754L3.90012 5.00022L0.211333 1.31179C0.142114 1.24164 0.0877451 1.15827 0.0514738 1.06665C0.0152026 0.975023 -0.00222666 0.877029 0.000227414 0.778519C0.0132986 0.576556 0.0994371 0.386254 0.242559 0.243146C0.385681 0.100038 0.576002 0.0139081 0.777984 0.000838218C0.880117 -0.00401225 0.982154 0.0122271 1.07773 0.0485439C1.17331 0.0848608 1.26039 0.140476 1.33352 0.211923Z" :fill="referer.theme.ctaBgColor"/>
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
        <tfoot class="text-center" v-if="collaborateurs.length > 0">
          <tr>
            <td colspan="5">
              <v-pagination
                v-model="page"
                :length="length"
                @input="pagination"
              ></v-pagination>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <!-- end table  -->
    <ConfirmeDeletePopup
      v-if="selectedItem && openPopup"
      :openPopup="openPopup"
      :item="selectedItem"
      v-on:fetchData="fetchData"
      @close="openPopup = false"
    />
  </div>
</template>
<script>
import ConfirmeDeletePopup from "@/features/company/components/Collaborateurs/ConfirmeDeletePopup";
import { getCollaborator, inviteCollaborator } from "../../services/appApi";

export default {
  name: "CollaborateursFormAddComponent",
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    referer() {
      return this.$store.state.referrer;
    }
  },
  components: {
    ConfirmeDeletePopup,
  },
  data() {
    return {
      collaborateurs: [],
      openPopup: false,
      selectedItem: null,
      page: 1,
      length: null,
      loading_table:false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    supprimerContact(item) {
      this.selectedItem = item;
      this.openPopup = true;
    },
    envoyer(item) {
      inviteCollaborator(item.id)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          //this.fetchData();
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.status.mesage,
            type: "error",
          });
        });
    },
    fetchData() {
      this.loading_table=true;
      getCollaborator()
        .then((res) => {
          this.collaborateurs = res.data.items;
          this.page = res.data.meta.currentPage;
          this.length = res.data.meta.totalPages;
          // this.$router.push({
          //   name: "Company.MonEntreprisePage.Collaborateurs.invitation",
          // });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.isSendingForm = false;
          this.loading_table = false;
        });
    },
    pagination(value) {

      // if (value != this.page) {
        getCollaborator(value)
          .then((res) => {
            this.collaborateurs = res.data.items;
            this.page = res.data.meta.currentPage;
            this.length = res.data.meta.totalPages;
            // this.$router.push({
            //   name: "Company.MonEntreprisePage.Collaborateurs.invitation",
            // });
          })
          .catch(({ response }) => {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            this.isSendingForm = false;
          });
      }
    // },
  },
};
</script>
