<template>
  <span
    v-if="label"
    class="tw-inline-flex tw-items-center tw-justify-center tw-space-x-2 tw-border tw-border-gray-900 tw-rounded-full tw-py-0.5 tw-text-xs tw-font-medium tw-text-gray-900"
    :class="classNames"
    style="width: 7rem;"
  >
    <slot name="icon"></slot>
    <span class="tw-block">{{ label }}</span>
  </span>
</template>
<script>
export default {
  name: "BadgeComponent",
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    classNames: {
      type: String,
      default: "",
    },
  },
};
</script>
