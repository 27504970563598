<template>
  <div id="register-activity-item-question-container">
    <div class="register-activity-item-header"
      :class="register.status === 'valide' ? 'validate' : register.status === 'draft' ? 'draft' : ''"
      @click="handleSelectItem">
      <span class="register-activity-item-header-title"
        :class="register.status === 'valide' ? 'validate' : register.status === 'draft' ? 'draft' : ''">{{
          register.name
        }}</span>
      <svg v-if="!isPreview" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.25926 13.5926L14.8519 1L18 4.14815L5.40741 16.7407M2.25926 13.5926L1 18L5.40741 16.7407M2.25926 13.5926L5.40741 16.7407M12.3333 3.51852L15.4815 6.66667"
          :stroke="register.status === 'valide' ? '#27AE60' : register.status === 'draft' ? '#E8973E' : 'var(--cta-bg-color)'"
          stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <Transition name="slide-up">
      <div v-if="register.selected" class="register-activity-item-content">
        <div v-if="!isPreview" v-html="handleDescription(register)"></div>
        <div v-for="(question, index) in questions" :key="question.id">
          <div class="tw-flex tw-flex-col tw-my-4 tw-space-y-1"
            v-if="question.type === 'text_field' && register.order !== 8 && register.order !== 7">
            <span class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }}
            </span>
            <input type="text" class="register-activity-item-content-question-input tw-w-2/3"
              :placeholder="inputPlaceHolder(question.placeHolder)" v-model="question.value" :readonly="isPreview" />
            <p v-html="question.description"></p>
          </div>


          <div class="tw-flex tw-flex-col tw-space-y-6 tw-my-4 tw-space-y-1"
            v-if="question.type === 'text_field' && register.order === 7">
            <div v-for="(questionValue, index) in question.value" :key="JSON.parse(question.title)[index]">
              <span class="register-activity-item-content-question-title"> {{ index + 1 }} • {{
                JSON.parse(question.title)[index] ? JSON.parse(question.title)[index] : ` Destinataire ${index + 1}`
              }}
              </span>
              <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4">
                <input type="text" class="register-activity-item-content-question-input tw-w-2/3"
                  :placeholder="JSON.parse(question.placeHolder)[index] ? inputPlaceHolder(JSON.parse(question.placeHolder)[index]) : 'Collaborators'"
                  v-model="question.value[index]" :readonly="isPreview" />
                <svg v-if="!isPreview && index === 0" @click="addNewPersonnalLine(question.id)"
                  class="tw-cursor-pointer" width="38" height="37" viewBox="0 0 32 31" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="30" :fill="referer.theme.ctaBgColor"
                    :stroke="referer.theme.ctaBgColor" />
                  <path
                    d="M21.75 15H17V10.25C17 10.1125 16.8875 10 16.75 10H15.25C15.1125 10 15 10.1125 15 10.25V15H10.25C10.1125 15 10 15.1125 10 15.25V16.75C10 16.8875 10.1125 17 10.25 17H15V21.75C15 21.8875 15.1125 22 15.25 22H16.75C16.8875 22 17 21.8875 17 21.75V17H21.75C21.8875 17 22 16.8875 22 16.75V15.25C22 15.1125 21.8875 15 21.75 15Z"
                    fill="white" />
                </svg>
                <div v-else class="tw-flex tw-justify-center tw-items-center" style="height: 40px;width: 40px;">
                  <img class="tw-cursor-pointer" src="@/assets/images/icons/closePopupBlack.svg" alt="closePopup"
                    @click="deleteItem(question, index)" />
                </div>
              </div>
            </div>

            <p v-html="question.description"></p>
          </div>

          <div class="tw-flex tw-flex-col tw-space-y-6 tw-my-4 tw-space-y-1"
            v-if="question.type === 'text_field' && register.order === 8">
            <div v-for="(questionValue, index) in question.value" :key="JSON.parse(question.title)[index]">
              <span class="register-activity-item-content-question-title"> {{ index + 1 }} • {{
                JSON.parse(question.title)[index] ? JSON.parse(question.title)[index] : ` Destinataire ${index + 1}`
              }}
              </span>
              <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4">
                <input type="text" class="register-activity-item-content-question-input tw-w-2/3"
                  :placeholder="JSON.parse(question.placeHolder)[index] ? inputPlaceHolder(JSON.parse(question.placeHolder)[index]) : 'Destinataire'"
                  v-model="question.value[index]" :readonly="isPreview" />
                <svg v-if="!isPreview && index === 0" @click="addNewPersonnalLine(question.id)"
                  class="tw-cursor-pointer" width="38" height="37" viewBox="0 0 32 31" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="30" :fill="referer.theme.ctaBgColor"
                    :stroke="referer.theme.ctaBgColor" />
                  <path
                    d="M21.75 15H17V10.25C17 10.1125 16.8875 10 16.75 10H15.25C15.1125 10 15 10.1125 15 10.25V15H10.25C10.1125 15 10 15.1125 10 15.25V16.75C10 16.8875 10.1125 17 10.25 17H15V21.75C15 21.8875 15.1125 22 15.25 22H16.75C16.8875 22 17 21.8875 17 21.75V17H21.75C21.8875 17 22 16.8875 22 16.75V15.25C22 15.1125 21.8875 15 21.75 15Z"
                    fill="white" />
                </svg>
                <div v-else class="tw-flex tw-justify-center tw-items-center" style="height: 40px;width: 40px;">
                  <img class="tw-cursor-pointer" src="@/assets/images/icons/closePopupBlack.svg" alt="closePopup"
                    @click="deleteItem(question, index)" />
                </div>
              </div>
            </div>

            <p v-html="question.description"></p>
          </div>
          <div class="tw-flex tw-flex-col tw-my-2 tw-space-y-3" v-if="question.type === 'select_field'">
            <span v-if="question.title" :class="{ 'tw-mt-8': index !== 0 }"
              class="register-activity-item-content-question-title">{{ question.title }} </span>
            <div
              class="tw-flex tw-flex-row tw-items-center tw-justify-between register-activity-item-content-delect-container">
              <span class="register-activity-item-content-desc">{{ question.description }}</span>
              <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4">

                <div class="register-activity-item-content-sheet sheet-bleu"
                  :class="{ 'active': question.value === 'effectif' }"
                  @click="handleSelectFieldResponse(question, 'effectif')"></div>
                <div class="register-activity-item-content-sheet sheet-ciel"
                  :class="{ 'active': question.value === 'en projet' }"
                  @click="handleSelectFieldResponse(question, 'en projet')"></div>
                <div class="register-activity-item-content-sheet sheet-orange"
                  :class="{ 'active': question.value === 'sans objet' }"
                  @click="handleSelectFieldResponse(question, 'sans objet')"></div>

              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-space-y-1 tw-select-none" v-if="question.type === 'check_field'">
            <div @click="changeCheckYesOrNo(question.id, question.value === 'OUI' ? 'NON' : 'OUI')"
              class="tw-flex tw-flex-row tw-items-center" :class="{ 'tw-cursor-pointer': !isPreview }">
              <svg v-if="question.value && question.value === 'OUI'" width="16" height="17" viewBox="0 0 16 17"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16.1" rx="3" fill="#E9EAEC" />
                <path d="M11.7342 5.46484L6.60091 10.6303L4.26758 8.28234" stroke="black" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <svg v-if="question.value && question.value === 'NON'" width="16" height="17" viewBox="0 0 16 17"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16.1" rx="3" fill="#E9EAEC" />
              </svg>
              <span class="ml-2" v-html="question.description"></span>
            </div>

          </div>
          <div class="tw-flex tw-flex-col tw-my-4 tw-space-y-1"
            v-if="question.type === 'multi_text_field' && register.order === 5">
            <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items center tw-space-x-4">
              <span class="register-activity-item-content-question-title tw-w-3/5"> {{ question.order }} • {{
                JSON.parse(question.title)[0]
              }} </span>
              <span class="register-activity-item-content-question-title tw-w-1/5"> {{ JSON.parse(question.title)[1] }}
              </span>
            </div>

            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4"
              v-for="(qstValue, qstValueIndex) in question.value" :key="`${qstValueIndex}`">
              <input v-for="(oneAnswer, keyObject, ObjectIndex) in qstValue" :key="`${ObjectIndex}${keyObject}`"
                type="text" class="register-activity-item-content-question-input"
                :class="ObjectIndex === 0 ? 'tw-w-3/5' : 'tw-w-1/5'"
                :placeholder="inputPlaceHolder(JSON.parse(question.placeHolder)[ObjectIndex])"
                v-model="question.value[qstValueIndex][`value${ObjectIndex + 1}`]" />

              <svg v-if="!isPreview && qstValueIndex === 0" @click="addNewPersonnalLine(question.id)"
                class="tw-cursor-pointer" width="38" height="37" viewBox="0 0 32 31" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="30" :fill="referer.theme.ctaBgColor"
                  :stroke="referer.theme.ctaBgColor" />
                <path
                  d="M21.75 15H17V10.25C17 10.1125 16.8875 10 16.75 10H15.25C15.1125 10 15 10.1125 15 10.25V15H10.25C10.1125 15 10 15.1125 10 15.25V16.75C10 16.8875 10.1125 17 10.25 17H15V21.75C15 21.8875 15.1125 22 15.25 22H16.75C16.8875 22 17 21.8875 17 21.75V17H21.75C21.8875 17 22 16.8875 22 16.75V15.25C22 15.1125 21.8875 15 21.75 15Z"
                  fill="white" />
              </svg>
              <div v-else class="tw-flex tw-justify-center tw-items-center" style="height: 40px;width: 40px;">
                <img class="tw-cursor-pointer" src="@/assets/images/icons/closePopupBlack.svg" alt="closePopup"
                  @click="deleteItem(question, qstValueIndex)" />
              </div>


            </div>


            <p v-html="question.description"></p>
          </div>
          <div class="tw-flex tw-flex-col tw-my-4 tw-space-y-1"
            v-if="question.type === 'multi_text_field' && register.order === 6">
            <span v-if="question.title" :class="{ 'tw-mt-8': index !== 0 }"
              class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }} </span>
            <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-space-x-4">
              <p class="!tw-mb-0 tw-w-3/5" v-html="JSON.parse(question.description)[0]"></p>
              <p class="!tw-mb-0 tw-w-2/5 tw-pl-3" v-html="JSON.parse(question.description)[1]"></p>
            </div>
            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4">
              <input v-for="(placeholder, index) in JSON.parse(question.placeHolder)" :key="placeholder" type="text"
                class="register-activity-item-content-question-input" :class="index === 0 ? 'tw-w-3/5' : 'tw-w-1/5'"
                :placeholder="inputPlaceHolder(placeholder)" v-model="question.value[index]" :readonly="isPreview" />
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-my-4 tw-space-y-1"
            v-if="question.type === 'multi_text_field' && register.order === 9 && question.order === 1">
            <span class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }}
            </span>
            <div class="pt-4 tw-flex tw-flex-row tw-items-center tw-space-x-12">
              <div @click="changeCheckYesOrNo(question.id, 'OUI')" class="tw-flex tw-flex-row tw-items-center"
                :class="{ 'tw-cursor-pointer': !isPreview }">
                <svg v-if="question.value[0] && question.value[0] === 'OUI'" width="16" height="17" viewBox="0 0 16 17"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16.1" rx="3" fill="#E9EAEC" />
                  <path d="M11.7342 5.46484L6.60091 10.6303L4.26758 8.28234" stroke="black" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg v-else width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16.1" rx="3" fill="#E9EAEC" />
                </svg>
                <span class="ml-2"
                  style="font-style: normal; font-weight: 400; font-size: 14px; line-height: 21px;  color: rgba(0, 0, 0, 0.6);">
                  Oui </span>
              </div>
              <div @click="changeCheckYesOrNo(question.id, 'NON')" class="tw-flex tw-flex-row tw-items-center"
                :class="{ 'tw-cursor-pointer': !isPreview }">
                <svg v-if="question.value[0] && question.value[0] === 'NON'" width="16" height="17" viewBox="0 0 16 17"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16.1" rx="3" fill="#E9EAEC" />
                  <path d="M11.7342 5.46484L6.60091 10.6303L4.26758 8.28234" stroke="black" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg v-else width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16.1" rx="3" fill="#E9EAEC" />
                </svg>
                <span class="ml-2"
                  style="font-style: normal; font-weight: 400; font-size: 14px; line-height: 21px;  color: rgba(0, 0, 0, 0.6);">
                  Non </span>

              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-my-4 tw-space-y-1"
            v-if="question.type === 'multi_text_field' && register.order === 9 && question.order !== 1 && EuFormDisplay">
            <span class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }}
            </span>
            <div v-for="(questionValue, index) in question.value" :key="`quest-${index}`">
              <p class="!tw-mb-0" v-html="question.description"></p>
              <div class="tw-w-full tw-flex tw-flex-row tw-items-center tw-space-x-4">
                <input type="text" class="register-activity-item-content-question-input" style="width: 30% !important;"
                  :placeholder="inputPlaceHolder(JSON.parse(question.placeHolder)[0])"
                  v-model="question.value[index][0]" :readonly="isPreview" />
                <input type="text" class="register-activity-item-content-question-input" style="width: 30% !important;"
                  :placeholder="inputPlaceHolder(JSON.parse(question.placeHolder)[1])"
                  v-model="question.value[index][1]" :readonly="isPreview" />
                <div style="width: 30% !important;" class="register-activity-item-content-question-select-container">
                  <select class="register-activity-item-content-question-select" v-model="question.value[index][2]"
                    :disabled="isPreview">
                    <option value="0" disabled>{{ inputPlaceHolder(JSON.parse(question.placeHolder)[2]) }}</option>
                    <option value="1">Je ne sais pas</option>
                    <option value="2">Décision d’adéquation</option>
                    <option value="3">Clauses contractuelles types</option>
                    <option value="4">Règles internes d’entreprise</option>
                    <option value="5">Code de conduite</option>
                    <option value="6">Mécanisme de certification</option>
                  </select>
                </div>
                <svg v-if="!isPreview && index === 0" @click="addNewPersonnalLine(question.id)"
                  class="tw-cursor-pointer" width="38" height="37" viewBox="0 0 32 31" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="30" :fill="referer.theme.ctaBgColor"
                    :stroke="referer.theme.ctaBgColor" />
                  <path
                    d="M21.75 15H17V10.25C17 10.1125 16.8875 10 16.75 10H15.25C15.1125 10 15 10.1125 15 10.25V15H10.25C10.1125 15 10 15.1125 10 15.25V16.75C10 16.8875 10.1125 17 10.25 17H15V21.75C15 21.8875 15.1125 22 15.25 22H16.75C16.8875 22 17 21.8875 17 21.75V17H21.75C21.8875 17 22 16.8875 22 16.75V15.25C22 15.1125 21.8875 15 21.75 15Z"
                    fill="white" />
                </svg>
                <div v-if="!isPreview && index !== 0" class="tw-flex tw-justify-center tw-items-center"
                  style="height: 40px;width: 40px;">
                  <img class="tw-cursor-pointer" src="@/assets/images/icons/closePopupBlack.svg" alt="closePopup"
                    @click="deleteItem(question, index)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isPreview && isFirstAnswers" class="tw-mt-6" v-html="handleFooter(register)"></div>
        <div v-if="!isPreview && isFirstAnswers"
          class="tw-my-8 tw-flex tw-flex-row tw-items-center tw-justify-center tw-space-x-12">
          <PrimaryButtonComponent @click="submit('valide')" class="register-activity-item-content-btn valid"
            :loading="type === 'valide' && isFormLoading">
            Je valide
          </PrimaryButtonComponent>
          <PrimaryButtonComponent @click="submit('draft')" class="register-activity-item-content-btn draft"
            :loading="type === 'draft' && isFormLoading">
            Je complèterai
          </PrimaryButtonComponent>
        </div>
        <div v-if="!isPreview && !isFirstAnswers"
          class="tw-my-8 tw-flex tw-flex-row tw-items-center tw-justify-center tw-space-x-12">
          <PrimaryButtonComponent @click="submit('valide')" class="register-activity-item-content-btn valid"
            :loading="type === 'valide' && isFormLoading">
            Valider
          </PrimaryButtonComponent>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { persistRegisterQuestionsAnswer } from "@/features/ProcessingRegister/services/appApi";
import moment from "moment";
export default {
  name: "RegisterActivityItem",
  components: { PrimaryButtonComponent },

  data() {
    return {
      questions: [],
      isFormLoading: false,
      type: '',
      EuFormDisplay: true
    }
  },
  props: {
    register: {
      type: Object,
      default: () => { }
    },
    user: {
      type: Object,
      default: () => { }
    },
    activity: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    isFirstAnswers: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    setTimeout(() => {
      this.questions = this.register.registerModuleQuestions.map(obj => {
        if (obj.value === '' || obj.value === null) {
          if (obj.title === 'Description du traitement :') {
            return { ...obj, value: obj.type === 'multi_text_field' ? [] : this.activity };
          }
          else if (obj.title && obj.title.includes('N° / Référence')) {
            return { ...obj, value: obj.type === 'multi_text_field' ? [] : this.referenceNumberHandler };
          }
          else if (obj.title && obj.title.includes('Date de création :')) {
            return { ...obj, value: obj.type === 'multi_text_field' ? [] : this.creationDateHandler };
          }
          else if (obj.title && obj.title.includes('Date de mise à jour :')) {
            return { ...obj, value: obj.type === 'multi_text_field' ? [] : this.currentDateHandler };
          }
          else if (obj.description && obj.description.includes('Pas de co-responsable de traitement')) {
            return { ...obj, value: obj.type === 'multi_text_field' ? [] : 'NON' };
          }
          else if (obj.registerModuleId === 5) {
            return { ...obj, value: [{ value1: "", value2: "" }] };
          }
          else if (obj.registerModuleId === 7) {
            return { ...obj, value: [''] };
          }
          else if (obj.registerModuleId === 8) {
            return { ...obj, value: [''] };
          }
          else if (obj.registerModuleId === 9) {
            if (obj.order === 1) {
              return { ...obj, value: obj.type === 'multi_text_field' ? ['OUI'] : '' };
            }
            else {
              return { ...obj, value: obj.type === 'multi_text_field' ? [['', '', '0']] : '' }
            }
          }
          else {
            return {
              ...obj, value: obj.type === 'multi_text_field' ? [] : ''
            };
          }

        } else {
          if (obj.type === 'multi_text_field') {
            return { ...obj, value: JSON.parse(obj.value) };
          }
          else if (obj.registerModuleId === 8 || obj.registerModuleId === 7) {
            return { ...obj, value: JSON.parse(obj.value) };
          } else {
            return { ...obj, value: obj.value };
          }
        }
      });
    }, 2000);
  },
  watch: {
    questions: {
      handler: function (val) {
        this.$store.dispatch('updateQuestions', val);
      },
      deep: true
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    handleValidateButtonDisabled() {
      let disabled = false;
      if (this.register.name === 'Acteurs' || this.register.name === 'Finalités du traitement') {
        disabled = this.questions.every(item => item.value === "")
      }
      else if (this.register.name === 'Description du traitement') {
        disabled = this.questions[0].value === '' || this.questions[3].value === '' || this.questions[4].value === ''
      }
      else if (this.register.name === 'Transferts hors Union Européenne') {
        this.questions.forEach((item, index) => {
          if (disabled === false) {
            if (this.questions[0].value[0] === 'OUI') {
              if (index > 0) {
                let count2 = 0;
                item.value.forEach(qst => {
                  if (qst[0].length && qst[1].length && qst[2] != 0) {
                    count2++
                  }
                });
                if (count2 == 0) {
                  disabled = true;
                } else {
                  let count3 = 0;
                  item.value.forEach(qst => {
                    if (!qst[0].length || !qst[1].length || qst[2] == 0) {
                      count3++
                    }
                  });
                  if (count3 != 0) {
                    disabled = true;
                  } else {
                    disabled = false;
                  }
                }
              }
            } else {
              disabled = false;
            }
          }
        });
      }
      else if (this.register.name === 'Catégories de données sensibles') {
        disabled = false;
      }
      else {
        let count = 0;
        this.questions.forEach(item => {
          if (disabled === false) {
            if (this.register.name === 'Catégories de données personnelles') {

              item.value.forEach(qst => {
                if (Object.values(qst)[0] !== '') {
                  count++
                }
              });

            }
            else if (this.register.name === 'Catégories de Personnes concernées' || this.register.name === 'Service interne ou sous-traitant destinataire de la donnée') {
              disabled = item.value.every(qst => qst === '');
            }
            else if (item.type === 'multi_text_field') {
              item.value.length < JSON.parse(item.placeHolder).length ? disabled = true : disabled = false;
            }
            else {
              item.value === "" ? disabled = true : disabled = false;
            }
          }
        });
        if (this.register.name === 'Catégories de données personnelles') {
          disabled = count < 1 ? true : false;
        }
      }
      return disabled;
    },
    referenceNumberHandler() {
      if (this.user && this.user.company) {
        const tradeName = this.user.company.tradeName.substring(0, 2);
        const date = new Date().getFullYear().toString().slice(-2);
        const name = this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1);
        const random = Math.floor(1 + Math.random() * 9);
        return `${tradeName}${date}${name}000${random}`;
      } else {
        return '';
      }
    },
    creationDateHandler() {
      if (this.user) {
        return moment(this.user.createdAt).format('DD/MM/YYYY');
      } else {
        return '';
      }

    },
    currentDateHandler() {
      if (this.user) {
        return moment().format('DD/MM/YYYY');
      } else {
        return '';
      }
    }
  },
  methods: {
    deleteItem(question, index) {
      this.questions = this.questions.map(quest => {
        if (quest.id === question.id) {
          return { ...quest, value: quest.value.filter((item, i) => i !== index) }
        }
        else {
          return { ...quest };
        }
      });
    },
    handleDescription(register) {
      return register.description.replace('«activité»', this.activity).replace('«société»', this.user.company.tradeName);
    },

    handleFooter(register) {
      return register.footer.replace('«activité»', this.activity).replace('«société»', this.user.company.tradeName);
    },
    addNewPersonnalLine(questionId) {
      this.questions = this.questions.map(question => {
        if (question.registerModuleId === 5) {
          if (question.id === questionId) {
            return { ...question, value: [...question.value, { value1: "", value2: "" }] }
          }
          else {
            return { ...question };
          }
        }
        else if (question.registerModuleId === 7) {
          if (question.id === questionId) {
            const titles = [...JSON.parse(question.title), `Profil personne concernée ${JSON.parse(question.title).length + 1}`];
            return {
              ...question,
              value: [...question.value, ''],
              title: JSON.stringify(titles)
            }
          }
          else {
            return { ...question };
          }
        }
        else if (question.registerModuleId === 8) {
          if (question.id === questionId) {
            const titles = [...JSON.parse(question.title), `Destinataire ${JSON.parse(question.title).length + 1}`];
            return {
              ...question,
              value: [...question.value, ''],
              title: JSON.stringify(titles)
            }
          }
          else {
            return { ...question };
          }
        }
        else if (question.registerModuleId === 9 && question.order !== 1) {
          if (question.id === questionId) {
            return {
              ...question,
              value: [...question.value, ['', '', 0]],
            }
          }
          else {
            return { ...question };
          }
        }
        else {
          return { ...question };
        }
      });
    },
    changeCheckYesOrNo(questionId, value) {
      if (!this.isPreview) {
        this.questions = this.questions.map(question => {
          if (question.registerModuleId === 9) {
            if (value === 'OUI') {
              if (question.id === questionId) {
                this.EuFormDisplay = true;
                return { ...question, value: [value] }
              }
              else {
                return { ...question };
              }
            }
            else {
              if (question.id === questionId) {
                this.EuFormDisplay = false;
                return { ...question, value: [value] }
              }
              else {
                return { ...question, value: question.type === 'multi_text_field' ? [...question.value] : '' }
              }
            }
          }
          else if (question.registerModuleId === 2 && question.order === 5) {
            return { ...question, value: value }
          }
          else {
            return { ...question };
          }
        });
      }
    },
    isNumber(evt, index) {
      if (index !== 0) {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    inputPlaceHolder(placeHolder) {
      return placeHolder
    },
    handleSelectItem() {
      this.$emit('handleSelectItem', this.register)
    },
    handleSelectFieldResponse(question, value) {
      if (!this.isPreview) {
        console.log('tttt')
        this.questions.map(item => {
          if (item.id === question.id) {
            item.value = value;
          }
        })
      }
    },
    submit(type) {
      if (this.handleValidateButtonDisabled) {
        this.$snackbar.showAlertMessage({
          type: 'error',
          message: 'Veuillez remplir tous les champs obligatoires'
        });
        return;
      }
      this.type = type;
      this.isFormLoading = true;
      persistRegisterQuestionsAnswer({ type, questions: this.questions }, this.$route.params.token || this.$route.params.id)
        .then(response => {
          this.$snackbar.showAlertMessage({
            type: 'success',
            message: response.data.message
          });
          this.$emit('handlePersistResponse', { questions: this.questions, id: this.register.id, status: type });
        })
        .catch(err => console.log(err))
        .finally(() => this.isFormLoading = false);
    },
  }
}
</script>
<style lang="scss" scoped>
.delete-input {
  height: 9px;
  position: absolute;
  right: 10px;
}

#register-activity-item-question-container {
  display: flex;
  flex-direction: column;

  .register-activity-item-header {
    margin-bottom: 10px;
    padding: 6px 10px;
    border: 1px solid var(--cta-bg-color);
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.validate {
      border-color: #27AE60 !important;
    }

    &.draft {
      border-color: #E8973E !important;
    }
  }

  .register-activity-item-header-title {
    user-select: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--cta-bg-color);

    &.validate {
      color: #27AE60 !important;
    }

    &.draft {
      color: #E8973E !important;
    }
  }

  .register-activity-item-content {
    display: flex;
    flex-direction: column;

    .register-activity-item-content-question-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }

    .register-activity-item-content-question-input {
      border: 1px solid var(--cta-bg-color);
      padding: 6px;
    }

    .register-activity-item-content-question-select-container {
      border: 1px solid var(--cta-bg-color);
    }

    .register-activity-item-content-question-select {
      width: 100% !important;
      padding: 6px;
      background: url(/images/background/select-icon.png) no-repeat right;
      -webkit-appearance: none;

      background-position-x: calc(100% - 10px);
    }

    .register-activity-item-content-btn {
      min-width: 200px !important;
      height: auto !important;
      padding: 5px 32px;
      border: 1px solid var(--cta-bg-color) !important;
      border-radius: 16px !important;

      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 22px !important;
      text-align: center !important;
      letter-spacing: -0.5px !important;

      &.valid {
        background-color: var(--cta-bg-color) !important;
        color: #FFFFFF !important;
      }

      &.invalid {
        border: 1px solid var(--cta-bg-color) !important;
        background-color: #FFFFFF !important;
        color: var(--cta-bg-color) !important;
      }

      &.draft {
        background-color: #FFFFFF !important;
        color: var(--cta-bg-color) !important;
      }
    }

    .register-activity-item-content-delect-container {
      padding-bottom: 10px;
      border-bottom: 1px solid #000000;
    }

    .register-activity-item-content-sheet {
      width: 25px;
      height: 15px;
      cursor: pointer;

      &.sheet-bleu {
        border: 1px solid var(--cta-bg-color);
        color: var(--cta-bg-color);

        &.active {
          background-color: var(--cta-bg-color);
        }
      }

      &.sheet-ciel {
        border: 1px solid #649AD3;
        color: #649AD3;

        &.active {
          background-color: #649AD3;
        }
      }

      &.sheet-orange {
        border: 1px solid #E8973E;
        color: #E8973E;

        &.active {
          background-color: #E8973E;
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
</style>

<style lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.register-activity-item-content-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.register-activity-item-content-desc-red {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #EB5757;
}

.register-activity-item-content-square {
  width: 25px;
  height: 15px;
  left: 669px;
  top: 268px;

  &.bleu {
    background: var(--cta-bg-color);
    color: var(--cta-bg-color);
  }

  &.ciel {
    background: #649AD3;
    color: #649AD3;
  }

  &.orange {
    background: #E8973E;
    color: #E8973E;
  }
}
</style>
