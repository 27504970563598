<template>
  <div
    class="tw-grid tw-grid-cols-8 tw-gap-2 tw-border-b tw-pb-4 tw-border-dashed tw-border-black tw-place-content-center"
  >
    <div class="tw-relative tw-col-span-4">
      <div class="tw-flex tw-items-center tw-justify-start tw-space-x-2">
        <div class="tw-flex tw-justify-center tw-items-center" style="font-weight: bold !important;">
          <AvatarComponent no-tooltip :label="`${member.firstName} ${member.lastName}`">
            <template #icon v-if="member.isLeader"><crown-icon/></template>
            <template #icon v-else><div style="width: 1rem;"></div></template>
          </AvatarComponent>
        </div>
        <div class="tw-relative">
          <div class="tw-font-bold tw-text-gray-900 tw-text-xs">
            {{ 
              `${member.firstName} ${member.lastName}`.length > 30 
                ? `${member.firstName} ${member.lastName}`.substring(0,30) + '...' 
                : `${member.firstName} ${member.lastName}`
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="tw-relative tw-flex tw-items-center tw-justify-center tw-col-span-2 tw-font-bold">
      <template v-if="member.clientsCount">
        <v-tooltip right content-class="tw-bg-white tw-border tw-border-black">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ member.clientsCount }}
              {{ member.clientsCount > 1 ? 'clients' : 'client' }}
            </span>
          </template>
          <div class="tw-flex tw-flex-col tw-text-black tw-text-xs">
            <span v-for="(tradeName, index) in member.clientsTradeNames" :key="index">{{ tradeName }}</span>
          </div>
        </v-tooltip>
      </template>
      <template v-else> - </template>
    </div>
    <div class="tw-relative tw-flex tw-justify-end tw-items-center tw-col-span-2">
      <div class="tw-relative tw-inline-block tw-text-left">
        <div class="tw-w-100 tw-flex tw-justify-center tw-items-center tooltip">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
          </svg>
          <div class="tooltiptext">
            <ul>
              <li v-if="!member.isLeader" class="tw-cursor-pointer" @click="setAsLeader">Désigner chef</li>
              <li class="tw-cursor-pointer" @click="viewMember">Voir</li>
              <li class="tw-cursor-pointer" @click="detachMember">Supprimer</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <TeamMemberPopup
      v-if="canOpenTeamMemberUpdatePopup"
      :openPopup="canOpenTeamMemberUpdatePopup"
      :item="member"
      @close="canOpenTeamMemberUpdatePopup = false"
      @updated="$emit('updated')"
    />
  </div>
</template>
<script>
import CrownIcon from '@/components/icons/CrownIcon.vue';
import AvatarComponent from "../../../../../../components/common/Avatar.vue";
import TeamMemberPopup from "@/features/company/components/Expert/TeamMemberPopup.vue";
import { setLeadForTeamMember, deleteTeamMember } from "@/features/company/services/appApi";

export default {
  components: { AvatarComponent, CrownIcon, TeamMemberPopup },
  name: "TeamDetailsItemComponent",
  props: {
    member: {
      type: Object,
      default: () => ({}),
    },
    teamId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showActions: false,
      show: false,
      canOpenTeamMemberUpdatePopup: false,
    };
  },
  methods: {
    setAsLeader() {
      setLeadForTeamMember({ teamId: this.teamId, collaboratorId: this.member.id })
        .then(({ data: { message } }) => {
          this.$snackbar.showAlertMessage({ message, type: "success" });
          this.$emit("leadUpdated", { teamId: this.teamId, collaboratorId: this.member.id });
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({ 
            message: "Une erreur est survenue lors de la mise à jour",
            type: "error"
          });
        });
    },
    viewMember() {
      this.canOpenTeamMemberUpdatePopup = true;
    },
    detachMember() {
      deleteTeamMember(this.teamId, this.member.id)
        .then(({ data: { message } }) => {
          this.$snackbar.showAlertMessage({ message, type: "success" });
          this.$emit("deleted", { teamId: this.teamId, collaboratorId: this.member.id });
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({ 
            message: "Une erreur est survenue lors de la mise à jour",
            type: "error"
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
input:checked + svg {
  display: block;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  width: max-content;
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: -6px;
  right: 23px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>
