<template>
  <div id="questionnaire-empty">
    <v-btn class="tw-float-left tw-m-0" elevation="1" text rounded large @click="$router.push({ name: dashboardName })">Revenir au Dashboard</v-btn>
    <div class="tw-flex tw-justify-center tw-align-center tw-w-full">
      <img :src="EmptyImg" alt="invitation" />
    </div>
  </div>
</template>
<script>
export default {
  name: "ValiderComponent",
  computed: {
    EmptyImg: function () {
      return require("@/assets/images/icons/bg_invitation.svg");
    },
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  },
};
</script>
