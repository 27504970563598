<template>
  <div id="collaborateurs-add">
    <div
      class="tw-flex tw-flex-col tw-align-start"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Mon premier registre de traitement"
      />
    </div>

      <div
          class="tw-flex tw-flex-col tw-align-start tw-justify-between"
      >
        <h3 class="tw-mt-4 tw-text-justify tw-text-xs md:tw-w-9/12">
          Le registre de traitement est destiné à recenser les traitements de données personnelles mis
          en œuvre dans votre entreprise en tant que responsable de traitement.
          Centralisé et régulièrement mis à jour, il vous permet de répondre à l’obligation
          de tenir un registre prévue par le RGPD.
          <br />
          <br />
          Vous n’avez pas encore effectué la cartographie de l’écosystème de la donnée personnelle de personnes physiques que traite votre entreprise. Pas de souci, vous pouvez encore le faire.
        </h3>
        <h3 class="tw-mt-2 tw-text-justify tw-text-xs tw-font-bold md:tw-w-9/12">
          Voulez-vous lancer la cartographie de l’écosystème de la donnée de votre entreprise ?        
        </h3>
      </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
export default {
  name: "AddGuideComponent",
  components: {EntrepriseHeaderBlockTextComponent},
  data() {
    return {

    };
  },
  methods: {
    submit(type) {
      if (type === 'link') {
        this.$router.push({ name: this.linkRoute });
      }
      else {
        this.$router.push({ name: this.buttonRoute });
      }
    }
  },
};
</script>
