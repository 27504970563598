<template>
  <v-container class="tw-pt-0 tw-mt-0">

    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <v-row class="tw-ml-0">
        <router-link :to="{ name: 'LoginPage' }">
          <RetourButtonComponent />
        </router-link>
      </v-row>
      <div class="tw-ml-0">
        <TitleComponent class="public-title-auth tw-mt-8" text="Mot de passe oublié ?" style="letter-spacing: -0.5px" />
      </div>
      <div class="tw-ml-0">
        <SignupSecondaryTextComponent class="tw-mt-4 public-title">
          Saisissez l’adresse e-mail de votre compte pour<br />
          redéfinir votre mot de passe :
        </SignupSecondaryTextComponent>
      </div>
      <v-row>
        <v-col lg="5" md="12" sm="12" xs="12">
          <div class="split tw-ml-0 tw-mt-6"></div>
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider> -->
      <div>
        <v-form ref="form" @submit="onSubmit" v-model="formValid" class="tw-mt-10">
          <v-row class="tw-mb-24">
            <v-col lg="3" md="9" cols="11">
              <TextFieldComponent class="input_email" :rules="rules.email" v-model="form.email" label="Adresse e-mail"
                append-icon="mdi-account-outline" />
            </v-col>
            <v-col class="tw-flex tw-justify-center tw-items-center" lg="2" md="3" cols="12">
              <PrimaryButtonComponent :style="{
                backgroundColor: `${referer.theme.ctaBgColor} !important`,
                color: `${referer.theme.ctaTextColor} !important`
              }" class="tw-rounded-lg" :loading="isSendingForm"
                :disabled="!formValid || isSendingForm" type="submit">{{ text }}
              </PrimaryButtonComponent>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <label v-if="send" class="public-title-renv">Un email vous permettant de redéfinir votre mot de passe<br />
        vous a été envoyé à l’adresse ci-dessus.</label>
    </div>
  </v-container>
</template>
<script>
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import TitleComponent from "../../../components/common/TitleComponent";
import SignupSecondaryTextComponent from "../../auth/components/SignupSecondaryTextComponent";
import { validationRules } from "../../../utils/validation-rules";
import { sendResetPasswordEmail } from "@/features/auth/services/appApi";
export default {
  name: "ResetPassword",
  components: {
    TitleComponent,
    SignupSecondaryTextComponent,
    TextFieldComponent,
    RetourButtonComponent,
    PrimaryButtonComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      send: false,
      text: "Envoyer",
      formValid: false,
      formSent: false,
      isSendingForm: false,
      errors: {
        email: "",
      },
      form: {
        email: "",
      },
      rules: {
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
      },
    };
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;

      sendResetPasswordEmail(this.form)
        .then((response) => {
          this.isSendingForm = false;
          this.formSent = true;
          this.send = true;
          this.text = "Renvoyer";
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: response.data.message[0],
            type: "error",
          });
          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
          }
          this.$refs.form.validate();
        });
    },
  },
};
</script>
<style>
.split {
  height: 1px;
  width: 100%;
  background-color: #52575c;
}
</style>
