<template>
  <section :style="{ 'background-color': `${referer.theme.bgColor2} !important` }" id="SubMenuSection">
    <div :style="{ 'background-color': referer.theme.bgColor1 }" id="subMenuPurple">
      <v-container class="tw-m-0">
        <div class="tw-mx-auto inner-containner">
          <div class="tw-flex tw-items-start list-padding">
            <div class="tw-w-7/12 list-left">
              <ul>
                <li class="subsection-li">De fil en aiguille,</li>
                <li class="subsection-li">{{referer.title}} vous accompagne.</li>
                <li :style="{'color': referer.theme.textColor3}" class="subsection-li active">
                  {{referer.title}}, ficelé par des experts.
                </li>
              </ul>
            </div>
            <div class="tw-w-5/12 list-right">
              <ul>
                <li>
                  <router-link
                    to="#"
                    tag="a"
                    class="router-nav"
                    v-on:click.native="openMentions"
                    >Mentions légales</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="#"
                    tag="a"
                    class="router-nav"
                    v-on:click.native="openPopupHandler"
                    >Conditions Générales d’Utilisation</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="#"
                    tag="a"
                    class="router-nav"
                    v-on:click.native="openPolitique"
                    >Politique de confidentialité</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <CGUPopup @close="closePopup" :openPopup="openPopup" />
    <MentionsLegalesPopup
      @close="closeMentions"
      :openPopup="openPopupMentions"
    />
    <PolitiquePopup @close="closePolitique" :openPopup="openPopupPolitique" />
  </section>
</template>
<script>
import CGUPopup from "@/components/layout/landing/CGUPopup";
import MentionsLegalesPopup from "@/components/layout/landing/MentionsLegalesPopup";
import PolitiquePopup from "@/components/layout/landing/PolitiquePopup";
export default {
  name: "SubMenuSection",
  components: { PolitiquePopup, MentionsLegalesPopup, CGUPopup },
  data() {
    return {
      openPopup: false,
      openPopupMentions: false,
      openPopupPolitique: false,
    };
  },
  methods: {
    openPopupHandler() {
      this.openPopup = true;
    },
    closePopup() {
      this.openPopup = false;
    },
    openMentions() {
      this.openPopupMentions = true;
    },
    closeMentions() {
      this.openPopupMentions = false;
    },
    openPolitique() {
      this.openPopupPolitique = true;
    },
    closePolitique() {
      this.openPopupPolitique = false;
    },
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  }
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/vutify-theme/variables";
.subsection-li {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 84px;
  color: #ffffff;

  @media (max-width: $breakpoint-tablet) {
    font-size: 24px;
  }
}

.subsection-li.active {
  color: #ffce73;

  @media (max-width: $breakpoint-tablet) {
    font-size: 24px;
  }
}
</style>
