<template>
  <div>
    <EntrepriseHeaderBlockTextComponent 
      class="tw-mt-24"
      title="Créer un compte client"
      description="Renseignez les informations de votre client afin de créer son compte Viqtor®."
    />

    <div 
      class="tw-mt-9 tw-mb-16 tw-pt-5 tw-px-10 tw-pb-12 tw-rounded-3xl"
      style="width: fit-content; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);"
    >
      <span>Compte client</span>

      <div class="tw-mt-5 tw-flex tw-space-x-8">
        <div>
          <span :style="{ color: `${referer.theme.ctaBgColor}` }">Sélectionnez la taille de la structure</span>
          
          <div class="tw-mt-2 tw-grid tw-grid-cols-2 tw-gap-x-3 tw-gap-y-2" style="width: fit-content;">
            <div
              v-for="(plan, index) in plans"
              :key="index"
              class="tw-rounded-xl tw-py-2 tw-pl-4 tw-cursor-pointer"
              style="width: 200px;"
              :style="{
                border: `1px solid ${referer.theme.ctaBgColor}`,
                backgroundColor: selectedPlan && selectedPlan.id === plan.id ? referer.theme.ctaBgColor : 'transparent',
                color: selectedPlan && selectedPlan.id === plan.id ? '#fff' : '#25282B'
              }"
              @click="selectedPlan = plan"
            >
              <span class="tw-text-sm tw-font-medium">{{ plan.title }}</span>
            </div>
          </div>
        </div>

        <div v-if="selectedPlan">
          <span :style="{ color: `${referer.theme.ctaBgColor}` }">Tarif</span>

          <div 
            class="tw-text-white tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-xl tw-mt-2"
            style="height: 200px; width: 300px;"
            :style="{ backgroundColor: `${referer.theme.ctaBgColor}` }"
          >
            <span class="tw-font-medium">{{ selectedPlan.title }}</span>
            <img class="tw-mt-3" :src="selectedPlanImage" alt="plan" />
            <span class="tw-mt-3 tw-leading-tight tw-text-3xl">{{ selectedPlan.price }} HT €</span>
            <span class="tw-text-xs tw-font-light">Engagement {{ selectedPlan.engagement }}</span>
          </div>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-items-center tw-space-x-4">
        <v-btn
          elevation="1" text rounded large
          class="tw-mt-14"
          :style="{ border: `1px solid ${referer.theme.ctaBgColor}`, color: `${referer.theme.ctaBgColor}` }"
          @click="returnHandler"
        ><span class="tw-px-6">Retour</span></v-btn>
        <v-btn
          elevation="1" text rounded large
          class="tw-mt-14 tw-text-white"
          :style="{ backgroundColor: `${!selectedPlan ? '#C4C4C4' : referer.theme.ctaBgColor}` }"
          @click="submitForm"
          :loading="isLoading"
          :disabled="!selectedPlan"
        ><span class="tw-px-6">Suivant</span></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { createExpertClient } from "@/features/company/services/appApi";
import { getPlansByParams } from "@/features/auth/services/appApi";

export default {
  name: 'NewExpertClientSubsciptionSelection',
  components: {
    EntrepriseHeaderBlockTextComponent
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      plans: [],
      selectedPlan: null
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    selectedPlanImage() {
      return require('@/assets/images/salaries/expert-' + this.selectedPlan.img);
    }
  },
  mounted() {
    if (!this.form.tradeName) this.$router.push({ name: 'Company.ExpertClientAddNew' });
    // const engagement = this.user.workInCompany.planPrice.engagement;
    this.getPlansByParams(12);
  },
  methods: {
    returnHandler() {
      this.$router.push({ name: 'Company.ExpertClientAddNew' });
    },
    submitForm() {
      this.isLoading = true;
      createExpertClient({ ...this.form, planPriceId: this.selectedPlan.id })
        .then(() => {
          this.$router.push({ 
            name: 'Company.ExpertClientAddNew.Confirmation', 
            params: { tradeName: this.form.tradeName }
          })
        })
        .catch(error => {
          console.log(error)
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la création du compte client.",
            type: "error",
          });
        })
        .finally(() => this.isLoading = false);
    },
    getPlansByParams(engagement) {
      getPlansByParams({ regulation: "monthly", engagement })
        .then(({ data }) => {
          this.plans = data.data.map(plan => ({
            id: plan.id,
            title: plan.plan.salaryRange + " salariés",
            img: plan.plan.img,
            price: plan.price,
            engagement: "mensuel"
          }));
        })
        .catch((err) => {
          console.log(err)
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la récupération des plans.",
            type: "error",
          });
        });
    },
  }
}
</script>