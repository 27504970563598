<template>
  <div id="collaborateurs-add">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Invitez vos collaborateurs à rejoindre viqtor"
        description=""
      />
    </div>
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
       <h3 class="tw-mt-4">Remplacer les informations de votre sous-traitant manuellement :</h3>
    </div>
    <CollaborateursFormAddComponent />
    <img src="@/assets/images/icons/hr_ou.svg" class="tw-mt-4 hr_img" />
    <h1 class="title-import-two-steps">Enregistrez toute votre liste en 2 étapes :</h1>
    <div class="collaborators-row">
      <div class="collaborators-col-8">
        <CollaborateurImportBlockTextComponent
          title="1. Téléchargez d’abord le modèle"
          description="Pour importer facilement la liste de vos collaborateurs, téléchargez ce modèle."
        />
        <v-btn
          elevation="0"
          text
          rounded
          large
          @click="downloadCSVData"
          download
          class="tw-text-white"
          outlined
        >
          Télécharger le modèle
          <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.4375 3.66667H6.1875V0H2.8125V3.66667H0.5625L4.5 8.55556L8.4375 3.66667ZM0 9.77778H9V11H0V9.77778Z" :fill="referer.theme.ctaBgColor"/>
          </svg>
        </v-btn>
        <p>
        Remplissez le fichier sans modifier l’ordre des colonnes.<br/><br/>
        Ce fichier est un Excel .csv comprenant 5 colonnes, dans l’ordre : le numéro de SIREN,
        la civilité, le prénom et le nom du contact ainsi que son adresse email.<br/><br/>
        <b>Vous pouvez importer jusqu’à 50 sous-traitants par fichier.</b>
        </p>
      </div>
      <div class="collaborators-col-4">
        <CollaborateurImportBlockTextComponent
          title="2. Importez votre liste de collaborateurs"
          description="Une fois le fichier rempli, importez-le ici."
        />

        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          useCustomSlot=true
          :options="dropzoneOptions"
          class="upload-file"
          :includeStyling="false"
          @vdropzone-removed-file="fileRemoved" @vdropzone-file-added="fileAdded"
          >
          <div class="dropzone-custom-content tw-flex tw-items-center tw-flex-col">
            <img src="@/assets/images/icons/upload.svg" />
            <span class="title-upload-drpzone">Glissez et déposez ici votre liste de collaborateurs</span>
            <v-btn
              elevation="1"
              text
              rounded
              large
              :loading="isUploadModel"
              @click="uploadModel"
              class="tw-text-white"
            >
              Parcourir
            </v-btn>
          </div>
        </vue-dropzone>
        <v-btn
          elevation="1"
          text
          rounded
          large
          :loading="isImportFile"
          @click="importFile"
          class="tw-text-white"
        >
          Continuer
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import CollaborateurImportBlockTextComponent from "@/components/common/CollaborateurImportBlockTextComponent";
import CollaborateursFormAddComponent from "@/features/company/components/Collaborateurs/CollaboratorFormAddComponent";
import { importCollaborators } from '../../../services/appApi';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: "AddCollaborateursPage",
  components: {
    CollaborateurImportBlockTextComponent,
    EntrepriseHeaderBlockTextComponent,
    CollaborateursFormAddComponent,
    vueDropzone: vue2Dropzone,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
		fileInputText() {
			return this.file ? "" : "AUCUN FICHIER CHOISI"
		},
		fileLInk() {
      return require("@/assets/csv/collaborators.csv");
		},
	},
  data() {
    return {
      isUploadModel: false,
      isImportFile: false,
      file: null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 50,
        maxFiles:1,
        dictRemoveFile:`<div class="tw-flex tw-justify-center tw-py-3">
                            <svg class="tw-flex tw-justify-center" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.41 1.28642L10 0L5.705 3.91858L1.41 0L0 1.28642L4.295 5.205L0 9.12358L1.41 10.41L5.705 6.49142L10 10.41L11.41 9.12358L7.115 5.205L11.41 1.28642Z" fill="#2A353A"/>
                            </svg>
                          </div>`,
        addRemoveLinks: true,
        autoProcessQueue: false,
        uploadMultiple:1
      },
    };
  },
  methods: {
    onFileChanged(event) {
			this.file = event.target.files[0]
		},
    fileRemoved(){
      this.file = null;
    },
    fileAdded(file){
      if(this.file){
        this.$refs.myVueDropzone.removeFile(this.file)
      }
      this.file = file;
    },
    importFile() {
      this.isImportFile = true;
      const data = new FormData();
			data.append('file', this.file);
      importCollaborators(data).then((res) => {
        this.$snackbar.showAlertMessage({
          message: res.data.message,
          type: "success",
        });
        this.$router.push({
          name: "Company.MonEntreprisePage.Collaborateurs.invitation",
        });
      }).catch(({ response }) => {
        if(response.status === 500){
            this.$snackbar.showAlertMessage({
              message: "Ficher importé",
              type: "success",
            });
            this.$router.push({
              name: "Company.MonEntreprisePage.Collaborateurs.invitation",
            });
        }else {
          this.$notify({
              group: "foo",
              type: "error",
              title: "Attention",
              text: "Erreur de validation de données",
            });
        }
      }).finally(()=>{
        this.isImportFile = false;
      });
    },
    downloadCSVData () {
        var str = "gender;firstName;lastName;email;service";
        var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement('a');
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'viqtor-collaborateurs-model.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  },
};
</script>
