<template>
  <div id="gouvernance-add-container">
    <div class="tw-flex tw-flex-col tw-align-start">
      <EntrepriseHeaderBlockTextComponent
        title="Ma gouvernance, responsabilité<br> et engagement de la Direction de l’entreprise" />
    </div>
    <div>
      <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="3" />
    </div>
    <div class="collaborator-form-container">
      <SignupSecondaryTextComponent class="public-text-register">
        <b>La liste des collaborateurs de l’entreprise.</b>
      </SignupSecondaryTextComponent>
      <div class="collaborators-row">
        <v-row>
          <v-col class="my-4" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Collaborateurs</th>
                    <th>Fonction</th>
                    <th>Email</th>
                    <th style="text-align: center">Statut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td> {{
                      item.firstName ? item.firstName + " " + item.lastName :
                        item.collaborateur.firstName + "" + item.collaborateur.lastName
                    }} </td>
                    <td> {{ item.function ? item.function : item.collaborateur.function }} </td>
                    <td> {{ item.collaborateur.email }} </td>
                    <td v-html="handleStatusSheet(item)"></td>

                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import { getNewCollaborators } from "@/features/company/services/appApi";

export default {
  name: "NewGouvernanceStepTwoPage",
  components: {
    SignupSecondaryTextComponent,
    StepsComponent,
    EntrepriseHeaderBlockTextComponent,
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      ids: [],
      data: []
    };
  },
  mounted() {
    if (this.IsJsonString(this.$route.params.ids) && Array.isArray(JSON.parse(this.$route.params.ids))) {
      let ids = JSON.parse(this.$route.params.ids);
      if (this.onlyNumbers(ids)) {
        this.ids = ids;
        this.fetchGovernance();
      } else {
        this.$router.replace({ name: 'Gouvernance.Add.Guide' });
      }
    }
    else {
      this.$router.replace({ name: 'Gouvernance.Add.Guide' });
    }
  },
  methods: {
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    onlyNumbers(array) {
      return array.every(element => {
        return typeof element === 'number';
      });
    },
    fetchGovernance() {
      getNewCollaborators('governance', this.$route.params.ids)
        .then(response => {
          this.data = response.data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    handleStatusSheet(item) {
      if (item.status === 2) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background:#27AE60">
                    OK
                  </div>
                </div>`;
      }
      if (item.status === 1) {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white" style="background: #E8973E">
                    LU
                  </div>
                </div>`;
      }
      else {
        return `<div class="tw-flex tw-justify-center tw-items-center">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center"
                       style="border: 1px solid ${this.referer.theme.ctaBgColor}; color: ${this.referer.theme.ctaBgColor}"
                  >
                    NON LU
                  </div>
                </div>`;
      }
    },
    submit() {
      this.$router.push({ name: 'EcoSystem.Add.Step2.EMAIL' });
    },
  },
};
</script>
