<template>
  <v-dialog
    v-model="open"
    transition="dialog-bottom-transition"
    id="addTeamPopup"
    persistent
    width="60%"
    content-class="rounded-xl"
  >
    <div class="tw-flex tw-justify-end tw-pr-2">
      <svg @click="$emit('close')" class="tw-cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.3242 0.5L0.824219 15" stroke="black"/>
        <path d="M0.824218 0.499999L15.3242 15" stroke="black"/>
      </svg>
    </div>
    <div class="tw-py-2 tw-px-9 tw-flex tw-flex-col tw-justify-between tw-h-full">
      <EntrepriseHeaderBlockTextComponent
        :title='`Modifier l’équipe "${team.name}"`'
        description="Vous pouvez modifier votre équipe en complétant les informations ci-dessous."
      />

      <div class="tw-mt-6 tw-flex tw-items-center tw-justify-between tw-space-x-10">
        <div class="tw-flex tw-items-center tw-justify-center tw-mt-2">
          <TextFieldComponent
            v-model="form.name"
            label="Nommer l'équipe"
            :hide-details="!errors.name.length"
            :error="errors.name.length > 0"
            :error-messages="errors.name"
            style="font-size: 14px !important"
            persistent-placeholder
            placeholder="Ex: Equipe de développement"
            custom-class="custom-text-field small-placeholder"
          />
        </div>
        <div class="tw-flex tw-items-center tw-justify-center tw-mt-2">
          <TextFieldComponent
            v-model="search"
            label="Recherche"
            style="font-size: 14px !important"
            persistent-placeholder
            placeholder="Ex: Jean Dupont"
            custom-class="custom-text-field small-placeholder"
          />
        </div>
      </div>

      <v-simple-table height="300px" class="tw-overflow-auto filter tw-border-2 tw-border-b-2 tw-border-t-0 !tw-rounded-lg border-primary tw-mt-3">
        <template v-slot:default>
          <thead 
            class="!tw-bottom-b-0 !tw-text-white"
            style="background-color: var(--cta-bg-color); color: white !important"
          >
            <tr class="!tw-text-white">
              <th>
                <div @click="addToOrder('firstName')" class="tw-cursor-pointer tw-flex">
                  <span class="!tw-text-white">Nom</span>
                  <img v-if="orderby == 'firstName' && orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                  <img v-else-if="orderby == 'firstName' && orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                  <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                </div>
              </th>
              <th>
                <div @click="addToOrder('actualTeams')" class="tw-cursor-pointer tw-flex">
                  <span class="!tw-text-white">Equipe actuelle</span>
                  <img v-if="orderby == 'actualTeams' && orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                  <img v-else-if="orderby == 'actualTeams' && orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                  <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                </div>
              </th>
              <th>
                <div @click="addToOrder('joinStatus')" class="tw-cursor-pointer tw-flex tw-justify-center">
                  <span class="!tw-text-white">Ajouter à cette équipe</span>
                  <img v-if="orderby == 'joinStatus' && orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                  <img v-else-if="orderby == 'joinStatus' && orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                  <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                </div>
              </th>

              <th>
                <div @click="addToOrder('leadStatus')" class="tw-cursor-pointer tw-flex tw-justify-center">
                  <span class="!tw-text-white">Nommer chef d’équipe</span>
                  <img v-if="orderby == 'leadStatus' && orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                  <img v-else-if="orderby == 'leadStatus' && orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                  <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                </div>
              </th>
            </tr>
          </thead>
          <template v-if="filteredCollaborators.length > 0">
            <tbody>
              <tr v-for="collaborator in filteredCollaborators" :key="collaborator.id">
                <td class="tw-py-3" style="padding: 0 !important;">
                  <div class="tw-flex tw-items-center tw-space-x-4">
                    <AvatarComponent :label="collaborator.fullname" />
                    <span class="tw-text-xs tw-font-medium">
                      {{ collaborator.fullname.length > 30 ? collaborator.fullname.substring(0,30) + '...' : collaborator.fullname }}
                    </span>
                  </div>
                </td>
                <td class="tw-py-3 tw-pl-4" v-if="collaborator.teams.length == 0">-</td>
                <td class="tw-py-3" v-if="collaborator.teams.length == 1">
                  <BadgeComponent :label="collaborator.teams[0].name">
                    <template #icon v-if="collaborator.teams[0].isLeader"><crown-icon/></template>
                  </BadgeComponent>
                </td>
                <td class="tw-py-3 tw-ml-4" v-if="collaborator.teams.length > 1">
                  <v-tooltip right content-class="tw-bg-white tw-border tw-border-black">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs" v-on="on"
                        style="width: 7rem; display: block;"
                        class="tw-text-white tw-text-center tw-bg-[#2A353A] tw-py-1 tw-px-4 tw-text-xs tw-font-medium tw-rounded-full"
                      >{{ collaborator.teams.length }} équipes</div>
                    </template>
                    <div class="tw-flex tw-flex-col tw-text-black tw-text-xs">
                      <span v-for="team in collaborator.teams" :key="team.id">{{ team.name }}</span>
                    </div>
                  </v-tooltip>
                </td>
                <td class="tw-py-3">
                  <label class="tw-relative tw-flex tw-items-center tw-justify-center">
                    <div class="tw-flex tw-h-6 tw-w-6 tw-items-start tw-justify-start tw-border tw-border-gray-900 tw-focus-within:border-gray-500">
                      <input
                        type="checkbox"
                        :checked="collaborator.isJoined"
                        v-model="collaborator.isJoined"
                        @change="selectCollaboratorHandler(collaborator.id)"
                        class="tw-absolute tw-opacity-0 tw-focus:outline-none"
                      />
                      <svg class="tw-pointer-events-none tw-hidden tw-h-[18px] tw-w-[18px] tw-fill-current tw-text-gray-800" viewBox="0 0 20 20">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                      </svg>
                    </div>
                  </label>
                </td>
                <td class="tw-py-3">
                  <label class="tw-relative tw-flex tw-items-center tw-justify-center">
                    <div class="tw-flex tw-h-6 tw-w-6 tw-items-start tw-justify-start tw-border tw-border-gray-900 tw-focus-within:border-gray-500">
                      <input
                        type="checkbox"
                        :checked="collaborator.isLeader"
                        v-model="collaborator.isLeader"
                        :disabled="!collaborator.isJoined"
                        @change="selectCollaboratorAsLeadHandler(collaborator.id)"
                        class="tw-absolute tw-opacity-0 tw-focus:outline-none"
                      />
                      <svg class="tw-pointer-events-none tw-hidden tw-h-[18px] tw-w-[18px] tw-fill-current tw-text-gray-800" viewBox="0 0 20 20">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"/>
                      </svg>
                    </div>
                  </label>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-if="filteredCollaborators.length == 0">
            <tbody>
              <tr>
                <td colspan="100%" class="text-center tw-py-2">
                  Pas de données disponibles
                </td>
              </tr>
            </tbody>
          </template>
        </template>
      </v-simple-table>

      <div class="tw-mt-12 tw-flex tw-justify-between tw-items-center">
        <v-btn
          elevation="0"
          text
          rounded
          large
          class="tw-text-white tw-bg-red-600 tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer"
          :loading="isDeleting"
          @click="deleteTeamHandler"
        >
          <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4"
            >Supprimer</span
          >
        </v-btn>
        <div class="tw-flex tw-justify-center tw-items-center tw-space-x-8">
          <v-btn
            elevation="0"
            text
            rounded
            large
            class="dynamic-text tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer"
            :style="{
              border: `1px solid ${referrer.theme.ctaBgColor}`,
            }"
            @click="$emit('close')"
          >
            <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4"
              >Annuler</span
            >
          </v-btn>
          <v-btn
            elevation="0"
            text
            rounded
            large
            class="tw-text-white tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer"
            :style="{
              backgroundColor: `${referrer.theme.ctaBgColor}`,
            }"
            :loading="isUpdating"
            @click="updateTeamHandler"
          >
            <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4"
              >Valider</span
            >
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import TextFieldComponent from "@/components/common/TextFieldCompanyComponent";
import { mapState } from "vuex";
import CrownIcon from "@/components/icons/CrownIcon.vue";
import { getTeamMembersWithoutPagination, updateTeam, deleteTeam } from "@/features/company/services/appApi";
export default {
  name: "AddTeamPopupComponent",
  components: {
    EntrepriseHeaderBlockTextComponent,
    TextFieldComponent,
    CrownIcon,
  },
  props: {
    team: {
      type: Object,
      default: () => ({}),
    },
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      form: {
        id: 0,
        name: '',
        collaboratorsIds: [],
        collaboratorsToBeLeadsIds: [],
      },
      errors: {
        name: [],
      },
      search: "",
      isLoading: false,
      isUpdating: false,
      isDeleting: false,
      collaborators: [],
      filteredCollaborators: [],
      orderby: "",
      orderType: "",
    };
  },
  computed: {
    ...mapState(["referrer"]),
  },
  mounted() {
    this.getCollaborators();
    this.form = {
      id: this.team.id,
      name: this.team.name,
      collaboratorsIds: this.team.members.map(({ id }) => id),
      collaboratorsToBeLeadsIds: this.team.members
        .filter(({ isLeader }) => isLeader)
        .map(({ id }) => id),
    }
  },
  watch: {
    canOpen: {
      handler(val) {
        this.open = val;
      },
      immediate: true,
    },
    form: {
      handler() { this.errors.name = []; },
      deep: true,
    },
    search: {
      handler(search) {
        this.filteredCollaborators = this.collaborators.filter((collaborator) => {
          return collaborator.fullname.toLowerCase().includes(search.toLowerCase());
        });
      },
      immediate: true,
    },
  },
  methods: {
    addToOrder(field) {
      this.orderby = field;
      this.orderType = this.orderType === "asc" ? "desc" : "asc";

      if (field === "firstName")
        this.filteredCollaborators = this.filteredCollaborators.sort((a, b) => {
          if (this.orderType === "asc") {
            return a.fullname.localeCompare(b.fullname);
          } else {
            return b.fullname.localeCompare(a.fullname);
          }
        });
      if (field === "actualTeams")
        this.filteredCollaborators = this.filteredCollaborators.sort((a, b) => {
          if (this.orderType === "asc") {
            return a.teams.length - b.teams.length;
          } else {
            return b.teams.length - a.teams.length;
          }
        });
      if (field === "joinStatus")
        this.filteredCollaborators = this.filteredCollaborators.sort((a, b) => {
          if (this.orderType === "asc") {
            return a.isJoined - b.isJoined;
          } else {
            return b.isJoined - a.isJoined;
          }
        });
      if (field === "leadStatus")
        this.filteredCollaborators = this.filteredCollaborators.sort((a, b) => {
          if (this.orderType === "asc") {
            return a.isLeader - b.isLeader;
          } else {
            return b.isLeader - a.isLeader;
          }
        });
    },
    getCollaborators() {
      getTeamMembersWithoutPagination()
        .then(({ data }) => {
          this.collaborators = data.map((collaborator) => {
            return {
              id: collaborator.id,
              fullname: `${collaborator.firstName} ${collaborator.lastName}`,
              isJoined: !!this.form.collaboratorsIds.find(
                (id) => id === collaborator.id
              ),
              isLeader: !!this.form.collaboratorsToBeLeadsIds.find(
                (id) => id === collaborator.id
              ),
              teams: collaborator.expertTeams
                ? collaborator.expertTeams.map(({ team, isLead }) => ({ name: team.name, isLeader: isLead }))
                : [],
            };
          });
          this.filteredCollaborators = this.collaborators;
        })
        .catch(error => console.log(error))
    },
    selectCollaboratorHandler(collaboratorId) {
      if (this.form.collaboratorsIds.includes(collaboratorId)) {
        this.form.collaboratorsIds = this.form.collaboratorsIds.filter((id) => id !== collaboratorId);
        this.form.collaboratorsToBeLeadsIds = this.form.collaboratorsToBeLeadsIds.filter((id) => id !== collaboratorId);
      } else {
        this.form.collaboratorsIds.push(collaboratorId);
      }
      this.filteredCollaborators = this.filteredCollaborators.map((collaborator) => {
        if (collaborator.id === collaboratorId) {
          collaborator.isJoined = !collaborator.isJoined;
          if (!collaborator.isJoined) collaborator.isLeader = false;
        }
        return collaborator;
      });
      this.collaborators = this.collaborators.map((collaborator) => {
        if (collaborator.id === collaboratorId) {
          collaborator.isJoined = !collaborator.isJoined;
          if (!collaborator.isJoined) collaborator.isLeader = false;
        }
        return collaborator;
      });
    },
    selectCollaboratorAsLeadHandler(collaboratorId) {
      this.form.collaboratorsToBeLeadsIds = this.form.collaboratorsToBeLeadsIds.includes(collaboratorId)
        ? this.form.collaboratorsToBeLeadsIds.filter((id) => id !== collaboratorId)
        : [collaboratorId];
      this.filteredCollaborators = this.filteredCollaborators.map((collaborator) => {
        collaborator.isLeader = collaborator.id === collaboratorId && !collaborator.isLeader;
        return collaborator;
      });
      this.collaborators = this.collaborators.map((collaborator) => {
        collaborator.isLeader = collaborator.id === collaboratorId && !collaborator.isLeader;
        return collaborator;
      });
    },
    deleteTeamHandler() {
      this.isDeleting = true;
      deleteTeam(this.form.id)
        .then(({ data: { message } }) => {
          this.$snackbar.showAlertMessage({ message, type: "success" });
          this.$emit("close");
          this.$emit("deleted");
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({ 
            message: "Une erreur est survenue lors de la modification de l'équipe",
            type: "error"
          });
        })
        .finally(() => { this.isDeleting = false; });
    },
    updateTeamHandler() {
      this.errors.name = [];
      if (this.form.name.length === 0) {
        this.errors.name = ["Le nom de l'équipe est requis"];
        return;
      }

      this.isUpdating = true;
      updateTeam(this.form.id, this.form)
        .then(({ data: { message } }) => {
          this.$snackbar.showAlertMessage({ message, type: "success" });
          this.$emit("close");
          this.$emit("updated");
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({ 
            message: "Une erreur est survenue lors de la modification de l'équipe",
            type: "error"
          });
        })
        .finally(() => { this.isUpdating = false; });
    },
  },
};
</script>

<style lang="scss">
input:checked + svg { display: block; }
.custom-text-field.small-placeholder input::placeholder { font-size: 12px; }
</style>
