import { render, staticRenderFns } from "./RadioButtonReponseComponent.vue?vue&type=template&id=061dd8ad&scoped=true&"
import script from "./RadioButtonReponseComponent.vue?vue&type=script&lang=js&"
export * from "./RadioButtonReponseComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061dd8ad",
  null
  
)

export default component.exports