<template>
  <v-container class="px-12 overflow-auto">
    <Breadcrumb divider=">"></Breadcrumb>
    <v-row no-gutters class="mb-12 mt-4">
      <v-col cols="12">
        <h1
          class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl"
        >
          Dernière vérification !
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="my-4" cols="12">
        <h1
          class="my-4 tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4"
        >
          Liste des sous-traitants qui vont recevoir l’invitation :
        </h1>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Raison sociale</th>
                <th>Contact</th>
                <th>Soumis par</th>
                <th>Suppression</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in subcontractorsValid" :key="item.name">
                <td>{{ item.socialReason }}</td>
                <td>{{ item.firstName + " " + item.lastName }}</td>
                <td>
                  {{ item.client.firstName + " " + item.client.lastName }}
                </td>
                <td
                  @click="supprimerSubcontractorValid(item)"
                  class="tw-cursor-pointer"
                >
                  <v-icon dark small color="black">mdi-close</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col class="my-4 d-flex d-items-center justify-space-between" cols="12">
        <PrimaryButtonComponent class="return-btn" @click="retour"
          >Retour</PrimaryButtonComponent
        >
        <PrimaryButtonComponent class="validate-btn" @click="valider"
          >Valider</PrimaryButtonComponent
        >
      </v-col>
    </v-row>
    <SubcontractorsLoaderModal
      :count-all="countTotalSubcontractors"
      :count-finished="countFinishedSubcontractors"
      :action="action"
      v-model="importSubcontractorsModal"
      :error="importationError"
      :is-loading="subscribing"
      :log="log"
      :on-close="onCloseImportModal"
    />
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { sendManyInvitation } from "../services/appApi";
import {
  bindEventPusher,
  onChannelSubscriptionError,
  onChannelSubscriptionSucceeded,
  subscribeToChannel,
  unsubscribeFromChannelPusher,
} from "../services/pusherService";
import SubcontractorsLoaderModal from "../components/SubcontractorsLoaderModal";

export default {
  name: "SubContractorInvitationVerification",
  computed: {
    subcontractorsValid() {
      return this.$store.getters.getSubcontractorsValid;
    },
  },
  data() {
    return {
      importSubcontractorsModal: false,
      countTotalSubcontractors: 0,
      countFinishedSubcontractors: 0,
      invalidData: 0,
      subscribing: true,
      log: "",
      // validation or importation
      action: "validation",
      importationError: "",
    };
  },
  components: {
    SubcontractorsLoaderModal,
    Breadcrumb,
    PrimaryButtonComponent,
  },
  mounted() {
    const channelName = `importation-${this.$store.state.user.id}`;
    const channel = subscribeToChannel(channelName);

    onChannelSubscriptionSucceeded(channel, () => {
      console.log("subscription succeeded", channel);

    });

    onChannelSubscriptionError(channel, (error) => {
      this.subscribing = false;
      this.importSubcontractorsModal = false;
      this.$snackbar.showAlertMessage({
        message: "Connexion a echoué",
        type: "error",
      });
      console.log("subscription failed", error);
    });

    bindEventPusher(channel, "error", (error) => {
      unsubscribeFromChannelPusher(channelName);
      this.$snackbar.showAlertMessage({
        message: error.message,
        type: "error",
      });
      this.importationError = error.message;
      this.subscribing = false;
      console.log("error when importing subcontractors");
    });

    /*

    validation events

    */
    bindEventPusher(channel, "start_validation", (data) => {
      this.importSubcontractorsModal = true;
      this.countTotalSubcontractors = data.total;
      this.countFinishedSubcontractors = 0;
      this.action = "validation";
      this.subscribing = false;
      console.log("start_validation");
    });

    bindEventPusher(channel, "valid_subcontractor", ({ data }) => {
      console.log("valid_subcontractor", data);
      this.countFinishedSubcontractors++;
      this.log = `${data.sirenOrRna}, ${data.firstName} ${data.lastName}: Réussi`;
    });

    bindEventPusher(channel, "invalid_subcontractor", ({ data }) => {
      console.log("invalid_subcontractor", data);
      this.countFinishedSubcontractors++;
      this.invalidData++;
      this.log = `${data[0].target.sirenOrRna}, ${data[0].target.firstName} ${data[0].target.lastName}: Echoué`;
    });

    bindEventPusher(channel, "finish_validation", (data) => {
      console.log("finish_validation", data);
      if(this.invalidData > 0) {
        this.$snackbar.showAlertMessage({
          message: "Certains sous-traitant n'ont pas été ajoutés",
          type: "error",
        });
      }
    });

    /*

    importation events

    */
    bindEventPusher(channel, "start_inviting", (data) => {
      this.countTotalSubcontractors = data.total;
      this.countFinishedSubcontractors = 0;
      this.action = "invitation";
      console.log("start_inviting");
    });

    bindEventPusher(channel, "invite_subcontractor", (data) => {
      console.log("invite_subcontractor", data);
      this.countFinishedSubcontractors++;
      this.log = `${data.subcontractor.sirenOrRna}, ${data.subcontractor.firstName} ${data.subcontractor.lastName}: Réussi`;
    });

    bindEventPusher(channel, "finish_inviting", (data) => {
      unsubscribeFromChannelPusher(channelName);
      this.$snackbar.showAlertMessage({
        message: data.message,
        type: "success",
      });
      this.$router.push({
        name: "SubContractorInvitationConfirmation",
      });
      console.log("finish_validation", data);
    });
  },
  methods: {
    onCloseImportModal() {
      this.importSubcontractorsModal = false;
    },
    supprimerSubcontractorValid(item) {
      this.$store.dispatch("supprimerSubcontractorValid", item);
    },
    retour() {
      // this.$router.push({
      // 	name: "SubContractorInvitation",
      // });
      this.$router.go(-1);
    },
    valider() {
      this.isSendingForm = true;
      let data = this.subcontractorsValid.map((invitation) => {
        return {
          gender: invitation.gender,
          firstName: invitation.firstName,
          lastName: invitation.lastName,
          email: invitation.email,
          sirenOrRna: invitation.sirenOrRna,
          socialReason: invitation.socialReason,
        };
      });
      let req = {
        subcontractors: data,
        emailTemplate: this.$store.getters.getEmailTemplate,
      };
      sendManyInvitation(req)
        .catch(({ response }) => {
          response.data.message.forEach((err) => {
            this.$snackbar.showAlertMessage({
              message: err,
              type: "error",
            });
          });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
  },
};
</script>

<style lang="scss">
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.return-btn,
.validate-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: 250px !important;
  margin: 0 12px;
  background: var(--cta-bg-color) !important;
  color: #fff !important;
}
</style>
