<template>
  <v-dialog
      v-if="handelPopup"
      v-model="handelPopup"
      @click:outside="closePopup"
      width="400"
      transition="dialog-bottom-transition"
    >
    <svg @click="closePopup" class="img-close tw-cursor-pointer" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33352 0.211923L5.00009 3.87813L8.66666 0.223033C8.73885 0.149586 8.8255 0.0919159 8.92112 0.05367C9.01674 0.0154241 9.11927 -0.00256573 9.2222 0.000838218C9.42418 0.0139081 9.6145 0.100038 9.75762 0.243146C9.90075 0.386254 9.98689 0.576556 9.99996 0.778519C10.001 0.87782 9.98178 0.97629 9.94358 1.06796C9.90539 1.15962 9.84896 1.24258 9.77774 1.31179L6.10006 5.00022L9.77774 8.68864C9.92218 8.82863 10.0022 9.02194 9.99996 9.22191C9.98689 9.42387 9.90075 9.61418 9.75762 9.75729C9.6145 9.90039 9.42418 9.98652 9.2222 9.99959C9.11927 10.003 9.01674 9.98501 8.92112 9.94676C8.8255 9.90851 8.73885 9.85085 8.66666 9.7774L5.00009 6.1223L1.34464 9.7774C1.27244 9.85085 1.1858 9.90851 1.09017 9.94676C0.99455 9.98501 0.892029 10.003 0.789095 9.99959C0.583345 9.98888 0.388856 9.90234 0.243172 9.75667C0.0974876 9.611 0.0109371 9.41653 0.000227414 9.2108C-0.000777895 9.1115 0.0184003 9.01303 0.0565986 8.92136C0.094797 8.8297 0.151219 8.74674 0.222444 8.67754L3.90012 5.00022L0.211333 1.31179C0.142114 1.24164 0.0877451 1.15827 0.0514738 1.06665C0.0152026 0.975023 -0.00222666 0.877029 0.000227414 0.778519C0.0132986 0.576556 0.0994371 0.386254 0.242559 0.243146C0.385681 0.100038 0.576002 0.0139081 0.777984 0.000838218C0.880117 -0.00401225 0.982154 0.0122271 1.07773 0.0485439C1.17331 0.0848608 1.26039 0.140476 1.33352 0.211923Z" :fill="referer.theme.ctaBgColor"/>
    </svg>
    <div id="deleteDialog">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.906 0C8.93 0 0 8.972 0 20C0 31.028 8.972 40 20 40C31.028 40 40 31.028 40 20C40 8.972 30.986 0 19.906 0ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" :fill="referer.theme.ctaBgColor"/>
      </svg>
      <p class="tw-text-center">Attention, si vous supprimez ce collaborateur, il ne pourra plus vous partager ses listes sous-traitants</p>
      <v-btn
          elevation="1"
          text
          rounded
          large
          :loading="isSendingForm"
          @click="supprimerContact"
          class="tw-text-white tw-cursor-pointer"
        >
          Confirmer
        </v-btn>
      </div>
    </v-dialog>
</template>
<script>
import { deleteCollaborator } from '../../services/appApi';
export default {
  name: "ConfirmeDeletePopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: String,
      default: "",
    },
  },
  computed: {
    handelPopup(){
      return this.openPopup;
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      isSendingForm: false
    };
  },
  methods: {
    supprimerContact() {
      this.isSendingForm = true;
      deleteCollaborator(this.item.id).then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.$emit('fetchData');
          this.openPopup = false;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.status.mesage,
            type: "error",
          });
        }).finally(()=>{
          this.isSendingForm = false;
          this.closePopup();
        });
    },
    closePopup(){
      this.$emit('close');
      this.openPopup = false;
    }
  },
};
</script>
<style lang="scss" scoped>
  #deleteDialog{
    position: relative;
    img {
      height: 40px;
      width: 40px;
      margin: 20px auto 20px;
    }
    .img-close {
      height: 10px;
      width: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
</style>
