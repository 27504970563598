<template>
  <v-dialog v-if="$parent.isMobileOrTablet" :persistent="false" width="300" v-bind="$attrs" v-on="$listeners">
    <div class="tw-text-center tw-px-4 tw-py-2 tw-text-black-100 tw-min">
      <h2 class="tw-font-bold tw-text-xl tw-mb-4">Alerte</h2>
      <div>
        Pour bénéficier des fonctionnalités de la plataforme viqtor ainsi que
        pour des raisons de sécurité, nous vous donnons rendez-vous sur un
        ordinateur.
      </div>
      <v-btn
        @click="$emit('close')"
        class="tw-text-white victor-submit-button tw-m-auto"
      >
        Fermer
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "MobileOrTabletModal",
};
</script>

<style scoped></style>
