<template>
  <header id="header" class="tw-grid tw-grid-flow-col tw-grid-cols-16 tw-border-b tw-border-sidebar-border tw-z-30">
    <div class="pl-5 lg:tw-hidden tw-items-center tw-flex tw-justify-start tw-col-span-12 lg:tw-border-r tw-border-sidebar-border tw-h-12">
      <a href="#" @click="$parent.showMenu">
        <img src="@/assets/images/icons/menu.svg" alt="menu" />
      </a>
    </div>

    <div v-if="show_expert" class="tw-ml-16 tw-font-semibold tw-flex tw-items-center">{{ company.socialReason }}</div>

    <div 
      v-if="this.$route.name != 'Company.NotificationPage'" 
      class="pr-5 tw-flex tw-items-center md:tw-justify-end tw-col-span-12 lg:tw-border-r tw-border-sidebar-border tw-h-12"
    >
      <div class="tw-flex tw-items-center" :class="{'tw-justify-between md:2/4':subscribed,'tw-justify-end':!subscribed}">
        <router-link 
          tag="a"
          :to="{ name: 'Incident.Homepage' }"
          class="tw-mr-5 tw-rounded-xl tw-text-center tw-text-xs tw-font-semibold tw-py-1"
          v-if="subscribed && referer.title != 'Viqtor Expert'"
          :style="{
            border: `1px solid ${referer.theme.ctaBgColor}`,
            background: `${referer.theme.ctaBgColor}`,
            color: `${referer.theme.ctaTextColor}`,
            width: '180px',
          }"
        >
          Déclarer une violation
        </router-link>
      </div>

      <router-link
        tag="a"
        :to="{ name: 'Company.NotificationPage' }"
        class="notification"
      >
        <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="17.0089" cy="16.3254" rx="16.0089" ry="15.675" :fill="referer.theme.ctaBgColor" :stroke="referer.theme.ctaBgColor"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.24787 18.4985V18.2905C9.27904 17.675 9.48051 17.0786 9.8315 16.5629C10.4157 15.9434 10.8157 15.1842 10.9893 14.365C10.9893 13.7318 10.9893 13.0896 11.0458 12.4565C11.3376 9.40828 14.4158 7.30078 17.4563 7.30078H17.5316C20.5721 7.30078 23.6502 9.40828 23.9515 12.4565C24.0079 13.0896 23.9515 13.7318 23.9985 14.365C24.1745 15.1861 24.5741 15.9476 25.1564 16.572C25.51 17.0831 25.7118 17.6773 25.74 18.2905V18.4895C25.761 19.3164 25.4701 20.1228 24.921 20.7598C24.1954 21.5047 23.2108 21.9681 22.1535 22.0623C19.0532 22.3879 15.9252 22.3879 12.8249 22.0623C11.7688 21.964 10.7856 21.5013 10.0574 20.7598C9.5168 20.1223 9.22984 19.3207 9.24787 18.4985Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.1221 25.209C15.6065 25.8043 16.3178 26.1896 17.0987 26.2796C17.8796 26.3696 18.6656 26.1568 19.2828 25.6884C19.4726 25.5498 19.6434 25.3888 19.7911 25.209" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span
          class="badge"
          :style="{
            border: `1px solid ${referer.theme.ctaBgColor}`,
            color: referer.theme.ctaBgColor,
          }"
        >
          {{ getNotificationTotal }}
        </span>
      </router-link>
    </div>
    
    <div 
      v-if="this.$route.name == 'Company.NotificationPage'"
      class="pr-5 tw-flex tw-items-center tw-justify-end tw-col-span-12 lg:tw-border-r tw-border-sidebar-border tw-h-12"
    >
      <router-link
        tag="a"
        :to="{ name: 'Company.NotificationPage' }"
        class="notification"
      >
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <circle cx="17" cy="17" r="16" :fill="referer.theme.ctaBgColor" :stroke="referer.theme.ctaBgColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.24299 19.219V19.0066C9.27414 18.3784 9.4755 17.7697 9.82629 17.2432C10.4102 16.6108 10.8099 15.8359 10.9835 14.9997C10.9835 14.3534 10.9835 13.6979 11.0399 13.0516C11.3316 9.94025 14.408 7.78906 17.4468 7.78906H17.5221C20.5609 7.78906 23.6374 9.94025 23.9384 13.0516C23.9949 13.6979 23.9384 14.3534 23.9855 14.9997C24.1614 15.8378 24.5607 16.6152 25.1427 17.2525C25.4961 17.7742 25.6978 18.3807 25.726 19.0066V19.2098C25.747 20.0538 25.4563 20.8769 24.9075 21.5271C24.1823 22.2875 23.1982 22.7605 22.1415 22.8566C19.0429 23.189 15.9166 23.189 12.8181 22.8566C11.7626 22.7563 10.7799 22.284 10.0521 21.5271C9.51176 20.8764 9.22497 20.0582 9.24299 19.219Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.1133 26.0684C15.5974 26.676 16.3084 27.0693 17.0888 27.1612C17.8693 27.253 18.6548 27.0358 19.2717 26.5577C19.4614 26.4163 19.6321 26.2519 19.7797 26.0684" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </svg>
      </router-link>
    </div>

    <div
      v-if="show_expert"
      class="header-list-dropdown tw-col-span-1 tw-flex tw-items-center tw-justify-center lg:tw-border-r"
    >
      <button class="public-header-list-li-a" @click="loginToExpert">Revenir au compte admin</button>
    </div>

    <div class="header-list-dropdown tw-col-span-1">
      <v-menu :close-on-click="closeProfil" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on" class="tw-flex tw-w-full tw-u tw-h-full tw-items-center tw-justify-evenly">
            <span class="tw-capitalize">{{ getLoggedUser }}</span>
            <img
              src="@/assets/images/icons/arraw.svg" alt="arraw"
              class="tw-w-4 tw-mr-3"
            />
          </a>
        </template>

        <v-list>
          <v-list-item
            @click="$router.push({ name: 'Company.Profile' }).catch(() => {})"
            class="tw-cursor-pointer"
          >
            <v-list-item-icon style="margin-right: 8px">
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9134 10.0488C11.1407 8.77005 9.9422 7.77858 8.51111 7.2342C9.21365 6.7281 9.7326 6.0225 9.99445 5.21735C10.2563 4.41221 10.2478 3.54833 9.97008 2.7481C9.69239 1.94786 9.15961 1.25184 8.44721 0.758623C7.73481 0.265406 6.87891 0 6.00074 0C5.12258 0 4.26668 0.265406 3.55428 0.758623C2.84188 1.25184 2.3091 1.94786 2.03141 2.7481C1.75371 3.54833 1.74519 4.41221 2.00704 5.21735C2.26889 6.0225 2.78784 6.7281 3.49038 7.2342C2.05935 7.77858 0.860887 8.77001 0.0882025 10.0486C0.00084641 10.1943 -0.0227797 10.3673 0.0225097 10.5297C0.0677991 10.692 0.178304 10.8305 0.329769 10.9147C0.481235 10.9989 0.661286 11.0219 0.830405 10.9787C0.999523 10.9355 1.14389 10.8296 1.23182 10.6843C1.71527 9.88033 2.41048 9.21274 3.24759 8.74861C4.08471 8.28448 5.03425 8.04014 6.00082 8.04014C6.96738 8.04015 7.91692 8.2845 8.75403 8.74864C9.59114 9.21278 10.2863 9.88037 10.7698 10.6843C10.8582 10.8288 11.0024 10.9338 11.1711 10.9765C11.3398 11.0192 11.5192 10.996 11.6702 10.9121C11.8212 10.8282 11.9315 10.6903 11.9771 10.5286C12.0226 10.3668 11.9997 10.1943 11.9134 10.0488ZM3.13798 4.02119C3.13798 3.47732 3.30588 2.94567 3.62044 2.49346C3.93501 2.04126 4.38211 1.6888 4.90521 1.48068C5.42831 1.27255 6.00392 1.21809 6.55924 1.3242C7.11456 1.4303 7.62466 1.69219 8.02502 2.07676C8.42539 2.46133 8.69804 2.95131 8.8085 3.48472C8.91896 4.01814 8.86227 4.57103 8.64559 5.0735C8.42892 5.57596 8.06199 6.00543 7.59121 6.30758C7.12043 6.60974 6.56695 6.77101 6.00074 6.77101C5.24176 6.77019 4.5141 6.48021 3.97742 5.96469C3.44073 5.44918 3.13884 4.75023 3.13798 4.02119Z" fill="black" />
              </svg>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="header-list-dropdown-text hover:tw-text-black hover:tw-font-bold">Mon compte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="$router.push({ name: 'Company.Profil.CGU' }).catch(() => {})"
            class="tw-cursor-pointer"
          >
            <v-list-item-icon style="margin-right: 8px">
              <svg
                class="tw-text-black hover:tw-fill-current hover:tw-text-blue-200"
                width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 2.2C1 2.2 1.9 1 4 1C6.1 1 7 2.2 7 2.2V10.6C7 10.6 6.1 10 4 10C1.9 10 1 10.6 1 10.6V2.2Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7 2.2C7 2.2 7.9 1 10 1C12.1 1 13 2.2 13 2.2V10.6C13 10.6 12.1 10 10 10C7.9 10 7 10.6 7 10.6V2.2Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="header-list-dropdown-text hover:tw-text-black hover:tw-font-bold" >CGU</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="$router.push({ name: 'Company.Profil.Contact' }).catch(() => {})"
            class="tw-cursor-pointer"
          >
            <v-list-item-icon style="margin-right: 8px">
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9134 10.0488C11.1407 8.77005 9.9422 7.77858 8.51111 7.2342C9.21365 6.7281 9.7326 6.0225 9.99445 5.21735C10.2563 4.41221 10.2478 3.54833 9.97008 2.7481C9.69239 1.94786 9.15961 1.25184 8.44721 0.758623C7.73481 0.265406 6.87891 0 6.00074 0C5.12258 0 4.26668 0.265406 3.55428 0.758623C2.84188 1.25184 2.3091 1.94786 2.03141 2.7481C1.75371 3.54833 1.74519 4.41221 2.00704 5.21735C2.26889 6.0225 2.78784 6.7281 3.49038 7.2342C2.05935 7.77858 0.860887 8.77001 0.0882025 10.0486C0.00084641 10.1943 -0.0227797 10.3673 0.0225097 10.5297C0.0677991 10.692 0.178304 10.8305 0.329769 10.9147C0.481235 10.9989 0.661286 11.0219 0.830405 10.9787C0.999523 10.9355 1.14389 10.8296 1.23182 10.6843C1.71527 9.88033 2.41048 9.21274 3.24759 8.74861C4.08471 8.28448 5.03425 8.04014 6.00082 8.04014C6.96738 8.04015 7.91692 8.2845 8.75403 8.74864C9.59114 9.21278 10.2863 9.88037 10.7698 10.6843C10.8582 10.8288 11.0024 10.9338 11.1711 10.9765C11.3398 11.0192 11.5192 10.996 11.6702 10.9121C11.8212 10.8282 11.9315 10.6903 11.9771 10.5286C12.0226 10.3668 11.9997 10.1943 11.9134 10.0488ZM3.13798 4.02119C3.13798 3.47732 3.30588 2.94567 3.62044 2.49346C3.93501 2.04126 4.38211 1.6888 4.90521 1.48068C5.42831 1.27255 6.00392 1.21809 6.55924 1.3242C7.11456 1.4303 7.62466 1.69219 8.02502 2.07676C8.42539 2.46133 8.69804 2.95131 8.8085 3.48472C8.91896 4.01814 8.86227 4.57103 8.64559 5.0735C8.42892 5.57596 8.06199 6.00543 7.59121 6.30758C7.12043 6.60974 6.56695 6.77101 6.00074 6.77101C5.24176 6.77019 4.5141 6.48021 3.97742 5.96469C3.44073 5.44918 3.13884 4.75023 3.13798 4.02119Z" fill="black" />
              </svg>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="header-list-dropdown-text hover:tw-text-black hover:tw-font-bold" >Contact</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logoutUser" class="tw-cursor-pointer">
            <v-list-item-icon style="margin-right: 8px">
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.45455 11C2.442 11 0 8.53765 0 5.5C0 2.46235 2.442 1.55006e-06 5.45455 1.55006e-06C6.30145 -0.000640003 7.13683 0.197876 7.89433 0.579779C8.65182 0.961682 9.31055 1.51645 9.81818 2.2H8.34C7.71013 1.63997 6.93337 1.2751 6.10294 1.14917C5.2725 1.02324 4.42367 1.14161 3.6583 1.49007C2.89293 1.83853 2.24354 2.40227 1.78804 3.11365C1.33254 3.82504 1.09029 4.65384 1.09036 5.50061C1.09043 6.34738 1.33282 7.17614 1.78843 7.88745C2.24405 8.59875 2.89354 9.16239 3.65896 9.51072C4.42439 9.85905 5.27324 9.97727 6.10365 9.8512C6.93407 9.72514 7.71076 9.36014 8.34055 8.8H9.81873C9.31104 9.48363 8.65222 10.0384 7.89463 10.4203C7.13703 10.8022 6.30154 11.0007 5.45455 11ZM9.27273 7.7V6.05H4.90909V4.95H9.27273V3.3L12 5.5L9.27273 7.7Z" fill="black" />
              </svg>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="header-list-dropdown-text hover:tw-text-black hover:tw-font-bold">Déconnexion</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </header>
</template>

<script>
import { getNotificationsUser } from "@/features/auth/services/appApi";

export default {
  name: "HeaderCompany",
  mounted() {
    this.getNotifications();
  },
  data: () => ({
    languages: [{ title: "FR" }, { title: "EN" }],
    closeLanguage: true,
    closeProfil: true,
    offset: true,
    activeLanguage: "FR",
  }),
  computed: {
    subscribed() {
      if (this.$store.getters.getAuthUser) {
        return this.$store.getters.getAuthUser.workInCompany.planPrice ? this.$store.getters.getAuthUser.workInCompany.planPrice.price !== 0 : false;
      }
      else {
        return false;
      }
    },
    getNotificationTotal() {
      if (this.$store.state.notificationsTotal) {
        return this.$store.getters.getNotificationsTotal;
      } else {
        return 0;
      }
    },
    getLoggedUser() {
      if (this.$store.state.user) return this.$store.getters.getLoggedUser;
      else return "Loading...";
    },
    company() {
      return this.$store.state.user.workInCompany;
    },
    referer() {
      return this.$store.state.referrer;
    },
    show_expert() {
      return this.$store.state.tokenExpert;
    },
  },
  methods: {
    getNotifications() {
      getNotificationsUser()
        .then((resp) => {
          let notifCount = resp.data.data.filter(function (element) {
            return element.seen === false;
          }).length;
          this.$store.dispatch("increment", {
            count: notifCount,
          });
        })
        .catch(() => "");
    },
    setLanguage(lang) {
      this.activeLanguage = lang.title;
    },
    logoutUser() {
      this.$store.dispatch("logout");
    },
    loginToExpert() {
      this.$store
        .dispatch("setToken", this.$store.state.tokenExpert)
        .then(() => {
          this.$store.dispatch("removeExpertToken").then(() => {
            this.$router.push({ name: "Company.Expert.DashboardPage" });
            this.$snackbar.showAlertMessage({
              message: "Vous êtes connecté en tant qu'expert",
              type: "success",
            });
          });
        });
    },

  },
};
</script>
<style scoped></style>
