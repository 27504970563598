<template>
    <v-dialog
        v-if="handelPopup"
        v-model="handelPopup"
        @click:outside="closePopup"
        width="600"
        transition="dialog-bottom-transition"
      >
      <div id="cnilincidentDialog" class="tw-p-8">

        <div class="tw-w-full tw-flex tw-justify-center tw-items-center">
            <div class="tw-w-3/4 tw-font-bold tw-text-2xl tw-text-center tw-leading-10">
                Souhaitez-vous vraiment 
                déclarer l’incident à la CNIL ?
            </div>
        </div>
        <div class="tw-flex tw-justify-around tw-w-full tw-mt-6">
            <v-btn
              elevation="3"
              text
              rounded
              small
              @click="closePopup"
              class="tw-text-gray-100 tw-cursor-pointer normal-btn"
            >
              Non
            </v-btn>
            <v-btn
              elevation="3"
              text
              rounded
              small
              @click="openCnilIncident"
              class="tw-text-white tw-cursor-pointer"
            >
              Oui
            </v-btn>
        </div>
        </div>
      </v-dialog>
  </template>
  <script>
  import {updateIncident} from "../services/appApi";

  export default {
    name: "ConfirmeDeletePopup",
    props: {
      openPopup: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
      },
    },
    computed: {
      referer() {
        return this.$store.state.referrer;
      },
      handelPopup(){
        return this.$parent.openPopup;
      }
    },
    data() {
      return {
        isSendingForm: false
      };
    },
    methods: {
      openCnilIncident(){
        this.$parent.openPopup = false;
        this.updateIncident(this.item)

        window.open('https://notifications.cnil.fr/notifications/index', '_blank');
      },
      updateIncident(el){
        updateIncident(el.id).then(() => {
          this.item.declarer = true;
        }).catch(({response}) => {
          console.log(response)
        })
      },
      closePopup(){
        this.$emit('close');
        this.$parent.openPopup = false;
      }
    },
  };
  </script>
  <style lang="scss" scoped>
#cnilincidentDialog {
  button {
    width: 140px ;
    background-color: var(--cta-bg-color);
  }
  .normal-btn {
    background-color: transparent !important;
    // border-radius: 20px;
  }
  }
  </style>
  