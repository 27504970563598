<template>
  <v-container class="px-12 overflow-auto invitation-confirmation-container">
    <Breadcrumb divider=">"></Breadcrumb>

    <v-row no-gutters>
      <v-col cols="12" class="mt-4 mb-12">
        <h1 class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl">Vos invitations ont bien été envoyées !</h1>
        <h5 class="tw-tracking-tight tw-text-base caption">Félicitations ! Vos sous-traitants vont recevoir votre invitation très rapidement !</h5>
        <h5 class="tw-tracking-tight tw-text-base caption">Vous retrouverez leur score et leur statut dans votre liste de sous-traitants. </h5>
      </v-col>

      <v-col cols="12" class="mt-4 mb-12">
        <PrimaryButtonComponent
            class="return-btn"
            :style="{
            'background-color': `${referer.theme.ctaTextColor} !important`,
            color: `${referer.theme.ctaBgColor} !important`,
          }"
            :to="{ name: dashboardName }"
        >Revenir au dashboard</PrimaryButtonComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "SubContractorInvitationConfirmation",

  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  },

  data() {
    return {

    };
  },

  components: {
    Breadcrumb,
    PrimaryButtonComponent,
  },

  methods: {

  },
};
</script>

<style lang="scss">
.invitation-confirmation-container {
    background-image: url(/images/background/bg_invitation_confirmation.svg);
    background-repeat: no-repeat;
    background-position: 50% 70%;
    height: 100%;
}

.v-application ol, .v-application ul {
  padding-left: 0 !important;
}

.return-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: 250px !important;
  margin: 0 12px;
  background: var(--cta-bg-color) !important;
  color: #fff !important;
}
</style>
