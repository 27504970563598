<template>
  <div>
      <v-select
        :items="items"
        :label="label"
        solo
        dense
        rounded
        v-bind="$attrs"
        elevation="0"
        v-on="$listeners"
        append-icon="mdi-chevron-down"
        hide-details
        flat
        class="combobox-collaborator"
      ></v-select>
  </div>
</template>

<script>
export default {
  name: "SelectBoxComponent",
  props: {
    label: String,
    items: Array,
  },
};
</script>

<style lang="scss">
.combobox-collaborator.v-input
{
  .v-input__slot {
    background: #f0F0F0 !important;
  }
  width: 200px;
  font-size: 12px !important;
}
</style>
