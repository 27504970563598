var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"tw-pt-0 tw-mt-0",attrs:{"id":"LoginPage"}},[_c('div',{staticClass:"auth-container tw-mt-20 tw-min-h-screen"},[_c('router-link',{attrs:{"to":{ name: 'LoginPage' }}},[_c('RetourButtonComponent')],1),_c('TitleComponent',{staticClass:"public-title-auth tw-mt-4",attrs:{"text":"Entrez sur"}}),_c('TitleComponent',{staticClass:"public-title-auth",attrs:{"text":"votre espace."}}),_c('v-form',{ref:"form",staticClass:"tw-mt-10",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('PasswordFieldComponent',{staticClass:"tw-mt-6 sm:tw-w-full md:tw-w-1/3",attrs:{"rules":_vm.rules.password,"error-messages":_vm.errors.password,"label":"Mot de passe"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('v-btn',{staticClass:"tw-tracking-normal tw-mt-4 pa-0 tw-normal-case text-transform-none",attrs:{"tile":"","depressed":"","color":"white","elevation":"0"},on:{"click":_vm.onPasswordForget}},[_c('span',{staticClass:"public-text-login",style:({
          color: `${_vm.theme.ctaBgColor}`,
        })},[_vm._v("Mot de passe oublié ?")])]),_c('div',{staticClass:"tw-mt-10"},[_c('PrimaryButtonComponent',{staticClass:"tw-rounded-lg",style:({
          backgroundColor: `${_vm.theme.ctaBgColor} !important`,
          color: `${_vm.theme.ctaTextColor}  !important`
        }),attrs:{"loading":_vm.isSendingForm,"disabled":!_vm.isFormValid || _vm.isSendingForm,"type":"submit"}},[_vm._v("Entrer")])],1),_c('LoginSecondaryTextComponent',{staticClass:"public-text-login tw-mt-16"},[_vm._v(" Pas encore de compte ? "),_c('router-link',{staticClass:"text-decoration-underline",style:({
          color: `${_vm.theme.ctaBgColor}`,
        }),attrs:{"to":{ name: _vm.platformTitle == 'Viqtor Expert' ? 'Auth.Registration.Expert.PreRegister' : 'signup' }}},[_vm._v(" Inscrivez-vous ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }