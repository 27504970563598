import Vuex from "vuex";
import Vue from "vue";
import ApiService from "../services/api.service";
import JwtService from "../services/jwt.service";
import createPersistedState from "vuex-persistedstate";
import router from "@/routes/router";
import referrer from "@/plugins/referrer";
import DashboardItem from "@/features/company/components/Mocks/data";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    children:null,
    referenceData:null,
    urlChange:false,
    token: JwtService.getToken() || "",
    dashboardOrder: DashboardItem,
    user: null,
    dpo: null,
    tempUser: null,
    correctAnswers: null,
    answers: [],
    totalAnswersCorrect: 0,
    pre_loader: false,
    snackbar: {
      message: "",
      type: "",
    },
    notificationsTotal: "0",
    notificationsMessages: [],
    elearningToken: null,
    questionnaireToken: null,

    subcontractorsValid: [],
    subcontractorsInvalid: [],
    collaboratorsValid: [],
    collaboratorsInvalid: [],
    emailTemplate: null,
    oneSubcontractor: null,
    referrer: referrer,
    canBypassMaintenance: false,
    bypassMaintenanceKey: null,

    addCollaborator: null,
    processingRegister: {
      isManagerDelegate: true,
      isResponsibleDelegate: true,
      responsible: {},
      manager: {},
      delegate: {},
    },

    clientValid: [],
    clientInvalid: [],
    tokenExpert: null,
    questions:[],

    publicQuizChapters: [],

    gpmseUserToken: null,
    gpmseCompanySocialReason: null,
    gpmseQuizChapters: [],
    gpmseQuizChaptersWithScore: [],
    gpmseShowDashboardRedirectionPopup: false,

    multiAuthEmail: null,
    multiAuthAccounts: [],
  },

  getters: {
    getQuestions: state => state.questions,
    getChildren: state => state.children,
    getReferenceData: state => state.referenceData,
    getDpo: state => state.dpo,
    getDashboardOrder(state) {
      return state.dashboardOrder;
    },
    getElearningToken(state) {
      return state.elearningToken;
    },
    getQuestionnaireToken(state) {
      return state.questionnaireToken;
    },
    messageToDisplay(state) {
      return state.chosenMessage;
    },
    getNotifications(state) {
      return state.notificationsMessages;
    },
    getNotificationsTotal(state) {
      return state.notificationsTotal;
    },
    getLoggedUser(state) {
      return `${state.user.firstName}`;
    },
    getCollaboratorsValid(state) {
      return state.collaboratorsValid;
    },
    getCollaboratorsInvalid(state) {
      return state.collaboratorsInvalid;
    },
    getAnswers(state) {
      return state.answers;
    },
    getCorrectAnswers(state) {
      return state.correctAnswers;
    },
    getTotalAnswersCorrects(state) {
      return state.totalAnswersCorrect;
    },
    getSubcontractorsValid(state) {
      return state.subcontractorsValid;
    },
    getSubcontractorsInvalid(state) {
      return state.subcontractorsInvalid;
    },
    getEmailTemplate(state) {
      return state.emailTemplate;
    },
    getOneSubcontractor(state) {
      return state.oneSubcontractor;
    },

    getAuthUser(state) {
      return state.user;
    },
    getTempUser(state) {
      return state.tempUser;
    },
    getAddCollaborator(state) {
      return state.addCollaborator;
    },
    getAuthUserTradeName(state) {
      return state.user && state.user.workInCompany.tradeName;
    },
    getAuthUserFirstName(state) {
      return state.user && state.user.firstName;
    },
    getReferrerLogo(state) {
      return state.referrer && state.referrer.logo;
    },
    getAuthUserActivity(state) {
      return state.user && state.user.service;
    },
    getAuthUserLastName(state) {
      return state.user && state.user.lastName;
    },
    getAuthUserFunction(state) {
      return state.user && state.user.workInCompany.corporateOfficer;
    },
    getProcessingRegister(state) {
      return state.processingRegister;
    },
    getClientsValid(state) {
      return state.clientValid;
    },
    getClientsInvalid(state) {
      return state.clientInvalid;
    },
    getTokenExpert(state) {
      return state.tokenExpert;
    },
    getPublicQuizChapters(state) {
      return state.publicQuizChapters;
    },

    getGpmseQuizChapters(state) {
      return state.gpmseQuizChapters;
    },
    getGpmseQuizChaptersWithScore(state) {
      return state.gpmseQuizChaptersWithScore;
    },
  },
  mutations: {
    SET_REFERENCE_DATA(state, reference){
      state.referenceData = reference;
    },
    SET_CAN_BYPASS_MAINTENANCE(state, canBypassMaintenance) {
      state.canBypassMaintenance = canBypassMaintenance;
    },
    SET_BYPASS_MAINTENANCE_KEY(state, bypassMaintenanceKey) {
      state.bypassMaintenanceKey = bypassMaintenanceKey;
    },
    SET_CHILDREN(state, children){
      state.children = children;
    },
    SET_DPO(state, dpo){
      state.dpo = dpo;
    },
    SET_QUESTIONS(state, questions){
      state.questions.push(questions);
    },
    SET_PROCESSING_REGISTER(state,data) {
      state.processingRegister = data;
    },
    UNSET_PROCESSING_REGISTER(state) {
      state.processingRegister = {
        isManagerDelegate: true,
        isResponsibleDelegate: true,
        responsible: {},
        manager: {},
        delegate: {},
      };
    },
    SET_DASHBOARD_ORDER(state, data) {
      state.dashboardOrder = data;
    },
    SET_ELERNING_TOKEN(state, data) {
      state.elearningToken = data;
    },
    SET_QUESTIONNAIRE_TOKEN(state, data) {
      state.questionnaireToken = data;
    },
    SET_ANSWERS(state, data) {
      state.answers = data;
    },
    SET_CORRECT_ANSWERS(state, data) {
      state.correctAnswers = data;
    },
    SET_TOTAL_ANSWERS_CORRECT(state, count) {
      state.totalAnswersCorrect = count;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = null;
      state.tokenExpert = null;
    },
    reset_auth(state) {
      JwtService.unsetToken();
      state.status = "";
      state.token = "";
      state.user = null;
    },
    set_auth(state, { user }) {
      state.user = user;
    },
    SET_USER(state, user) {
      state.tempUser = user;
    },
    loader(state) {
      state.pre_loader = !state.pre_loader;
    },
    showAlertMessage(state, payload) {
      state.snackbar.message = payload.message;
      state.snackbar.type = payload.type;
    },
    addNotification(state, payload) {
      state.notificationsMessages.push({
        id: state.notificationsMessages.length + 1,
        date: payload.date,
        description: payload.description,
        Accepter: 0,
        redirectPath: payload.redirectPath,
      });
    },
    supprimerNotification(state, payload) {
      state.notificationsMessages.splice(
        state.notificationsMessages.indexOf(payload.id),
        1
      );
    },
    increment(state, payload) {
      state.notificationsTotal = payload.count;
    },
    getNotificationCount(state) {
      state.notificationsTotal;
    },

    supprimerSubcontractorValid(state, payload) {
      state.subcontractorsValid.splice(
        state.subcontractorsValid.indexOf(payload.email),
        1
      );
    },
    supprimerCollaboratorValid(state, payload) {
      state.collaboratorsValid = state.collaboratorsValid.filter(
        (item) => item.email !== payload.email
      );
    },
    loadSubcontractorValid(state, payload) {
      state.subcontractorsValid = payload;
    },
    loadSubcontractorInvalid(state, payload) {
      state.subcontractorsInvalid = payload;
    },

    loadCollaboratorValid(state, payload) {
      state.collaboratorsValid = payload;
    },
    loadCollaboratorInvalid(state, payload) {
      state.collaboratorsInvalid = payload;
    },
    setEmailTemplate(state, payload) {
      state.emailTemplate = payload;
    },
    importOneSubcontractor(state, payload) {
      state.oneSubcontractor = payload;
    },
    set_referrer(state, referrer) {
      state.referrer = referrer;
    },
    addCollaborator(state, payload) {
      state.addCollaborator = payload;
    },
    deleteCollaborator(state) {
      state.addCollaborator = null;
    },

    loadClientValid(state, payload) {
      state.clientValid = payload;
    },
    loadClientInvalid(state, payload) {
      state.clientInvalid = payload;
    },
    supprimerClientValid(state, payload) {
      state.clientValid = state.clientValid.filter(
        (item) => item.email !== payload.email
      );
    },
    SET_TOKEN_EXPERT(state) {
      state.tokenExpert = JwtService.getToken().token;
    },
    UNSET_TOKEN_EXPERT(state) {
      state.tokenExpert = null;
    },
    SET_PUBLIC_QUIZ_CHAPTERS(state, data) {
      state.publicQuizChapters = data;
    },

    SET_GPMSE_USER_TOKEN(state, data) {
      state.gpmseUserToken = data;
    },
    SET_GPMSE_QUIZ_CHAPTERS(state, data) {
      state.gpmseQuizChapters = data;
    },
    SET_GPMSE_QUIZ_CHAPTERS_WITH_SCORE(state, data) {
      state.gpmseQuizChaptersWithScore = data;
    },
    SET_GPMSE_COMPANY_SOCIAL_REASON(state, data) {
      state.gpmseCompanySocialReason = data;
    },
    SET_GPMSE_SHOW_DASHBOARD_REDIRECTION_POPUP(state, data) {
      state.gpmseShowDashboardRedirectionPopup = data;
    },

    SET_MULTI_AUTH_EMAIL(state, email) {
      state.multiAuthEmail = email;
    },
    SET_MULTI_AUTH_ACCOUNTS(state, accounts) {
      state.multiAuthAccounts = accounts;
    },
  },

  actions: {
    handleReferenceData({commit}, reference){
      commit('SET_REFERENCE_DATA', reference);
    },
    handleChildren({commit}, children){
      commit('SET_CHILDREN', children);
    },
    updateQuestions({commit}, questions){
      commit('SET_QUESTIONS', questions);
    },
    increment({ commit }, payload) {
      commit("increment", payload);
    },
    setAuth({ commit }, payload) {
      commit("set_auth", { user: payload });
    },
    setToken({ commit }, token) {
      JwtService.setToken(token);
      ApiService.setHeader();
      ApiService.get("profile")
        .then((response) => {
          const user = response.data.data;
          commit("auth_success", { token, user });
        })
        .catch((err) => {
          console.log("err => ", err);
        });
    },
    loadSubcontractorValid({ commit }, payload) {
      commit("loadSubcontractorValid", payload);
    },
    loadSubcontractorInvalid({ commit }, payload) {
      commit("loadSubcontractorInvalid", payload);
    },
    loadCollaboratorValid({ commit }, payload) {
      commit("loadCollaboratorValid", payload);
    },
    loadCollaboratorInvalid({ commit }, payload) {
      commit("loadCollaboratorInvalid", payload);
    },
    setEmailTemplate({ commit }, payload) {
      commit("setEmailTemplate", payload);
    },
    supprimerSubcontractorValid({ commit }, payload) {
      commit("supprimerSubcontractorValid", payload);
    },
    supprimerCollaboratorValid({ commit }, payload) {
      commit("supprimerCollaboratorValid", payload);
    },
    login({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        ApiService.post("auth/signin", userData)
          .then((response) => resolve(response))
          .catch((error) => {
            commit("auth_error");
            JwtService.unsetToken();
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        JwtService.unsetToken();
        resolve();
        router.push({ name: "LoginPage" })
          .then(() => localStorage.removeItem('vuex'));
      });
    },
    loader({ commit }) {
      commit("loader");
    },
    importOneSubcontractor({ commit }, payload) {
      commit("importOneSubcontractor", payload);
    },
    addCollaborator({ commit }, payload) {
      commit("addCollaborator", payload);
    },
    deleteCollaborator({ commit }) {
      commit("addCollaborator");
    },

    setReferrer({ commit }, payload) {
      commit("set_referrer", payload);
    },
    setProcessingRegister({ commit }, payload) {
      commit("SET_PROCESSING_REGISTER", payload);
    },
    unsetProcessingRegister({ commit }) {
      commit("UNSET_PROCESSING_REGISTER");
    },
    loadClientValid({ commit }, payload) {
      commit("loadClientValid", payload);
    },
    loadClientInvalid({ commit }, payload) {
      commit("loadClientInvalid", payload);
    },
    deleteClients({ commit }) {
      commit("loadClientValid");
      commit("loadClientInvalid");
    },
    deleteClient({ commit }) {
      commit("loadClientValid");
      commit("loadClientInvalid");
    },
    supprimerClientValid({ commit }, payload) {
      commit("supprimerClientValid", payload);
    },
    setExpertToken({ commit }) {
      commit("SET_TOKEN_EXPERT");
    },
    removeExpertToken({ commit }) {
      commit("UNSET_TOKEN_EXPERT");
    },
  },
  modules: {},

  plugins: [createPersistedState({
    key: "vuex",
  })],
});
