<template>
  <v-btn
    :elevation="elevation"
    text
    v-bind="$attrs"
    rounded
    large
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "SubmitButtonComponent",
  props: {
    onClick: Function,
    elevation: {
      type: String,
      default: "1",
    },
  }
};
</script>

<style scoped></style>
