<template>
  <section id="second">
    <v-container>
      <div class="tw-mx-auto tw-flex warper tw-flex-wrap tw-flex-row">
        <div class="tw-w-6/12 md:tw-w-4/12 box">
          <svg
            width="186"
            height="186"
            viewBox="0 0 186 186"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="93" cy="93" r="93" :fill="referer.theme.icon1BgColor" />
            <path
              d="M113 92.4787C109.205 95.1201 104.747 96.6641 100.122 96.9391C100.456 95.7682 100.457 94.5281 100.123 93.357C99.789 92.1859 99.1342 91.13 98.2314 90.3072C96.3807 88.6876 93.9584 87.8643 91.497 88.0183C89.0624 88.2136 86.6921 88.8921 84.5262 90.0137C81.5674 91.4122 78.8546 93.275 76.4921 95.5306C74.4836 97.526 72.4751 99.4628 70.4665 101.4C67.8329 103.866 65.4213 106.556 63.2595 109.44C62.078 111.025 60.8965 112.668 59.715 114.253C58.3563 116.189 56.9385 118.068 56.1706 120.298C55.2845 122.821 55.4617 125.58 56.1115 128.162C56.7613 130.745 58.888 132.74 61.31 133.796C63.7321 134.853 66.5676 135.616 69.1078 134.912C73.1249 133.738 76.1967 130.627 79.0914 127.634C84.349 122.117 89.8429 117.011 95.1005 111.494C100.24 112.961 105.202 113.431 110.401 112.257C105.734 119.593 98.1724 127.047 91.9696 133.151C85.7668 139.255 77.7917 144.067 69.0488 144.889C60.3058 145.71 50.8539 141.954 46.4824 134.442C45.2336 132.352 44.4106 130.039 44.0603 127.634C43.2333 121.824 45.0646 115.896 48.1365 110.907C51.2083 105.919 55.0482 100.167 59.1834 96.0001H39.2517C37.496 96.001 35.742 96.1108 34 96.3288"
              :stroke="referer.theme.icon1Color"
              stroke-width="3"
              stroke-miterlimit="10"
            />
            <path
              d="M112 92.5899C112 90.8909 112 89.5435 111.17 88.0789C110.34 86.6143 110.043 85.6183 108.976 84.3295C106.474 81.3427 103.248 79.0297 99.5984 77.6059C95.9486 76.1821 91.9934 75.6937 88.1012 76.1862C84.543 76.6549 80.9256 77.3579 77.8419 79.0568C71.3622 82.5765 65.6411 87.3129 61 93"
              :stroke="referer.theme.icon1Color"
              stroke-width="3"
              stroke-miterlimit="10"
            />
            <path
              d="M117.276 91.0699C114.976 94.458 111.791 96.3273 107.839 97.5541C104.595 98.5472 100.172 99.6571 97.0456 98.3135C95.4965 97.6177 94.1494 96.5465 93.1288 95.1987C92.1083 93.851 91.4471 92.2703 91.2064 90.6025C87.1962 91.6825 83.4048 93.4415 80 95.8016C80.7078 97.3788 81.4156 98.8977 82.2413 100.416C84.9544 105.382 90.7936 107.485 96.3378 108.887C101.056 110.055 104.477 110.815 112.676 108.478C116.509 107.368 120.52 104.856 123.469 102.169C126.418 99.4818 128.836 96.3273 131.314 93.1729C134.263 89.3174 137.448 85.4619 140.397 81.6064C144.231 76.5242 149.48 70.6826 149.952 63.7895C150.424 56.8964 147.416 49.7696 141.93 45.5052C135.383 40.4814 126.182 39.9556 118.279 42.4675C110.375 44.9794 103.71 50.1785 97.6354 55.7864C91.2064 61.7449 86.134 68.638 80.9437 75.648C86.7149 73.7144 92.8968 73.3115 98.874 74.4797C104.477 68.2083 110.723 62.5309 117.512 57.5389C118.738 56.6318 120.04 55.8306 121.405 55.1439C123.159 54.4017 125.042 54.0049 126.949 53.9755C129.662 53.9171 132.493 54.5597 134.617 56.2538C135.655 57.0858 136.479 58.1494 137.023 59.3577C137.566 60.5659 137.813 61.8845 137.743 63.2053C137.448 67.0024 134.499 69.9816 131.844 72.7272C126.07 78.5688 121.818 84.4688 117.276 91.0699Z"
              :stroke="referer.theme.icon1Color"
              stroke-width="3"
              stroke-miterlimit="10"
            />
          </svg>

          <span class="victor-meduim-title">Cohérence ++</span>
          <p>
            Cohérence d’une gouvernance des données par une documentation
            exhaustive, cohérence d’une gouvernance en parfaite adéquation avec
            les métiers de votre entreprise.
          </p>
        </div>
        <div class="tw-w-6/12 md:tw-w-4/12 box">
          <svg
            width="186"
            height="186"
            viewBox="0 0 186 186"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="93" cy="93" r="93" :fill="referer.theme.icon2BgColor" />
            <g clip-path="url(#clip0)">
              <path
                d="M22.1367 138.7C31.5433 137.713 40.6301 137.641 50.0824 137.504C55.8203 137.419 61.728 137.302 67.0091 135.041L67.1657 134.976C74.725 131.65 76.1023 121.465 69.8356 116.082L66.2192 112.978L61.9695 127.162C61.6951 128.131 61.204 129.025 60.5335 129.775C58.2291 132.245 55.7681 130.782 54.7889 128.828C54.2573 127.586 53.9564 126.258 53.9011 124.908L51.29 99.3566L30.3095 88.0998C27.8747 86.7932 25.1787 85.1598 24.6369 82.4485C49.9387 75.6017 74.4247 68.5327 99.707 61.6924L118.873 56.4789L131.119 53.2122C139.187 51.0367 146.544 49.1159 154.619 46.973C155.962 46.5301 157.37 46.3201 158.784 46.3523C159.49 46.3732 160.181 46.5677 160.794 46.9186C161.408 47.2695 161.926 47.7661 162.302 48.3646C162.601 49.0266 162.728 49.7534 162.671 50.4776C162.614 51.2017 162.375 51.8996 161.976 52.5067C161.173 53.7163 160.176 54.7847 159.025 55.6687C143.541 69.1315 129.991 84.6733 118.762 101.852C116.355 105.52 114.053 109.25 111.855 113.044C110.961 114.586 110.08 116.141 109.205 117.695L102.09 130.305C91.4623 122.21 80.4107 114.337 69.7899 106.243C97.2068 93.3656 122.111 76.1897 144.978 56.3613C133.299 57.4066 122.724 62.0909 112.169 67.2065L56.6232 94.13C58.1703 108.653 61.6562 123.059 67.5313 136.42"
                :stroke="referer.theme.icon2Color"
                stroke-width="4"
                stroke-miterlimit="10"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="142"
                  height="95"
                  fill="white"
                  transform="translate(22 45)"
                />
              </clipPath>
            </defs>
          </svg>
          <span class="victor-meduim-title">Agilité</span>
          <p>
            {{ referer.title }} est votre boite à outil pour communiquer, former, évaluer,
            contractualiser, documenter, répondre...<br />
            En toute simplicité.
          </p>
        </div>
        <div class="tw-w-6/12 md:tw-w-4/12 box">
          <svg
            width="191"
            height="186"
            viewBox="0 0 191 186"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="93" cy="93" r="93" :fill="referer.theme.icon3BgColor" />
            <path
              d="M21.7808 90.51C35.7238 74.9154 54.5883 64.5679 75.2328 61.1908C95.8773 57.8138 117.056 61.611 135.241 71.95C144.871 77.43 153.411 84.59 161.891 91.71C144.208 110.086 120.603 121.625 95.2408 124.29C89.7725 125.018 84.2201 124.812 78.8208 123.68C72.3408 122.12 66.5608 118.58 60.5408 115.68C49.3027 110.379 37.0995 107.432 24.6808 107.02C22.7213 106.808 20.7416 107.154 18.9708 108.02C17.2608 109.02 16.3608 111.48 17.5308 113.02C18.0708 113.613 18.789 114.015 19.5772 114.165C20.3654 114.315 21.1809 114.204 21.9008 113.85C23.3277 113.137 24.5754 112.112 25.5508 110.85L40.1408 94.43C44.7408 89.25 49.3808 84.04 54.8808 79.82C60.3808 75.6 66.8808 72.4 73.7708 71.82C80.6608 71.24 88.0208 73.59 92.4908 78.88C96.9608 84.17 97.9008 92.52 93.8208 98.12C89.7408 103.72 80.5808 105.12 75.6108 100.28C71.1308 95.9201 71.3008 88.28 74.7108 82.99C77.0653 79.3896 80.6253 76.7454 84.7524 75.5316C88.8796 74.3178 93.3042 74.6137 97.233 76.3663C101.162 78.1188 104.338 81.2134 106.192 85.0954C108.046 88.9773 108.457 93.3927 107.351 97.55C105.871 103.09 101.791 107.66 96.9708 110.76C91.6908 114.15 85.2608 115.98 79.0808 114.98C74.7298 114.184 70.7258 112.078 67.6052 108.943C64.4847 105.808 62.3966 101.795 61.6208 97.4401C60.0108 87.3001 66.3508 76.97 75.3008 71.94C84.2508 66.91 95.2308 66.53 105.301 68.74C115.371 70.95 124.641 75.57 133.841 80.14L145.241 85.76C156.141 91.03 167.582 95.1005 179.361 97.9C182.641 98.71 186.221 99.39 189.271 97.96"
              :stroke="referer.theme.icon3Color"
              stroke-width="4"
              stroke-miterlimit="10"
            />
          </svg>
          <span class="victor-meduim-title">Vision</span>
          <p>
            Adopter {{referer.title}}, c’est se donner un tableau de bord exhaustif et en
            temps réel pour orchestrer votre conformité au RGPD.
          </p>
        </div>
      </div>
      <svg class="tw-hidden lg:tw-block" width="437" height="213" viewBox="0 0 437 213" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 9.74987C59.1713 -5.11723 121.297 2.81613 174.35 31.9499C191.78 41.6299 208.54 54.1599 217.8 71.8199C227.06 89.4799 226.99 113.18 213.33 127.71C200.94 140.88 178.7 143.25 163.81 132.99C148.92 122.73 143.22 101.09 151.12 84.8299C162.12 62.1199 191.36 56.1399 216.55 54.3899C269.55 50.7199 325.37 54.2199 371.06 81.2699C416.75 108.32 434.45 197.08 434.49 212.68"
            :stroke="referer.theme.svg1Color" stroke-width="4" stroke-miterlimit="10" />
      </svg>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "secondSection",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  }
};
</script>
