<template>
  <div id="collaborateurs-add">
    <CollaboratorAddGuideComponent
        title1="Cartographie de l’écosystème de la donnée"
        title2="personnelle de votre entreprise"
        link-route="EcoSystem.Add.Step1"
        button-route="EcoSystem.Add.Step1"
        step-one-text="Vous allez télécharger la liste des collaborateurs de l’entreprise, de tous les collaborateurs. Vous aurez simplement besoin de leur civilité, nom, prénom et adresse email professionnelle."
        step-two-text="Un message prérédigé vous sera proposé, vous pourrez le modifier comme vous le voulez et leur envoyer en un clic. C’est aussi simple, rien de plus, après c’est à eux de jouer, ils en ont pour 5 minutes, montre en main !"
        step-three-text="Vos collaborateurs vont être invités à renseigner un mini questionnaire. Vous saurez donc, qui fait partie, ou pas, de l’écosystème de la donnée personnelle. Plus tard ceux qui en font partie seront formés."
    >
      <div
          class="tw-flex tw-align-start tw-justify-between"
      >
        <h3 class="tw-mt-4 tw-text-justify tw-text-xs md:tw-w-9/12">
          C’est une étape cruciale qui ne vous prendra pas plus de temps que ça.
          Il est important d’identifier les collaborateurs de l’entreprise qui font partie de cet écosystème,
          qui collectent, utilisent, manipulent et archivent les données personnelles de personnes physiques
          que votre entreprise est amenée à traiter dans le cadre de ses activités.
        </h3>
      </div>
    </CollaboratorAddGuideComponent>
  </div>
</template>

<script>
import CollaboratorAddGuideComponent from "@/features/company/components/Collaborateurs/CollaboratorAddGuideComponent";
export default {
  name: "NewEcoSystemGuidePage",
  components: {CollaboratorAddGuideComponent}
}
</script>

<style scoped>

</style>
