<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <router-link :to="{ name: 'LoginPage' }">
        <RetourButtonComponent />
      </router-link>
      <TitleComponent
        class="public-title-auth tw-mt-8"
        text="Redéfinir votre"
      />
      <TitleComponent class="public-title-auth" text="mot de passe. " />
      <div>
        <v-form
          ref="form"
          @submit="onSubmit"
          v-model="formValid"
          class="tw-mt-10"
        >
          <v-row class="tw-mb-24">
            <v-col class="tw-pl-3 tw-pt-0" lg="5" md="6" sm="10" cols="12">
              <PasswordInputComponent
                v-model="form.password"
                :rules="rules.password"
                :error-messages="errors.password"
                label="Rédéfinir votre mot de passe"
                validate
                passwordRules="Au moins 11 caractères, dont 1 majuscule, 1 minuscule, 1 caractère
                            spécial (@, !, _, %, #, :, $, *, ...)"
              />
              <PasswordInputComponent
                :rules="rules.confirmPassword"
                v-model="form.confirmPassword"
                :error-messages="errors.confirmPassword"
                label="Vérification du mot de passe"
                class="tw-pt-8"
              />
              <PrimaryButtonComponent
                class="tw-rounded-lg tw-mt-6"
                :loading="isSendingForm"
                :disabled="!formValid || isSendingForm"
                type="submit"
                >Valider
              </PrimaryButtonComponent>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>
<script>
import PasswordInputComponent from "../../../components/common/PasswordFieldComponent";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import TitleComponent from "../../../components/common/TitleComponent";
import { validationRules } from "../../../utils/validation-rules";
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import { resetPassword } from "@/features/auth/services/appApi";
export default {
  name: "newPassword",
  components: {
    TitleComponent,
    PasswordInputComponent,
    PrimaryButtonComponent,
    RetourButtonComponent,
  },

  data() {
    return {
      formValid: false,
      formSent: false,
      isSendingForm: false,
      errors: {
        password: "",
        confirmPassword: "",
      },
      form: {
        password: "",
        confirmPassword: "",
      },
      rules: {
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.form.password),
        ],
      },
    };
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      resetPassword(this.$route.params.token, {
        password: this.form.password,
      })
        .then((response) => {
          this.isSendingForm = false;
          this.formSent = true;
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "success",
          });
          this.$router.push({ name: "LoginPage" });
        })
        .catch(({ response }) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
          }
          this.$refs.form.validate();
        });
    },
  },
};
</script>
<style></style>
