import { render, staticRenderFns } from "./ExpertFirstStepRegistrationPage.vue?vue&type=template&id=74711b3b&scoped=true&"
import script from "./ExpertFirstStepRegistrationPage.vue?vue&type=script&lang=js&"
export * from "./ExpertFirstStepRegistrationPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74711b3b",
  null
  
)

export default component.exports