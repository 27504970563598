<template>
  <div class="tw-pt-8 tw-px-1" id="dashboard">
    <EntrepriseHeaderBlockTextComponent title="Mon dashboard" />
    <template v-if="isLoading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-mt-6 tw-flex align-center" id="dashboard-block" :style="{ backgroundColor: `${referer.theme.ctaBgColor} !important` }">
        <div class="tw-w-3/4 tw-flex tw-flex-col tw-align-start tw-justify-between">
          <h1 class="title-block-dashboard">Avec Viqtor® EXPERT, c’est vous l’expert !</h1>
          <span class="text-block-dashboard tw-mt-8">
            Le management des ressources internes et externes de vos clients est un volet chronophage de leur conformité et nécessite une attention particulière. 
            <br /><br />
            En votre qualité d’Expert du RGPD, Viqtor® vous permet de gagner en efficacité et surtout beaucoup temps pour former les collaborateurs et évaluer les sous-traitants qui font partie de l’écosystème de la donnée personnelle de vos clients.
          </span>
        </div>
        <div class="tw-w-1/4 tw-flex tw-align-center tw-justify-end">
          <img src="@/assets/images/dashboard/dashboard-icon.png" width="150" alt="logo" />
        </div>
      </div>

      <div class="tw-flex tw-gap-x-4 tw-mt-6">
        <div class="tw-w-full tw-pt-4 tw-rounded-3xl" style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);">
          <span class="tw-pl-6 tw-font-bold tw-text-lg">Liste de vos clients</span>
          
          <div v-if="companies.length > 0" class="tw-flex tw-justify-between tw-space-x-4 tw-mt-4">
            <perfect-scrollbar class="expert-scrollbar tw-overflow-auto" style="height: 390px; width: 70%;">
              <table class="tw-w-full tw-h-full tw-text-xs tw-font-medium">
                <tr
                  v-for="(company, index) in companies"
                  :key="index"
                  class="hover:tw-bg-theme-primary hover:tw-text-white tw-group tw-cursor-pointer"
                  :class="{ 
                    'tw-bg-theme-primary tw-text-white': selectedCompany.id === company.id, 
                    'tw-bg-[#daf1f6]': index % 2 === 0 && selectedCompany.id !== company.id
                  }"
                  @click="showClientAnalytics(company.id)"
                >
                  <td class="tw-pl-6 tw-py-3 tw-text-left">{{ company.name }}</td>
                  <td class="tw-pl-6 tw-py-3 tw-text-left">{{ company.contact }}</td>
                  <!-- <td class="tw-pl-6 tw-py-3 tw-text-center">{{ company.email }}</td> -->
                  <td class="tw-pl-6 tw-pr-4 tw-py-3 tw-flex tw-justify-end">
                    <div
                      @click.stop="company.isAccountActive && showClient(company.userId)"
                      class="tw-flex tw-items-center tw-space-x-6 tw-py-2 tw-px-8 tw-rounded-3xl"
                      :class="{
                        'tw-cursor-pointer tw-bg-theme-primary group-hover:tw-bg-white tw-text-white group-hover:!tw-text-theme-primary': company.isAccountActive,
                        'tw-cursor-default tw-bg-[#E5E5E5] tw-text-[#BDBDBD]': !company.isAccountActive,
                        'tw-bg-white !tw-text-theme-primary': selectedCompany.id === company.id && company.isAccountActive,
                      }"
                      style="width: fit-content;"
                    >
                      <span style="width: max-content;">Voir le compte</span>
                      <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.01204e-06 1.82032V12.1803C2.01204e-06 12.9703 0.870002 13.4503 1.54 13.0203L9.68 7.84032C9.82251 7.75013 9.9399 7.62536 10.0212 7.47762C10.1026 7.32988 10.1452 7.16397 10.1452 6.99532C10.1452 6.82666 10.1026 6.66075 10.0212 6.51301C9.9399 6.36527 9.82251 6.24051 9.68 6.15032L1.54 0.980316C1.38917 0.882761 1.2148 0.827723 1.0353 0.82101C0.855793 0.814296 0.677803 0.856156 0.520105 0.942173C0.362408 1.02819 0.23085 1.15517 0.13931 1.30973C0.0477698 1.46429 -0.000358634 1.64069 2.01204e-06 1.82032Z" fill="currentColor"/>
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr v-if="companies.length < 2" class="hover:tw-bg-theme-primary hover:tw-text-white tw-pl-6"><td colspan="100%" class="tw-py-6"></td></tr>
                <tr v-if="companies.length < 3" class="hover:tw-bg-theme-primary hover:tw-text-white tw-bg-[#daf1f6] tw-pl-6"><td colspan="100%" class="tw-py-6"></td></tr>
                <tr v-if="companies.length < 4" class="hover:tw-bg-theme-primary hover:tw-text-white tw-pl-6"><td colspan="100%" class="tw-py-6"></td></tr>
                <tr v-if="companies.length < 5" class="hover:tw-bg-theme-primary hover:tw-text-white tw-bg-[#daf1f6] tw-pl-6"><td colspan="100%" class="tw-py-6"></td></tr>
                <tr v-if="companies.length < 6" class="hover:tw-bg-theme-primary hover:tw-text-white tw-pl-6"><td colspan="100%" class="tw-py-6"></td></tr>
                <tr v-if="companies.length < 7"  class="hover:tw-bg-theme-primary hover:tw-text-white tw-bg-[#daf1f6] tw-pl-6"><td colspan="100%" class="tw-py-6"></td></tr>
              </table>
            </perfect-scrollbar>
            <div style="width: 30%;">
              <div class="tw-bg-[#daf1f6] tw-p-4" style="box-shadow: 0px 1.949px 8.77px 0px rgba(0, 0, 0, 0.10);">
                <div class="tw-font-bold tw-text-xs tw-text-center">
                  {{ !!selectedCompany.name ? selectedCompany.name : ' - ' }}
                </div>
                <div class="tw-mt-3 tw-space-y-2">
                  <div class="tw-flex tw-items-center">
                    <div class="tw-text-xs tw-w-full">Registres de traitement</div>
                    <v-progress-linear 
                      style="width: 250px; pointer-events: none;"
                      height="5"
                      rounded
                      v-model="selectedCompany.analytics.registreProgress"
                    ></v-progress-linear>
                  </div>
                  <div class="tw-flex tw-items-center">
                    <div class="tw-text-xs tw-w-full">Sous-traitants</div>
                    <v-progress-linear
                      style="width: 250px; pointer-events: none;"
                      height="5"
                      rounded
                      v-model="selectedCompany.analytics.sousTraitantProgress"
                    ></v-progress-linear>
                  </div>
                  <div class="tw-flex tw-items-center">
                    <div class="tw-text-xs tw-w-full">Ecosystème de la donnée</div>
                    <v-progress-linear
                      style="width: 250px; pointer-events: none;"
                      height="5"
                      rounded
                      v-model="selectedCompany.analytics.ecosystemProgress"
                    ></v-progress-linear>
                  </div>
                  <div class="tw-flex tw-items-center">
                    <div class="tw-text-xs tw-w-full">E-learning</div>
                    <v-progress-linear
                      style="width: 250px; pointer-events: none;"
                      height="5"
                      rounded
                      v-model="selectedCompany.analytics.elearningProgress"
                    ></v-progress-linear>
                  </div>
                  <div class="tw-flex tw-items-center">
                    <div class="tw-text-xs tw-w-full">Enrôlement interne</div>
                    <v-progress-linear
                      style="width: 250px; pointer-events: none;"
                      height="5"
                      rounded
                      v-model="selectedCompany.analytics.enrollementProgress"
                    ></v-progress-linear>
                  </div>
                  <div class="tw-flex tw-items-center">
                    <div class="tw-text-xs tw-w-full">Documentation</div>
                    <v-progress-linear
                      style="width: 250px; pointer-events: none;"
                      height="5"
                      rounded
                      v-model="selectedCompany.analytics.documentationProgress"
                    ></v-progress-linear>
                  </div>
                </div>
              </div>
              <div class="tw-bg-[#daf1f6] tw-px-4 tw-py-14 tw-mt-3" style="box-shadow: 0px 1.949px 8.77px 0px rgba(0, 0, 0, 0.10);">
                <div class="tw-font-bold tw-text-xs tw-text-center">Score de conformité</div>
                <div class="tw-mt-4 tw-font-semibold tw-text-4xl tw-text-center tw-text-theme-primary">
                  {{ 
                    selectedCompany.analytics.conformityScore > 0 
                      ? `${selectedCompany.analytics.conformityScore} %` : ' - ' 
                  }}
                </div>
              </div>
            </div>
          </div>
          
          <div class="tw-py-6 tw-flex tw-justify-center"><span v-if="!companies.length">Pas de données disponibles</span></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { getDataBaseClientExpert, loginToClientExpert, getAnalyticsForClient } from "@/features/company/services/appApi";

export default {
  name: "ExpertClientDashboardPage",
  components: {
    EntrepriseHeaderBlockTextComponent
  },
  data() {
    return {
      isLoading: true,
      companies: [],
      selectedCompany: {
        id: 0,
        name: "",
        contact: "",
        userId: 0,
        isAccountActive: false,
        analytics: {
          registreProgress: 0,
          sousTraitantProgress: 0,
          ecosystemProgress: 0,
          elearningProgress: 0,
          enrollementProgress: 0,
          documentationProgress: 0,
          conformityScore: 0,
        },
      },
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return ["Viqtor Expert"].includes(this.referer.title) &&
        this.user.expertType !== "company"
        ? "Company.DashboardPage"
        : "Company.Expert.DashboardPage";
    },
  },
  mounted() {
    this.pagination();
  },
  methods: {
    pagination() {
      getDataBaseClientExpert(1, { noLimit: true })
        .then(({ data: { items } }) => {
          this.companies = items.map((company) => {
            return {
              id: company.id,
              name: company.socialReason,
              contact: company.users[0].firstName + " " + company.users[0].lastName,
              email: company.users[0].email,
              userId: company.users[0].id,
              isAccountActive: company.users[0].isAccountActive,
            };
          });
          if (this.companies.length > 0) this.showClientAnalytics(this.companies[0].id);
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors du chargement des données",
            type: "error",
          });
        })
        .finally(() => { this.isLoading = false; });
    },
    showClientAnalytics(companyId) {
      if (this.selectedCompany.id === companyId) return;

      getAnalyticsForClient(companyId)
        .then(({ data }) => {
          this.selectedCompany = { ...this.companies.find((c) => c.id === companyId) };
          this.selectedCompany.analytics = data;
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
    showClient(userId) {
      loginToClientExpert(userId)
        .then((response) => {
          this.$store.dispatch("setExpertToken").then(() => {
            this.$store.dispatch("setToken", response.data.data.accessToken);
            if (
              !response.data.data.planPriceId &&
              response.data.data.subscribed === false
            )
              this.$router.push({ name: "SetupAccountCompany" });
            if (
              response.data.data.planPriceId &&
              response.data.data.subscribed === false
            )
              this.$router.push({
                name: "SetupAccountPricingCompany",
                params: { planId: response.data.data.planPriceId },
              });
            if (response.data.data.subscribed === true)
              this.$router.push({ name: this.dashboardName });

            this.$snackbar.showAlertMessage({
              message: "Vous êtes connecté en tant que client",
              type: "success",
            });
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
  }
};
</script>

<style>
.expert-scrollbar .ps__thumb-y,
.expert-scrollbar .ps__rail-y:hover > .ps__thumb-y,
.expert-scrollbar .ps__rail-y:focus > .ps__thumb-y,
.expert-scrollbar .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #0aa1c4 !important;
}
.v-progress-linear .v-progress-linear__background.primary {
  background-color: #ffffff !important;
  opacity: 1 !important;
}
</style>