<template>
  <div>
    <template v-if="isLoading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div>
        <EntrepriseHeaderBlockTextComponent
          title="Mes factures"
          description=""
        />
      </div>

      <a style="width: fit-content" class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="$router.push({ name: 'Company.Profile' })">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
        </svg>
        <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
      </a>

      <div class="tw-mt-4">
        <!-- <div class="tw-flex tw-items-center tw-justify-end tw-space-x-2">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 12.175L3.175 7.35L4.25 6.275L7.25 9.275V0H8.75V9.275L11.75 6.275L12.825 7.35L8 12.175ZM1.5 16C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V10.925H1.5V14.5H14.5V10.925H16V14.5C16 14.9 15.85 15.25 15.55 15.55C15.25 15.85 14.9 16 14.5 16H1.5Z" :fill="referer.theme.ctaBgColor"/>
          </svg>
          <span :style="{color: `${referer.theme.ctaBgColor}`}" class="tw-font-bold tw-text-xs tw-cursor-pointer">Télécharger tout</span>
        </div> -->

        <div style="background: #FAFAFA;">
          <v-simple-table class="tw-mt-5 tw-px-10 tw-py-4 tw-rounded-2xl" style="background: #FAFAFA;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left tw-text-sm tw-font-medium tw-tracking-wide">
                    Numéro de facture
                  </th>
                  <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide">
                    Date
                  </th>
                  <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide">
                    Montant
                  </th>
                  <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide">
                    Afficher la facture
                  </th>
                </tr>
              </thead>
              <tbody class="tw-pt-4">
                <tr v-for="invoice in invoices" :key="invoice.number" :style="{color: invoice.isPayed ? 'auto' : '#F60C0C'}">
                  <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-medium tw-tracking-wide">{{ invoice.number }}</td>
                  <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-center">{{ invoice.date }}</td>
                  <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-center">{{ (invoice.total * 0.01).toFixed(2).replace(".", ",") }} € <span v-if="!invoice.isPayed">- non payé</span></td>
                  <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-semibold tw-tracking-wide tw-flex tw-items-center tw-justify-center">
                    <a 
                      class="tw-text-white tw-py-2 tw-px-12 tw-rounded-md"
                      :style="{
                        width: 'fit-content', 
                        background: invoice.isPayed ? referer.theme.ctaBgColor : '#F01421'
                      }"
                      :href="invoice.invoicePdf"
                    >{{ invoice.isPayed ? 'Détails de la facture' : 'Régulariser'}}</a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="tw-flex tw-items-baseline tw-justify-center tw-space-x-8 tw-pb-3 tw-text-sm">
            <pagination-component
              :length="pagesCount"
              :page="selectedPage"
              @changePage="changePageHandler"
            />
          </div>
        </div>
      </div>

      <div class="tw-mt-20">
        <EntrepriseHeaderBlockTextComponent
          title="Méthode de paiement"
          description=""
        />
      </div>

      <div style="background-color: #fafafa;" class="tw-mb-16 tw-pt-12 tw-px-20 tw-pb-5 tw-mt-16 tw-flex tw-flex-col tw-items-center">
        <div style="background-color: white;" class="tw-py-3 tw-px-3 tw-w-full tw-space-y-2">
          <div v-for="method in paymentMethods" :key="method.id" class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-3 tw-rounded-md" style="border: .75px solid #f3f4f6;">
            <div class="tw-flex tw-space-x-4">
              <div class="tw-flex tw-items-center tw-justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#4d5562" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"/></svg>
              </div>
              <div class="tw-flex tw-flex-col tw-space-y-1">
                <span class="tw-flex tw-items-center tw-font-medium tw-capitalize">
                  {{ method.brand }}
                  <div class="tw-flex tw-px-2 tw-space-x-1">
                    <svg width="8" height="8" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8.3489e-09C5.97968 -5.65935e-05 6.93779 0.28769 7.75533 0.827499C8.57287 1.36731 9.21382 2.1354 9.59856 3.03636C9.98331 3.93733 10.0949 4.93147 9.91948 5.89531C9.74405 6.85915 9.28935 7.75023 8.61183 8.45786C7.93431 9.16549 7.06384 9.65849 6.10853 9.87563C5.15322 10.0928 4.15517 10.0245 3.23834 9.67926C2.3215 9.33403 1.52629 8.72707 0.951458 7.93376C0.37663 7.14045 0.0475181 6.19575 0.00500011 5.217L0 5L0.00500011 4.783C0.0609194 3.49575 0.611607 2.27978 1.54222 1.38866C2.47284 0.497541 3.71154 7.44508e-05 5 8.3489e-09Z" fill="black"/></svg>
                    <svg width="8" height="8" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8.3489e-09C5.97968 -5.65935e-05 6.93779 0.28769 7.75533 0.827499C8.57287 1.36731 9.21382 2.1354 9.59856 3.03636C9.98331 3.93733 10.0949 4.93147 9.91948 5.89531C9.74405 6.85915 9.28935 7.75023 8.61183 8.45786C7.93431 9.16549 7.06384 9.65849 6.10853 9.87563C5.15322 10.0928 4.15517 10.0245 3.23834 9.67926C2.3215 9.33403 1.52629 8.72707 0.951458 7.93376C0.37663 7.14045 0.0475181 6.19575 0.00500011 5.217L0 5L0.00500011 4.783C0.0609194 3.49575 0.611607 2.27978 1.54222 1.38866C2.47284 0.497541 3.71154 7.44508e-05 5 8.3489e-09Z" fill="black"/></svg>
                    <svg width="8" height="8" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8.3489e-09C5.97968 -5.65935e-05 6.93779 0.28769 7.75533 0.827499C8.57287 1.36731 9.21382 2.1354 9.59856 3.03636C9.98331 3.93733 10.0949 4.93147 9.91948 5.89531C9.74405 6.85915 9.28935 7.75023 8.61183 8.45786C7.93431 9.16549 7.06384 9.65849 6.10853 9.87563C5.15322 10.0928 4.15517 10.0245 3.23834 9.67926C2.3215 9.33403 1.52629 8.72707 0.951458 7.93376C0.37663 7.14045 0.0475181 6.19575 0.00500011 5.217L0 5L0.00500011 4.783C0.0609194 3.49575 0.611607 2.27978 1.54222 1.38866C2.47284 0.497541 3.71154 7.44508e-05 5 8.3489e-09Z" fill="black"/></svg>
                    <svg width="8" height="8" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8.3489e-09C5.97968 -5.65935e-05 6.93779 0.28769 7.75533 0.827499C8.57287 1.36731 9.21382 2.1354 9.59856 3.03636C9.98331 3.93733 10.0949 4.93147 9.91948 5.89531C9.74405 6.85915 9.28935 7.75023 8.61183 8.45786C7.93431 9.16549 7.06384 9.65849 6.10853 9.87563C5.15322 10.0928 4.15517 10.0245 3.23834 9.67926C2.3215 9.33403 1.52629 8.72707 0.951458 7.93376C0.37663 7.14045 0.0475181 6.19575 0.00500011 5.217L0 5L0.00500011 4.783C0.0609194 3.49575 0.611607 2.27978 1.54222 1.38866C2.47284 0.497541 3.71154 7.44508e-05 5 8.3489e-09Z" fill="black"/></svg>
                  </div>
                  {{ method.last4 }}
                </span>
                <span v-if="method.expirationDate" class="tw-text-sm" style="color: #6c727f;">Expiré {{ method.expirationDate }}</span>
              </div>
              <span
                v-if="method.isDefault"
                class="tw-text-sm tw-font-medium tw-px-3 tw-py-0.5 tw-rounded-xl"
                style="height: fit-content; background-color: #dee9fc;"
                :style="{color: `${referer.theme.ctaBgColor}`}"
              >Défaut</span>
            </div>
            <div class="tw-flex tw-items-center tw-space-x-4">
              <svg v-if="!method.isDefault" @click="setDefaultPaymentMethodHandler(method)" :style="{ color: `${referer.theme.ctaBgColor}` }" class="tw-cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M9.944 3.25h4.112c1.838 0 3.294 0 4.433.153c1.172.158 2.121.49 2.87 1.238c.748.749 1.08 1.698 1.238 2.87c.09.673.127 1.456.142 2.363a.755.755 0 0 1 .004.23c.007.566.007 1.178.007 1.84V12a.75.75 0 0 1-1.5 0c0-.446 0-.862-.002-1.25H2.752c-.002.388-.002.804-.002 1.25c0 1.907.002 3.261.14 4.29c.135 1.005.389 1.585.812 2.008c.423.423 1.003.677 2.009.812c1.028.138 2.382.14 4.289.14h4a.75.75 0 0 1 0 1.5H9.944c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87c-.153-1.14-.153-2.595-.153-4.433v-.112c0-.662 0-1.274.007-1.84a.757.757 0 0 1 .003-.23c.016-.907.053-1.69.143-2.363c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238c1.14-.153 2.595-.153 4.433-.153Zm-7.168 6h18.448a15.6 15.6 0 0 0-.114-1.54c-.135-1.005-.389-1.585-.812-2.008c-.423-.423-1.003-.677-2.009-.812c-1.027-.138-2.382-.14-4.289-.14h-4c-1.907 0-3.261.002-4.29.14c-1.005.135-1.585.389-2.008.812c-.423.423-.677 1.003-.812 2.009c-.06.445-.094.952-.114 1.539Zm15.694 4.22a.75.75 0 0 1 1.06 0l2 2a.75.75 0 1 1-1.06 1.06l-.72-.72V20a.75.75 0 0 1-1.5 0v-4.19l-.72.72a.75.75 0 1 1-1.06-1.06l2-2ZM5.25 16a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Zm6.5 0a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd"/></svg>
              <svg v-if="paymentMethods.length > 1" @click="removePaymentMethodHandler(method)" class="tw-text-red-500 tw-cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
            </div>
          </div>
        </div>

        <a 
          class="tw-mt-8 tw-text-white tw-py-2 tw-px-12 tw-rounded-md tw-font-semibold"
          :style="{ width: 'fit-content', background: referer.theme.ctaBgColor }"
          @click.prevent="$router.push({ name: 'Company.Profile.AddPaymentMethod' })"
        >Ajouter</a>
      </div>
    </template>

    <v-dialog 
      v-if="canShowRemovePaymentMethodDialog" 
      v-model="canShowRemovePaymentMethodDialog"
      @click:outside="canShowRemovePaymentMethodDialog = false"
      width="600"
      transition="dialog-bottom-transition"
    >
      <div id="modifyDialog" class="tw-p-8">
        <h2 class="tw-font-bold tw-text-center tw-text-3xl">
          Supprimer cette méthode de paiement
        </h2>
        <p class="tw-text-center tw-text-base tw-mt-4">
          Êtes-vous sûr de vouloir supprimer cette méthode de paiement ?
        </p>
        <hr class="tw-w-full tw-border-black" />
        <div class="tw-flex tw-justify-around tw-w-full tw-mt-4">
          <v-btn 
            elevation="3" 
            text large 
            @click="canShowRemovePaymentMethodDialog = false" 
            class="tw-text-gray-100 tw-cursor-pointer normal-btn"
          >
            ANNULER
          </v-btn>
          <v-btn 
            elevation="3" 
            text large 
            :loading="isRemovingPaymentMethod" 
            :disabled="isRemovingPaymentMethod" 
            @click.prevent="confirmRemovePaymentMethodHandler"
            class="tw-text-white tw-cursor-pointer"
            :style="{ background: referer.theme.ctaBgColor }"
          >
            SUPPRIMER
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import paginationComponent from '@/components/common/PaginationComponent.vue';
import { getUserInvoices, getUserPaymentMethods, removeUserPaymentMethod, setUserDefaultPaymentMethod } from "../../../services/appApi";

export default {
  name: "ProfileInvoicesPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    paginationComponent,
  },
  data() {
    return {
      isLoading: true,
      invoices: [],
      paymentMethods: [],
      limit: 10,
      pagesCount: 1,
      selectedPage: 1,
      selectedPaymentMethod: null,
      canShowRemovePaymentMethodDialog: false,
      isRemovingPaymentMethod: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  async mounted() {
    await this.getInvoices();
    await this.getPaymentMethods()
    this.isLoading = false;
  },
  methods: {
    async getInvoices() {
      try {
        const { data: { items, meta } } = await getUserInvoices({ 
          page: this.selectedPage, limit: this.limit 
        });
        this.invoices = items;
        this.pagesCount = meta.pagesCount;
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la récupération des factures",
          type: "error",
        });
      }
    },
    async getPaymentMethods() {
      try {
        const { data } = await getUserPaymentMethods();
        this.paymentMethods = data;
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la récupération des méthodes de paiement",
          type: "error",
        });
      }
    },
    removePaymentMethodHandler(method) {
      this.selectedPaymentMethod = method;
      this.canShowRemovePaymentMethodDialog = true;
    },
    async confirmRemovePaymentMethodHandler() {
      this.isRemovingPaymentMethod = true;

      try {
        await removeUserPaymentMethod(this.selectedPaymentMethod.id);
        await this.getPaymentMethods();
        this.$snackbar.showAlertMessage({
          message: "La méthode de paiement a été supprimée avec succès",
          type: "success",
        });
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la suppression de la méthode de paiement",
          type: "error",
        });
      }
      finally {
        this.isRemovingPaymentMethod = false;
        this.canShowRemovePaymentMethodDialog = false;
      }
    },
    async changePageHandler(page) {
      this.selectedPage = page;
      this.isLoading = true;
      await this.getInvoices();
    },
    setDefaultPaymentMethodHandler(paymentMethod) {
      setUserDefaultPaymentMethod(paymentMethod.id)
        .then(async () => {
          this.$snackbar.showAlertMessage({
            message: "La méthode de paiement est maintenant par défaut",
            type: "success",
          });
          this.getPaymentMethods();
        })
        .catch(() => {
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de mettre la méthode de paiement par défaut",
            type: "error",
          });
        });
    }
  }
};
</script>