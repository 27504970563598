<template>
  <div class="tw-flex tw-justify-center">
    <div class="tw-w-full tw-flex tw-items-end tw-font-bold">
      <div class="border-steps-circle tw-border-3 tw-border-black-100 tw-flex">
        <span>1</span>
      </div>

      <svg
        class="tw-relative"
        width="50%"
        height="12"
        viewBox="0 0 622 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-96.21875 1.58984C167.629 8.33984 453.579 13.2899 731.009 7.29987"
          :style="{ stroke: step > 1 ? '#1D1D1B' : '#F4F4F4' }"
          stroke-width="3"
          stroke-miterlimit="10"
        />
      </svg>
      <div
        :class="`${
          step > 1
            ? 'tw-border-black-100 tw-text-black-100'
            : 'tw-border-white-100 tw-text-white-100'
        }`"
        class="border-steps-circle tw-border-3 tw-flex"
      >
        <span>2</span>
      </div>
      <svg
        class="tw-relative"
        width="50%"
        height="13"
        viewBox="0 0 635 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-96.460938 8.84991C174.461 15.6099 460.551 7.8499 731.009 1.8999"
          :style="{ stroke: step > 2 ? '#1D1D1B' : '#F4F4F4' }"
          stroke-width="3"
          stroke-miterlimit="10"
        />
      </svg>
      <div
        :class="`${
          step > 2
            ? 'tw-border-black-100 tw-text-black-100'
            : 'tw-border-white-100 tw-text-white-100'
        }`"
        class="border-steps-circle tw-border-3 tw-flex"
      >
        <span>3</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsComponent",
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped></style>
