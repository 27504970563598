<template>
  <div class="tw-p-4">
    <div class="tw-flex tw-mb-12">
      <img src="@/assets/images/dbwo.png" width="150" class="tw-text-right" alt="logo de DBWO" />
    </div>
    <h3 class="tw-font-bold">
      Politique générale de protection des données à caractère personnel
    </h3>
    <br>
    <p class="tw-text-justify">
      La société DBWO s’attache à la mise en œuvre et au respect du règlement no 2016/679, dit règlement général sur la protection des données (RGPD), adopté par le Parlement européen le 14 avril 2016 et dont les dispositions sont directement applicables dans l'ensemble des 28 États membres de l'Union européenne à compter du 25 mai 2018.
    </p>
    <p class="tw-text-justify">
      Le respect de la vie privée et de la protection des données à caractère personnel constitue un facteur de confiance, valeur à laquelle tient particulièrement DBWO, en s’attachant au respect des libertés et droits fondamentaux de chacun.
    </p>
    <p class="tw-text-justify">
      La présente politique de protection des données à caractère personnel témoigne des engagements mis en œuvre par DBWO dans le cadre de ses activités quotidiennes pour une utilisation responsable des données personnelles.
    </p>
    <br>
    <h3 class="tw-font-bold">
      Principes applicables à la protection des données personnelles
    </h3>
    <br>
    <p class="tw-text-justify">
      DBWO conçoit, développe, maintient, et commercialise auprès de ses clients professionnels Viqtor, la plateforme en ligne d’outils juridiques visant à les mettre en conformité avec la règlementation qui l’amènent à recueillir, stocker et traiter des données personnelles dans le respect des lois et réglementations en vigueur, et notamment de la loi Informatique et Libertés du 6 janvier 1978 modifiée et des normes édictées par la CNIL.
    </p>
    <p class="tw-text-justify">
      Elle applique dans la conception de ses solutions les principes définis par la Privacy by Design et mène une politique d’information et de sensibilisation de ses équipes au respect des principes édictés par le Règlement Général pour la Protection des Données.    <br>
    <div class="tw-flex tw-items-baseline">
      <span class="tw-mr-8">1.</span>
      <span>Finalité déterminée, explicite et légitime du traitement :</span>
    </div>
    <br>
    <p class="tw-text-justify">
      Les données personnelles sont collectées dans un cadre contractuel pour des objectifs précis en lien avec les finalités décrites et définis par les besoins du traitement dans le cadre de notre procédure de protection des données personnelles par défaut.
    </p>
    <p class="tw-text-justify">
      En l’occurrence, les données détenues par DBWO lui servent exclusivement
    </p>
    <ul class="tw-ml-12 tw-list-none">
      <li>- A assurer l’accès de ses utilisateurs à leurs services en ligne</li>
      <li>-	A mettre en œuvre les mesures de sécurisations des accès aux données</li>
      <li>- A permettre la communication nécessaire au titre de la règlementation entre l’utilisateur et ses partenaires</li>
      <li>- A tenir ses clients informés des évolutions fonctionnelles de sa plateforme</li>
    </ul>
    <br>
    <div class="tw-flex tw-items-baseline">
      <span class="tw-mr-8">2.</span>
      <span>Proportion et pertinence des données collectées :</span>
    </div>
    <br>
    <p class="tw-text-justify">
      Les données personnelles collectées sont strictement nécessaires à l’objectif poursuivi par la collecte.
    </p>
    <p class="tw-text-justify">
      Les solutions de sécurité DBWO s’attachent à minimiser les données collectées
    </p>
    <p class="tw-text-justify">
      En l’espèce, les données personnelles collectées sont :
    </p>
    <ul class="tw-ml-12 tw-list-none">
      <li>- Les noms et prénom de l’utilisateur.</li>
      <li>- L’adresse e-mail professionnel et le numéro de téléphone professionnel de l’utilisateur.</li>
      <li>- L’employeur et la fonction de l’utilisateur</li>
    </ul>
    <br>
    <div class="tw-flex tw-items-baseline">
      <span class="tw-mr-8">3.</span>
      <span>Durée de conservation limitée des données à caractère personnel :</span>
    </div>
    <br>
    <p class="tw-text-justify">
      Les données à caractère personnel sont conservées par l’opérateur OVH sous la responsabilité de DBWO pendant une durée limitée qui n’excède pas la durée nécessaire aux finalités de collecte et détruites, sous réserves de disposition légales spécifiques, dans un délai maximum d’un mois après que la disparition de leur objet contractuel.
    </p>
    <br>
    <div class="tw-flex tw-items-baseline">
      <span class="tw-mr-8">4.</span>
      <span>Confidentialité / Sécurité des données :</span>
    </div>
    <br>
    <p class="tw-text-justify">
      Des Politiques de Protection des Systèmes d’Information (PSSI) sont mises en œuvre, adaptées à la nature des données traitées et de leur traitement.
    </p>
    <p class="tw-text-justify">
      Des mesures de sécurité physiques, logiques et organisationnelles appropriées sont prévues pour garantir la confidentialité des données, et notamment éviter tout accès non autorisé.
    </p>
    <p class="tw-text-justify">
      DBWO exige également de tout sous-traitant qu’il présente des garanties appropriées pour assurer la sécurité et la confidentialité des données personnelles.
    </p>
    <br>
    <div class="tw-flex tw-items-baseline">
      <span class="tw-mr-8">5.</span>
      <span>Droits des personnes :</span>
    </div>
    <br>
    <p class="tw-text-justify">
      Tous les moyens nécessaires à garantir l’effectivité des droits des personnes sur leurs données personnelles sont mis en œuvre.
    </p>
    <ul class="tw-ml-12 tw-list-none">
      <li>- Les données personnelles sont collectées loyalement ; aucune collecte n’est effectuée à l’insu des personnes et sans qu’elles en soient informées..</li>
      <li>- Les données personnelles collectées sont portées à la connaissance des personnes concernées.</li>
      <li>- Les données collectées ne sont pas utilisées ultérieurement de manière incompatible avec ces finalités.</li>
      <li>- Les données personnelles sont tenues exactes et à jour.</li>
      <li>- Les délais de conservation des données sont portés à la connaissance des personnes, et varient selon la nature des données, la finalité des traitements, ou les exigences légales ou réglementaires.</li>
      <li>- Si des données à caractère personnel devaient faire l’objet de transferts vers des pays situés dans l’Union Européenne ou hors de l’Union Européenne, les personnes concernées en seraient précisément informées, et des mesures spécifiques seraient prises pour encadrer ces transferts.</li>
      <li>- Les moyens nécessaires à garantir l’effectivité des droits des personnes sur leurs données personnelles sont mis en œuvre, notamment par une information claire et complète sur les traitements de données mis en œuvre, facilement accessible et compréhensible par tous.</li>
    </ul>
    <p class="tw-text-justify">
      Toute personne dispose de droits sur les données la concernant, qu’elle peut exercer à tout moment et gratuitement, en justifiant de son identité. Ainsi, les personnes peuvent accéder à leurs données personnelles, et dans certains cas les faire rectifier, supprimer ou s’opposer à leur traitement.
    </p>
    <p class="tw-text-justify">
      L’accès à ces droits (accès, rectification, suppression, opposition, limitation de traitement, portabilité, effacement, droit de ne pas faire l’objet d’une décision individuelle automatisée est facilité par les fonctionnalités des logiciels utilisés par DBWO.
    </p>
    <br>
    <div class="tw-flex tw-items-baseline">
      <span class="tw-mr-8">6.</span>
      <span>Droits issus de la Loi pour une République numérique.</span>
    </div>
    <br>
    <p class="tw-text-justify">
      Le nouvel article 40-1 de la loi Informatique et libertés permet aux personnes de donner des directives relatives à la conservation, à l'effacement et à la communication de leurs données après leur décès.
    </p>
    <p class="tw-text-justify">
      Une personne peut être désignée pour exécuter ces directives. Celle-ci a alors qualité, lorsque la personne est décédée, pour prendre connaissance des directives et demander leur mise en œuvre aux responsables de traitement concernés.
    </p>
    <p class="tw-text-justify">
      En l'absence de directives données de son vivant par la personne, les héritiers auront la possibilité d'exercer certains droits, en particulier :
    </p>
    <ul class="tw-ml-12 tw-list-none">
      <li>- Le droit d'accès, s'il est nécessaire pour le règlement de la succession du défunt ;</li>
      <li>- Le droit d'opposition pour procéder à la clôture des comptes utilisateurs du défunt et s'opposer au traitement de leurs données.</li>
    </ul>
    <br>
    <h3 class="tw-font-bold">Suivi de la Politique de Protection des Données Personnelles.</h3>
    <br>
    <p class="tw-text-justify">
      Cette politique est révisée au moins tous les ans pour prendre en compte les évolutions législatives et réglementaires, et tout changement dans l’organisation de DBWO ou dans les offres, produits et services proposés.
    </p>
    <br>
    <p class="tw-text-justify">
      La présente Politique de Protection des Données Personnelles est complétée
    </p>
    <ul class="tw-ml-12 tw-list-none">
      <li>- D’une documentation de nos solutions sur leurs finalités explicites concernant les personnes, les destinataires des données, leurs durées de conservation, et les modalités d’exercice des droits des personnes.</li>
      <li>- D’une évaluation complète et régulière de nos sous-traitants hébergeurs de données et éditeurs de logiciels quant à la prise en compte de leurs obligations dans le cadre du RPGD.</li>
      <li>- De clauses contractuelles adaptées vis à vis de nos sous-traitants dans le cadre d’un risque normal ou important généré par un traitement sur la protection des données privées.</li>
      <li>- De clause spécifiques de confidentialité sur les contrats de travail de nos collaborateurs ayant à opérer des données privées.</li>
      <li>- D’un règlement portant sur l’utilisation des matériels informatiques.</li>
    </ul>
  </div>
</template>

<script>
export default {
name: "PolitiqueComponent"
}
</script>

<style scoped>

</style>
