<template>
  <div id="collaborateurs-add">
    <CollaboratorAddGuideComponent
        title1="Inviter un collaborateur à faire le e-learning"
        title2=""
        link-route="Elearning.Add.Step1"
        button-route="Elearning.Add.Step1"
        step-one-text="Tous les collaborateurs vont être invités à cliquer sur le message pour accuser réception de l’engagement de la direction. Vous saurez donc, qui a lu le message de la Direction"
        step-two-text="Un message prérédigé vous sera proposé, vous pourrez le modifier comme vous le voulez et leur envoyer en un clic. C’est aussi simple, rien de plus, après c’est à eux de jouer, ils en ont pour 5 minutes, montre en main !"
        step-three-text="Vos collaborateurs vont être invités à renseigner un mini questionnaire. Vous saurez donc, qui fait partie, ou pas, de l’écosystème de la donnée personnelle. Plus tard ceux qui en font partie seront formés."
    >
      <div
          class="tw-flex tw-flex-col tw-align-start tw-justify-between"
      >
        <h3 class="tw-mt-4 tw-text-justify tw-text-xs md:tw-w-9/12">
          L’engagement de la Direction est un point important. Il se manifeste au travers du document
          « Engagement RGPD » qui doit être diffusé à l’interne par email et dont l’objet est « Mise en conformité
          au RGPD de notre société ». Chaque année, Viqtor® synthétise les lignes de force des actions meenées,
          recense l’ensemble des données à chaque acteur interne et les circularise personnellement et
          automatiquement par email. Les nouveaux collaborateurs que vous renseignerez seront immédiatement informés.
          C’est aussi simple !
        </h3>
        <h3 class="tw-mt-2 tw-text-justify tw-text-xs tw-font-bold md:tw-w-9/12">
          Il y a 3 étapes dont seulement deux vous concernent, laissez-vous tout simplement guider !
        </h3>
      </div>
    </CollaboratorAddGuideComponent>
  </div>
</template>

<script>
import CollaboratorAddGuideComponent from "@/features/company/components/Collaborateurs/CollaboratorAddGuideComponent";
export default {
  name: "NewElearningGuidePage",
  components: {CollaboratorAddGuideComponent}
}
</script>

<style scoped>

</style>
