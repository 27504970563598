<template>
  <v-dialog v-if="handelPopup" v-model="handelPopup" @click:outside="closePopup" width="600"
    transition="dialog-bottom-transition">
    <!-- <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/> -->
    <div id="modifyDialog" class="tw-p-8">
      <!-- <img src="@/assets/images/icons/info.svg" alt="logo" /> -->
      <h2 class="tw-font-bold tw-text-center tw-text-3xl">
        Modifier le collaborateur
      </h2>
      <hr class="tw-w-full tw-border-black" />
      <div class="tw-my-10">
        <text-field-component class="register-activity-input" label="" placeholder="Email"
          v-model="collaborator.email" :error-messages="errors.email" />
        <text-field-component class="register-activity-input" label="" placeholder="Nom" v-model="collaborator.lastName"
          :error-messages="errors.lastName" />
        <text-field-component class="register-activity-input" label="" placeholder="Prénom"
          v-model="collaborator.firstName" :error-messages="errors.firstName" />
        <text-field-component class="register-activity-input" label="" placeholder="Fonction"
          v-model="collaborator.function" :error-messages="errors.function" />
      </div>
      <div class="tw-flex tw-justify-around tw-w-full">
        <v-btn elevation="3" text large @click="closePopup" class="tw-text-gray-100 tw-cursor-pointer normal-btn">
          ANNULER
        </v-btn>
        <v-btn elevation="3" text large :loading="isSendingForm" @click="updateContact"
          class="tw-text-white tw-cursor-pointer">
          MODIFIER
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import TextFieldComponent from "../../../components/common/TextFieldComponent.vue";
import { updateCollaborator } from "../services/appApi";
export default {
  components: { TextFieldComponent },
  name: "ModifyPopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      immutable: true
    },
  },
  data() {
    return {
      isSendingForm: false,
      collaborator: {
        email: "",
        firstName: "",
        lastName: "",
        function: "",
      },
      errors: {
        email: "",
        firstName: "",
        lastName: "",
        function: "",
      },
    };
  },
  mounted() {
    this.collaborator = this.item;
    this.$parent.modifyPopup = this.openPopup;
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    handelPopup() {
      return this.$parent.modifyPopup;
    },
  },
  methods: {
    updateContact() {
      this.isSendingForm = true;

      updateCollaborator(this.item.id, {
        id: this.item.id,
        email: this.collaborator.email,
        firstName: this.collaborator.firstName,
        lastName: this.collaborator.lastName,
        function: this.collaborator.function,
      })
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "Modifié avec succès",
            type: "success",
          });
          this.$emit("fetchData");
          this.$parent.modifyPopup = false;
          this.isSendingForm = false;
          this.closePopup();
        })
        .catch(({ response }) => {
          if (response.data.status == 422) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
              this.errors[key] = this.getValidationMessage(key, value);
            });
          }
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
    closePopup() {
      this.$emit("fetchData");
      this.$emit("modifyClose");
    },
    getValidationMessage(key, value) {
      switch (key) {
        case "firstName":
          return [value[0].replace("firstName", "prénom")];
        case "lastName":
          return [value[0].replace("lastName", "nom")];
        case "service":
          return [value[0].replace("service", "service")];
        default:
          return value;
      }
    },
  },
  watch: {
    item(newVal) {
      this.collaborator = newVal;
    },
  },
};
</script>
<style lang="scss" scoped>
#modifyDialog {
  button {
    background-color: var(--cta-bg-color);
  }

  .normal-btn {
    background-color: transparent !important;
    // border-radius: 20px;
  }

  position: relative;

  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }

  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
