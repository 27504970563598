<template>
<div style='display:inline-block;text-align:center;font-family:"Calibri",sans-serif;'>
    <select class="action_menu" v-bind="$attrs"
        @change="onChange($event.target.value)"
        style='display:inline-block;text-align:center;font-family:"Calibri",sans-serif;font-weight:bold;'>
        <slot></slot>
        <option v-for="(item, index) in items" :value="item" :key="`${items}-${index}`" :selected="index==0">{{ item }}</option>
    </select>
</div>
</template>

<script>
export default {
    props:{
        items:{
            type:Array,
            default:()=>[]
        },
    },
    methods: {
        onChange(e) {
            this.$emit('change', e);
        },
    },
    
}
</script>

<style>

</style>