<template>
  <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-12">
    <div class="tw-relative tw-flex tw-justify-center">
      <BadgeComponent :label="team.name" />
    </div>
    <div class="tw-relative">
      <label class="tw-flex tw-items-center tw-justify-center">
        <div
          class="tw-flex tw-h-6 tw-w-6 tw-items-start tw-justify-start tw-border tw-border-gray-900 tw-focus-within:border-gray-500"
        >
          <input
            type="checkbox"
            :checked="team.isAttachedToCompany"
            @change="$emit('selected', team.id)"
            class="tw-absolute tw-opacity-0 tw-focus:outline-none"
          />
          <svg class="tw-pointer-events-none tw-hidden tw-h-[18px] tw-w-[18px] tw-fill-current tw-text-gray-800" viewBox="0 0 20 20">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"/>
          </svg>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "CollaboratorItemComponent",
  props: {
    team: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
input:checked + svg {
  display: block;
}
</style>
