<template>
  <div>
    <v-text-field
        class="auth-text-field"
        :style="inputStyle"
        v-bind="$attrs"
        v-on="$listeners"
        @click:append="toggleRightIcon"
    ></v-text-field>
    <div class="tw-text-black-100 tw-font-normal tw-text-sm tw-mt-2">
      {{ textRules }}
    </div>
  </div>

</template>

<script>
export default {
  name: "TextFieldComponent",
  props: {
    containerStyle: {
      type: String,
      default: "",
    },
    textRules: {
      type: String,
      default: "",
    },
    toggleRightIcon: {
      type: Function,
      default: () => {},
    },
    inputStyle: {
      type: String,
      default: "",
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
