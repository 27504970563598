<template>
  <header
    id="public-header"
    class="tw-bg-white"
    :class="{ active: scrollPosition > 0 }"
    :style="{ padding: theme.theme.headerPadding }"
  >
    <v-container class="tw-pt-0 tw-mt-0">
      <div class="tw-text-black-100 tw-flex tw-justify-between tw-items-center">
        <div>
          <img
            class="tw-cursor-pointer"
            :style="{
              width: theme.theme.logoWidth,
              height: theme.theme.logoHeight,
            }"
            :src="theme.logo"
            alt="logo"
            @click="navigate('landing-public')"
          />
        </div>
      </div>
    </v-container>
  </header>
</template>

<script>
export default {
  name: "Header",

  computed: {
    CheckIfHome: function () {
      return this.$router.resolve({ name: "landing-public" }).resolved.fullPath;
    },
    theme() {
      return this.$store.state.referrer;
    },
  },
  data() {
    let show_default_header = true;
    if (
      this.$route.name === "PreInscriptionLogin" ||
      this.$route.name === "PreInscriptionFirstStep"
    ) {
      show_default_header = false;
    }
    return {
      showSidebar: false,
      scrollPosition: null,
      show_default_header: show_default_header,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    navigate(nav) {
      this.$router.push({ name: nav });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  z-index: 99999999999999;
  transition: all 0.15s linear;
}

header.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>
