<template>
  <div id="quesionnaire-Steps-one" class="tw-mt-4">
    <div class="tw-text-sm tw-underline">
      <router-link :to="{ name: 'EcoSystem.List' }">Retour</router-link>
    </div>
    <div class="tw-text-3xl tw-my-10 tw-font-bold tw-capitalize">
      <h1 v-if="collaborateur">{{ gender[collaborateur.gender] }} {{ collaborateur.firstName }} {{ collaborateur.lastName }}</h1>
    </div>
    <div class="tw-flex tw-flex-col tw-space-y-2">
      <span class="tw-text-xl tw-font-semibold">ECOSYSTÈME DES DONNÉES PERSONNELLES DE &lt;
        {{ user.workInCompany ? user.workInCompany.socialReason : '-' }} &gt;</span>
      <span class="tw-text-l tw-font-bold title-data-trait">Données traitées par « {{ collaborateur.firstName }}
        {{ collaborateur.lastName }} »</span>
    </div>
    <showAnswersTable v-for="(item, index) in questions" :key="index" :items="questions[index]" :isNothingChecked="isNothingChecked" />
    <div class="tw-full tw-text-center">
      <PrimaryButtonComponent @click.prevent="updateCollaborateur"
        class="tw-text-white bg-color-primary tw-w-[140px] tw-m-auto" :loading="isSendingForm" type="button">
        Valider
      </PrimaryButtonComponent>
    </div>
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { getCollaboratorAnswers, updateCollaboratorAnswers } from "@/features/company/services/appApi";
import showAnswersTable from "./Questionnaire/components/showAnswersTable.vue"
export default {
  name: "EcosystemPersonelData",
  data() {
    return {
      isSendingForm: false,
      collaborateur: null,
      questions: [],
      gender: {
        male: "Monsieur",
        female: "Madame",
        none: ""
      }
    };
  },
  components: {
    showAnswersTable,
    PrimaryButtonComponent
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isNothingChecked() {
      let count = 0;
      this.questions.forEach(el => {
        el.forEach(item => {
          item.answers.forEach(answer => {
            answer.forEach(e => {
              if (e.content == "Aucunes" && e.checked) {
                count++;
              }
            })
          })
        })
      })
      return count > 0;
    }
  },
  mounted() {
    this.getCollaborateur();
  },
  methods: {
    getCollaborateur() {
      getCollaboratorAnswers(this.$route.params.id).then(res => {
        this.collaborateur = res.data.data.collaborateur
        this.questions = JSON.parse(res.data.data.ecosystemQuiz)

        if (!this.questions)
          this.$router.push({ name: 'EcoSystem.List' })

      })
    },
    updateCollaborateur() {
      this.isSendingForm = true;
      updateCollaboratorAnswers(this.$route.params.id, { ecosystemQuiz: JSON.stringify(this.questions),inactive:this.isNothingChecked==1 }).then(res => {
        this.collaborateur = res.data.data.user
        this.questions = JSON.parse(res.data.data.ecosystemQuiz)
        this.$snackbar.showAlertMessage({
          message: 'L’Écosystème des données ont été mises à jour',
          type: "success",
        });
        this.$router.push({ name: 'EcoSystem.List' })
      }).finally(() => this.isSendingForm = false)
    },
  },
  // watch isNothingChecked
  watch: {
    isNothingChecked: function (val) {
      if (val) {
        this.questions.forEach(el => {
          el.forEach(item => {
            item.answers.forEach(answer => {
              answer.forEach(e => {
                if (e.id>43 || (e.id>25 && e.id<43)) {
                  e.checked=false;
                }
              })
            })
          })
        })
      }
    }
  }
};
</script>

<style scoped>

</style>
