<template>
  <div class="tw-relative">
    <div class="tw-mt-6 md:tw-px-80 tw-px-10">
      <div class="tw-mx-6">
        <img :src="logo" alt="logo" width="140" />
      </div>
    </div>
    <div
      class="tw-flex tw-flex-col tw-space-y-3 tw-my-36 md:tw-ml-80 tw-ml-20 tw-select-none"
    >
      <h2 class="tw-font-extrabold tw-text-4xl">
        {{ question.id }}- {{ question.title }}?
      </h2>
      <hr class="tw-w-2/4 tw-border bg-color-primary" />
      <div class="tw-text-3xl">
        <span
          class=" tw-flex tw-items-center tw-space-x-3 tw-mx-3 font-color-primary"
          v-if="correct"
          ><answerRight /><span>Bonne réponse</span></span
        >
        <span
          class="tw-flex tw-items-center tw-space-x-3 tw-mx-3 tw-text-red-500"
          v-else
          ><answerWrong /><span>Mauvaise réponse</span></span
        >
      </div>
      <div class="tw-m-6 tw-flex tw-flex-col tw-space-y-4">
        <div class="tw-w-3/4" v-html="question.information"> 
        </div>
      </div>
      <span
        v-if="question.id < questions.length"
        class="tw-cursor-pointer"
        @click="backToNextQuestion()"
        >Question suivante &#8594;</span
      >
      <span v-else class="tw-cursor-pointer" @click="showResult()"
        >Voir le score &#8594;</span
      >
    </div>
  </div>
</template>

<script>
import answerRight from "@/components/common/AnswerRight";
import answerWrong from "@/components/common/AnswerWrong";
import { storeCollaboratorElearningAnswers } from "@/features/company/services/appApi";

export default {
  name: "ElearningQuizAnswers",
  props: ["correct", "questions", "question", "total", "answers"],
  data() {
    return {};
  },
  components: {
    answerRight,
    answerWrong,
  },
  computed: {
    logo() {
      return this.$store.state.referrer.logo;
    },
  },
  methods: {
    backToNextQuestion() {
      this.$parent.index++;
      this.$parent.correct = false;
      this.$parent.show = true;
    },
    showResult() {
      this.$store.commit(
        "SET_TOTAL_ANSWERS_CORRECT",
        (this.total * 100) / this.questions.length
      );
      this.$store.commit(
        "SET_CORRECT_ANSWERS",
        this.total
      );

      let data = {
        score: parseInt((this.total * 100) / this.questions.length),
        quiz: JSON.stringify(this.answers),
      };
      storeCollaboratorElearningAnswers(
        this.$store.getters.getElearningToken,
        data
      )
        .then(() => this.$router.push({ name: "Elearning-quiz-end" }))
        .catch(({ response }) => {
          if (response.status == 400) {
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                position: "bottom right",
                text: item,
              });
            });
          }
        });
    },
  },
};
</script>

<style>
</style>