<template>
  <div id="input-paiement-container"
       class="
       tw-flex
       tw-flex-col
       md:tw-flex-row
       lg:tw-flex-row
       md:tw-items-center
       lg:tw-items-center
       md:tw-justify-end
       lg:tw-justify-end
       md:tw-space-x-3"
  >
    <div class="tw-flex tw-flex-col tw-w-5/12">
      <span class="paiement-input-label tw-w-full">{{label}}</span>
      <span class="paiement-input-sublabel tw tw-w-full" v-if="subLabel">{{subLabel}}</span>
    </div>

    <input type="text" class="paiement-input tw-w-7/12" :value="value" @input="inputEvent" />
  </div>
</template>

<script>
export default {
  name: "TextFieldPaiementComponent",
  props: {
    label: String,
    subLabel: String,
    value: String,
  },
  methods: {
    inputEvent(e) {
      this.$emit('input', e.target.value);
    },
  }
}
</script>

<style lang="scss" scoped>
#input-paiement-container {

  .paiement-input {
    min-height: 20px;
    border: 0.5px solid  var(--cta-bg-color);
    box-sizing: border-box;
    padding: 2px;
  }

  .paiement-input-label {
    font-family: Poppins, 'sans-serif';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #25282B;
    text-align: right;
  }

  .paiement-input-sublabel {
    font-family: Poppins, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 22px;
    color: #828282;
    text-align: right;
    margin-top: -5px;
  }

}

</style>