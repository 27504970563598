<template>
  <div id="historique-table">
    <h1 class="mon-historique-table-title">{{ title }}</h1>
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <!-- table  -->
      <v-simple-table id="contact-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Raison sociale</th>
              <th>Contact</th>
              <th>Date de partage</th>
              <th>Supprimer</th>
            </tr>
          </thead>
          <tbody v-if="accepter_data.length > 0">
            <tr v-for="item in accepter_data" :key="item.name">
              <td>{{ item.client.socialReason }}</td>
              <td>
                {{
                  item.client.user.firstName + " " + item.client.user.lastName
                }}
              </td>
              <td>
                {{ item.shareDate | get_date_evaluation }}
              </td>
              <td>
                <img
                  src="@/assets/images/icons/supprimer.svg"
                  alt="supprimer"
                  class="tw-cursor-pointer"
                  @click="supprimerContact(item)"
                />
              </td>
            </tr>
            <ConfirmeDeletePopup
              v-if="selectedItem && openPopup"
              :openPopup="openPopup"
              :item="selectedItem"
              v-on:fetchData="fetchData"
              @close="openPopup = false"
            />
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">
                Pas de données disponibles
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <h1 class="mon-historique-table-title">{{ title2 }}</h1>
    <template v-if="loading_qst">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <!-- table  -->
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Raison sociale</th>
              <th>Contact</th>
              <th>
                <div>Date de refus</div>
              </th>
              <th>
                <div>
                  Accepter
                  <img
                    v-if="!accepter"
                    src="@/assets/images/icons/accepter-head.svg"
                    alt="Accepter"
                    @click="accepterAll"
                  />
                  <img
                    v-else
                    src="@/assets/images/icons/refuser-head.svg"
                    alt="Accepter"
                    @click="accepterAll"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="refuser_data.length > 0">
            <tr v-for="item in refuser_data" :key="item.id">
              <td>{{ item.client.socialReason }}</td>
              <td>
                {{
                  item.client.user.firstName + " " + item.client.user.lastName
                }}
              </td>
              <td>
                {{ item.shareDate | get_date_evaluation }}
              </td>
              <td>
                <label :for="'Accepter[' + item.id + ']'">
                  <img
                    src="@/assets/images/icons/radio-input.svg"
                    v-if="item.Accepter == 0 || item.Accepter == null"
                  />
                  <input
                    type="checkbox"
                    :name="'result[' + item.id + ']'"
                    :id="'Accepter[' + item.id + ']'"
                    value="1"
                    v-model="item.Accepter"
                  />
                  <img
                    src="@/assets/images/icons/radio-check.svg"
                    alt="check"
                    v-if="item.Accepter == 1"
                  />
                </label>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">
                Pas de données disponibles
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- end table  -->
      <v-btn
        v-if="refuser_data.length > 0"
        elevation="1"
        text
        rounded
        large
        class="tw-text-white disabled:opacity-50"
        :loading="isSendingForm"
        @click="onSubmit"
      >
        {{ formSubmitButton }}
      </v-btn>
    </template>
  </div>
</template>
<script>
import {
  HistoriqueValiderContactApi,
  HistoriqueShareGetContactApi,
} from "../../services/appApi";
import moment from "moment";
import ConfirmeDeletePopup from "@/features/company/components/partage/ConfirmeDeletePopup";
export default {
  name: "EntrepriseHistoriqueTableComponent",
  components: { ConfirmeDeletePopup },
  props: {
    title: {
      type: String,
      default: "",
    },
    title2: {
      type: String,
      default: "",
    },
  },
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    accepterAllImg() {
      return this.accepter
        ? "@/assets/images/icons/accepter-head.svg"
        : "@/assets/images/icons/accepter-head.svg";
    },
  },

  filters: {
    get_date_evaluation(date) {
      let newDate = moment(date).format("DD/MM/YYYY");
      return newDate;
    },
  },

  data() {
    return {
      loading_qst: false,
      accepter_data: [],
      refuser_data: [],
      accepter: false,
      isSendingForm: false,
      selectedItem: {},
      openPopup: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onSubmit() {
      this.isSendingForm = true;
      let reqData = this.refuser_data.map((item) => ({
        clientId: item.clientId,
        accepted: item.Accepter,
      }));
      HistoriqueValiderContactApi({ responses: reqData })
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.fetchData();
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.status.mesage,
            type: "error",
          });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
    fetchData() {
      this.loading_qst = true;
      HistoriqueShareGetContactApi()
        .then((res) => {
          this.accepter_data = res.data.data.filter((item) => {
            return item.status === "evaluated";
          });
          this.refuser_data = res.data.data.filter((item) => {
            return item.status === "rejected";
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading_qst = false;
        });
    },
    accepterAll() {
      if (this.accepter) {
        this.accepter_data.map(function (item) {
          item.Accepter = 0;
        });
      } else {
        this.accepter_data.map(function (item) {
          item.Accepter = 1;
        });
      }
      this.accepter = !this.accepter;
    },
    supprimerContact(item) {
      this.selectedItem = item;
      this.openPopup = true;
      // HistoriqueDeleteContactApi(item.clientId)
      //   .then((res) => {
      //     this.$snackbar.showAlertMessage({
      //       message: res.data.message,
      //       type: "success",
      //     });
      //     this.fetchData();
      //   }).catch(({ response }) => {
      //     this.$snackbar.showAlertMessage({
      //       message: response.data.message,
      //       type: "error",
      //     });
      //   });
    },
  },
};
</script>
