<template>
  <div id="historique">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Mon historique de partage"
        description=""
      />
    </div>
    <EntrepriseHistoriqueTableComponent 
      title="Vous partagez actuellement votre questionnaire avec ces entreprises :"
      title2="Vous avez refusé de partager votre questionnaire avec ces entreprises :"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EntrepriseHistoriqueTableComponent from "../../../components/partage/EntrepriseHistoriqueTableComponent";
export default {
  name: "MonEntreprisePartageMonHistoriquePage",
  computed: {},
  components: {
    EntrepriseHeaderBlockTextComponent,
    EntrepriseHistoriqueTableComponent,
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
