<template>
  <div id="viqtor-dashboard">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "DashboardContainerPage",
};
</script>
