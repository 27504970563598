<template>
  <div class="!tw-pt-12">
    <template v-if="isLoading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-flex tw-items-center tw-justify-between">
        <EntrepriseHeaderBlockTextComponent title="Gérer les équipes" />
        
        <div class="tw-flex tw-space-x-8">
          <img
            @click="canOpenAddTeamPopup = true"
            class="tw-cursor-pointer"
            src="@/assets/images/cta/add_team_cta.svg"
            alt="logo"
          />
        </div>
      </div>

      <div class="tw-w-full tw-overflow-auto filter tw-border-2 tw-p-6 tw-rounded-lg border-primary tw-mt-8">
        <SignupSecondaryTextComponent class="subheader tw-mb-6">
          Créer et gérer vos équipes.
        </SignupSecondaryTextComponent>

        <v-simple-table height="600px" id="collaborateurs-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  <div @click="addToOrder('teams.name')" class="tw-cursor-pointer tw-flex">
                    <span>Nom</span>
                    <img v-if="filters.orderBy == 'teams.name' && filters.orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                    <img v-else-if="filters.orderBy == 'teams.name' && filters.orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                    <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                  </div>
                </th>
                <th>
                  <div @click="addToOrder('collaboratorsCount')" class="tw-cursor-pointer tw-flex">
                    <span>Collaborateurs</span>
                    <img v-if="filters.orderBy == 'collaboratorsCount' && filters.orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                    <img v-else-if="filters.orderBy == 'collaboratorsCount' && filters.orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                    <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                  </div>
                </th>
                <th>
                  <div @click="addToOrder('expertCompaniesCount')" class="tw-cursor-pointer tw-flex tw-justify-center">
                    <span>Clients</span>
                    <img v-if="filters.orderBy == 'expertCompaniesCount' && filters.orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                    <img v-else-if="filters.orderBy == 'expertCompaniesCount' && filters.orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                    <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                  </div>
                </th>
                <th>
                  <div @click="addToOrder('sumScore')" class="tw-cursor-pointer tw-flex tw-justify-center">
                    <span>Moyenne conformité</span>
                    <img v-if="filters.orderBy == 'sumScore' && filters.orderType == 'desc'" src="@/assets/images/icons/sort-desc.svg" alt="" />
                    <img v-else-if="filters.orderBy == 'sumScore' && filters.orderType == 'asc'" src="@/assets/images/icons/sort-asc.svg" alt="" />
                    <img v-else src="@/assets/images/icons/no-sort.svg" alt="" />
                  </div>
                </th>

                <th>
                  <div class="tw-cursor-pointer tw-flex tw-justify-center">
                    <span>Actions</span>
                  </div>
                </th>
              </tr>
            </thead>
            <template v-if="teams.length > 0">
              <tbody
                v-for="team in teams"
                :key="team.id"
                class="!tw-border-0"
              >
                <tr class="!tw-border-0">
                  <td>
                    <div class="tw-relative tw-flex tw-justify-start tw-items-center tw-space-x-2">
                      <button v-if="team.members.length > 0" @click="showDetailsHandler(team.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="tw-w-4 tw-h-4 dynamic-text" :class="{ 'tw-rotate-90': canShowDetailsForId == team.id }">
                          <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                        </svg>
                      </button>
                      <div v-if="team.members.length == 0" style="width: 1rem;"></div>
                      <BadgeComponent :label="team.name" />
                    </div>
                  </td>

                  <td>
                    <div class="tw-flex tw-items-center tw-justify-start">
                      <AvatarComponent 
                        v-for="member in (team.members.length > 3 ? team.members.slice(0, 2) : team.members)"
                        :key="member.id"
                        :label="`${member.firstName} ${member.lastName}`"
                      />

                      <v-menu bottom right v-if="team.members.length > 3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <div
                              v-bind="attrs" v-on="on"
                              :style="{ 'background-color': '#2A353A' }"
                              class="tw-ml-3 tw-h-8 tw-p-1 tw-w-8 tw-uppercase tw-rounded-full tw-text-white tw-bg-gray-500 tw-flex tw-items-center tw-justify-center tw-ring-white tw-font-medium"
                            >
                              {{ `+${team.members.length - 2}` }}
                            </div>
                          </v-btn>
                        </template>

                        <v-list class="!tw-p-1">
                          <v-list-item v-for="member in team.members.slice(2)" :key="member.id">
                            <v-list-item-title class="tw-cursor-pointer !hover:dynamic-bg !hover:dynamic-text !tw-p-1">
                              <div :key="member.id" class="tw-flex tw-justify-start tw-items-center tw-space-x-2">
                                <AvatarComponent no-tooltip :label="`${member.firstName} ${member.lastName}`" />

                                <span>{{ `${member.firstName} ${member.lastName}` }}</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </td>

                  <td>
                    <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
                      <span class="tw-inline-flex tw-items-center tw-justify-center tw-space-x-2 tw-border tw-border-gray-900 tw-rounded-full tw-text-xs tw-font-medium tw-text-gray-900 tw-h-8 tw-w-8">
                        <span class="tw-block">{{ team.clientsCount }}</span>
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="tw-w-full tw-flex tw-justify-center">{{ team.avgCompliance }}</div>
                  </td>

                  <td>
                    <div
                      class="tw-cursor-pointer tw-mx-auto dynamic-bg tw-w-24 tw-h-8 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white"
                      @click="openUpdateTeamPopup(team)"
                    >
                      Modifier
                    </div>
                  </td>
                </tr>
                <transition
                  enter-active-class="tw-transition tw-ease-out tw-duration-500"
                  enter-class="tw-transform tw-opacity-0 tw-scale-95"
                  enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
                  leave-active-class="tw-transition tw-ease-in tw-duration-250"
                  leave-class="tw-transform tw-opacity-100 tw-scale-100"
                  leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
                >
                  <tr :id="`member-${team.id}`" :class="{ 'tw-hidden': canShowDetailsForId != team.id || sortedTeamMembersByLead.length == 0 }">
                    <td colspan="5">
                      <div class="tw-space-y-4 tw-my-2 tw-p-4" style="width: 60%;">
                        <div v-for="member in sortedTeamMembersByLead" :key="member.id">
                          <TeamDetailsItem 
                            v-if="!!member"
                            :member="member"
                            :team-id="team.id"
                            @leadUpdated="teamDetailsItemUpdatedHandler"
                            @updated="memberUpdatedHandler"
                            @deleted="memberUpdatedHandler"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </transition>
              </tbody>
            </template>
            <template v-if="teams.length == 0">
              <tbody>
                <tr>
                  <td colspan="100%" class="text-center">
                    Pas de données disponibles
                  </td>
                </tr>
              </tbody>
            </template>

            <tfoot class="text-center" v-if="length.length > 1">
              <tr>
                <td colspan="100%">
                  <v-pagination
                    :color="referer.theme.ctaBgColor"
                    v-model="page"
                    :length="length"
                    @input="pagination"
                  ></v-pagination>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </div>
    </template>

    <AddTeamPopup
      v-if="canOpenAddTeamPopup"
      :can-open="canOpenAddTeamPopup"
      @close="canOpenAddTeamPopup = false"
      @created="pagination"
    />

    <UpdateTeamPopup
      v-if="canOpenUpdateTeamPopup"
      :can-open="canOpenUpdateTeamPopup"
      :team="selectedTeam"
      @close="canOpenUpdateTeamPopup = false"
      @updated="memberUpdatedHandler"
      @deleted="pagination"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import TeamDetailsItem from "./TeamDetailsItem.vue";
import AddTeamPopup from "./AddTeamPopup.vue";
import UpdateTeamPopup from "./UpdateTeamPopup.vue";
import { getTeams } from "@/features/company/services/appApi";

export default {
  name: "ExpertClientTeam",
  components: {
    EntrepriseHeaderBlockTextComponent,
    SignupSecondaryTextComponent,
    TeamDetailsItem,
    AddTeamPopup,
    UpdateTeamPopup,
  },
  data() {
    return {
      isLoading: true,
      canOpenAddTeamPopup: false,
      canOpenUpdateTeamPopup: false,
      filters: {
        orderBy: "",
        orderType: "",
      },
      teams: [],
      selectedTeam: {},
      canShowDetailsForId: 0,
      sortedTeamMembersByLead: [],
      length: 1,
      page: 1,
    };
  },
  computed: {
    referer() {
      return this.$store.getters.referer;
    },
  },
  mounted() {
    this.pagination();
  },
  methods: {
    addToOrder(item) {
      if (this.filters.orderBy === item) {
        this.filters.orderType = this.filters.orderType === "asc" ? "desc" : "asc";
      } else {
        this.filters.orderBy = item;
        this.filters.orderType = "asc";
      }
      this.pagination();
    },
    showDetailsHandler(id) {
      this.canShowDetailsForId = this.canShowDetailsForId != id ? id : 0;
      this.sortedTeamMembersByLead = this.teams
        .find(team => team.id == id).members
        .toSorted((a, b) => {
          if (a.isLeader && !b.isLeader) return -1;
          if (!a.isLeader && b.isLeader) return 1;
          return 0;
        });
    },
    openUpdateTeamPopup(team) {
      this.selectedTeam = team;
      this.canOpenUpdateTeamPopup = true;
    },
    pagination(page) {
      getTeams(page, this.filters)
        .then(({ data: { items, meta } }) => {
          this.teams = items.map((team) => ({
            id: team.id,
            name: team.name,
            avgCompliance: !!team.expertCompanies && team.expertCompanies.length > 0
              ? Math.floor(
                team.expertCompanies.reduce(
                  (acc, company) => acc + (company.quiz ? company.quiz.scorePercent : 0), 0
                ) / team.expertCompanies.length
              ) + '%'
              : '-',
            clientsCount: !!team.expertCompanies && team.expertCompanies.length > 0
              ? team.expertCompanies.length
              : '-',
            members: !!team.expertCollaborators && team.expertCollaborators.length > 0
              ? team.expertCollaborators.map(({ collaborator, isLead }) => ({
                id: collaborator.id,
                firstName: collaborator.firstName,
                lastName: collaborator.lastName,
                isLeader: isLead,
                clientsTradeNames: !!collaborator.expertCompanies && collaborator.expertCompanies.length > 0
                  ? collaborator.expertCompanies.map(expertCompany => expertCompany.tradeName)
                  : [],
                clientsCount: !!collaborator.expertCompanies && collaborator.expertCompanies.length > 0
                  ? collaborator.expertCompanies.length
                  : 0,
                email: collaborator.email,
                service: collaborator.user.service,
                phone: collaborator.user.phone 
                  ? collaborator.user.phone
                  : collaborator.user.mobilePhone
              }))
              : []
          }));
          this.page = meta.currentPage;
          this.length = meta.totalPages;
        })
        .catch(error => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la récupération des données.",
            type: "error",
          });
        })
        .finally(() => { this.isLoading = false; });

      this.page = page;
    },
    teamDetailsItemUpdatedHandler({ teamId, collaboratorId }) {
      console.log('Passed by here !');
      this.teams = this.teams.map(team => {
        if (team.id == teamId) {
          team.members = team.members.map(member => {
            if (member.id == collaboratorId) {
              member.isLeader = true;
            } else {
              member.isLeader = false;
            }
            return member;
          });
        }
        return team;
      })
      this.sortedTeamMembersByLead = this.teams
        .find(team => team.id == teamId).members
        .toSorted((a, b) => {
          if (a.isLeader && !b.isLeader) return -1;
          if (!a.isLeader && b.isLeader) return 1;
          return 0;
        });
    },
    memberUpdatedHandler() {
      this.pagination();
      setTimeout(() => {
        this.sortedTeamMembersByLead = this.teams
          .find(team => team.id == this.canShowDetailsForId).members
          .toSorted((a, b) => {
            if (a.isLeader && !b.isLeader) return -1;
            if (!a.isLeader && b.isLeader) return 1;
            return 0;
          });
      }, 500);
    },
  }
};
</script>

<style lang="scss" scoped>
#collaborateurs-table {
  overflow-y: auto !important;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477e;
  position: absolute;
  z-index: 1000;
  top: 22px;
  right: -36px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>
