<template>
  <div class="tw-flex tw-flex-col" id="subconstractor-block">
    <div class="tw-flex tw-space-x-2">
      <div class="tw-w-2/5">
        <div class="tw-flex tw-flex-col tw-space-y-6 tw-mb-6">
          <h1 class="subconstractor-dashboard-title">Nombre de sous-traitants</h1>
          <h1 class="subconstractor-dashboard-number">+ {{ subConstractorTotal }}</h1>
          <div class=" tw-w-3/4 subconstractor-dashboard-hr"></div>
          <div class="tw-flex tw-space-x-1 align-center">

            <svg class="tw-mr-3" width="125" height="26" viewBox="0 0 125 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 25C1 25 9.20574 16.2064 19.9231 16.2064C30.6404 16.2064 29.4691 21.521 40.4138 21.521C51.3585 21.521 56.0995 1 71.6833 1C87.267 1 90.6139 19.9549 103.686 19.9549C113.869 19.9549 116.473 13 124 13" :stroke="referer.theme.ctaBgColor" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="8.5" cy="8" rx="8.5" ry="8" :fill="referer.theme.ctaBgColor"/>
              <path d="M12.96 5.30985C12.9093 5.18773 12.8122 5.09069 12.6901 5.03998C12.63 5.01437 12.5655 5.00079 12.5001 5H10.0009C9.8683 5 9.74116 5.05265 9.64742 5.14638C9.55368 5.2401 9.50101 5.36721 9.50101 5.49976C9.50101 5.6323 9.55368 5.75942 9.64742 5.85314C9.74116 5.94686 9.8683 5.99952 10.0009 5.99952H11.2955L8.5013 8.79317L6.85678 7.14396C6.81031 7.09712 6.75503 7.05994 6.69412 7.03457C6.6332 7.0092 6.56787 6.99614 6.50188 6.99614C6.4359 6.99614 6.37056 7.0092 6.30965 7.03457C6.24874 7.05994 6.19345 7.09712 6.14699 7.14396L3.14786 10.1425C3.10101 10.189 3.06382 10.2442 3.03844 10.3051C3.01307 10.366 3 10.4314 3 10.4973C3 10.5633 3.01307 10.6286 3.03844 10.6895C3.06382 10.7504 3.10101 10.8057 3.14786 10.8522C3.19432 10.899 3.24961 10.9362 3.31052 10.9616C3.37143 10.9869 3.43677 11 3.50275 11C3.56874 11 3.63407 10.9869 3.69499 10.9616C3.7559 10.9362 3.81118 10.899 3.85765 10.8522L6.50188 8.20345L8.14641 9.85265C8.19287 9.8995 8.24816 9.93668 8.30907 9.96205C8.36998 9.98742 8.43532 10.0005 8.5013 10.0005C8.56729 10.0005 8.63262 9.98742 8.69354 9.96205C8.75445 9.93668 8.80973 9.8995 8.8562 9.85265L12.0003 6.70418V7.99855C12.0003 8.1311 12.053 8.25821 12.1467 8.35193C12.2404 8.44566 12.3676 8.49831 12.5001 8.49831C12.6327 8.49831 12.7599 8.44566 12.8536 8.35193C12.9473 8.25821 13 8.1311 13 7.99855V5.49976C12.9992 5.43445 12.9856 5.36993 12.96 5.30985Z" fill="white"/>
            </svg>

            <span
                class="percentage-number"
                :style="{ color: referer.theme.ctaBgColor}"
            >{{ subConstractorPercentage !== '' ? subConstractorPercentage : '0' }} %</span>
          </div>
          <span class="percentage-text">
            Le nombre de sous-traitants est en hausse de {{ subConstractorPercentage !== '' ? subConstractorPercentage : '0' }} % ce mois-ci.
          </span>
        </div>
      </div>
      <div class="tw-w-3/5">
        <div class="tw-w-full tw-space-x-4 tw-flex tw-justify-end tw-pr-2 tw-mb-6" id="subconstractor-barchart-block">
          <div class="tw-flex tw-items-center tw-space-x-2 ">
            <div class="legend-item" :style="{ backgroundColor: referer.theme.barchartItem1Color}"></div>
            <span class="legend-item-text">Invitation</span>
          </div>
          <div class="tw-flex tw-items-center tw-space-x-2">
            <div class="legend-item" :style="{ backgroundColor: referer.theme.barchartItem2Color}"></div>
            <span class="legend-item-text">Réponse</span>
          </div>
        </div>
        <BarChartSubContractor
            :colors="[ referer.theme.barchartItem1Color, referer.theme.barchartItem2Color ]"
            :data="barchartData"
            :styles="{ height: `220px`, position: 'relative' }" />
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-w-full second-part-subconstractor">
      <div class="tw-w-4/12 tw-flex tw-flex-col tw-border-r tw-items-center tw-space-y-1 tw-p-2">
        <div class="tw-flex tw-items-center tw-justify-center tw-space-x-1">
          <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="6" :fill="referer.theme.ctaBgColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.48268 5.62705H9.50602C10.736 5.62705 11.336 6.28372 11.3327 7.63038V9.25372C11.3327 10.5404 10.5393 11.3337 9.24935 11.3337H6.74602C5.46268 11.3337 4.66602 10.5404 4.66602 9.25038V6.74705C4.66602 5.36705 5.27935 4.66705 6.48935 4.66705H7.01602C7.32635 4.66372 7.61602 4.80705 7.80602 5.05038L8.09935 5.44038C8.19268 5.55705 8.33268 5.62705 8.48268 5.62705ZM6.45602 9.09706H9.54268C9.67935 9.09706 9.78935 8.98372 9.78935 8.84706C9.78935 8.70706 9.67935 8.59706 9.54268 8.59706H6.45602C6.31602 8.59706 6.20602 8.70706 6.20602 8.84706C6.20602 8.98372 6.31602 9.09706 6.45602 9.09706Z" fill="white"/>
          </svg>
          <span class="invitation-text">Invitations envoyées</span>
        </div>
        <div class="tw-flex tw-items-center tw-space-x-1">
          <span class="invitation-number">{{ invitationObject.total }}</span>
          <img src="@/assets/images/dashboard/flech-icon.png" width="36" height="28" alt="" />
        </div>
        <v-progress-linear class="tw-rounded tw-w-8/12" :color="referer.theme.ctaBgColor" background-color="#E4E4E4" :value="invitationObject.progress"></v-progress-linear>
      </div>
      <div class="tw-w-4/12 tw-flex tw-flex-col tw-border-r tw-items-center tw-space-y-1 tw-p-2">
        <div class="tw-flex tw-items-center tw-justify-center tw-space-x-1">
          <svg width="22" height="21" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0996094" width="16" height="16" rx="6" :fill="referer.theme.evaluatedProgressColor"/>
            <rect x="3" y="4" width="10.2692" height="2.05385" stroke="white" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.8999 7.4231V12.9H4.36914V7.4231" stroke="white" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="6.4231" y="8.10767" width="3.42308" height="2.05385" stroke="white" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <span class="invitation-text">Sous-traitants évalués</span>
        </div>
        <div class="tw-flex tw-items-center tw-space-x-1">
          <span class="invitation-number">{{ evaluateObject.total }}</span>
          <img src="@/assets/images/dashboard/flech-icon.png" width="36" height="28" alt="" />
        </div>
        <v-progress-linear class="tw-rounded tw-w-8/12" :color="referer.theme.evaluatedProgressColor" background-color="#E4E4E4" :value="evaluateObject.progress"></v-progress-linear>
      </div>
      <div class="tw-w-4/12 tw-flex tw-flex-col tw-items-center tw-space-y-1 tw-p-2">
        <div class="tw-flex tw-items-center tw-justify-center tw-space-x-1">
          <img src="@/assets/images/dashboard/note-avg.png" width="10%" alt="" />
          <span class="invitation-text">Note moyenne</span>
        </div>
        <div class="tw-flex tw-items-center tw-space-x-1">
          <span class="invitation-number">{{ avgObject.total }} / 100</span>
        </div>
        <v-progress-linear class="tw-rounded tw-w-8/12" color="#3F8CFF" background-color="#E4E4E4" :value="avgObject.progress"></v-progress-linear>
      </div>
    </div>

  </div>
</template>

<script>
import BarChartSubContractor from "@/features/company/components/Dashboard/BarChartSubContractor";
export default {
  name: "SubContractorBolckOld",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  props: {
    subConstractorTotal: { type: String, default: "", },
    subConstractorPercentage: { type: String, default: "", },
    barchartData: { type: Array, default: function () { return [] }, },
    invitationObject: { type: Object, default: function () { return {} }, },
    evaluateObject: { type: Object, default: function () { return {} }, },
    avgObject: { type: Object, default: function () { return {} }, },
  },
  components: {BarChartSubContractor}
}
</script>

<style scoped>
</style>
