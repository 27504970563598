<template>
  <div id="offer-item-container"
    :class="{
      'offer-item-container-last' : offer.isLast,
      'offer-item-container-first' : offer.isFirst,
      'offer-item-is-checked' : selectedOffer.id === offer.id
    }"
  >
    <div class="tw-p-2 offer-item-container-one">
      <p class="offer-item-number-salary-text" v-if="offer.price === 0">
        Module
        <br />
        sous-traitance
      </p>
      <p v-else class="offer-item-number-salary-text">
        {{ offer.plan.salaryRange }}
        <br />
        salariés
      </p>
      <p class="offer-item-number-subcontractor-text" v-if="offer.price !== 0">
        Jusqu’à {{ offer.plan.subContractorNumber }} sous-traitants
      </p>
      <img v-if="offer.plan.img !== 'none'" class="tw-mx-auto" :src="getImage(offer.plan.img)" :alt="offer.plan.img" />
      <div v-if="offer.price === 0">
        <p class="tw-mt-4 offer-item-price-mounth-text">
          Gratuit !
        </p>
        <p class="offer-item-price-year-text">
          {{ referer.title === 'GDPR assist' ? '  ' : 'Pour bénéficier du module gratuit vous devrez reseigner un moyen de paiement' }}

        </p>
      </div>
      <div v-else>
      <p class="tw-mt-4 offer-item-number-subcontractor-text">
        Engagement {{ selectedEngagement === '24' ? '2 ans' : '1 an' }}
      </p>
      </div>
    </div>

    <div class="offer-item-with-container">
      <p class="offer-item-annual-text">
        {{ selectedRegulation === 'monthly' ? 'Règlement mensuel' : 'Règlement en 1 fois' }}
      </p>
      <p class="offer-item-annual-text">
        {{ offer.price | priceFormat }} €
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "OfferItemComponent",
  data() {
    return {
      isChecked: false,
    }
  },
  props: {
    selectedOffer: {},
    selectedRegulation: {
      type: String,
      default: '',
    },
    selectedEngagement: {
      type: [String, Number],
      default: '',
    },
    offer: {},
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  filters: {
    priceFormat(value) {
      value = "" + value;
      var c = "",
          d = 0;
      while (value.match(/^0[0-9]/)) {
        value = value.substr(1);
      }
      for (let i = value.length - 1; i >= 0; i--) {
        c = d !== 0 && d % 3 === 0 ? value[i] + " " + c : value[i] + c;
        d++;
      }
      return c;
    },
  },
  methods: {
    getImage(img) {
      return require('@/assets/images/salaries/' + img);
    }
  }
}
</script>

<style scoped>

</style>
