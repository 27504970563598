<template>
  <div id="Questionnaire-detail" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
    <img width="6%" :src="referer.logo" alt="LOGO" />

    <h3 class="tw-mt-12 tw-font-semibold tw-text-2xl tw-w-fit" style="letter-spacing: -0.5px;">C’est validé !</h3>
    
    <span class="mon-entreprise-para tw-mt-3 !tw-text-center" style="line-height: 1.3;">Merci d’avoir répondu au questionnaire !<br>Le résultat de votre évaluation vous sera transmis par email.</span>

    <div id="questionnaire-empty" class="tw-w-fit tw-flex tw-flex-col tw-items-center">
      <v-btn elevation="1" text rounded large @click="goToPublicSite">Retourner sur le site</v-btn>
      <img class="tw-w-60 tw-mt-8" src="@/assets/images/icons/bg_invitation.svg" alt="invitation" />
    </div>
  </div>
</template>

<script>
export default {
  name: "GDPRPublicQuizSuccess",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    goToPublicSite() {
      window.location.href = "https://viqtor.eu/";
    }
  }
}
</script>