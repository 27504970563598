<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen tw-relative">
      <v-overlay :value="isLoadingData" class="tw-z-99">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <TitleComponent class="public-title-auth tw-mt-8" text="Complétez votre compte" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-8">
        <div class="tw-mb-4">Vous y êtes presque !</div>
      </SignupSecondaryTextComponent>

      <v-form @submit="onSubmit" v-model="formValid" class="tw-pb-16 tw-mt-8" autocomplete="off">
        <v-row>
          <v-col cols="12" md="3" lg="4">
            <TextFieldComponent :rules="rules.phone" v-model="form.phone" label="Numéro de téléphone" />
          </v-col>
          <v-col cols="12" md="3" lg="4">
            <PasswordInputComponent @keyup="handelPassword" v-model="form.password" :rules="rules.password"
              :error-messages="errors.password" label="Mot de passe" validate passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
spécial (., @, !, _, %, #, :, $, *, ..., -)" />
          </v-col>
          <v-col cols="12" md="3" lg="4">
            <PasswordInputComponent :disabled="disableComfirmPassword" :rules="rules.confirmPassword"
              v-model="form.confirmPassword" :error-messages="errors.confirmPassword"
              label="Vérification de mot de passe" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.service" v-model="form.service" label="Service" />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <TextFieldComponent :rules="rules.role" v-model="form.role" label="Poste / Rôle" />
          </v-col>
        </v-row>

        <div class="tw-flex tw-mx-6 tw-justify-end tw-mt-14">
          <PrimaryButtonComponent :loading="isSendingForm" :disabled="!formValid || isSendingForm" type="submit">Suivant
            <v-icon right dark> mdi-arrow-right </v-icon>
          </PrimaryButtonComponent>
        </div>
      </v-form>
      <p class="tw-absolute tw-bottom-0 registration-iml">
        Les informations à caractère personnel recueillies dans le cadre
        contractuel de votre souscription à la plateforme Viqtor sont enregistrées
        dans un fichier informatisé par la société DBWO pour vous permettre
        l’accès aux services qu’elle propose, assurer l’interaction de votre
        organisation avec ses partenaires ainsi que vous tenir informés de
        l’évolutions de ses prestations. Elles seront conservées tout au long de
        la durée de souscription au service, période durant laquelle elles
        pourront être régulièrement mises à jour à votre demande. Hors demande de
        votre part, elles seront détruites après résiliation de la souscription
        par votre organisation sous un délai de 3 mois après le terme du contrat.
        Ces données sont destinées au personnel administratif de DBWO et
        conservées sur les serveurs de l’entreprise OVH. Conformément à la loi «
        informatique et libertés », vous pouvez exercer votre droit d'accès aux
        données vous concernant et les faire rectifier en contactant :
        dpo@dbwo.legal
      </p>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PasswordInputComponent from "@/components/common/PasswordFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { validationRules } from "@/utils/validation-rules";
import { getDataFromToken, InvitationExpertCollaboratorSecondStepSignUpApi } from "../../services/appApi";
import _ from "lodash";


export default {
  name: "SecondStepRegistrationPage",
  components: {
    PrimaryButtonComponent,
    TextFieldComponent,
    PasswordInputComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
  },
  data() {
    return {
      disableComfirmPassword: true,
      formValid: false,
      isLoadingData: true,
      isSendingForm: false,
      errors: {
        service: "",
        role: "",
        password: "",
        confirmPassword: "",
        phone: "",
      },
      form: {
        service: "",
        role: "",
        password: "",
        confirmPassword: "",
        phone: "",
      },
      obj: null,
      rules: {
        service: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "service"),
        ],
        role: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "role"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.form.password),
        ],
      },
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      InvitationExpertCollaboratorSecondStepSignUpApi(
        this.$route.params.token,
        this.form
      )
        .then(() => {
          this.isSendingForm = false;
          this.$router.push({
            name: "Auth.Registration.Expert.Collaborator.LastStep",
            params: {
              token: this.$route.params.token,
            },
          });
        })
        .catch(({ response }) => {
          this.isSendingForm = false;
          if (_.isArray(response.data.message)) {
            response.data.message.forEach(item => {
              this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Attention',
                text: item
              })
            });
          }
          else {
            this.$notify({
              group: 'foo',
              type: 'error',
              title: 'Attention',
              text: response.data.message
            });
          }
          if (response.status === 400) {
            this.errors = { ...this.errors, ...response.data };
          }
          this.$refs.form.validate();
        });
    },

    fetchData() {
      getDataFromToken(this.$route.params.token)
        .then(({ data: { data } }) => {
          this.isLoadingData = false;
          this.form = {
            ...this.form,
            service: data.service || "",
            role: data.role || "",
          };
        })
        .catch(({ response }) => {
          this.isLoadingData = false;
          if (response.status === 404) {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
            this.$router.replace({ name: "TokenExpired" });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: quelque chose s'est mal passé",
              type: "error",
            });
          }
        });
    },

    handelPassword(event) {
      if (
        event.target.value.length >= 12 &&
        event.target.value.match(/[a-z]/) &&
        event.target.value.match(/[A-Z]/) &&
        event.target.value.match(/\d+/) &&
        (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) || event.target.value.includes('-'))
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.form.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
