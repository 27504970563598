<template>
  <div id="collaborateurs-add">
    <CollaboratorAddGuideComponent :title1="title1" :title2="title2" :link-route="linkRoute" :button-route="buttonRoute"
      :step-one-text="stepOneText" :step-two-text="stepTwoText" :step-three-text="stepThreeText"
      link-text="C’est le point le plus fastidieux, mais finalement ça reste simple, non ?">

    </CollaboratorAddGuideComponent>
  </div>
</template>

<script>
import CollaboratorAddGuideComponent from "@/features/company/components/Collaborateurs/CollaboratorAddGuideComponent";

export default {
  name: "AddGuideComponent",
  components: { CollaboratorAddGuideComponent },
  props: {
    title1: {
      type: String,
      default: ''
    },
    title2: {
      type: String,
      default: ''
    },
    buttonRoute: {
      type: String,
      default: ''
    },
    linkRoute: {
      type: String,
      default: ''
    },
    stepOneText: {
      type: String,
      default: ''
    },
    stepTwoText: {
      type: String,
      default: ''
    },
    stepThreeText: {
      type: String,
      default: ''
    },
    withAction: {
      type: Boolean,
      default: false
    },
    ctaText: {
      type: String,
      default: "Ça commence à se démêler un peu, non ?"
    },
    isCtaTextClickable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    };
  },
  methods: {
    submit(type) {
      if (type === 'link') {
        this.$router.push({ name: this.linkRoute });
      }
      else {
        this.$router.push({ name: this.buttonRoute });
      }
    }
  },
};
</script>
