<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div style="padding-top: 2rem; padding-bottom: 3rem;">
      <div data-id="6418980" data-element_type="column">
        <div>
          <div data-id="4bbf9e7" data-element_type="widget" data-widget_type="heading.default">
            <div>
              <h1 style="font-weight: 600; font-size: 47px; width: 80%; line-height: 71px; font-family: 'Poppins';">Gestion de mes données
                personnelles</h1>
            </div>
          </div>
          <div data-id="cb6038c" data-element_type="widget" data-widget_type="text-editor.default"
            style="margin-top: 30px;">
            <div>
              <p>En conformité avec le Règlement Général pour la Protection des Données (RGPD), vous pouvez dans cette
                page
                demander à :</p>
              <ul style="list-style-type: disc; margin-left: 40px; margin-top: 10px; margin-bottom: 10px;">
                <li>Retirer votre consentement,</li>
                <li>Accéder à vos données,</li>
                <li>Modifier vos données,</li>
                <li>Effacer vos données,</li>
                <li>Transférer vos données vers un tiers (droit à la portabilité)</li>
              </ul>
              <p>&nbsp;</p>
              <p style="width: 85%;">Remplissez le formulaire ci-dessous, <b>{{ referrer.title }}</b> s’engage à répondre
                à votre demande dans un délai
                raisonnable qui ne saurait dépasser 1 mois à compter de la réception de votre demande.</p>
            </div>
          </div>
          <div data-id="6eb1688" data-element_type="widget"
            data-settings="{&quot;step_next_label&quot;:&quot;Suivant&quot;,&quot;step_previous_label&quot;:&quot;Pr\u00e9c\u00e9dent&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}"
            data-widget_type="form.default">
            <div>
              <form method="post" name="Nouveau formulaire" @submit.prevent="sendRequest">
                <input type="hidden" name="post_id" value="11779">
                <input type="hidden" name="form_id" value="6eb1688">
                <input type="hidden" name="referer_title" value="Gestion de mes données personnelles - Gatewatcher">

                <input type="hidden" name="queried_id" value="11779">

                <div>
                  <div style="display: flex; flex-direction: column;">
                    <label for="form-field-name">Nom <span style="color: red;">*</span></label>
                    <input
                      v-model="form.lastName"
                      style="width: 24%; margin-top: -6px; background-color: white; border: 1px solid black; border-radius: 5px; padding: .5rem 1rem;"
                      size="1" type="text" name="form_fields[name]" id="form-field-name" placeholder="Nom" required
                      aria-required="true">
                  </div>
                  <div style="display: flex; flex-direction: column;">
                    <label for="form-field-field_d98fc7c">Prénom <span style="color: red;">*</span></label>
                    <input
                      v-model="form.firstName"
                      style="width: 24%; margin-top: -6px; background-color: white; border: 1px solid black; border-radius: 5px; padding: .5rem 1rem;"
                      size="1" type="text" name="form_fields[field_d98fc7c]" id="form-field-field_d98fc7c"
                      placeholder="Prénom" required aria-required="true">
                  </div>
                  <div style="display: flex; flex-direction: column;">
                    <label for="form-field-email">E-mail <span style="color: red;">*</span></label>
                    <input
                      v-model="form.email"
                      style="width: 24%; margin-top: -6px; background-color: white; border: 1px solid black; border-radius: 5px; padding: .5rem 1rem;"
                      size="1" type="email" name="form_fields[email]" id="form-field-email" placeholder="E-mail" required
                      aria-required="true">
                  </div>
                  <div>
                    <label for="form-field-field_87c2f77">Motif de votre demande <span style="color: red;">*</span></label>
                    <div>
                      <div>
                        <i aria-hidden="true"></i>
                      </div>
                      <select v-model="form.motif" style="width: 24%; margin-top: -6px; background-color: white; border: 1px solid black; border-radius: 5px; padding: .5rem 1rem;" name="form_fields[field_87c2f77]" id="form-field-field_87c2f77" required aria-required="true">
                        <option value="retirer-votre-consentement">Retirer votre consentement</option>
                        <option value="acceder-a-vos-donnees">Accéder à vos données</option>
                        <option value="modifier-vos-donnees">Modifier vos données</option>
                        <option value="effacer-vos-donnees">Effacer vos données</option>
                        <option value="transférer-vos-donnees-vers-un-tiers">Transférer vos données vers un tiers</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <v-btn
                      :style="{ backgroundColor: `${referrer.theme.ctaBgColor}` }" 
                      style="color: white; border-radius: 5px; margin-top: 1rem; padding: .5rem 1rem; width: 24%;"
                      elevation="1"
                      text
                      class="primary-btn"
                      large
                      :loading="isSendingForm"
                      :disabled="!isFormValid || isSendingForm"
                      type="submit"
                    >
                    Envoyer
                    </v-btn>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { sendRightsExerciceRequest } from "@/features/auth/services/appApi";

export default {
  data() {
    return {
      form: {
        lastName: "",
        firstName: "",
        email: "",
        motif: "retirer-votre-consentement"
      },
      isSendingForm: false,
    };
  },
  computed: {
    referrer() {
      return this.$store.state.referrer;
    },
    isFormValid() {
      return this.form.lastName && this.form.firstName && this.form.email;
    }
  },
  methods: {
    sendRequest() {
      this.isSendingForm = true;

      sendRightsExerciceRequest(this.form)
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "Votre demande a bien été envoyée.",
            type: "success",
          });

          this.form = { lastName: "", firstName: "", email: "", motif: "retirer-votre-consentement" }
        })
        .catch(() => {
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de l'envoi de votre demande.",
            type: "error",
          });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    }
  }
}
</script>