<template>
  <div id="notifications">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="notifications-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Mes notifications"
        description="Retrouvez ici toutes vos notifications !"
      />
      <v-btn
        :style="{
                backgroundColor: `${referer.theme.ctaBgColor} !important`,
                borderColor: `${referer.theme.ctaBgColor} !important`,
                color: `${referer.theme.ctaTextColor} !important`
              }"
        elevation="1"
        text
        rounded
        large
        @click="navigatedd()"
        class="tw-mt-4"
      >
        Gérer mes notifications
        <img src="@/assets/images/icons/historique.svg" alt="logo" />
      </v-btn>
    </div>
    <NotificationTableComponents
      title="Voulez-vous partager votre questionnaire avec les entreprises suivantes ?"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import NotificationTableComponents from "../../../components/Notification/NotificationTableComponents";
export default {
  name: "MonEntreprisePartagePage",
  computed: {
    getNotificationsMessage() {
      return this.$store.getters.getNotifications;
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    EntrepriseHeaderBlockTextComponent,
    NotificationTableComponents,
  },
  data() {
    return {
      openMenu: false,
      notification: null,
    };
  },
  mounted(){
  },
  methods: {
    navigatedd() {
      this.$router.push({
        name: "Company.Parametres.NotificationPage",
      });
    },
  },
};
</script>
