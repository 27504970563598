<template>
  <div class="tw-flex tw-flex-col tw-justify-center tw-py-7 bg-color-primary tw-h-full tw-text-white">
      <div class="tw-flex tw-justify-center">
          <img :src="referrer.logoWhite" width="140" height="auto" alt="" />
      </div>
      <div class="tw-flex tw-flex-col tw-space-y-2 tw-mt-20">
          <span class="tw-self-center tw-font-extrabold tw-text-4xl tw-tracking-wider">Merci pour vos réponses !</span>
      </div>
      <div class="tw-flex tw-justify-center tw-my-24 tw-font-thin tw-text-lg tw-uppercase">{{user.firstName}} {{user.lastName}}</div>
      <div class="tw-flex tw-justify-center tw-text-center tw-w-2/4 tw-mx-auto">
           <p>Merci d’avoir répondu au questionnaire. Vos réponses ont été enregistrées
            et serviront à la mise en conformité avec le <span class="tw-font-bold">Règlement Général de la Protection des Données. [RGPD]</span>
            </p>
      </div>
      <div class="tw-flex tw-justify-center tw-pt-1 tw-my-16">
          <primary-button-component
              class="dynamic-text tw-bg-white"
              :style="{
                backgroundColor: `${referrer.theme.ctaBgColor} !important`,
                color: `${referrer.theme.ctaTextColor}  !important`,
                border: `2px solid ${referrer.theme.ctaTextColor}  !important`
              }"
            @click="endSteps()" >
            Fermer
          </primary-button-component>
      </div>
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
      name: "QuestionnaireStepFour",
      components: {  PrimaryButtonComponent },
          data(){
        return{
            user:[],
        }
    },
    mounted(){
      this.user=this.$store.getters.getTempUser
    },
    computed: {
      referrer() {
        return this.$store.state.referrer ? this.$store.state.referrer : {};
      },
    },
    methods:{
        endSteps(){
            this.$store.commit('SET_QUESTIONNAIRE_TOKEN',"")
            this.$router.push({name:'landing-public'})
        },

    }
}
</script>

<style>

</style>
