<template>
  <div>
    <TextFieldComponent
      :error="validate && Boolean(passwordStrength) && passwordStrength < 4"
      v-on:keyup="keyup"
      :type="passwordVisible ? 'text' : 'password'"
      :append-icon="passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
      v-bind="$attrs"
      v-on="$listeners"
      :toggle-right-icon="toggleRightIcon"
      :hide-details="hideDetails"
    />
    <div v-if="validate" class="tw-relative">
      <div
        class="mt-2 tw-h-2 tw-rounded-lg tw-overflow-hidden tw-bg-white-100 tw-mb-3 tw-relative"
      >
        <div
          :style="{ background: progressBarColors[passwordStrength] }"
          :class="progressBarClass"
          class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-bg-red"
        ></div>
      </div>
      <div class="tw-text-black-100 tw-font-normal tw-text-sm" v-html="passwordRules"></div>
    </div>
  </div>
</template>

<script>
import TextFieldComponent from "./TextFieldComponent";
import { calculatePasswordStrength } from "../../utils";
export default {
  name: "PasswordFieldComponent",
  components: { TextFieldComponent },
  props: {
    validate: Boolean,
    passwordRules: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      passwordStrength: 0,
      progressBarColors: {
        1: "#DF5760",
        2: "#d6d26f",
        3: "#ccd66f",
        4: "#69C285",
      },
      passwordVisible: false,
    };
  },
  computed: {
    progressBarClass: function () {
      if (this.passwordStrength < 4) {
        return `tw-w-${this.passwordStrength}/4`;
      }

      return "tw-w-full";
    },
  },
  methods: {
    keyup({ target: { value } }) {
      if (value) {
        this.passwordStrength = calculatePasswordStrength(value);
      } else {
        this.passwordStrength = 1;
      }
    },
    toggleRightIcon() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style scoped></style>
