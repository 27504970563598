<template>
  <v-app :style="cssVars">
    <MobileOrTabletModal
      v-model="isMobileOrTablet"
      v-on:close="isMobileOrTablet = false"
    />
    <notifications group="foo" position="bottom right" />
    <SnackbarComponent />
    <router-view class="tw-bg-white"></router-view>
  </v-app>
</template>

<script>
import SnackbarComponent from "./components/common/SnackbarComponent";
import { isMobileOrTablet } from "./utils";
import MobileOrTabletModal from "./components/MobileOrTabletModal";
import { serverConfig } from "./config/serverConfig";
import axios from "axios";

export default {
  name: "App",
  components: {
    MobileOrTabletModal,
    SnackbarComponent,
  },
  data() {
    return {
      isMobileOrTablet: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    cssVars() {
      return {
        '--cta-bg-color': this.referer.theme.ctaBgColor,
        '--cta-text-color': this.referer.theme.ctaBgColor,
        '--border-input-color': this.referer.theme.ctaBgColor,
        '--rgpd-processes-color-one': this.referer.theme.rgpdProcessesOne,
        '--rgpd-processes-color-two': this.referer.theme.rgpdProcessesTwo,
        '--rgpd-processes-color-three': this.referer.theme.rgpdProcessesThree,
        '--rgpd-processes-color-four': this.referer.theme.rgpdProcessesFour,
        '--rgpd-processes-color-five': this.referer.theme.rgpdProcessesFive,
      }
    },
  },
  mounted() {
    // this.$gtag.event('page-view', { method: 'Google' })
    this.isMobileOrTablet = isMobileOrTablet();
    axios.defaults.headers.common['x-maintenance-bypass-key'] = this.$store.state.maintenanceBypassKey;
    axios
      .get(`${serverConfig.appUrl}/affiliate?hostname=${location.host}`)
      .then(({ data }) => {
        if(data !== '') {
          this.$store.dispatch('setReferrer', data);
          const favicon = document.getElementById("favicon");
          favicon.href = data.favicon;
        }

      });
  },
};
</script>

<style lang="scss" scoped>

</style>
