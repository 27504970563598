export default [
    {
            id:1,
            type:'governance',
            title:"Ma<br> gouvernance",
            percentage:5,
            route: 'Gouvernance.List.Page',
            svg:`<svg width="79" height="68" viewBox="0 0 79 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1397_6823)">
                    <path d="M52.0306 68H25.8994L0.12207 22.4626H26.075L52.0306 68Z" fill="#FE4F0D"/>
                    <path d="M65.9327 0.429199L78.9996 22.9687L52.2938 67.9708L39.3174 45.585L65.9327 0.429199Z" fill="#F37748"/>
                    <path d="M0 22.5395L13.0669 0L65.55 0.532554L52.5709 22.921L0 22.5395Z" fill="#EFAD93"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1397_6823">
                    <rect width="79" height="68" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>`
          },
    {
    id:2,
    type:'ecosystem',
    title:"Mon écosystème des données",
    percentage:15,
    route: 'EcoSystem.List',
    svg:`<svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1397_6787)">
        <path d="M35.4988 0C28.4778 0 21.6145 2.08203 15.7767 5.98282C9.93898 9.88362 5.38903 15.428 2.7022 21.9147C0.0153813 28.4015 -0.687614 35.5394 0.682116 42.4257C2.05185 49.3121 5.43277 55.6375 10.3974 60.6023C15.362 65.5671 21.6873 68.9481 28.5734 70.3179C35.4595 71.6877 42.5971 70.9847 49.0836 68.2977C55.5702 65.6108 61.1143 61.0607 65.015 55.2228C68.9157 49.3848 70.9976 42.5213 70.9976 35.5V0H35.4988ZM61.7203 35.5C61.7208 40.6864 60.1833 45.7565 57.3023 50.069C54.4213 54.3816 50.3263 57.7429 45.535 59.728C40.7437 61.713 35.4713 62.2326 30.3847 61.2211C25.298 60.2096 20.6256 57.7123 16.9582 54.0451C13.2909 50.378 10.7933 45.7056 9.78134 40.6189C8.76939 35.5322 9.28855 30.2596 11.2731 25.468C13.2577 20.6763 16.6186 16.5808 20.9307 13.6994C25.2429 10.8179 30.3126 9.27995 35.4988 9.27995H61.7203V35.5Z" fill="#84BCDA"/>
        <path d="M9.27734 35.5001C9.27734 42.4559 12.0405 49.1268 16.9588 54.0454C21.8772 58.9639 28.5479 61.7271 35.5035 61.7271C42.4591 61.7271 49.1298 58.9639 54.0481 54.0454C58.9665 49.1268 61.7296 42.4559 61.7296 35.5001V9.28003H35.4988C32.0555 9.27973 28.6458 9.9577 25.4645 11.2752C22.2831 12.5928 19.3925 14.5241 16.9576 16.9589C14.5226 19.3937 12.5911 22.2843 11.2733 25.4656C9.95556 28.6469 9.27734 32.0566 9.27734 35.5001ZM51.1975 19.8124V35.5093C51.198 38.6144 50.2777 41.6499 48.5529 44.232C46.8282 46.814 44.3766 48.8266 41.508 50.0152C38.6395 51.2037 35.483 51.515 32.4376 50.9095C29.3922 50.304 26.5947 48.809 24.399 46.6135C22.2032 44.418 20.7079 41.6207 20.102 38.5753C19.496 35.5298 19.8068 32.3731 20.9949 29.5043C22.183 26.6356 24.1952 24.1835 26.7768 22.4584C29.3585 20.7332 32.3938 19.8124 35.4988 19.8124H51.1975Z" fill="#60CDD7"/>
        <path d="M9.27734 35.5001C9.27734 42.4559 12.0405 49.1268 16.9588 54.0454C21.8772 58.9639 28.5479 61.7271 35.5035 61.7271C42.4591 61.7271 49.1298 58.9639 54.0481 54.0454C58.9665 49.1268 61.7296 42.4559 61.7296 35.5001V9.28003H35.4988C32.0555 9.27973 28.6458 9.9577 25.4645 11.2752C22.2831 12.5928 19.3925 14.5241 16.9576 16.9589C14.5226 19.3937 12.5911 22.2843 11.2733 25.4656C9.95556 28.6469 9.27734 32.0566 9.27734 35.5001ZM51.1975 19.8124V35.5093C51.198 38.6144 50.2777 41.6499 48.5529 44.232C46.8282 46.814 44.3766 48.8266 41.508 50.0152C38.6395 51.2037 35.483 51.515 32.4376 50.9095C29.3922 50.304 26.5947 48.809 24.399 46.6135C22.2032 44.418 20.7079 41.6207 20.102 38.5753C19.496 35.5298 19.8068 32.3731 20.9949 29.5043C22.183 26.6356 24.1952 24.1835 26.7768 22.4584C29.3585 20.7332 32.3938 19.8124 35.4988 19.8124H51.1975Z" fill="#B4E1FA"/>
        </g>
        <defs>
        <clipPath id="clip0_1397_6787">
        <rect width="71" height="71" fill="white"/>
        </clipPath>
        </defs>
        </svg>`
  },
    {
        id:3,
        type:'contractAmendments',
        title:"Mes processus RGPD",
        percentage:20,
        route: 'Company.RgpdProcesses.List',
        svg:`<svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1397_6745)">
        <path d="M43.2042 21.7959H0V65.0001H43.2042V21.7959Z" fill="#BC9B08"/>
        <path d="M59.7668 5.23584H5.3252V59.6775H59.7668V5.23584Z" fill="#ECC30B"/>
        <path d="M65.0001 0H21.7959V43.2065H65.0001V0Z" fill="#FFE25F"/>
        </g>
        <defs>
        <clipPath id="clip0_1397_6745">
        <rect width="65" height="65" fill="white"/>
        </clipPath>
        </defs>
        </svg>`
  },
    {
    id:4,
    type:'processingRegister',
    title:"Mon 1er registre de traitement",
    percentage:25,
    route: 'Register.PreRegister.Page',
              svg:`<svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1397_6741)">
                  <path d="M65.0005 32.5001C65.0005 32.4823 65.0005 32.4646 65.0005 32.4446C65.0005 23.8256 61.5766 15.5597 55.4821 9.46516C49.3875 3.37064 41.1216 -0.0532227 32.5027 -0.0532227C23.8837 -0.0532227 15.6178 3.37064 9.52328 9.46516C3.42876 15.5597 0.00488281 23.8256 0.00488281 32.4446C0.00488281 32.4646 0.00488281 32.4823 0.00488281 32.5001C0.00488281 32.5179 0.00488281 32.5379 0.00488281 32.5556C0.0184204 41.1655 3.44833 49.4181 9.54144 55.5012C15.6346 61.5843 23.8928 65.0007 32.5027 65.0001V54.6836C26.6262 54.683 20.99 52.3508 16.8313 48.1988C12.6727 44.0468 10.3314 38.4144 10.3214 32.5379C10.3214 32.5379 10.3214 32.5134 10.3214 32.5001C10.3214 32.4868 10.3214 32.4757 10.3214 32.4623H32.5027V10.3099C38.379 10.3099 44.0152 12.6417 48.1739 16.7933C52.3326 20.9449 54.674 26.5771 54.684 32.4534C54.684 32.4668 54.684 32.4801 54.684 32.4912C54.684 32.5023 54.684 32.5179 54.684 32.529V32.5467H65.0005C64.9982 32.5379 65.0005 32.5245 65.0005 32.5001Z" fill="#84DA92"/>
                  <path d="M32.5 54.6836C38.3734 54.6831 44.007 52.3533 48.1651 48.2051C52.3233 44.057 54.6666 38.4291 54.6813 32.5557H32.5V54.6836Z" fill="#84DA92"/>
                  <path d="M54.6832 32.5C54.6832 32.5 54.6832 32.4756 54.6832 32.4622C54.6755 26.584 52.3349 20.9492 48.1754 16.7956C44.016 12.6419 38.3779 10.3092 32.4996 10.3098V32.4534H10.3184C10.3184 32.4667 10.3184 32.48 10.3184 32.4911C10.3184 32.5022 10.3184 32.5178 10.3184 32.5289C10.3284 38.4054 12.6696 44.0378 16.8283 48.1898C20.9869 52.3418 26.6231 54.6741 32.4996 54.6746V32.5555H54.681V32.5378C54.681 32.5378 54.6832 32.5244 54.6832 32.5Z" fill="#B0FBBC"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1397_6741">
                  <rect width="65" height="65" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                  `
  },
    {
    id:5,
    type:'subContractorEvaluation',
    title:"L’évaluation de ma sous-traitance",
    route: 'SubContractor.List',
    percentage:20,
              svg:`<svg width="75" height="65" viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1397_6725)">
                      <g style="mix-blend-mode:multiply">
                      <path d="M37.3347 8.10473L37.3174 0.00216184L74.9421 64.8076L37.3174 0.00216184L37.311 0L0.00195312 64.987L37.311 0L37.3347 8.10473Z" fill="#60CDD7"/>
                      </g>
                      <g style="mix-blend-mode:multiply">
                      <path d="M74.9357 64.8185L74.9421 64.8077L56.2012 54.0569L74.9357 64.8185Z" fill="#BA6CA0"/>
                      </g>
                      <g style="mix-blend-mode:multiply">
                      <path d="M16.3521 55.501L0.00216184 64.9871L0 65.0001L16.3521 55.501Z" fill="#F4A0B5"/>
                      </g>
                      <path d="M37.4104 43.267L37.3347 8.10473L37.311 0L0.00195312 64.987L16.3519 55.5009L37.4104 43.267Z" fill="#9381FF"/>
                      <path d="M37.4171 43.2778L16.3521 55.5009L0 65L74.9358 64.8184L56.2013 54.0568L37.4171 43.2778Z" fill="#E0DBFF"/>
                      <path d="M37.411 43.2736V43.2671L16.3525 55.5009L37.4175 43.2779L37.411 43.2736Z" fill="#F4A0B5"/>
                      <g style="mix-blend-mode:multiply">
                      <path d="M37.411 43.2736V43.2671L16.3525 55.5009L37.4175 43.2779L37.411 43.2736Z" fill="#98C7D9"/>
                      </g>
                      <path d="M37.3347 8.10477L37.4276 43.2714L56.201 54.0568L74.942 64.8076L37.3174 0.00219727L37.3347 8.10477Z" fill="#AC9EFF"/>
                      <path d="M37.4279 43.2714L37.335 8.10474L37.4106 43.2671L37.415 43.2649L37.4279 43.2714Z" fill="#98C7D9"/>
                      <g style="mix-blend-mode:multiply">
                      <path d="M37.4279 43.2714L37.335 8.10474L37.4106 43.2671L37.415 43.2649L37.4279 43.2714Z" fill="#EFBFD9"/>
                      </g>
                      <path d="M37.4169 43.2785L37.4282 43.2717L37.4147 43.2649L37.4102 43.2672V43.2739L37.4169 43.2785Z" fill="#EF79B4"/>
                      <g style="mix-blend-mode:multiply">
                      <path d="M37.4169 43.2785L37.4282 43.2717L37.4147 43.2649L37.4102 43.2672V43.2739L37.4169 43.2785Z" fill="#A58DE0"/>
                      </g>
                      </g>
                      <defs>
                      <clipPath id="clip0_1397_6725">
                      <rect width="74.9423" height="65" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>
                      `
  },
    {
    id:6,
    type:'contributors',
    title:"Mes<br> collaborateurs",
    route: 'Collaborateurs',
    percentage:15,
              svg:`<svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1397_6720)">
                    <g filter="url(#filter0_d_1397_6720)">
                    <path d="M38.8575 0H26.198L0 26.1991L26.122 52.4767L32.1489 46.4144L12.6698 26.1888L38.8575 0Z" fill="#FF9799"/>
                    </g>
                    <path d="M64.877 39.0859V26.352L38.8311 0L12.707 26.2755L18.7339 32.3379L38.8413 12.7443L64.877 39.0859Z" fill="#D56062"/>
                    <path d="M26.1426 64.9999H38.8001L65.0001 38.7987L38.876 12.5232L32.8512 18.5855L52.3303 38.8111L26.1426 64.9999Z" fill="#A44446"/>
                    <path d="M0.0576172 25.9141V38.646L26.1036 65L52.2276 38.7224L46.2008 32.6621L26.0933 52.2557L0.0576172 25.9141Z" fill="#D56062"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_1397_6720" x="-4" y="0" width="46.8574" height="60.4768" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1397_6720"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1397_6720" result="shape"/>
                    </filter>
                    <clipPath id="clip0_1397_6720">
                    <rect width="65" height="65" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `
  }
  ]
