<template>
  <v-dialog v-if="dialog" width="90%" v-model="dialog" @click:outside="close">
    <div>
      <button v-on:click="close" class="tw-absolute tw-left-8 tw-top-8">
        <v-icon large :color="referer.theme.ctaBgColor"> mdi-close </v-icon>
      </button>
      <div class="tw-px-4">
        <h3 class="tw-font-bold tw-text-4xl tw-text-center tw-mb-6 tw-mt-2">
          {{ title }}
        </h3>
        <div>
          <perfect-scrollbar
            @ps-y-reach-end="onEndReach"
            ref="scroll"
            :style="{ height: contentHeight }"
          >
            <slot />
          </perfect-scrollbar>
          <div class="tw-flex tw-flex-col tw-items-center">
            <v-icon style="font-size: 50px !important;" :color="referer.theme.ctaBgColor" @click="moveDown">
              mdi-chevron-down
            </v-icon>
            <div class="tw-flex tw-items-center">
              <PrimaryButtonComponent
                :style="{
                  backgroundColor: `${referer.theme.ctaBgColor}`,
                  color: `${referer.theme.ctaTextColor}`,
                }"
                @click.prevent="onAccept"
                :disabled="!read || conditionsWasAccepted"
                class="tw-mx-auto"
              >
                Accepter
              </PrimaryButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
export default {
  name: "ReadConditionsModalComponent",
  components: { PrimaryButtonComponent },
  props: {
    type: String,
    title: String,
    btnTitle: String,
    btnClass: String,
    conditionsWasAccepted: Boolean,
    dialog: Boolean,
    contentHeight: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      read: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    close() {
      this.$emit("closeEvent");
    },

    onEndReach() {
      this.read = true;
    },
    moveDown(){
      this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
    },
    onAccept() {
      this.$emit("closeEvent");
      this.$emit("acceptConditions");
    },
  },
};
</script>

<style scoped></style>
