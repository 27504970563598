import Questionnaire from '@/Questionnaire'

import ElearningQuizStepOne from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Quiz/ElearningQuizStepOne";
import ElearningQuizChapitre from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Quiz/ElearningQuizChapitre";
import ElearningQuizSousChapitre from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Quiz/ElearningQuizSousChapitre";
import ElearningQuizCours from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Quiz/ElearningQuizCours";
import ElearningQuizQuestions from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Quiz/ElearningQuizQuestions";
// import ElearningQuizAnswers from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Answer/ElearningQuizAnswers";
import ElearningQuizEnd from "@/features/company/pages/MonEntreprise/Collaborateurs/E-Learning/Answer/ElearningQuizEnd";

const routes = [
    {
        name: "ElearningQuiz",
        path: "/elearning",
        component: Questionnaire,
        meta: {
            breadcrumb: { name: ''}
      },
      children: [
          {
            name: "Elearning-quiz-step-one",
            path: "step-one/:token",
            component: ElearningQuizStepOne,
            meta: {
                breadcrumb: { name: ''}
          }
          },
          {
            name: "Elearning-quiz-chapitre",
            path: "chapitres/:id",
            component: ElearningQuizChapitre,
            meta: {
                elearning:true,
  
                breadcrumb: { name: ''}
          }
          },
          {
            name: "Elearning-quiz-sous-chapitre",
            path: "chapitres/:chapitre_id/sous-chapitre/:sous_chapitre_id",
            component: ElearningQuizSousChapitre,
            meta: {
              elearning:true,
  
                breadcrumb: { name: ''}
            },
          },
          {
            name: "Elearning-quiz-cours",
            path: "chapitres/:chapitre_id/sous-chapitre/:sous_chapitre_id/cours",
            component: ElearningQuizCours,
            meta: {
              elearning:true,
  
                breadcrumb: { name: ''}
          }
          },

          {
            name: "Elearning-quiz-question",
            path: "questions",
            component: ElearningQuizQuestions,
            meta: {
              elearning:true,
  
                breadcrumb: { name: ''}
          }
          },
          // {
          //   name: "Elearning-quiz-answers",
          //   path: "answers/:id",
          //   component: ElearningQuizAnswers,
          //   meta: {
          //       requiresAuth: true,
          //       breadcrumb: { name: ''}
          // }
          // },
          {
            name: "Elearning-quiz-end",
            path: "quiz-end",
            component: ElearningQuizEnd,
            meta: {
              elearning:true,
  
                breadcrumb: { name: ''}
          }
          },
      ]
    },
]

export default routes;