<template>
  <v-form @submit="onSubmit" v-model="formValid" class="tw-pb-16 mt-23">
    <div class="tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-3">
    
      <SelectBoxComponent
        v-model="form.gender"
        :items="genderItems"
        label="Civilité"
        class="mb-23"
        hide-details
      />
      <TextFieldComponent
        v-model="form.firstName"
        label="Prénom"
        class="mb-23"   
        hide-details     
      />
      <TextFieldComponent
        v-model="form.lastName"
        label="Nom"
        class="mb-23"
        hide-details
      />
      <TextFieldComponent
        v-model="form.email"
        label="Adresse e-mail"
        class="mb-23"
      />
      <TextFieldComponent
        v-model="form.sirenOrRna"
        label="SIREN / RNA"
        class="mb-23"
        containerStyle="red"
      />
      <TextFieldComponent
        v-model="form.socialReason"
        label="Raison Sociale"
        class="mb-23"
      />
    </div>
    <SubmitButtonComponent
      class="tw-text-white"
      :loading="isSendingForm"
      type="submit"
      >{{ formSubmitButton }}
    </SubmitButtonComponent>
  </v-form>
</template>
<script>
import TextFieldComponent from "@/components/common/TextFieldCompanyComponent";
import SelectBoxComponent from "@/components/common/SelectBoxCompanyComponent";
import SubmitButtonComponent from "@/components/common/SubmitButtonComponent";
import { storeCollaborator } from '../../services/appApi';

export default {
  name: "SoustraitantFormAddComponent",
  computed: {
    formSubmitButton() {
      return "Continuer";
    },
  },
  components: {
    TextFieldComponent,
    SubmitButtonComponent,
    SelectBoxComponent
  },
  data() {
    return {
      isSendingForm: false,
      formValid: false,
      disableInput: true,
      genderItems: [
        { label: "Madame", value: "female" },
        { label: "Monsieur", value: "male" },
      ],
      errors: {
        sirenOrRna: "",
      },
      form: {
        gender: "",
        email: "",
        firstName: "",
        lastName: "",
        sirenOrRna: "",
        socialReason: "",
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.isSendingForm = true;
      storeCollaborator(this.$route.params.token,this.form).then(() => {
        // this.$snackbar.showAlertMessage({
        //   message: res.data.message,
        //   type: "success",
        // });
        this.$router.push({
          name: "MsgSousTraitant",
        });
      }).catch(() => {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Attention",
          text: "Erreur de validation de données",
        });      
      }).finally(()=>{
        this.isSendingForm = false;
      });
      
    },
  },
};
</script>
