<template>
  <div>
    <signup-secondary-text-component class="subheader tw-mb-6">
        {{ documentSelected.title }}
      </signup-secondary-text-component>
      <div class="tw-px-14 font-calibri show-refrence-box">
        <div v-html="documentSelected.html" ref="data"></div>
      </div>
  </div>
</template>

<script>
import SignupSecondaryTextComponent from '../../../../../../auth/components/SignupSecondaryTextComponent.vue'
import data from '../data/data.js'

export default {
  name: 'ViewRefrence',
  components: { SignupSecondaryTextComponent },
  props:{
    documentSelected: {
      type: Object,
      default: () => {}
    },
    changeReference:{
      type: Boolean,
      default: () => false
    },
    subcontractors:{
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
    }
  },
  computed : {
    selectedData (){
        return data.filter(el => el.id == this.documentSelected.id) 
    },
  },
}
</script>

<style lang="scss" scoped>
#app.v-application * {
        font-family: "Calibri" !important;
} 
.show-refrence-box{
    width: 691px;
    padding: 20px;
    overflow: auto;
    margin: 0 auto;
}
</style>