export const stepOne = [
  {
    id: 1,
    content:
      "Quels outils informatiques vous ont été fournis par l’entreprise dans le cadre de votre mission ?",
    answers: [
      [
        {
          id: 1,
          content: "Ordinateur PC/MAC",
          checked: false,
        },
        {
          id: 2,
          content: "Ordinateur portable PC/MAC",
          checked: false,
        },
        {
          id: 3,
          content: "Tablette",
          checked: false,
        },
        {
          id: 4,
          content: "Smartphone",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 2,
    content:
      "Utilisez-vous un outil informatique personnel, vous appartenant ?",
    answers: [
      [
        {
          id: 5,
          content: "Ordinateur PC/MAC",
          checked: false,
        },
        {
          id: 6,
          content: "Ordinateur portable PC/MAC",
          checked: false,
        },
        {
          id: 7,
          content: "Tablette",
          checked: false,
        },
        {
          id: 8,
          content: "Smartphone",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 3,

    content: "Sur quel type de logiciel(s) de l’Entreprise travaillez-vous ?",

    answers: [
      [
        {
          id: 9,
          content: "Ressources Humaines",
          checked: false,
        },
        {
          id: 10,
          content: "CRM",
          checked: false,
        },
        {
          id: 11,
          content: "ERP",
          checked: false,
        },
        {
          id: 12,
          content: "Comptabilité",
          checked: false,
        },
      ],
      [
        {
          id: 13,
          content: "Planification",
          checked: false,
        },
        {
          id: 14,
          content: "ATS",
          checked: false,
        },
        {
          id: 15,
          content: "Logiciels métier",
          checked: false,
        },
        {
          id: 16,
          content: "Gestion de projet",
          checked: false,
        },
      ],
      [
        {
          id: 17,
          content: "Solutions type Pack-Office",
          checked: false,
        },
        {
          id: 18,
          content: "Messagerie mail ou autre",
          checked: false,
        },
        {
          id: 19,
          content: "Gestionnaire base données",
          checked: false,
        },
        {
          id: 20,
          content: "Plateformes web distantes",
          checked: false,
        },
      ],
      [
        {
          id: 21,
          content: "Plateformes ou CloudSaas",
          checked: false,
        },
        {
          id: 22,
          content: "Plateformes administrations publiques",
          checked: false,
        },
        {
          id: 23,
          content: "Plateformes de routage",
          checked: false,
        },
        {
          id: 24,
          content: "Services de géolocalisation",
          checked: false,
        },
      ],
    ],
  },
];
export const stepTwo = [
  {
    id: 4,
    content:
      "Quelles sont les personnes dont vous traitez les données dans le cadre de votre mission ?",
    answers: [
      [
        {
          id: 25,
          content: "Collaborateurs",
          checked: false,
        },
        {
          id: 26,
          content: "Candidats",
          checked: false,
        },
        {
          id: 27,
          content: "Clients",
          checked: false,
        },
        {
          id: 28,
          content: "Contacts commerciaux",
          checked: false,
        },
        {
          id: 29,
          content: "Contacts prestataires",
          checked: false,
        },
        {
          id: 30,
          content: "Contacts fournisseurs",
          checked: false,
        },

        {
          id: 31,
          content: "Prospects",
          checked: false,
        },
        {
          id: 32,
          content: "Prescripteurs",
          checked: false,
        },
        {
          id: 33,
          content: "Distributeurs",
          checked: false,
        },
        {
          id: 34,
          content: "Membres",
          checked: false,
        },
        {
          id: 35,
          content: "Licenciés",
          checked: false,
        },
        {
          id: 36,
          content: "Patients",
          checked: false,
        },
        {
          id: 37,
          content: "Actionnaires",
          checked: false,
        },
        {
          id: 38,
          content: "Sociétaires",
          checked: false,
        },
        {
          id: 39,
          content: "Administrés",
          checked: false,
        },
        {
          id: 40,
          content: "Partenaires",
          checked: false,
        },
        {
          id: 41,
          content: "Contacts administration",
          checked: false,
        },
        {
          id: 42,
          content: "Autres",
          checked: false,
        },
        {
          id: 43,
          content: "Aucunes",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 5,
    content: "Quelles types de données utilisez-vous ?",
    answers: [
      [
        {
          id: 90,
          content:
            "État civil, identité, données d'identification, images, pièces identité…",
          checked: false,
        },
        {
          id: 44,
          content:
            "Vie personnelle (habitudes de vie, situation familiale, etc.)",
          checked: false,
        },
        {
          id: 45,
          content:
            "Informations d'ordre économique et financières (revenus, situation financière, situation fiscale, etc.) ",
          checked: false,
        },
        {
          id: 46,
          content:
            "Données de contacts, adresse postale, adresse email, téléphones fixe, téléphone portable",
          checked: false,
        },
      ],
      [
        {
          id: 47,
          content:
            "Données de connexion (adresse IP, login, mots de passe, etc.)",
          checked: false,
        },
        {
          id: 48,
          content:
            "Données de localisation (déplacements, données GPS, GSM, etc.)",
          checked: false,
        },
        {
          id: 49,
          content: "Numéro de Sécurité Sociale (ou NIR)",
          checked: false,
        },
        {
          id: 50,
          content: "Données de paiement, Carte bancaire, SEPA, RIB",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 6,
    content: "Êtes-vous amenés à utiliser des données de ce type ?",
    answers: [
      [
        {
          id: 51,
          content: "Données de santé",
          checked: false,
        },
        {
          id: 52,
          content: "Données Génétiques",
          checked: false,
        },
        {
          id: 53,
          content: "Données biométriques",
          checked: false,
        },
        {
          id: 54,
          content: "Appartenances syndicales",
          checked: false,
        },
      ],
      [
        {
          id: 55,
          content: "Convictions religieuses ou philosophiques",
          checked: false,
        },
        {
          id: 56,
          content: "Opinions politiques",
          checked: false,
        },
        {
          id: 57,
          content: "Orientations sexuelles",
          checked: false,
        },
        {
          id: 58,
          content: "Condamnations",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 7,
    content:
      "Ces données enregistrées dans le cadre de votre mission sont elles consultables ou transmises à d’autres destinataires ?",
    answers: [
      [
        {
          id: 59,
          content: "Collaborateur du service",
          checked: false,
        },
        {
          id: 60,
          content: "Direction",
          checked: false,
        },
        {
          id: 61,
          content: "Autres services internes",
          checked: false,
        },
        {
          id: 62,
          content: "Sous-traitants/prestataires",
          checked: false,
        },
      ],
      [
        {
          id: 63,
          content: "Administration",
          checked: false,
        },
        {
          id: 64,
          content: "Hébergeurs/Infogérents",
          checked: false,
        },
        {
          id: 65,
          content: "Clients / prospects / patients",
          checked: false,
        },
        {
          id: 66,
          content: "Contacts personnels",
          checked: false,
        },
      ],
    ],
  },
];
export const stepThree = [
  {
    id: 8,
    content:
      "Dans le cadre de votre mission, à quels types de traitements procédez-vous ?",
    answers: [
      [
        {
          id: 67,
          content: "Collecte",
          checked: false,
        },
        {
          id: 68,
          content: "Enregistrement",
          checked: false,
        },
        {
          id: 69,
          content: "Modification",
          checked: false,
        },
        {
          id: 70,
          content: "Utilisation",
          checked: false,
        },
        {
          id: 71,
          content: "Édition",
          checked: false,
        },
        {
          id: 72,
          content: "Consultation",
          checked: false,
        },
        {
          id: 73,
          content: "Exportation",
          checked: false,
        },
        {
          id: 74,
          content: "Diffusion",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 9,
    content:
      "Comment qualifieriez-vous les données personnelles que vous traitez ?",
    answers: [
      [
        {
          id: 75,
          content: "Sensibles",
          checked: false,
        },
        {
          id: 76,
          content: "Nombreuses",
          checked: false,
        },
        {
          id: 77,
          content: "Bien protégées",
          checked: false,
        },
        {
          id: 78,
          content: "Utiles",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 10,
    content:"Comment décririez-vous la politique de protection des données de votre entreprise ?",
    answers: [
      [
        {
          id: 79,
          content: "Générale",
          checked: false,
        },
        {
          id: 80,
          content: "Continue",
          checked: false,
        },
        {
          id: 81,
          content: "Volontaire",
          checked: false,
        },
        {
          id: 82,
          content: "Technique",
          checked: false,
        },
        {
          id: 83,
          content: "Claire",
          checked: false,
        },
        {
          id: 84,
          content: "Responsabilisante",
          checked: false,
        },
        {
          id: 85,
          content: "Documentée",
          checked: false,
        },
        {
          id: 86,
          content: "Compréhensible",
          checked: false,
        },
      ],
    ],
  },
  {
    id: 11,
    content:"A quelles règles avez-vous été sensibilisé par votre entreprise ?",
    answers: [
      [
        {
          id: 87,
          content: "Règles relatives à la sécurité du système d’information",
          checked: false,
        },
        {
          id: 88,
          content: "Règles relatives à la protection des données privées",
          checked: false,
        },
        {
          id: 89,
          content: "Règles relatives à l’utilisation des outils informatiques",
          checked: false,
        },
        {
          id: 90,
          content:
            "Règles relatives à l’archivage, à l’exportation et à la destruction des données",
          checked: false,
        },
      ],
    ],
  },
];
