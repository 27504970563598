<template>
  <v-app class="tw-pt-0 tw-mt-0">
    <div id="governance-accept-receipt" class="tw-flex tw-flex-col tw-items-center">
      <img class="accept-receipt-logo" :src="referer.logoWhite" width="20%" height="auto" alt="LOGO" />
      <h1 class="accept-receipt-title tw-mt-20">Merci d’avoir accusé réception</h1>
      <p class="accept-receipt-name tw-mt-12">{{ fullName }}</p>
    </div>
  </v-app>
</template>

<script>

import { VerifyReferenceEmailReception } from "@/features/company/services/appApi";

export default {
  name: "NewGouvernanceAcceptReceipt",
  components: {},
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      fullName: '',
    }
  },
  mounted() {
    VerifyReferenceEmailReception(this.$route.params.token)
      .then(response => {
        // if (response.data.data) {
          console.log("🚀 ~ file: GouvernanceReferenceVerification.vue:40 ~ mounted ~ response:", response.data)
          this.fullName = `${response.data.firstName} ${response.data.lastName}`;
        // } else {
        //   this.$router.replace({ name: 'landing-public' });
        // }

      })
    .catch(({response}) => this.$notify({
                      group: "foo",
                      type: "error",
                      title: "Attention",
                      position: "bottom right",
                      text: response.data.message,
                    }));
  }
}
</script>

<style scoped>

</style>
