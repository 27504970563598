<template>
  <div id="Questionnaire-rgbd">
    <template v-if="loading_qst">
         <content-placeholders class="">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
    </template>
    <template v-else>
      <div class="tw-flex tw-items-center tw-justify-between">
        <EntrepriseHeaderBlockTextComponent
          title="Questionnaire RGPD"
          description="Répondez à toutes les questions pour établir votre score."
        >
        </EntrepriseHeaderBlockTextComponent>
      </div>
      <div class="tw-w-full marginTop" id="table">
        <ChapterComponent
          v-for="(chapter, key) in blueprint"
          :key="key"
          :chapter="chapter"
          :chapter-order-number="key+1"
          :saved-chapter="quizDetail[key]"
          :can-show-questions="selectChapitre == chapter.id"
          @selectChapter="handleChapitre"
          @input-changed="hanleUserResponds"
        ></ChapterComponent>
      </div>
      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-20">
        <v-btn
          elevation="1"
          text
          rounded
          large
          @click="Enregistrer()"
          class="victor-button-bg"
        >
          Enregistrer
        </v-btn>
        <v-btn elevation="1" text rounded large @click="showPopup()">
          Valider
        </v-btn>
      </div>
      <div class="tw-flex tw-justify-between">
        <p>
          Vous pouvez enregistrer à tout moment pour sauvegarder vos réponses.
          Vous pourrez revenir sur chacune d’entre-elles par la suite.
        </p>
        <p>
          Une fois votre questionnaire validé, vous ne pourrez plus modifier vos
          réponses. Votre évaluation vous sera transmise par email.
        </p>
      </div>
      <AccepterPopup
        :openPopup="openPopup"
        @fetchData="Valider()"
        @close="closePopup"
      />
    </template>
  </div>
</template>
<script>
import AccepterPopup from "@/features/company/components/Questionnaire/AccepterPopup";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import ChapterComponent from "@/features/company/components/Questionnaire/RGPDChapterComponent";
// import SpinnerViqtor from '@/components/common/SpinnerViqtor';

import {
  getQuiBlueprint,
  storeDraftQuiz,
  getQuizDetail,
  storeQuiz,
} from "../../../services/appApi";

export default {
  name: "QuestionnaireRGBDPage",
  computed: {
    questionnaireScore() {
      var sum = this.blueprint.reduce((acc, item) => acc + item.score, 0);
      var lenght = this.blueprint.length;
      return (sum / lenght).toFixed(2);
    },
  },
  components: {
    EntrepriseHeaderBlockTextComponent,
    ChapterComponent,
    AccepterPopup,
    // SpinnerViqtor
  },
  data() {
    return {
      blueprint: [],
      quizDetail: [],
      chapters: [],
      selectChapitre: null,
      openPopup: false,
      loading_qst:false,
      loading:true
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    showPopup() {
      this.openPopup = true;
    },
    closePopup(value) {
      this.openPopup = value;
    },
    async fetchData() {
      this.loading_qst=true;
      await getQuiBlueprint().then((response) => {
        this.blueprint = response.data.data;
        this.chapters = this.blueprint.map((item) => {
          return {
            chapterId: item.id,
            questions: item.questions.map((q) => {
              return {
                questionId: q.id,
                responses: q.responses.map((r) => {
                  return {
                    id: r.id,
                    label: r.label,
                    responseId: null,
                  };
                }),
              };
            }),
          };
        });
        this.quizDetail = this.chapters.map(chapter => {
          return {
            chapterId: chapter.chapterId,
            questions: chapter.questions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses.map((response) => {
                  const isAnswerIncluded = !!this.quizDetail
                    .find((item) => item.chapterId == chapter.chapterId)
                    .questions.find((q) => q.questionId == question.questionId)
                    .responses.find((r) => r.id == response.id)

                  return {
                    id: response.id,
                    label: response.label,
                    responseId: isAnswerIncluded ? response.id : null,
                  };
                }),
              };
            }),
          }
        })
      })
      .catch((err) => {
        console.log(err);
        this.$snackbar.showAlertMessage({
          message: err.response.data.message,
          type: "error",
        });
      })
      .finally(() => {
          this.loading_qst = false;
      });
    },
    loadData() {
      this.loading = true;
      getQuizDetail().then((response) => {
        if(response.data.data ){
          this.quizDetail = response.data.data.quizChapters.map((item) => {
            const questions = item.quizQuestions.map((question) => {
              return {
                questionId: question.questionId,
                responses: question.responses,
              };
            });
            return { chapterId: item.chapterId, questions: questions };
          });
        }
      }).then(()=>{
        this.fetchData();
        this.loading = false;
      });
    },
    handleChapitre(id) {
      if (this.selectChapitre == id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    hanleUserResponds(chapterId, questions) {
      this.chapters = this.chapters.map((item) => {
        if (item.chapterId == chapterId) {
          return {
            chapterId,
            questions,
          };
        }
        return item;
      });
    },
    Enregistrer() {
      const data = {
        chapters: this.chapters,
      };
      storeDraftQuiz(data).then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((err) => {
          this.$snackbar.showAlertMessage({
            message: err.response.data.message,
            type: "error",
          });
        });
      // this.$router.push({
      //   name: "Company.MonEntreprisePage.Questionnaire.RGBD.valid",
      // });
    },
    Valider() {
      const data = {
        chapters: this.chapters,
      };
      storeQuiz(data)
        .then(() => {
          this.$router.push({
            name: "Company.MonEntreprisePage.Questionnaire.RGBD.invitation",
          });
        })
        .catch((err) => {
          if (err.response.data.statusCode === 400) {
            this.$snackbar.showAlertMessage({
              message: err.response.data.message,
              type: "error",
            });
          }
          this.openPopup = false;
        })
        .finaly(() => (this.openPopup = false));
    },
  },
};
</script>
<style scoped></style>
