<template>
  <v-container class="overflow-auto px-12">
    <Breadcrumb divider=">"></Breadcrumb>
    <v-row no-gutters class="mb-12 mt-4">
      <v-col cols="12">
        <h1 class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl">
          Invitation à l’évaluation de vos sous-traitants
        </h1>
        <h5 class="tw-tracking-tight tw-text-base caption">
          Vous pouvez modifier l’invitation si besoin.
        </h5>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-center" cols="12">
        <h1 class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4">
          Aperçu de l’e-mail envoyé à vos sous-traitants
        </h1>
      </v-col>

      <div class="mail-preview">
        <rich-text-editor v-if="edit" v-model="text" />
        <div class="px-14 py-6" v-else v-html="text"></div>
      </div>

      <v-col class="my-4 d-flex justify-space-between" cols="12">
        <PrimaryButtonComponent class="edit-btn" v-if="!edit" @click="edit = true" :style="{
          backgroundColor: `${referer.theme.ctaBgColor} !important`,
          color: `${referer.theme.ctaTextColor} !important`,
        }">Modifier</PrimaryButtonComponent>
        <PrimaryButtonComponent class="edit-btn" :style="{
          backgroundColor: `${referer.theme.ctaBgColor} !important`,
          color: `${referer.theme.ctaTextColor} !important`,
        }" v-if="edit" @click="edit = false">Annuler</PrimaryButtonComponent>
        <PrimaryButtonComponent :style="{
          backgroundColor: `${referer.theme.ctaBgColor} !important`,
          color: `${referer.theme.ctaTextColor} !important`,
        }" class="test-send-btn" @click="test_invitation" :loading="isSendingForm">Tester l’invitation
        </PrimaryButtonComponent>

        <PrimaryButtonComponent :style="{
          backgroundColor: `${referer.theme.ctaBgColor} !important`,
          color: `${referer.theme.ctaTextColor} !important`,
        }" class="next-btn" @click="next_btn">Suivant</PrimaryButtonComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { sendTestInvitation } from "../services/appApi";
import ApiService from "@/services/api.service";
import _ from "lodash";
import { serverConfig } from "@/config/serverConfig";

export default {
  name: "SubContractorInvitation",
  computed: {
    subcontractorsValid() {
      return this.$store.getters.getSubcontractorsValid;
    },
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      isSendingForm: false,
      IsimportMany: false,
      edit: false,
      text:
        '<table\r\n id="email" role="presentation"\r\n border="0"\r\n cellpadding="0"\r\n cellspacing="0"\r\n class="body"\r\n>\r\n <tr>\r\n <td>&nbsp;</td>\r\n <td class="container">\r\n <div class="content">\r\n <!-- START CENTERED WHITE CONTAINER -->\r\n <table role="presentation" class="main">\r\n <!-- START MAIN CONTENT AREA -->\r\n <tr>\r\n<td class="wrapper"><table\r\n role="presentation"\r\n border="0"\r\n cellpadding="0"\r\n cellspacing="0"\r\n >\r\n <tr>\r\n <td>\r\n <div\r\n style="\r\n display: flex;flex-direction:column;align-items:center;gap:40px;\r\n justify-content: center;\r\n align-items: center;\r\n margin: 2rem 0;\r\n "\r\n >\r\n<img\r\nsrc="https://i.ibb.co/7ynkVts/viqtor-noir2.png"\r\nwidth="200"\r\n/>\r\n<img\r\nsrc="https://i.ibb.co/7ynkVts/viqtor-noir.png"\r\nalt="logo"\r\nwidth="120"\r\n/>\r\n </div>\r\n <h1 class="purpleheader">\r\n ' +
        this.$store.state.user.workInCompany.socialReason +
        "\r\n <br />\r\n \u00C9metteur : " +
        this.$store.state.user.firstName +
        " " +
        this.$store.state.user.lastName +
        "/ " +
        this.$store.state.user.role +
        '\r\n </h1>\r\n</td>\r\n </tr>\r\n<tr>\r\n<td class="purplefooter"><div>INFORMATION \u00C0 NOS FOURNISSEURS ET SOUS-TRAITANTS</div></td>\r\n</tr>\r\n<tr>\r\n<td>\r\n <div class="br">\r\n <br />\r\n <br />\r\n Bonjour,\r\n <br />\r\n <br />\r\n Dans le cadre de notre  mise en conformit\u00E9 au RGPD [R\u00E8glement\r\n G\u00E9n\u00E9ral sur la Protection des donn\u00E9es], nous avons\r\n l\u2019obligation d\u2019\u00E9valuer la conformit\u00E9 de nos\r\n partenaires, fournisseurs et sous-traitants, dont vous\r\n faites partie.\r\n <br />\r\n <br />\r\n C\u2019est important, car votre conformit\u00E9 au RGPD est\r\n d\u00E9terminante pour notre conformit\u00E9 et ses risques\r\n associ\u00E9s, business comme financiers.\r\n <br />\r\n <br />\r\n Sans cette \u00E9valuation il deviendra compliqu\u00E9 de\r\n poursuivre des relations commerciales avec votre\r\n entreprise.\r\n <br />\r\n <br />\r\n Nous avons choisi de vous \u00E9valuer avec Viqtor\u00AE, la\r\n seule plateforme qui propose un module d\u00E9di\u00E9, tr\u00E8s\r\n simple et surtout rapide.\r\n <br />\r\n <br />\r\n En cliquant sur le lien unique et s\u00E9curis\u00E9 ci-dessous,\r\n laissez-vous guider, vous en aurez montre en main pour\r\n \u00E0 peines quelques minutes.\r\n </div>\r\n </td>\r\n </tr>\r\n <td class="purplefooter" style="padding: 1rem" ><div><p style="text-align: left;color:#FFFFFF">Vous avez été identifié comme l’interlocuteur de ' +
        this.$store.state.user.workInCompany.tradeName +
        ' pour répondre au questionnaire d’évaluation de votre conformité au RGPD.<br/><br/>Êtes-vous bien le bon interlocuteur pour répondre à cette évaluation qui engage votre entreprise ?</p><br /><table><tr><td><a style="color:white; border-color: white" href="{{link}}?answer=oui">Oui</a></td><td><a style="color:white; border-color: white" href="{{link2}}?answer=non">Non</a></td></tr></table></div></td></tr><tr><td><br/>\r\n <br/>\r\n <div class="br">\r\n \u00C0 l\u2019issue de votre \u00E9valuation, et ce, quel que soit\r\n le r\u00E9sultat, nous reprendrons contact avec vous.\r\n  <br/>\r\n  <br/>\r\n Cordialement, \r\n  <br/>\r\n ' +
        this.$store.state.user.firstName +
        " " +
        this.$store.state.user.lastName +
        '\r\n </div>\r\n <br />\r\n <br />\r\n</td>\r\n</tr>\r\n</table>\r\n</td>\r\n</tr>\r\n\r\n<!-- END MAIN CONTENT AREA -->\r\n                </table>\r\n                <!-- END CENTERED WHITE CONTAINER -->\r\n\r\n                <!-- START FOOTER -->\r\n                <div class="footer">\r\n<table\r\n        role="presentation"\r\n        border="0"\r\n        cellpadding="0"\r\n        cellspacing="0"\r\n>\r\n    <tr>\r\n        <td class="content-block">\r\nViqtor\u00AE est une plateforme con\u00E7ue pour piloter la conformit\u00E9\r\nde votre entreprise au RGPD.<br />\r\nCe premier module gratuit vous permet de commencer votre\r\nmise en conformit\u00E9 en \u00E9valuant<br />\r\nvos sous-traitants pour vous pr\u00E9munir des risques business\r\net financiers\r\n        </td>\r\n    </tr>\r\n    <tr>\r\n        <td class="mb mt">\r\n\r\n\r\n        </td>\r\n        <td></td>\r\n    </tr>\r\n    <tr>\r\n        <td class="purplefooter">\u00A9 ' +
        new Date().getFullYear() +
        ', viqtor.</td>\r\n    </tr>\r\n    <tr>\r\n        <td class="mt">\r\nConfidentialit\u00E9 I Conditions d\u2019utilisation\r\n<p class="br" style="text-align: center">\r\nViqtor \u00AE/ DBWO SAS - 70 rue de Ponthieu - 75008 Paris -\r\nFrance\r\n</p>\r\n        </td>\r\n    </tr>\r\n</table>\r\n                </div>\r\n                <!-- END FOOTER -->\r\n            </div>\r\n</td>\r\n<td>&nbsp;</td>\r\n    </tr>\r\n</table>',
      subcontractor: null,
    };
  },
  props: ["type"],
  afterRouteEnter(to, from, next) {
    console.log(from, to);
    if (from.name === "SubContractor.PendingSubContractors.verifier.preview") {
      this.IsimportMany = true;
    } else if (from.name === "SubContractor.SubContractorImport") {
      this.IsimportMany = true;
    } else {
      if (!this.subcontractor) {
        this.$router.go(-1);
      }
    }
    next();
  },
  async mounted() {
    if (this.$route.params.type === "many") {
      this.IsimportMany = true;
    }
    const referrer = this.referer.title;
    this.text = this.text.replaceAll("Viqtor", referrer);
    this.text = this.text.replaceAll("viqtor.", referrer);
    this.text = this.text.replaceAll("https://i.ibb.co/7ynkVts/viqtor-noir.png", this.referer.logoEmail);
    console.log("🚀 ~ file: SubContractorInvitation.vue:117 ~ mounted ~ text:", this.text)
    this.text = this.$store.getters.getAuthUser.workInCompany.logo ?
    this.text.replaceAll(`https://i.ibb.co/7ynkVts/viqtor-noir2.png`,`${serverConfig.appUrl}${this.$store.getters.getAuthUser.workInCompany.logo }`):
    this.text.replaceAll(`https://i.ibb.co/7ynkVts/viqtor-noir2.png"`,`" style="display:none;"`)
    ;

    this.subcontractor = await this.$store.getters.getOneSubcontractor;
  },

  components: {
    Breadcrumb,
    PrimaryButtonComponent,
  },

  methods: {
    test_invitation() {
      this.isSendingForm = true;
      const data = { emailTemplate: this.text };
      sendTestInvitation(data)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          if (_.isArray(response.data.message)) {
            response.data.message.forEach((err) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Alert",
                text: err,
              });
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
    next_btn() {
      if (this.IsimportMany) {
        this.$store.dispatch("setEmailTemplate", this.text).then(() => {
          this.$router.push({
            name: "SubContractorInvitationVerification",
          });
        });
      } else {
        let params = {
          gender: this.subcontractor.gender,
          firstName: this.subcontractor.firstName,
          lastName: this.subcontractor.lastName,
          email: this.subcontractor.email,
          socialReason: this.subcontractor.socialReason,
          sirenOrRna: this.subcontractor.sirenOrRna,
          emailTemplate: this.text,
        };
        ApiService.post("subcontractor/invite-one", params)
          .then((response) => {
            this.$router.push({ name: "SubContractorInvitationConfirmation" });
            console.log(response);
          })
          .catch(({ response }) => {
            if (_.isArray(response.data.message)) {
              response.data.message.forEach((err) => {
                this.$notify({
                  group: "foo",
                  type: "error",
                  title: "Alert",
                  text: err,
                });
              });
            } else {
              this.$notify({
                  group: "foo",
                  type: "error",
                  title: "Alert",
                  text: response.data.message,
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
.v-application ol,
.v-application ul {
  padding-left: 0 !important;
}

.mail-preview {
  border: 1px solid #e5e7eb;

  p {
    font-size: 14px;
  }

  .quiz-link {
    color: var(--cta-bg-color) #6c5dd3 !important;
    margin: 16px auto;
    text-align: center;
    display: block;
    font-weight: bold;
    text-decoration: underline;
  }

  footer {
    display: none !important;
  }
}

.edit-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: 150px !important;
  margin: 0 12px;
  background: #fff !important;
  color: #000 !important;
  border: 2px solid #000 !important;
}

.test-send-btn {
  height: 45px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  width: 250px !important;
  margin: 0 12px;
  background: var(--cta-bg-color) !important;
  color: #fff !important;
}
</style>
