<template>
  <div>
    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div>
        <EntrepriseHeaderBlockTextComponent
          title="Mes administrateurs"
          description=""
        />
      </div>

      <a style="width: fit-content" class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="$router.push({ name: 'Company.Profile' })">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
        </svg>
        <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
      </a>

      <div class="tw-mt-4" style="background: #FAFAFA;">
        <v-simple-table height="600px" class="tw-mt-5 tw-px-10 tw-py-4 tw-rounded-2xl" style="background: #FAFAFA;">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left tw-text-sm tw-font-medium tw-tracking-wide" style="width: 20%;">
                  Prénom
                </th>
                <th class="text-left tw-text-sm tw-font-medium tw-tracking-wide" style="width: 20%;">
                  Nom
                </th>
                <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide" style="width: 35%;">
                  Email
                </th>
                <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide">
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="tw-pt-4">
              <tr v-for="admin in admins" :key="admin.id">
                <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-medium tw-tracking-wide">{{ admin.firstName }}</td>
                <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-left">{{ admin.lastName }}</td>
                <td style="border: none; margin: .5rem 0;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-center">{{ admin.email }}</td>
                <td style="border: none; margin: .5rem 0;" class="tw-flex tw-items-center tw-justify-center">
                  <div class="tw-h-full tw-flex tw-justify-center tw-items-center tooltip" style="width: fit-content;">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                    </svg>
                    <div class="tooltiptext">
                      <ul>
                        <li class="tw-cursor-pointer" @click="updateAdminHandler(admin.id)">Modifier</li>
                        <li class="tw-cursor-pointer" @click="removeAdminHandler(admin.id)">Supprimer</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="tw-flex tw-items-baseline tw-justify-center tw-space-x-8 tw-pb-3 tw-text-sm">
          <pagination-component
            :length="pagesCount"
            :page="selectedPage"
            @changePage="changePageHandler"
          />
        </div>
      </div>
    </template>

    <AdminModifyPopup 
      v-if="canShowModifyPopup"
      :is-visible="canShowModifyPopup"
      :admin="selectedAdmin"
      @close="closeModifyPopupHandler"
    />
    <AdminConfirmDeletePopup
      v-if="canShowConfirmDeletePopup"
      :admin="selectedAdmin"
      :is-visible="canShowConfirmDeletePopup"
      @close="closeConfirmDeletePopupHandler"
    />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import AdminModifyPopup from "@/features/company/components/Profile/AdminModifyPopup";
import AdminConfirmDeletePopup from "@/features/company/components/Profile/AdminConfirmDeletePopup";
import paginationComponent from '@/components/common/PaginationComponent.vue';
import { getRelatedAdmins } from '../../../services/appApi';

export default {
  name: "ProfileAdministratorsPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    paginationComponent,
    AdminModifyPopup,
    AdminConfirmDeletePopup,
  },
  data() {
    return {
      loading: true,
      admins: [],
      selectedAdmin: {},
      selectedPage: 1,
      limit: 10,
      pagesCount: 1,
      canShowModifyPopup: false,
      canShowConfirmDeletePopup: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  async mounted() {
    await this.getRelatedAdmins();
  },
  methods: {
    async getRelatedAdmins() {
      const options = { page: this.selectedPage, limit: this.limit };
      try {
        const { data: { items, meta } } = await getRelatedAdmins(options);
        this.admins = items;
        this.pagesCount = meta.totalPages;
        this.loading = false;
      } 
      catch (error) {
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors du chargement des administrateurs",
          type: "error",
        });
      }
    },

    async changePageHandler(page) {
      if (page == this.selectedPage) return;
      this.selectedPage = page;
      await this.getRelatedAdmins();
    },

    async closeModifyPopupHandler() {
      this.canShowModifyPopup = false
      await this.getRelatedAdmins();
    },

    async closeConfirmDeletePopupHandler() {
      this.canShowConfirmDeletePopup = false
      await this.getRelatedAdmins();
    },

    updateAdminHandler(id) {
      this.selectedAdmin = this.admins.find(admin => admin.id == id);
      this.canShowModifyPopup = true;
    },
    
    removeAdminHandler(id) {
      this.selectedAdmin = this.admins.find(admin => admin.id == id);
      this.canShowConfirmDeletePopup = true;
    },
  },
}
</script>

<style>
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  border: 1px solid #14477E;
  position: absolute;
  z-index: 1000;
  top: -0.75rem;
  right: -6.75rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext li {
  padding: 0.5rem 1rem;
}

.tooltip .tooltiptext li:hover {
  background-color: rgba(206, 206, 206, 0.39);
}
</style>