<template>
  <div class="tw-mt-10">
    <EntrepriseHeaderBlockTextComponent
      title="Mon entreprise"
      description=""
    />
    <EntrepriseFormComponent class="tw-pt-4" title="Informations sur l’entreprise" />
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EntrepriseFormComponent from "../../components/EntrepriseFormComponent";
export default {
  name: "MonEntrepriseQuestionnairePage",
  computed: {},
  components: {
    EntrepriseHeaderBlockTextComponent,
    EntrepriseFormComponent,
  },
  data() {
    return {
      openMenu: false,
    };
  },
  methods: {},
};
</script>
<style scoped></style>
