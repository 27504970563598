<template>
  <v-dialog
      v-if="handelPopup"
      v-model="handelPopup"
      @click:outside="closePopup"
      width="400"
      transition="dialog-bottom-transition"
    >
    <img class="img-close" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/>
    <div id="deleteDialog">
      <img src="@/assets/images/icons/info.svg" alt="logo" />
      <p>Attention, si vous supprimez cette entreprise, elle ne pourra plus voir les résultats de votre questionnaire !</p>
      <v-btn
          elevation="1"
          text
          rounded
          large
          :loading="isSendingForm"
          @click="supprimerPartageContact"
          class="tw-text-white"
        >
          Confirmer
        </v-btn>
      </div>
    </v-dialog>
</template>
<script>
import {HistoriqueDeleteContactApi} from '../../services/appApi';
export default {
  name: "ConfirmeDeletePopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: String,
      default: "",
    },
  },
  computed: {
    handelPopup(){
      return this.openPopup;
    }
  },
  data() {
    return {
      isSendingForm: false
    };
  },
  methods: {
    supprimerPartageContact() {
      this.isSendingForm = true;
      HistoriqueDeleteContactApi(this.item.clientId)
          .then((res) => {
            this.$snackbar.showAlertMessage({
              message: res.data.message,
              type: "success",
            });
            this.$emit('fetchData');
            this.openPopup = false;
          })
          .catch(({ response }) => {
            this.$snackbar.showAlertMessage({
              message: response.data.message,
              type: "error",
            });
          })
          .finally(()=>{
          this.isSendingForm = false;
          this.closePopup();
          });
    },
    closePopup(){
      this.$emit('close');
      this.openPopup = false;
    }
  },
};
</script>
<style lang="scss" scoped>
  #deleteDialog{
    position: relative;
    img {
      height: 40px;
      width: 40px;
      margin: 20px auto 20px;
    }
    .img-close {
      height: 10px;
      width: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
</style>
