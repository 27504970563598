<template>
  <div class="container !tw-py-14" id="setup-account-expert-page">
    <TitleComponent text="Sélectionnez votre mode de paiement !" />
    <SignupSecondaryTextComponent class="public-text-register tw-mt-12 tw-w-1/2">
      Vous allez rejoindre le club Expert et bénéficirez de tarifs préférentiels ainsi que la possibilité de personnaliser de la plateforme avec votre image de marque. 
    </SignupSecondaryTextComponent>

    <div style="margin-top: 1rem; font-weight: 900; color: var(--cta-bg-color);">Notre tarif unique :</div>
    <TextFieldComponent disabled class="field-readonly" value="100 € HT" style="width: 26rem;"/>

    <div style="margin-top: 2rem; font-weight: 900; color: var(--cta-bg-color);">Sélectionnez un mode de paiement :</div>
    <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center">
      <PaiementModeItem v-for="item in paiementTypes" :key="item.id" @click.native="choseOneMode(item.value)"
        :title="item.title" :img="item.img" :item="item.value" :selected-item="selectedPaiement" />
    </div>

    <div 
      v-if="isSelectingPaiment" 
      class="tw-mt-4 tw-w-8 tw-h-8 tw-border-2 tw-border-dashed tw-rounded-full tw-animate-spin tw-border-t-transparent"
      style="border-color: var(--cta-bg-color) !important;"
    ></div>

    <div class="md:tw-w-9/12 md:tw-mt-6">
      <StripeElementPayment
        ref="stripeElementPayment"
        :key="`${selectedPaiement}-element`"
        v-if="canShowPaymentForm && !isSelectingPaiment"
        :pk="publicKey"
        :elementsOptions="elementsOptions"
        :confirm-params="confirmParams"
        @error="handleError"
      />
    </div>

    <div v-if="canShowPaymentForm && !isSelectingPaiment && canShowPaymentFormSubmit" class="tw-mt-6">
      <PrimaryButtonComponent :loading="isSendingForm" @click.prevent="paiementHandler">Payer</PrimaryButtonComponent>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PaiementModeItem from "@/features/auth/components/PaiementModeItem";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import { getStripePublicKey, getStripePaymentIntent } from "@/features/company/services/appApi";

export default {
  name: "SetupAccountExpertPage",
  components: {
    TitleComponent,
    SignupSecondaryTextComponent,
    TextFieldComponent,
    PaiementModeItem,
    StripeElementPayment,
    PrimaryButtonComponent
  },
  data() {
    return {
      canShowPaymentForm: false,
      canShowPaymentFormSubmit: false,
      isSendingForm: false,
      isSelectingPaiment: false,
      selectedPaiement: "",
      paiementTypes: [
        {
          id: 1,
          title: 'Cartes de crédit et de débit',
          img: 'visa.svg',
          value: 'card'
        },
        {
          id: 2,
          title: 'SEPA Stripe',
          img: 'sepa_debit.png',
          value: 'sepa_debit'
        },
      ],
      publicKey: null,
      paymentIntent: null,
      elementsOptions: {
        appearance: { theme: "stripe" },
        clientSecret: "",
      },
      confirmParams: {
        return_url: ``,
      },
    }
  },
  mounted() {
    this.loadStripePublicKey();
  },
  methods: {
    async choseOneMode(item) {
      this.isSelectingPaiment = true;
      await this.loadStripePaymentIntent(item);
    },
    async loadStripePaymentIntent(item) {
      const { data: { client_secret } } = await getStripePaymentIntent(item);
      this.elementsOptions.clientSecret = client_secret;
      
      setTimeout(() => { 
        this.canShowPaymentForm = true;
        this.selectedPaiement = item;
        this.isSelectingPaiment = false;
      }, 1000);

      setTimeout(() => { 
        this.canShowPaymentFormSubmit = true;
      }, 2000);
    },
    async loadStripePublicKey() {
      const { data } = await getStripePublicKey();
      this.publicKey = data;
      this.confirmParams.return_url = window.location.origin + this.$router.resolve({ name: 'Company.Expert.DashboardPage' }).href;
    },
    async paiementHandler() {
      this.isSendingForm = true;
      this.$refs.stripeElementPayment.submit();
    },
    handleError() {
      this.isSendingForm = false;
    },
  },
}
</script>

<style>
#setup-account-expert-page .field-readonly .v-input--is-dirty.theme--light.v-input input {
  border: none !important;
  box-shadow: none !important;
  background-color: #F4F4F4 !important;
}
#setup-account-expert-page .v-input {
  font-size: 20px !important;
}
</style>