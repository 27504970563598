<template>
  <div>
    <v-text-field
        solo
        dense
        rounded
        v-bind="$attrs"
        elevation="0"
        v-on="$listeners"
        hide-details
        flat
        @click:append="toggleRightIcon"
        class="combobox-collaborator"
    ></v-text-field>
  </div>

</template>

<script>
export default {
  name: "SearchTextFieldComponent",
  props: {
    containerStyle: {
      type: String,
      default: "",
    },
    textRules: {
      type: String,
      default: "",
    },
    toggleRightIcon: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
