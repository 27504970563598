<template>
  <div id="collaborateurs-add">
    <div
      class="tw-flex tw-flex-col tw-align-start"
    >
      <EntrepriseHeaderBlockTextComponent
        :title="title1"
      />
      <EntrepriseHeaderBlockTextComponent
          :title="title2"
      />
    </div>
    <slot />
    <div>
      <StepsComponent class="tw-mt-12 tw-w-10/12 tw-mx-auto" :step="3" />
    </div>
    <div class="tw-mt-12  tw-flex tw-flex-row tw-justify-between">
      <p class="tw-text-xs space-between-paragraph">
        {{stepOneText}}
      </p>
      <p class="tw-text-xs space-between-paragraph">
        {{stepTwoText}}
      </p>
      <p class="tw-text-xs text-align-center">
        {{stepThreeText}}
      </p>
    </div>
    <div class="tw-mt-24 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-6">
      <span class="tw-text-xs dynamic-text tw-cursor-pointer tw-select-none" @click="submit('link')">
        {{ linkText }}
      </span>
      <PrimaryButtonComponent
          :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important`
          }"
          type="submit"
          @click="submit('button')">
        C'est parti !
      </PrimaryButtonComponent>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import StepsComponent from "@/features/auth/components/StepsComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "CollaboratorAddGuideComponent",
  components: {
    PrimaryButtonComponent,
    StepsComponent,
    EntrepriseHeaderBlockTextComponent,
  },
  props: {
    title1: {
      type: String,
      default: ''
    },
    title2: {
      type: String,
      default: ''
    },
    buttonRoute: {
      type: String,
      default: ''
    },
    linkRoute: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: 'Ça commence à se démêler un peu, non ?'
    },
    stepOneText: {
      type: String,
      default: ''
    },
    stepTwoText: {
      type: String,
      default: ''
    },
    stepThreeText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {

    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    }
  },
  methods: {
    submit(type) {
      if (type === 'link') {
        this.$router.push({ name: this.linkRoute });
      }
      else {
        this.$router.push({ name: this.buttonRoute });
      }
    }
  },
};
</script>


<style lang="scss" scoped>
.space-between-paragraph{
  margin-right: 6rem;
  @media screen and (max-width: 768px) {
    margin-right: 2rem;
    text-align: center;
  }
}
.text-align-center{
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
</style>