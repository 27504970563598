<template>
  <div id="paiement-mode-container" :class="{ 'active': selectedItem === item }">
    <span class="paiement-mode-title">
      {{ title }}
    </span>
    <img :src="getImage(img)" class="paiement-mode-img" alt="IMAGE" height="44" width="auto" />
  </div>
</template>

<script>
export default {
  name: "PaiementModeItem",
  props: {
    title: String,
    img: String,
    item: String,
    selectedItem: String
  },
  methods: {
    getImage(img) {
      return require('@/assets/images/pricing/' + img);
    }
  }
}
</script>

<style scoped>
.paiement-mode-img {
  height: 45px !important;
}
</style>