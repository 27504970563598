<template>
  <v-app class="tw-pt-0 tw-mt-0" :id="activeRoute">
    <Header class="public-header" />
    <router-view></router-view>
    <Footer v-if="checkLanding" class="tw-w-full"/>
    <FooterLanding v-else class="tw-w-full"/>
  </v-app>
</template>

<script>
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import FooterLanding from "./components/layout/landing/Footer";

export default {
  name: "App",
   computed: {
    checkLanding() {
      return this.$route.name !== "landing-public";
    },
    
  },
  data() {
    return {
      activeRoute: this.$route.name,
    }
  },
  watch: {
    '$route' () {
      this.activeRoute = this.$route.name
    }
  },
  components: {
    Footer,
    FooterLanding,
    Header,
  },

};
</script>

<style type="scss">
@import "assets/styles/auth/public.scss";
</style>
