<template>
  <div id="collaborateur">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "MonEntrepriseCollaborateursPage",
  computed: {},

};
</script>
