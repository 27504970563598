<template>
  <div>
    <v-radio-group 
      class="auth-v-label" 
      row 
      v-bind="$attrs" 
      v-on="$listeners" 
      v-model="answerId"
      @change="changeHandler"
    >
      <v-radio 
        v-for="answer in answers" 
        :key="answer.id" 
        :label="answer.label"
        :color="referer.theme.ctaBgColor" 
        :value="answer.id"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioButtonQuestionnaireComponent",
  props: {
    answers: {
      type: Array,
      default: () => [],
    },
    selectedAnswerId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      answerId: 0,
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    }
  },
  mounted() {
    this.answerId = this.selectedAnswerId;
  },
  watch: {
    selectedAnswerId: {
      immediate: true,
      handler(value) {
        this.answerId = value;
      }
    }
  },
  methods: {
    changeHandler() {
      this.$emit("answered", this.answerId);
    }
  }
}
</script>