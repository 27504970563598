<template>
  <div class="text-center">
    <v-dialog
      v-if="$parent.videoModal"
      content-class="video-modal"
      max-width="1000"
      v-bind="$attrs"
      v-on="$listeners"
      @click:outside="clickOutsideDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mx-auto mt-0 mb-16 tw-relative tw-z-10 tw-hidden lg:tw-block"
          :style="{
            'background-color': `${theme.ctaBgColor} !important`,
            color: `${theme.ctaTextColor} !important`,
            outline: 'none !important',
            border: 'none !important'
          }"
          >Voir la vidéo
          <img width="10%" src="@/assets/images/icons/play.svg">
        </v-btn>

        <v-btn
            v-bind="attrs"
            class="lg:tw-hidden tw-block"
            v-on="on"
            :style="{
             marginTop: '260px',
             marginLeft: '30px',
            'background-color': `${theme.ctaBgColor} !important`,
            color: `${theme.ctaTextColor} !important`,
            outline: 'none !important',
            border: 'none !important'
          }"
        >Voir la vidéo
          <img width="10%" src="@/assets/images/icons/play.svg">
        </v-btn>
      </template>

      <video id="video-landing" controls autoplay src="@/assets/videos/VIQTOR_V01.mp4"></video>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "VideoModal",
  computed: {
    theme() {
      return this.$store.state.referrer.theme;
    },
  },
  methods: {
    clickOutsideDialog(){
      let video = document.getElementById('video-landing');
      video.pause();
    }
  },
};
</script>

<style lang="scss">
.video-modal {
  padding: 0 !important;
  border-radius: 0 !important;
}
</style>
