<template>
  <v-dialog
    v-if="openPopup"
    v-model="openPopup"
    width="60%"
    transition="dialog-bottom-transition"
    persistent
  >
    <div style="height: 600px">
      <div class="tw-flex tw-justify-end">
        <div class="tw-rounded-full tw-p-2 hover:tw-bg-gray-300">
          <svg
            @click="$emit('close')"
            class="tw-cursor-pointer hover:tw-text-red-600"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.3242 0.5L0.824219 15" stroke="black" />
            <path d="M0.824218 0.499999L15.3242 15" stroke="black" />
          </svg>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-justify-between">
        <div class="tw-space-y-8">
          <h3 class="tw-text-center tw-text-black tw-text-2xl tw-font-medium">
            Attribuer
          </h3>

          <ul class="tw-flex tw-justify-center tw-items-center tw-border-b">
            <li @click="openTab = 1" :class="{ '-tw-mb-px': openTab === 1 }">
              <a
                :class="openTab === 1 ? activeClasses : inactiveClasses"
                class="tw-inline-block tw-text-center tw-px-4 tw-py-2 tw-font-semibold tw-bg-white tw-w-40"
              >
                Collaborateur
              </a>
            </li>
            <li @click="openTab = 2" :class="{ '-tw-mb-px': openTab === 2 }">
              <a
                :class="openTab === 2 ? activeClasses : inactiveClasses"
                class="tw-inline-block tw-text-center tw-px-4 tw-py-2 tw-font-semibold tw-bg-white tw-w-40"
              >
                Équipe
              </a>
            </li>
          </ul>

          <div v-show="openTab === 1">
            <div class="tw-space-y-8">
              <div
                class="tw-flex tw-items-center tw-justify-center tw-px-4 tw-sm:px-6 tw-lg:px-8"
              >
                <div class="tw-relative tw-bg-gray-200 tw-rounded-full">
                  <input
                    type="text"
                    class="tw-rounded-full tw-text-gray-400 tw-bg-gray-200 tw-h-10 tw-w-80 tw-pl-5 tw-pr-10 tw-z-40 tw-focus:shadow tw-focus:outline-none"
                    placeholder="Rechercher..."
                    v-model="keyword"
                  />
                  <div class="tw-absolute tw-right-4 tw-top-3">
                    <svg
                      class="tw-h-4 tw-w-4 tw-fill-current tw-text-gray-600"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 56.966 56.966"
                      style="enable-background: new 0 0 56.966 56.966"
                      xml:space="preserve"
                      width="512px"
                      height="512px"
                    >
                      <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
                <div class="tw-space-y-6 tw-py-2 tw-max-h-56 tw-overflow-y-auto tw-w-[80%] tw-pr-4">
                  <collaborator-item
                    v-for="collaborator in filteredCollaborators"
                    :key="collaborator.id"
                    :collaborator="collaborator"
                    @selected="selectCollaboratorsHandler"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-show="openTab === 2">
            <div class="tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-20">
              <div class="tw-space-y-6 tw-py-2 tw-max-h-60 tw-overflow-y-auto tw-w-[40%] tw-pr-4">
                <team-item
                  v-for="team in allExpertTeams"
                  :key="team.id"
                  :team="team"
                  @selected="selectTeamsHandler"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="tw-mt-12 tw-flex tw-justify-center tw-items-center tw-space-x-8"
        >
          <span
            class="tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-12 tw-cursor-pointer"
            :style="{
              border: `1px solid ${referrer.theme.ctaBgColor}`,
              color: `${referrer.theme.ctaBgColor}`,
            }"
            @click="$emit('close')"
            >Fermer</span
          >
          <v-btn
            elevation="0"
            text
            rounded
            large
            class="tw-text-white tw-text-xs tw-font-semibold tw-leading-[21.03px] tw-rounded-full tw-py-3 tw-px-10 tw-cursor-pointer"
            :style="{
              backgroundColor: `${referrer.theme.ctaBgColor}`,
            }"
            :loading="isLoading"
            @click="syncToCompany"
          >
            <span class="tw-text-xs tw-font-semibold tw-tracking-normal tw-px-4"
              >Confirmer</span
            >
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {
  getTeamMembersWithoutPagination,
  getTeamsWithoutPagination,
  syncCollaboratorsToCompany,
  syncTeamsToCompany,
} from "@/features/company/services/appApi";
import CollaboratorItem from "./CollaboratorItem.vue";
import { mapState } from "vuex";
import TeamItem from "./TeamItem.vue";
import _ from "lodash";

export default {
  components: {
    CollaboratorItem,
    TeamItem,
  },
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      keyword: "",
      allCollaborators: [],
      allExpertTeams: [],
      filteredCollaborators: [],
      attachedCollaboratorsIds: [],
      attachedTeamsIds: [],
      openTab: 1,
      inactiveClasses: "dynamic-bg tw-rounded-t tw-text-white",

      activeClasses:
        "tw-border-l tw-border-t tw-border-r dynamic-text tw-rounded-t",
    };
  },
  computed: {
    ...mapState(["referrer", "user"]),
    handelPopup() {
      return this.openPopup;
    },
  },
  mounted() {
    this.getTeamMembers();
    this.getTeams();
  },
  watch: {
    selectedCompany: {
      handler() {
        this.attachedCollaboratorsIds = this.selectedCompany.expertCollaborators.map(
          (item) => item.id
        );

        this.filteredCollaborators = this.filteredCollaborators.map((item) => {
          return {
            ...item,
            isAttachedToCompany: this.attachedCollaboratorsIds.includes(
              item.id
            ),
          };
        });
        this.attachedTeamsIds = this.selectedCompany.expertTeams.map(
          (item) => item.id
        );
        this.allExpertTeams = this.allExpertTeams.map((item) => {
          return {
            ...item,
            isAttachedToCompany: this.attachedTeamsIds.includes(item.id),
          };
        });
      },
      deep: true,
    },
    keyword: {
      handler(value) {
        this.filteredCollaborators = this.allCollaborators.filter((item) => {
          return item.fullName.toLowerCase().includes(value.toLowerCase());
        });
        this.filteredCollaborators = this.filteredCollaborators.map((item) => {
          return {
            ...item,
            isAttachedToCompany: this.attachedCollaboratorsIds.includes(
              item.id
            ),
          };
        });
      },
      immediate: true,
    },
  },
  methods: {
    getTeamMembers() {
      getTeamMembersWithoutPagination()
        .then(({ data }) => {
          this.allCollaborators = data.map((collaborateur) => {
            return {
              id: collaborateur.id,
              fullName: collaborateur.firstName + " " + collaborateur.lastName,
              isAttachedToCompany: false,
              relatedTeams: collaborateur.expertTeams.map(({ team }) => ({
                id: team.id,
                name: team.name,
              })),
            };
          });
          this.filteredCollaborators = this.allCollaborators;
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message:
              "Une erreur est survenue lors de la récupération des collaborateurs",
            type: "error",
          });
        });
    },
    getTeams() {
      getTeamsWithoutPagination()
        .then(({ data }) => {
          this.allExpertTeams = data.map((team) => ({
            id: team.id,
            name: team.name,
            isAttachedToCompany: false,
          }));
        })
        .catch(error => {
          console.log(error)
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la récupération des équipes",
            type: "error",
          });
        })
    },
    selectCollaboratorsHandler(collaboratorId) {
      if (this.attachedCollaboratorsIds.includes(collaboratorId)) {
        this.attachedCollaboratorsIds = this.attachedCollaboratorsIds.filter(
          (item) => item !== collaboratorId
        );
      } else {
        this.attachedCollaboratorsIds.push(collaboratorId);
      }
    },
    selectTeamsHandler(teamId) {
      if (this.attachedTeamsIds.includes(teamId)) {
        this.attachedTeamsIds = this.attachedTeamsIds.filter(
          (item) => item !== teamId
        );
      } else {
        this.attachedTeamsIds.push(teamId);
      }
    },
    syncToCompany() {
      const isCollaboratorsChanged = !_.isEqual(
        this.attachedCollaboratorsIds.sort(),
        this.selectedCompany.expertCollaborators.map((item) => item.id).sort()
      );
      const isTeamsChanged = !_.isEqual(
        this.attachedTeamsIds.sort(),
        this.selectedCompany.expertTeams.map((item) => item.id).sort()
      );

      if (!isCollaboratorsChanged && !isTeamsChanged) {
        this.$snackbar.showAlertMessage({
          message: "Aucune modification n'a été effectuée",
          type: "info",
        });
        this.$emit("close");
      } else {
        if (isCollaboratorsChanged) this.syncCollaboratorsToCompany();
        if (isTeamsChanged) this.syncTeamsToCompany();
      }
    },
    syncCollaboratorsToCompany() {
      this.isLoading = true;
      syncCollaboratorsToCompany({
        companyId: this.selectedCompany.id,
        collaboratorsIds: this.attachedCollaboratorsIds,
      })
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "Les modifications ont été effectuées avec succès",
            type: "success",
          });
          this.$emit("updated");
          this.$emit("close");
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message:
              "Une erreur est survenue lors de la synchronisation des collaborateurs",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    syncTeamsToCompany() {
      this.isLoading = true;
      syncTeamsToCompany({
        companyId: this.selectedCompany.id,
        teamsIds: this.attachedTeamsIds,
      })
        .then(() => {
          this.$snackbar.showAlertMessage({
            message: "Les modifications ont été effectuées avec succès",
            type: "success",
          });
          this.$emit("updated");
          this.$emit("close");
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showAlertMessage({
            message:
              "Une erreur est survenue lors de la synchronisation des équipes",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
