<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-20 tw-min-h-screen">
      <TitleComponent class="public-title-auth tw-mt-4"
        text="Connectez-vous à votre compte de préinscription de vos clients" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
        Pour pré-inscrire vos clients vous devez vous connecter à l’aide de votre ID (6 caractères dont 4 chiffres et 2
        lettres)<br />
        Une fois pré-inscrits, vos clients recevront un email pour finaliser leur inscription.
      </SignupSecondaryTextComponent>
      <div class="form_container">
        <v-form ref="form" @submit="onSubmit" v-model="formValid" class="tw-mt-12">
          <h3 class="form_title">Avec Viqtor, le maître du jeu, c’est vous !</h3>
          <TextFieldComponent append-icon="mdi-account-outline" class="sm:tw-w-full tw-mt-6" :rules="rules.login"
            v-model="form.login" label="Votre ID" />
          <PasswordFieldComponent class="tw-mt-6 sm:tw-w-full " v-model="form.password" :rules="rules.password"
            :error-messages="errors.password" label="Mot de passe" />
          <v-btn tile depressed @click="onPasswordForget" color="white" elevation="0"
            class="tw-tracking-normal tw-mt-4 pa-0 tw-normal-case text-transform-none">
            <span class="public-text-login" :style="{
              color: `${referer.theme.ctaBgColor}`,
            }">Mot de passe oublié ?</span>
          </v-btn>
          <div class="tw-mt-12 center-item">
            <PrimaryButtonComponent :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important`
            }" class="tw-rounded-lg" :loading="isSendingForm"
              :disabled="!formValid || isSendingForm" type="submit">C’est parti !</PrimaryButtonComponent>
          </div>


        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import TextFieldComponent from "@/components/common/TextFieldComponent";
import PasswordFieldComponent from "@/components/common/PasswordFieldComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import { validationRules } from "@/utils/validation-rules";
import { preInscriptionLogin } from "@/features/auth/services/appApi";

export default {
  name: "PreInscriptionLogin",
  created() {
    localStorage.clear();
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      errors: {
        login: "",
        password: "",
      },
      form: {
        login: "",
        password: "",
      },
      rules: {
        login: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "login"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "login"),
        ],
      },
    };
  },
  components: {
    PrimaryButtonComponent,
    PasswordFieldComponent,
    TextFieldComponent,
    TitleComponent,
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.isSendingForm = true;
      // login && password
      preInscriptionLogin(this.form)
        .then((response) => {
          this.$router.push({
            name: "PreInscriptionFirstStep",
            params: {
              token: response.data.data.accessToken
            },
          });
        })
        .catch((err) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: err.response.data.message,
            type: "error",
          });
          if (err.status === 400) {
            this.errors = { ...this.errors, ...err.data };
          }
          this.$refs.form.validate();
        });
    },
    onPasswordForget() {
      this.$router.push({ name: "reset" });
    },
  },
};
</script>

<style type="scss" scoped>
.form_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height, or 161% */
  text-align: center;
  color: #000000;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
