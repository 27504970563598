<template>
  <div>
    <template v-if="loading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>
    <template v-else>
      <div>
        <div>
          <EntrepriseHeaderBlockTextComponent
            title="Historique de connexion"
            description=""
          />
        </div>

        <a style="width: fit-content" class="tw-mt-11 tw-flex tw-items-center tw-space-x-2" @click.prevent="$router.push({ name: 'Company.Profile' })">
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.87575 4.87492H2.8945L5.9445 1.82492C6.18825 1.58117 6.18825 1.18117 5.9445 0.937416C5.88668 0.879476 5.818 0.833509 5.74239 0.802146C5.66678 0.770782 5.58573 0.754639 5.50387 0.754639C5.42202 0.754639 5.34096 0.770782 5.26536 0.802146C5.18975 0.833509 5.12107 0.879476 5.06325 0.937416L0.944496 5.05617C0.886557 5.11399 0.840589 5.18267 0.809226 5.25828C0.777863 5.33388 0.761719 5.41494 0.761719 5.49679C0.761719 5.57865 0.777863 5.6597 0.809226 5.73531C0.840589 5.81091 0.886557 5.8796 0.944496 5.93742L5.06325 10.0562C5.12111 10.114 5.1898 10.1599 5.26541 10.1912C5.34101 10.2226 5.42204 10.2387 5.50387 10.2387C5.5857 10.2387 5.66673 10.2226 5.74234 10.1912C5.81794 10.1599 5.88663 10.114 5.9445 10.0562C6.00236 9.9983 6.04826 9.92961 6.07958 9.85401C6.11089 9.7784 6.12701 9.69737 6.12701 9.61554C6.12701 9.53371 6.11089 9.45268 6.07958 9.37708C6.04826 9.30147 6.00236 9.23278 5.9445 9.17492L2.8945 6.12492H9.87575C10.2195 6.12492 10.5007 5.84367 10.5007 5.49992C10.5007 5.15617 10.2195 4.87492 9.87575 4.87492Z" fill="#25282B"/>
          </svg>
          <span class="tw-border-b tw-border-black tw-leading-none">Retour</span>
        </a>

        <div class="tw-mt-12" style="background: #FAFAFA;">
          <v-simple-table height="600px" class="tw-mt-5 tw-px-10 tw-py-4 tw-rounded-2xl" style="background: #FAFAFA;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left tw-text-sm tw-font-medium tw-tracking-wide">
                    ID
                  </th>
                  <th class="text-left tw-text-sm tw-font-medium tw-tracking-wide">
                    Date
                  </th>
                  <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide" style="width: 20%;">
                    Heure
                  </th>
                  <th class="text-center tw-text-sm tw-font-medium tw-tracking-wide">
                    Email
                  </th>
                </tr>
              </thead>
              <tbody class="tw-pt-4">
                <tr v-for="signInLog in signInLogs" :key="signInLog.id">
                  <td style="border: none; margin: .5rem 0; padding: 1.25rem 16px;" class="tw-text-sm tw-font-medium tw-tracking-wide">{{ signInLog.id }}</td>
                  <td style="border: none; margin: .5rem 0; padding: 1.25rem 16px;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-left">{{ signInLog.date }}</td>
                  <td style="border: none; margin: .5rem 0; padding: 1.25rem 16px;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-center">{{ signInLog.time }}</td>
                  <td style="border: none; margin: .5rem 0; padding: 1.25rem 16px; width: 35%;" class="tw-text-sm tw-font-medium tw-tracking-wide tw-text-center">{{ signInLog.email }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="tw-flex tw-items-baseline tw-justify-center tw-space-x-8 tw-pb-3 tw-text-sm">
            <pagination-component
              :length="pagesCount"
              :page="selectedPage"
              @changePage="changePageHandler"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import paginationComponent from '@/components/common/PaginationComponent.vue';
import { getUserSignInLogs } from '../../../services/appApi';

export default {
  name: "ProfileConnexionHistoryPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
    paginationComponent,
  },
  data() {
    return {
      loading: true,
      selectedPage: 1,
      limit: 10,
      pagesCount: 1,
      signInLogs: [],
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
  },
  async mounted() {
    await this.getSignInLogs();
  },
  methods: {
    async getSignInLogs() {
      try {
        const { data: { items, meta } } = await getUserSignInLogs({ page: this.selectedPage, limit: this.limit });
        this.pagesCount = meta.totalPages;
        this.signInLogs = items.map((item) => {
          return {
            id: `ID${item.id}`,
            date: (new Date(item.createdAt)).toLocaleString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" }),
            time: `${(new Date(item.createdAt)).getHours()}h${(new Date(item.createdAt)).getMinutes()}`,
            email: this.user.email,
          };
        });
        this.loading = false;
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors du chargement de l'historique de connexion",
          type: "error",
        });
      }
    },
    async changePageHandler(page) {
      if (page == this.selectedPage) return;
      this.selectedPage = page;
      await this.getSignInLogs();
    },
  }
}
</script>