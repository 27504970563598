<template>
  <div class="chaptre">
    <div
      class="tw-grid tw-grid-cols-12 tw-gap-4 tw-cursor-pointer"
      @click="$parent.handleChapitre(chaptre.id)"
    >
      <div class="tw-col-span-8">
        {{ index + 1 }}. {{ chaptre.chapter.label }}
      </div>
      <div class="tw-col-span-3">
        <v-progress-linear v-if="Math.abs(chaptreScore) < 50" :value="Math.abs(chaptreScore)" :color="referer.theme.firstStepProgressquiz" height="10" rounded></v-progress-linear>
        <v-progress-linear v-else-if="Math.abs(chaptreScore) >= 50 && Math.abs(chaptreScore) < 100" :value="Math.abs(chaptreScore)" :color="referer.theme.secondStepProgressquiz" height="10" rounded></v-progress-linear>
        <v-progress-linear v-else :value="Math.abs(chaptreScore)" :color="referer.theme.lastStepProgressquiz" height="10" rounded></v-progress-linear>
      </div>
      <div class="tw-col-auto">
        <svg class="tw-float-right tw-cursor-pointer" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666667 0C0.489856 0 0.320286 0.070238 0.195262 0.195262C0.0702379 0.320287 0 0.489856 0 0.666667C0 0.843478 0.0702379 1.01305 0.195262 1.13807C0.320286 1.2631 0.489856 1.33333 0.666667 1.33333H16.6667C16.8435 1.33333 17.013 1.2631 17.1381 1.13807C17.2631 1.01305 17.3333 0.843478 17.3333 0.666667C17.3333 0.489856 17.2631 0.320287 17.1381 0.195262C17.013 0.070238 16.8435 0 16.6667 0H0.666667ZM0 6C0 5.82319 0.0702379 5.65362 0.195262 5.5286C0.320286 5.40357 0.489856 5.33333 0.666667 5.33333H16.6667C16.8435 5.33333 17.013 5.40357 17.1381 5.5286C17.2631 5.65362 17.3333 5.82319 17.3333 6C17.3333 6.17681 17.2631 6.34638 17.1381 6.4714C17.013 6.59643 16.8435 6.66667 16.6667 6.66667H0.666667C0.489856 6.66667 0.320286 6.59643 0.195262 6.4714C0.0702379 6.34638 0 6.17681 0 6ZM0 11.3333C0 11.1565 0.0702379 10.987 0.195262 10.8619C0.320286 10.7369 0.489856 10.6667 0.666667 10.6667H16.6667C16.8435 10.6667 17.013 10.7369 17.1381 10.8619C17.2631 10.987 17.3333 11.1565 17.3333 11.3333C17.3333 11.5101 17.2631 11.6797 17.1381 11.8047C17.013 11.9298 16.8435 12 16.6667 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333Z" :fill="referer.theme.ctaBgColor"/>
        </svg>
      </div>
    </div>
    <template>
      <div
        :key="question.id"
        v-for="(question, i) in chaptre.quizQuestions"
        class="subChaptre tw-grid tw-grid-cols-12 tw-gap-4"
        :class="{ 'victor-grid': chaptre.id == $parent.selectChapitre }"
      >
        <div
          :class="{
            'tw-col-span-10': question.question.questionType == 'radio',
            'tw-col-span-12': question.question.questionType != 'radio',
          }"
        >
          <span>{{ index + 1 }}.{{ i + 1 }}</span>
          <span>{{ question.question.label }}</span>
        </div>
        <div
          class="tw-col-span-2"
          v-if="question.question.questionType == 'radio'"
        >
          <RadioButtonComponent
            :question="question.question.responses"
            hide-details
            :disabled="disabled"
            :readonly="readonly"
            :select="question.responses"
          />
        </div>
        <div class="tw-col-span-12" v-else>
          <SelectBoxComponent
            :question="question.question.responses"
            hide-details
            :disabled="disabled"
            :readonly="readonly"
            :select="question.responses"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import RadioButtonComponent from "@/components/common/RadioButtonReponseComponent";
import SelectBoxComponent from "@/components/common/CheckButtonReponseComponent";

export default {
  name: "ChapterComponent",
  computed: {
    chaptreScore() {
      var filter = 0;
      if (this.chaptre.quizQuestions) {
        filter = this.chaptre.quizQuestions.filter((m) => {
          return m.responses.length > 0;
        });
      }
      return (filter.length / this.chaptre.quizQuestions.length) * 100;
    },
    referer() {
      return this.$store.state.referrer;
    }
  },
  props: {
    chaptre: {
      type: Object,
      default: null,
    },
    index: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RadioButtonComponent,
    SelectBoxComponent,
  },
};
</script>
