<template>
  <div class="tw-relative tw-h-full tw-flex tw-flex-col tw-justify-center">
      <div v-if="show" class="tw-h-full tw-flex tw-flex-col tw-justify-around">
            <div class="tw-mt-6 md:tw-px-80 tw-px-10">
                <div class="tw-mx-6">
                    <img :src="logo" width="140" height="auto" alt="" />
                </div>
            </div>
            <div class="tw-mt-36 md:tw-px-80 tw-px-10 tw-select-none">
                <h2 class="tw-font-extrabold tw-text-xl">{{questions[index].id}}- {{questions[index].content}} ?</h2>
                <small class="tw-text-xs tw-italic tw-text-gray-500">cliquer sur la ou les réponses de votre choix</small>
                <div class="tw-m-6 tw-flex tw-flex-col tw-space-y-4">
                    <div class="tw-flex tw-space-x-4" v-for="answer in questions[index].answers" :key="answer.id">
                        <CheckBoxComponent :switched="data.includes(answer)" @click.native="chooseItem(answer)"/>
                        <span class="tw-select-none tw-w-3/4 tw-cursor-pointer" @click="chooseItem(answer)" :class="{'font-color-primary':data.includes(answer)}">{{answer.content}}</span>
                    </div>
                </div>
            </div>
            <div class="md:tw-px-80 tw-px-10 tw-flex tw-relative tw-justify-between tw-items-center">
                <div class="font-color-primary tw-italic tw-flex tw-justify-end" ><span v-show="questions[index].multiple">Plusieurs réponses possibles</span></div>
                <div class="tw-flex tw-justify-end">
                    <PrimaryButtonComponent
                        :style="{
                            backgroundColor: `${referer.theme.ctaBgColor} !important`,
                            color: `${referer.theme.ctaTextColor} !important` }"
                                class="add-btn tw-h-10"
                                @click="envoyer"
                                :disabled="data.length==0"
                            >
                                valider
                    </PrimaryButtonComponent>
                </div>
            </div>
      </div>
      <div v-else>
        <ElearningQuizAnswers :correct="correct" :questions="questions" :answers="answers" :question="questions[index]" :total="total"/>
      </div>
  </div>
</template>

<script>
import CheckBoxComponent from "@/components/common/CheckBoxComponent"
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import ElearningQuizAnswers from "../Answer/ElearningQuizAnswers.vue"
import {all_questions} from "./mocks/data"
export default {
    name: "ElearningQuizQuestions",
    data(){
        return{
            index:0,
            switched:false,
            show:true,
            correct:false,
            data:[],
            questions:all_questions,
            answers:[],
            total:0
        }
    },
    components:{
        CheckBoxComponent,PrimaryButtonComponent,ElearningQuizAnswers
    },
      computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    referer() {
      return this.$store.state.referrer;
    },
    logo() {
      return this.$store.state.referrer.logo;
    },
  },
    methods:{
        chooseItem(item){
            this.data.includes(item)?this.data.splice(this.data.indexOf(item),1):this.data.push(item)
        },
        envoyer(){

            const wrongAnswer=this.data.filter(el=>!el.is_right)
            const rightAnswers=this.questions[this.index].answers.filter(el=>el.is_right)
                // console.log("wrongAnswer",wrongAnswer);
                // console.log("rightAnswers",rightAnswers);
                // console.log("data",this.data);
            if(wrongAnswer.length==0 && this.data.length == rightAnswers.length )
            {
                this.correct=true
                this.total++
            }
            const answers = {
                question:this.questions[this.index],
                answers:this.data
            }
            this.answers.push(answers)
            this.data=[]

            this.show=false

        }
    }
}
</script>

<style>

</style>
