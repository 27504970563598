<template>
  <div style="width: 90%; margin: 0 auto; letter-spacing: 0.5px">
    <p
      style="
        text-align: center;
        font-family: 'Arial', sans-serif;
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
      "
    >
      Politique générale de protection des données à caractère personnel
    </p>
    <br /><br /><br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      est
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.structureJuridicYellow }}</span>
      qui s’attache à la mise en œuvre et au respect du règlement no 2016/679,
      dit règlement général sur la protection des données (RGPD), adopté par le
      Parlement européen le 14 avril 2016 et dont les dispositions sont
      directement applicables dans l'ensemble des 28 États membres de l'Union
      européenne à compter du 25 mai 2018.<br /><br />
      Le respect de la vie privée et de la protection des données à caractère
      personnel constitue un facteur de confiance, valeur à laquelle tient
      particulièrement
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >, en s’attachant au respect des libertés et droits fondamentaux de
      chacun.<br /><br />
      La présente politique de protection des données à caractère personnel
      témoigne des engagements mis en œuvre par
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      dans le cadre de ses activités quotidiennes pour une utilisation
      responsable des données personnelles.<br /><br />
    </p>
    <p
      style="
        font-family: 'Arial', sans-serif;
        font-size: 16px;
        line-height: 0px;
        font-weight: 400;
      "
    >
      Principes applicables à la protection des données personnelles
    </p>
    <hr />
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      <select-box @change="(e)=>referenceData.menuGraySelectedItem=e" :items="data.menuGray" v-model="referenceData.menuGraySelectedItem" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
        &nbsp;<span v-if="!data.show">{{ referenceData.menuGraySelectedItem }}</span> auprès de ses 
      <select-box :items="data.menuRed" @change="(e)=>referenceData.menuRedSelectedItem=e" v-model="referenceData.menuRedSelectedItem" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
        &nbsp;<span v-if="!data.show">{{ referenceData.menuRedSelectedItem }}</span> des 
      <select-box @change="(e)=>referenceData.menuPurpleSelectedItem=e" v-model="referenceData.menuPurpleSelectedItem" :items="data.menuPurple" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
      &nbsp;<span v-if="!data.show">{{ referenceData.menuGraySelectedItem }}</span> qui amènent à
      recueillir et traiter des données personnelles dans le respect des lois et
      réglementations en vigueur, et notamment de la loi Informatique et
      Libertés du 6 janvier 1978 modifiée et des normes édictées par la CNIL.<br /><br />

      Elle applique dans la conception et la mise en œuvre de ses &nbsp;{{ referenceData.menuPurpleSelectedItem }}&nbsp; les principes définis par la Privacy by Design et mène une
      politique d’information et de sensibilisation de ses équipes au respect
      des principes édictés par le Règlement Général pour la Protection des
      Données.<br /><br />

      &nbsp;1. Finalité déterminée, explicite et légitime du traitement :<br /><br />
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      <select-box @change="(e)=>referenceData.menuActionsSelectedItem=e" v-model="referenceData.menuActionsSelectedItem" :items="data.menuActions" v-if="data.show">
        <option disabled>sélectionnez une valeur</option>
      </select-box>
      &nbsp;<span v-if="!data.show">{{ referenceData.menuActionsSelectedItem }}</span>&nbsp;
      <text-field placeholder="Typologies de l’offre" v-model="referenceData.typologieOffre" v-if="data.show"/>&nbsp;<span v-if="!data.show">{{ referenceData.typologieOffre }}</span>
      <br /><br />

      C’est au titre de sous-traitant du responsable de traitement qu’elle
      applique les dispositions du RGPD sur ses activités de services à ses
      &nbsp;{{ referenceData.menuRedSelectedItem}}&nbsp;<br /><br />

      Par ailleurs, à titre de responsable de traitement,
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      traite manuellement ou avec des solutions logicielles système ou Saas
      (web) des données personnelles et privées lui permettant, dans la cadre de
      son fonctionnement et de l’exercice de ses missions, de se conformer à ses
      obligations contractuelles et légales.<br /><br />

      Les données personnelles sont collectées pour des objectifs précis en lien
      avec les finalités décrites et définis par les besoins du traitement opéré
      à partir d’un cahier des charges validé dans le cadre de notre procédure
      de protection des données personnelles par défaut.<br /><br />

      &nbsp;2. Proportion et pertinence des données collectées :<br /><br />

      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      s’attache à minimiser les données personnelles collectées en ne traitant
      que les données strictement nécessaires à l’objectif poursuivi par la
      collecte. <br /><br />

      &nbsp;3. Durée de conservation limitée des données à caractère personnel
      :<br /><br />

      Les données à caractère personnel sont conservées pendant une durée
      limitée qui n’excède pas la durée nécessaire aux finalités de collecte et
      détruites, sous réserves de disposition légales spécifiques, dans un délai
      maximum d’un mois après que la disparition de leur objet contractuel.<br /><br />

      &nbsp;4. Confidentialité / Sécurité des données : <br /><br />

      Des Politiques de Protection des Systèmes d’Information (PPSI) sont mises
      en œuvre, adaptées à la nature des données traitées et de leur
      traitement.<br /><br />

      Des mesures de sécurité physiques, logiques et organisationnelles
      appropriées sont prévues pour garantir la confidentialité des données, et
      notamment éviter tout accès non autorisé.<br /><br />

      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      exige également de tout sous-traitant qu’il présente des garanties
      appropriées pour assurer la sécurité et la confidentialité des données
      personnelles.<br /><br />

      &nbsp;5. Droits des personnes :<br /><br />

      Tous les moyens nécessaires à garantir l’effectivité des droits des
      personnes sur leurs données personnelles sont mis en œuvre.<br /><br />
    </p>

    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif">
        Les données personnelles sont collectées loyalement ; aucune collecte
        n’est effectuée à l’insu des personnes et sans qu’elles en soient
        informées.
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Les données personnelles collectées sont portées à la connaissance des
        personnes concernées.
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Les données collectées ne sont pas utilisées ultérieurement de manière
        incompatible avec ces finalités.
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Les données personnelles sont tenues exactes et à jour.
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Les délais de conservation des données sont portés à la connaissance des
        personnes, et varient selon la nature des données, la finalité des
        traitements, ou les exigences légales ou réglementaires.
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Si des données à caractère personnel devaient faire l’objet de
        transferts vers des pays situés dans l’Union Européenne ou hors de
        l’Union Européenne, les personnes concernées en seraient précisément
        informées, et des mesures spécifiques seraient prises pour encadrer ces
        transferts.
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Les moyens nécessaires à garantir l’effectivité des droits des personnes
        sur leurs données personnelles sont mis en œuvre, notamment par une
        information claire et complète sur les traitements de données mis en
        œuvre, facilement accessible et compréhensible par tous.
      </li>
    </ul>
    <br />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Toute personne dispose de droits sur les données la concernant, qu’elle
      peut exercer à tout moment et gratuitement, en justifiant de son identité.
      Ainsi, les personnes peuvent accéder à leurs données personnelles, et dans
      certains cas les faire rectifier, supprimer ou s’opposer à leur
      traitement.<br /><br />

      L’accès à ces droits (accès, rectification, suppression, opposition,
      limitation de traitement, portabilité, effacement, droit de ne pas faire
      l’objet d’une décision individuelle automatisée est facilité par les
      fonctionnalités des logiciels utilisés par
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span
      >.<br /><br />

      &nbsp;6. Droits issus de la Loi pour une République numérique.<br /><br />

      Le nouvel article 40-1 de la loi Informatique et libertés permet aux
      personnes de donner des directives relatives à la conservation, à
      l'effacement et à la communication de leurs données après leur décès.<br /><br />

      Une personne peut être désignée pour exécuter ces directives. Celle-ci a
      alors qualité, lorsque la personne est décédée, pour prendre connaissance
      des directives et demander leur mise en œuvre aux responsables de
      traitement concernés.<br /><br />

      En l'absence de directives données de son vivant par la personne, les
      héritiers auront la possibilité d'exercer certains droits, en particulier
      :<br />
    </p>
    <ul style="list-style: '- '; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif">
        Le droit d'accès, s'il est nécessaire pour le règlement de la succession
        du défunt;
      </li>
      <li style="font-family: 'Calibri', sans-serif">
        Le droit d'opposition pour procéder à la clôture des comptes
        utilisateurs du défunt et s'opposer au traitement de leurs données.
      </li>
    </ul>
    <br /><br />
    <p
      style="
        font-family: 'Arial', sans-serif;
        font-size: 16px;
        line-height: 0px;
        font-weight: 400;
      "
    >
      Principes applicables à la protection des données personnelles
    </p>
    <hr />
    <p
      style="
        font-family: 'Calibri', sans-serif;
        font-size: 15px;
        line-height: 20px;
      "
    >
      Cette politique est révisée au moins tous les ans pour prendre en compte
      les évolutions législatives et réglementaires, et tout changement dans
      l’organisation de
      &nbsp;<span
        style="font-family: 'Calibri', sans-serif"
      >{{ data.socialClient }}</span>
      ou dans les offres, produits et services proposés.
    </p>
    <br /><br />
    <p
      style="
        font-family: 'Arial', sans-serif;
        font-size: 16px;
        font-weight: 500;
      "
    >
      La présente Politique de Protection des Données Personnelles est complétée
    </p>

    <ul style="list-style: none; margin-left: 10px">
      <li style="font-family: 'Calibri', sans-serif">&#10003;
        D’une documentation de nos solutions sur leurs finalités explicites
        concernant les personnes, les destinataires des données, leurs durées de
        conservation, et les modalités d’exercice des droits des personnes.
      </li>
      <li style="font-family: 'Calibri', sans-serif">&#10003;
        D’une évaluation complète et régulière de nos sous-traitants accédant
        aux données personnelles dont nous lui aurions confié toute ou partie du
        traitement, quant à la prise en compte de leurs rôles et obligations
        dans le cadre du RPGD.
      </li>
      <li style="font-family: 'Calibri', sans-serif">&#10003;
        De clauses contractuelles adaptées vis à vis de nos sous-traitants dans
        le cadre d’un risque normal ou important généré par un traitement sur la
        protection des données privées.
      </li>
      <li style="font-family: 'Calibri', sans-serif">&#10003;
        De clause spécifiques de confidentialité sur les contrats de travail de
        nos collaborateurs ayant à opérer des données privées.
      </li>
      <li style="font-family: 'Calibri', sans-serif">&#10003;
        D’un règlement portant sur l’utilisation des matériels informatiques.
      </li>
    </ul>
  </div>
</template>

<script>
import selectBox from './components/selectBox.vue';
import TextField from './components/textField.vue';
export default {
  name: "PolitiqueGeneraleDeGestionDesDonnees",
  components: { selectBox, TextField },
  props:{
    data:{
      type:Object,
      default:()=>{}
    },
    oldReference:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      referenceData:{
        menuActionsSelectedItem:'',
        menuGraySelectedItem:'',
        menuRedSelectedItem:'',
        menuPurpleSelectedItem:'',
        typologieOffre:'',
      }
    };
  },
  mounted() {
    if(Object.keys(this.oldReference)){
      this.referenceData = this.oldReference
    }
  },
  methods:{
  },
  watch:{
    referenceData:{
      handler(value){
        this.$store.dispatch('handleReferenceData',value)
      },
      deep:true
    },
  }
};
</script>

<style></style>
