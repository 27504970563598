<template>
  <section class="pdf-content">
    <EntrepriseSecurityBlockTextComponent
        class="tw-mb-12"
        :title="module.user ? `Registre de traitement de ${module.user.workInCompany.socialReason}` : ''"
    />
    <section v-for="register in registerModules" :key="register.id">
      <section class="register-activity-item-header" :style="{ border: `1px solid ${referer.theme.ctaBgColor}` }">
        <span class="register-activity-item-header-title" :style="{ color: referer.theme.ctaBgColor }">{{ register.name }}</span>
      </section>
      <section class="register-activity-item-content">
        <section v-for="(question, index) in register.registerModuleQuestions" :key="question.id">
          <section class="tw-flex tw-flex-col tw-my-4 tw-space-y-1" v-if="question.type === 'text_field' && register.order !== 8">
            <span class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }} </span>
            <span class="register-activity-item-content-question-input tw-w-2/3" :style="{ border: `1px solid ${referer.theme.ctaBgColor}` }"> {{ question.value }} </span>
            <p v-html="question.description"></p>
          </section>
          <section class="tw-flex tw-flex-col tw-my-4 tw-space-y-1" v-if="question.type === 'text_field' && register.order === 8">
            <section class="tw-flex tw-flex-col tw-space-y-4" v-for="(qstValue, qstValueIndex) in JSON.parse(question.value)" :key="`${qstValueIndex}`">
              <span class="register-activity-item-content-question-title"> {{ qstValueIndex + 1 }} • {{ JSON.parse(question.title)[qstValueIndex] ? JSON.parse(question.title)[qstValueIndex] : ` Destinataire ${qstValueIndex + 1}` }} </span>
              <span class="register-activity-item-content-question-input tw-w-2/3" :style="{ border: `1px solid ${referer.theme.ctaBgColor}` }"> {{ qstValue }} </span>
            </section>
            <p v-html="question.description"></p>
          </section>
          <section class="tw-flex tw-flex-col tw-my-2 tw-space-y-3" v-if="question.type === 'select_field'">
            <span v-if="question.title" :class="{'tw-mt-8' : index !== 0}" class="register-activity-item-content-question-title">{{ question.title }} </span>
            <section class="tw-flex tw-flex-row tw-items-center tw-justify-between register-activity-item-content-delect-container">
              <span class="register-activity-item-content-desc">{{ question.description }}</span>
              <section  class="tw-flex tw-flex-row tw-items-center tw-space-x-4">
                <div class="register-activity-item-content-sheet sheet-bleu" :class="{ 'active' : question.value === 'effectif' }"></div>
                <div class="register-activity-item-content-sheet sheet-ciel" :class="{ 'active' : question.value === 'en projet' }"></div>
                <div class="register-activity-item-content-sheet sheet-orange" :class="{ 'active' : question.value === 'sans objet' }"></div>
              </section>
            </section>
          </section>
          <section class="tw-flex tw-flex-col tw-my-4 tw-space-y-1" v-if="question.type === 'multi_text_field' && register.order === 5">

            <section class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-space-x-4">
              <span class="register-activity-item-content-question-title tw-w-2/4"> {{ question.order }} • {{ JSON.parse(question.title)[0] }} </span>
              <span class="register-activity-item-content-question-title tw-w-2/4 tw-text-left"> {{ JSON.parse(question.title)[1] }} </span>
            </section>
            <section class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-space-x-4" v-for="(qstValue, qstValueIndex) in JSON.parse(question.value)" :key="`${qstValueIndex}`">
              <span
                  v-for="(oneAnswer, keyObject, ObjectIndex) in qstValue"
                  :key="`${ObjectIndex}${keyObject}`"
                  class="register-activity-item-content-question-input tw-w-2/4"
                  :style="{ border: `1px solid ${referer.theme.ctaBgColor}`, width: '50%' }"
              > {{ JSON.parse(question.value)[qstValueIndex][`value${ObjectIndex + 1}`] }}</span>
            </section>
            <p v-html="question.description"></p>
          </section>
          <section class="tw-flex tw-flex-col tw-my-4 tw-space-y-1" v-if="question.type === 'multi_text_field' && register.order === 6">
            <span v-if="question.title" :class="{'tw-mt-8' : index !== 0}" class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }} </span>
            <section class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-space-x-4">
              <span class="register-activity-item-content-question-title !tw-mb-0  tw-w-2/4" v-html="JSON.parse(question.description)[0]"></span>
              <span class="register-activity-item-content-question-title !tw-mb-0  tw-w-2/4" v-html="JSON.parse(question.description)[1]"></span>
            </section>
            <section class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-space-x-4">
              <span
                  v-for="(placeholder, index) in JSON.parse(question.placeHolder)"
                  :key="placeholder"
                  class="register-activity-item-content-question-input tw-w-2/5"
                  :style="{ border: `1px solid ${referer.theme.ctaBgColor}`, width: '50%' }"
              >
                {{ JSON.parse(question.value)[index] }}
              </span>
            </section>
          </section>
          <section class="tw-flex tw-flex-col tw-my-4 tw-space-y-1" v-if="question.type === 'multi_text_field' && register.order === 9">
            <span class="register-activity-item-content-question-title"> {{ question.order }} • {{ question.title }} </span>
            <p class="!tw-mb-0" v-html="question.description"></p>

            <section class="tw-flex tw-flex-row tw-items-center tw-space-x-4">
              <section v-if="question.order === 1" style="width: 100%">
                <div
                    class="register-activity-item-content-question-input"
                    :style="{ border: `1px solid ${referer.theme.ctaBgColor}`, width: '30%' }"
                >
                  {{ JSON.parse(question.value)[0] }}
                </div>
              </section>
              <section v-else class="tw-flex tw-flex-row tw-items-center tw-space-x-4" style="width: 100%">
                <div
                    v-for="(placeholder, index) in JSON.parse(question.placeHolder)"
                    :key="placeholder"
                    class="register-activity-item-content-question-input"
                    :style="{ border: `1px solid ${referer.theme.ctaBgColor}`, width: '30%' }"
                >
                  {{ index ===  JSON.parse(question.placeHolder).length -1 ? getSelectValue(JSON.parse(question.value)[index]) : JSON.parse(question.value)[index] }}
                </div>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>

import EntrepriseSecurityBlockTextComponent from "@/components/common/EntrepriseSecurityBlockTextComponent";
export default {
  name: "PdfContent",
  components: {EntrepriseSecurityBlockTextComponent},
  props: {
    registerModules: {
      type: Array,
      default: () => ([])
    },
    module: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      questions: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  methods: {
    getSelectValue(value) {
      switch (value) {
        case '1':
          return 'Je ne sais pas';
        case '2':
          return 'Décision d’adéquation';
        case '3':
          return 'Clauses contractuelles types';
        case '4':
          return 'Règles internes d’entreprise';
        case '5':
          return 'Code de conduite';
        case '6':
          return 'Mécanisme de certification';
        default:
          return '';
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;
  padding: 0 20px 50px 20px;

  .register-activity-item-header {
    margin-top: 15px;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .register-activity-item-header-title {
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }

  .register-activity-item-content {
    display: flex;
    flex-direction: column;

    .register-activity-item-content-question-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }

    .register-activity-item-content-question-input {
      padding: 0 5px 15px 5px;
      margin-top: 10px;
    }

    .register-activity-item-content-delect-container {
      padding-bottom: 10px;
      border-bottom: 1px solid #000000;
    }

    .register-activity-item-content-sheet {
      width: 25px;
      height: 15px;
      cursor: pointer;

      &.sheet-bleu {
        border: 1px solid #14477E;
        color: #14477E;

        &.active {
          background-color: #14477E;
        }
      }

      &.sheet-ciel {
        border: 1px solid #649AD3;
        color: #649AD3;

        &.active {
          background-color: #649AD3;
        }
      }

      &.sheet-orange {
        border: 1px solid #E8973E;
        color: #E8973E;

        &.active {
          background-color: #E8973E;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.register-activity-item-content-square-preview {
  width: 45px;
  height: 15px;

  &.bleu {
     background: #14477E;
     color: #14477E;
   }

  &.ciel {
     background: #649AD3;
     color: #649AD3;
   }

  &.orange {
     background: #E8973E;
     color: #E8973E;
   }
}
</style>
