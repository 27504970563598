<template>
  <div
    class="tw-flex tw-flex-col tw-align-start"
    id="profil-contact"
  >
    <EntrepriseHeaderBlockTextComponent
      title="Contact"
      description="Notre équipe est à votre disposition pour vous accompagner au quotidien et répondre à vos questions."
    />
    <div class="tw-flex tw-mt-8 tw-mb-8">
      <div class="tw-flex-1">
        <EntrepriseHeaderBlockTextComponent title="Formulaire" />
        <div class="form-contact">
          <v-form class="tw-w-full" autocomplete="off">
            <v-text-field
              solo
              label="Nom"
              v-model="form.name"
              append-icon="mdi-account "
            ></v-text-field>
            <v-text-field
              solo
              label="Email"
              v-model="form.email"
              append-icon="mdi-send"
            ></v-text-field>
            <v-text-field
              solo
              v-model="form.subject"
              label="Objet"
              append-icon="mdi-message-outline "
            ></v-text-field>
            <v-textarea
              solo
              v-model="form.message"
              label="Message"
              append-icon="mdi-message-text-outline "
            ></v-textarea>

            <v-btn
              :disabled="disableSubmitButton"
              :class="disableSubmitButton ? 'btn-disabled' : 'btn-valid'"
              elevation="1"
              text
              rounded
              large
              :loading="isSendingForm"
              class="tw-text-white"
              @click="onSubmit"
            >
              Envoyer
              <img src="@/assets/images/icons/arrow.svg" alt="bell" />
            </v-btn>
          </v-form>
        </div>
      </div>
      <div class="tw-border-r tw-p-0 tw-mx-8 tw-w-0.5"></div>
      <div
        class="coordonees-container tw-flex tw-flex-col tw-space-y-4 tw-flex-1"
      >
        <EntrepriseHeaderBlockTextComponent
          class="tw-text-center"
          title="Coordonnées"
        />
        <div class="tw-flex tw-items-center tw-pl-14">
          <svg
            class="tw-mr-10"
            width="20"
            height="26"
            viewBox="0 0 20 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.98093 7.41354C1.61266 4.98797 3.3229 2.80921 5.93549 2.01097C6.39911 1.86922 6.89934 1.90957 7.33425 2.1238C7.76916 2.33803 8.10603 2.71002 8.27622 3.16398L9.11495 5.40059C9.24997 5.7604 9.27438 6.15235 9.18506 6.52613C9.09573 6.89992 8.89675 7.23848 8.61364 7.49837L6.11866 9.78319C5.99568 9.89605 5.90404 10.0388 5.85267 10.1977C5.80131 10.3565 5.79198 10.5259 5.82559 10.6894L5.84872 10.7897L5.9085 11.0403C6.21878 12.2582 6.69155 13.4288 7.31409 14.5206C7.99223 15.6807 8.8338 16.7372 9.81292 17.6576L9.89005 17.727C10.0146 17.8376 10.1657 17.914 10.3286 17.9488C10.4914 17.9836 10.6605 17.9756 10.8194 17.9256L14.0451 16.9095C14.4117 16.7944 14.8043 16.7914 15.1726 16.901C15.5409 17.0106 15.868 17.2278 16.1121 17.5246L17.6391 19.3775C18.2754 20.1487 18.1983 21.2824 17.4675 21.9631C15.4681 23.8276 12.7186 24.2093 10.8059 22.6726C8.46137 20.7814 6.48491 18.4749 4.97529 15.8683C3.45341 13.2637 2.43686 10.3952 1.979 7.41354H1.98093ZM7.84818 10.8167L9.91511 8.91939C10.4817 8.39984 10.88 7.72281 11.059 6.97523C11.238 6.22765 11.1895 5.44363 10.9197 4.72382L10.0829 2.48721C9.74032 1.57376 9.06237 0.825295 8.18717 0.39432C7.31196 -0.036654 6.30536 -0.117702 5.37248 0.167692C2.12747 1.16067 -0.477412 4.07019 0.074028 7.70468C0.45965 10.2421 1.34851 13.4697 3.3094 16.8401C4.9381 19.6507 7.0701 22.1376 9.5989 24.1765C12.4679 26.4806 16.2972 25.694 18.7844 23.3764C20.2614 21.9997 20.4118 19.713 19.1296 18.1551L17.6025 16.3002C17.114 15.7073 16.4595 15.2738 15.723 15.0553C14.9864 14.8368 14.2014 14.8433 13.4686 15.0739L10.7905 15.9165C10.099 15.2036 9.49331 14.4122 8.98576 13.5584C8.49549 12.6953 8.11341 11.7751 7.84818 10.8186V10.8167Z"
              :fill="referer.theme.ctaBgColor"
            />
          </svg>
          <span class="coordonees-text">01 81 80 01 13</span>
        </div>
        <div class="tw-flex tw-items-center tw-pl-14">
          <svg
            class="tw-mr-10"
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L12 12L5 5"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="1"
              y="1"
              width="22"
              height="18"
              rx="2"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 13L5 15"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17 13L19 15"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="coordonees-text">contact@viqtor.eu</span>
        </div>
        <div class="tw-flex tw-items-center tw-pl-14">
          <svg
            class="tw-mr-10"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12.375V22.375H13"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.86 8.624L12 1.375L1 10.375"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23 15.1922C23 17.5582 19 21.3752 19 21.3752C19 21.3752 15 17.5582 15 15.1922C15.0139 14.1522 15.4458 13.1616 16.1983 12.4435C16.9507 11.7255 17.9605 11.3405 19 11.3752C20.0395 11.3405 21.0493 11.7255 21.8017 12.4435C22.5542 13.1616 22.9861 14.1522 23 15.1922V15.1922Z"
              :stroke="referer.theme.ctaBgColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <circle cx="19" cy="15.375" r="1" :fill="referer.theme.ctaBgColor" />
          </svg>
          <span class="coordonees-text"
            >70 rue de Ponthieu 75008<br />PARIS</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { sendContactForm } from "@/features/company/services/appApi";
export default {
  name: "ProfilContactPage",
  components: { EntrepriseHeaderBlockTextComponent },
  computed: {
    disableSubmitButton() {
      if (
        this.form.name === "" ||
        this.form.subject === "" ||
        this.form.email === "" ||
        this.form.message === ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      isSendingForm: false,
      formValid: true,
      form: {
        email: "",
        name: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.isSendingForm = true;
      sendContactForm(this.form)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          this.form = {
            name: "",
            email: "",
            subject: "",
            message: "",
          };
        })
        .catch(({ response }) => {
          response.data.message.forEach((item) => {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Attention",
              text: item,
            });
          });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
  },
};
</script>

<style scoped></style>
