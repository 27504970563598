<template>
  <v-container class="tw-pt-0 tw-mt-0">
  <div class="auth-container tw-mt-20 tw-relative tw-min-h-screen">
    <v-overlay :value="isLoadingData">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="!formSent">
      <TitleComponent class="public-title-auth tw-mt-12" text="Finalisez votre compte" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-1">
        Un dernier effort !
      </SignupSecondaryTextComponent>
      <SignupSecondaryTextComponent class="public-text-register tw-mt-1">
        Conformément à notre
        <v-btn text class="tw-underline btn-link-text" @click="openPolitique">charte de confidentialité</v-btn
        >
        , nous avons besoin de votre consentement pour :
      </SignupSecondaryTextComponent>
    </div>

    <div class="tw-text-center">
      <v-form ref="form" v-if="!formSent">
        <div class="tw-w-8/12 tw-m-auto tw-mt-12">
          <SwitchButtonComponent
              v-model="notifsAccepted"
              @change="onNotifsSwitchChange"
              label="Utiliser les données renseignées pour vous notifier et vous tenir informé de l’activité."
          />
          <SwitchButtonComponent
              v-model="cookiesAccepted"
              @change="onCookiesSwitchChange"
              label="Utiliser des cookies dits de confort qui vous permettront d’accéder plus facilement à votre compte."
          />
          <div class="public-text-register-step3 tw-mt-4">
            Vous déclarez avoir lu et accepté les
            <v-btn text class="tw-underline btn-link-text tw-cursor-pointer"  @click="modalitiesDialog = true">modalités</v-btn> et les
            <v-btn text class="tw-underline btn-link-text tw-cursor-pointer"  @click="cguDialog = true">conditions générales d’utilisation</v-btn>
            de la plateforme.
          </div>
          <div class="tw-flex tw-flex-col tw-items-center tw-gap-5 tw-justify-center tw-mt-12">
            <PrimaryButtonComponent
                :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important`
            }"
            @click="modalitiesDialog = true"
            >Lire les modalités</PrimaryButtonComponent>
            <PrimaryButtonComponent
                :style="{
              backgroundColor: `${referer.theme.ctaBgColor} !important`,
              color: `${referer.theme.ctaTextColor} !important`
            }"
            @click="cguDialog = true"
            >Lire les CGU</PrimaryButtonComponent>
          </div>
          <div class="tw-flex tw-flex-col tw-my-8 tw-items-center">
            <ReadConditionsModalComponent
                type="MDT"
                btn-class="tw-w-64 tw-mb-4"
                btn-title="Lire les modalités"
                title="Lire les modalités"
                :dialog= "modalitiesDialog"
                @closeEvent="closeModalsFromChild"
                @openEvent="openModalsFromChild"
                @acceptConditions="modalitiesWasAccepted = true"
                :conditionsWasAccepted="modalitiesWasAccepted"
            >
              <MentionsLegalesComponent />
            </ReadConditionsModalComponent>
            <ReadConditionsModalComponent
                type="CGU"
                btn-class="tw-w-64"
                btn-title="Lire les CGU"
                title="CGU"
                :dialog= "cguDialog"
                @closeEvent="closeModalsFromChild"
                @openEvent="openModalsFromChild"
                @acceptConditions="cgeWasAccepted = true"
                :conditionsWasAccepted="cgeWasAccepted"
            >
              <CGUComponent />
            </ReadConditionsModalComponent>
          </div>
        </div>
        <div class="tw-flex tw-justify-between tw-mt-14 tw-pb-20">
          <SecondaryButtonComponent @click="onBack">
            <v-icon left dark> mdi-arrow-left </v-icon
            >Précédent</SecondaryButtonComponent
          >
          <PrimaryButtonComponent
              :loading="isSendingForm"
              @click="onSubmit"
              :disabled="!formValid || isSendingForm"
          >Suivant
            <v-icon right dark> mdi-arrow-right </v-icon></PrimaryButtonComponent
          >
        </div>
      </v-form>
      <p v-if="!formSent" class="tw-absolute tw-bottom-0 registration-iml">
        Les informations à caractère personnel recueillies dans le cadre contractuel de
        votre souscription à la plateforme Viqtor sont enregistrées dans un fichier informatisé
        par la société DBWO pour vous permettre l’accès aux services qu’elle propose, assurer
        l’interaction de votre organisation avec ses partenaires ainsi que vous tenir informés
        de l’évolutions de ses prestations.
        Elles seront conservées tout au long de la durée de souscription au service, période durant
        laquelle elles pourront être régulièrement mises à jour à votre demande. Hors demande de
        votre part, elles seront détruites après résiliation de la souscription par votre organisation
        sous un délai de 3 mois après le terme du contrat.
        Ces données sont destinées au personnel administratif de DBWO et conservées sur les serveurs
        de l’entreprise OVH.
        Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit
        d'accès aux données vous concernant et les faire rectifier en contactant : dpo@dbwo.legal
      </p>
    </div>

    <div v-if="formSent">
      <TitleComponent class="tw-mt-12" text="Votre compte est créé !" />
      <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
        Vous pouvez désormais vous connecter à la plateforme viqtor !
      </SignupSecondaryTextComponent>
      <div class="tw-flex tw-justify-center tw-my-24">
          <PrimaryButtonComponent @click="go_to_login">Connexion</PrimaryButtonComponent>
      </div>
    </div>
    <PolitiquePopup @close="closePolitique" :openPopup="openPopupPolitique" />
  </div>
  </v-container>
</template>

<script>
import SecondaryButtonComponent from "@/components/common/SecondaryButtonComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import TitleComponent from "@/components/common/TitleComponent";
import SwitchButtonComponent from "@/components/common/SwitchButtonComponent";
import ReadConditionsModalComponent from "@/features/auth/components/ReadConditionsModalComponent";
import { validationRules } from "@/utils/validation-rules";
import { getDataFromToken } from "../../services/appApi";
import {lastStepSignUpApi} from "@/features/company/services/appApi";
import MentionsLegalesComponent from "@/components/layout/landing/MentionsLegalesComponent";
import CGUComponent from "@/features/company/components/Dashboard/CGUComponent";
import PolitiquePopup from "@/components/layout/landing/PolitiquePopup";

function validateForm() {
  this.formValid =
    this.notifsAccepted &&
    this.cookiesAccepted &&
    this.modalitiesWasAccepted &&
    this.cgeWasAccepted;
}

export default {
  name: "LastStepRegistrationPage",
  components: {
    PolitiquePopup,
    CGUComponent,
    MentionsLegalesComponent,
    ReadConditionsModalComponent,
    SwitchButtonComponent,
    SecondaryButtonComponent,
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
  },
  data() {
    return {
      openPopupPolitique: false,
      formSent: false,
      formValid: false,
      isLoadingData: true,
      isSendingForm: false,
      notifsAccepted: false,
      cookiesAccepted: false,
      modalitiesWasAccepted: false,
      cgeWasAccepted: false,
      modalitiesDialog: false,
      cguDialog: false,
      rules: {
        notifsAccepted: [validationRules.required],
      },
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
      ],
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    openPolitique() {
      this.openPopupPolitique = true;
    },
    closePolitique() {
      this.openPopupPolitique = false;
    },
    onBack() {
      this.$router.push({ name: 'signupSecond' , params: { token: this.$route.params.token }});
    },
    onNotifsSwitchChange: function (value) {
      this.notifsAccepted = value;
    },
    onCookiesSwitchChange: function (value) {
      this.cookiesAccepted = value;
    },
    onSubmit() {
      this.isSendingForm = true;
      lastStepSignUpApi(this.$route.params.token)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          window.scrollTo(0, 0);
        })
        .catch(({ response: { data } }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: data.message || "Erreur: quelque chose s'est mal passé",
            type: "error",
          });
        });
    },

    go_to_login() {
      this.$router.push({ name: 'LoginPage' })
    },

    openModalsFromChild(item) {
      if (item === 'CGU') {
        this.cguDialog = true;
      }
      if (item === 'MDT') {
        this.modalitiesDialog = true;
      }
    },

    closeModalsFromChild() {
      this.cguDialog = false;
      this.modalitiesDialog = false;
    }
  },
  watch: {
    notifsAccepted: validateForm,
    cookiesAccepted: validateForm,
    modalitiesWasAccepted: validateForm,
    cgeWasAccepted: validateForm,
  },
  mounted() {
    getDataFromToken(this.$route.params.token)
      .then(() => {
        this.isLoadingData = false;
      })
      .catch(({ response }) => {
        this.isLoadingData = false;
        if (response.status === 404) {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
          this.$router.replace({name: "TokenExpired"});
        } else {
          this.$snackbar.showAlertMessage({
            message: "Erreur: quelque chose s'est mal passé",
            type: "error",
          });
        }
      });
  },
};
</script>

<style scoped></style>
