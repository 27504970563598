<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <v-row class="auth-container tw-mt-20 tw-min-h-screen">
      <v-col md="6" cols="12">
        <router-link tag="a" :to="{ name: 'LoginPage' }">
          <RetourButtonComponent />
        </router-link>
        <TitleComponent class="public-title-auth tw-mt-4" text="Double" />
        <TitleComponent class="public-title-auth" text="authentification." />
        <LoginSecondaryTextComponent
          class="public-text-login tw-mt-6"
          v-if="phone"
        >
          Nous vous avons envoyé un code au {{ phone.substring(0, 2) }} ** ** **
          {{ phone.substring(phone.length, phone.length - 2) }}.
        </LoginSecondaryTextComponent>
        <v-divider
          class="tw-mt-6"
          style="background-color: #52575c; width: 100%"
        ></v-divider>
        <LoginSecondaryTextComponent class="public-text-login tw-mt-6">
          Veuillez taper le code reçu par SMS dans le champs ci-dessous :
          <button
            :disabled="isSendingForm"
            v-on:click.prevent="resendVerificationCode"
            :style="{ color: referer.theme.ctaBgColor }"
          >
            Renvoyer le code
          </button>
        </LoginSecondaryTextComponent>
        <v-form
          ref="form"
          @submit="onSubmit"
          v-model="formValid"
          class="tw-mt-10"
        >
          <v-row>
            <v-col md="4" cols="6">
              <LoginTextFieldComponent
                class="pa-0 ma-0 tw-h-full"
                :rules="rules.codeSms"
                v-model="form.codeSms"
              />
            </v-col>
            <v-col cols="2">
              <PrimaryButtonComponent
                  :style="{
                    backgroundColor: `${referer.theme.ctaBgColor} !important`,
                    color: `${referer.theme.ctaTextColor} !important`,
                    }"
                    height="40"
                    class="tw-rounded-lg"
                    :loading="isSendingForm"
                    :disabled="!formValid || isSendingForm"
                    type="submit"
              >OK
              </PrimaryButtonComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import RetourButtonComponent from "@/components/common/RetourButtonComponent";
import LoginSecondaryTextComponent from "@/features/auth/components/LoginSecondaryTextComponent";
import LoginTextFieldComponent from "@/components/common/LoginTextFieldComponent";
import { validationRules } from "@/utils/validation-rules";
import { twoFactorValidation } from "@/features/auth/services/appApi";
import { resend2FaVerificationCode } from "../services/appApi";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
  name: "LoginSecondStep",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      errors: {
        codeSms: "",
      },
      form: {
        codeSms: "",
      },
      rules: {
        codeSms: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "codeSms"),
        ],
      },
    };
  },
  props: {
    token: String,
    phone: String,
  },
  components: {
    PrimaryButtonComponent,
    LoginTextFieldComponent,
    LoginSecondaryTextComponent,
    RetourButtonComponent,
    TitleComponent,
  },

  methods: {
    dashboardName(expertType) {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    },
    resendVerificationCode() {
      resend2FaVerificationCode(this.token)
        .then((response) => {
          this.$router.push({ params: { token: response.data.data.secret } });
          this.isSendingForm = false;
          this.token = response.data.data.secret;
        })
        .catch((err) => {
          this.isSendingForm = false;
          this.$snackbar.showAlertMessage({
            message: err.response.data.message,
            type: "error",
          });
        });
    },
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      const data = {
        token: this.form.codeSms,
        secret: this.token,
      };
      twoFactorValidation(data)
        .then((response) => {
          this.$store.dispatch("setToken", response.data.data.accessToken);
          this.isSendingForm = false;
          this.formSent = true;

          if (this.referer.slug === "gpmse" && response.data.data.userType === "bo_gpmse")
            return this.$router.push({ name: "GdprQuizGpmse.quizFollowup" });

          if (
            !response.data.data.planPriceId &&
            !response.data.data.subscribed &&
            !response.data.data.isExpertAccountActive
          )
            this.$router.push({ name: this.referer.title === 'Viqtor Expert' ? 'SetupAccountExpert' : 'SetupAccountCompany' });
          if (response.data.data.planPriceId && response.data.data.subscribed === false)
            this.$router.push({ name: "SetupAccountPricingCompany", params: { planId: response.data.data.planPriceId } });
          if (response.data.data.subscribed === true || response.data.data.isExpertAccountActive === true)
            this.$router.push({ name: this.dashboardName(response.data.data.expertType) });
        })
        .catch(error => {
          this.isSendingForm = false;
          console.log(error)

          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de la validation du code.",
            type: "error",
          });

          if (!!error.response && error.response.status === 400)
            this.errors = { ...this.errors, ...error.response.data };
          
          this.$refs.form.validate();
        });
    },
  },
};
</script>

<style scoped></style>
