<template>
  <div id="invitation">
    <div
      class="tw-flex tw-align-start tw-justify-between"
      id="mes-partages-header"
    >
      <EntrepriseHeaderBlockTextComponent
        title="Questionnaire validé !"
        description="Merci d’avoir répondu au questionnaire !<br>Le résultat de votre évaluation vous sera transmis par email."
      />
    </div>
    <v-btn
      elevation="1"
      text
      rounded
      large
      @click="$router.push({ name: dashboardName })"
      class="tw-text-white"
    >
      Revenir au Dashboard
    </v-btn>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
export default {
  name: "invitationCollaborateursPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
  },
  data() {
    return {
      openMenu: false,
    };
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    user() {
      return this.$store.state.user;
    },
    dashboardName() {
      return (
        ['Viqtor Expert'].includes(this.referer.title) &&
        this.user.expertType !== "company"
      ) 
        ? 'Company.Expert.DashboardPage' 
        : 'Company.DashboardPage';
    }
  },
};
</script>
