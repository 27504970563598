<template>
  <div id="register-detail-page">
    <v-btn class="tw-mt-8 register-detail-retour-btn" color="#25282B" plain :to="{ name: 'Register.PreRegister.Page' }">
      Retour
    </v-btn>
    <div class="tw-flex tw-pb-2 tw-items-end tw-justify-between">
      <EntrepriseHeaderBlockTextComponent class="tw-mt-8"
        :title="module.collaborateur ? `${module.collaborateur.firstName} ${module.collaborateur.lastName}` : ''"
        description="" />

      <v-btn :loading="isDownloadLoading" class="register-detail-download-btn" rounded color="var(--cta-bg-color)" dark
        @click="downloadPdf()">
        Télécharger
      </v-btn>
    </div>
    <EntrepriseSecurityBlockTextComponent class="tw-mb-12"
      :title="module.collaborateur ? `Registre de traitement de ${module.collaborateur.company.socialReason}` : ''" />
    <RegisterActivityItem @handleSelectItem="handleSelectRegisterActivityItem"
      @handlePersistResponse="handlePersistResponse" v-for="item in registerModules" :key="item.id" :register="item"
      :is-preview="false" :user="module.collaborateur" :is-first-answers="false" />
    <vue-html2pdf :show-layout="controlValue.showLayout" :float-layout="controlValue.floatLayout"
      :enable-download="controlValue.enableDownload" :preview-modal="controlValue.previewModal"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight" :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat" :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth" :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions" @progress="onProgress($event)" @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf">
      <pdf-content @domRendered="domRendered()" slot="pdf-content" :register-modules="registerModules"
        :module="module" />
    </vue-html2pdf>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import EntrepriseSecurityBlockTextComponent from "@/components/common/EntrepriseSecurityBlockTextComponent";
import { getRegisterDataByUser } from "@/features/ProcessingRegister/services/appApi";
import RegisterActivityItem from "@/features/ProcessingRegister/components/RegisterActivityItem";
import VueHtml2pdf from 'vue-html2pdf'
import PdfContent from "@/features/ProcessingRegister/components/PdfContent";
export default {
  name: "PreRegisterDetail",
  components: {
    PdfContent,
    RegisterActivityItem, EntrepriseSecurityBlockTextComponent, EntrepriseHeaderBlockTextComponent, VueHtml2pdf
  },
  computed: {
    controlValue() {
      return {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: false,
        paginateElementsByHeight: 1100,
        manualPagination: false,
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px'
      }
    },
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: this.module.collaborateur ? `registre de traitement ${this.module.collaborateur.firstName} ${this.module.collaborateur.lastName}.pdf` : 'registre de traitement.pdf',
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
  },
  data() {
    return {
      isDataLoading: false,
      isDownloadLoading: false,
      registerModules: [],
      module: {},
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
    }
  },
  mounted() {
    this.getRegisterDataByUser();
  },
  methods: {
    getRegisterDataByUser() {
      this.isDataLoading = true;
      getRegisterDataByUser(this.$route.params.id)
        .then(response => {
          this.module = response.data.data.module;
          this.registerModules = response.data.data.registerModules.map(obj => ({ ...obj, selected: false, status: null }));
        })
        .catch(err => console.log(err))
        .finally(() => this.isDataLoading = false)
    },
    handleSelectRegisterActivityItem(args) {
      this.registerModules.map(register => {
        if (register.id === args.id) {
          register.selected = !register.selected;
        }
        else {
          register.selected = false;
        }
      })
    },
    handlePersistResponse(args) {
      this.registerModules.map(register => {
        if (register.id === args.id) {
          register.status = args.status;
          register.registerModuleQuestions = args.questions;
          register.selected = false;
        }
      })
    },
    async downloadPdf() {
      this.isDownloadLoading = true;
      if (!(await this.validateControlValue())) return;
      this.$refs.html2Pdf.generatePdf();
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        this.controlValue.pdfQuality = 2;
        return false;
      }
      if (!this.controlValue.paginateElementsByHeight) {
        this.controlValue.paginateElementsByHeight = 1400;
        return false;
      }
      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];
      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        this.controlValue.pdfFormat = "a4";
        return false;
      }
      if (!this.controlValue.pdfOrientation) {
        this.controlValue.pdfOrientation = "portrait";
        return false;
      }
      if (!this.controlValue.pdfContentWidth) {
        this.controlValue.pdfContentWidth = "800px";
        return false;
      }
      return true;
    },
    onProgress(progress) {
      if (progress === 100) {
        this.isDownloadLoading = false;
      }
    },
    hasDownloaded() {
      this.isDownloadLoading = false;
    },
    domRendered() {
      this.contentRendered = true;
    },
  }
}
</script>

<style lang="scss">
#register-detail-page {
  padding: 20px 0;
}

.register-detail-retour-btn {
  text-transform: none !important;
  text-decoration: underline !important;
  text-underline-offset: 2px;
  padding: 0 !important;
}

.register-detail-download-btn {
  padding: 8px 48px !important;

  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  text-align: center !important;
  letter-spacing: -0.5px !important;
  color: #FFFFFF !important;
}
</style>
