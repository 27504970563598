<template>
	<v-container class="px-12 overflow-auto">
		<Breadcrumb divider=">"></Breadcrumb>

		<v-row no-gutters class="mb-12 mt-4">
			<v-col md="6" cols="12" class="my-4">
				<h1 class="tw-tracking-tight tw-text-base tw-font-semibold tw-mt-4 tw-text-2xl">Détails du questionnaire</h1>
				<h5 class="tw-tracking-tight tw-text-base caption">Retrouvez ici vos réponses au questionnaire.</h5>
			</v-col>

			<v-col md="6" cols="12" class="my-4 align-center d-flex justify-end">
				<PrimaryButtonComponent class="default-btn download-btn">Télécharger</PrimaryButtonComponent>

				<PrimaryButtonComponent class="default-btn  print-btn">Imprimer</PrimaryButtonComponent>
			</v-col>

			<v-col cols="12" class="my-4">
				<v-expansion-panels>
					<v-expansion-panel v-for="(item,i) in 5" :key="i">
						<v-expansion-panel-header expand-icon="mdi-menu">
							Item
						</v-expansion-panel-header>

						<v-expansion-panel-content>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>

			<v-col cols="12" class="my-4">
				<PrimaryButtonComponent class="default-btn  return-btn">Retour</PrimaryButtonComponent>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";

export default {
	name: "SubContractorQuiz",

	computed: {

	},

	data() {
		return {

		};
	},

	components: {
		Breadcrumb,
		PrimaryButtonComponent,
	},

	methods: {

	},
};
</script>

<style scopred lang="scss">

.default-btn {
	height: 45px !important;
	padding: 0 !important;
	font-size: 12px !important;
	min-width: 100px !important;
	width: 130px !important;
	margin: 0 12px;
	font-weight: normal !important;
	color: #fff !important;
}

</style>

<style lang="scss">

.v-expansion-panel {
	margin: 12px 0;
	box-shadow: none;
	border-radius: 10px !important;

	&:before{
		content:none !important;
	}

	.v-expansion-panel-header {
		box-shadow: none;
		border: 2px solid var(--cta-bg-color);
		border-radius: 10px !important;
	}
}
</style>
