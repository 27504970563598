<template>
  <div id="entreprise-header">
    <h1 v-if="title.length > 0" class="security-title" v-html="title"></h1>
    <span v-if="description.length > 0" class="security-para" v-html="description"></span>
  </div>
</template>

<script>
export default {
  name: "EntrepriseSecurityBlockTextComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>
