<template>
  <v-app class="tw-pt-0 tw-mt-0">
    <div id="modifyDialog" class="tw-p-8">
      <div>
        <img
          class="tw-cursor-pointer"
          :style="{
            width: referrer.theme.logoWidth,
            height: referrer.theme.logoHeight,
          }"
          :src="referrer.logo"
          alt="logo"
          @click="navigate('landing-public')"
        />
      </div>
      <h2 class="tw-mt-12 tw-font-bold tw-text-center tw-text-3xl">
        Mise à jour du compte collaborateur admin
      </h2>
      <hr class="tw-w-full tw-border-black" />
      <v-form v-model="formValid" class="tw-pb-16" autocomplete="off">
        <div class="tw-my-10">
          <text-field-component class="register-activity-input" label="" type="tel" placeholder="Téléphone" v-model="collaborator.phone"
            :error-messages="errors.phone" />
          <PasswordInputComponent class="register-activity-input" placeholder="Mot de passe" @keyup="handelPassword" v-model="collaborator.password" :rules="rules.password"
              :error-messages="errors.password" label="" validate passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
            spécial (., @, !, _, %, #, :, $, *, ..., -)" />
          <PasswordInputComponent class="register-activity-input" placeholder="Confirmation mot de passe" :disabled="disableComfirmPassword" :rules="rules.confirmPassword"
              v-model="collaborator.confirmPassword" :error-messages="errors.confirmPassword"
              label="" />
        
        </div>
        <div class="tw-flex tw-justify-around tw-w-full">
          <v-btn elevation="3" text large :loading="isSendingForm" :disabled="isSendingForm" @click.prevent="updateAdmin"
            class="tw-text-white tw-cursor-pointer">
            Sauvegarder
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-app>
</template>

<script>
import TextFieldComponent from "../../../components/common/TextFieldComponent.vue";
import PasswordInputComponent from "../../../components/common/PasswordFieldComponent.vue";
import { validationRules } from '../../../utils/validation-rules';
import { collaboratorcanUpdateAdmin, collaboratorupdateAdmin } from "../services/appApi";

export default {
  name: "CollaborateursPage",
  components: { 
    TextFieldComponent, 
    PasswordInputComponent 
  },
  props: {
    token: String,
  },
  data() {
    return {
      isSendingForm: false,
      disableComfirmPassword: false,
      formValid: false,
      collaborator: {
        phone: "",
        password: "",
        confirmPassword: "",
      },
      errors: {
        phone: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.collaborator.password),
        ],
      },
    };
  },
  computed: {
    referrer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    collaboratorcanUpdateAdmin(this.token).catch(_ => {
      console.log(_);
      this.$router.push({ name: 'ErrorPage' });
    });
  },
  methods: {
    updateAdmin() {
      this.isSendingForm = true;

      collaboratorupdateAdmin({
        collaboratorAdminToken: this.token,
        password: this.collaborator.password,
        phone: this.collaborator.phone,
      })
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });

          this.$router.push({ name: 'LoginPage' });
        })
        .catch(({ response }) => {
          if (response.data.status == 422) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
              this.errors[key] = this.getValidationMessage(key, value);
            });
          }
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
    handelPassword(event) {
      if (
        event.target.value.length >= 12 &&
        event.target.value.match(/[a-z]/) &&
        event.target.value.match(/[A-Z]/) &&
        event.target.value.match(/\d+/) &&
        (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ||
          event.target.value.includes("-"))
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.collaborator.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    },
    getValidationMessage(key, value) {
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
#modifyDialog {
  max-width: 50%;
  margin: 0 auto;

  button {
    background-color: var(--cta-bg-color);
  }

  .normal-btn {
    background-color: transparent !important;
    // border-radius: 20px;
  }

  position: relative;

  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }

  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>