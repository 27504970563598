<template>
  <div id="collaborateurs-table">
    <content-placeholders v-if="loading_table">
      <content-placeholders-text :lines="10" />
    </content-placeholders>
    <!-- table  -->
    <v-simple-table v-else style="height: 100%; min-height: 600px;">
      <template v-slot:default>
        <thead>
          <tr>
            <th>
                <div @click="addToOrder('firstName')" class="tw-cursor-pointer tw-flex">
                  <span>Collaborateur</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='firstName' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='firstName' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
            </th>
            <th>
                <div @click="addToOrder('email')" class="tw-cursor-pointer tw-flex">
                  <span>Email</span>
                  <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='email' && orderType=='desc'" alt="">
                  <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='email' && orderType=='asc'" alt="">
                  <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
                </div>
            </th>
            <th>
              <div @click="addToOrder('function')" class="tw-cursor-pointer tw-flex">
                <span>Fonction</span>
                <img src="@/assets/images/icons/sort-desc.svg" v-if="orderby=='function' && orderType=='desc'" alt="">
                <img src="@/assets/images/icons/sort-asc.svg" v-else-if="orderby=='function' && orderType=='asc'" alt="">
                <img src="@/assets/images/icons/no-sort.svg" v-else alt="">
              </div>
            </th>
            <th>
              <div class="tw-flex tw-justify-center tw-items-center">
                <span>Score</span>
              </div>
            </th>
            <th>
              <div class="tw-flex tw-justify-center tw-items-center">
                <span>Envoyer</span>
              </div>
            </th>
            <th v-if="canHaveActions">
              <div class="tw-flex tw-justify-center tw-items-center">
                <span>Action</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="length > 0">
          <tr v-for="item in data" :key="item.id">
            <td :title="item.collaborateur.firstName + ' ' + item.collaborateur.lastName" class="limit-length" :class="{ 'color-gray': item.collaborateur.status == 0 }">{{ (item.collaborateur.firstName + " " + item.collaborateur.lastName).length>30?(item.collaborateur.firstName + " " + item.collaborateur.lastName).substring(0,30)+'...':item.collaborateur.firstName + " " + item.collaborateur.lastName }}</td>
            <td :title="item.collaborateur.email" class="limit-length">{{ item.collaborateur.email }}</td>
            <td :title="item.collaborateur.function" class="limit-length">{{ item.function ? item.function : item.collaborateur.function }}</td>
            <td class="tw-flex tw-justify-center tw-items-center">
              <round :items="item"></round>
            </td>
            <td>
              <div v-if="send && dataItem == item" class="tw-flex tw-justify-center tw-items-center">
                <v-progress-circular indeterminate color="black"></v-progress-circular>
              </div>
              <div v-else>
                <div class="tw-flex tw-justify-center tw-items-center" v-if="item.elearningScore >= 75">
                  <div class="shape tw-rounded-full tw-flex tw-justify-center tw-items-center success">
                    <span class="tw-text-white">
                      &#10004;
                    </span>
                  </div>
                </div>
                <Sheet-secondary  :item="item" @click.native="sendCollaborator(item)" v-else>
                  <div>
                    <span v-if="!item.elearningSendAt">
                      Envoyer
                    </span>
                    <span v-else>
                      Renvoyer
                    </span>
                  </div>
                </Sheet-secondary>
              </div>
            </td>
            <td v-if="canHaveActions">
              <div v-if="!item.elearningScore" class="tw-w-100 tw-h-full tw-flex tw-justify-center tw-items-center tooltip">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 10 12)" fill="var(--cta-bg-color)"/>
                  <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 15 12)" fill="var(--cta-bg-color)"/>
                </svg>
                <div class="tooltiptext">
                  <ul>
                    <li class="tw-cursor-pointer" @click="resendInvitation(item)">Renvoyer l'invitation</li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              Pas de données disponibles
            </td>
          </tr>
        </tbody>
        <tfoot class="text-center" v-if="length > 1 || data.length >= 10">
          <tr>
            <td colspan="12">
              <footer-table-pagination-component :limit="perPage" :choosingPage="choosedPage" :length="length" :page="page" @changeLimitAndPage="changeLimitAndPage" @changePage="changePage" />
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import SheetSecondary from "@/components/common/SheetSecondary";
import round from "@/components/common/RoundSecondary";
import { getNewCollaboratorsPaginate, inviteCollaboratorElearning, resendCollaborator } from "../../../../services/appApi";
import moment from 'moment'
import FooterTablePaginationComponent from "@/components/common/FooterTablePaginationComponent.vue";

export default {
  name: "ElearningCollaboratorsTable",
  props: ['word','filter'],
  computed: {
    formSubmitButton() {
      return "Confirmer";
    },
    referer() {
      return this.$store.state.referrer;
    },
    activeSendAll() {
      const expectation = this.data.some(el => !el.elearningSendAt || moment(new Date()).diff(moment(el.elearningSendAt), 'hours') >= 72);
      return expectation
    },
    canHaveActions() {
      // const isAtLeastNoElearningQuiz = this.data.some(el => !el.elearningScore);
      // return isAtLeastNoElearningQuiz;
      return false;
    }
  },
  components: {
    round,
    SheetSecondary,
    FooterTablePaginationComponent
  },
  data() {
    return {
      orderby: "",
      orderType: "",
      data: [],
      openPopup: false,
      selectedItem: null,
      perPage: 10,
      page: 1,
      choosedPage: 1,
      length: 1,
      loading_table: false,
      collaborateurs: [],
      sendAll: false,
      send: false,
      dataItem: null
    };
  },
  mounted() {
    this.pagination();
  },
  methods: {
    resendInvitation(module) {
      resendCollaborator(module.type, module.id)
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        });
    },
    changeLimitAndPage(obj) {
      this.perPage = parseInt(obj.limit);
      this.page == obj.page?this.pagination():this.changePage(obj.page);
    },
    changePage(page){
      this.page=parseInt(page);
      setTimeout(()=>{
        document.querySelector('#collaborateurs-table').scrollIntoView({
          behavior: 'smooth'
        });
      },200)
    },
    addToOrder(item) {
      if (this.orderby == item && this.orderType == "desc") {
        this.orderby = null
        this.orderType = null
      } else {
        if (this.orderby == item) {
          this.orderType = "desc"
        }
        else {
          this.orderby = item
          this.orderType = "asc"
        }
      }
    },
    supprimerContact(item) {
      this.selectedItem = item;
      this.openPopup = true;
    },
    async envoyer() {

      this.data.forEach(el => {

        if (el.elearningSendAt) {
          if (!el.status || el.elearningScore < 75) {
            if (moment(new Date()).diff(moment(el.elearningSendAt), 'hours') >= 72) {
              this.sendCollaborators(el)
            }
          }
        } else {
          this.sendCollaborators(el)
        }
      })


    },

    sendCollaborators(item) {
      if (!item.elearningSendAt || moment(new Date()).diff(moment(item.elearningSendAt), 'hours') >= 72) {
        this.sendAll = true
        inviteCollaboratorElearning(item.id)
          .then(() => {
            this.$snackbar.showAlertMessage({
              message: " &#10004; Le e-learning a bien été envoyé",
            });
            this.pagination();
          })
          .catch(({ response }) => {
            this.$snackbar.showAlertMessage({
              message: response.status.mesage,
              type: "error",
            });
          }).finally(() => { this.sendAll = false })
      }
    },
    sendCollaborator(item) {
      if (!item.elearningSendAt || moment(new Date()).diff(moment(item.elearningSendAt), 'hours') >= 72) {
        this.send = true
        this.dataItem = item
        inviteCollaboratorElearning(item.id)
          .then(() => {
            this.$snackbar.showAlertMessage({
              message: " &#10004; Le e-learning a bien été envoyé",
            });
            this.pagination();
          })
          .catch(({ response }) => {
            this.$snackbar.showAlertMessage({
              message: response.status.mesage,
              type: "error",
            });
          }).finally(() => { this.send = false })
      }
    },
    pagination(value = this.page, keyWord = this.word) {
      getNewCollaboratorsPaginate('elearning', value, keyWord,this.perPage, this.filter, this.orderby, this.orderType)
        .then((res) => {
          this.data = res.data.items;
          this.page = parseInt(res.data.meta.currentPage);
          this.length = parseInt(res.data.meta.totalPages);
        })
        .catch(({ response }) => {
          this.$snackbar.showAlertMessage({
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
  },
  watch: {
    word() {
      this.pagination()
    },
    orderby() {
        this.pagination()
    },
    orderType() {
        this.pagination()
    },
    page() {
      this.choosedPage = parseInt(this.page);
        this.pagination()
    }
  }
};
</script>
<style scoped>
.success {
  width: 35px;
  border-radius: 50%;
}
</style>
