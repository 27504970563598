<template>
    <div>
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 1.73633L2 24.7363" stroke="rgba(239, 68, 68)" stroke-width="4"/>
        <path d="M2 1.73633L25 24.7363" stroke="rgba(239, 68, 68)" stroke-width="4"/>
      </svg>
    </div>
</template>

<script>
export default {
 
}
</script>

<style>

</style>