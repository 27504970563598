<template>
  <div class="tw-my-10 tw-flex tw-flex-col tw-space-y-10">
    <div class="ecosystem-collaborateur" v-for="(item, index) in items" :key="index">
      <p class="tw-text-sm tw-font-bold tw-mb-3">
        {{ item.content }} 
      </p>
      <div class="tw-grid tw-grid-cols-8 tw-gap-4 tw-mb-2" v-for="(answers) in item.answers" :key="answers.$key">
        <div class="tw-flex tw-items-center tw-col-span-2 tw-border-primary tw-border-2 tw-p-2 tw-m-0"
          v-for="(answer, index) in answers" @click="chooseItem(answer)"
          :style="{'background-color': isNothingChecked && answer.id >24 && answer.id != 43? '#F5F5F5' : '#FFFFFF'}"
          :class="{ 'tw-cursor-pointer bg-color-white':!isNothingChecked ||answer.id < 25 || answer.id == 43,'bg-color-primary ': answer.checked, 'bg-color-white': !answer.checked, 'rounded-end': index % 4 == 3, 'rounded-start': index % 4 == 0 }"
          :key="index">

          <div 
                :key="index" class="tw-w-full tw-text-center tw-text-xs tw-font-semibold">{{ answer.content }}</div>
          <svg v-if="answer.checked" @click.stop="addAdditionalAnswers(answer)" class="tw-ml-1" width="17" height="17" viewBox="0 0 17 17" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9911 7.89314H9.10718V5.00921C9.10718 4.92573 9.03887 4.85742 8.95539 4.85742H8.04468C7.9612 4.85742 7.89289 4.92573 7.89289 5.00921V7.89314H5.00896C4.92548 7.89314 4.85718 7.96144 4.85718 8.04492V8.95564C4.85718 9.03912 4.92548 9.10742 5.00896 9.10742H7.89289V11.9914C7.89289 12.0748 7.9612 12.1431 8.04468 12.1431H8.95539C9.03887 12.1431 9.10718 12.0748 9.10718 11.9914V9.10742H11.9911C12.0746 9.10742 12.1429 9.03912 12.1429 8.95564V8.04492C12.1429 7.96144 12.0746 7.89314 11.9911 7.89314Z"
              fill="#FFFFFF" />
            <path
              d="M8.5 0C3.80603 0 0 3.80603 0 8.5C0 13.194 3.80603 17 8.5 17C13.194 17 17 13.194 17 8.5C17 3.80603 13.194 0 8.5 0ZM8.5 15.558C4.6029 15.558 1.44196 12.3971 1.44196 8.5C1.44196 4.6029 4.6029 1.44196 8.5 1.44196C12.3971 1.44196 15.558 4.6029 15.558 8.5C15.558 12.3971 12.3971 15.558 8.5 15.558Z"
              fill="#FFFFFF" />
          </svg>
        </div>
      </div>
    </div>
    <AddMultipleAnswersModal
      v-if="selectedItem && openPopup"
      :openPopup="openPopup"
      :item="selectedItem"
      @popupClose="openPopup = false"
      @addAdditionalAnswersToOldAnswer="addAdditionalAnswersToOldAnswer"
    />
  </div>
</template>

<script>
import AddMultipleAnswersModal from "./AddMultipleAnswersModal";
export default {
  name: "answerTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
    isNothingChecked: {
      type: Boolean,
      required: false,
    },
  },
  components: { AddMultipleAnswersModal },

  data() {
    return {
      selectedItem: null,
      openPopup: false,
    }
  },
  computed: {
    getScreenWidth() {
      return window.innerWidth
    },
    referrer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    addAdditionalAnswersToOldAnswer(additionalAnswers) {
      this.selectedItem.additionnalAnswers = additionalAnswers;
    },
    addAdditionalAnswers(answer) {
      this.selectedItem = answer;
      this.openPopup = true;
    },
    chooseItem(answer) {
      if(!this.isNothingChecked ||answer.id < 25 || answer.id == 43)
      {
        answer.checked = !answer.checked;
        this.$parent.answers.includes(answer)
          ? this.$parent.answers.splice(this.$parent.answers.indexOf(answer), 1)
          : this.$parent.answers.push(answer);
      }
    },
    addAnswer: function (index) {
      let answer = { checked: false, content: "", id: -1 };
      this.items[index].answers[this.items[index].answers.length - 1].push(answer);
    }

  }

}
</script>

<style scoped>
.table:last-child {
  border: none;
}

.rounded-end {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-start {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}
</style>
