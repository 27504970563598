import ApiService from "@/services/api.service";

export const preregister = (form) => {
  return ApiService.post("gpmse/pre-register", form);
}

export const quiz = (data) => {
  return ApiService.post("gpmse/quiz", data);
}

export const registerNotify = (data) => {
  return ApiService.post("gpmse/register-notify", data);
}