<template>
  <v-btn tile depressed color="white" elevation="0" class="pa-0 tw-tracking-tighter tw-lowercase tw-font-bold">
    <v-icon size="30" :color="referer.theme.ctaBgColor" class="tw-mr-2">mdi-arrow-left</v-icon> retour
  </v-btn>
</template>

<script>
export default {
name: "RetourButtonComponent",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
}
</script>

<style scoped>

</style>