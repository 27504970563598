<template>
  <div id="Questionnaire-detail">
    <div class="tw-flex tw-items-center tw-justify-between">
      <EntrepriseHeaderBlockTextComponent
        title="C’est validé !"
        description="Merci d’avoir répondu au questionnaire !<br>Le résultat de votre évaluation vous sera transmis par email."
      >
      </EntrepriseHeaderBlockTextComponent>
    </div>
    <ValiderComponent />
  </div>
</template>

<script>
import ValiderComponent from "@/features/company/components/Questionnaire/ValiderComponent";
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
export default {
  name: "ValidQuestionnairePage",
  computed: {
    questionnaireScore() {
      var sum = this.table_data.reduce((acc, item) => acc + item.score, 0);
      var lenght = this.table_data.length;
      return (sum / lenght).toFixed(2);
    },
  },
  components: {
    EntrepriseHeaderBlockTextComponent,
    ValiderComponent,
  },
  data() {
    return {
      table_data: [],
      selectChapitre: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.table_data = [
        {
          id: "508e9ec9-ed63-42cc-8a6f-63c897b3ce60",
          label: "Conformité",
          minNote: 1,
          maxNote: 4,
          questions: [
            {
              id: "2bacc9c4-acef-445a-8989-fb6733d4545e",
              label:
                "Votre organisation s'est elle dotée d'un Comité de Pilotage pour sa conformité au Règlement Général sur la Protection des Données [RGPD] ?",
              questionType: "radio",
              chapterId: "508e9ec9-ed63-42cc-8a6f-63c897b3ce60",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "4f70de86-b3f2-4156-a85b-385ff4796505",
              label:
                "Votre organisation dispose-t-elle d'un programme de sensibilisation au RGPD et à la sécurisation des données pour l'ensemble de ses collaborateurs ?",
              questionType: "radio",
              chapterId: "508e9ec9-ed63-42cc-8a6f-63c897b3ce60",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "9e212646-8065-4088-88b6-0b01c69cb1d8",
              label:
                "Votre organisation prend-elle en compte le respect de la vie privée des personnes dès la conception de vos offres de services [Privacy by Design] ? ",
              questionType: "radio",
              chapterId: "508e9ec9-ed63-42cc-8a6f-63c897b3ce60",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "fcd7555e-f8dc-490f-9611-a6cff49ca697",
              label:
                'Avez-vous un document décrivant votre  "politique générale de protection des données" que vous tenez à la disposition de vos clients ?',
              questionType: "radio",
              chapterId: "508e9ec9-ed63-42cc-8a6f-63c897b3ce60",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
          ],
        },
        {
          id: "7061facb-844c-4038-9d53-86a0f0be3963",
          label: "Renseignement relatifs à la criticité de vos traitements",
          minNote: 1,
          maxNote: 4,
          questions: [
            {
              id: "de40e28c-2eb1-48d5-993d-efb6517c871e",
              label:
                "Organisation : quelle cadre juridique votre organisation utilise-t-elle pour opérer ses transferts de données vers des pays en dehors de l'UE/EAA ?",
              questionType: "select_one",
              chapterId: "7061facb-844c-4038-9d53-86a0f0be3963",
              responses: [
                {
                  id: "49862c8a-0f18-4c4b-be3e-3dc581eb54b7",
                  label: "Niveau de protection adéquat",
                  score: 1,
                },
                {
                  id: "a3e73a08-10e2-4138-95d2-567697018ade",
                  label: "Clauses contractuelles type",
                  score: 1,
                },
                {
                  id: "d1033b31-2a53-4fa4-b5d7-994c691deeb0",
                  label: "Règle d'entreprise contraignantes",
                  score: 0,
                },
              ],
            },
          ],
        },
        {
          id: "8feb1721-280b-444f-8fbf-2555f98e8010",
          label: "Délégué à la protection des données",
          minNote: 1,
          maxNote: 2,
          questions: [
            {
              id: "4d512c9c-d19c-47f4-8f31-28090c393974",
              label:
                "Avez-vous nommé un Délégué à la Protection des Données (DPD ou DPO)  ?",
              questionType: "radio",
              chapterId: "8feb1721-280b-444f-8fbf-2555f98e8010",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "90361d44-a7f7-4be9-a964-7e83e3a28886",
              label:
                "L’activité de base de notre entreprise nous amène à traiter à grande échelle des données dites « sensibles » relatives à des condamnations pénales et infractions ou à des données telles que l'appartenance syndicale, génétiques, biométriques, santé, origine raciale ou ethnique, opinions politiques, convictions religieuses ou philosophiques, vie ou orientation sexuelle.",
              questionType: "radio",
              chapterId: "8feb1721-280b-444f-8fbf-2555f98e8010",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "a7d970bd-351c-4cff-9707-73335da611fe",
              label:
                "Notre activité de base nous amène à réaliser un suivi régulier et systématique des personnes physiques à grande échelle",
              questionType: "radio",
              chapterId: "8feb1721-280b-444f-8fbf-2555f98e8010",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "b0c685c7-8245-49b6-9f80-93e647db08c6",
              label: "Nous sommes un organisme public ou une collectivité",
              questionType: "radio",
              chapterId: "8feb1721-280b-444f-8fbf-2555f98e8010",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
            {
              id: "d2252d78-b6a0-4a83-baa8-45799c142ea3",
              label: "Avez-vous un correspondant à la protection des données ?",
              questionType: "radio",
              chapterId: "8feb1721-280b-444f-8fbf-2555f98e8010",
              responses: [
                {
                  id: "8f90e80f-3f6b-434c-aaef-d135275ea72d",
                  label: "Non",
                  score: 0,
                },
                {
                  id: "9ad4b6ea-feaa-4d10-b46d-b472466edea8",
                  label: "Oui",
                  score: 1,
                },
              ],
            },
          ],
        },
      ];
    },
    getBadge(score) {
      if (score >= 66) return "victor-badge-green";
      else if (score >= 33) return "victor-badge-yellow";
      else return "victor-badge-red";
    },
    getLgBadge(score) {
      if (score >= 66) return "victor-lg-badge-green";
      else if (score >= 33) return "victor-lg-badge-yellow";
      else return "victor-lg-badge-red";
    },
    getColorBadge(score) {
      if (score >= 66) return "victor-green";
      else if (score >= 33) return "victor-yellow";
      else return "victor-red";
    },
    handleChapitre(id) {
      if (this.selectChapitre == id) {
        this.selectChapitre = null;
      } else {
        this.selectChapitre = id;
      }
    },
    Retour() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire",
      });
    },
  },
};
</script>
<style scoped></style>
