<template>
  <div class="tw-grid tw-grid-cols-1 gap-40 md:tw-grid-cols-3 lg:tw-grid-cols-5 tw-mt-5">

        <reference-item       
            v-for="ref in references"
            :key="ref.$key" 
            :reference="ref" 
            :referer="referer"
            @click.native="selectDocument(ref)"/>

  </div>
</template>

<script>
import referenceItem from './referenceItem.vue';
export default {
  name: 'ReferenceList',
  components: { referenceItem },
    props:{
        references: {
            type: Array,
            default: () => []
        }
    },
    computed:{
        referer() {
        return this.$store.state.referrer;
        },
    },
    methods:{
        selectDocument(ref){
            ref.update = false
            this.$emit('selectDocument', ref)
        }
    }
};
</script>

<style lang="scss" scoped>
.done-color {
  color: #fff !important;
  background: var(--cta-text-color) !important;
}
.tw-mt-2 {
  margin-top: 0.6rem !important;
}
.document-box {
  padding: 40px;
  min-height: 500px;
  box-shadow: 3px 3px 0.4em rgb(178, 178, 178),
    -3px 3px 0.4em rgb(178, 178, 178);
  border-radius: 25px;
}
.document-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.item-box {
  height: 145px;
  width: 145px;
}
.h-1 {
  height: 66px;
}
.h-2 {
  height: 50px;
}
.h-3 {
  height: 23px;
}
.gap-40 {
  gap: 40px;
}
</style>