<template>
  <div>
    <v-radio-group
      class="auth-v-label"
      row
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-radio
        :color="referer.theme.ctaBgColor"
        v-for="item in items"
        :key="item.id"
        :label="item.label"
        :value="item.value"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioButtonParamsComponent",
  computed: {
    activeModel() {
      return this.select;
    },
    referer() {
      return this.$store.state.referrer;
    }
  },
  props: {
    items: Array,
    select: Number,
  },
  data() {
    return {
      column: this.select,
    };
  },
};
</script>

<style scoped></style>
