<template>
  <div class="tw-flex tw-flex-col tw-space-y-10">
      <div class="tw-flex tw-justify-center">
      <div class="tw-flex tw-justify-center">
        <img :src="referer.logo" width="100%" alt=""/>
      </div>
      </div>
      <div class="tw-flex tw-justify-center">
          <p class=" tw-w-2/4 tw-text-center font-color-primary">
            Bienvenue sur la plateforme de e-learning Viqtor
          </p>
      </div>
      <div class="tw-flex tw-justify-center">
          <PrimaryButtonComponent
              :style="{
                      backgroundColor: `${referer.theme.ctaBgColor} !important`,
                      color: `${referer.theme.ctaTextColor} !important` }"
                      class="add-btn tw-h-10"
                      @click="nextStep"
                  >
                      Commencer
          </PrimaryButtonComponent>
      </div>
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import {getNewCollaboratorByToken} from "@/features/company/services/appApi";
export default {
      name: "ElearningQuizStepOne",
    components:{
        PrimaryButtonComponent
    },
    mounted(){
      this.getCollaborator()
    },
    computed: {
      referer() {
        return this.$store.state.referrer;
      },
    },
      methods:{
        nextStep(){
          this.$router.push({ name:'Elearning-quiz-chapitre', params:{id:1} })
        },
        getCollaborator(){
            getNewCollaboratorByToken("elearning",this.$route.params.token).then(res =>
            {
                // console.log(res.data);
                if(res.data.data)
                  this.$store.commit('SET_ELERNING_TOKEN',this.$route.params.token)
                else
                 this.$router.push({name:"TokenExpired"})

            }).catch(()=>{
                // console.log(response);
            })
        }
      }

}
</script>

<style>

</style>
