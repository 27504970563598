<template>
  <v-dialog v-if="handelPopup" v-model="handelPopup" @click:outside="closePopup" width="600"
    transition="dialog-bottom-transition">
    <!-- <img class="img-close tw-cursor-pointer" src="@/assets/images/icons/closePopup.svg" alt="closePopup" @click="closePopup"/> -->
    <div id="modifyDialog" class="tw-p-8">
      <!-- <img src="@/assets/images/icons/info.svg" alt="logo" /> -->
      <h2 class="tw-font-bold tw-text-center tw-text-3xl">
        Rendre ce collaborateur admin
      </h2>
      <hr class="tw-w-full tw-border-black" />
      <v-form v-model="formValid" class="tw-pb-16" autocomplete="off">
        <div class="tw-my-10">
          <text-field-component class="register-activity-input" label="" type="tel" placeholder="Téléphone" v-model="collaborator.phone"
            :error-messages="errors.phone" />
          <PasswordInputComponent class="register-activity-input" placeholder="Mot de passe" @keyup="handelPassword" v-model="collaborator.password" :rules="rules.password"
              :error-messages="errors.password" label="" validate passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
            spécial (., @, !, _, %, #, :, $, *, ..., -)" />
          <PasswordInputComponent class="register-activity-input" placeholder="Confirmation mot de passe" :disabled="disableComfirmPassword" :rules="rules.confirmPassword"
              v-model="collaborator.confirmPassword" :error-messages="errors.confirmPassword"
              label="" />
        
        </div>
        <div class="tw-flex tw-justify-around tw-w-full">
          <v-btn elevation="3" text large @click="closePopup" class="tw-text-gray-100 tw-cursor-pointer normal-btn">
            ANNULER
          </v-btn>
          <v-btn elevation="3" text large :loading="isSendingForm" :disabled="isSendingForm" @click.prevent="becomeAdmin"
            class="tw-text-white tw-cursor-pointer">
            RENDRE ADMIN
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>
<script>
import TextFieldComponent from "../../../components/common/TextFieldComponent.vue";
import PasswordInputComponent from "../../../components/common/PasswordFieldComponent.vue";
import { collaboratorbecomeAdmin } from "../services/appApi";
import { validationRules } from '../../../utils/validation-rules';
export default {
  components: { 
    TextFieldComponent, 
    PasswordInputComponent },
  name: "BecomeAdminPopup",
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      immutable: true
    },
  },
  data() {
    return {
      isSendingForm: false,
      disableComfirmPassword: false,
      formValid: false,
      collaborator: {
        phone: "",
        password: "",
        confirmPassword: "",
      },
      errors: {
        phone: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.collaborator.password),
        ],
      },
    };
  },
  mounted() {
    this.collaborator = this.item;
    this.$parent.isBecomeAdminPopup = this.openPopup;
  },
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
    handelPopup() {
      return this.$parent.isBecomeAdminPopup;
    },
  },
  methods: {
    becomeAdmin() {
      this.isSendingForm = true;

      collaboratorbecomeAdmin({
        collaboratorId: this.item.id,
        password: this.collaborator.password,
        phone: this.collaborator.phone,
      })
        .then((res) => {
          this.$snackbar.showAlertMessage({
            message: res.data.message,
            type: "success",
          });
          // this.$parent.isBecomeAdminPopup = false;
          // this.$emit("fetchData");
          // this.isSendingForm = false;
          this.closePopup();
        })
        .catch(({ response }) => {
          if (response.data.status == 422) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
              this.errors[key] = this.getValidationMessage(key, value);
            });
          }
        })
        .finally(() => {
          this.isSendingForm = false;
        });
    },
    closePopup() {
      this.$emit("fetchData");
      this.$emit("PopupClose");
    },
    handelPassword(event) {
      if (
        event.target.value.length >= 12 &&
        event.target.value.match(/[a-z]/) &&
        event.target.value.match(/[A-Z]/) &&
        event.target.value.match(/\d+/) &&
        (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ||
          event.target.value.includes("-"))
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.collaborator.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    },
    getValidationMessage(key, value) {
          return value;
    },
  },
  watch: {
    item(newVal) {
      this.collaborator = newVal;
    },
  },
};
</script>
<style lang="scss" scoped>
#modifyDialog {
  button {
    background-color: var(--cta-bg-color);
  }

  .normal-btn {
    background-color: transparent !important;
    // border-radius: 20px;
  }

  position: relative;

  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }

  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
