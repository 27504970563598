<template>
  <div>
    <template v-if="isLoading">
      <content-placeholders class="">
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </template>

    <template v-else>
      <EntrepriseHeaderBlockTextComponent
        title="Mon compte"
        description=""
      />
  
      <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
        <div class="tw-mt-8" style="width: 48%;" v-if="canShowInvoices">
          <div class="tw-p-5 tw-bg-[#FAFAFA] tw-rounded-t-2xl">
            <span class="tw-font-semibold tw-text-sm">MES FACTURES / MES MOYENS DE PAIEMENT</span>
          </div>
          <div 
            v-for="index in [0, 1, 2, 3]" :key="index"
            class="tw-p-5 tw-flex tw-items-center tw-justify-between"
            :class="{
              'tw-bg-[#F3F6F9]': index % 2 === 0, 
              'tw-bg-[#FAFAFA]': index % 2 !== 0,
              'tw-py-7': invoices.length <= index
            }"
          >
            <span class="tw-text-xs tw-capitalize">{{ invoices.length > index ? invoices[index].date : '' }}</span>
            <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
              {{ invoices.length > index ? `${invoices[index].price} €` : '' }}
            </span>
            <a 
              class="tw-underline tw-text-xs"
              :style="{color: `${referrer.theme.ctaBgColor}`}"
              v-if="invoices.length > index"
              :href="invoices[index].invoicePdf"
            >Télécharger</a>
          </div>
          <div 
            class="tw-py-5 tw-flex tw-justify-center tw-rounded-b-2xl" 
            :style="{background: `${referrer.theme.ctaBgColor}`}"
          >
            <a 
              class="tw-underline tw-font-semibold tw-text-sm tw-text-white" 
              @click.prevent="$router.push({ name: 'Company.Profile.Invoices' })"
            >Voir plus</a>
          </div>
        </div>

        <div v-if="referrer.title !== 'Viqtor Expert'" class="tw-mt-8" style="width: 48%;">
          <div class="tw-p-5 tw-bg-[#FAFAFA] tw-rounded-t-2xl">
            <span class="tw-uppercase tw-font-semibold tw-text-sm">Mon ABONNEMENT</span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#F3F6F9]">
            <span class="tw-text-xs">Nombre de salariés</span>
            <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
              {{ currentSubscription.salaryRange }}
            </span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#FAFAFA]">
            <span class="tw-text-xs">Date de renouvellement</span>
            <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
              {{ currentSubscription.renewalDate }}
            </span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#F3F6F9]">
            <span class="tw-text-xs">Tarif</span>
            <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
              {{ currentSubscription.price }}
            </span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#FAFAFA]">
            <span class="tw-text-xs">Engagement</span>
            <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
              {{ currentSubscription.engagement }}
            </span>
          </div>
          <div 
            class="tw-py-5 tw-flex tw-justify-center tw-rounded-b-2xl" 
            :style="{background: `${referrer.theme.ctaBgColor}`}"
          >
            <a 
              class="tw-underline tw-font-semibold tw-text-sm tw-text-white" 
              @click.prevent="$router.push({ name: 'Company.Profile.UpdateSubscription' })"
            >Changer</a>
          </div>
        </div>
      </div>
  
      <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mt-6">
        <div class="tw-mt-8" style="width: 32%;">
          <div class="tw-p-5 tw-bg-[#FAFAFA] tw-rounded-t-2xl">
            <span class="tw-uppercase tw-font-semibold tw-text-sm">MES INFORMATIONS</span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#F3F6F9]">
            <span class="tw-text-xs">
              N° client : 
              <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
                {{ user.id }}
              </span>
            </span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#FAFAFA]">
            <span class="tw-text-xs">
              Société : 
              <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
                {{ user.workInCompany.tradeName }}
              </span>
            </span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#F3F6F9]">
            <span class="tw-text-xs">
              Prénom :
              <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
                {{ user.firstName }}
              </span>
            </span>
          </div>
          <div class="tw-p-5 tw-flex tw-items-center tw-justify-between tw-bg-[#FAFAFA]">
            <span class="tw-text-xs">
              Nom : 
              <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
                {{ user.lastName }}
              </span>
            </span>
          </div>
          <div 
            class="tw-py-5 tw-flex tw-justify-center tw-rounded-b-2xl" 
            :style="{background: `${referrer.theme.ctaBgColor}`}"
          >
            <a 
              class="tw-underline tw-font-semibold tw-text-sm tw-text-white" 
              @click.prevent="$router.push({ name: 'Company.Profile.Informations' })"
            >Voir plus</a>
          </div>
        </div>

        <div class="tw-mt-8" style="width: 32%;">
          <div class="tw-p-5 tw-bg-[#FAFAFA] tw-rounded-t-2xl">
            <span class="tw-uppercase tw-font-semibold tw-text-sm">administrateurs</span>
          </div>
          <div 
            v-for="index in [0, 1, 2, 3]" :key="index" 
            class="tw-p-5 tw-flex tw-items-center tw-justify-between" 
            :class="{'tw-bg-[#F3F6F9]': index % 2 === 0, 'tw-bg-[#FAFAFA]': index % 2 !== 0}"
          >
            <span class="tw-text-xs" :class="{'tw-py-2': admins.length <= index}">
              {{ admins.length > index ? admins[index].fullName : '' }}
            </span>
          </div>
          <div 
            class="tw-py-5 tw-flex tw-justify-center tw-rounded-b-2xl"
            :style="{background: `${referrer.theme.ctaBgColor}`}"
          >
            <a 
              class="tw-underline tw-font-semibold tw-text-sm tw-text-white"
              @click.prevent="$router.push({ name: 'Company.Profile.Administrators' })"
            >Voir plus</a>
          </div>
        </div>

        <div class="tw-mt-8" style="width: 32%;">
          <div class="tw-p-5 tw-bg-[#FAFAFA] tw-rounded-t-2xl">
            <span class="tw-uppercase tw-font-semibold tw-text-sm">HISTORIQUE DE CONNEXION</span>
          </div>
          <div
            v-for="index in [0, 1, 2, 3]" :key="index"
            class="tw-p-5 tw-flex tw-items-center tw-justify-between"
            :class="{
              'tw-bg-[#F3F6F9]': index % 2 === 0, 'tw-bg-[#FAFAFA]': index % 2 !== 0,
              'tw-py-7': signInLogs.length <= index,
            }"
          >
            <span class="tw-text-xs" v-if="signInLogs.length > index">
              Date : 
              <span class="tw-text-xs" :style="{color: `${referrer.theme.ctaBgColor}`}">
                {{ signInLogs[index] }}
              </span>
            </span>
          </div>
          <div 
            class="tw-py-5 tw-flex tw-justify-center tw-rounded-b-2xl" 
            :style="{background: `${referrer.theme.ctaBgColor}`}"
          >
            <a 
              class="tw-underline tw-font-semibold tw-text-sm tw-text-white"
              @click.prevent="$router.push({ name: 'Company.Profile.ConnexionHistory' })"
            >Voir plus</a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EntrepriseHeaderBlockTextComponent from "@/components/common/EntrepriseHeaderBlockTextComponent";
import { getRelatedAdmins, getUserInvoices, getUserSignInLogs } from '../../../services/appApi';
import { mapState } from "vuex";

export default {
  name: "ProfileDashboardPage",
  components: {
    EntrepriseHeaderBlockTextComponent,
  },
  data() {
    return {
      admins: [],
      currentSubscription: { salaryRange: "", renewalDate: "", price: "", engagement: "" },
      invoices: [],
      signInLogs: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapState(['referrer', 'user']),
    canShowInvoices() {
      return (
        (
          this.referrer.title === 'Viqtor Expert' &&
          this.user.expertType === 'client' &&
          !this.user.isCollaborator
        ) ||
        this.referrer.title !== 'Viqtor Expert'
      )
    }
  },
  async mounted() {
    await this.getRelatedAdmins();
    if (this.canShowInvoices) await this.getInvoices();
    await this.getSignInLogs();
    this.isLoading = false;

    if (this.referrer.title !== 'Viqtor Expert') {
      this.currentSubscription.salaryRange = this.user.workInCompany.planPrice.plan.salaryRange.replace('-', 'à');
      this.currentSubscription.renewalDate = new Date(
        this.user.workInCompany.subscriptions[
          this.user.workInCompany.subscriptions.length - 1
        ].endDate * 1000
      ).toLocaleDateString('fr-FR')
      this.currentSubscription.price = `
        ${this.user.workInCompany.planPrice.price} € par 
        ${this.user.workInCompany.planPrice.type === 'monthly' ? 'mois' : 'an'}
      `;
      this.currentSubscription.engagement = this.user.workInCompany.planPrice.engagement == 24 ? '2 ans' : '1 an';
    }
  },
  methods: {
    async getRelatedAdmins() {
      const options = { page: 1, limit: 4 };
      try {
        const { data: { items } } = await getRelatedAdmins(options);
        this.admins = items.map((item) => ({
          id: item.id,
          fullName: `${item.firstName} ${item.lastName}`,
        }));
      } 
      catch (error) {
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors du chargement des administrateurs",
          type: "error",
        });
      }
    },
    async getInvoices() {
      try {
        const { data: { items } } = await getUserInvoices({ page: 1, limit: 4 });
        this.invoices = items.map((item) => ({
          id: item.number,
          date: (new Date(
            +item.date.split("/")[2],
            item.date.split("/")[1] - 1,
            +item.date.split("/")[0])
          )
            .toLocaleString("fr-FR", { month: "long", year: "numeric" }),
          price: (item.total * 0.01).toFixed(2).replace(".", ","),
          invoicePdf: item.invoicePdf,
        }));
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la récupération des factures",
          type: "error",
        });
      }
    },
    async getSignInLogs() {
      try {
        const { data: { items } } = await getUserSignInLogs({ page: 1, limit: 4 });
        this.signInLogs = items.map((item) => {
          const date = new Date(item.createdAt);
          const dateString = date.toLocaleString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" });
          const timeString = date.toLocaleString("fr-FR", { hour: "2-digit", minute: "2-digit" });
          const meridiem = date.getHours() >= 12 ? "PM" : "AM";
          return `${dateString} - ${timeString} ${meridiem} - ID${item.id}`;
        });
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la récupération des factures",
          type: "error",
        });
      }
    }
  }
}
</script>