<template>
  <v-btn
    elevation="1"
    text
    class="secondary-btn"
    rounded
    large
    v-bind="$attrs"
    v-on="$listeners"
    ><slot
  /></v-btn>
</template>

<script>
export default {
  name: "SecondaryButtonComponent",
};
</script>

<style scoped></style>
