import ApiService from "@/services/api.service";


export const createIncident = (data) => {
  return ApiService.post('incident',data);
};

export const getIncidents = (page) => {
  return ApiService.get(`incident?page=${page}`);
}

export const updateIncident = (id) => {
  return ApiService.put(`incident/${id}`);
}

