<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen">
      <!-- <StepsComponent :step="1" /> !-->
      <div v-if="!formSent" class="tw-relative tw-min-h-screen">
        <TitleComponent class="tw-mt-8" text="Préinscription" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Pour commencer l’inscription de votre client, nous avons besoin de
          recueillir quelques informations. Cela ne vous prendra que 2 minutes
          !<br />
          Une fois vos informations renseignées, en cliquant sur “VALIDER”,
          votre client recevra un email sécurisé pour confirmer ses coordonnées
          de contact.
        </SignupSecondaryTextComponent>
        <div class="tw-container">
          <v-form
            ref="form"
            @submit="onSubmit"
            v-model="formValid"
            class="tw-mt-8 container"
            autocomplete="off"
          >
            <h5 class="inputs_head">Informations entreprise</h5>
            <div class="grid-custom tw-mt-4">
              <TextFieldComponent
                :rules="rules.tradeName"
                v-model="form.tradeName"
                :error-messages="errors.tradeName"
                label="Dénomination commerciale de l’entreprise"
              />
              <TextFieldComponent
                :rules="rules.sirenOrRna"
                v-model="form.sirenOrRna"
                :error-messages="errors.sirenOrRna"
                textRules="EX : 894730001"
                label="Numéro de SIREN"
              />
            </div>
            <h5 class="inputs_head">Informations du contact</h5>
            <RadioButtonComponent
              :rules="rules.gender"
              :items="genders"
              @change="onGenderChange"
            />

            <div class="grid-custom tw-mt-4">
              <TextFieldComponent
                :rules="rules.firstName"
                :error-messages="errors.firstName"
                v-model="form.firstName"
                label="Prénom"
              />
              <TextFieldComponent
                :rules="rules.lastName"
                v-model="form.lastName"
                :error-messages="errors.lastName"
                label="Nom"
              />
            </div>
            <div class="grid-custom tw-mt-4">
              <TextFieldComponent
                :rules="rules.email"
                v-model="form.email"
                label="Adresse e-mail"
              />

              <TextFieldComponent
                :rules="rules.phone"
                v-model="form.phone"
                :error-messages="errors.phone"
                messages="Ce numéro vous sera utile pour la double authentification."
                label="Numéro de téléphone mobile"
                textRules="Ce numéro vous sera utile pour la double authentification."
              />
            </div>
            <div class="tw-flex tw-justify-center tw-pb-20 tw-mt-6">
              <PrimaryButtonComponent
                :style="{
                  backgroundColor: `${referer.theme.ctaBgColor} !important`,
                  color: `${referer.theme.ctaTextColor} !important`,
                }"
                :loading="isSendingForm"
                :disabled="!formValid || isSendingForm"
                type="submit"
                >Valider</PrimaryButtonComponent
              >
            </div>
          </v-form>
        </div>
        <p class="tw-absolute tw-bottom-0 registration-iml">
          Les informations à caractère personnel recueillies dans le cadre de
          votre demande de souscription à la plateforme Viqtor® sont
          enregistrées dans un fichier informatisé par la société DBWO pour
          permettre la finalisation de l’inscription de votre organisation à nos
          services. Elles seront conservées jusqu’à la validation de la
          souscription, période durant laquelle elles pourront être
          régulièrement mises à jour à votre demande. Hors demande de votre
          part, elles seront détruites en cas de non-finalisation de votre
          demande sous un délai de 2 mois après cet envoi. Ces données sont
          destinées au personnel administratif de DBWO et conservées sur les
          serveurs de l’entreprise OVH qui héberge le site Viqtor® sur le
          territoire français. Conformément à la loi « informatique et libertés
          », vous pouvez exercer votre droit d'accès aux données vous concernant
          et les faire rectifier en contactant : dpo@dbwo.legal.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "../../../../components/common/TitleComponent";
import SignupSecondaryTextComponent from "../../components/SignupSecondaryTextComponent";
import RadioButtonComponent from "../../../../components/common/RadioButtonComponent";
import TextFieldComponent from "../../../../components/common/TextFieldComponent";
import PrimaryButtonComponent from "../../../../components/common/PrimaryButtonComponent";
//import StepsComponent from "../../components/StepsComponent";
import { validationRules } from "../../../../utils/validation-rules";
import {
  preInscriptionRegistrationFirstStep,
  preInscriptionCheckToken,
} from "../../services/appApi";
export default {
  name: "PreInscriptionFirstStepRegistrationPage",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  mounted() {
    this.checkUserToken();
  },
  components: {
    // StepsComponent,
    PrimaryButtonComponent,

    TextFieldComponent,
    RadioButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      disableComfirmPassword: true,
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        tradeName: "",
        sirenOrRna: "",
        phone: "",
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        tradeName: "",
        sirenOrRna: "",
        phone: "",
      },
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        tradeName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "tradeName"),
        ],
        sirenOrRna: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "sirenOrRna"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
      },
      genders: [
        {
          value: "male",
          label: "Monsieur",
        },
        {
          value: "female",
          label: "Madame",
        },
        {
          value: "none",
          label: "Ne souhaite pas préciser",
        },
      ],
    };
  },
  methods: {
    checkUserToken() {
      preInscriptionCheckToken(this.$route.params.token)
          .then((res) => {
            if (!res.data.data)
              this.$router.push({
                name: "PreInscriptionLogin",
              });
          });
    },
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      preInscriptionRegistrationFirstStep(this.$route.params.token, this.form)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          this.$snackbar.showAlertMessage({
            message: "La pré-inscription de votre client s’est parfaitement déroulée. Félicitations",
            type: "success",
          });
          this.$router.push({ name: "PreInscriptionLogin" });
          window.scrollTo(0, 0);
        })
        .catch(({ response }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          if (response.status === 400) {
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                position: "bottom right",
                text: item,
              });
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: la requete a échoué",
              type: "error",
            });
          }
          this.$refs.form.validate();
        });
    },
    onGenderChange(value) {
      this.form.gender = value;
    },
  },
};
</script>

<style scoped>
.inputs_head {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */

  /* Black */

  color: #25282b;
}
</style>
