<template>
  <div id="collaborateurs-add">
    <CollaboratorAddGuideComponent
        title1="Mes collaboratrices et mes collaborateurs"
        title2="dans l’entreprise"
        link-route="Gouvernance.Add.Step1"
        button-route="Gouvernance.Add.Step1"
        step-one-text="Vous allez télécharger la liste des collaborateurs de l’entreprise,  de tous les collaborateurs. Vous aurez simplement besoin de leur civilité, nom, prénom et adresse email professionnelle."
        step-two-text="Un message prérédigé vous sera proposé, vous pourrez le modifier comme vous le voulez et leur envoyer en un clic. C’est aussi simple, rien de plus, après c’est à eux de jouer, ils en ont pour 5 minutes, montre en main !"
        step-three-text="Au fur et à mesure Viqtor® gèrera pour vous à partir de votre base de données de collaborateurs, que vous pourrez compléter plus tard, celles et ceux qu’il sera nécessaire de sensibiliser, de former aux bonnes pratiques, etc..."
    >
      <div
          class="tw-flex tw-align-start tw-justify-between"
      >
        <h3 class="tw-mt-4 tw-text-justify tw-text-xs md:tw-w-9/12">
          Il est important de commencer par faire une « photo » des collaborateurs de l’entreprise. Pourquoi ? 
          Parce qu’une fois que vous aurez créé votre base, Viqtor® va vous simplifier la vie, il travaillera pour vous. 
          Comme par exemple pour identifier qui, dans votre entreprise, fait partie de l’écosystème des données personnelles 
          de personnes physiques, qui collecte, qui utilise, qui manipule dans le cadre de ses activités ce type de données. 
          Mais aussi pour envoyer l’engagement de la Direction<b>(Ma Gouvernance)</b> et identifier qui doit être formé...<br>
          Tout ce que vos collaborateurs seront invités à faire et qui ne leur prendra pas plus de temps que ça, sera centralisé 
          dans cette base.
        </h3>
      </div>
    </CollaboratorAddGuideComponent>
  </div>
</template>

<script>
import CollaboratorAddGuideComponent from "@/features/company/components/Collaborateurs/CollaboratorAddGuideComponent";
export default {
  name: "NewCollaboratorGuidePage",
  components: {CollaboratorAddGuideComponent}
}
</script>

<style scoped>

</style>
