<template>
  <div class="company-select-box">
    <div class="tw-relative">
      <label class="tw-text-black-100 tw-absolute">{{ label }}</label>
    </div>
    <div data-app>
      <v-select
        v-bind="$attrs"
        v-on="$listeners"
        :items="items"
        label="Sélectionner"
        item-text="label"
        item-value="value"
        persistent-hint
        single-line
        append-icon="mdi-chevron-down"
      >
        <template v-slot:item="{ item }">
          <div class="tw-text-black-100 tw-font-bold tw-pl-2">
            {{ item.label }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectBoxCompanyComponent",
  props: {
    label: String,
    items: Array,
  },
};
</script>

<style scoped>
label {
  top: -15px !important;
}
</style>
