<template>
  <div id="questionnaire-empty">
    <v-btn elevation="1" text rounded large @click="Repondre()">Répondre</v-btn>
    <div class="tw-flex tw-justify-center tw-align-center">
      <img :src="EmptyImg" alt="invitation" />
    </div>
  </div>
</template>
<script>
export default {
  name: "EmptyComponent",
  computed: {
    EmptyImg: function () {
      return require("@/assets/images/icons/bell_black.svg");
    },
  },
  methods: {
    Repondre() {
      this.$router.push({
        name: "Company.MonEntreprisePage.Questionnaire.RGBD",
      });
    },
  },
};
</script>
