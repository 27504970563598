<template>
  <h2><slot /></h2>
</template>

<script>
export default {
  name: "ErrorSecondaryTextComponent",
  props: {
    text: String,
  },
};
</script>

<style scoped></style>
