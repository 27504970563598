export default [
  {
    id: 1,
    title: "Engagement de la direction",
    html: `
            <div style="width: 90%;margin: 0 auto;letter-spacing: 0.5px;">
            <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">Engagement RGPD <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="annee_rouge"></span></p><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">Chères Collaboratrices, chers collaborateurs,<br><br><br><br>
            Nous poursuivons notre démarche de mise en conformité des traitements de données personnelles (collecte, enrichissement, développement, hébergement, maintenance, etc.) qu’effectue notre Entreprise, aux exigences du Règlement Général sur la Protection des Données.<br><br>
            Le principal objectif <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="annee_rouge"></span> sera d’avoir mis à jour notre gouvernance RGPD au <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="JJ/MM/AAAA" contenteditable="true"></span> prochain.<br><br>
            Dans les prochains jours, notre comité de pilotage de la conformité va être appelé à siéger.<br><br>
            J’ai chargé <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="fonction_y"></span>, de mener un audit général de notre entreprise afin de d’identifier les points d’amélioration de notre conformité au RGPD . <br><br>
            Il présentera au Comité de pilotage les axes de progression de notre mise en conformité et nous déciderons la feuille de route <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="annee_rouge"></span>.
            <br><br><br><br>
            Les principales étapes que je nous fixe sur les prochains mois sont les suivantes :<br/> <br>
            Au <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="JJ/MM/AAAA" contenteditable="true"></span> : 	finalisation de l’audit.<br/> <br>
            Au <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="JJ/MM/AAAA" contenteditable="true"></span>  : 	revu annuelle de Direction RGPD et validation de la feuille de route <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="annee_rouge"></span><br/> <br>
            Au <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="JJ/MM/AAAA" contenteditable="true"></span> : 	bilan des actions entreprises dans le cadre de la feuille de route<br><br>

            Je vous demande de partager pleinement ce projet clé car au-delà de la nécessaire conformité à la loi, je suis persuadé des bénéfices que nous pourrons retirer de la démarche, tant en termes d’efficacité interne que d’avantage concurrentiel.
            <br></p><br>
            <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"margin-left: 20px;">Sachant que je peux compter sur vous, je vous remercie de votre engagement.</p><br>
            <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"text-align: center;"><span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="ville"></span>, le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span>.<br>
            <span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span></p>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;""><span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y editable-subcontact" placeholder="Prénom" contenteditable="true"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y editable-subcontact" placeholder="Nom" contenteditable="true"></span>, <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="fonction_y editable-subcontact" placeholder="Fonction" contenteditable="true"></span><br>
            <span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span></p>
            </div>
        `,
  },
  //   {
  //     id:2,
  //     title:"Mention contractuelles sous-traitants",
  //     html: `
  //         <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
  //         <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;font-weight: bold;">Mention contractuelle sous-traitants</p><br><br><br>
  //         <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
  //         Dans le cadre de la politique de sécurisation des ses sites et de ses collaborateurs, notre entreprise a été amené à vous demander un certain nombre d’information à caractère personnel.<br><br>
  //         Nous vous informons que ces informations recueillies sur cette fiche de consigne sont enregistrées dans un fichier informatisé par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> pour assurer l’exécution de ses obligations contractuelles.<br><br>
  //         Elles seront conservées tout au long de la durée du contrat, période durant laquelle elles pourront être régulièrement mises à jour à votre demande. <br><br>
  //         Hors obligations légales spécifiques, elles seront détruites sous un délai de 3 mois après que nous ayons mis un terme au contrat ou après votre départ de l’entreprise.<br><br>
  //         Ces données sont destinées aux collaborateurs de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.<br><br>
  //         Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : <a style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" href="mailto:dpd-cpd@entreprise.com">dpd-cpd@entreprise.com</a><br><br>
  //         Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : <a style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" href="https://www.bloctel.gouv.fr/">https://www.bloctel.gouv.fr/</a><br><br>
  //         </p>
  //         </div>
  //     `
  //   },
  {
    id: 3,
    title: "Mention contractuelles Salaries",
    html: `
            <div style="width: 90%;margin: 0 auto;letter-spacing: 0.5px;">
            <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;font-weight: bold;">Information des salariés</p><br><br><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Les informations à caractère personnel recueillies sur ce contrat sont enregistrées dans un fichier informatisé par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> pour assurer l’exécution des obligations sociales et contractuelles à votre égard.<br><br>
            Elles seront conservées tout au long de la durée de votre contrat de travail, période durant laquelle elles pourront être régulièrement mises à jour à votre demande.<br><br>
            Hors obligations légales spécifiques, elles seront détruites sous un délai de 3 mois après le terme de notre collaboration.<br><br>
            Ces données sont destinées au personnel administratif en charge de la gestion des ressources humaines.<br><br>
            Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : <a style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" href=""  class="email"></a><br><br>
            Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : <a style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" href="https://www.bloctel.gouv.fr/">https://www.bloctel.gouv.fr/</a><br><br>
            </p>
            </div>
        `,
  },
  {
    id: 4,
    title: "Politique générale de gestion des données",
    html: `
            <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
            <p style="text-align: center;font-family: 'Arial',sans-serif;font-size: 18px;line-height: 20px;font-weight: 500;">Politique générale de protection des données à caractère personnel</p><br><br><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> est <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> qui s’attache à la mise en œuvre et au respect du règlement no 2016/679, dit règlement général sur la protection des données (RGPD), adopté par le Parlement européen le 14 avril 2016 et dont les dispositions sont directement applicables dans l'ensemble des 28 États membres de l'Union européenne à compter du 25 mai 2018.<br><br>
            Le respect de la vie privée et de la protection des données à caractère personnel constitue un facteur de confiance, valeur à laquelle tient particulièrement <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, en s’attachant au respect des libertés et droits fondamentaux de chacun.<br><br>
            La présente politique de protection des données à caractère personnel témoigne des engagements mis en œuvre par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> dans le cadre de ses activités quotidiennes pour une utilisation responsable des données personnelles.<br><br> 
            </p>
            <p style="font-family: 'Arial',sans-serif;font-size: 16px;line-height: 0px;font-weight: 400;">Principes applicables à la protection des données personnelles</p>
            <hr>
            <br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> _MENU_GRAY auprès de ses _MENU_RED des _MENU_PURPLE qui amènent à recueillir et traiter des données personnelles dans le respect des lois et réglementations en vigueur, et notamment de la loi Informatique et Libertés du 6 janvier 1978 modifiée et des normes édictées par la CNIL.<br><br>

            Elle applique dans la conception et la mise en œuvre de ses &nbsp;<span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  id="result_menu_purple"></span>&nbsp; les principes définis par la Privacy by Design et mène une politique d’information et de sensibilisation de ses équipes au respect des principes édictés par le Règlement Général pour la Protection des Données.<br><br>

            &nbsp;1.	Finalité déterminée, explicite et légitime du traitement :<br><br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> <span class="action"></span>&nbsp;<span style="font-family: 'Calibri',sans-serif;font-size: 15px;" class="editable" contenteditable="true" placeholder="Typologies de l’offre"></span> <br><br>

            C’est au titre de sous-traitant du responsable de traitement qu’elle applique les dispositions du RGPD sur ses activités de services à ses &nbsp;<span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" id="result_menu_red"></span>&nbsp;<br><br>

            Par ailleurs, à titre de responsable de traitement, <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> traite manuellement ou avec des solutions logicielles système ou Saas (web) des données personnelles et privées lui permettant, dans la cadre de son fonctionnement et de l’exercice de ses missions, de se conformer à ses obligations contractuelles et légales.<br><br>

            Les données personnelles sont collectées pour des objectifs précis en lien avec les finalités décrites et définis par les besoins du traitement opéré à partir d’un cahier des charges validé dans le cadre de notre procédure de protection des données personnelles par défaut.<br><br>

            &nbsp;2.  Proportion et pertinence des données collectées :<br><br>

            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> s’attache à minimiser les données personnelles collectées en ne traitant que les données strictement nécessaires à l’objectif poursuivi par la collecte. <br><br>

            &nbsp;3.  Durée de conservation limitée des données à caractère personnel :<br><br>

            Les données à caractère personnel sont conservées pendant une durée limitée qui n’excède pas la durée nécessaire aux finalités de collecte et détruites, sous réserves de disposition légales spécifiques, dans un délai maximum d’un mois après que la disparition de leur objet contractuel.<br><br>

            &nbsp;4.  Confidentialité / Sécurité des données :  <br><br>

            Des Politiques de Protection des Systèmes d’Information (PPSI) sont mises en œuvre, adaptées à la nature des données traitées et de leur traitement.<br><br>

            Des mesures de sécurité physiques, logiques et organisationnelles appropriées sont prévues pour garantir la confidentialité des données, et notamment éviter tout accès non autorisé.<br><br>

            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> exige également de tout sous-traitant qu’il présente des garanties appropriées pour assurer la sécurité et la confidentialité des données personnelles.<br><br>

            &nbsp;5.  Droits des personnes :<br><br>

            Tous les moyens nécessaires à garantir l’effectivité des droits des personnes sur leurs données personnelles sont mis en œuvre.<br><br>
            <ul style="list-style: '- ';margin-left: 10px;">
            <li style="font-family: 'Calibri',sans-serif">Les données personnelles sont collectées loyalement ; aucune collecte n’est effectuée à l’insu des personnes et sans qu’elles en soient informées.</li>
            <li style="font-family: 'Calibri',sans-serif">Les données personnelles collectées sont portées à la connaissance des personnes concernées.</li>
            <li style="font-family: 'Calibri',sans-serif">Les données collectées ne sont pas utilisées ultérieurement de manière incompatible avec ces finalités.</li>
            <li style="font-family: 'Calibri',sans-serif">Les données personnelles sont tenues exactes et à jour.</li>
            <li style="font-family: 'Calibri',sans-serif">Les délais de conservation des données sont portés à la connaissance des personnes, et varient selon la nature des données, la finalité des traitements, ou les exigences légales ou réglementaires.</li>
            <li style="font-family: 'Calibri',sans-serif">Si des données à caractère personnel devaient faire l’objet de transferts vers des pays situés dans l’Union Européenne ou hors de l’Union Européenne, les personnes concernées en seraient précisément informées, et des mesures spécifiques seraient prises pour encadrer ces transferts.</li>
            <li style="font-family: 'Calibri',sans-serif">Les moyens nécessaires à garantir l’effectivité des droits des personnes sur leurs données personnelles sont mis en œuvre, notamment par une information claire et complète sur les traitements de données mis en œuvre, facilement accessible et compréhensible par tous.</li>
            </ul>
            <br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Toute personne dispose de droits sur les données la concernant, qu’elle peut exercer à tout moment et gratuitement, en justifiant de son identité. Ainsi, les personnes peuvent accéder à leurs données personnelles, et dans certains cas les faire rectifier, supprimer ou s’opposer à leur traitement.<br><br>

            L’accès à ces droits (accès, rectification, suppression, opposition, limitation de traitement, portabilité, effacement, droit de ne pas faire l’objet d’une décision individuelle automatisée est facilité par les fonctionnalités des logiciels utilisés par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.<br><br>

            &nbsp;6. Droits issus de la Loi pour une République numérique.<br><br>

            Le nouvel article 40-1 de la loi Informatique et libertés permet aux personnes de donner des directives relatives à la conservation, à l'effacement et à la communication de leurs données après leur décès.<br><br>

            Une personne peut être désignée pour exécuter ces directives. Celle-ci a alors qualité, lorsque la personne est décédée, pour prendre connaissance des directives et demander leur mise en œuvre aux responsables de traitement concernés.<br><br>

            En l'absence de directives données de son vivant par la personne, les héritiers auront la possibilité d'exercer certains droits, en particulier :<br>
            </p>
            <ul style="list-style: '- ';margin-left: 10px;">
            <li style="font-family: 'Calibri',sans-serif">Le droit d'accès, s'il est nécessaire pour le règlement de la succession du défunt;</li>
            <li style="font-family: 'Calibri',sans-serif">Le droit d'opposition pour procéder à la clôture des comptes utilisateurs du défunt et s'opposer au traitement de leurs données.</li>
            </ul>
            <br><br>
            <p style="font-family: 'Arial',sans-serif;font-size: 16px;line-height: 0px;font-weight: 400;">Principes applicables à la protection des données personnelles</p>
            <hr>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Cette politique est révisée au moins tous les ans pour prendre en compte les évolutions législatives et réglementaires, et tout changement dans l’organisation de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> ou dans les offres, produits et services proposés.
            </p>
            <br><br>
            <p style="font-family: 'Arial',sans-serif;font-size: 16px;font-weight: 500;">La présente Politique de Protection des Données Personnelles est complétée</p>

            <ul style="list-style: '&#10003; ';margin-left: 10px;">
            <li style="font-family: 'Calibri',sans-serif">
            D’une documentation de nos solutions sur leurs finalités explicites concernant les personnes, les destinataires des données, leurs durées de conservation, et les modalités d’exercice des droits des personnes.
            </li>
            <li style="font-family: 'Calibri',sans-serif">
            D’une évaluation complète et régulière de nos sous-traitants accédant aux données personnelles dont nous lui aurions confié toute ou partie du traitement, quant à la prise en compte de leurs rôles et obligations dans le cadre du RPGD.

            </li>
            <li style="font-family: 'Calibri',sans-serif">
            De clauses contractuelles adaptées vis à vis de nos sous-traitants dans le cadre d’un risque normal ou important généré par un traitement sur la protection des données privées.

            </li>
            <li style="font-family: 'Calibri',sans-serif">
            De clause spécifiques de confidentialité sur les contrats de travail de nos collaborateurs ayant à opérer des données privées.

            </li>
            <li style="font-family: 'Calibri',sans-serif">
            D’un règlement portant sur l’utilisation des matériels informatiques.
            </li>
            </ul>     
            </p>
            </div>
`,
  },
  {
    id: 5,
    title: "Charte informatique",
    html: `
        <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
            <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 18px;line-height: 20px;">Charte d'utilisation des ressources informatiques de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span></p><br>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Préambule.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> met en œuvre un système d’information et de communication nécessaire à l’exercice de son activité.<br>
            Elle met ainsi à disposition de ses collaborateurs des outils informatiques, et de communication.<br>
            La présente charte définit les conditions d’accès et les règles d’utilisation des moyens informatiques et des ressources extérieures via les outils de communication de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span><br>
            Elle a également pour objet de sensibiliser les utilisateurs aux risques liés à l’utilisation de ces ressources en termes d’intégrité et de confidentialité des informations traitées. Ces risques imposent le respect de certaines règles de sécurité et de bonne conduite.<br> 
            L’imprudence, la négligence ou la malveillance d’un utilisateur peuvent en effet avoir des conséquences graves de nature à engager sa responsabilité civile et/ou pénale ainsi que celle de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Protection des données à caractère personnel.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            La loi n°78-17 du 6 janvier 1978 modifiée en 2004 relative à l’informatique, aux fichiers et aux libertés définit les conditions dans lesquelles des traitements de données à caractère personnel peuvent être effectués. <br>
            Elle ouvre aux personnes concernées par les traitements un droit d’accès et de rectification des données enregistrées sur leur compte.<br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> a désigné <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span> comme _MENU_BLUE à caractère personnel. <br>
            Ce dernier a pour mission de veiller au respect des dispositions de la loi n°78-17 du 6 janvier 1978 modifiée.<br>
            Il est obligatoirement consulté par le responsable des traitements préalablement à leur création.<br>
            Il recense dans un registre la liste de l’ensemble des traitements de données à caractère personnel de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> au fur et à mesure de leur mise en œuvre. <br>
            Cette liste est tenue à disposition de toute personne en faisant la demande.<br>
            Elle est également diffusée sur l’intranet de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.<br>
            Le correspondant veille au respect des droits des personnes (droit d’accès, de rectification et d’opposition). <br>
            En cas de difficultés rencontrées lors de l’exercice de ces droits, les personnes concernées peuvent saisir <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, _MENU_BLUE.
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Champs d’application de la charte.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            La présente charte s'applique à tout utilisateur du Système d'Information et de communication de la <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> pour l’exercice de ses activités professionnelles. <br>
            L’utilisation à titre privé de ces outils est tolérée, mais doit être raisonnable et ne pas perturber le bon fonctionnement du service.<br>
            La charte est diffusée à l’ensemble des utilisateurs par note de service et, à ce titre, mise à disposition sur l’intranet de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>. <br>
            Elle est systématiquement remise à tout nouvel arrivant.<br>
            Des actions de communication internes sont organisées régulièrement afin d’informer les utilisateurs des pratiques recommandées.<br>
            Quelques définitions :<br>
            On désignera sous le terme « utilisateur » ou « collaborateur » toute personne autorisée à accéder aux outils informatiques et aux moyens de communication de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> et à les utiliser : employés, stagiaires, intérimaires, personnels de sociétés prestataires, visiteurs occasionnels....<br>
            Les termes "outils informatiques et de communication" recouvrent tous les équipements informatiques, de télécommunications et de reprographie de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Règles d’utilisation du système d’information de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Chaque utilisateur accède aux outils informatiques nécessaires à l’exercice de son activité professionnelle dans les conditions définies par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.<br>
            <ol style="list-style:decimal">
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
                    Modalités d’intervention du service de l’informatique interne.<br>
            Le service de l’informatique interne assure le bon fonctionnement et la sécurité des réseaux, des moyens informatiques et de communication de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>. <br>
            Les collaborateurs/personnels de ce service disposent d’outils techniques afin de procéder aux investigations et au contrôle de l’utilisation des systèmes informatiques mis en place.<br>
            Ils ont accès à l’ensemble des données techniques mais s’engagent à respecter les règles de confidentialité applicables aux contenus des documents.<br>
            Ils sont assujettis au devoir de réserve et sont tenus de préserver la confidentialité des données qu’ils sont amenés à connaître dans le cadre de leurs fonctions.<br>
                </li>
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
                    Authentification.<br>
            L'accès aux ressources informatiques repose sur l’utilisation d'un nom de compte ("login" ou identifiant) fourni à l'utilisateur lors de son arrivée chez <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>. <br>
            Un mot de passe est associé à cet identifiant de connexion.<br>
            Les moyens d’authentification sont personnels et confidentiels.<br>
            Actuellement, le mot de passe doit être composé de <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="Mot de passe" contenteditable="true"></span> (exemple : 8 caractères minimum combinant chiffres, lettres et caractères spéciaux) <br>
            Il ne doit comporter ni le nom, prénom ni l’identifiant d’ouverture de la session de travail. Il doit être renouvelé régulièrement <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="......" contenteditable="true"></span> (exemple : tous les 3 mois).<br>
                </li>
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
                    Règles de sécurité.<br>
            Tout utilisateur s’engage à respecter les règles de sécurité suivantes :<br>
            - Signaler au service informatique interne de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> toute violation ou tentative de violation suspectée de son compte réseau et de manière générale tout dysfonctionnement.<br>
            - Ne jamais confier son identifiant/mot de passe.<br>
            - Ne jamais demander son identifiant/mot de passe à un collègue ou à un collaborateur.<br>
            - Ne pas masquer sa véritable identité.<br>
            - Ne pas usurper l'identité d'autrui.<br>
            - Ne pas modifier les paramétrages du poste de travail.<br>
            - Ne pas installer de logiciels sans autorisation.<br>
            - Ne pas copier, modifier, détruire les logiciels propriétés de [nom de l’organisme].<br>
            - Verrouiller son ordinateur dès qu’il quitte son poste de travail.<br>
            - Ne pas accéder, tenter d'accéder, supprimer ou modifier des informations qui ne lui appartiennent pas.<br>
            - Toute copie de données sur un support externe est soumise à l’accord du supérieur hiérarchique et doit respecter les règles définies par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.<br>
                </li>
            </ol>
            <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"> En outre, il convient de rappeler que les visiteurs ne peuvent avoir accès au Système d'Information de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> sans l'accord préalable du service informatique interne.
            Les intervenants extérieurs doivent s'engager à faire respecter la présente charte par leurs propres salariés et éventuelles entreprises sous-traitantes. 
            Dès lors, les contrats signés entre <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> et tout tiers ayant accès aux données, aux programmes informatiques ou autres moyens, doivent comporter une clause rappelant cette obligation.
            </span>
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Moyens informatiques.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            1.	Configuration du poste de travail.<br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> met à disposition de chaque utilisateur un poste de travail doté des outils informatiques nécessaires à l’accomplissement de ses fonctions.<br>
            L’'utilisateur ne doit pas :<br>
            - Modifier ces équipements et leur fonctionnement, leur paramétrage, ainsi que leur configuration physique ou logicielle.<br>
            - Connecter ou déconnecter du réseau les outils informatiques et de communications sans y avoir été autorisé par l’équipe informatique interne.<br>
            - Déplacer l’équipement informatique (sauf s’il s’agit d’un « équipement nomade »)<br>
            - Nuire au fonctionnement des outils informatiques et de communications.<br>
            Toute installation de logiciels supplémentaires (logiciels de consultation de fichiers multimédia) est subordonnée à l’accord du service informatique interne.<br>
            2.	Équipements nomades et procédures spécifiques aux matériels de prêt.<br>
            Équipements nomades.<br>
            On entend par « équipements nomades » tous les moyens techniques mobiles (ordinateur portable, imprimante portable, téléphones mobiles ou smartphones, CD ROM, clé USB, disques durs externes etc..). <br>
            Quand cela est techniquement possible, ils doivent faire l’objet d’une sécurisation particulière, au regard de la sensibilité des documents qu’ils peuvent stocker, notamment par chiffrement.<br>
            Quand un ordinateur portable se trouve dans le bureau du collaborateur qui en a l’usage, cet ordinateur doit être physiquement attaché à l’aide de l’antivol prévu à cet effet (sauf quand l’utilisateur est physiquement présent dans son bureau).<br>
            L’utilisation de smartphones pour relever automatiquement la messagerie électronique comporte des risques particuliers pour la confidentialité des messages, notamment en cas de perte ou de vol de ces équipements. <br>
            Quand ces appareils ne sont pas utilisés pendant quelques minutes, ils doivent donc être verrouillés par un moyen adapté de manière à prévenir tout accès non autorisé aux données qu’ils contiennent.<br>
            Procédures spécifiques aux matériels de prêt. <br>
            L’utilisateur doit renseigner et signer un registre, tenu par le service informatique interne, actant la remise de l’équipement nomade ou encore la mise à disposition d’un matériel spécifique pour la tenue d’une réunion (vidéoprojecteur). <br>
            Il en assure la garde et la responsabilité et doit informer [nom du service ou de la direction compétente] en cas d’incident (perte, vol, dégradation) afin qu’il soit procédé aux démarches telles que la déclaration de vol ou de plainte. Il est garant de la sécurité des équipements qui lui sont remis et ne doit pas contourner la politique de sécurité mise en place sur ces mêmes équipements. Le retour du matériel est consigné dans le registre.<br>
            3.	Internet.<br>
            Les utilisateurs peuvent consulter les sites internet présentant un lien direct et nécessaire avec l'activité professionnelle, de quelque nature qu’ils soient.<br>
            Toutefois, une utilisation ponctuelle et raisonnable, pour un motif personnel, des sites internet dont le contenu n'est pas contraire à la loi, l'ordre public, et ne met pas en cause l'intérêt et la réputation de l’institution, est admise.<br>
            4.	Messagerie électronique.<br>
            Conditions d’utilisation<br>
            La messagerie mise à disposition des utilisateurs est destinée à un usage professionnel.<br>
            L'utilisation de la messagerie à des fins personnelles est tolérée si elle n'affecte pas le travail du collaborateur ni la sécurité du réseau informatique de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>.<br>
            Tout message qui comportera la mention expresse ou manifeste de son caractère personnel bénéficiera du droit au respect de la vie privée et du secret des correspondances. <br>
            A défaut, le message est présumé professionnel.<br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> s’interdit d’accéder aux dossiers et aux messages identifiés comme « personnel » dans l’objet de la messagerie du collaborateur.<br>
            L’utilisation de la messagerie électronique doit se conformer aux règles d’usage définies par le service informatique interne, et validées par la direction :<br>
            - volumétrie de la messagerie,<br>
            - taille maximale de l’envoi et de la réception d’un message,<br>
            - nombre limité de destinataires simultanés lors de l’envoi d’un message,<br>
            - gestion de l’archivage de la messagerie.<br>
            Le transfert de messages, ainsi que leurs pièces jointes, à caractère professionnel sur des messageries personnelles est soumis aux mêmes règles que les copies de données sur supports externes.<br>
            Les collaborateurs peuvent consulter leur messagerie à distance, à l’aide d’un navigateur (webmail). <br>
            Les fichiers qui seraient copiés sur l’ordinateur utilisé par le collaborateur dans ce cadre doivent être effacés dès que possible de l’ordinateur utilisé.<br>
            Consultation de la messagerie<br>
            En cas d'absence d'un agent et afin de ne pas interrompre le fonctionnement du service, le service informatique interne de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> peut, ponctuellement transmettre au supérieur hiérarchique un message électronique à caractère exclusivement professionnel et identifié comme tel par son objet et/ou son expéditeur (cf conditions d’utilisation). <br>
            Le supérieur hiérarchique n'a pas accès aux autres messages du collaborateur. <br>
            Le collaborateur concerné est informé dès que possible de la liste des messages qui ont été transférés.<br>
            En cas d’absence prolongée d’un collaborateur (longue maladie), le chef de service peut demander au service informatique, après accord de son directeur, le transfert des messages reçus.<br>
            Courriel non sollicité<br>
            Afin de ne pas accentuer davantage l'encombrement du réseau lié à ce phénomène, les utilisateurs sont invités à limiter leur consentement explicite préalable à recevoir un message de type commercial, newsletter, abonnements ou autres, et de ne s'abonner qu'à un nombre limité de listes de diffusion notamment si elles ne relèvent pas du cadre strictement professionnel.<br>
            5.	Téléphone.<br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> met à disposition des utilisateurs, pour l’exercice de leur activité professionnelle, des téléphones fixes et mobiles.<br>
            L’utilisation du téléphone à titre privé est admise à condition qu’elle demeure raisonnable.<br>
            Des restrictions d’utilisation par les collaborateurs des téléphones fixes sont mises en place en tenant compte de leurs missions. A titre d’exemple, certains postes sont limités aux appels nationaux, d’autres peuvent passer des appels internationaux <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> s’interdit de mettre en œuvre un suivi individuel de l’utilisation des services de télécommunications. <br>
            Seules des statistiques globales sont réalisées sur l’ensemble des appels entrants et sortants. Elle vérifie que les consommations n’excèdent pas les limites des contrats passés avec les opérateurs.<br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> s’interdit d’accéder à l’intégralité des numéros appelés via l’autocommutateur mis en place et via les téléphones mobiles. <br>
            Toutefois, en cas d’utilisation manifestement anormale, le service informatique, sur demande de la Direction, se réserve le droit d’accéder aux numéros complets des relevés individuels.<br>
            6.	L’utilisation des outils informatiques par les représentants du personnel.<br>
            Les représentants du personnel au comité d’entreprise (CE) utilisent, dans le cadre de leur mandat, les outils informatiques qui leur sont attribués pour l’exercice de leur activité professionnelle. Ils disposent d’une adresse électronique dédiée.
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Administration du système d’information.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Afin de surveiller le fonctionnement et de garantir la sécurité du système d’information de l’entreprise, différents dispositifs sont mis en place.<br>
            1.	Les systèmes automatiques de filtrage.<br>
            A titre préventif, des systèmes automatiques de filtrage permettant de diminuer les flux d'information pour la [nom de l’organisme] et d'assurer la sécurité et la confidentialité des données sont mis en œuvre.<br>
            Il s’agit notamment du filtrage des sites Internet, de l’élimination des courriels non sollicités, du blocage de certains protocoles (peer to peer, messagerie instantanée...).<br>
            2.	Les systèmes automatiques de traçabilité.<br>
            Le service informatique de la [nom de l’organisme] opère sans avertissement les investigations nécessaires à la résolution de dysfonctionnements du système d'information ou de l'une de ses composantes, qui mettent en péril son fonctionnement ou son intégrité.<br>
            Il s’appuie pour ce faire, sur des fichiers de journalisation (fichiers « logs ») qui recensent toutes les connexions et tentatives de connexions au système d'information.<br>
            Ces fichiers comportent les données suivantes : dates, postes de travail et objet de l’évènement.<br>
            Le service informatique est le seul utilisateur de ces informations qui sont effacées à l’expiration d’un délai de trois mois.<br>
            
            
            3.	Gestion du poste de travail.<br>
            A des fins de maintenance informatique, le service informatique interne de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> peut accéder à distance à l'ensemble des postes de travail. <br>
            Cette intervention s'effectue avec l'autorisation expresse du collaborateur ou de l'utilisateur.<br>
            Dans le cadre de mises à jour et évolutions du système d’information, et lorsqu’aucun utilisateur n’est connecté sur son poste de travail, le service informatique peut être amené à intervenir sur l’environnement technique des postes de travail. <br>
            Il s’interdit d’accéder aux contenus.
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Procédure applicable lors du départ de l’utilisateur.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Lors de son départ, l’utilisateur doit restituer au service de l’informatique interne les matériels mis à sa disposition.<br>
            Il doit préalablement effacer ses fichiers et données privées., Toute copie de documents professionnels doit être autorisée par le chef de service.<br>
            Les comptes et les données personnelles de l’utilisateur sont, en tout état de cause, supprimés dans un délai maximum d’un mois après son départ.<br>
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Responsabilités – sanctions.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Le manquement aux règles et mesures de sécurité et de confidentialité définies par la présente charte est susceptible d’engager la responsabilité de l’utilisateur et d’entraîner des sanctions à son encontre.<br>
            Des sanctions en interne peuvent être prononcées, elles consistent :<br>
            - dans un premier temps, en un rappel à l’ordre émanant de la Direction après avis du service informatique interne, en cas de non-respect des règles énoncées par la charte ;<br>
            - dans un second temps, et en cas de renouvellement, après avis du comité de pilotage de la conformité., en des sanctions disciplinaires.<br>
            Le non-respect des lois et textes applicables en matière de sécurité des systèmes d’information (cf. liste des textes en annexe) est susceptible de sanctions pénales prévues par la loi.
            </p>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;font-weight:bold;">Entrée en vigueur de la charte.</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            La présente <span id="version"></span> de la charte a été adoptée après information et consultation du comité de pilotage de la conformité.<br>
            Elle est applicable à compter du <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span>.
            </p>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <br><h5 style="font-family: 'Calibri',sans-serif;text-decoration: underline;text-align: center;">ANNEXE</h5><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;text-align: center;">
            
            DISPOSITIONS LEGALES APPLICABLES<br>
            Directive 95/46/CE du 24 octobre 1995 relative à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données.<br>
            Loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés ; modifiée par la loi n°2004-801 du 6 août 2004.<br>
            Dispositions Pénales :<br>
            - Code Pénal (partie législative) : art 226-16 à 226-24<br>
            - Code Pénal (partie réglementaire) : art R. 625-10 à R. 625-13<br>
            Loi n°88-19 du 5 janvier 1988 relative à la fraude informatique dite loi Godfrain.<br>
            Dispositions pénales : art 323-1 à 323-3 du Code pénal.<br>
            Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique (LCEN)<br>
            Loi n°94-361 du 10 mai 1994 sur la propriété intellectuelle des logiciels.<br>
            Disposition pénale : art L.335-2 du Code pénal.<br>
            </p>
            
            
            
            
    </div>            
`,
  },
  {
    id: 6,
    title: "Avis constitution comité pilotage RGPD",
    html: `
        <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
        <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 16px;line-height: 20px;font-weight: 500;border: 1px solid black;padding: 5px 0;">Nomination des membres du Comité de Pilotage RGPD</p><br><br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            &emsp;&emsp;&emsp;&emsp;&emsp;Chères Collaboratrices, chers collaborateurs,<br><br>
        
            &emsp;&emsp;&emsp;&emsp;&emsp;Afin de satisfaire aux exigences du Règlement Général pour la Protection des Données Privées, nous avons procédé à 
            la nomination des membres du Comité de Pilotage RGPD animé par notre _MENU_BLUE <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, et placé sous mon autorité directe.<br><br>
            &emsp;&emsp;&emsp;&emsp;&emsp;Les personnes concernées sont : <br><br>
            <span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span><br><br>
            <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, _MENU_BLUE<br><br>
            <div id="input-container">
                <div class="input-group">
                <span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Nom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Prénom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Fonction" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="email-editable editable-subcontact" placeholder="Email" contenteditable="true"></span><button class="plus-btn">+</button>
                </div>
            </div>
         <br><br>
        
            
            <span style="font-family: 'Calibri',sans-serif;">Dans ce cadre, elles seront amenées à participer aux travaux et décision concernant les sujets</span><br>
        <ul style="list-style: '- ';margin-left: 10px;">
        <li style="font-family: 'Calibri',sans-serif">De la mise en conformité des services et processus de l’entreprise aux obligations du RGPD.</li>
        <li style="font-family: 'Calibri',sans-serif">De la diffusion et du contrôle de l’application de ces règles d’entreprise.</li>
        <li style="font-family: 'Calibri',sans-serif">De l’animation et de la formation du personnel de l’entreprise aux obligations du RGPD.</li>
        <li style="font-family: 'Calibri',sans-serif">De l’information et de la contractualisation de ces obligations vis-à-vis des sous-traitants.</li>
        <li style="font-family: 'Calibri',sans-serif">De la validation des modifications portées au Registre des Traitement au terme du processus de Privacy by Design appliqué à tout nouveau traitement ou toute modification dans l’organisation de l’Entreprise.</li>
        <li style="font-family: 'Calibri',sans-serif">De décider des Enquêtes d’impact sur la vie privée et des audits interne et sous-traitants menés par l’Entreprise.</li>
        </ul>
        <br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Le comité de Pilotage RGPD a vocation à définir chaque année l’engagement RGPD de l’Entreprise après présentation par le Délégué à la Protection des Données de son rapport annuel.<br><br><br>
        
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="ville"></span>, le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span><br><br>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span><br><br>
            _MENU_BLUE
            <br><br>
        </p>
            
        </p>
        </div>    
`,
  },
  {
    id: 7,
    title: "Membre du comité data breach",
    html: `
        <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
        <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 16px;line-height: 20px;font-weight: 500;border: 1px solid black;padding: 5px 0;">Nomination des membres du Comité de Violation de Données personnelles</p><br><br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Chères Collaboratrices, chers collaborateurs,<br><br>
        
            Afin de satisfaire aux exigences du Règlement Général pour la Protection des Données Privées, nous avons procédé à 
            la nomination des membres du Comité de « Violation des données personnelles »  animé par notre _MENU_BLUE <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, qui est placé sous mon autorité directe.<br><br>
            Les personnes concernées sont : <br><br>
            <span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span><br><br>
            <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, _MENU_BLUE<br><br>
            <div id="input-container">
                <div class="input-group">
                <span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Nom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Prénom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Fonction" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="email-editable editable-subcontact" placeholder="Email" contenteditable="true"></span><button class="plus-btn">+</button>
                </div>
            </div>
          <br><br>
        
            
            À ce titre ces personnes seront amenées à contribuer au respect par l’Entreprises de ses obligations vis-à-vis de son autorité de contrôle et vis-à-vis de ses clients en cas de violation de l’intégrité de ses systèmes.  <br>
            Elles devront permettre notamment à notre _MENU_BLUE de notifier toute violation de données à caractère personnel dans les délais impartis et accompagner cette notification de toute documentation utile. <br>
        <ul style="list-style: '- ';margin-left: 10px;">
        <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">La description de la nature de la violation de données à caractère personnel y compris, si possible, les catégories et le nombre approximatif de personnes concernées par la violation et les catégories et le nombre approximatif d'enregistrements de données à caractère personnel concernés ;</li>
        <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Le nom et les coordonnées de notre _MENU_BLUE ou d'un autre point de contact auprès duquel des informations supplémentaires peuvent être obtenues ;</li>
        <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">La description des conséquences probables de la violation de données à caractère personnel ;</li>
        <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">La description des mesures prises par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> ou qu’elle propose de prendre pour remédier à la violation de données à caractère personnel, y compris, le cas échéant, les mesures pour en atténuer les éventuelles conséquences négatives.</li>
        </ul>
        <br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
            Le comité de Pilotage RGPD a vocation à définir chaque année l’engagement RGPD de l’Entreprise après présentation par le Délégué à la Protection des Données de son rapport annuel.<br><br><br>
        
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="ville"></span>, le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span><br><br>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span><br><br><br><br>
        </p>
            
        </p>
        </div>
        `,
  },
  {
    id: 8,
    name: "Avenant RGPD responsable de traitement",
    html: `
    <div style="width: 90%; padding-top: 150px;margin: 0 auto;"letter-spacing: 0.5px;>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;text-align: center;line-height: 35px;">_MENU_SOUSTRAITANT</p>
        <br>
        <br>
        <hr>
        <br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;text-align: center;line-height: 35px;"> 
        AVENANT RGPD <br>
        AU CONTRAT DU <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge" class="editable" contenteditable="true"></span>
        </p>
        <br>
        <hr>
        <p style="font-family: 'Calibri',sans-serif;font-size: 25px;text-align: center; line-height: 35px;"> 
            <span style="font-size: 15px;text-decoration: underline;">ENTRE</span><br>
            <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span><br>
            (« Le donneur d’ordre responsable de traitement »)<br>
            <span style="font-size: 15px;text-decoration: underline;">ET</span><br>
            <span class="red_trade"></span>&emsp;<span class="red_juridic"></span><br>
            (« Le prestataire sous-traitant »)<br><br>
            
            <span style="font-size: 15px;">Le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span></span>
           <br>
           <br>
           <br>
           <br>
        </p>
        
        <p style="margin-top:200px;font-family: 'Calibri',sans-serif;font-size: 15px;text-align: center;line-height: 35px;">SOMMAIRE</p>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;text-align: justify;line-height: 35px;">Préambule</p>
        <ol type="I" style="list-style-type:upper-roman">
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Objet</li><br>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Description du traitement faisant l’objet de la sous-traitance</li><br>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Durée du contrat</li><br>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                Obligations de <span class="red_trade"></span>&emsp;vis-à-vis <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> . <br><br>
                    &emsp;&emsp;Traitement des données <br><br>
                    &emsp;&emsp;Confidentialité des données <br><br>
                    &emsp;&emsp;Formation des personnes<br><br>
                    &emsp;&emsp;Prise en compte des principes de protection des données dès la conception <br><br>
                    &emsp;&emsp;Sous-traitance<br><br>
                    &emsp;&emsp;Droit d’information des personnes concernées<br><br>
                    &emsp;&emsp;Exercice des droits des personnes<br><br>
                    &emsp;&emsp;Notification des violations de données à caractère personnel.<br><br>
                    &emsp;&emsp;Assistance à <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> dans le cadre du respect de ses obligations règlementaires.<br><br>
                    &emsp;&emsp;Mesures de sécurité.<br><br>
                    &emsp;&emsp;Sort des données<br><br>
                    &emsp;&emsp;Délégué à la protection des données<br><br>
                    &emsp;&emsp;Registre des catégories de activités de traitement<br><br>
                    &emsp;&emsp;Documentation<br><br>
                    
            </li>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Obligations de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> vis-à-vis de <span class="red_trade"></span>&emsp;<span class="red_juridic"></span></li><br>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Autorité de contrôle, loi applicable et attribution de juridiction</li><br>
        </ol>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 25px;">
            
        Le présent avenant ( « L’Avenant ») en date du <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span> conclu entre : <br><br><br>
        
        <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>, dont le siège social est sis <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="adress_siege_social"></span>, immatriculée au Registre du Commerce et des Sociétés de <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="Tribunal de commerce" contenteditable="true"></span> sous le numéro <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="saisir un numéro RC" contenteditable="true"></span> (« Le donneur d’ordre responsable de traitement »).<br><br>
        Et<br><br>
        <span class="red_trade"></span>&emsp;<span class="red_juridic"></span>, dont le siège social est sis <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="adresse du siège" contenteditable="true"></span>, immatriculée au Registre du Commerce et des Sociétés de <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="ville" contenteditable="true"></span> sous le numéro <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="saisir un numéro RC" contenteditable="true"></span> (« Le prestataire sous-traitant »), <br><br><br><br>
        
        Préambule<br><br>
        <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> ET <span class="red_trade"></span> ont signé un contrat le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span>  (le « Contrat Initial »), dans le cadre duquel <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> partage des données personnelles avec la société <span class="red_trade"></span>. <br>
        Afin de se conformer au Règlement Général sur la Protection des Données (le « RGPD »), les parties souhaitent amender le Contrat Initial en y incluant les dispositions ci-dessous. <br>
        Les autres dispositions du Contrat Initial demeurent inchangées. <br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">
            &emsp;&emsp;&emsp;&emsp;I.	Objet.<br>
        Les présentes clauses ont pour objet de définir les conditions dans lesquelles <span class="red_trade"></span> s’engage à effectuer pour le compte de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> les opérations de traitement de données à caractère personnel définies ci-après.<br>
        Dans le cadre de leurs relations contractuelles, les parties s’engagent à respecter la réglementation en vigueur applicable au traitement de données à caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après, « le règlement européen sur la protection des données »).<br><br>
        </p>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">
            &emsp;&emsp;&emsp;&emsp;II.	Description du traitement faisant l’objet de la sous-traitance.<br>
            Les prestations assurées par <span class="red_trade"></span> pour le compte de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> sont explicitées dans le contrat signé par les parties le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span> portant sur la fourniture des services IT (le « Contrat Initial ») et restent inchangées. <br>
            <br>
        </p>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">
            &emsp;&emsp;&emsp;&emsp;III.  Durée du contrat.<br>
            Le présent avenant entre en vigueur à compter du 25 mai 2018 et à vocation à former avec le « Contrat Initial » le « contrat » dont les conditions de durée et de validité demeurent inchangées<br><br>
            <br>
        </p>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">
            &emsp;&emsp;&emsp;&emsp;IV.	Obligations de <span class="red_trade"></span> vis-à-vis de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>.<br><br>
                <span class="red_trade"></span> s'engage à :<br>
        </p>
        <ul style="list-style: '- ';margin-left: 10px;">
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Traiter les données uniquement pour la ou les seule(s) finalité(s) qui fait/font l’objet de la sous-traitance</li>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                Traiter les données conformément aux instructions documentées de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> figurant au « contrat initial ». Si <span class="red_trade"></span> considère qu’une instruction constitue une violation du règlement européen sur la protection des données ou de toute autre disposition du droit de l’Union ou du droit des Etats membres relative à la protection des données, 
                il en informe immédiatement <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>. En outre, si <span class="red_trade"></span> est tenu de procéder à un transfert de données vers un pays tiers ou à une organisation internationale, en vertu du droit de l’Union ou du droit de l’Etat membre auquel il est soumis, il doit informer <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> de cette obligation juridique avant le traitement, sauf si le droit concerné interdit une telle information pour des motifs importants d’intérêt public
            </li>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                Garantir la confidentialité des données à caractère personnel traitées dans le cadre du présent contrat
            </li>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                Veiller à ce que les personnes autorisées à traiter les données à caractère personnel en vertu du présent contrat :
                <ul style="list-style-type:circle;">
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">S’engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité</li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Reçoivent la formation nécessaire en matière de protection des données à caractère personnel</li>
                </ul>
            </li>
            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                Prendre en compte, s’agissant de ses outils, produits, applications ou services, les principes de protection des données dès la conception et de protection des données par défaut
            </li>
        </ul>
        <br>
        <p>
        <span style="font-family: 'Calibri', sans-serif;font-weight: bold;">Sous-traitance</span><br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;"><span class="red_trade"></span> peut faire appel à un autre sous-traitant (ci-après, « sous-traitant ultérieur ») pour mener des activités de traitement spécifiques. Dans ce cas, il informe préalablement et par écrit <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> de tout changement envisagé concernant l’ajout ou le remplacement de autres sous-traitants. Cette information doit indiquer clairement les activités de traitement sous-traitées, l’identité et les coordonnées du sous-traitant et les dates du contrat de sous-traitance. <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> dispose d’un délai maximum de 15 jours à compter de la date de réception de cette information pour présenter ses objections. Cette sous-traitance ne peut être effectuée que si <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> n’a pas émis d’objection pendant le délai convenu.<br><br>
        Le sous-traitant ultérieur est tenu de respecter les obligations du présent contrat pour le compte et selon les instructions de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>. Il appartient à <span class="red_trade"></span> de s’assurer que le sous-traitant ultérieur présente les mêmes garanties suffisantes quant à la mise en œuvre de mesures techniques et organisationnelles appropriées, de manière à ce que le traitement réponde aux exigences du règlement européen sur la protection des données. Si le sous-traitant ultérieur ne remplit pas ses obligations en matière de protection des données, <span class="red_trade"></span> demeure pleinement responsable devant <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> de l’exécution par l’autre sous-traitant de ses obligations.<br><br></p>
        <span style="font-family: 'Calibri', sans-serif;font-weight: bold;">Droit d’information des personnes concernées</span><br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">Il appartient à <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> de fournir l’information aux personnes concernées par les opérations de traitement au moment de la collecte des données.<br><br></p>
        <span style="font-family: 'Calibri', sans-serif;font-weight: bold;">Exercice des droits des personnes</span><br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">Dans la mesure du possible, <span class="red_trade"></span> doit aider <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> à s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des personnes concernées : droit de accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage).<br><br>
        Lorsque les personnes concernées exercent auprès de <span class="red_trade"></span> des demandes d’exercice de leurs droits, <span class="red_trade"></span> doit adresser ces demandes dès réception par courrier électronique à <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>.<br><br></p>
        <span style="font-family: 'Calibri', sans-serif;font-weight: bold;">Notification des violations de données à caractère personnel.</span><br><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 15px;"><span class="red_trade"></span> notifie à la <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> toute violation de données à caractère personnel dans un délai maximum de 48 heures après en avoir pris connaissance et par le moyen le plus rapide et le plus sûr. Cette notification est accompagnée de toute documentation utile afin de permettre au <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>, si nécessaire, de notifier cette violation à l’autorité de contrôle compétente. <br><br>
            La notification contient au moins :<br><br></p>
            <ul style="list-style: '- ';margin-left: 10px;">
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">La description de la nature de la violation de données à caractère personnel y compris, si possible, les catégories et le nombre approximatif de personnes concernées par la violation et les catégories et le nombre approximatif de enregistrements de données à caractère personnel concernés ;</li>
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                    Le nom et les coordonnées du délégué à la protection des données ou d’un autre point de contact auprès duquel des informations supplémentaires peuvent être obtenues ;
                </li>
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                    La description des conséquences probables de la violation de données à caractère personnel ;        
                </li>
                <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                    La description des mesures prises ou que <span class="red_trade"></span> propose de prendre pour remédier à la violation de données à caractère personnel, y compris, le cas échéant, les mesures pour en atténuer les éventuelles conséquences négatives.
                </li>
            </ul>
        <p style="font-family: 'Calibri', sans-serif;font-size: 15px;">
            Si, et dans la mesure où il n’est pas possible de fournir toutes ces informations en même temps, les informations peuvent être communiquées de manière échelonnée sans retard indu.
            <br><br>
            <span style="font-weight: 600;">Aide de <span class="red_trade"></span> dans le cadre du respect par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> de ses obligations règlementaires.</span><br><br>
            Dans la mesure de son implication dans le traitement, <span class="red_trade"></span> aide <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> pour la réalisation de analyses d’impact relative à la protection des données.<br><br>
            Dans la mesure de son implication dans le traitement, <span class="red_trade"></span> aide <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> pour la réalisation de la consultation préalable de l’autorité de contrôle.<br><br>
            <span style="font-weight: 600;">Mesures de sécurité.</span><br><br>
            <span class="red_trade"></span> s’engage à mettre en œuvre les mesures de sécurité validées dans l’analyse d’impact relative à la protection des données, les codes de conduites ou le cas échéant, règles prévues par le processus de prise en compte de la protection des données dès la conception et décrites dans son « Plan de Sécurisation des données ».<br><br>
            <span style="font-weight: 600;">Sort des données</span><br><br>
            Au terme de la prestation de services relatifs au traitement de ces données, <span class="red_trade"></span> s’engage à renvoyer toutes les données à caractère personnel à <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span>. <br><br>
            Le renvoi doit s’accompagner de la destruction de toutes les copies existantes dans les systèmes d’information de <span class="red_trade"></span>. Une fois détruites, <span class="red_trade"></span> devra justifier par écrit de la destruction.<br><br>
            <span style="font-weight: 600;">Délégué à la protection des données</span><br><br>
            Conformément à l’article 37 du règlement européen sur la protection des données, <span class="red_trade"></span> a nommé un délégué à la protection des données en la personne de <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="saisir le prénom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="saisir le nom" contenteditable="true"></span>.<br><br>
            
            <span style="font-family: 'Calibri', sans-serif;font-weight: 600;">Registre des catégories de activités de traitement</span><br><br>
            <span class="red_trade"></span> déclare tenir par écrit un registre de toutes les catégories de activités de traitement effectuées pour le compte de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> comprenant :<br>
                <ul style="list-style: '- ';margin-left: 10px;">
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Le nom et les coordonnées de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> pour le compte duquel il agit, des éventuels sous-traitants et, le cas échéant, du délégué à la protection des données ;</li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Les catégories de traitements effectués pour le compte de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> ;</li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Le cas échéant, les transferts de données à caractère personnel vers un pays tiers ou à une organisation internationale, y compris l'identification de ce pays tiers ou de cette organisation internationale et, dans le cas des transferts visés à l'article 49, paragraphe 1, deuxième alinéa du règlement européen sur la protection des données, les documents attestant de l'existence de garanties appropriées ;</li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Dans la mesure du possible, une description générale des mesures de sécurité techniques et organisationnelles, y compris entre autres, selon les besoins :
                        <ul style="list-style-type:circle;">
                            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">La pseudonymisation et le chiffrement des données à caractère personnel ;</li>
                            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Des moyens permettant de garantir la confidentialité, l'intégrité, la disponibilité et la résilience constantes des systèmes et des services de traitement ;</li>
                            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Des moyens permettant de rétablir la disponibilité des données à caractère personnel et l'accès à celles-ci dans des délais appropriés en cas d’incident physique ou technique ;</li>
                            <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Une procédure visant à tester, à analyser et à évaluer régulièrement l'efficacité des mesures techniques et organisationnelles pour assurer la sécurité du traitement.</li>
                        </ul>
                    </li>
                </ul>
                <br>
                <br>
            <span style="font-weight: 600;">Documentation</span><br><br>
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">
                <span class="red_trade"></span> met à la disposition de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> la documentation nécessaire pour démontrer le respect de toutes ses obligations et pour permettre la réalisation de audits, y compris des inspections, par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> ou un autre auditeur qu'il a mandaté, et contribuer à ces audits.<br><br>
                    &emsp;&emsp;&emsp;&emsp;V.	Obligations de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> vis-à-vis de <span class="red_trade"></span><br><br>
                    <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span> s’engage à :<br>
            </p>
                <ul  style="list-style: '- ';margin-left: 10px;">
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Fournir à <span class="red_trade"></span> les données visées au II des présentes clauses</li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Documenter par écrit toute instruction concernant le traitement des données par <span class="red_trade"></span></li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Veiller, au préalable et pendant toute la durée du traitement, au respect des obligations prévues par le règlement européen sur la protection des données de la part de <span class="red_trade"></span>.</li>
                    <li style="font-family: 'Calibri',sans-serif;font-size: 15px;">Superviser le traitement, y compris réaliser les audits et les inspections auprès de <span class="red_trade"></span>.</li>
                </ul>
        
        
            <p style="font-family: 'Calibri',sans-serif;font-size: 15px;">
            &emsp;&emsp;&emsp;&emsp;VI.	Autorité de contrôle, loi applicable et attribution de juridiction<br><br>
            Les parties ont choisi la CNIL comme autorité de contrôle de leur conformité RGPD.<br>
            Le Contrat est soumis au droit français <br>
            Tout différend relatif au Contrat sera soumis à la compétence exclusive du Tribunal de commerce de <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="Tribunal de commerce" contenteditable="true"></span>.<br><br><br>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            </p>
            <p>
                <div style="position: relative;">
                    <div style="position: absolute; left: 40px;bottom: 30px;">
                            <span style="font-family: 'Calibri',sans-serif;">
                            <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="saisir la ville" contenteditable="true"></span>, le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span>
                            </span>
                            <br><br>
                            <span style="font-family: 'Calibri',sans-serif;font-size: 13px;">Pour <span class="red_trade"></span></span><br><br>
                        <div style="height: 120px;width: 180px;border: 1px solid black;"></div>
                        <br><br>
                        <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="civilité, prénom, nom" contenteditable="true"></span>
                    </div>
                    <div style="position: absolute; right: 20px;bottom: 30px;">
                        <span style="font-family: 'Calibri',sans-serif;font-size: 13px;"></span><br><br>
                        <span style="font-family: 'Calibri',sans-serif;font-size: 13px;">Pour <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, <span style="font-family: 'Calibri',sans-serif;"  class="structure_juridique_y"></span></span><br><br>
                        <div style="height: 120px;width: 180px;border: 1px solid black;"></div>
                        <br><br>
                        <span style="font-family: 'Calibri',sans-serif;" class="editable" placeholder="civilité, prénom, nom" contenteditable="true"></span>
                    </div>
                </div>
            </p>
        </p>
            
        </p>
        <br><br>
        </p>
        
        </div>
        `,
  },
  {
    id: 9,
    title: "Mention contractuelles Clients directes",
    html: `
    <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
    <p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;font-weight: bold;">Information des clients directs</p><br><br><br>
    <p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
    Des informations à caractère personnel vous concernant sont enregistrées dans un fichier informatisé par <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span> pour assurer l’exécution du contrat de prestation que vous avez souscrit.<br><br>
    Elles seront conservées tout au long de la durée du contrat, période durant laquelle elles pourront être régulièrement mises à jour à votre demande. Hors obligations légales spécifiques, elles seront détruites sous un délai de 3 mois après le terme du contrat.<br><br>
    Ces données sont destinées aux techniciens et personnels administratifs de notre Entreprise<br><br>
    Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : <a style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" href="" class="email"></a><br><br>
    Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : <a style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;" href="https://www.bloctel.gouv.fr/">https://www.bloctel.gouv.fr/</a><br><br>
    </p>
    </div>
    `,
  },
  {
    id: 10,
    title: "Engagement de confidentialité",
    html: `
    <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
        <p style="text-align: center;font-family: 'Arial',sans-serif;font-size: 18px;line-height: 20px;font-weight: 500;">Engagement de confidentialité</p><br>
        <p style="font-family: 'Calibri',sans-serif;font-size: 14px;line-height: 20px;">
            Je soussigné {{prenom}} {{nom}}, exerçant les fonctions de {{fonction}} au sein de <span style="font-family: 'Calibri',sans-serif;"  class="social_client"></span>, étant à ce titre amené/e à accéder à des données à caractère personnel, déclare reconnaître la confidentialité desdites données.</span><br><br>
            Je m’engage par conséquent, conformément aux articles 34 et 35 de la loi du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés ainsi qu’aux articles 32 à 35 du règlement général sur la protection des données du 27 avril 2016, à prendre toutes précautions conformes aux usages et à l’état de l’art dans le cadre de mes attributions afin de protéger la confidentialité des informations auxquelles j’ai accès, et en particulier d’empêcher qu’elles ne soient communiquées à des personnes non expressément autorisées à recevoir ces informations.<br><br>
            Je m’engage en particulier à :<br>
            <ul style="list-style: '- ';margin-left: 10px;font-size: 14px;">
                <li style="font-family: 'Calibri',sans-serif">Ne pas utiliser les données auxquelles je peux accéder à des fins autres que celles prévues par mes attributions ;</li>
                <li style="font-family: 'Calibri',sans-serif">Ne divulguer ces données qu’aux personnes dûment autorisées, en raison de leurs fonctions, à en recevoir communication, qu’il s’agisse de personnes privées, publiques, physiques ou morales ;</li>
                <li style="font-family: 'Calibri',sans-serif">Ne faire aucune copie de ces données sauf à ce que cela soit nécessaire à l’exécution de mes fonctions ;</li>
                <li style="font-family: 'Calibri',sans-serif">Prendre toutes les mesures conformes aux usages et à l’état de l’art dans le cadre de mes attributions afin d’éviter l’utilisation détournée ou frauduleuse de ces données ;</li>
                <li style="font-family: 'Calibri',sans-serif">Prendre toutes précautions conformes aux usages et à l’état de l’art pour préserver la sécurité physique et logique de ces données ;</li>
                <li style="font-family: 'Calibri',sans-serif">M’assurer, dans la limite de mes attributions, que seuls des moyens de communication sécurisés seront utilisés pour transférer ces données ;</li>
                <li style="font-family: 'Calibri',sans-serif">En cas de cessation de mes fonctions, restituer intégralement les données, fichiers informatiques et tout support d’information relatif à ces données.</li>
            </ul><br><br>
            <div style="font-family: 'Calibri',sans-serif;font-size: 14px;line-height: 20px;">
                Cet engagement de confidentialité, en vigueur pendant toute la durée de mes fonctions, demeurera effectif, sans limitation de durée après la cessation de mes fonctions, quelle qu’en soit la cause, dès lors que cet engagement concerne l’utilisation et la communication de données à caractère personnel.
            <br><br>

                J’ai été informé que toute violation du présent engagement m’expose à des sanctions disciplinaires et pénales conformément à la réglementation en vigueur, notamment au regard des articles 226-16 à 226-24 du code pénal.                                                                                                                                  
            <br><br>
            
            Fait à <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="ville"></span>, le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span>, en 2 exemplaires<br><br>
            
            
            <div style="font-family: 'Calibri',sans-serif;font-size: 14px;line-height: 20px;text-align: center;">&emsp;&emsp;&emsp;Signature</div>                                     
            </div>
        </p>
    </div>
    `,
  },
  {
    id: 11,
    title: "Membre comité pilotage RGPD",
    html: `
    <div style="width: 90%; margin: 0 auto;letter-spacing: 0.5px;">
<p style="text-align: center;font-family: 'Calibri',sans-serif;font-size: 16px;line-height: 20px;font-weight: 500;border: 1px solid black;padding: 5px 0;">Nomination des membres du Comité de Pilotage RGPD</p><br><br><br>
<p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
Chères Collaboratrices, chers collaborateurs,<br><br>

Afin de satisfaire aux exigences du Règlement Général pour la Protection des Données Privées, nous avons procédé à 
la nomination des membres du Comité de Pilotage RGPD animé par notre _MENU_BLUE, <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, et placé sous mon autorité directe.<br><br>
Les personnes concernées sont : <br><br>
<span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span><br><br>
<span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="prenom_y"></span> <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="nom_y"></span>, _MENU_BLUE<br><br>
<div id="input-container-last">
    <div class="input-group">
        <span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Nom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Prénom" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="editable-subcontact" placeholder="Fonction" contenteditable="true"></span><span style="font-family: 'Calibri',sans-serif;" class="email-editable editable-subcontact" placeholder="Email" contenteditable="true"></span><button class="plus-btn">+</button>
    </div>
</div>
<br><br>

<span style="font-family: 'Calibri',sans-serif;">Dans ce cadre, elles seront amenées à participer aux travaux et décision concernant les sujets</span><br>
<ul style="list-style: '- ';margin-left: 10px;">
<li style="font-family: 'Calibri',sans-serif">De la mise en conformité des services et processus de l’entreprise aux obligations du RGPD.</li>
<li style="font-family: 'Calibri',sans-serif">De la diffusion et du contrôle de l’application de ces règles d’entreprise.</li>
<li style="font-family: 'Calibri',sans-serif">De l’animation et de la formation du personnel de l’entreprise aux obligations du RGPD.</li>
<li style="font-family: 'Calibri',sans-serif">De l’information et de la contractualisation de ces obligations vis-à-vis des sous-traitants.</li>
<li style="font-family: 'Calibri',sans-serif">De la validation des modifications portées au Registre des Traitement au terme du processus de Privacy by Design appliqué à tout nouveau traitement ou toute modification dans l’organisation de l’Entreprise.</li>
<li style="font-family: 'Calibri',sans-serif">De décider des Enquêtes d’impact sur la vie privée et des audits interne et sous-traitants menés par l’Entreprise.</li>
</ul>
<br><br>
<p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
<p style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;">
Le comité de Pilotage RGPD a vocation à définir chaque année l’engagement RGPD de l’Entreprise après présentation par le _MENU_BLUE de son rapport annuel.<br><br><br>

&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="ville"></span>, le <span style="font-family: 'Calibri',sans-serif;font-size: 15px;line-height: 20px;"  class="date_rouge"></span><br><br>
&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style="font-family: 'Calibri',sans-serif;"  class="nom_gouvernant_compte_client"></span><br><br><br><br>
</p>
</p>

</p>
</div> 
    `,
  },
];
