<template>
  <div id="quesionnaire-Steps-one" class="">
    <div class="md:tw-m-28">
    <div class="tw-text-l">
      ÉCOSYSTÈME DE LA DONNÉE PERSONNELLE |
      <span class="font-color-primary tw-uppercase"> VOTRE ENVIRONNEMENT DE TRAVAIL </span>
    </div>
    <div class="tw-flex bg-color-primary tw-w-3/4 tw-px-3 tw-mt-3">
        <span class="tw-py-1 tw-px-4">
            <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.33338 13.7502H11.6667M6.16671 20.1668H9.83338H6.16671ZM1.58338 8.25016C1.58338 6.54836 2.25942 4.91625 3.46277 3.71289C4.66613 2.50954 6.29824 1.8335 8.00004 1.8335C9.70185 1.8335 11.334 2.50954 12.5373 3.71289C13.7407 4.91625 14.4167 6.54836 14.4167 8.25016C14.4173 9.28331 14.1674 10.3012 13.6885 11.2166C13.2095 12.1321 12.5158 12.9177 11.6667 13.5063L11.1699 15.8585C11.1041 16.2921 10.8851 16.6877 10.5526 16.9736C10.2201 17.2595 9.79614 17.4167 9.35763 17.4168H6.64246C6.20394 17.4167 5.77998 17.2595 5.44748 16.9736C5.11499 16.6877 4.89599 16.2921 4.83021 15.8585L4.33338 13.5164C3.484 12.9257 2.79023 12.1382 2.31135 11.2212C1.83246 10.3041 1.58269 9.28473 1.58338 8.25016V8.25016Z" stroke="#F8F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        <span class="tw-text-white tw-py-1">
            Cliquez sur la ou les réponses qui vous concernent, celles-ci s’activeront. Plusieurs choix possibles.
        </span>
    </div>
<answer-table :items.sync="items"/>
        </div>
    <div class="tw-py-10 tw-relative tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-16">
      <primary-button-component
        :disabled="answers.length>0"
        @click="prevStep()"
        class="
          tw-absolute tw-left-10 tw-bottom-10
          dynamic-text
          tw-bg-white tw-outline tw-outline-2
        "
        :style="{
                backgroundColor: `${referrer.theme.ctaBgColor} !important`,
                color: `${referrer.theme.ctaTextColor}  !important`
        }"
        >&#8592; Précédent</primary-button-component
      >
      <primary-button-component
        class="
          tw-absolute
          tw-right-10
          tw-bottom-10
          tw-text-white
          tw-outline
          tw-outline-2
          tw-outline-white
          tw-bg-transparent
        "
        :style="{
                backgroundColor: `${referrer.theme.ctaBgColor} !important`,
                color: `${referrer.theme.ctaTextColor}  !important`
        }"
        :disabled="answers.length == 0"
        @click="nextStep()"
        >Suivant &#8594;</primary-button-component
      >
    </div>
  </div>
</template>

<script>
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import {stepOne} from "../mocks/data";
import AnswerTable from "./AnswersTable.vue";
export default {
  name: "StepOne",
  components: { PrimaryButtonComponent,AnswerTable},
  data() {
    return {
      answers: [],
      items: stepOne,
      lastAnswers: [],
    };
  },
  created() {
    this.items.forEach(element=>{
        element.answers.forEach(el=>{
            el.forEach(e=>{
                e.checked?this.answers.push(e):null
            })
        })
    })
  },
  computed: {
    referrer() {
      return this.$store.state.referrer;
    },
  },
  methods: {
    nextStep() {
      this.$parent.index++
      this.$parent.allAnswers.push(this.items)
    },
    prevStep() {
      this.$parent.index--
    },
    chooseItem(answer) {
      answer.checked = !answer.checked;
      this.answers.includes(answer)
        ? this.answers.splice(this.answers.indexOf(answer), 1)
        : this.answers.push(answer);
    },
  },
};
</script>

<style lang="scss" scoped></style>
