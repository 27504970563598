import Questionnaire from '@/Questionnaire'

import QuestionnaireStepOne from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/Questionnaire/QuestionnaireStepOne";
import QuestionnaireStepTwo from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/Questionnaire/QuestionnaireStepTwo";
import QuestionnaireStepThree from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/Questionnaire/QuestionnaireStepThree";
import QuestionnaireStepFour from "@/features/company/pages/MonEntreprise/Collaborateurs/EcoSystem/Questionnaire/QuestionnaireStepFour";

const routes = [
    {
        name: "Questionnaire",
        path: "/questionnaire",
        component: Questionnaire,
        meta: {
            breadcrumb: { name: ''}
      },
      children: [
          {
            name: "Questionnaire-step-one",
            path: "step-one/:token",
            component: QuestionnaireStepOne,
            meta: {
                breadcrumb: { name: ''}
          }
          },
          {
            name: "Questionnaire-step-two",
            path: "step-two/:token",
            component: QuestionnaireStepTwo,
            meta: {
                questionnaire:true,
                breadcrumb: { name: ''}
          }
          },
          {
            name: "Questionnaire-step-three",
            path: "step-three/:token",
            component: QuestionnaireStepThree,
            meta: {
              questionnaire:true,
                breadcrumb: { name: ''}
          }
          },
          {
            name: "Questionnaire-step-four",
            path: "step-four",
            component: QuestionnaireStepFour,
            meta: {
              questionnaire:true,
                breadcrumb: { name: ''}
          }
          },
      ]
    },
]

export default routes;
