<template>
  <v-dialog
    v-model="openPopup"
    v-if="openPopup"
    @click:outside="closePopup"
    scrollable
    width="80%"
    transition="dialog-bottom-transition"
  >
    <perfect-scrollbar
        ref="scroll"
    >
      <img
          class="img-close"
          src="@/assets/images/icons/closePopup.svg"
          alt="closePopup"
          @click="closePopup"
      />
      <div class="tw-mt-12">
        <template>
          <MentionsLegalesComponent />
        </template>
      </div>
    </perfect-scrollbar>
  </v-dialog>
</template>
<script>
import MentionsLegalesComponent from "@/components/layout/landing/MentionsLegalesComponent";
export default {
  name: "MentionsLegalesPopup",
  components: {MentionsLegalesComponent},
  props: {
    openPopup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },

  data() {
    return {
    };
  },
  methods: {
    closePopup() {
      this.$emit("close", false);
      this.openPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#deleteDialog {
  position: relative;
  img {
    height: 40px;
    width: 40px;
    margin: 20px auto 20px;
  }
  .img-close {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
